import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UrlifeInfiniteScroller from '../UrlifeInfiniteScroller'
import ProjectChatMessage from './ProjectChatMessage'
import ChatInput from './ChatInput'
import { ROLES } from '../../../constants'
import {
  LoadMore
} from '../SVG/index'

/* eslint-disable sort-keys */
const styles = {
  header: {
    fontSize: 16,
    fontWeight: 500,
    color: '#545454',
    letterSpacing: '0.58px',
    textTransform: 'uppercase'
  },
  scrollerBlock: {
    width: '100%',
    padding: '10px 15px',
    overflowY: 'auto'
  },
  scroller: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '20px 0'
  }
}
/* eslint-enable sort-keys */

class ChatTabView extends Component {
  componentDidMount () {
    this.props.setPolling(true)
  }

  componentWillUnmount () {
    this.props.setPolling(false)
  }

  render () {
    const {
      classes,
      project,
      roleId,
      userId,
      thread,
      messages,
      users,
      hasMore,
      isLoading,
      shouldScrollToEnd,
      loadMore,
      onSend
    } = this.props
    const isAdmin = roleId === ROLES.ADMIN
    let otherUserId = ''
    switch (roleId) {
      case ROLES.ADMIN:
      case ROLES.PRODUCER:
        otherUserId = thread === 'customer' ? project.customerId : project.projectSkus[0].editorId
        break
      case ROLES.USER:
      case ROLES.EDITOR:
        otherUserId = project.projectSkus[0].producerId
        break
      default:
        break
    }
    const otherUser = otherUserId ? users[otherUserId] : null
    return (
      <React.Fragment>
        <div className={classes.header}>
          {otherUser ? 'Messages with ' + otherUser.fullname : 'Messages'}
        </div>
        {!isAdmin && <ChatInput onSend={onSend} />}
        <div className={classes.scrollerBlock}>
          <UrlifeInfiniteScroller
            loadMore={loadMore}
            hasMore={hasMore}
            className={classes.scroller}
            scrollElement={'parent'}
            shouldScrollToEnd={shouldScrollToEnd}
          >
            {
              messages.map(msg => {
                return <ProjectChatMessage
                  key={msg.id}
                  message={msg}
                  messageUserId={msg.user_id}
                  messageUser={users[msg.user_id]}
                  currentUserId={userId}
                  thread={thread}
                />
              })
            }
            {isLoading &&
              <div className={classes.loader}><LoadMore /></div>
            }
          </UrlifeInfiniteScroller>
        </div>
      </React.Fragment>
    )
  }
}

ChatTabView.propTypes = {
  classes: PropTypes.object.isRequired,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number,
  setPolling: PropTypes.func.isRequired,
  shouldScrollToEnd: PropTypes.bool,
  thread: PropTypes.oneOf(['customer', 'editor']).isRequired,
  userId: PropTypes.string,
  users: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ChatTabView))
