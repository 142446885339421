import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { WarningIcon } from '../../SVG'

/* eslint-disable sort-keys */
const styles = {
  infoBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#01b7d7',
    overflow: 'hidden',
    '&._large': {
      height: 32
    },
    '&._medium': {
      height: 29
    },
    '&._small': {
      height: 11
    }
  },
  icon: {
    color: '#ffffff',
    '&._large': {
      width: 17,
      height: 17
    },
    '&._medium': {
      width: 15,
      height: 15
    },
    '&._small': {
      display: 'none'
    }
  },
  infoText: {
    position: 'relative',
    left: '8px',
    letterSpacing: '0.44px',
    color: '#ffffff',
    textTransform: 'uppercase',
    '&._large': {
      fontSize: 13
    },
    '&._medium': {
      fontSize: 12
    },
    '&._small': {
      display: 'none'
    }
  }
}
/* eslint-enable sort-keys */

class InfoBar extends React.Component {
  render () {
    const { classes, text, sizeClass } = this.props
    return (
      <div className={classNames(classes.infoBar, sizeClass)}>
        <WarningIcon className={classNames(classes.icon, sizeClass)} />
        <div className={classNames(classes.infoText, sizeClass)}>{text}</div>
      </div>
    )
  }
}

InfoBar.propTypes = {
  classes: PropTypes.object.isRequired,
  sizeClass: PropTypes.oneOf(['_small', '_medium', '_large']).isRequired,
  text: PropTypes.string
}

export default withStyles(styles)(InfoBar)
