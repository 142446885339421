import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { AlertTriangle } from '../../../common/SVG'
import classNames from 'classnames'
import ModalPreview from '../../../common/ModalPreview'

/* eslint-disable sort-keys */
const styles = {
  fieldThumbnail: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  fieldThumbnailImage: {
    width: 64,
    height: 64,
    flexShrink: 0,
    borderRadius: 10,
    backgroundColor: '#eff2f5',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    position: 'relative',
    border: '1px solid transparent',
    '&._error': {
      borderColor: '#eb5757'
    },
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  fieldThumbnailIcon: {
    fontSize: 20,
    position: 'absolute',
    color: '#eb5757',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    margin: 'auto'
  },
  changeFileFormContainer: {
    display: 'flex',
    position: 'relative',
    paddingTop: 6
  },
  openFileFormLink: {
    color: '#01b7d7',
    border: 'none',
    fontSize: 14,
    background: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      borderBottom: 'none'
    }
  },
  changeFileForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 350,
    top: '100%',
    marginTop: 10,
    left: -22,
    backgroundColor: '#fff',
    boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.1)',
    padding: '10px 22px',
    zIndex: 1,
    '&:before': {
      content: '""',
      border: '6px solid transparent',
      borderBottom: '6px solid white',
      position: 'absolute',
      top: -12,
      left: 70
    }
  },
  changeFileInputContainer: {
    width: '100%',
    '& + &': {
      marginTop: 30
    }
  },
  changeFileInputComponent: {
    width: '100%'
  },
  changeFileInput: {
    fontSize: 14
  },
  submitButton: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: '#01b7d7',
    fontWeight: 700,
    marginTop: 10,
    '&[disabled]': {
      background: 'none'
    }
  },
  editFilePlayIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
}
/* eslint-enable sort-keys */

class EditUploadField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openModal: false
    }
  }

  showPreview () {
    this.setState({ openModal: true })
  }

  onClose () {
    this.setState({ openModal: false })
  }

  onChange (e) {
    const file = e.target.files[0]
    if (!file) return false
    this.props.onApply(file, this.props.fieldTitle)
  }

  render () {
    const {
      classes,
      image,
      fieldTitle,
      rowId,
      acceptFiles,
      preview
    } = this.props
    const { openModal } = this.state
    const isValidImage = image && image.trim().length > 0

    return (
      <div className={classes.fieldThumbnail}>
        <div className={classes.changeFileFormContainer}>
          <div
            className={classNames(
              classes.fieldThumbnailImage,
              { _error: !isValidImage },
              { _clickable: !!preview }
            )}
            style={image ? { backgroundImage: `url(${image})` } : {}}
            onClick={() => preview && this.showPreview()}
          >

            {(!image) && <AlertTriangle className={classes.fieldThumbnailIcon} />}
          </div>
          <label htmlFor={fieldTitle + '-' + rowId}>
            <p className={classes.openFileFormLink} >Change {fieldTitle.toLowerCase()}</p></label>
          <input
            data-testid='euf-input'
            onChange={this.onChange.bind(this)}
            style={{ display: 'none' }}
            accept={acceptFiles || '.jpg, .jpeg, .png, .svg'}
            type="file"
            id={fieldTitle + '-' + rowId}
          />

        </div>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          {...preview}
        />
      </div>
    )
  }
}

EditUploadField.propTypes = {
  acceptFiles: PropTypes.string,
  classes: PropTypes.object.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  image: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  preview: PropTypes.object,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default withStyles(styles)(EditUploadField)
