import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PlusCircledIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 25 25' style={{ fill: 'none' }}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 24C6.14873 24 1 18.8513 1 12.5C1 6.14873 6.14873 1 12.5 1C18.8513 1 24 6.14873 24 12.5C24 18.8513 18.8513 24 12.5 24Z" stroke="currentColor"/>
      <rect x="12" y="7" width="1" height="11" fill="currentColor"/>
      <rect x="18" y="12" width="1" height="11" transform="rotate(90 18 12)" fill="currentColor"/>
    </SvgIcon>
  )
}
