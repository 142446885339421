const initialState = {
  loggedIn: false,
  wantsLogin: false,
  wasAutomaticLogout: false,
  wasManualLogout: false
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        loginFailed: false,
        wantsLogin: false,
        wasAutomaticLogout: false,
        wasManualLogout: false
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        email: '',
        loggedIn: true,
        loggingIn: false
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginFailed: true
      }
    case 'RECEIVED_INFO':
      return {
        ...state,
        email: action.user.data.email,
        fullname: action.user.data.fullname || '',
        loggedIn: true,
        role: action.user.data.role || '',
        roleId: action.user.data.roleId || '',
        userid: action.user.data.id
      }
    case 'FAILED_INFO':
      return {
        ...state,
        infoFailed: true
      }
    case 'RESET_PASS_START':
      return {
        ...state,
        resetFailed: false,
        resetSuccess: false
      }
    case 'RESET_PASS_SUCCESS':
      return {
        ...state,
        resetSuccess: true
      }
    case 'RESET_PASS_FAILED':
      return {
        ...state,
        resetFailed: true
      }
    case 'RESET_LOGIN':
      return {
        ...state,
        loginFailed: false
      }
    case 'LOGOUT':
      return {
        loggedIn: false,
        wasAutomaticLogout: action.wasAutomaticLogout,
        wasManualLogout: action.wasManualLogout
      }
    case 'WANTS_LOGIN':
      return {
        ...state,
        wantsLogin: action.wantsLogin
      }
    case 'GETTING_COGNITO':
      return {
        ...state,
        cognito: {},
        failedGettingCognito: false,
        gettingCognito: true,
        gotCognito: false
      }
    case 'GOT_COGNITO':
      return {
        ...state,
        cognito: action.cognito,
        gettingCognito: false,
        gotCognito: true
      }
    case 'GET_COGNITO_FAILED':
      return {
        ...state,
        failedGettingCognito: true,
        gettingCognito: false
      }
    default:
      return state
  }
}

export default user
