import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  termsContainer: {
    '& a': {
      color: '#5334b6',
      '&:hover': {
        color: '#01b7d7'
      }
    }
  },
  termsText: {
    ...commonStyles.text({
      lineHeight: 1.5,
      letterSpacing: 0.8,
      color: '#1c2025',
      fontSize: 16,
      marginBottom: 30
    })
  },
  termsList: {
    listStyle: 'none',
    ...commonStyles.text({
      lineHeight: 1.5,
      letterSpacing: 0.8,
      color: '#1c2025',
      fontSize: 16,
      marginBottom: 20
    })
  },
  termsItem: {
    position: 'relative',
    marginBottom: 25,
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: "'—'",
      top: 0,
      left: -35,
      color: '#1c2025'
    }

  },
  termsTitle: {
    marginTop: 0,
    ...commonStyles.text({
      lineHeight: 1.25,
      fontSize: 48,
      fontWeight: 300,
      color: '#000000'
    })
  },
  termsSubTitle: {
    ...commonStyles.text({
      lineHeight: 0.92,
      fontSize: 26,
      color: '#000000'
    }),
    margin: '50px 0 40px'
  },
  termsLink: {
    ...commonStyles.text({
      color: '#5334b6'
    }),
    textDecoration: 'none'
  },
  termsBox: {
    border: '2px solid #000',
    padding: '5px'
  },
  termsBoxHeader: {
    ...commonStyles.text({
      textTransform: 'uppercase',
      lineHeight: 0.92,
      fontSize: 26,
      color: '#000'
    })
  }
}
/* eslint-enable sort-keys */

class Terms extends React.Component {
  render () {
    const { classes } = this.props
    return <div>
      <div className={classes.termsContainer}>
        <h2 className={classes.termsTitle}>Agreement between User and urlifemedia.com</h2>
        <div className={classes.termsTextBlock}>
          <p className={classes.termsText}>
            The following are the terms of use (“Terms”) that govern your use of the
            websites and applications where this appears (collectively, the “Site”).
            Our privacy policy (<a className={classes.termsLink} href="/privacy">www.urlifemedia.com/privacy</a>) and
            any other policies, rules or guidelines that may be applicable to particular
            offers or features on the Site are also incorporated into these Terms.
            By visiting or using the Site, you expressly agree to these Terms, as updated
            from time to time.
          </p>
          <p className={classes.termsText}>
            urlifemedia.com is an Application Site, which is operated by
            URLIFE Media, Inc. and its affiliates (collectively, “URLIFE”, “we”, “us”
            and/or “our”).
            The purpose of this website is to inform user about URLIFE personal digital
            services.
            Users will be able to register and purchase custom digital services,
            including but not limited to editing of content and customized mini-movies,
            by uploading personal photos and videos. Users can select from a variety of
            packages.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We provide the services of expert Hollywood editors and producers who will
              transform your photos and videos into custom mini-movies or a curated
              folder of edited and enhanced content. These terms govern your use of our
              Service.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Uploading Content and Carrier Rates</h3>
          <p className={classes.termsText}>
            Note that carrier data rate plans will apply if you are using your mobile
            carrier to upload content.
            We will send notifications suggesting that you connect to a wi-fi network.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              You should use Wi-Fi to upload your content!
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Electronic Communications</h3>
          <p className={classes.termsText}>
            Visiting urlifemedia.com or sending emails to URLIFE constitutes electronic
            communications.
            You consent to receive electronic communications and you agree that all
            agreements, notices, disclosures and other communications that we provide to
            you electronically, via email and on the Site, satisfy any legal requirement
            that such communications be in writing.
            You have the right to opt-out of receiving any communications from us other
            than communications directly related to providing you the custom personal
            digital services.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We like our customers. We like talking to our customers.
              So we may send you emails from time to time.
              Let us know if you {'don\'t'} want to receive them.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Your account</h3>
          <p className={classes.termsText}>
            If you use this site, you are responsible for maintaining the confidentiality
            of your account and password and for restricting access to your computer, and
            you agree to accept responsibility for all activities that occur under your
            account or password.
            You may not assign or otherwise transfer your account to any other person or
            entity.
            You acknowledge that URLIFE is not responsible for third party access to your
            account that results from theft or misappropriation of your account.
            URLIFE and its associates reserve the right to refuse or cancel service,
            terminate accounts, or remove or edit content in our sole discretion.
          </p>
          <p className={classes.termsText}>
            URLIFE does not knowingly collect, either online or offline, personal
            information from persons under the age of thirteen.
            If you are under 18, you may use urlifemedia.com only with permission of a
            parent or guardian.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              Keep your account information safe and private.
              You cannot use URLIFE if you are under 13.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Code of Conduct</h3>
          <p className={classes.termsText}>
            You agree that you will comply with all applicable laws, rules and
            regulations, and that you will not:
          </p>
          <ul className={classes.termsList}>
            <li className={classes.termsItem}>
              Engage in any illegal, vulgar or harassing activity with any of our users;
            </li>
            <li className={classes.termsItem}>
              Restrict or inhibit any other person from using the Site;
            </li>
            <li className={classes.termsItem}>
              Use the Site for any unlawful purpose;
            </li>
            <li className={classes.termsItem}>
              Express or imply that any statements you make are endorsed by us, without
              our prior written consent;
            </li>
            <li className={classes.termsItem}>
              Impersonate any person or entity, whether actual or fictitious, including
              any employee or representative of our company;
            </li>
            <li className={classes.termsItem}>
              Submit (a) any content or information that is unlawful, fraudulent,
              libelous, defamatory, or otherwise objectionable, or infringes our or any
              third {'party\'s'} intellectual property or other rights; (b) any
              non-public information about companies without authorization; (c) any
              advertisements, solicitations, chain letters, pyramid schemes, surveys,
              contests, investment opportunities or other unsolicited commercial
              communication; or (d) any content or information that, in the sole judgment
              of URLIFE, is objectionable or which may expose URLIFE or its users to any
              harm or liability of any type
            </li>
            <li className={classes.termsItem}>
              Submit, or provide links to, any postings containing material that could be
              considered harmful, obscene, pornographic, sexually explicit, indecent,
              lewd, violent, abusive, profane, insulting, threatening, harassing, hateful
              or otherwise objectionable, or contains any personal contact information or
              other personal information identifying any third party;
            </li>
            <li className={classes.termsItem}>
              Submit, or provide links to, any postings containing material that
              harasses, victimizes, degrades, or intimidates an individual or group of
              individuals on the basis of religion, race, ethnicity, sexual orientation,
              gender, age, or disability;
            </li>
            <li className={classes.termsItem}>
              Engage in spamming or flooding;
            </li>
            <li className={classes.termsItem}>
              Harvest or collect information about Site users; or
            </li>
            <li className={classes.termsItem}>
              Use any area of the Site for commercial purposes, such as to promote your
              content or your company, products or services.
            </li>
          </ul>
          <p className={classes.termsText}>
            Unless otherwise expressly authorized herein or in the Site, you agree not to
            display, distribute, license, perform, publish, reproduce, duplicate, copy,
            create derivative works from, modify, sell, resell, exploit, transfer or
            upload for any commercial purposes, any portion of the Site, use of the Site,
            or access to the Site.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              URLIFE is all about making {'people\'s'} memories even better, so {'don\'t'} do
              anything that would change or jeopardize that for others.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Ownership of Content and Grant of Conditional License</h3>
          <p className={classes.termsText}>
            The Site and all data, text, designs, pages, print screens, images, artwork,
            photographs, audio and video clips, and HTML code, source code, or software
            that reside or are viewable or otherwise discoverable on the Site
            (collectively, the “Content”) are owned by us or our licensors.
            We own a copyright and, in many instances, patents and other intellectual
            property in the Site and Content.
            We may change the Content and features of the Site at any time.
          </p>
          <p className={classes.termsText}>
            Parts of the Content are articles and newsletters related to content
            creation, production, exhibition, distribution and licensing.
            The information and views expressed in the articles and newsletters may be
            opinions (either ours or those of other parties) and URLIFE in no way
            endorses, warranties or guarantees any such information and views.
          </p>
          <p className={classes.termsText}>
            We grant you a limited, conditional, no-cost, non-exclusive,
            non-transferable, non-sublicensable license to view this Site and its Content
            as permitted by these Terms for non-commercial purposes only if, as a
            condition precedent, you agree that you will not:
          </p>
          <ul className={classes.termsList}>
            <li className={classes.termsItem}>
              Submit any software or other materials that contain any viruses, worms,
              Trojan horses, defects, date bombs, time bombs or other items of a
              destructive nature;
            </li>
            <li className={classes.termsItem}>
              Manipulate identifiers, including by forging headers, in order to disguise
              the origin of any posting that you submit;
            </li>
            <li className={classes.termsItem}>
              Link to any portion of the Site other than the URL assigned to the home
              page of the Site;
            </li>
            <li className={classes.termsItem}>
              “Frame” or “mirror” any part of the Site;
            </li>
            <li className={classes.termsItem}>
              Modify, adapt, sub-license, translate, sell, reverse engineer, decompile or
              disassemble any portion of the Site or otherwise attempt to derive any
              source code or underlying ideas or algorithms of any part of the Content;
            </li>
            <li className={classes.termsItem}>
              Remove any copyright, trademark or other proprietary rights notices
              contained on the Site;
            </li>
            <li className={classes.termsItem}>
              Use any robot, spider, offline reader, site search/retrieval application or
              other manual or automatic device, tool, or process to retrieve, index, data
              mine or in any way reproduce or circumvent the navigational structure or
              presentation of the Content or the Site, including with respect to any
              CAPTCHA displayed on the Site.
              Operators of public search engines may use spiders to copy materials from
              the Site for the sole purpose of and solely to the extent necessary for
              creating publicly available searchable indices of the materials, but not
              caches or archives of such materials. We may revoke this exception at any
              time and require removal of archived materials gathered in the past;
            </li>
            <li className={classes.termsItem}>
              Take any action that imposes or may impose (in our sole discretion) an
              unreasonable or disproportionately large load on our infrastructure;
            </li>
            <li className={classes.termsItem}>
              Reproduce, modify, display, publicly perform, distribute or create
              derivative works of the Site or the Content;
            </li>
            <li className={classes.termsItem}>
              Use the Site or the Content in an attempt to, or in conjunction with, any
              device, program or service designed to circumvent any technological measure
              that effectively controls access to, or the rights in, the Site and/or
              Content in any way including, without limitation, by manual or automatic
              device or process, for any purpose.
            </li>
          </ul>
          <p className={classes.termsText}>
            This license is expressly conditioned on your preexisting agreement to comply
            with, and your actual compliance with, each of the provisions described in
            this section.
            This license exists only so long as you strictly comply with each of the
            provisions described in this section.
            Any use of the Site or Content by you or anyone acting on your behalf that
            does not strictly comply with each and every provision in this section
            exceeds the scope of the license granted to you herein, constitutes
            unauthorized reproduction, display, or creation of unauthorized derivative
            versions of the Site and Content, and infringes our copyrights, trademarks,
            patents and other rights in the Site and Content.
            You will not acquire any ownership rights by using the Site or the Content.
          </p>
          <p className={classes.termsText}>
            The registered and unregistered trademarks, logos and service marks displayed
            on the Site are owned by us or our licensors.
            You may not use our trademarks, logos and service marks in any way without
            our prior written permission.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We hope you love our site! However, everything created by us, on our site
              (aside from your own content) is covered by trademark and copyright rules.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>User Content</h3>
          <p className={classes.termsText}>
            You will be able to submit audio and video recordings, photographs, artwork
            or other materials to the Site (“User Content”).
          </p>
          <p className={classes.termsText}>
            By submitting User Content, you certify that you are at least 18 years old,
            or you are at least 13 years old and have obtained your {'parent\'s'} or
            legal {'guardian\'s'} express consent to submit User Content.
            As between You and us, You own all rights to your User Content.
            If you submit User Content to the Site, you grant us a worldwide,
            non-exclusive, transferable, sublicenseable, royalty-free right and license
            to use your User Content to provide our Service.
            We may contact you and request permission to share your final video for
            promotional purposes, but will never do so without your consent.
            This license will not affect your ownership in your User Content, including
            the right to grant additional licenses to your User Content, except if it
            conflicts with these Terms.
            We are not obligated to post, display or otherwise use any User Content, or
            to attribute your User Content to you.
            You will not make or authorize any claim against us that our use of your User
            Content infringes any of your rights.
            We will not be liable for any loss or harm caused by the posting or your
            reliance on information obtained through the postings.
          </p>
          <p className={classes.termsText}>
            You will be responsible for your User Content and the consequences of posting
            it.
            By submitting User Content, you represent to us that (i) you own, or have the
            necessary permission to submit the User Content and to grant the licenses to
            us under this section, and (ii) you have the written permission of every
            identifiable person in the User Content to use that {'person\'s'} name and likeness
            in the manner contemplated by the Site and these Terms or, if the person is a
            minor, the written permission of the {'minor\'s'} parent or legal guardian.
          </p>
          <p className={classes.termsText}>
            We will have the right (but not the obligation) to monitor the Site, and the
            User Content, and to disclose any User Content and the circumstances
            surrounding its submission in order to operate the Site properly, or to
            protect ourselves, our sponsors and our users, or to comply with legal
            obligations or governmental requests.
          </p>
          <p className={classes.termsText}>
            If we are notified that your User Content does not comply with these Terms,
            we may investigate the allegation and may decide to remove your User Content
            and cancel your account.
            We may also hold you liable for any User Content that infringes the rights of
            a third party, and require you to pay or reimburse us for any amounts we
            believe are necessary to resolve any complaint.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              While using our Service, you are submitting photos and videos for us to
              create a project for you.
              Use your best judgment with the content you submit!
              Also, this is your content – and we would never use it without your permission.
              But {'we\'d'} like to reserve the right to contact you in case we want to use
              your content for promotional purposes.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Claims of Copyright Infringement on the Site</h3>
          <p className={classes.termsText}>
            Under the Digital Millennium Copyright Act of 1998 (the {'"DMCA"'}) if you believe
            in good faith that any content on the Site infringes your copyright, you may
            send us a notice requesting that the content be removed.
            The notice must include: (a) your (or your {'agent\'s'}) physical or
            electronic signature; (b) identification of the copyrighted work on our Site
            that is claimed to have been infringed (or a representative list if multiple
            copyrighted works are included in one notification); (c) identification of
            the content that is claimed to be infringing or the subject of infringing
            activity, including information reasonably sufficient to allow us to locate
            the content on the Site; (d) your name, address, telephone number and email
            address (if available); (e) a statement that you have a good faith belief
            that use of the content in the manner complained of is not authorized by you
            or your agent or the law; and (f) a statement that the information in the
            notification is accurate and, under penalty of perjury, that you or your agent
            is authorized to act on behalf of the copyright owner.
            If you believe in good faith that a notice of copyright infringement has been
            wrongly filed against you, you may send us a counter-notice.
            You may read more information about the DMCA
            at <a className={classes.termsLink} href="http://www.loc.gov/copyright">http://www.loc.gov/copyright</a>.
          </p>
          <p className={classes.termsText}>
            Notices and counter-notices should be sent to URLIFE Media, Inc., 3524 Hayden
            Avenue, Los Angeles, California 90232, Attn: Chief Operating
            Officer, <a className={classes.termsLink} href="mailto:vickil@urlifemedia.com">vickil@urlifemedia.com</a>.
            There can be penalties for false claims under the DMCA.
            We suggest that you consult your legal advisor before filing a notice or
            counter-notice.
          </p>
          <p className={classes.termsText}>
            To be effective, the notification must be in writing and contain the
            following information:
          </p>
          <ul className={classes.termsList}>
            <li className={classes.termsItem}>
              an electronic or physical signature of the person authorized to act on
              behalf of the owner of the copyright or other intellectual property interest;
            </li>
            <li className={classes.termsItem}>
              a description of the copyrighted work or other intellectual property that
              you claim has been infringed;
            </li>
            <li className={classes.termsItem}>
              a description of where the material that you claim is infringing is located
              on the Site, with enough detail that we may find it on the Site;
            </li>
            <li className={classes.termsItem}>
              your address, telephone number, and email address;
            </li>
            <li className={classes.termsItem}>
              a statement by you that you have a good faith belief that the disputed use
              is not authorized by the copyright or intellectual property owner, its
              agent, or the law;
            </li>
            <li className={classes.termsItem}>
              a statement by you, made under penalty of perjury, that the above
              information in your Notice is accurate and that you are the copyright or
              intellectual property owner or authorized to act on the copyright or
              intellectual property {'owner\'s'} behalf.
            </li>
          </ul>
          <p className={classes.termsText}>
            If you believe that your User Content that was removed (or to which access
            was disabled) is not infringing, or that you have the authorization from
            the copyright owner, the copyright {'owner\'s'} agent, or pursuant to the law, to
            upload and use the content in your User Content, you may send a written
            counter-notice containing the following information to the Copyright Agent
            (identified above): (a) your physical or electronic signature; (b)
            identification of the content that has been removed or to which access has
            been disabled and the location at which the content appeared before it was
            removed or disabled; (c) a statement that you have a good faith belief that
            the content was removed or disabled as a result of mistake or a
            misidentification of the content; and (d) your name, address, telephone
            number, and email address, a statement that you consent to the jurisdiction
            of the federal court located within Central District of California and a
            statement that you will accept service of process from the person who
            provided notification of the alleged infringement.
          </p>
          <p className={classes.termsText}>
            If a counter-notice is received by the Copyright Agent, URLIFE will send a
            copy of the counter-notice to the original complaining party informing that
            person that it may replace the removed content or cease disabling it in 10
            business days.
            Unless the copyright owner files an action seeking a court order against the
            content provider, member or user, the removed content may be replaced, or
            access to it restored, in 10 to 14 business days or more after receipt of the
            counter-notice, at our sole discretion.
          </p>
          <p className={classes.termsText}>
            In accordance with the DMCA and other applicable law, URLIFE has adopted a
            policy of terminating, in appropriate circumstances and at {'URLIFE\'s'} sole
            discretion, users who are deemed to be repeat infringers.
            URLIFE may also at its sole discretion limit access to the Site and/or
            terminate the registrations of any users who infringe any intellectual
            property rights of others, whether or not there is any repeat infringement.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We follow the Copyright laws.
              If you think people are misusing your copyright, follow these procedures.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Links to third party sites/Third party services</h3>
          <p className={classes.termsText}>
            urlifemedia.com may contain links to other websites (“Linked Sites”).
            The Linked Sites are not under the control of URLIFE and URLIFE is not
            responsible for the contents of any Linked Site, including without limitation
            any link contained in a Linked Site, or any changes or updates to a Linked
            Site.
            URLIFE is providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by URLIFE of the site or any
            association with its operators.
          </p>
          <p className={classes.termsText}>
            Certain services made available via urlifemedia.com are delivered by third
            party sites and organizations.
            For example, we do not process any payments; they are done so by a third
            party processor.
            By using any product, service or functionality originating from the
            urlifemedia.com domain, you hereby acknowledge and consent that other parties
            may have access to your information and data.
            The handling of any such data or information will be governed by those
            third {'parties\''} privacy policies.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              URLIFE may occasionally feature content linked outside of URLIFE.
              Most of that stuff will be awesome and fun, but we {'can\'t'} control the
              3rd party sites, or their content.
              But we do promise to only work with good companies.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>International Users</h3>
          <p className={classes.termsText}>
            The Service is controlled, operated and administered by URLIFE from our
            offices within the USA.
            If you access the Service from a location outside the USA, you are
            responsible for compliance with all local laws.
            You agree that you will not use the URLIFE Content accessed through
            urlifemedia.com in any country or in any manner prohibited by any applicable
            laws, restrictions or regulations.
            These Terms shall be governed by the laws of the State of California, without
            respect to its conflict of laws principles.
          </p>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Indemnification</h3>
          <p className={classes.termsText}>
            You agree to indemnify, defend and hold harmless URLIFE, its officers,
            directors, employees, agents and third parties, for any losses, costs,
            liabilities and expenses (including reasonable {'attorneys\''} fees)
            relating to or arising out of your use of or inability to use the Site or
            services, any user postings made by you, your violation of any terms of this
            Agreement or your violation of any rights of a third party, or your violation
            of any applicable laws, rules or regulations.
            URLIFE reserves the right, at its own cost, to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by you, in
            which event you will fully cooperate with URLIFE in asserting any available
            defenses.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              You are responsible for whatever you post and any legal issues that may
              result from it.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Liability disclaimer</h3>
          <p className={classes.termsText}>
            WE PROVIDE THE SITE AND THE SERVICES TO YOU {'"AS IS"'} AND {'"AS AVAILABLE"'}.
            WE TRY TO KEEP THE SITE UP, BUG-FREE AND SAFE, BUT YOU USE IT AT YOUR OWN RISK.
            TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND TO THE EXTENT THAT APPLICABLE
            LAW PERMITS THE DISCLAIMER OF EXPRESS OR IMPLIED WARRANTIES, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF TITLE,
            NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            OR WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR COURSE OF PERFORMANCE
            OR USAGE OF TRADE.
            WE DO NOT GUARANTEE THAT THE SITE WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE
            OR THAT THE SITE WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR
            IMPERFECTIONS.
            WE DO NOT GUARANTEE THE QUALITY OR STANDARD OF ANY OF THE CONTENT, INCLUDING
            ANY OF THE NEWSLETTERS OR ARTICLES.
            WE ARE NOT RESPONSIBLE FOR THE ACTIONS OR INFORMATION OF THIRD PARTIES,
            INCLUDING COLUMNISTS, AND YOU RELEASE US FROM ANY CLAIMS AND DAMAGES, KNOWN
            AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE
            AGAINST ANY SUCH THIRD PARTIES.
            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE §1542,
            WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
            DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
            EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
            AFFECTED HIS SETTLEMENT WITH THE DEBTOR.
          </p>
          <p className={classes.termsText}>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL URLIFE
            MEDIA AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
            WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF
            OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE
            DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR
            FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS,
            SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
            OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, EVEN IF URLIFE MEDIA OR ANY OF ITS SUPPLIERS
            HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
            BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY
            NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR
            WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
            DISCONTINUE USING THE SITE.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              Our service is built around giving you a great experience and we want you
              to like us.
              However, sometimes there are glitches, and things {'don\'t'} work as planned.
              We understand if you want to discontinue doing business with us.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Disputes, Including Mandatory Arbitration and Class Action Waiver</h3>
          <p className={classes.termsText}>
            Any dispute or claim relating in any way to your use of the Site, or to
            products or services sold or distributed by us or through us, will be
            resolved by binding arbitration rather than in court, with the
            following exceptions:
          </p>
          <p className={classes.termsText}>
            You may assert claims in small claims court if your claims apply;
          </p>
          <p className={classes.termsText}>
            If a claim involves the conditional license granted to you as described in
            the Ownership of Content and Grant of Conditional License section above,
            either of us may file a lawsuit in a federal or state court located within
            Los Angeles, California, and we both consent to the jurisdiction of those
            courts for such purposes; and
          </p>
          <p className={classes.termsText}>
            In the event that the arbitration agreement in these Terms is for any reason
            held to be unenforceable, any litigation against us (except for small-claims
            court actions) may be commenced only in a federal or state court located
            within Los Angeles, California, and we both consent to the jurisdiction of
            those courts for such purposes.
          </p>
          <p className={classes.termsText}>
            This arbitration agreement is intended to be broadly interpreted and will
            survive termination of these Terms.
            The arbitrator, and not any federal, state or local court or agency, shall
            have exclusive authority to the extent permitted by law to resolve all
            disputes arising out of or relating to the interpretation, applicability,
            enforceability or formation of this Agreement, including, but not limited to
            any claim that all or any part of this Agreement is void or voidable.
            There is no judge or jury in arbitration, and court review of an arbitration
            award is limited.
            However, an arbitrator can award on an individual basis the same damages and
            relief as a court (including injunctive and declaratory relief or statutory
            damages), and must follow these Terms as a court would.
          </p>
          <p className={classes.termsText}>
            To begin an arbitration proceeding, you must send a letter requesting
            arbitration and describing your claim to: URLIFE Media, Inc., 3524 Hayden
            Avenue, Los Angeles, California 90232 Attn: Chief Operating Officer.
            You may download the forms located
            at <a className={classes.termsLink} href="http://www.jamsadr.com">http://www.jamsadr.com</a>.
            The arbitration will be conducted by JAMS under its Streamlined Arbitration
            Rules and Procedures or, if applicable, its Comprehensive Arbitration Rules
            and Procedures, and any applicable supplemental rules including its Consumer
            Arbitration Standards of Minimum Fairness.
            The JAMS Rules are available online
            at <a className={classes.termsLink} href="http://www.jamsadr.com">http://www.jamsadr.com</a> or
            by calling (800) 352-5267.
            Payment of all filing, administration and arbitrator fees will be governed
            by {'JAMS\'s'} rules.
            You will be responsible for those fees, and in no event will we pay
            for {'attorneys\''} fees.
            You may choose to have the arbitration conducted by telephone, based on
            written submissions, or in person in the Los Angeles, California.
          </p>
          <p className={classes.termsText}>
            We each agree that the arbitrator may not consolidate more than
            one {'person\'s'} claims, and may not otherwise preside over any form of a
            representative or class proceeding, and that any dispute resolution
            proceedings will be conducted only on an individual basis and not in a class,
            consolidated or representative action.
            You agree to waive any right to a jury trial or to participate in a class action.
            If this specific provision is found to be unenforceable, then the entirety of
            this arbitration section will be null and void and neither of us will be
            entitled to arbitrate or dispute.
          </p>
          <p className={classes.termsText}>
            You agree that these Terms evidence a transaction involving interstate
            commerce and will be governed by and construed in accordance with federal
            law to the fullest extent possible.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We aim for 100% satisfaction.
              But if we have a dispute, it will be decided by arbitration in Los Angeles,
              California.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Termination/access restriction</h3>
          <p className={classes.termsText}>
            URLIFE reserves the right, in its sole discretion, to terminate your access
            to the Site and the related services or any portion thereof at any time,
            without notice.
            To the maximum extent permitted by law, this agreement is governed by the
            laws of the State of California and you hereby consent to the exclusive
            jurisdiction and venue of courts in California in all disputes arising out of
            or relating to the use of the Site.
            Use of the Site is unauthorized in any jurisdiction that does not give effect
            to all provisions of these Terms, including, without limitation, this section.
          </p>
          <p className={classes.termsText}>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and URLIFE as a result of this agreement or
            use of the Site. {'URLIFE\'s'} performance
            of this agreement is subject to existing laws and legal
            process, and nothing contained in this agreement is in derogation
            of {'URLIFE\'s'} right to comply with governmental, court and law enforcement
            requests or requirements relating to your use of the Site or information
            provided to or gathered by URLIFE with respect to such use.
            If any part of this agreement is determined to be invalid or unenforceable
            pursuant to applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the invalid or
            unenforceable provision will be deemed superseded by a valid, enforceable
            provision that most closely matches the intent of the original provision and
            the remainder of the agreement shall continue in effect.
          </p>
          <p className={classes.termsText}>
            Unless otherwise specified herein, this agreement constitutes the entire
            agreement between the user and URLIFE with respect to the Site and it
            supersedes all prior or contemporaneous communications and proposals, whether
            electronic, oral or written, between the user and URLIFE with respect to the
            Site.
            A printed version of this agreement and of any notice given in electronic
            form shall be admissible in judicial or administrative proceedings based upon
            or relating to this agreement to the same extent and subject to the same
            conditions as other business documents and records originally generated and
            maintained in printed form.
            It is the express wish to the parties that this agreement and all related
            documents be written in English.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              {'We\'re'} building a community and abuses of the system affects all of us
              and our ability to help our customers.
              If there are abuses, we have the right to stop working with you.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Changes to Terms</h3>
          <p className={classes.termsText}>
            We reserve the right to determine the form and means of providing
            notifications to you, and you agree to receive legal notices electronically
            if {'that\'s'} what we decide.
            We may revise these Terms from time to time and the most current version
            will always be posted on our website.
            If a revision, in our discretion, is material, {'we\'ll'} notify you.
            By continuing to access or use the Site after revisions become effective,
            you agree to be bound by the new Terms.
            If you don’t agree to the new terms, please stop using the Site.
          </p>
          <div className={classes.termsBox}>
            <h3 className={classes.termsBoxHeader}>WHAT THIS MEANS</h3>
            <p className={classes.termsText}>
              We have the right to update our Terms of Service on a random basis, as
              needed. {'We\'ll'} keep you posted and always be transparent with any changes.
            </p>
          </div>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Contact Us</h3>
          <p className={classes.termsText}>URLIFE welcomes your questions or comments regarding the Terms:</p>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>URLIFE Media</h3>
          <p className={classes.termsText}>3524 Hayden Avenue</p>
          <p className={classes.termsText}>Los Angeles, California 90232</p>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Email Address:</h3>
          <a className={classes.termsLink} href="mailto:help@urlifemedia.com">help@urlifemedia.com</a>
        </div>
        <div className={classes.termsTextBlock}>
          <h3 className={classes.termsSubTitle}>Telephone number:</h3>
          <p className={classes.termsText}>3107304501</p>
          <p className={classes.termsText}>Effective as of October 10, 2022</p>
        </div>
      </div>
    </div>
  }
}
Terms.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Terms)
