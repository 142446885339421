import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PromoCode ({ color, ...otherProps }) {
  const iconColor = color || '#4A30B5'
  return (
    <SvgIcon {...otherProps} viewBox='0 0 147 127'>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-359.000000, -986.000000)">
          <g transform="translate(361.000000, 868.000000)">
            <g transform="translate(0.000000, 133.000000)">
              <line x1="143" y1="41.2105263" x2="0" y2="41.2105263" id="Path" stroke={iconColor} strokeWidth="3"></line>
              <text fill={iconColor} fillRule="nonzero" fontFamily="Montserrat-SemiBold, Montserrat" fontSize="7" fontWeight="800">
                <tspan x="6.54248366" y="63.6105263">Promo Code</tspan>
              </text>
              <text id="XXXX" fill={iconColor} fillRule="nonzero" fontFamily="Montserrat-SemiBold, Montserrat" fontSize="6" fontWeight="800">
                <tspan x="6.54248366" y="72.6842105">XXXX</tspan>
              </text>
              <path d="M132.664659,0.168353027 L8.33205434,0.0168978875 C8.33205434,0.0168978875 -0.152180497,-0.891832952 0.00207831859,10.6187577 C0.156337134,22.1293483 0.156337134,79.2279361 0.156337134,79.2279361 C0.156337134,79.2279361 0.310595949,87.7094239 11.5714895,86.9521482 L132.818918,86.9521482 C132.818918,86.9521482 143,87.4065136 143,78.1677501 L143,7.2867446 C143,7.13528946 143,7.13528946 143,6.98383432 C142.691482,5.31782778 141.148894,-0.134557252 132.664659,0.168353027 Z" id="Path" stroke={iconColor} strokeWidth="3"></path>
              <line x1="91.5947712" y1="0.915789474" x2="91.5947712" y2="87" id="Path" stroke={iconColor} strokeWidth="3"></line>
              <path d="M106.505127,55.4903243 L91.9264292,40.4278477 C91.9264292,40.4278477 91.2993884,39.9804474 91.769669,40.7261145 L106.348367,55.7885912 C106.348367,55.7885912 106.661887,56.086858 106.505127,55.4903243 Z" id="Path" stroke={iconColor} strokeWidth="3" fill={iconColor} fillRule="nonzero"></path>
              <path d="M91.1317559,40.3356048 L76.7782795,55.4806305 C76.7782795,55.4806305 76.3152641,56.2303842 77.0869564,55.6305812 L91.5947712,40.6355063 C91.4404328,40.4855555 91.7491097,40.185654 91.1317559,40.3356048 Z" id="Path" stroke={iconColor} strokeWidth="3" fill={iconColor} fillRule="nonzero"></path>
              <path d="M90.4917836,40.3608752 C93.4104693,37.6699056 91.1816908,30.7037838 85.5136675,24.8016066 C79.8456443,18.8994294 74.4186857,18.3676242 71.5,21.0585937 C68.5813143,23.7495633 69.2761485,28.6442923 74.9441717,34.5464695 C80.612195,40.4486467 87.5730979,43.0518448 90.4917836,40.3608752 Z" id="Oval" stroke={iconColor} strokeWidth="3"></path>
              <path d="M108.06172,34.4267364 C113.572849,28.3979746 114.475286,22.739894 111.637391,19.9912109 C108.799496,17.2425279 103.295922,18.4441127 97.7847931,24.4728745 C92.2736636,30.5016362 90.1065789,37.6171611 92.9444738,40.3658442 C95.7823688,43.1145273 102.55059,40.4554981 108.06172,34.4267364 Z" id="Oval" stroke={iconColor} strokeWidth="3"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

PromoCode.propTypes = {
  color: PropTypes.string
}
