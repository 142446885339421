import React from 'react'
import PropTypes from 'prop-types'

const validateFunctions = {
  email: (field, fieldData) => {
    return !fieldData.value || /^.+@.+$/.test(fieldData.value)
  },
  passwordLength: (field, fieldData, compare, min) => fieldData.value.length >= min,
  passwordMatch: (field, fieldData, compareData) => {
    if (!compareData) {
      return false
    } else {
      return fieldData.value === compareData.value
    }
  },
  required: (field, fieldData) => fieldData.value.trim().length
}

const errors = {
  email: 'Incorrect email',
  passwordLength: 'Password is too short',
  passwordMatch: 'Passwords do not match',
  required: 'Field is required'
}

const getError = (errorName) => {
  return errors[errorName] || ''
}

class ValidateForm extends React.Component {
  constructor (props) {
    super(props)
    this.fields = []
    this.compareFields = []
    this.passwordMinLength = 0
    this.state = {
      fieldsData: {}
    }
  }

  getFieldData (fieldName) {
    return { ...(this.state.fieldsData[fieldName] || { errors: [], value: '' }) }
  }

  getField (fieldName) {
    return this.fields.filter(field => field.name === fieldName)[0] || null
  }

  setCompareFields () {
    const { compareFields } = this.props
    this.compareFields.push(...compareFields)
  }

  getCompareFieldData (fieldName) {
    const compareField = this.compareFields.filter(field => {
      return field !== fieldName
    })[0] || null
    if (!compareField) {
      return false
    } else {
      return this.getFieldData(compareField)
    }
  }

  renderField (fieldName, rules, renderFn) {
    if (!this.fields.filter(field => field.name === fieldName).length) {
      const field = {
        name: fieldName,
        onBlur: e => {
          const fieldData = this.getFieldData(field.name)
          this.setState({
            fieldsData: {
              ...this.state.fieldsData,
              [field.name]: {
                ...fieldData,
                changed: true,
                errors: this.validateField(field.name)
              }
            }
          })
        },
        onChange: e => {
          const fieldItemData = this.getFieldData(fieldName)
          this.setState({
            fieldsData: {
              ...this.state.fieldsData,
              [fieldName]: { ...fieldItemData, value: e.target.value }
            }
          }, () => {
            if (fieldItemData.changed) {
              this.setState({
                fieldsData: {
                  ...this.state.fieldsData,
                  [fieldName]: {
                    ...this.getFieldData(fieldName),
                    errors: this.validateField(fieldName)
                  }
                }
              })
            }
            this.props.onFieldChange && this.props.onFieldChange(field, this.getFieldData(field.name))
          })
        },
        rules: rules || rules
      }
      this.fields.push(field)
    }
    return renderFn(this.getField(fieldName), this.getFieldData(fieldName))
  }

  checkFormValid () {
    return this.fields.filter(field => this.getFieldData(field.name).errors.length).length === 0
  }

  validateField (fieldName) {
    const fieldData = this.getFieldData(fieldName)
    const field = this.getField(fieldName)
    const compareFieldData = this.getCompareFieldData(fieldName) || false
    const maxLength = this.passwordMinLength
    const errors = []
    if (field.rules && field.rules.length) {
      field.rules.forEach(ruleName => {
        if (
          validateFunctions[ruleName] &&
          !validateFunctions[ruleName](
            field,
            fieldData,
            compareFieldData,
            maxLength
          )) {
          errors.push(getError(ruleName))
        }
      })
    }
    return errors
  }

  validateForm (afterValidate = () => {}) {
    const newFieldsData = { ...this.state.fieldsData }

    this.fields.forEach(field => {
      newFieldsData[field.name] = {
        ...this.getFieldData(field.name),
        changed: true,
        errors: this.validateField(field.name)
      }
    })
    this.setState({
      fieldsData: newFieldsData
    }, afterValidate)
  }

  onSubmit (e) {
    e.preventDefault()
    this.validateForm(() => {
      this.props.onSubmit && this.checkFormValid() && this.props.onSubmit(e)
    })
  }

  componentDidMount () {
    if (this.props.validateOnMount) {
      this.validateForm()
    }
    if (this.props.compareFields) {
      this.setCompareFields()
    }
    if (this.props.passwordMinLength) {
      this.passwordMinLength = this.props.passwordMinLength
    }
  }

  render () {
    const { children } = this.props
    return <form action="" onSubmit={e => this.onSubmit(e)}>{children ? children(this) : null}</form>
  }
}

ValidateForm.propTypes = {
  children: PropTypes.func.isRequired,
  compareFields: PropTypes.array,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  passwordMinLength: PropTypes.number,
  validateOnMount: PropTypes.bool
}

export default ValidateForm
