import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CouponIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 27 27">
      <g><g><path d="M21.897 6.545c0 .794-.646 1.441-1.441 1.441-.794 0-1.44-.646-1.441-1.44 0-.795.647-1.442 1.442-1.442.794 0 1.44.646 1.44 1.441zm1.73 0a3.175 3.175 0 0 0-3.17-3.172h-.001a3.176 3.176 0 0 0-3.172 3.172 3.176 3.176 0 0 0 3.172 3.172 3.175 3.175 0 0 0 3.172-3.172zM9.95 26.67c.91 0 1.762-.352 2.398-.988l11.716-11.715a.865.865 0 1 0-1.224-1.224L11.123 24.459c-.31.31-.726.48-1.174.48h-.001c-.448 0-.865-.17-1.175-.48l-6.23-6.228c-.31-.31-.48-.728-.48-1.176.001-.448.172-.866.482-1.176l13.34-13.34a1.62 1.62 0 0 1 1.083-.476l6.317.004c.91 0 1.652.741 1.653 1.651v6.29c-.014.424-.183.819-.475 1.11a.865.865 0 1 0 1.222 1.226 3.378 3.378 0 0 0 .983-2.308V3.717a3.388 3.388 0 0 0-3.382-3.38L16.941.332a3.36 3.36 0 0 0-2.28.983L1.32 14.656a3.367 3.367 0 0 0-.988 2.398c0 .91.35 1.763.987 2.4l6.229 6.229a3.366 3.366 0 0 0 2.398.987h.002z" /></g></g>
    </SvgIcon>
  )
}
