import ApiService from '../services/ApiService'
import { api } from './../constants'
import { updateSystemFile, uploadFile } from './index'

export {
  addCategory,
  createCategoryFile,
  deleteCategory,
  getAllCategories,
  getCategory,
  updateCategory
}

function getAllCategories (filter) {
  return async dispatch => {
    dispatch({ type: 'GETTING_ALL_CATEGORIES' })
    try {
      const response = await new ApiService(api.CATEGORY, {}, { filter }, false, dispatch)
        .get()
      dispatch({ categories: response.data, type: 'GOT_ALL_CATEGORIES' })
      return response.data
    } catch (e) {
      dispatch({ e, type: 'GET_ALL_CATEGORIES_FAILED' })
    }
  }
}

function getCategory (categoryId) {
  return dispatch => {
    return new ApiService(api.ONE_CATEGORY, { categoryId }, {}, true, dispatch)
      .get()
  }
}

function updateCategory (categoryId, categoryData) {
  return async dispatch => {
    const response = await new ApiService(api.ONE_CATEGORY, { categoryId }, {}, true, dispatch)
      .put(categoryData)
    dispatch({ category: response.data[0], type: 'UPDATE_CATEGORY' })
    return response.data[0]
  }
}

function addCategory (categoryData) {
  return async dispatch => {
    const response = await new ApiService(api.CATEGORY, {}, {}, true, dispatch)
      .post(categoryData)
    dispatch({ category: response.data[0], type: 'ADD_CATEGORY' })
    return response.data[0]
  }
}

function deleteCategory (categoryId) {
  return async dispatch => {
    const response = await new ApiService(api.ONE_CATEGORY, { categoryId }, {}, true, dispatch)
      .delete()
    dispatch({ categoryId, type: 'DELETE_CATEGORY' })
    return response.data[0]
  }
}

function createCategoryFile (fileType, file, fileObject) {
  return dispatch => {
    return new ApiService(api.SYSTEM_FILES, { fileType }, {}, true, dispatch)
      .post(file)
      .then(
        response => {
          const responseFileData = response.data[0]
          return dispatch(uploadFile(
            null,
            fileObject,
            responseFileData.id,
            responseFileData.fileExt,
            'category',
            'system',
            'system/' + responseFileData.id + '.' + responseFileData.fileExt,
            (cognitoInfo) => {
              return dispatch(updateSystemFile(
                { id: responseFileData.id, status: 3 },
                cognitoInfo.IdentityId)
              )
            }
          ))
        }
      )
  }
}
