import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import {
  CheckMark
} from './SVG'

/* eslint-disable sort-keys */
const styles = {
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: '#414141',
    lineHeight: '26px'
  },
  icon: {
    position: 'relative',
    width: 22,
    height: 22,
    borderRadius: 2,
    '&._unchecked': {
      border: '1px solid #cccccc',
      'input:hover ~ &': {
        border: '1px solid #999999'
      }
    },
    '&._checked': {
      backgroundColor: '#01b7d7'
    }
  },
  checkMark: {
    position: 'absolute',
    left: 4,
    top: 4,
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
    color: '#999999'
  }
}
/* eslint-enable sort-keys */

class UrlifeCheckbox extends React.Component {
  render () {
    const {
      classes,
      className,
      iconClassName,
      labelClassName,
      label,
      ...otherProps
    } = this.props
    const iconClass = iconClassName ? classNames(classes.icon, iconClassName) : classes.icon
    const labelClass = labelClassName ? classNames(classes.label, labelClassName) : classes.label
    return (
      <div className={className}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid='ul-checkbox'
              inputProps={{ 'data-testid': 'ul-checkbox-input' }}
              icon={
                <span
                  data-testid='ul-checkbox-unchecked'
                  className={classNames(iconClass, '_unchecked')}
                />
              }
              checkedIcon={
                <span
                  data-testid='ul-checkbox-checked'
                  className={classNames(iconClass, '_checked')}
                >
                  <CheckMark className={classes.checkMark} />
                </span>
              }
              {...otherProps} />
          }
          label={<Typography className={labelClass}>{label}</Typography>}
        />
      </div>
    )
  }
}

UrlifeCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string
}

export default withStyles(styles)(UrlifeCheckbox)
