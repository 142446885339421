import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  history
} from '../../../../helpers'
import UrlifeList from './UrlifeList'
import {
  DownloadIcon,
  SaveExitIcon,
  TrashIcon
} from '../../SVG/index'
import {
  closeMoreMenu
} from '../../../../actions'
import UrlifeConfirmDialog from '../../UrlifeConfirmDialog'

class ProjectMoreMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmDeleteOpen: false
    }
  }

  async handleClick (action) {
    const {
      saveProject,
      downloadProject,
      closeMoreMenuFunction
    } = this.props
    switch (action) {
      case 'saveAndExit':
        saveProject && await saveProject()
        history.push('/home')
        closeMoreMenuFunction()
        break
      case 'delete':
        this.setState({ confirmDeleteOpen: true })
        break
      case 'download':
        downloadProject && await downloadProject()
        closeMoreMenuFunction()
        break
      case 'exit':
        history.push('/home')
        closeMoreMenuFunction()
        break
      default:
        break
    }
  }

  async deleteProject () {
    const {
      deleteProject,
      closeMoreMenuFunction
    } = this.props
    deleteProject && await deleteProject()
    closeMoreMenuFunction()
    history.goBack()
  }

  render () {
    const {
      saveProject,
      downloadProject,
      deleteProject
    } = this.props
    const { confirmDeleteOpen } = this.state
    const items = []
    if (saveProject) {
      items.push({
        action: 'saveAndExit',
        icon: <SaveExitIcon />,
        text: 'save & exit',
        type: 'list-item'
      })
    }
    if (downloadProject) {
      items.push({
        action: 'download',
        icon: <DownloadIcon />,
        text: 'download project',
        type: 'list-item'
      })
    }
    if (deleteProject) {
      items.push({
        action: 'delete',
        icon: <TrashIcon />,
        text: 'delete',
        type: 'list-item'
      })
    }
    if (items.length === 0) {
      items.push({
        action: 'exit',
        icon: <SaveExitIcon />,
        text: 'exit',
        type: 'list-item'
      })
    }
    return (
      <React.Fragment>
        <UrlifeList
          items={items}
          handleClick={this.handleClick.bind(this)}
        />
        {/* Confirm delete dialog */ }
        <UrlifeConfirmDialog
          isOpen={confirmDeleteOpen}
          header={'Are you sure you want to delete this project from your Gallery?'}
          text={'Deleting will permanently remove all your content from this project'}
          onIgnore={() => { this.setState({ confirmDeleteOpen: false }) }}
          cancelLabel={'Keep project'}
          onCancel={() => { this.setState({ confirmDeleteOpen: false }) }}
          confirmLabel={'Delete'}
          onConfirm={this.deleteProject.bind(this)}
        />
      </React.Fragment>
    )
  }
}

ProjectMoreMenu.propTypes = {
  closeMoreMenuFunction: PropTypes.func.isRequired,
  deleteProject: PropTypes.func,
  downloadProject: PropTypes.func,
  saveProject: PropTypes.func
}

function mapDispatchToProps (dispatch) {
  return {
    closeMoreMenuFunction: function () {
      dispatch(closeMoreMenu())
    }
  }
}

export default connect(null, mapDispatchToProps)(ProjectMoreMenu)
