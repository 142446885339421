import { api } from './../constants'
import ApiService from '../services/ApiService'

export {
  createCoupon,
  fetchUserCoupons,
  deleteCoupon
}

function fetchUserCoupons (userId, params = {}) {
  return dispatch => {
    return new ApiService(api.COUPONS, { userId }, params, true, dispatch)
      .get()
  }
}

function createCoupon (userId, couponData) {
  return dispatch => {
    return new ApiService(api.COUPONS, { userId }, {}, true, dispatch)
      .post(couponData)
  }
}

function deleteCoupon (userId, couponId) {
  return dispatch => {
    return new ApiService(api.ONE_COUPON, { couponId, userId }, {}, true, dispatch)
      .delete()
  }
}
