import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PackageIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><path fill="#999" d="M9.84 10.054a.754.754 0 0 0 0 1.507h5.632a.754.754 0 0 0 0-1.507zM22.939 5.57c0 .33-.268.598-.597.598H2.97a.598.598 0 0 1-.597-.598V2.305c0-.33.268-.597.598-.597H22.34c.33 0 .597.268.597.597zM22.341.2H2.97C1.812.2.868 1.145.868 2.305v3.266c0 1.16.944 2.105 2.105 2.105h.001v16.361c0 .417.338.754.754.754h17.858a.754.754 0 0 0 .754-.753V10.694a.754.754 0 0 0-1.507 0v12.59H4.48V7.676h16.35v.428a.754.754 0 0 0 1.508 0v-.428h.001c1.161 0 2.105-.944 2.105-2.105V2.305c0-1.16-.944-2.105-2.104-2.105z"/></g></g>
    </SvgIcon>
  )
}

PackageIcon.propTypes = {
  color: PropTypes.string
}
