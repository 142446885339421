import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeButton from '../../common/UrlifeButton'
import {
  listProjects,
  listUsers,
  updateProjectSku,
  userInfoById
} from '../../../actions'
import {
  commonStyles,
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  history,
  toLocale
} from '../../../helpers'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import AdminActionDialog from '../AdminActionDialog'
import {
  PROJECT_STATUSES,
  ROLES
} from '../../../constants'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  titleText: {
    color: '#000',
    margin: '10px 0px 30px 10px'
  },
  projectsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: 10,
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  projectsRow: {
    cursor: 'pointer',
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  projectsCol: {
    padding: 10,
    display: 'table-cell'
  },
  button: {
    margin: 5
  }
}
/* eslint-enable sort-keys */

class NegotiationList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      initialFetchDone: false,
      isProjectsLoading: false,
      nextProject: null,
      price: false,
      projects: [],
      queued: false,
      selectedProject: null,
      users: []
    }
    this.matchUser = this.matchUser.bind(this)
  }

  onComplete () {
    const id = this.state.selectedProject.id
    history.push(`/projects/${id}/summary`)
  }

  openDialog (e, project, type) {
    e.stopPropagation()
    this.setState({ selectedProject: project, [type]: true })
  }

  closeDialog (type) {
    this.setState({ selectedProject: null, [type]: false })
  }

  toProject (e, id) {
    e.stopPropagation()
    history.push(`/projects/${id}/summary`)
  }

  async updateProjectSku (updateFields = {}) {
    const { updateProjectSkuFunction } = this.props
    const { selectedProject } = this.state
    const projectSku = getLeastCompleteProjectSku(selectedProject)
    if (projectSku) {
      const projectSkuRes = await updateProjectSkuFunction(selectedProject.id, { id: projectSku.id, ...updateFields })
      const skuIndex = selectedProject.projectSkus.findIndex(sku => sku.id === projectSku.id)
      selectedProject.projectSkus[skuIndex] = projectSkuRes
      this.setState({ selectedProject })
    }
  }

  matchUser (userId) {
    const { users } = this.state
    const selectedUser = users.find(user => user.id === userId)
    return selectedUser ? `${selectedUser.fullname}, ${selectedUser.email}` : 'loading...'
  }

  fetchMore () {
    const { nextProject, initialFetchDone } = this.state
    if (!initialFetchDone) {
      if (nextProject && this.state.projects.length < 100) {
        this.fetchProjects()
      } else {
        this.setState({ initialFetchDone: true })
      }
    }
  }

  fetchProjects () {
    const { nextProject: cursor, projects, users } = this.state
    const { listProjectsFunction } = this.props
    const params = { count: 100, cursor, method: 'DESC', sort: 'createdAt' }
    this.setState(
      { isProjectsLoading: true },
      () => {
        listProjectsFunction(params)
          .then(
            response => {
              const projectsInNegotiation = response.data.filter(project => (
                getLeastCompleteProjectSkuStatus(project) === PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION
              ))
              const newUsers = []
              projectsInNegotiation.forEach(project => {
                if (!users.find(user => user.id === project.customerId)) {
                  if (!newUsers.includes(project.customerId)) {
                    newUsers.push(project.customerId)
                  }
                }
              })
              if (newUsers.length > 0) {
                this.fetchUser(newUsers)
              }
              this.setState({
                isProjectsLoading: false,
                nextProject: response.nextCursor,
                projects: [...projects, ...projectsInNegotiation]
              }, this.fetchMore)
            }
          )
      }
    )
  }

  async fetchUser (userIds) {
    const { users } = this.state
    const { userInfoByIdFunction } = this.props
    const promises = userIds.map(user => userInfoByIdFunction(user))
    Promise.all(promises).then(res => {
      const newUsers = res.map(item => item.data)
      this.setState({ users: [...users, ...newUsers] })
    })
  }

  fetchInitialUsers () {
    const { listUsersFunction } = this.props
    const params = { count: 100, queryRole: ROLES.USER }
    listUsersFunction(params).then(
      response => {
        this.setState({ users: response.data }, this.fetchProjects)
      })
  }

  componentDidMount () {
    this.fetchInitialUsers()
  }

  render () {
    const { classes } = this.props
    const {
      projects,
      users,
      nextProject,
      isProjectsLoading,
      queued,
      price
    } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h5" component="h3" className={classes.titleText}>
            <span>Projects in negotiation status</span>
          </Typography>
          {projects && users.length > 0 &&
          <UrlifeInfiniteScroller
            loadMore={this.fetchProjects.bind(this)}
            hasMore={!!nextProject}
            isLoading={isProjectsLoading}
            isEmpty={!projects.length}
            className={classes.projectsTable}
          >
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Project title</div>
              <div className={classes.headerCol}>Created Date</div>
              <div className={classes.headerCol}>User</div>
              <div className={classes.headerCol}>Actions</div>
              <div className={classes.headerCol}></div>
            </div>
            {projects.map(project => (
              <div
                key={project.id}
                className={classes.projectsRow}
                onClick={e => this.toProject(e, project.id)}
              >
                <div className={classes.projectsCol}>
                  {project.title}
                </div>
                <div className={classes.projectsCol}>
                  {toLocale(new Date(project.createdAt), { full: true })}
                </div>
                <div className={classes.projectsCol}>
                  {this.matchUser(project.customerId)}
                </div>
                <UrlifeButton
                  inverted
                  onClick={e => this.openDialog(e, project, 'price')}
                  className={classes.button}
                >
                  Set price
                </UrlifeButton>
                <UrlifeButton
                  onClick={e => this.openDialog(e, project, 'queued')}
                  className={classes.button}
                >
                  Move to production queue
                </UrlifeButton>
              </div>
            ))}
          </UrlifeInfiniteScroller>
          }
        </Paper>
        <AdminActionDialog
          type='price'
          isOpen={price}
          updateProjectSku={this.updateProjectSku.bind(this)}
          onClose={() => this.closeDialog('price')}
          onComplete={this.onComplete.bind(this)}
        />
        <AdminActionDialog
          type='queued'
          isOpen={queued}
          updateProjectSku={this.updateProjectSku.bind(this)}
          onClose={() => this.closeDialog('queued')}
          onComplete={this.onComplete.bind(this)}
        />
      </div>
    )
  }
}

NegotiationList.propTypes = {
  classes: PropTypes.object.isRequired,
  listProjectsFunction: PropTypes.func.isRequired,
  listUsersFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired,
  userInfoByIdFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    listProjectsFunction: function (params) {
      return dispatch(listProjects(params))
    },
    listUsersFunction: function (params) {
      return dispatch(listUsers(params))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    },
    userInfoByIdFunction: async function (id) {
      return dispatch(userInfoById(id))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(NegotiationList))
