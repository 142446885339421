import { DEBUG_LEVEL } from '../constants'
import forge from 'node-forge'

export async function createFileFingerprint (file) {
  if (DEBUG_LEVEL === 'fingerprint') {
    /* eslint-disable-next-line no-console */
    console.debug('START CREATING FINGERPRINT: ' +
      (Date.now() - window.sessionStorage.getItem('debug_time_start')))
  }

  let fingerprint = ''
  const chunkCount = 4
  const hashSize = 16384 // NOTE: Must be evenly divisible by chunkCount
  const chunkSize = hashSize / chunkCount
  const hashData = new Uint8Array(hashSize)
  for (let i = 0; i < chunkCount; i++) {
    const reader = new window.FileReader()
    const setOffset = i * chunkSize
    const offset = hashData[setOffset] + Math.floor((i / chunkCount) * file.size)
    const blob = file.slice(offset, offset + chunkSize)
    if (blob.size === 0) {
      if (DEBUG_LEVEL === 'fingerprint') {
        /* eslint-disable-next-line no-console */
        console.debug('BLOB FAIL: ' +
          (Date.now() - window.sessionStorage.getItem('debug_time_start')))
      }
      return ''
    }
    let readSuccess = false
    await new Promise((resolve) => {
      reader.addEventListener('loadend', () => {
        readSuccess = reader.result.byteLength > 0
        if (readSuccess) {
          hashData.set(new Uint8Array(reader.result), setOffset)
        }
        resolve()
      })
      reader.addEventListener('abort', () => {
        resolve()
      })
      reader.addEventListener('error', () => {
        resolve()
      })
      reader.readAsArrayBuffer(blob)
    })
    if (!readSuccess) {
      if (DEBUG_LEVEL === 'fingerprint') {
        /* eslint-disable-next-line no-console */
        console.debug('FILE READ FAIL: ' +
          (Date.now() - window.sessionStorage.getItem('debug_time_start')))
      }
      return ''
    }
  }
  if (DEBUG_LEVEL === 'fingerprint') {
    /* eslint-disable-next-line no-console */
    console.debug('FILE READ DONE: ' +
      (Date.now() - window.sessionStorage.getItem('debug_time_start')))
  }

  const hashString = String.fromCharCode.apply(null, hashData)
  const sha256 = forge.md.sha256.create()
  sha256.update(hashString)
  const md5 = forge.md.md5.create()
  md5.update(hashString)
  fingerprint = sha256.digest().toHex() + md5.digest().toHex()

  if (DEBUG_LEVEL === 'fingerprint') {
    /* eslint-disable-next-line no-console */
    console.debug('FINGERPRINT CREATED: ' +
      (Date.now() - window.sessionStorage.getItem('debug_time_start')))
  }

  return fingerprint
}
