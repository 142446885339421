import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  createInspiration,
  createInspirationFile,
  deleteInspiration,
  fetchInspiration,
  fetchSingleInspiration,
  updateInspiration
} from '../../actions'
import { fileProgressStatus } from '../../reducers/fileProgress'
import EditTable from './EditTable/EditTable'

const videoMinRes = {
  height: 439,
  width: 780
}
const imageMinRes = {
  aspectX: 16,
  aspectY: 10,
  height: 350,
  width: 560
}

class EditInspiration extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSortAnimationId: false,
      creatingNewItem: false,
      duplicateDisplayOrder: [],
      items: [],
      newItem: false,
      sortAnimationToBottomId: false,
      sortAnimationToTopId: false,
      thumbWait: false
    }
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }

      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
    this.fieldSettings = [
      {
        default: true,
        field: 'isHidden',
        name: 'Active',
        type: 'checkboxInverted',
        width: 70
      },
      {
        field: 'id',
        name: 'ID',
        type: 'string',
        width: 100
      },
      {
        field: 'title',
        name: 'Title',
        type: 'text'
      },
      {
        field: 'subtitle',
        name: 'Subtitle',
        required: false,
        type: 'text'
      },
      {
        field: 'displayOrder',
        name: 'Order',
        type: 'sort',
        width: 200
      }
    ]
    this.existingFieldSettings = [
      {
        acceptFiles: '.mp4',
        field: 'video',
        name: 'Video',
        type: 'uploadFile',
        width: 150
      },
      {
        field: 'image',
        name: 'Image',
        type: 'uploadFile',
        width: 150
      }
    ]
    this.bogusFields = [
      {
        field: '',
        name: '',
        type: 'string',
        width: 150
      }
    ]
  }

  /**
   * @param {string|number} id
   * @param {object} fieldsData
   * @param {boolean} [apply] - save to sever after updating data
   * @param {boolean} [needSort] - sort after changing
   */
  onChangeField (id, fieldsData, apply = false, needSort = false, callback = null) {
    const { items, duplicateDisplayOrder } = this.state

    const newStateData = {}

    newStateData.items = items.map(field => (
      field.id === id
        ? { ...field, ...fieldsData }
        : field
    ))
    if (needSort) newStateData.items = newStateData.items.sort(this.compareFields)

    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = items.filter(it => {
        return it.id !== id && it.displayOrder === +fieldsData.displayOrder
      }).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(id)
        ? duplicateDisplayOrder.concat(id)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== id)
    }

    this.setState(newStateData, callback)

    if (apply) this.applyChanges(id, fieldsData)
  }

  /* exchange 2 items positions with animation */
  exchangeFieldOrderPosition (currentItemIndex, changing) {
    const { items } = this.state

    const exchangeItemIndex = currentItemIndex + changing
    const currentItemPosition = items[currentItemIndex].displayOrder
    const exchangeItemPosition = items[exchangeItemIndex].displayOrder
    const target = changing > 0 ? 'bottom' : 'top'

    // start animation
    this.setState({
      activeSortAnimationId: items[currentItemIndex].id,
      sortAnimationToBottomId: items[target === 'bottom' ? currentItemIndex : exchangeItemIndex].id,
      sortAnimationToTopId: items[target === 'top' ? currentItemIndex : exchangeItemIndex].id
    })

    // end animation with timeout
    window.setTimeout(() => {
      this.setState({
        activeSortAnimationId: false,
        items: items.map((field, fieldIndex) => {
          if (fieldIndex === currentItemIndex) return { ...field, displayOrder: exchangeItemPosition }
          if (fieldIndex === exchangeItemIndex) return { ...field, displayOrder: currentItemPosition }
          return field
        }).sort(this.compareFields),
        sortAnimationToBottomId: false,
        sortAnimationToTopId: false
      })
    }, 600)

    // apply
    if (items[currentItemIndex].id) {
      this.applyChanges(
        items[currentItemIndex].id,
        { displayOrder: exchangeItemPosition }
      )
    }
    if (items[exchangeItemIndex].id) {
      this.applyChanges(
        items[exchangeItemIndex].id,
        { displayOrder: currentItemPosition }
      )
    }
  }

  // check filing items fields
  validateItem (item) {
    const { duplicateDisplayOrder } = this.state
    // filter errors
    return this.fieldSettings.filter(fieldSettingsItem => {
      let isValid = true;
      [].concat(fieldSettingsItem.field).forEach(fieldName => {
        if (fieldName === 'id') return false
        if (item[fieldName].toString().length === 0 &&
          !(Object.keys(fieldSettingsItem).includes('required') && fieldSettingsItem.required === false)) {
          isValid = false
        }
      })

      return !isValid
    }).length === 0 && !duplicateDisplayOrder.includes(item.tempId || item.id)
  }

  onChangeNewItem (fieldsData) {
    const { newItem, items, duplicateDisplayOrder } = this.state

    const newStateData = {
      newItem: { ...newItem, ...fieldsData }
    }
    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = fieldsData.displayOrder && items.filter(it => {
        return it.id !== newItem.tempId && it.displayOrder === +fieldsData.displayOrder
      }).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(newItem.tempId)
        ? duplicateDisplayOrder.concat(newItem.tempId)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== newItem.tempId)
    }
    this.setState(newStateData)
  }

  /**
   * @param {string|null} id
   * @param {object} fieldsData
   */
  applyChanges (id, fieldsData) {
    const { updateInspirationFunction } = this.props
    updateInspirationFunction(id, fieldsData)
  }

  /**
   * add new item without saving
   * */
  addNewTempItem () {
    const { items } = this.state
    const sortOrderValues = items.map(item => +item.displayOrder)
    const maxSortOrder = items.length ? Math.max(...sortOrderValues) : 0

    const newItem = this.fieldSettings.reduce((ob, fieldSettingsItem) => {
      [].concat(fieldSettingsItem.field).forEach(fieldName => {
        switch (fieldName) {
          case 'displayOrder':
            ob[fieldName] = maxSortOrder + 100
            break
          case 'date':
            // need ISO format for moment library correct working
            ob[fieldName] = new Date().toISOString()
            break
          default:
            ob[fieldName] = typeof fieldSettingsItem.default !== 'undefined' ? fieldSettingsItem.default : ''
        }
      })
      return ob
    }, {
      tempId: `newItem-${Date.now()}`
    })
    this.setState({ newItem })
  }

  onSubmitNewItem (callback = null) {
    const { newItem: { tempId, id, ...useItem } } = this.state
    const { addInspirationFunction } = this.props

    this.setState({
      creatingNewItem: true
    }, () => {
      addInspirationFunction(useItem)
        .then(
          response => {
            const responseNewItem = response.data[0]
            const newState = {
              creatingNewItem: false,
              newItem: false
            }
            if (responseNewItem && responseNewItem.id) {
              newState.items = this.state.items
                .concat(responseNewItem)
            }
            this.setState(
              newState,
              () => {
                callback && callback(responseNewItem.id)
              }
            )
          }
        )
    })
  }

  onDeleteItem (id) {
    const { deleteInspirationFunction } = this.props

    this.setState({
      items: this.state.items.filter(item => item.id !== id)
    })
    deleteInspirationFunction(id)
  }

  uploadFile (id, file, field) {
    const { createInspirationFileFunction } = this.props
    const fileInfo = { mimeType: file.type }
    this.setState({ updatedRow: id, updatedType: field })
    createInspirationFileFunction(
      field === 'Image' ? 'inspirationImage' : 'inspirationVideo',
      { files: [fileInfo], inspirationId: id, type: 'file' },
      file
    )
  }

  getUpdatedRow (times = 0) {
    const { updatedRow, updatedType, items } = this.state
    const { getInspirationFunction } = this.props
    this.setState({ thumbWait: true })
    getInspirationFunction(updatedRow).then(
      row => {
        if ((updatedType === 'Image' && row.data[0].imageThumbnailUrl) ||
          (updatedType === 'Video' && row.data[0].videoThumbnailUrl)) {
          this.setState({
            items: items.map(vid => {
              this.setState({ thumbWait: false })
              if (vid.id === row.data[0].id) return row.data[0]
              return vid
            })
          })
        } else if (times < 30) {
          this.time = window.setTimeout(() => this.getUpdatedRow(++times), 500)
        } else {
          this.time = window.setTimeout(() => this.getUpdatedRow(++times), 10000)
        }
      }
    )
  }

  componentWillUnmount () {
    if (this.time) {
      window.clearTimeout(this.time)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { fileProgress } = this.props
    const oldInspirationUploads = prevProps.fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'inspiration' &&
      file.status === fileProgressStatus.inProgress)
    const newInspirationUploads = fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'inspiration' &&
      file.status === fileProgressStatus.inProgress)
    if (oldInspirationUploads.length > 0 &&
      newInspirationUploads.length === 0) {
      this.getUpdatedRow()
    }
  }

  componentDidMount () {
    const { getAllInspirationFunction } = this.props
    getAllInspirationFunction()
      .then(response => this.setState({
        items: response.data
      }))
  }

  render () {
    const {
      items,
      newItem,
      creatingNewItem,
      sortAnimationToTopId,
      sortAnimationToBottomId,
      activeSortAnimationId,
      duplicateDisplayOrder,
      thumbWait
    } = this.state
    const fieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.existingFieldSettings[0],
      this.existingFieldSettings[1],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4]

    ]
    const newFieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.bogusFields[0],
      this.bogusFields[0],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4]
    ]

    return (
      <EditTable
        title={'Edit Inspiration Videos'}
        videoMinRes={videoMinRes}
        imageMinRes={imageMinRes}
        message={thumbWait ? 'Waiting for file to process and generate a thumbnail.' : ''}
        items={items}
        fieldList={fieldList}
        newItem={newItem}
        newFieldList={newFieldList}
        newItemIsValid={!creatingNewItem && newItem && this.validateItem(newItem)}
        sortAnimationToTopId={sortAnimationToTopId}
        sortAnimationToBottomId={sortAnimationToBottomId}
        activeSortAnimationId={activeSortAnimationId}
        duplicateDisplayOrder={duplicateDisplayOrder}
        onChangeField={this.onChangeField.bind(this)}
        exchangeFieldOrderPosition={this.exchangeFieldOrderPosition.bind(this)}
        uploadFile={this.uploadFile.bind(this)}
        onDeleteItem={this.onDeleteItem.bind(this)}
        deleteHeader={'Are you sure you want to delete this inspiration video?'}
        deleteMessage={
          'An inspiration should have been inactive for at least 30 minutes to ensure a good user experience'
        }
        addNewTempItem={this.addNewTempItem.bind(this)}
        newButtonLabel={'New video'}
        onChangeNewItem={this.onChangeNewItem.bind(this)}
        onSubmitNewItem={this.onSubmitNewItem.bind(this)}
      />
    )
  }
}

EditInspiration.propTypes = {
  addInspirationFunction: PropTypes.func.isRequired,
  createInspirationFileFunction: PropTypes.func.isRequired,
  deleteInspirationFunction: PropTypes.func.isRequired,
  fileProgress: PropTypes.array,
  getAllInspirationFunction: PropTypes.func.isRequired,
  getInspirationFunction: PropTypes.func.isRequired,
  updateInspirationFunction: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    fileProgress: Object.values(state.fileProgress)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addInspirationFunction: function (data) {
      return dispatch(createInspiration(data))
    },
    createInspirationFileFunction: function (type, file, fileObject) {
      return dispatch(createInspirationFile(type, file, fileObject))
    },
    deleteInspirationFunction: function (id) {
      return dispatch(deleteInspiration(id))
    },
    getAllInspirationFunction: function () {
      return dispatch(fetchInspiration({ filter: 'all' }))
    },
    getInspirationFunction: function (id) {
      return dispatch(fetchSingleInspiration(id))
    },
    updateInspirationFunction: function (id, data) {
      return dispatch(updateInspiration(id, data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInspiration)
