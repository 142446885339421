import React from 'react'
import PropTypes from 'prop-types'
import UrlifeTextField from '../../common/UrlifeTextField'
import { withStyles } from '@material-ui/core'
import { TrashIcon, UserIcon } from '../../common/SVG'
import classNames from 'classnames'
import { commonStyles } from '../../../helpers'
import { getImageRotationCss } from '../../../helpers/imageRotationCss'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import iconStar from '../../../assets/img/castIcon.svg'

/* eslint-disable sort-keys */
const styles = theme => ({
  createMovieInputBlock: {
    margin: 15,
    width: 'calc(33.333333% - 30px)',
    position: 'relative',
    fontSize: 17,
    lineHeight: 1.7,
    '&._createProject': {
      width: '100%'
    },
    '&._projectDetails': {
      width: '100%',
      minHeight: 36,
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      marginBottom: 0
    },
    ...commonStyles.media(767, {
      width: 'calc(50% - 30px)'
    }),
    ...commonStyles.media(575, {
      width: '100%',
      margin: '10px 0'
    })
  },
  inputWrapper: {
    width: '100%'
  },
  editStarBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    paddingTop: 3,
    paddingBottom: 22,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    '&._projectDetails': {
      paddingTop: 15,
      paddingBottom: 0
    }
  },
  editStarBarRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    paddingTop: 3,
    paddingBottom: 22,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    '&._projectDetails': {
      paddingTop: 15,
      paddingBottom: 0
    }
  },
  createMovieLabel: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    marginTop: 'auto',
    marginRight: 10,
    '&._withImage': {
      backgroundSize: 'cover',
      position: 'relative',
      top: -10
    },
    '&._disable': {
      opacity: 0.5,
      pointerEvents: 'none',
      cursor: 'default'
    },
    '&._projectDetails': {
      position: 'relative',
      width: 39,
      height: 36,
      top: -35,
      marginRight: 15
    },
    '&._noEdit': {
      pointerEvents: 'none',
      cursor: 'default',
      top: 'auto',
      width: 36
    }
  },
  trashButton: {
    cursor: 'pointer',
    display: 'block',
    width: 18,
    margin: '30px 3px 0',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    }
  },
  trashButtonLeft: {
    fontSize: 17,
    height: 32,
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    }
  },
  trashButtonArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
    cursor: 'pointer',
    color: '#000',
    width: 18,
    margin: '35px 0 20px 0'
  },
  createMovieIcon: {
    marginTop: 5,
    cursor: 'pointer',
    width: '100%',
    maxHeight: '24px'
  },
  userIcon: {
    width: 'calc(100% + 4px)',
    height: 'calc(100% + 4px)',
    marginLeft: -2,
    marginTop: -2
  },
  userIconBackground: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: '#d8d8d8',
    '&._createMovie': {
      marginTop: -10
    }
  }
})
/* eslint-enable sort-keys */

class StarsMovieInput extends React.Component {
  photoUpload (e) {
    const file = e.target.files[0]
    this.props.onImageChange(file)
  }

  render () {
    const {
      classes,
      num,
      cast,
      onChange,
      onDelete,
      isDeletable,
      onBlur,
      type,
      thumbnail
    } = this.props
    let imageUrl = ''
    let hasThumbnail = false
    if (type !== 'projectDetails' && !(cast && cast.castFileId)) {
      imageUrl = String(iconStar)
    }
    if (thumbnail) {
      imageUrl = thumbnail
      hasThumbnail = true
    } else if (cast) {
      const castImageUrl =
        cast.thumbnailCdnUrl ||
        (cast.signedThumbnailUrl && cacheSignedUrl.get(cast.signedThumbnailUrl)) ||
        cast.cdnUrl
      if (castImageUrl && !(/.*\.(HEIC|heic|HEIF|heif)$/.test(castImageUrl))) {
        imageUrl = castImageUrl
        hasThumbnail = true
      }
    }
    return (
      <div
        className={
          classNames(
            classes.createMovieInputBlock,
            { _createProject: type === 'createProject' },
            { _projectDetails: type.startsWith('projectDetails') }
          )
        }>
        {type.startsWith('projectDetails') &&
        <React.Fragment>
          <label
            htmlFor={`fileUpload${num}`}
            className={classNames([
              classes.createMovieLabel,
              '_projectDetails',
              { _withImage: hasThumbnail },
              { _noEdit: type === 'projectDetails' }
            ])}
            style={
              {
                backgroundImage: `url(${imageUrl})`,
                ...getImageRotationCss(hasThumbnail ? 0 : cast.orientation)
              }
            }>
            {!imageUrl &&
            <div className={classes.userIconBackground}>
              <UserIcon className={classes.userIcon} stroke={'#b1b1b1'} />
            </div>
            }
          </label>
          {type === 'projectDetails' &&
          <div>
            {(cast && cast.name)}
          </div>
          }
          {type === 'projectDetailsEditing' &&
          <div className={classes.inputWrapper}>
            <UrlifeTextField
              autoComplete={'off'}
              label={'Name'}
              onChange={onChange}
              onBlur={onBlur}
              id="read-only-input"
              defaultValue={(cast && cast.name) || ''}
              margin="normal"
              placeholder="Star or supporting cast"
            />
            <div className={classNames(classes.editStarBar, '_projectDetails')}>
              {isDeletable &&
                <TrashIcon
                  onClick={onDelete}
                  className={classes.trashButton}
                />
              }
            </div>
          </div>
          }
        </React.Fragment>
        }
        {!type.startsWith('projectDetails') &&
        <React.Fragment>
          <UrlifeTextField
            autoComplete={'off'}
            label={'Name'}
            onChange={onChange}
            onBlur={onBlur}
            id="read-only-input"
            defaultValue={(cast && cast.name) || ''}
            margin="normal"
            placeholder="Star or supporting cast"
          />
          <div className={classes.editStarBarRow}>
            <label
              htmlFor={`fileUpload${num}`}
              className={classNames([
                classes.createMovieLabel,
                { _withImage: hasThumbnail }
              ])}
              style={
                {
                  backgroundImage: `url(${imageUrl})`,
                  ...getImageRotationCss(hasThumbnail ? 0 : cast.orientation)
                }
              }>
              {!imageUrl &&
              <div className={classNames(classes.userIconBackground, '_createMovie')}>
                <UserIcon className={classes.userIcon} stroke={'#b1b1b1'} />
              </div>}
            </label>
            {isDeletable &&
              <div onClick={onDelete} className={classes.trashButtonArea}>
                <TrashIcon className={classes.trashButtonLeft} />
              </div>
            }
          </div>
        </React.Fragment>
        }
        <input
          onChange={this.photoUpload.bind(this)}
          style={{ display: 'none', pointerEvents: 'none' }}
          accept=".jpg, .jpeg, .png, .svg, .heic, .heif"
          type="file"
          id={`fileUpload${num}`}
        />
      </div>
    )
  }
}
StarsMovieInput.propTypes = {
  cast: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  num: PropTypes.number,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
  thumbnail: PropTypes.string,
  type: PropTypes.oneOf(['normal', 'createProject', 'projectDetails', 'projectDetailsEditing']).isRequired
}

export default withStyles(styles)(StarsMovieInput)
