import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {
  listUserProjects,
  userInfoById
} from '../../../actions'
import {
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  toLocale
} from '../../../helpers'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import {
  FILE_STATUSES,
  PROJECT_STATUSES_NAME,
  ROLES
} from '../../../constants'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  userTitleText: {
    color: '#bfbfbf',
    marginRight: 10
  },
  userName: {
    marginRight: 4
  },
  projectCountainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  projectCountHeaderCell: {
    display: 'table-cell',
    fontWeight: '500',
    padding: '0 10px',
    paddingTop: 10,
    position: 'sticky',
    top: '50px',
    background: 'none',
    zIndex: '1',
    whiteSpace: 'nowrap'
  },
  projectCountCell: {
    padding: '0 10px',
    textAlign: 'center',
    display: 'table-cell'
  },
  userProjects: {
    margin: '3em 0',
    display: 'table',
    width: '100%',
    color: '#000',
    fontSize: 15,
    '&._projectCounts': {
      width: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    }
  },
  userProjectsRow: {
    display: 'table-row'
  },
  userProjectsHeaderCol: {
    display: 'table-cell',
    padding: '10px',
    position: 'sticky',
    fontWeight: 500,
    zIndex: 1,
    top: 90,
    backgroundColor: '#f6f6f6',
    verticalAlign: 'bottom',
    width: '18%',
    '&:first-child': {
      width: '28%'
    }
  },
  userProjectsCol: {
    display: 'table-cell',
    padding: '10px',
    '&._red': {
      color: 'red'
    }
  },
  userProjectsLink: {
    color: '#01b7d7',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}
/* eslint-enable sort-keys */

class UrlifeUserInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isProjectsLoading: false,
      nextProject: null,
      projects: [],
      user: null
    }
    this.projectStatuses = {}
    Object.assign(this.projectStatuses, PROJECT_STATUSES_NAME)
    this.projectStatuses['6'] = 'Cust. Approval'
  }

  fetchProjects () {
    const { user, nextProject: cursor, projects } = this.state
    const { dispatch } = this.props
    const params = { count: 100, cursor, method: 'DESC', sort: 'createdAt' }
    this.setState(
      { isProjectsLoading: true },
      () => {
        dispatch(listUserProjects(user.id, params))
          .then(
            response => {
              this.setState({
                isProjectsLoading: false,
                nextProject: response.nextCursor,
                projects: [...projects, ...response.data]
              })
            }
          )
      }
    )
  }

  componentDidMount () {
    const { match, dispatch } = this.props
    const { userId } = match.params

    userId && dispatch(userInfoById(userId))
      .then(
        response => {
          this.setState(
            { user: response.data },
            this.fetchProjects
          )
        }
      )
  }

  render () {
    const { classes } = this.props
    const { user, projects, nextProject, isProjectsLoading } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h5" component="h3">
            <span className={classes.userTitleText}>Projects of user:</span>
            {user && <span className={classes.userName}>{user.fullname}, </span>}
            {user && <span className={classes.userEmail}>{user.email}</span>}
          </Typography>
          {user && user.projectCounts && user.roleId === ROLES.USER &&
          <div className={classNames(classes.userProjects, '_projectCounts')}>
            {Object.keys(this.projectStatuses).map(status => {
              return (
                <div key={status} className={classes.projectCountainer}>
                  <div className={classes.projectCountHeaderCell}>
                    {this.projectStatuses[status]}
                  </div>
                  <span className={classes.projectCountCell}>
                    {user.projectCounts[status]}
                  </span>
                </div>
              )
            })}
          </div>
          }
          { user &&
          <UrlifeInfiniteScroller
            loadMore={this.fetchProjects.bind(this)}
            hasMore={!!nextProject}
            isLoading={isProjectsLoading}
            isEmpty={!projects.length}
            className={classes.userProjects}
          >
            <div className={classes.userProjectsRow}>
              <div className={classes.userProjectsHeaderCol}>Title</div>
              <div className={classes.userProjectsHeaderCol}>Status</div>
              <div className={classes.userProjectsHeaderCol}>
                Files <br /> (uploaded/total)
              </div>
              <div className={classes.userProjectsHeaderCol}>Created Date</div>
              <div className={classes.userProjectsHeaderCol}>Submitted Date</div>
              <div className={classes.userProjectsHeaderCol}>
                Messages <br /> (customer + editor)
              </div>
            </div>
            {projects.map(project => {
              const projectSku = getLeastCompleteProjectSku(project)
              return (
                <div key={project.id} className={classes.userProjectsRow}>
                  <div className={classes.userProjectsCol}>
                    {project.deleted
                      ? project.title
                      : <Link
                        className={classes.userProjectsLink}
                        to={`/projects/${project.id}`}>{project.title || '[ No title ]'}</Link>
                    }
                  </div>
                  <div className={classes.userProjectsCol}>
                    {this.projectStatuses[getLeastCompleteProjectSkuStatus(project)]}
                  </div>
                  <div
                    className={`${classes.userProjectsCol} ${project.fileCounts.toUpload ? '_red' : ''}`}>
                    {project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE]}
                    /
                    {project.fileCounts[FILE_STATUSES.CLIENT_UPLOADING] +
                      project.fileCounts[FILE_STATUSES.UPLOAD_FAILED] +
                      project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE]}
                  </div>
                  <div className={classes.userProjectsCol}>
                    {toLocale(new Date(project.createdAt), { full: true })}
                  </div>
                  <div className={classes.userProjectsCol}>
                    {projectSku && projectSku.firstSubmittedDate
                      ? toLocale(new Date(projectSku.firstSubmittedDate), { full: true })
                      : 'N/A'}
                  </div>
                  <div className={classes.userProjectsCol}>
                    {project.messageCounts.customer} + {project.messageCounts.editor}
                  </div>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
          }
        </Paper>
      </div>
    )
  }
}

UrlifeUserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
}

export default withStyles(styles)(connect()(UrlifeUserInfo))
