import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ImageLoading (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 39 30' >
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.9991L38.0002 30V27.3925L38.487 26.8244L38.0002 26.4071V0.000461578L0 0V29.9991ZM35.9999 24.6923V1.99985L2 1.99939V19.5067L10.9692 10.5376L21.0388 22.0457L28.0233 17.8542L35.9999 24.6923ZM35.9999 27.3252L27.8161 20.3118L20.6348 24.6199L10.8713 13.4629L2 22.3342V27.9997L35.9999 28.0002V27.3252ZM28.6277 14.4174C26.1236 14.4174 24.0859 12.3798 24.0859 9.87522C24.0859 7.36972 26.1236 5.33251 28.6277 5.33251C31.1322 5.33251 33.169 7.36972 33.169 9.87522C33.169 12.3798 31.1322 14.4174 28.6277 14.4174ZM28.6275 7.33299C27.2261 7.33299 26.0859 8.47359 26.0859 9.87517C26.0859 11.2767 27.2261 12.4168 28.6275 12.4168C30.0288 12.4168 31.1686 11.2767 31.1686 9.87517C31.1686 8.47359 30.0288 7.33299 28.6275 7.33299Z"/>
    </SvgIcon>
  )
}
