import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  commonStyles,
  getLeastCompleteProjectSkuStatus,
  inherits,
  mapProjectStatusToStage,
  toLocale
} from '../../../helpers'
import { connect } from 'react-redux'
import { PACKAGE_TYPES, PROJECT_STAGES, PROJECT_STATUSES, ROLES } from '../../../constants'
import { getImageRotationPureCss } from '../../../helpers/imageRotationCss'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import { projectFiles } from '../../../actions'
import styled from 'styled-components'
import ProjectBadge from '../../common/ProjectBadge'
import projectThumbnail from '../../../assets/img/defaultThumbnail/default16x9.jpg'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = inherits({
  myMoviePreparationText: 'myMoviePreparationWaiting'
})({
  myMovieWrap: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat',
    position: 'relative',
    height: 221,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eff2f5',
    '&:hover': {
      opacity: '0.7',
      backgroundColor: 'rgba(50, 50, 50, 0.1)'
    }
  },
  myCurationCover: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#eff2f5',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr 1fr'
  },
  curationImageContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    overflow: 'hidden'
  },
  curationImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  projectPlaceholder: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 24,
    margin: 'auto',
    color: '#dde3e8',
    fontSize: 107,
    ...commonStyles.media(1279, {
      fontSize: 76
    }),
    ...commonStyles.media(767, {
      fontSize: 50
    }),
    ...commonStyles.media(575, {
      fontSize: 86
    })
  },
  myMovieTitle: {
    fontSize: '18px',
    lineHeight: '1.33',
    textAlign: 'left',
    fontWeight: 500,
    color: '#FFF',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&._producer': {
      lineHeight: '25.45px',
      marginTop: 3
    }
  },
  myMovieDate: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left',
    opacity: '0.8',
    color: '#FFF'
  },
  myMovieInfo: {
    fontSize: 13,
    color: '#ffffff',
    fontWeight: 500,
    textAlign: 'left'
  },
  movieInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px 15px',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 95%)'
  }
})
/* eslint-enable sort-keys */

const MovieContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  width: ${props => props.width};
  height: ${props => props.height};
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  ${props => getImageRotationPureCss(props.rotation)}
`

class ProjectContentCard extends React.Component {
  constructor (props) {
    super(props)
    const { project } = props
    this.state = {
      coverAdditionalStyles: {},
      curationCovers: []
    }
    this.curationRefs = []
    this.fitCoverToParent = () => {
      if (project.coverFileOrientation && [6, 8, 5, 7].includes(project.coverFileOrientation)) {
        /**
         * need to fit to parent if we rotate image
         */
        const parent = this.coverRef.parentElement
        this.setState({
          coverAdditionalStyles: {
            height: parent.offsetWidth || 'auto',
            width: parent.offsetHeight || 'auto'
          }
        })
      }
    }
  }

  fetchProjectFiles () {
    const { dispatch, pack, project } = this.props
    if (pack && pack.type === PACKAGE_TYPES.CURATED) {
      dispatch(projectFiles(project.id, { count: 6 }))
        .then(data => {
          const thumbnails = []
          data.files && data.files.forEach(file => {
            if (file.signedThumbnailUrl) {
              thumbnails.push({ url: file.signedThumbnailUrl })
            }
          })
          this.setState({ curationCovers: thumbnails })
        })
    }
  }

  componentDidMount () {
    this.fetchProjectFiles()
    this.fitCoverToParent()
    window.addEventListener('resize', this.fitCoverToParent)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.fitCoverToParent)
  }

  render () {
    const { classes, isLoading, project, pack, user, roleId, freeCoupons } = this.props
    const { coverAdditionalStyles, curationCovers } = this.state
    const isUser = roleId === ROLES.USER
    const isProducer = roleId === ROLES.PRODUCER
    const statusId = getLeastCompleteProjectSkuStatus(project)
    let projectBadgeLabel = null
    if (isUser) {
      let hasFreeCoupon = false
      freeCoupons && freeCoupons.forEach(coupon => {
        project.projectSkus.forEach(sku => {
          const isUnpaid = mapProjectStatusToStage(sku.statusId) === PROJECT_STAGES.PREP
          if (isUnpaid && coupon.skus.includes(sku.packageSku)) {
            hasFreeCoupon = true
          }
        })
      })
      let isFree = false
      project.projectSkus.forEach(sku => {
        const isUnpaid = mapProjectStatusToStage(sku.statusId) === PROJECT_STAGES.PREP
        if (isUnpaid) {
          isFree = sku && sku.price < 50
        }
      })
      if (statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL) {
        projectBadgeLabel = 'Ready for your review'
      } else if (statusId === PROJECT_STATUSES.PREP_WAIT_PAYMENT) {
        projectBadgeLabel = 'Finalize with email'
      } else if (statusId === PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION) {
        projectBadgeLabel = 'Finalize custom details'
      } else if (hasFreeCoupon || isFree) {
        projectBadgeLabel = 'Free Project'
      }
    } else if (isProducer) {
      if (statusId === PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION) {
        projectBadgeLabel = 'In Negotiation'
      } else if (statusId === PROJECT_STATUSES.PREP_WAIT_PAYMENT) {
        projectBadgeLabel = 'Awaiting payment'
      }
    }
    const movieDate = project.summaryDate || toLocale(new Date(project.createdAt), { forMyMovie: true })
    const isCurated = pack && pack.type && pack.type === PACKAGE_TYPES.CURATION
    return (
      <div
        className={classes.myMovieWrap}
        ref={ref => { this.coverRef = ref }}
      >
        {project.coverUrl && !isCurated && <MovieContainer
          thumbnail={cacheSignedUrl.get(project.coverUrl)}
          rotation={project.coverFileOrientation}
          width={coverAdditionalStyles.width}
          height={coverAdditionalStyles.height}
        >
        </MovieContainer>}
        {project.coverUrl && isCurated && <div className={classes.myCurationCover}>
          {curationCovers.map((cover, idx) => {
            return (
              <div
                key={idx}
                className={classes.curationImageContainer}
              >
                <img
                  ref={ref => { this.curationRefs[idx] = ref }}
                  className={classes.curationImage}
                  src={cacheSignedUrl.get(cover.url)}
                  alt=""/>
              </div>
            )
          })}
        </div>}
        {!project.coverUrl && !isLoading &&
          <MovieContainer
            thumbnail={project.skinnedThumbnail || projectThumbnail}
            rotation={project.coverFileOrientation}
            width={coverAdditionalStyles.width}
            height={coverAdditionalStyles.height}
          />
        }
        {projectBadgeLabel && <ProjectBadge label={projectBadgeLabel} />}
        <div className={classes.movieInfoContainer}>
          {isUser && <React.Fragment>
            <div className={classes.myMovieTitle}>{project && project.title}</div>
            <div className={classes.myMovieDate}>{movieDate}</div>
          </React.Fragment>}
          {!isUser && <React.Fragment>
            <div className={classes.myMovieInfo}>{movieDate}</div>
            <div className={classNames(classes.myMovieTitle, '_producer')}>{project && project.title}</div>
            <div className={classes.myMovieInfo}>{user ? user.fullname : '...'}</div>
          </React.Fragment>}
        </div>
      </div>
    )
  }
}

ProjectContentCard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  freeCoupons: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  pack: PropTypes.object,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number,
  user: PropTypes.object
}

const mapStateToProps = state => {
  return {
    roleId: state.user.roleId
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProjectContentCard))
