import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  headerText: {
    fontSize: 36,
    lineHeight: '44px',
    textTransform: 'uppercase',
    padding: '0 40px',
    color: props => props.color || '#512cb9',
    fontWeight: 600,
    ...commonStyles.media(991, {
      padding: '0 30px',
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      padding: '0 20px',
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      padding: '0 10px',
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      padding: '0 10px',
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.media(410, {
      maxWidth: '100%',
      margin: 'auto'
    }),
    '&._multiLine': {
      textAlign: 'center',
      maxWidth: 600,
      '&._postvoyage': {
        fontSize: 30,
        maxWidth: 740,
        ...commonStyles.media(991, {
          maxWidth: 525,
          padding: '0 25px',
          fontSize: 26,
          lineHeight: '34px'
        }),
        ...commonStyles.media(767, {
          maxWidth: 485,
          padding: '0 20px',
          fontSize: 22,
          lineHeight: '30px'
        }),
        ...commonStyles.media(575, {
          maxWidth: 390,
          padding: '0 15px',
          fontSize: 16,
          lineHeight: '22px'
        }),
        ...commonStyles.mediaDimensions(896, 414, {
          padding: '0 10px',
          fontSize: 24,
          lineHeight: '34px'
        })
      },
      ...commonStyles.media(991, {
        maxWidth: 455,
        padding: '0 25px',
        fontSize: 28,
        lineHeight: '34px'
      }),
      ...commonStyles.media(767, {
        maxWidth: 385,
        padding: '0 20px',
        fontSize: 24,
        lineHeight: '30px'
      }),
      ...commonStyles.media(575, {
        maxWidth: 290,
        padding: '0 15px',
        fontSize: 18,
        lineHeight: '22px'
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        padding: '0 10px',
        fontSize: 28,
        lineHeight: '34px'
      })
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 68,
    ...commonStyles.media(991, {
      marginBottom: 48
    }),
    ...commonStyles.media(767, {
      marginBottom: 20
    }),
    ...commonStyles.media(575, {
      marginBottom: 10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginBottom: 10
    })
  },
  line: {
    height: 3,
    background: props => props.color || 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)',
    flexGrow: 1,
    ...commonStyles.media(410, {
      display: 'none'
    })
  }
})
/* eslint-enable sort-keys */

const LineTitle = ({ color, className, extraClass, multiLine, text }) => {
  const classes = useStyles({ color })
  return (
    <div className={classNames(classes.header, className)}>
      <div className={classNames(classes.line, extraClass, { _multiLine: multiLine })} />
      <div className={classNames(classes.headerText, extraClass, { _multiLine: multiLine })}>
        {text}
      </div>
      <div className={classNames(classes.line, extraClass, { _multiLine: multiLine })} />
    </div>
  )
}

LineTitle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  extraClass: PropTypes.string,
  multiLine: PropTypes.bool,
  text: PropTypes.string.isRequired
}

export default LineTitle
