import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  getCreateProjectTotalSteps,
  getLeastCompleteProjectSku,
  getNoteStepsArray,
  getPackageType,
  history,
  mapProjectStatusToStage
} from '../../helpers'
import PropTypes from 'prop-types'
import AboutMovie from './components/Steps/AboutMovie'
import NameProject from './components/Steps/NameProject'
import DescribeProject from './components/Steps/DescribeProject'
import DetailsMovie from './components/Steps/DetailsMovie'
import CharactersMovie from './components/Steps/CharactersMovie'
import UploadFiles from './components/Steps/UploadFiles'
import Summary from './components/Steps/Summary'
import Checkout from '../payment/Checkout'
import ChoosePackage from '../common/Packages/ChoosePackage'
import ProducerRequest from './components/Steps/ProducerRequest'
import ContactMethod from './components/Steps/ContactMethod'
import ScheduleCall from './components/Steps/ScheduleCall'
import Congratulations from './components/Steps/Congratulations'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import {
  clearMoreMenuActions,
  clearProjectFiles,
  clearThumbnails,
  deleteProject,
  getAllPromptsCategory,
  getCategory,
  getPackageSku,
  projectInfo,
  setMoreMenuAction,
  setSkinId,
  setTopBarSteps,
  updateProject,
  updateProjectSku
} from '../../actions'
import {
  CONTACT_CHOICE,
  PACKAGE_TYPES,
  PROJECT_DATA,
  PROJECT_STAGES
} from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  createMovieContainer: {
    position: 'relative'
  },
  createMovieTitle: {
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: '300',
    color: '#000000'
  },
  createMovieContact: {
    fontFamily: 'Montserrat',
    fontSize: '36px',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#01b7d7',
    cursor: 'pointer'
  },
  createMovieTopContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '50px'
  },
  createMovieIcon: {
    overflow: 'hidden',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  createMovieIconText: {
    fontFamily: 'Montserrat',
    color: 'rgb 255 138 163',
    fontSize: '15px',
    fontWeight: '900'
  },
  createMovieProducer: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingProject: {
    color: 'black'
  }
}
/* eslint-enable sort-keys */

const historyState = { type: 'creationFlow' }

class CreateMovie extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      appointmentDate: null,
      contactChoice: CONTACT_CHOICE.CHOICE_NOT_MADE,
      contactProducer: false,
      currentStep: 0,
      implicitCategoryId: null,
      packageType: null,
      phoneNumber: '',
      project: null,
      projectCategory: null,
      prompts: [],
      unlisten: history.listen(this.onRouteChange.bind(this))
    }
    this.mounted = false
    this.validateOnMount = false
    this.init()
    this.lastLocation = '/home'
    this.lastRouteChange = {
      action: null,
      state: null
    }
  }

  init () {
    const { match } = this.props
    if (history.location.state && history.location.state.lastLocation) {
      this.lastLocation = history.location.state.lastLocation.pathname
    }
    if (match.path === '/createmovie/:subPath?/:projectId?') {
      if (match.params.subPath === 'from-pricing' ||
          match.params.subPath === 'continue' ||
          match.params.projectId) {
        if (this.mounted) {
          this.validateData()
        } else {
          this.validateOnMount = true
        }
      } else {
        history.replace('/createmovie/start')
      }
    } else {
      history.replace('/createmovie/start')
    }
  }

  async validateData () {
    const {
      projectInfoFunction,
      match
    } = this.props
    const { project } = this.state
    const projectMatch = match.params.projectId && project && project.Id ? match.params.projectId === project.Id : true
    if (!project || !projectMatch || !project.creationStatus) {
      if (!projectMatch) {
        this.resetProjectData()
        history.goBack()
      }
      if (match.params.projectId) {
        try {
          const projectRes = await projectInfoFunction(match.params.projectId)
          this.setState({ project: projectRes }, () => {
            this.setRequiredDataAndContinue(projectRes)
          })
        } catch (e) {
          history.goBack()
        }
      } else {
        this.resetProjectData()
        history.goBack()
      }
    } else {
      this.setRequiredDataAndContinue(project)
    }
  }

  initProjectStatus (project) {
    const status = project ? project.creationStatus : null
    const projectSku = getLeastCompleteProjectSku(project)
    if (this.mounted && status && projectSku) {
      const contactProducer =
        status.contactChoice !== CONTACT_CHOICE.CHOICE_NOT_MADE &&
        status.contactChoice !== CONTACT_CHOICE.NOTES
      this.setState({
        appointmentDate: projectSku.appointment,
        contactChoice: contactProducer ? status.contactChoice : CONTACT_CHOICE.CHOICE_NOT_MADE,
        contactProducer: contactProducer,
        phoneNumber: projectSku.phoneNumber || ''
      })
    }
  }

  async setRequiredDataAndContinue (project) {
    const {
      getAllPromptsCategoryFunction,
      getCategoryFunction
    } = this.props
    if (project.creationStatus) {
      this.initProjectStatus(project)
      const projectSku = getLeastCompleteProjectSku(project)
      this.setPackageSku(projectSku.packageSku, project, () => {
        this.switchStep(false, false, true, this.getCurrentPage(project))
      })
      if (project.categoryId) {
        const prompts = await getAllPromptsCategoryFunction(project.categoryId)
        const categoryRes = await getCategoryFunction(project.categoryId)
        this.setState({
          projectCategory: categoryRes.data[0],
          prompts: prompts
        }, () => {
          if (prompts.length !== project.creationStatus.totalNumberOfPrompts) {
            this.updateProject(null, { totalNumberOfPrompts: prompts.length })
          }
        })
      }
    } else {
      history.replace(`/projects/${project.id}/summary`)
    }
  }

  getCurrentPage (project) {
    const { skinData } = this.props
    const { packageType } = this.state
    const status = project.creationStatus
    const skinned = !!skinData

    if (status) {
      if (!status.category && skinned) {
        return -1
      } if (!status.category && packageType !== PACKAGE_TYPES.CURATED) {
        return 1
      } else if (!status.title) {
        if (packageType === PACKAGE_TYPES.CURATED) {
          return 1
        } else {
          return 2
        }
      } else if (!status.description) {
        if (packageType === PACKAGE_TYPES.CURATED) {
          return 2
        } else {
          return 3
        }
      } else if (!status.uploaded) {
        if (packageType === PACKAGE_TYPES.CURATED) {
          return 3
        } else {
          return 4
        }
      } else if (packageType === PACKAGE_TYPES.HOLLYWOOD) {
        switch (status.contactChoice) {
          case CONTACT_CHOICE.CHOICE_NOT_MADE:
            return 5
          case CONTACT_CHOICE.PRODUCER_CONTACT:
            return 6
          case CONTACT_CHOICE.APPOINTMENT:
            if (!status.madeAppointment) {
              return 7
            } else {
              return 8
            }
          case CONTACT_CHOICE.EMAIL_ME:
            return 7
          case CONTACT_CHOICE.NOTES:
            return this.getCurrentPageNotesAndAfter(status, packageType)
          default:
            return 5
        }
      } else if (packageType === PACKAGE_TYPES.RECAP) {
        return this.getCurrentPageNotesAndAfter(status, packageType)
      } else if (packageType === PACKAGE_TYPES.CURATED) {
        return 4
      } else {
        if (packageType === PACKAGE_TYPES.CURATED) {
          return 3
        } else {
          return 4
        }
      }
    } else {
      history.replace(`/projects/${project.id}/summary`)
    }
  }

  getCurrentPageNotesAndAfter (status, packageType) {
    // convert promtsAnswers to NotePageSteps
    const projectSteps = getCreateProjectTotalSteps(packageType)
    let currentNoteSteps = status.promptsAnswered % PROJECT_DATA.MAX_PROMPTS_PER_PAGE === 0 ? 0 : 1

    currentNoteSteps += Math.floor(status.promptsAnswered / PROJECT_DATA.MAX_PROMPTS_PER_PAGE) + 1
    let totalNoteSteps = status.totalNumberOfPrompts % PROJECT_DATA.MAX_PROMPTS_PER_PAGE === 0 ? 0 : 1
    totalNoteSteps += Math.floor(status.totalNumberOfPrompts / PROJECT_DATA.MAX_PROMPTS_PER_PAGE) + 1

    // return correct step
    if (currentNoteSteps < totalNoteSteps) {
      return projectSteps + currentNoteSteps
    } else if (!status.cast) {
      return projectSteps + totalNoteSteps
    } else {
      return projectSteps + totalNoteSteps + 1
    }
  }

  componentDidMount () {
    const {
      clearMoreMenuActionsFunction,
      setMoreMenuActionFunction
    } = this.props
    this.mounted = true
    clearMoreMenuActionsFunction()
    setMoreMenuActionFunction('saveProject', this.updateProject.bind(this))
    setMoreMenuActionFunction('deleteProject', this.deleteProject.bind(this))
    if (this.validateOnMount) {
      this.validateData()
    }
  }

  componentWillUnmount () {
    this.resetProjectData()
    this.state.unlisten()
    this.props.clearMoreMenuActionsFunction()
    this.mounted = false
  }

  resetProjectData () {
    if (this.mounted) {
      this.setState({
        appointmentDate: null,
        contactChoice: CONTACT_CHOICE.CHOICE_NOT_MADE,
        contactProducer: false,
        implicitCategory: null,
        implicitCategoryId: null,
        packageType: null,
        phoneNumber: ''
      })
    }
    this.props.clearProjectFilesFunction()
    this.props.setTopBarStepsFunction(0, 0)
    this.props.clearThumbnailsFunction()
  }

  getTotalNotesSteps (prompts) {
    let totalSteps = 1
    if (prompts && prompts.length > 0) {
      totalSteps += 1 + Math.floor((prompts.length - 1) / PROJECT_DATA.MAX_PROMPTS_PER_PAGE)
    }
    return totalSteps
  }

  onRouteChange (location, action) {
    const { match } = this.props
    if (action === 'POP') {
      if (this.lastRouteChange.matchSubPath === 'start') {
        history.goBack()
      } else if (
        this.lastRouteChange.action === 'REPLACE' &&
        this.lastRouteChange.state &&
        this.lastRouteChange.state.type === 'inAppBack'
      ) {
        this.switchStep(false)
      } else {
        history.goBack()
      }
    }
    if (action === 'PUSH' && location.state && location.state.type === 'packageChange') {
      this.resetProjectData()
      this.setState({
        currentStep: 0
      }, () => {
        this.init()
      })
    }
    this.lastRouteChange = {
      action: history.action,
      matchSubPath: match.params.subPath,
      state: history.location.state
    }
  }

  async goToDetailsFirstStep () {
    const { setTopBarStepsFunction } = this.props
    const { prompts } = this.state
    const noteSteps = this.getTotalNotesSteps(prompts)

    await setTopBarStepsFunction(1, noteSteps)
    if (noteSteps === 1) {
      this.changeLocation('/createmovie/characters')
    } else {
      this.changeLocation('/createmovie/details')
    }
  }

  changeLocation (path) {
    const {
      match,
      setTopBarStepsFunction
    } = this.props
    const { project } = this.state
    const state = history.location.state
    const isContinue =
      match.path === '/createmovie/:subPath?/:projectId?' &&
      match.params.subPath === 'continue'
    const returnPath = `${path}/${project.id}`
    const projectSku = getLeastCompleteProjectSku(project)
    const statusId = projectSku && projectSku.statusId
    const isPrep = mapProjectStatusToStage(statusId) === PROJECT_STAGES.PREP
    if (path === '/createmovie/summary' || path === '/createmovie/checkout') {
      setTopBarStepsFunction(0, 0)
    }
    if (!!statusId && !isPrep) {
      history.replace(`/projects/${project.id}/summary`, historyState)
    } else {
      if (state && state.type && state.type === 'creationFlow' && !isContinue) {
        history.replace(returnPath, historyState)
      } else if (
        state &&
        state.type &&
        state.type === 'packageChange' &&
        state.customPrice === false &&
        path === '/createmovie/summary'
      ) {
        this.setState({
          currentStep: this.getCurrentPage(project) + 1
        }, () => {
          history.replace(`/createmovie/checkout/${project.id}`, historyState)
        })
      } else {
        history.push(returnPath, historyState)
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 10)
  }

  goBack () {
    history.replace(this.lastLocation)
  }

  async setPrompts (prompts = []) {
    this.setState({ prompts: prompts })
  }

  switchStep (next = false, skip = false, continueFlow = false, step = false) {
    const {
      skinData,
      setTopBarStepsFunction
    } = this.props
    const {
      project,
      currentStep,
      packageType,
      implicitCategoryId,
      contactProducer
    } = this.state
    const {
      totalNumberOfPrompts
    } = project.creationStatus
    const skinned = !!skinData
    const isRecap = packageType === PACKAGE_TYPES.RECAP
    const isHollywood = packageType === PACKAGE_TYPES.HOLLYWOOD
    const isCurated = packageType === PACKAGE_TYPES.CURATED
    const projectSteps = getCreateProjectTotalSteps(packageType)
    const noteStepArr = getNoteStepsArray(totalNumberOfPrompts)
    const contactChoice = this.state.contactChoice && contactProducer
      ? this.state.contactChoice
      : project.creationStatus
        ? project.creationStatus.contactChoice
        : CONTACT_CHOICE.CHOICE_NOT_MADE
    const producerContactChosen =
      contactProducer ||
      contactChoice === CONTACT_CHOICE.PRODUCER_CONTACT ||
      contactChoice === CONTACT_CHOICE.EMAIL_ME ||
      contactChoice === CONTACT_CHOICE.APPOINTMENT
    const scheduleChosen = contactChoice === CONTACT_CHOICE.APPOINTMENT
    const skipCategory = (isHollywood || isRecap) && implicitCategoryId
    let newStep = currentStep

    if (step) {
      newStep = step
    } else if (skip) {
      newStep = projectSteps + noteStepArr.length
    }

    if (next) {
      newStep++
    } else if (!continueFlow && newStep > 0) {
      newStep--
    }

    if (newStep === 1 && skipCategory) {
      if (next) {
        newStep++
      } else if (!continueFlow) {
        newStep--
      }
    }

    if (newStep >= 0 && this.mounted) {
      this.setState({ currentStep: newStep })
    }

    const noteStep = newStep - projectSteps
    const maxNoteStep = noteStepArr.length

    if (newStep <= projectSteps && newStep >= 0) {
      // sets normal project steps
      const skip = skipCategory ? 1 : 0
      let step = newStep - skip
      if (skinned && step < 1) {
        step = 1
      }
      setTopBarStepsFunction(step, projectSteps - skip)
    } else if (newStep > projectSteps && ((!producerContactChosen && isHollywood) || !isHollywood) && newStep >= 0) {
      // sets notes header steps
      setTopBarStepsFunction(noteStep, maxNoteStep + 1)
    } else {
      // reset header step for rest of the pages
      setTopBarStepsFunction(0, 0)
    }

    switch (newStep) {
      case -1:
        this.changeLocation('/createmovie/start')
        break
      case 0:
        if (skinned) {
          history.goBack()
        } else {
          this.changeLocation('/createmovie/start')
        }
        break
      case 1:
        if (isHollywood || isRecap) {
          this.changeLocation('/createmovie/about-movie')
        } else if (isCurated) {
          this.changeLocation('/createmovie/name-project')
        }
        break
      case 2:
        if (isHollywood || isRecap) {
          this.changeLocation('/createmovie/name-project')
        } else if (isCurated) {
          this.changeLocation('/createmovie/describe-project')
        }
        break
      case 3:
        if (isHollywood || isRecap) {
          this.changeLocation('/createmovie/describe-project')
        } else if (isCurated) {
          this.changeLocation('/createmovie/upload-files')
        }
        break
      case 4:
        if (isHollywood || isRecap) {
          this.changeLocation('/createmovie/upload-files')
        } else if (isCurated) {
          this.changeLocation('/createmovie/summary')
        }
        break
      case 5:
        if (isHollywood) {
          this.changeLocation('/createmovie/producer')
        } else if (isRecap) {
          this.goToDetailsFirstStep()
        } else if (isCurated) {
          if (project && project.projectSkus[0].price) {
            this.changeLocation('/createmovie/checkout')
          } else {
            this.changeLocation('/createmovie/congratulations')
          }
        }
        break
      case 6:
        if (isHollywood) {
          if (producerContactChosen) {
            this.changeLocation('/createmovie/contact')
          } else {
            this.goToDetailsFirstStep()
          }
        } else if (isRecap) {
          this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        } else if (isCurated) {
          this.changeLocation('/createmovie/congratulations')
        }
        break
      case 7:
        if (isHollywood) {
          if (producerContactChosen) {
            if (scheduleChosen) {
              this.changeLocation('/createmovie/schedule')
            } else {
              this.changeLocation('/createmovie/summary')
            }
          } else {
            this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
          }
        } else if (isRecap) {
          this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        } else if (isCurated) {
          this.changeLocation('/createmovie/congratulations')
        }
        break
      case 8:
        if (isHollywood) {
          if (producerContactChosen) {
            if (scheduleChosen) {
              this.changeLocation('/createmovie/summary')
            } else {
              if (project && project.projectSkus[0].price) {
                this.changeLocation('/createmovie/checkout')
              } else {
                this.changeLocation('/createmovie/congratulations')
              }
            }
          } else {
            this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
          }
        } else if (isRecap) {
          this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        }
        break
      case 9:
        if (isHollywood) {
          if (producerContactChosen) {
            if (scheduleChosen) {
              if (project && project.projectSkus[0].price) {
                this.changeLocation('/createmovie/checkout')
              } else {
                this.changeLocation('/createmovie/congratulations')
              }
            } else {
              this.changeLocation('/createmovie/congratulations')
            }
          } else {
            this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
          }
        } else if (isRecap) {
          this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        }
        break
      case 10:
        if (isHollywood) {
          if (producerContactChosen) {
            if (scheduleChosen) {
              if (project && project.projectSkus[0].price) {
                this.changeLocation('/createmovie/congratulations')
              } else {
                this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
              }
            } else {
              this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
            }
          } else {
            this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
          }
        } else if (isRecap) {
          this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        }
        break
      default:
        this.notesStepsToCongrats(noteStep, maxNoteStep, skip)
        break
    }
  }

  notesStepsToCongrats (noteStep, maxNoteStep, skip = false) {
    const { project } = this.state
    if (noteStep > 0 && noteStep <= maxNoteStep) {
      this.changeLocation('/createmovie/details')
    } else if (noteStep === maxNoteStep + 1) {
      this.changeLocation('/createmovie/characters')
    } else if (noteStep === maxNoteStep + 2) {
      this.changeLocation('/createmovie/summary')
    } else if (noteStep === maxNoteStep + 3) {
      if (project && project.projectSkus[0].price) {
        this.changeLocation('/createmovie/checkout')
      } else {
        this.changeLocation('/createmovie/congratulations')
      }
    } else if (noteStep === maxNoteStep + 4) {
      this.changeLocation('/createmovie/congratulations')
    } else {
      history.goBack()
    }
  }

  nextStep (skip = false, newProject = null) {
    if (newProject) {
      this.setState({ project: newProject }, () => {
        this.setRequiredDataAndContinue(newProject)
      })
    } else {
      this.switchStep(true, skip)
    }
  }

  async setPackageSku (packageSkuId, project, callback = null) {
    const {
      getAllPromptsCategoryFunction,
      getCategoryFunction,
      getPackageSkuFunction,
      setSkinIdFunction,
      updateProjectFunction
    } = this.props

    try {
      const packageSku = await getPackageSkuFunction(packageSkuId)
      setSkinIdFunction(packageSku.skinId)
      const packageId = packageSku.packageId
      const packages = this.props.packages
      if (packageSku.implicitCategoryId && project.categoryId !== packageSku.implicitCategoryId) {
        const promptsRes = await getAllPromptsCategoryFunction(packageSku.implicitCategoryId)
        const projectRes = await updateProjectFunction({
          ...project,
          categoryId: packageSku.implicitCategoryId,
          creationStatus: {
            ...project.creationStatus,
            category: true,
            totalNumberOfPrompts: promptsRes.length
          }
        })
        const categoryRes = await getCategoryFunction(projectRes.categoryId)
        this.setState({ project: projectRes, projectCategory: categoryRes.data[0], prompts: promptsRes })
      }
      this.setState({
        implicitCategoryId: packageSku.implicitCategoryId,
        packageType: getPackageType(packages, packageId)
      }, () => {
        callback && callback()
      })
    } catch (e) {}
  }

  setContactProducer (shouldContact) {
    this.setState({ contactProducer: shouldContact })
  }

  setContactChoice (choice) {
    this.setState({ contactChoice: choice })
  }

  setPhoneNumber (phone) {
    this.setState({ phoneNumber: phone })
  }

  setAppointmentDate (date) {
    this.setState({ appointmentDate: date })
  }

  async updateProject (updateFields = null, creationStatusUpdateFields = null) {
    const { updateProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      const proj = updateFields ? { id: project.id, ...updateFields } : project
      if (creationStatusUpdateFields) {
        proj.creationStatus = {
          ...project.creationStatus,
          ...creationStatusUpdateFields
        }
      }
      const projectRes = await updateProjectFunction(proj)
      this.setState({ project: projectRes })
    }
  }

  async deleteProject () {
    const { deleteProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      await deleteProjectFunction(project.id)
      this.setState({ project: null })
    }
  }

  async updateProjectSku (updateFields = {}) {
    const { updateProjectSkuFunction } = this.props
    const { project } = this.state
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const changePackage = updateFields.packageSku && updateFields.packageSku !== projectSku.packageSku
      const projectSkuRes = await updateProjectSkuFunction(project.id, { id: projectSku.id, ...updateFields })
      const skuIndex = project.projectSkus.findIndex(sku => sku.id === projectSku.id)
      project.projectSkus[skuIndex] = projectSkuRes
      this.setState({ project })
      if (changePackage) {
        await this.setPackageSku(projectSkuRes.packageSku, project)
      }
    }
  }

  /*
    NOTE:
      Individual pages should not themselves call history.push to go to the next step,
      they should instead call nextStep.
      Because going between pages then triggers the onRouteChange callback, the individual
      pages should make sure that they have done everything they need to do before calling
      nextStep, such as updating project.
  */
  render () {
    const {
      classes,
      match,
      skinData
    } = this.props
    const {
      project,
      packageType,
      prompts,
      contactChoice,
      phoneNumber,
      appointmentDate,
      currentStep,
      projectCategory
    } = this.state
    const isFirstStage = !match.params.subPath || match.params.subPath === 'start'
    const skinned = !!skinData
    const projectSku = getLeastCompleteProjectSku(project)
    const isCustomPrice = projectSku && !projectSku.price && projectSku.price !== 0
    return <div className={classes.createMovieContainer}>
      {(project || isFirstStage) && <Switch>
        <Route
          path='/createmovie/start'
          render={() => <ChoosePackage
            projectId={project && project.id}
            projectSku={projectSku}
            skinned={skinned}
            nextStep={this.nextStep.bind(this)}
            updateProjectSku={this.updateProjectSku.bind(this)}
          />}
        />
        <Route
          path='/createmovie/about-movie'
          render={() => <AboutMovie
            categoryId={project.categoryId}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
            setPrompts={this.setPrompts.bind(this)}
          />}
        />
        <Route
          path='/createmovie/name-project'
          render={() => <NameProject
            title={project.creationStatus.title ? project.title : ''}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
          />}
        />
        <Route
          path='/createmovie/describe-project'
          render={() => <DescribeProject
            description={project.description}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
          />}
        />
        <Route
          path='/createmovie/upload-files'
          render={() => <UploadFiles
            project={project}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
          />}
        />
        <Route
          path='/createmovie/producer'
          render={() => <ProducerRequest
            contactChoice={project.creationStatus.contactChoice}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
            setContactProducer={this.setContactProducer.bind(this)}
          />}
        />
        <Route
          path='/createmovie/details'
          render={() => {
            const noteStep = currentStep - getCreateProjectTotalSteps(packageType)
            return (
              <DetailsMovie
                categoryId={project.categoryId}
                projectCategory={projectCategory}
                projectId={project.id}
                totalNumberOfPrompts={project.creationStatus.totalNumberOfPrompts}
                step={noteStep}
                prompts={prompts}
                nextStep={this.nextStep.bind(this)}
                updateProject={this.updateProject.bind(this)}
              />
            )
          }}
        />
        <Route
          path='/createmovie/characters'
          render={() => <CharactersMovie
            projectId={project.id}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
          />}
        />
        <Route
          path='/createmovie/contact'
          render={() => <ContactMethod
            email={projectSku.email}
            contactChoice={contactChoice}
            savedContactChoice={project.creationStatus.contactChoice}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
            updateProjectSku={this.updateProjectSku.bind(this)}
            setContactChoice={this.setContactChoice.bind(this)}
          />}
        />
        <Route
          path='/createmovie/schedule'
          render={() => <ScheduleCall
            phoneNumber={phoneNumber}
            appointmentDate={appointmentDate}
            isBooked={!!projectSku.appointment}
            nextStep={this.nextStep.bind(this)}
            projectId={project.id}
            updateProject={this.updateProject.bind(this)}
            updateProjectSku={this.updateProjectSku.bind(this)}
            setPhoneNumber={this.setPhoneNumber.bind(this)}
            setAppointmentDate={this.setAppointmentDate.bind(this)}
          />}
        />
        <Route
          path='/createmovie/summary'
          render={() => <Summary
            packageType={packageType}
            project={project}
            projectSku={projectSku}
            nextStep={this.nextStep.bind(this)}
            updateProject={this.updateProject.bind(this)}
            updateProjectSku={this.updateProjectSku.bind(this)}
          />}
        />
        <Route
          path='/createmovie/checkout'
          render={() => <Checkout
            project={project}
            canChangePackage={!skinned}
            nextStep={this.nextStep.bind(this)}
            updateProjectSku={this.updateProjectSku.bind(this)}
          />}
        />
        <Route
          path='/createmovie/congratulations'
          render={() => <Congratulations
            project={project}
            packageType={packageType}
            isCustomPrice={isCustomPrice}
          />}
        />
      </Switch>}
      {!project && !isFirstStage &&
      <p className={classes.loadingProject}>
        Loading your project...
      </p>}
    </div>
  }
}
CreateMovie.propTypes = {
  classes: PropTypes.object.isRequired,
  clearMoreMenuActionsFunction: PropTypes.func.isRequired,
  clearProjectFilesFunction: PropTypes.func.isRequired,
  clearThumbnailsFunction: PropTypes.func.isRequired,
  deleteProjectFunction: PropTypes.func.isRequired,
  getAllPromptsCategoryFunction: PropTypes.func.isRequired,
  getCategoryFunction: PropTypes.func.isRequired,
  getPackageSkuFunction: PropTypes.func.isRequired,
  match: PropTypes.object,
  packages: PropTypes.array.isRequired,
  projectInfoFunction: PropTypes.func.isRequired,
  setMoreMenuActionFunction: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  setTopBarStepsFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  updateProjectFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages,
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearMoreMenuActionsFunction: function () {
      dispatch(clearMoreMenuActions())
    },
    clearProjectFilesFunction: function () {
      dispatch(clearProjectFiles())
    },
    clearThumbnailsFunction: function () {
      dispatch(clearThumbnails())
    },
    deleteProjectFunction: function (projectId) {
      return dispatch(deleteProject(projectId))
    },
    getAllPromptsCategoryFunction: function (categoryId) {
      return dispatch(getAllPromptsCategory(categoryId))
    },
    getCategoryFunction: function (categoryId) {
      return dispatch(getCategory(categoryId))
    },
    getPackageSkuFunction: function (id) {
      return dispatch(getPackageSku(id))
    },
    projectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setMoreMenuActionFunction: function (name, func) {
      dispatch(setMoreMenuAction(name, func))
    },
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    setTopBarStepsFunction: function (step, totalSteps) {
      dispatch(setTopBarSteps(step, totalSteps))
    },
    updateProjectFunction: function (project) {
      return dispatch(updateProject(project))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateMovie))
