const fileBatch = (state = {}, action) => {
  switch (action.type) {
    case 'FILE_BATCH_PROCESS_FILES': {
      if (!action.projectId || !action.count) return state
      const projectFileBatch = state[action.projectId] || { completedCount: 0, totalCount: 0 }
      projectFileBatch.totalCount += action.count
      return {
        ...state,
        [action.projectId]: projectFileBatch
      }
    }
    case 'FILE_BATCH_PROCESS_COMPLETED': {
      if (!action.projectId || !action.count || !state[action.projectId]) return state
      const projectFileBatch = state[action.projectId]
      projectFileBatch.completedCount += action.count
      return {
        ...state,
        [action.projectId]: projectFileBatch
      }
    }
    case 'FILE_BATCH_CLEAR_PROCESS':
      if (!state[action.projectId]) return state
      delete state[action.projectId]
      return {
        ...state
      }
    default:
      return state
  }
}

export default fileBatch
