import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'
import CarouselWrapper from '../urlifeparallax/groups/components/CarouselWrapper'
import ModalPreview from './ModalPreview'
import { ParallaxArrowRight, ParallaxPlayIcon } from './SVG'
import styled from 'styled-components'

/*
 * This component needs to be held in a container that has:
 * overflowX: 'hidden'
 *
 * If that is not the case, the width of the page will be strange.
 */

/* eslint-disable sort-keys */
const styles = {
  root: {
    ...commonStyles.container(),
    minHeight: 40,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '&._solo': {
      marginBottom: 40
    },
    '& h2': {
      margin: 'auto 0',
      display: 'inline-block',
      color: '#5334b6',
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: 1,
      textTransform: 'uppercase',
      '&._solo': {
        fontSize: 30
      }
    },
    '& p': {
      color: '#323232',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 45,
      width: '100%'
    },
    '& div': {
      display: 'flex',
      cursor: 'pointer',
      '& span': {
        color: '#323232',
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: 1,
        margin: 'auto 20px auto auto'
      },
      '& svg': {
        width: 40,
        height: 40
      }
    }
  },
  ...commonStyles.media(1279, {
    root: {
      '& h2': {
        fontSize: 22,
        '&._solo': {
          fontSize: 24
        }
      }
    }
  }),
  ...commonStyles.media(599, {
    root: {
      '& h2': {
        width: '100%',
        fontSize: 18,
        '&._solo': {
          fontSize: 20
        }
      },
      '& div': {
        display: 'none'
      }
    }
  }),
  ...commonStyles.media(480, {
    root: {
      '& p': {
        fontSize: 14
      }
    }
  }),
  ...commonStyles.mediaDimensions(896, 414, {
    root: {
      '& h2': {
        fontSize: 18,
        '&._solo': {
          fontSize: 20
        }
      },
      '& p': {
        fontSize: 14
      },
      '& div': {
        display: 'none'
      }
    }
  }),
  group: {
    height: 'auto',
    width: '100vw',
    position: 'static',
    marginBottom: 450,
    marginTop: 20,
    boxSizing: 'border-box',
    ...commonStyles.media(599, {
      marginBottom: 350
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 280,
      marginBottom: 10
    }),
    ...commonStyles.media(480, {
      height: 345,
      marginBottom: 10
    })
  },
  layer: {
    position: 'relative',
    height: 'auto',
    overflow: 'visible'
  },
  playIcon: {
    position: 'relative',
    display: 'inline-block',
    width: '15%',
    padding: 14,
    height: 'auto',
    margin: 'auto'
  },
  textBox: {
    position: 'absolute',
    bottom: 15,
    left: 15
  },
  title: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'left',
    margin: 0
  },
  type: {
    opacity: 0.8,
    color: '#ffffff',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    margin: 0
  },
  thumbnailFilter: {
    position: 'absolute',
    left: 0,
    top: '70%',
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 95%)'
  }
}
/* eslint-enable sort-keys */

const MovieContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  margin-right: 21px;
  width: 560px;
  height: 350px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  @media (max-width: 599px) {
    width: 420px;
    height: 280px;
  };
  @media (max-width: 480px) {
    width: 270px;
    height: 161px;
    margin-top: -24px;
    margin-right: 10px;
  };
  @media (max-width: 896px) and (max-height: 414px) {
    width: 270px;
    height: 161px;
    margin-top: -24px;
    margin-right: 10px;
  };
`

class UrlifeVideoSlider extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      chosenGaTitle: '',
      chosenVideo: null,
      extraSpeed: 0,
      movies: null,
      openModal: false
    }
    this.thumbnailPriority = this.thumbnailPriority.bind(this)
    this.scrollFaster = this.scrollFaster.bind(this)
    this.scrollSlower = this.scrollSlower.bind(this)
    this.refScroll = React.createRef()
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }
      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
  }

  thumbnailPriority (video) {
    if (video.imageWebviewUrl) return video.imageWebviewUrl
    else if (video.imageUrl) return video.imageUrl
    else if (video.imageThumbnailUrl) return video.imageThumbnailUrl
    else return video.videoThumbnailUrl
  }

  setVideo (video, title) {
    this.setState({
      chosenGaTitle: title,
      chosenVideo: video,
      openModal: true
    })
  }

  onClose () {
    this.setState({
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    })
  }

  scrollFaster (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ extraSpeed: 10 })
  }

  scrollSlower (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ extraSpeed: 0 })
  }

  componentDidMount () {
    if (this.refScroll && this.refScroll.current) {
      const ref = this.refScroll.current
      ref.addEventListener('mousedown', this.scrollFaster)
      ref.addEventListener('pointerdown', this.scrollFaster)
      ref.addEventListener('touchstart', this.scrollFaster)
      ref.addEventListener('mouseup', this.scrollSlower)
      ref.addEventListener('pointerup', this.scrollSlower)
      ref.addEventListener('touchend', this.scrollSlower)
      ref.addEventListener('mouseleave', this.scrollSlower)
      ref.addEventListener('pointerleave', this.scrollSlower)
    }
  }

  componentWillUnmount () {
    const ref = this.refScroll.current
    ref.removeEventListener('mousedown', this.scrollFaster, false)
    ref.removeEventListener('pointerdown', this.scrollFaster, false)
    ref.removeEventListener('touchstart', this.scrollFaster, false)
    ref.removeEventListener('mouseup', this.scrollSlower, false)
    ref.removeEventListener('pointerup', this.scrollSlower, false)
    ref.removeEventListener('touchend', this.scrollSlower, false)
    ref.removeEventListener('mouseleave', this.scrollSlower, false)
    ref.removeEventListener('pointerleave', this.scrollSlower, false)
  }

  render () {
    const { classes, header, movies, subheader } = this.props
    const { extraSpeed, chosenVideo, chosenGaTitle, openModal } = this.state
    return (
      <div className={classes.group}>
        <div className={classes.layer}>
          <div className={classNames(classes.root, { _solo: !subheader })}>
            <h2 className={classNames({ _solo: !subheader })}>{header}</h2>
            <div ref={this.refScroll}>
              <span>SCROLL</span>
              <ParallaxArrowRight />
            </div>
          {subheader && <p>{subheader}</p>}
          </div>
          {movies &&
            <CarouselWrapper extraSpeed={extraSpeed} isOpenModalPreview={openModal}>
              {movies.map((item, idx) => {
                return (
                  !item.ishidden && <MovieContainer
                    key={idx}
                    thumbnail={this.thumbnailPriority(item)}
                    onClick={() => {
                      (item.videoWebviewUrl || item.videoUrl) &&
                        this.setVideo(
                          item.videoWebviewUrl ? item.videoWebviewUrl : item.videoUrl,
                          item.title)
                    }}
                  >
                    <div className={classes.thumbnailFilter}>
                    </div>
                    <ParallaxPlayIcon className={classes.playIcon}/>
                    <div className={classes.textBox}>
                      <p className={classes.title}>{item.title}</p>
                      <p className={classes.type}>{item.subtitle}</p>
                    </div>
                  </MovieContainer>
                )
              })}
            </CarouselWrapper>
          }
          <ModalPreview
            isOpenModalPreview={openModal}
            closeModalPreview={this.onClose.bind(this)}
            video={chosenVideo}
            gaVideoTitle={chosenGaTitle}
            title={''}
          />
        </div>
      </div>
    )
  }
}

UrlifeVideoSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  movies: PropTypes.arrayOf('object'),
  subheader: PropTypes.string
}

export default withStyles(styles)(UrlifeVideoSlider)
