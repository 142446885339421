import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function YoutubeIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 29 20">
      <defs><linearGradient id="bnjha" x1="0" x2="28.73" y1="0" y2="19.88" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1"/><stop offset="1" stopColor="#bd6ad3"/></linearGradient></defs><g><g><path fill="url(#bnjha)" d="M28.126 3.104c.599 2.214.599 6.837.599 6.837s0 4.622-.599 6.837a3.583 3.583 0 0 1-2.54 2.512c-2.24.592-11.223.592-11.223.592s-8.98 0-11.224-.592A3.583 3.583 0 0 1 .6 16.778C0 14.563 0 9.94 0 9.94s0-4.623.599-6.837A3.583 3.583 0 0 1 3.139.592C5.383 0 14.363 0 14.363 0s8.983 0 11.223.592a3.583 3.583 0 0 1 2.54 2.512M11.493 14.202l7.472-4.26-7.472-4.262v8.522"/></g></g>
    </SvgIcon>
  )
}
