import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import { checkConnection } from '../../actions'
import { fileProgressType } from '../../reducers/fileProgress'

class UrlifeConnection extends React.Component {
  componentDidUpdate (prevProps) {
    if (!this.props.backend &&
      (prevProps.backend || this.props.backendRetryCount !== prevProps.backendRetryCount)) {
      const retryCount = this.props.backendRetryCount + 1
      let waitTime = 1000
      if (retryCount > 30) {
        waitTime = 30000
      } else if (retryCount > 20) {
        waitTime = 10000
      } else if (retryCount > 10) {
        waitTime = 5000
      }
      setTimeout(() => {
        this.props.checkConnectionFunction(retryCount)
      }, waitTime)
    }
  }

  render () {
    const {
      backend,
      backendRetryCount,
      fileProgress
    } = this.props
    let message = ''
    if (!backend && backendRetryCount > 5) {
      message = 'Unable to connect to server. Please check that the network is working. Retrying...'
    } else if (fileProgress.some(file => file.progressType === fileProgressType.upload && file.retryCount > 0)) {
      message = 'File upload timed out. Retrying...'
    }
    return (
      <React.Fragment>
        {message && <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          open={true}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={
            <span id="message-id">
              {message}
            </span>
          }
        />}
      </React.Fragment>
    )
  }
}

UrlifeConnection.propTypes = {
  backend: PropTypes.bool.isRequired,
  backendRetryCount: PropTypes.number.isRequired,
  checkConnectionFunction: PropTypes.func.isRequired,
  fileProgress: PropTypes.array
}

const mapStateToProps = state => {
  return {
    backend: state.front.backend,
    backendRetryCount: state.front.retryCount,
    fileProgress: Object.values(state.fileProgress)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    checkConnectionFunction: function (retryCount) {
      return dispatch(checkConnection(retryCount))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlifeConnection)
