import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function UploadingIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 40">
      <g><g><path fill="#333" d="M4.995 37.534V26.957l7.56-5.532 7.561 5.532v10.577zm18.723 0h-1.276v-11.17c0-.371-.177-.72-.476-.94l-7.419-5.429 7.418-5.43c.3-.22.476-.568.476-.939V6.914a1.163 1.163 0 1 0-2.325 0v6.123l-7.56 5.534-7.56-5.534V2.456h15.12v1.086a1.163 1.163 0 1 0 2.326 0V2.456h1.276a1.163 1.163 0 1 0 0-2.326H1.393a1.163 1.163 0 1 0 0 2.326H2.67v11.17c0 .371.176.72.475.94l7.419 5.43-7.418 5.43c-.3.218-.476.567-.476.938v11.17H1.393a1.163 1.163 0 1 0 0 2.326h22.325a1.163 1.163 0 1 0 0-2.325z" /></g><g><path fill="#333" d="M12.63 15.98l5.6-4v-4.8H7.03v4.8z" /></g><g><path fill="#333" d="M18.23 31.98v4H7.03v-4z" /></g></g>
    </SvgIcon>
  )
}
