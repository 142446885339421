import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TopMenuDiscover (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><g><g><path fill="#fff" d="M24.861 12.5c0 6.816-5.545 12.361-12.361 12.361C5.684 24.861.139 19.316.139 12.5.139 5.684 5.684.139 12.5.139c6.816 0 12.361 5.545 12.361 12.361zm-1.389 0c0-6.05-4.922-10.972-10.972-10.972S1.528 6.45 1.528 12.5 6.45 23.472 12.5 23.472 23.472 18.55 23.472 12.5zm-10.775 8.992a.695.695 0 0 1-1.046.915l-.368-.422a.695.695 0 0 1 1.046-.914zM4.06 11.362a.695.695 0 0 1-1.046.915l-.368-.422a.695.695 0 0 1 1.045-.914zm18.36 1.797a.695.695 0 0 1-1.047.914l-.368-.421a.695.695 0 0 1 1.046-.915zM6.484 19.73a.927.927 0 0 1-.796-1.404v.001l4.63-7.697a.9.9 0 0 1 .309-.31h-.002l7.698-4.629a.924.924 0 0 1 1.272 1.271l-4.63 7.698a.944.944 0 0 1-.308.309l-7.698 4.63a.917.917 0 0 1-.475.132zm1.214-2.154l6.074-3.649 3.649-6.073-6.074 3.648zm6.2-14.508a.695.695 0 0 1-1.046.915l-.368-.422a.695.695 0 0 1 1.046-.914z"/></g></g></g></g>
    </SvgIcon>
  )
}
