import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function InfoIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><g><path fill="#999" d="M23.475 12.503a.694.694 0 1 0 1.389 0c0-6.816-5.545-12.361-12.361-12.361C5.687.142.142 5.687.142 12.502c0 6.817 5.545 12.362 12.36 12.362 5.792 0 10.745-3.936 12.045-9.57a.694.694 0 1 0-1.353-.313c-1.154 5.001-5.55 8.494-10.691 8.494-6.05 0-10.972-4.922-10.972-10.972S6.453 1.53 12.503 1.53s10.972 4.922 10.972 10.972z"/></g><g><path fill="#999" d="M12.685 9.1c-.362 0-.662-.12-.901-.361a1.213 1.213 0 0 1-.359-.889c0-.352.12-.648.359-.889.239-.24.539-.361.9-.361.362 0 .66.12.892.361.233.241.349.537.349.889s-.12.648-.359.889c-.239.24-.532.361-.881.361z"/></g><g><path fill="#999" d="M11.425 10.933a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1v6.334a1 1 0 0 1-1 1h-.5a1 1 0 0 1-1-1z"/></g></g></g>
    </SvgIcon>
  )
}

InfoIcon.propTypes = {
  color: PropTypes.string
}
