import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  CONTACT_CHOICE,
  PROJECT_STAGES,
  PROJECT_STATUSES,
  ROLES
} from '../../../../constants'
import { connect } from 'react-redux'
import {
  getLeastCompleteProjectSku,
  history,
  mapProjectStatusToStage
} from '../../../../helpers'
import moment from 'moment'

/* eslint-disable sort-keys */
const styles = {
  appointmentInfoContainer: {
    position: 'relative',
    width: '100%',
    '&._large': {
      height: 59
    },
    '&._medium': {
      height: 57
    },
    '&._small': {
      height: 57
    }
  },
  appointmentInfoText: {
    color: '#323232',
    '&._large': {
      fontSize: 14
    },
    '&._medium': {
      fontSize: 13
    },
    '&._small': {
      fontSize: 13
    }
  },
  scheduleCallContainer: {
    padding: 10,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& p': {
      margin: 0
    },
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      '& span': {
        opacity: 0.5,
        color: '#222222'
      },
      '& button': {
        color: '#01b7d7',
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        font: 'inherit',
        outline: 'inherit',
        textDecoration: 'underline'
      }
    }

  },
  projectIncompleteContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%'
  }
}
/* eslint-enable sort-keys */

class AppointmentInfo extends React.Component {
  scheduleCall (e) {
    const { project } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    e.preventDefault()
    history.push(
      `/projects/${project.id}/skus/${projectSku.id}/movie-schedule-call`,
      { type: 'projectStatusBar' }
    )
  }

  render () {
    const {
      classes,
      sizeClass,
      project,
      roleId
    } = this.props

    let appointment = null
    let creationStatusComplete = false
    let canChangeAppointment = false
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const stage = mapProjectStatusToStage(projectSku.statusId)
      appointment = projectSku.appointment || null
      creationStatusComplete =
        project &&
        project.creationStatus &&
        project.creationStatus.category &&
        project.creationStatus.description &&
        project.creationStatus.uploaded &&
        project.creationStatus.contactChoice &&
        (
          (
            project.creationStatus.contactChoice === CONTACT_CHOICE.NOTES &&
            project.creationStatus.promptsAnswered === project.creationStatus.totalNumberOfPrompts &&
            project.creationStatus.cast
          ) ||
          (
            project.creationStatus.contactChoice !== CONTACT_CHOICE.NOTES &&
            project.creationStatus.contactChoice !== CONTACT_CHOICE.CHOICE_NOT_MADE
          )
        )
      if (roleId === ROLES.USER) {
        canChangeAppointment = stage <= PROJECT_STAGES.QUEUED ||
          projectSku.statusId === PROJECT_STATUSES.PRODUCTION_REVISIONS
      } else {
        canChangeAppointment = stage >= PROJECT_STAGES.QUEUED
      }
    }

    return (
      <div className={classNames(classes.appointmentInfoContainer, sizeClass)}>
        {!!creationStatusComplete && <div className={classes.scheduleCallContainer}>
          <p className={classNames(classes.appointmentInfoText, sizeClass)}>
            {appointment
              ? 'Phone call scheduled'
              : 'Phone call not scheduled'
            }
          </p>
          <div>
            <span className={classNames(classes.appointmentInfoText, sizeClass)}>
              {appointment
                ? moment(appointment).format('MMM D - ha')
                : ''
              }
            </span>
            {canChangeAppointment && <button
              onClick={this.scheduleCall.bind(this)}
              className={classNames(classes.appointmentInfoText, sizeClass)}
            >
              {appointment
                ? 'Change'
                : 'Schedule call'
              }
            </button>}
          </div>
        </div>}
        {!creationStatusComplete &&
          <div className={classes.projectIncompleteContainer}>
            <span className={classNames(classes.appointmentInfoText, sizeClass)}>
              The project is not completed yet
            </span>
          </div>
        }
      </div>
    )
  }
}

AppointmentInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number,
  sizeClass: PropTypes.oneOf(['_small', '_medium', '_large']).isRequired
}

const mapStateToProps = state => {
  return {
    roleId: state.user.roleId
  }
}

export default withStyles(styles)(connect(mapStateToProps)(AppointmentInfo))
