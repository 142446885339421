import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { commonStyles } from '../../../helpers'
import { PACKAGE_TYPES } from '../../../constants'
import Package from './Package'

/* eslint-disable sort-keys */
const styles = {
  packagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 28,
    ...commonStyles.media(991, {
      flexDirection: 'column',
      alignItems: 'center'
    })
  },
  packageWrapper: {
    width: '49%',
    marginBottom: 20,
    ...commonStyles.media(991, {
      width: '70%'
    }),
    ...commonStyles.media(767, {
      width: '85%'
    }),
    ...commonStyles.media(575, {
      width: '100%'
    })
  }
}
/* eslint-enable sort-keys */

class PackageList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bulletSectionHeights: []
    }
  }

  onBulletSectionHeightChange (packId, newHeight) {
    const { bulletSectionHeights } = this.state
    if (newHeight !== bulletSectionHeights[packId]) {
      bulletSectionHeights[packId] = newHeight
      this.setState({ bulletSectionHeights: bulletSectionHeights })
    }
  }

  render () {
    const {
      classes,
      packages
    } = this.props
    const {
      bulletSectionHeights
    } = this.state
    const displayPackages = packages.filter(pack => pack.type !== PACKAGE_TYPES.CURATED).sort((a, b) => a.type - b.type)
    let bulletSectionMinHeight = 0
    packages && packages.forEach(pack => {
      if (bulletSectionMinHeight < bulletSectionHeights[pack.id]) {
        bulletSectionMinHeight = bulletSectionHeights[pack.id]
      }
    })
    return (
      <div className={classes.packagesContainer}>
        {displayPackages.map(pack => {
          const changedPack = { ...pack }
          switch (changedPack.type) {
            case PACKAGE_TYPES.HOLLYWOOD:
              changedPack.thumbnailUrl = 'https://static.urlifemedia.com/travelink/thumbnail-hollywood.jpg'
              changedPack.title = 'Hollywood movie'
              changedPack.subtitle = 'Fully customizable, personal producer experience'
              break
            case PACKAGE_TYPES.RECAP:
              changedPack.thumbnailUrl = 'https://static.urlifemedia.com/travelink/thumbnail-recap.jpg'
              changedPack.subtitle = 'Your personal highlight reel'
              break
            default:
              break
          }
          return (
            <div className={classes.packageWrapper} key={changedPack.id}>
              <Package
                pack={changedPack}
                bulletSectionMinHeight={bulletSectionMinHeight}
                onBulletSectionHeightChange={this.onBulletSectionHeightChange.bind(this)}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

PackageList.propTypes = {
  classes: PropTypes.object.isRequired,
  packages: PropTypes.array
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(PackageList))
