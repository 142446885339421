const initialState = {
  code: '',
  componentModal: 'login',
  isOpenModal: false
}

const toggleModal = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        code: action.code,
        componentModal: action.componentModal,
        isOpenModal: action.isOpenModal,
        lastComponentModal: state.componentModal
      }

    default:
      return state
  }
}

export default toggleModal
