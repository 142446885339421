import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import { history } from '../../helpers'
import TopTen from '../topten/TopTen'

const tips = {
  top10summer: {
    component: <TopTen/>
  }
}

class TipsRouter extends Component {
  constructor (props) {
    super(props)
    this.selectedTip = null
    this.init = () => {
      const tip = props.match.params.tip
      if (tip) {
        if (tips[tip]) {
          this.selectedTip = tip
        } else {
          this.selectedTip = null
          history.replace('/tips')
        }
      }
    }
    this.init()
  }

  render () {
    return (
      <Switch>
        <Route exact path='/tips' render={() => <TopTen/>}/>
        {this.selectedTip && <Route path='/tips/:tip' render={() => tips[this.selectedTip].component}/>}
      </Switch>
    )
  }
}

TipsRouter.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default TipsRouter
