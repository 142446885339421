import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'

/* eslint-disable sort-keys */
const styles = {
  editTextFieldControl: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  editTimeField: {
    width: 30
  },
  editTimeFieldInput: {
    fontSize: 14
  },
  editTextFieldError: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '100%',
    marginTop: 4
  },
  timeFieldSeparator: {

  },
  editTimeFieldNativeInput: {
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 4
  }
}
/* eslint-enable sort-keys */

class EditTimeField extends React.PureComponent {
  constructor (props) {
    super(props)

    const time = this.splitTime(props.value)
    this.state = {
      min: time.min,
      sec: time.sec
    }
  }

  checkValid () {
    const { value } = this.props
    const { min, sec } = this.state
    let toCheck = value
    if (this.isNumber(min) && this.isNumber(sec)) toCheck = this.combineTime(min, sec)
    return toCheck && toCheck.toString().trim().length > 0
  }

  combineTime (min, sec) {
    return parseInt(min) * 60 + parseInt(sec)
  }

  isNumber (val) {
    return /^[0-9]+$/.test(val)
  }

  onChange (min, sec) {
    if (!this.isNumber(min) || !this.isNumber(sec)) return
    if (parseInt(sec) > 59) {
      min = parseInt(min) + 1
      sec = parseInt(sec) - 60
    }
    this.setState({
      min, sec
    })
  }

  apply (min, sec) {
    const { onApply } = this.props
    if (this.checkValid()) {
      onApply(min * 60 + +sec)
    }
  }

  splitTime (time) {
    return {
      min: Math.floor(time / 60),
      sec: time % 60
    }
  }

  render () {
    const { classes } = this.props
    const { min, sec } = this.state
    const isValid = this.checkValid()

    return (
      <FormControl className={classes.editTextFieldControl} error={!isValid}>
        <TextField
          error={!isValid}
          placeholder={'00'}
          type={'text'}
          value={min || '00'}
          className={classes.editTimeField}
          inputProps={{
            className: classes.editTimeFieldNativeInput,
            maxLength: 2
          }}
          onChange={ e => { this.onChange(e.currentTarget.value, sec) }}
          onBlur={ e => { this.apply(e.currentTarget.value, sec) }}
        />
        <span className={classes.timeFieldSeparator}>:</span>
        <TextField
          error={!isValid}
          placeholder={'00'}
          type={'text'}
          value={ sec || '00'}
          className={classes.editTimeField}
          inputProps={{
            className: classes.editTimeFieldNativeInput,
            maxLength: 2
          }}
          onChange={ e => { this.onChange(min, e.currentTarget.value) }}
          onBlur={ e => { this.apply(min, e.currentTarget.value) }}
        />

        {!isValid &&
        <FormHelperText className={classes.editTextFieldError}>Required</FormHelperText>
        }
      </FormControl>
    )
  }
}

EditTimeField.propTypes = {
  classes: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default withStyles(styles)(EditTimeField)
