import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import connect from 'react-redux/es/connect/connect'
import { commonStyles } from '../../../helpers'

import {
  fetchAllCuratedFiles,
  fetchAllProjectFiles
} from '../../../actions'
import UploadMsg from '../../common/UploadMsg'
import { ROLES } from '../../../constants'
import FilesView from '../../common/files/FilesView'
import UrlifeCheckbox from '../../common/UrlifeCheckbox'

/* eslint-disable sort-keys */
const styles = {
  container: {
    maxWidth: '1210px',
    margin: '0 auto',
    padding: '0 0px 40px'
  },
  titleData: {
    ...commonStyles.text({
      fontSize: 24,
      color: '#000000',
      lineHeight: 1.5,
      textAlign: 'left',
      marginBottom: 30
    }),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  filterItem: {
    height: '24px',
    fontSize: 17,
    display: 'flex',
    marginLeft: 'auto',
    ...commonStyles.media(810, {
      fontSize: 15,
      marginLeft: 0,
      width: '100%'
    })
  },
  filterBar: {
    color: '#414141',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '26px'
  },
  filterCheckbox: {
    display: 'inline',
    marginLeft: 15,
    marginRight: 25
  },
  nofiles: {
    fontSize: 24,
    fontWeight: 700,
    color: '#232323',
    marginTop: 35
  }
}
/* eslint-enable sort-keys */

class EditUploadFiles extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      filters: [
        { active: false, check: 'notes', name: 'Files with notes' },
        { active: false, check: 'userFavs', name: 'Customer favorite' },
        { active: false, check: 'prodFavs', name: 'Producer favorite' }
      ],
      isLoading: false,
      uploadMsg: false
    }
  }

  componentDidMount () {
    const {
      project,
      projectFilesFunction,
      curatedFilesFunction
    } = this.props
    this.setState({ isLoading: true }, async () => {
      await projectFilesFunction(project.id, { method: 'ASC', sort: 'last_modified' })
      await curatedFilesFunction(project.id)
      this.setState({ isLoading: false })
    })
  }

  closeMsg () {
    this.setState({ uploadMsg: false })
  }

  changeFilter (e) {
    const newFilter = this.state.filters.map(filt => {
      if (filt.check === e.target.name) {
        return { ...filt, active: e.target.checked }
      }
      return filt
    })
    this.setState({ filters: newFilter })
  }

  render () {
    const { uploadMsg, filters, isLoading } = this.state
    const { classes, files, project, curatedFiles, roleId, updateProject } = this.props
    let filesToUse = []
    if ((roleId !== ROLES.USER && roleId !== ROLES.GUEST)) {
      const notes = filters[0].active
      const userFavs = filters[1].active
      const prodFavs = filters[2].active
      filesToUse = files.filter(file => {
        if (notes && !file.description && !file.producerNote) {
          return false
        }
        if (userFavs && !file.favorite) {
          return false
        }
        if (prodFavs && !file.producerFavorite) {
          return false
        }
        return true
      })
    } else {
      filesToUse = files
    }
    return (
      <React.Fragment>
        { uploadMsg &&
          <UploadMsg
            onClose={this.closeMsg.bind(this)}
            title="Uploading!"
            specText="Your files have begun uploading."
            warningText="Please do not leave, or refresh, this webapp while uploads are in progress."
          />
        }
        <div className={classes.myMovieContainer}>
          <div>
            {isLoading &&
              <div className={classes.titleData}>Loading files...</div>
            }
            {!isLoading && <React.Fragment>
              <div className={classes.filterBar}>
                Filter: {filters.map(filt => (
                  <UrlifeCheckbox
                    key={filt.check}
                    onChange={this.changeFilter.bind(this)}
                    checked={filt.active}
                    className={classes.filterCheckbox}
                    label={filt.name}
                    name={filt.check}
                  />
              )) }
              </div>
              {filesToUse.length > 0 &&
                <FilesView
                  type={'toggle'}
                  edit={true}
                  canUpload={true}
                  project={project}
                  files={filesToUse}
                  title='customer files'
                  updateProject={updateProject}
                />
              }

              {(filesToUse.length === 0) &&
                <div className={classes.nofiles}>No files with this filter setting</div>
              }
            </React.Fragment>}
          </div>
        </div>
        {(roleId !== ROLES.USER && roleId !== ROLES.GUEST) && curatedFiles.length > 0 &&
        <div className={classes.myMovieContainer}>
          <FilesView
            type={'toggle'}
            edit={true}
            canUpload={true}
            project={project}
            files={curatedFiles}
            title='curated files'
            isCurated={true}
            updateProject={updateProject}
          />
        </div>
        }
      </React.Fragment>
    )
  }
}

EditUploadFiles.propTypes = {
  classes: PropTypes.object.isRequired,
  curatedFiles: PropTypes.array,
  curatedFilesFunction: PropTypes.func.isRequired,
  files: PropTypes.array,
  project: PropTypes.object.isRequired,
  projectFilesFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    curatedFiles: state.file.curatedFiles,
    files: state.file.files,
    roleId: state.user.roleId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    curatedFilesFunction: function (projectId, params) {
      dispatch(fetchAllCuratedFiles(projectId, null, params))
    },
    projectFilesFunction: function (projectId, params) {
      return dispatch(fetchAllProjectFiles(projectId, params))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditUploadFiles))
