import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import UrlifeButton from '../../../common/UrlifeButton'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'
import {
  setTopBarHeaders
} from '../../../../actions'
import { CONTACT_CHOICE } from '../../../../constants'
import { commonStyles } from '../../../../helpers'

/* eslint-disable sort-keys */
const styles = theme => ({
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    marginTop: 40,
    marginBottom: 50
  },
  label: {
    color: '#323232',
    fontSize: 16,
    marginLeft: 10,
    fontWeight: 500,
    lineHeight: '26px'
  },
  emailContainer: {
    height: 44,
    marginLeft: 47,
    marginTop: -10
  },
  subLabel: {
    opacity: 0.8,
    color: '#323232',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px'
  },
  emailText: {
    position: 'absolute',
    cursor: 'pointer'
  },
  emailForm: {
    position: 'absolute',
    marginTop: '-2px',
    width: 300
  },
  nextButton: {
    padding: '8px 30px',
    margin: '60px 8px 5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  radio: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    border: '1px solid #cccccc'
  },
  radioChecked: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: '1px solid #01b7d7'
  },
  radioCheckedInner: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
  }
})
/* eslint-enable sort-keys */

class ContactMethod extends React.Component {
  constructor (props) {
    super(props)
    const contactChoice = props.contactChoice || props.savedContactChoice
    const email = props.email || props.userEmail
    this.state = {
      choice: contactChoice
        ? contactChoice === CONTACT_CHOICE.EMAIL_ME
          ? 'email'
          : contactChoice === CONTACT_CHOICE.APPOINTMENT
            ? 'schedule'
            : ''
        : '',
      editing: false,
      email: email,
      emailIsValid: this.validateEmail(email),
      haveEditedEmail: false
    }
  }

  handleChange (e) {
    const choice = e.target.value
    this.setState({ choice }, () => {
      const contactChoice = choice === 'schedule'
        ? CONTACT_CHOICE.APPOINTMENT
        : CONTACT_CHOICE.EMAIL_ME
      this.saveChoice(contactChoice, false)
    })
  }

  async saveChoice (choice, next = false) {
    const {
      nextStep,
      setContactChoice,
      updateProject,
      savedContactChoice
    } = this.props
    const { emailIsValid } = this.state
    if (choice === CONTACT_CHOICE.EMAIL_ME && !emailIsValid) {
      choice = savedContactChoice
    }

    setContactChoice(choice)
    await updateProject({}, { contactChoice: choice })
    if (next) {
      nextStep()
    }
  }

  onNext () {
    const { choice, haveEditedEmail } = this.state
    if (choice === 'schedule') {
      this.saveChoice(CONTACT_CHOICE.APPOINTMENT, true)
    } else if (haveEditedEmail) {
      this.saveChoice(CONTACT_CHOICE.EMAIL_ME, true)
    } else {
      this.saveEmail(true)
    }
  }

  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  validateEmail (email) {
    return email && email.length > 0 && /^.+@.+$/.test(email)
  }

  async saveEmail (next) {
    const {
      updateProjectSku
    } = this.props
    const {
      email,
      emailIsValid
    } = this.state
    if (emailIsValid) {
      await updateProjectSku({ email })
      if (next) {
        this.saveChoice(CONTACT_CHOICE.EMAIL_ME, true)
      }
    }
  }

  setEditing (editing, next = false) {
    if (!editing) {
      const { email } = this.state
      if (email.length === 0) {
        const { userEmail } = this.props
        this.setState({
          email: userEmail,
          emailIsValid: this.validateEmail(userEmail)
        }, () => {
          this.saveEmail(next)
        })
      } else {
        this.saveEmail(next)
      }
    }
    this.setState({
      editing: editing,
      haveEditedEmail: true
    })
  }

  handleChangeInputEmail (e) {
    const newEmail = e.target.value.trim()
    this.setState({
      email: newEmail,
      emailIsValid: this.validateEmail(newEmail)
    })
  }

  handleBlur (e) {
    // this is needed to allow user to click next when onBlur is called on field
    const { relatedTarget } = e
    if (relatedTarget && (relatedTarget.getAttribute('type')) === 'button') {
      this.setEditing(false, true)
    } else if (relatedTarget && (relatedTarget.getAttribute('type')) === 'radio') {
      this.setEditing(false, false)
      this.handleChange({ target: { value: relatedTarget.value } })
    } else {
      this.setEditing(false, false)
    }
  }

  onSubmit (e) {
    e.preventDefault()
    this.setEditing(false)
  }

  render () {
    const { classes } = this.props
    const { choice, email, emailIsValid, editing } = this.state
    return (
      <div className={classes.contactContainer}>
        <span className={classes.text}>To finish up, please let us know the best way to schedule a producer call</span>
        <RadioGroup aria-label="contactMethodNotes" name="contactMethodNotes" value={choice}
          onChange={this.handleChange.bind(this)}>
          <FormControlLabel classes={{ label: classes.label }} value={'email'}
            control={<Radio
              icon={<div className={classes.radio}></div>}
              checkedIcon={<div className={classes.radioChecked}>
                <div className={classes.radioCheckedInner}></div>
              </div>} />}
            label="Email me" />
          <div className={classes.emailContainer}>
            {editing &&
            <form onSubmit={this.onSubmit.bind(this)}>
              <FormControl
                variant={'outlined'}
                className={classes.emailForm}>
                <TextField
                  autoFocus
                  onChange={this.handleChangeInputEmail.bind(this)}
                  onBlur={this.handleBlur.bind(this)}
                  className={classes.cssUnderline}
                  defaultValue={email}
                  placeholder={email}
                  InputProps={{
                    classes: {
                      input: classes.subLabel
                    }
                  }}
                />
              </FormControl>
            </form>
            }
            {!editing &&
            <div className={classes.emailText}>
              <span className={classes.subLabel} onClick={this.setEditing.bind(this, true)}>
                {email}
              </span>
            </div>
            }
          </div>
          <FormControlLabel classes={{ label: classes.label }} value={'schedule'}
            control={<Radio
              icon={<div className={classes.radio}></div>}
              checkedIcon={<div className={classes.radioChecked}>
                <div className={classes.radioCheckedInner}></div>
              </div>} />}
            label="Schedule a call now" />
        </RadioGroup>
        <UrlifeButton
          className={classes.nextButton}
          disabled={!choice || (choice === 'email' && !emailIsValid)}
          onClick={this.onNext.bind(this)}>
          Next
        </UrlifeButton>
      </div>
    )
  }
}

ContactMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  contactChoice: PropTypes.number,
  email: PropTypes.string,
  nextStep: PropTypes.func.isRequired,
  savedContactChoice: PropTypes.number,
  setContactChoice: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired,
  userEmail: PropTypes.string
}

const mapStateToProps = state => {
  return {
    userEmail: state.user.email
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Contact method', 'Thank you! Get ready to share your story'))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactMethod))
