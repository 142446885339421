import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setTopBarHeaders
} from '../../../../actions'
import ScheduleCallForm from '../../../common/ScheduleCallForm'
import { CONTACT_CHOICE } from '../../../../constants'
import { history } from '../../../../helpers'

class ScheduleCall extends React.Component {
  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  async onNext () {
    const {
      nextStep,
      updateProject
    } = this.props
    await updateProject({}, { madeAppointment: true })
    nextStep()
  }

  async onSkip () {
    const {
      updateProject,
      projectId
    } = this.props
    await updateProject({}, {
      contactChoice: CONTACT_CHOICE.CHOICE_NOT_MADE,
      madeAppointment: false
    })
    history.push('/createmovie/producer/' + projectId)
  }

  render () {
    const {
      phoneNumber,
      appointmentDate,
      isBooked,
      setPhoneNumber,
      setAppointmentDate,
      updateProject,
      updateProjectSku
    } = this.props
    return (
      <React.Fragment>
        <ScheduleCallForm
          phoneNumber={phoneNumber}
          appointmentDate={appointmentDate}
          isBooked={isBooked}
          setPhoneNumber={setPhoneNumber}
          setAppointmentDate={setAppointmentDate}
          onDone={this.onNext.bind(this)}
          onCancelBooking={this.onSkip.bind(this)}
          updateProject={updateProject}
          updateProjectSku={updateProjectSku}
        />
      </React.Fragment>
    )
  }
}
ScheduleCall.propTypes = {
  appointmentDate: PropTypes.object,
  isBooked: PropTypes.bool,
  nextStep: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  setAppointmentDate: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Phone number', 'What is the best number to reach you?'))
    }
  }
}

export default connect(null, mapDispatchToProps)(ScheduleCall)
