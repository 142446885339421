import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'
import ModalVideo from '../common/ModalVideo'

/* eslint-disable sort-keys */
const styles = {
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px'
  },
  movieListCol: {
    width: '33.333%',
    padding: '7px',
    ...commonStyles.media(991, {
      padding: '5px'
    }),
    ...commonStyles.media(700, {
      width: '50%'
    }),
    ...commonStyles.media(575, {
      padding: '3px'
    }),
    ...commonStyles.media(375, {
      width: '100%'
    })
  },
  rejectedText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    color: '#d70101',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: 5
  }
}
/* eslint-enable sort-keys */

export class MovieList extends React.Component {
  getMovieUrl (movie) {
    if (movie.webview) {
      return movie.webviewCdnUrl
    }
    return movie.cdnUrl || movie.signedUrl
  }

  render () {
    const { classes, movieList, showRejectionReasons } = this.props
    return (
      <div className={classes.listContainer}>
        {movieList.map((movie, index) => {
          const thumbnail = movie.thumbnailCdnUrl ? movie.thumbnailCdnUrl : movie.signedThumbnailUrl
          const video = {
            imageWebviewUrl: thumbnail,
            subtitle: 'Version ' + (movieList.length - index),
            title: '',
            videoWebviewUrl: this.getMovieUrl(movie)
          }
          return index > 0
            ? (
            <div key={index} className={classes.movieListCol}>
              <ModalVideo video={video}/>
              {movie.rejectionReason && showRejectionReasons &&
              <div className={classes.rejectedText} onClick={() => { showRejectionReasons(index) }}>
                See rejection reasons
              </div>
              }
            </div>
              )
            : null
        })}
      </div>
    )
  }
}

MovieList.propTypes = {
  classes: PropTypes.object.isRequired,
  movieList: PropTypes.array.isRequired,
  showRejectionReasons: PropTypes.func
}

export default withStyles(styles)(MovieList)
