import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'
import ProjectDetailsLayoutContainer from './ProjectDetailsLayoutContainer'
import ProjectStatusBar from './ProjectStatusBar/ProjectStatusBar'
import StarsMovie from './Stars/StarsMovie'
import {
  DateIcon,
  LocationIcon,
  PencilIcon
} from './SVG'
import {
  createPromptAnswer,
  deletePromptAnswer,
  getAllPromptsCategory,
  getCategory,
  getPackageSku,
  getProjectAnswers,
  listCast,
  updatePromptAnswer
} from '../../actions'
import { PACKAGE_TYPES } from '../../constants'
import {
  commonStyles,
  getPackageType,
  toLocale
} from '../../helpers'
import PackageTitle from './Packages/components/PackageTitle'

/* eslint-disable sort-keys */
const styles = {
  root: {
    position: 'relative',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#323232',
    padding: '5px 0 15px',
    flexGrow: 1
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.58px',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  block: {
    width: '100%',
    marginBottom: 20
  },
  projectTitleWrapper: {
    padding: '2px 0 10px'
  },
  projectTitleForm: {
    width: '100%',
    marginTop: '-3px',
    paddingRight: 26,
    paddingBottom: 3
  },
  projectTitle: {
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: '0.5px',
    '&._editable': {
      cursor: 'pointer',
      paddingRight: 26
    }
  },
  projectDescriptionForm: {
    width: '100%',
    marginTop: '-6px',
    paddingRight: 26
  },
  projectDescription: {
    paddingBottom: 7,
    '&._editable': {
      cursor: 'pointer'
    },
    '&._placeholder': {
      opacity: 0.5
    }
  },
  pencilIcon: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer'
  },
  projectInfoBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '&._producer': {
      alignItems: 'flex-start'
    }
  },
  projectInfoItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 40,
    '&._producer': {
      marginTop: 11
    }
  },
  projectInfoIconWrapper: {
    paddingRight: 15
  },
  projectInfoIcon: {
    width: 25,
    height: 25,
    '&._category': {
      '-webkit-filter': 'invert(62%) sepia(45%) saturate(1232%) hue-rotate(225deg) brightness(82%) contrast(99%)',
      filter: 'invert(62%) sepia(45%) saturate(1232%) hue-rotate(225deg) brightness(82%) contrast(99%)'
    }
  },
  packageInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingRight: 40
  },
  skuInfoItem: {
    fontSize: 14,
    fontWeight: 500,
    color: '#323232',
    opacity: 0.8
  },
  prompt: {
    width: '100%',
    marginBottom: 13
  },
  promptHeader: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    marginTop: 4,
    marginBottom: 8
  },
  promptAnswer: {
    fontWeight: 500,
    lineHeight: '26px',
    '&._editable': {
      cursor: 'pointer',
      paddingRight: 26,
      paddingBottom: 7
    },
    '&._placeholder': {
      color: '#01b7d7'
    }
  },
  promptForm: {
    width: '100%',
    marginTop: '-6px',
    paddingRight: 26
  },
  statusBar: {
    marginBottom: 13,
    '&._rightAlign': {
      marginLeft: 'auto'
    },
    ...commonStyles.media(767, {
      width: '100%'
    })
  },
  producerForm: {
    marginTop: 20
  }
}
/* eslint-enable sort-keys */

class ProjectDetailsBox extends React.Component {
  constructor (props) {
    super(props)
    const { project } = props
    this.state = {
      answers: {},
      category: null,
      description: project.description ? project.description : '',
      editMode: 'none',
      keepPromptsOpen: false,
      packageSkus: [],
      prompts: [],
      summaryDate: project.summaryDate ? project.summaryDate : '',
      summaryLocation: project.summaryLocation ? project.summaryLocation : '',
      title: project.title ? project.title : ''
    }
  }

  componentDidMount () {
    const {
      project,
      show,
      listCastFunction,
      getCategoryFunction,
      getPackageSkuFunction,
      categories
    } = this.props
    if (show.includes('prompts') && project.categoryId) {
      this.fetchCategoryPrompts(project.categoryId)
      this.fetchPromptAnswers(project.id)
    }
    if (project && project.id && show.includes('stars')) {
      listCastFunction(project.id)
        .then(data => {
          this.setState({ casts: data })
        })
    }
    if (project && show.includes('producer-info')) {
      project.projectSkus.forEach(projectSku => {
        getPackageSkuFunction(projectSku.packageSku)
          .then(data => {
            this.setState({ packageSkus: [...this.state.packageSkus, data] })
          })
      })
    }
    if (project && project.categoryId && (show.includes('info') || show.includes('producer-info'))) {
      const category = categories.find(category => category.id === project.categoryId)
      if (category) {
        this.setState({ category })
      } else {
        getCategoryFunction(project.categoryId)
          .then(res => this.setState({ category: res.data[0] }))
      }
    }
  }

  async fetchCategoryPrompts (category) {
    const { getAllPromptsCategoryFunction } = this.props
    const prompts = await getAllPromptsCategoryFunction(category)
    this.setState({ prompts: prompts })
  }

  async fetchPromptAnswers (project) {
    const { getProjectAnswersFunction } = this.props
    const data = await getProjectAnswersFunction(project)
    if (data) {
      const answers = {}
      data.forEach(answer => {
        answers[answer.prompt_id] = {
          id: answer.id,
          value: answer.answer
        }
      })
      this.setState({ answers: answers })
    }
  }

  setEditMode (mode) {
    const { project, updateProject } = this.props
    const { editMode, title, description, summaryDate, summaryLocation } = this.state
    if (editMode === mode) {
      mode = 'none'
    }
    if (mode && mode.includes('prompt')) {
      this.setState({ keepPromptsOpen: true })
    }
    switch (editMode) {
      case 'title':
        const titleValue = title.trim()
        if (titleValue.length > 0 && titleValue !== project.title) {
          updateProject && updateProject({ title: titleValue })
        } else {
          this.setState({ title: project.title ? project.title : '' })
        }
        break
      case 'desc':
        const descriptionValue = description.trim()
        if (descriptionValue !== project.description) {
          updateProject && updateProject({ description: descriptionValue })
        }
        break
      case 'summaryDate':
        const dateValue = summaryDate.trim()
        if (dateValue !== project.summaryDate) {
          updateProject && updateProject({ summaryDate: dateValue })
        }
        break
      case 'summaryLocation':
        const locationValue = summaryLocation.trim()
        if (locationValue !== project.summaryLocation) {
          updateProject && updateProject({ summaryLocation: locationValue })
        }
        break
      default:
        break
    }
    this.setState({ editMode: mode })
  }

  handleChangeInputTitle (e) {
    this.setState({ title: e.target.value })
  }

  handleChangeInputDescription (e) {
    this.setState({ description: e.target.value })
  }

  handleChangeInputSummaryDate (e) {
    this.setState({ summaryDate: e.target.value })
  }

  handleChangeInputSummaryLocation (e) {
    this.setState({ summaryLocation: e.target.value })
  }

  handleChangeInputPrompt (promptId, e) {
    const { answers } = this.state
    this.setState({
      answers: {
        ...answers,
        [promptId]: {
          ...answers[promptId],
          value: e.target.value
        }
      }
    })
  }

  async handleBlurInputPrompt (promptId, e) {
    const {
      project,
      updatePromptAnswerFunction,
      createPromptAnswerFunction,
      deletePromptAnswerFunction
    } = this.props
    const { answers } = this.state
    const answer = answers[promptId] || { value: '' }
    if (answer.id) {
      if (answer.value.trim() === '') {
        // delete answer on clear
        await deletePromptAnswerFunction(project.id, answer.id)
        const newAnswer = this.state.answers[promptId]
        delete newAnswer.id
        this.setState({
          answers: {
            ...this.state.answers,
            [promptId]: newAnswer
          }
        })
      } else {
        // update if it was created
        await updatePromptAnswerFunction(project.id, answer.id, answer.value)
      }
    } else if (answer.value.trim() !== '') {
      // create answer
      const response = await createPromptAnswerFunction(project.id, {
        answer: answer.value.trim(),
        prompt_id: promptId
      })
      this.setState({
        // add id to state answer
        answers: {
          ...this.state.answers,
          [promptId]: {
            ...this.state.answers[promptId],
            id: response.id
          }
        }
      })
    }
    this.setEditMode('none')
  }

  onSubmit (e) {
    e.preventDefault()
    this.setEditMode('none')
  }

  render () {
    const {
      classes,
      show,
      canEdit,
      project,
      packages,
      packageType,
      columnLayout
    } = this.props
    const {
      category,
      editMode,
      title,
      description,
      summaryDate,
      summaryLocation,
      casts,
      answers,
      prompts,
      packageSkus,
      keepPromptsOpen
    } = this.state
    const selectedPackageSku = project.projectSkus[0].packageSku
    const selectedPackage = packages.find(pack => pack.skus.find(sku => sku.id === selectedPackageSku))
    const isHollywood = (selectedPackage && selectedPackage.type === PACKAGE_TYPES.HOLLYWOOD) ||
      packageType === PACKAGE_TYPES.HOLLYWOOD
    const isCurated = selectedPackage && selectedPackage.type === PACKAGE_TYPES.CURATED
    return (
      <div className={classes.root} ref={this.rootElement}>
        {this.props.title && <div className={classes.title}>{this.props.title}</div>}
        <ProjectDetailsLayoutContainer columnLayout={columnLayout}>
          {show.map((block) => {
            switch (block) {
              case 'title':
                const titleValue = title.trim()
                const descriptionValue = description.trim()
                return (<div className={classes.block} key={block}>
                  <div className={classes.titleBlock}>
                    {canEdit && editMode !== 'title' &&
                    <PencilIcon
                      className={classes.pencilIcon}
                      onClick={canEdit ? this.setEditMode.bind(this, 'title') : null} />
                    }
                    <form onSubmit={this.onSubmit.bind(this)}>
                      {editMode === 'title' &&
                      <FormControl
                        variant={'outlined'}
                        className={classes.projectTitleForm}>
                        <TextField
                          autoFocus
                          onChange={this.handleChangeInputTitle.bind(this)}
                          onBlur={this.setEditMode.bind(this, 'none')}
                          className={classNames(classes.cssUnderline)}
                          defaultValue={titleValue}
                          placeholder={project.title}
                          inputProps={{ maxLength: 50 }}
                          InputProps={{
                            classes: {
                              input: classes.projectTitle
                            }
                          }}
                        />
                      </FormControl>
                      }
                      {editMode !== 'title' &&
                      <div className={classes.projectTitleWrapper}>
                        <div
                          className={classNames(classes.projectTitle, { _editable: canEdit })}
                          onClick={canEdit ? this.setEditMode.bind(this, 'title') : null}>
                          {titleValue}
                        </div>
                      </div>
                      }
                      {editMode === 'desc' &&
                      <FormControl
                        variant={'outlined'}
                        className={classes.projectDescriptionForm}>
                        <TextField
                          autoFocus
                          onChange={this.handleChangeInputDescription.bind(this)}
                          onBlur={this.setEditMode.bind(this, 'none')}
                          className={classNames(classes.cssUnderline)}
                          defaultValue={descriptionValue}
                          placeholder={'Add a short description'}
                          inputProps={{ maxLength: 50 }}
                        />
                      </FormControl>
                      }
                      {editMode !== 'desc' &&
                      <div
                        className={
                          classNames(
                            classes.projectDescription,
                            { _editable: canEdit },
                            { _placeholder: descriptionValue.length === 0 }
                          )}
                        onClick={canEdit ? this.setEditMode.bind(this, 'desc') : null}>
                        {descriptionValue.length > 0 || !canEdit ? descriptionValue : 'Add a short description'}
                      </div>
                      }
                    </form>
                  </div>
                </div>)
              case 'info': {
                return (<div className={classes.block} key={block}>
                  <div className={classes.projectInfoBlock}>
                    {project.summaryLocation &&
                    <div className={classes.projectInfoItem}>
                      <div className={classes.projectInfoIconWrapper}>
                        <LocationIcon className={classes.projectInfoIcon} />
                      </div>
                      <div>
                        {project.summaryLocation}
                      </div>
                    </div>
                    }
                    {!isCurated && category &&
                    <div className={classes.projectInfoItem}>
                      {category.iconUrl &&
                      <div className={classes.projectInfoIconWrapper}>
                        <img
                          src={category.iconUrl}
                          alt="categoryIcon"
                          className={classNames(classes.projectInfoIcon, '_category')} />
                      </div>
                      }
                      <div>
                        {category.name}
                      </div>
                    </div>
                    }
                    {project.summaryDate &&
                    <div className={classes.projectInfoItem}>
                      <div className={classes.projectInfoIconWrapper}>
                        <DateIcon className={classes.projectInfoIcon} />
                      </div>
                      <div>
                        {project.summaryDate}
                      </div>
                    </div>
                    }
                  </div>
                </div>) }
              case 'producer-info': {
                const summaryDateValue = summaryDate.trim()
                const summaryLocationValue = summaryLocation.trim()
                return (<div className={classes.block} key={block}>
                  <div className={classNames(classes.projectInfoBlock, '_producer')}>
                    <div className={classes.packageInfoItem}>
                      <PackageTitle packageId={project.projectSkus[0].packageId} />
                      {project.projectSkus.map(projectSku => {
                        const sku = packageSkus.find(sku => sku.id === projectSku.packageSku)
                        if (sku) {
                          return <div
                            key={sku.id}
                            className={classes.skuInfoItem}
                          >
                            {sku.producerDescription || sku.description}
                          </div>
                        } else {
                          return null
                        }
                      })}
                    </div>
                    {category &&
                    <div className={classNames(classes.projectInfoItem, '_producer')}>
                      {category.iconUrl &&
                        <div className={classes.projectInfoIconWrapper}>
                          <img
                            src={category.iconUrl}
                            alt="categoryIcon"
                            className={classNames(classes.projectInfoIcon, '_category')} />
                        </div>
                      }
                      <div>
                        {category.name}
                      </div>
                    </div>
                    }
                  </div>
                  <form className={classes.producerForm} onSubmit={this.onSubmit.bind(this)}>
                    <div className={classes.prompt}>
                      {canEdit && editMode !== 'summaryLocation' &&
                      <PencilIcon
                        className={classes.pencilIcon}
                        onClick={canEdit ? this.setEditMode.bind(this, 'summaryLocation') : null} />
                      }
                      <div className={classes.promptHeader}>
                        Location
                      </div>
                      {editMode === 'summaryLocation' &&
                      <FormControl
                        variant={'outlined'}
                        className={classes.promptForm}>
                        <TextField
                          autoFocus
                          onChange={this.handleChangeInputSummaryLocation.bind(this)}
                          onBlur={this.setEditMode.bind(this, 'none')}
                          className={classNames(classes.cssUnderline)}
                          defaultValue={summaryLocationValue}
                          InputProps={{
                            classes: {
                              input: classes.promptAnswer
                            }
                          }}
                        />
                      </FormControl>
                      }
                      {editMode !== 'summaryLocation' &&
                      <div
                        className={
                          classNames(
                            classes.promptAnswer,
                            { _editable: canEdit }
                          )
                        }
                        onClick={canEdit ? this.setEditMode.bind(this, 'summaryLocation') : null}>
                        {summaryLocationValue.length > 0 ? summaryLocationValue : '+ Add location'}
                      </div>
                      }
                    </div>
                    <div className={classes.prompt}>
                      {canEdit && editMode !== 'summaryDate' &&
                      <PencilIcon
                        className={classes.pencilIcon}
                        onClick={canEdit ? this.setEditMode.bind(this, 'summaryDate') : null} />
                      }
                      <div className={classes.promptHeader}>
                        Date
                      </div>
                      {editMode === 'summaryDate' &&
                      <FormControl
                        variant={'outlined'}
                        className={classes.promptForm}>
                        <TextField
                          autoFocus
                          onChange={this.handleChangeInputSummaryDate.bind(this)}
                          onBlur={this.setEditMode.bind(this, 'none')}
                          className={classNames(classes.cssUnderline)}
                          defaultValue={summaryDateValue}
                          InputProps={{
                            classes: {
                              input: classes.promptAnswer
                            }
                          }}
                        />
                      </FormControl>
                      }
                      {editMode !== 'summaryDate' &&
                      <div
                        className={
                          classNames(
                            classes.promptAnswer,
                            { _editable: canEdit }
                          )
                        }
                        onClick={canEdit ? this.setEditMode.bind(this, 'summaryDate') : null}>
                        {summaryDateValue.length > 0 ? summaryDateValue : '+ Add date'}
                      </div>
                      }
                    </div>
                  </form>
                </div>) }
              case 'prompts':
                const hasAnswers = prompts.filter(prompt => answers[prompt.id] && answers[prompt.id].value).length > 0
                const showPrompts = !isCurated && (
                  (show.includes('appointment') && hasAnswers) ||
                  !show.includes('appointment') ||
                  keepPromptsOpen
                )
                return ((showPrompts || editMode.includes('prompt')) && prompts && project.categoryId
                  ? prompts.map((prompt, index) => {
                    const answer = answers[prompt.id] ? answers[prompt.id].value : ''
                    const promptEditMode = 'prompt' + index
                    return (answer.length > 0 || canEdit
                      ? <div className={classes.prompt} key={index}>
                        {canEdit && editMode !== promptEditMode &&
                          <PencilIcon
                            className={classes.pencilIcon}
                            onClick={canEdit ? this.setEditMode.bind(this, promptEditMode) : null} />
                        }
                        <div className={classes.promptHeader}>
                          {prompt.name}
                        </div>
                        {editMode === promptEditMode &&
                        <FormControl
                          variant={'outlined'}
                          className={classes.promptForm}>
                          <TextField
                            autoFocus
                            multiline
                            rowsMax={5}
                            onChange={this.handleChangeInputPrompt.bind(this, prompt.id)}
                            onBlur={this.handleBlurInputPrompt.bind(this, prompt.id)}
                            className={classNames(classes.cssUnderline)}
                            defaultValue={answer}
                            placeholder={prompt.prompt}
                            InputProps={{
                              classes: {
                                input: classes.promptAnswer
                              }
                            }}
                          />
                        </FormControl>
                        }
                        {editMode !== promptEditMode &&
                        <div
                          className={
                            classNames(
                              classes.promptAnswer,
                              { _editable: canEdit },
                              { _placeholder: answer.length === 0 }
                            )
                          }
                          onClick={canEdit ? this.setEditMode.bind(this, promptEditMode) : null}>
                          {answer.length > 0 ? answer : '+ Add details'}
                        </div>
                        }
                      </div>
                      : null
                    )
                  })
                  : canEdit
                    ? (
                      <React.Fragment key={block}>
                        <div className={classes.promptHeader}>
                          Notes
                        </div>
                        <div
                          className={classNames(classes.promptAnswer, '_editable', '_placeholder')}
                          onClick={this.setEditMode.bind(this, 'prompts')}>
                          + Add notes
                        </div>
                      </React.Fragment>
                      )
                    : null
                )
              case 'stars':
                const hasCasts = casts && casts.length > 0
                const showAddButton = canEdit && editMode !== 'stars' && !hasCasts
                return (
                  !isCurated &&
                  (hasCasts || canEdit) &&
                  getPackageType(packages, project.projectSkus[0].packageId) !== PACKAGE_TYPES.CURATED &&
                  <div className={classes.block} key={block}>
                    {canEdit && editMode !== 'stars' &&
                    <PencilIcon
                      className={classes.pencilIcon}
                      onClick={canEdit ? this.setEditMode.bind(this, 'stars') : null} />
                    }
                    <div className={classes.promptHeader}>
                      Featured
                    </div>
                    {showAddButton &&
                    <div
                      className={classNames(classes.promptAnswer, '_editable', '_placeholder')}
                      onClick={this.setEditMode.bind(this, 'stars')}>
                      + Add people
                    </div>
                    }
                    {!showAddButton &&
                    <StarsMovie
                      projectId={project.id}
                      type={editMode === 'stars' ? 'projectDetailsEditing' : 'projectDetails'}
                    />
                    }
                  </div>)
              case 'status':
                return (<div
                  className={classNames(classes.statusBar, { _rightAlign: columnLayout === 'double' })}
                  key={block}>
                  <ProjectStatusBar project={project} type={'standalone'} packageType={packageType}/>
                </div>)
              case 'appointment':
                return (isHollywood && (project.projectSkus[0].appointment || project.projectSkus[0].email)
                  ? <div className={classes.block} key={block}>
                      <div className={classes.promptHeader}>
                        Appointment
                      </div>
                      <p>{project.projectSkus[0].appointment
                        ? 'A producer will call you on ' + toLocale(
                          new Date(project.projectSkus[0].appointment),
                          { forThankYou: true }
                        )
                        : 'A producer will contact you at ' + project.projectSkus[0].email}</p>
                    </div>
                  : null
                )
              default:
                return null
            }
          })}
        </ProjectDetailsLayoutContainer>
      </div>
    )
  }
}

ProjectDetailsBox.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  columnLayout: PropTypes.oneOf(['single', 'double']).isRequired,
  createPromptAnswerFunction: PropTypes.func.isRequired,
  deletePromptAnswerFunction: PropTypes.func.isRequired,
  getAllPromptsCategoryFunction: PropTypes.func.isRequired,
  getCategoryFunction: PropTypes.func.isRequired,
  getPackageSkuFunction: PropTypes.func.isRequired,
  getProjectAnswersFunction: PropTypes.func.isRequired,
  listCastFunction: PropTypes.func.isRequired,
  packages: PropTypes.array,
  packageType: PropTypes.number,
  project: PropTypes.object.isRequired,
  show: PropTypes.array.isRequired,
  title: PropTypes.string,
  updateProject: PropTypes.func,
  updatePromptAnswerFunction: PropTypes.func.isRequired
}

ProjectDetailsBox.defaultProps = {
  canEdit: false,
  columnLayout: 'single'
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories,
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createPromptAnswerFunction: function (projectId, prompt) {
      return dispatch(createPromptAnswer(projectId, prompt))
    },
    deletePromptAnswerFunction: function (projectId, promptId) {
      dispatch(deletePromptAnswer(projectId, promptId))
    },
    getAllPromptsCategoryFunction: function (categoryId) {
      return dispatch(getAllPromptsCategory(categoryId))
    },
    getCategoryFunction: function (categoryId) {
      return dispatch(getCategory(categoryId))
    },
    getPackageSkuFunction: function (skuId) {
      return dispatch(getPackageSku(skuId))
    },
    getProjectAnswersFunction: function (projectId) {
      return dispatch(getProjectAnswers(projectId))
    },
    listCastFunction: function (projectId) {
      return dispatch(listCast(projectId))
    },
    updatePromptAnswerFunction: function (projectId, promptId, answer) {
      dispatch(updatePromptAnswer(projectId, promptId, answer))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsBox))
