import ApiService from '../services/ApiService'
import { abortUpload } from './index'
import { api } from '../constants'
import { cacheSignedUrl } from '../services/CacheSignedUrls'
import { setProjectCookies } from '../helpers'

export {
  createProject,
  updateProject,
  deleteProject,
  projectInfo,
  listProjects,
  listUserProjects,
  getPaymentIntent,
  purchaseWithCoupon,
  createProjectSku,
  updateProjectSku,
  downloadFilesSize
}

function createProject (project) {
  return async dispatch => {
    const response = await new ApiService(api.PROJECT, {}, {}, true, dispatch)
      .post(project)
    setProjectCookies(response)
    return response
  }
}

function createProjectSku (projectId, skuId) {
  return async dispatch => {
    const response = await new ApiService(api.PROJECT_SKUS, { projectId: projectId }, {}, true, dispatch)
      .post({ packageSku: skuId })
    return response.data
  }
}

function updateProjectSku (projectId, projectSku) {
  return async dispatch => {
    const response = await new ApiService(
      api.ONE_PROJECT_SKU,
      { projectId: projectId, projectSkuId: projectSku.id },
      {},
      true,
      dispatch
    ).put(projectSku)
    return response.data
  }
}

function updateProject (project) {
  return async dispatch => {
    delete project.packageId
    try {
      const projectRes = await new ApiService(
        api.PROJECT_INFO,
        { projectId: project.id },
        {},
        true,
        dispatch
      ).put(project)
      return projectRes
    } catch (e) {
      if (e.message !== 'Failed to fetch new access token.' &&
        e.message !== 'The user is not able to log in automatically.') {
        throw e
      }
    }
  }
}

function deleteProject (projectId) {
  return async (dispatch, getStore) => {
    const uploads = getStore().upload || {}
    Object.keys(uploads).forEach(upload => {
      if (uploads[upload].projectId === projectId) {
        dispatch(abortUpload(upload))
      }
    })
    const projectRes = await new ApiService(api.PROJECT_INFO,
      { projectId }, {}, true, dispatch)
      .delete()
    return projectRes
  }
}

function projectInfo (projectId) {
  return async dispatch => {
    const project = await new ApiService(api.PROJECT_INFO,
      { projectId }, {}, true, dispatch)
      .get()
    setProjectCookies(project)
    dispatch({ id: project.id, project, type: 'UPDATE_PROJECT_IN_LIST' })
    return project
  }
}

function listProjects (params = {}, filterFunc = null) {
  return async dispatch => {
    try {
      const response = await new ApiService(api.PROJECT, {}, params, true, dispatch)
        .get()
      if (filterFunc) {
        response.data = filterFunc(response.data)
      }
      dispatch({ projects: response.data, type: 'APPEND_PROJECTS' })
      // cache signed urls
      const fileLinks = response.data.reduce((arr, file) => {
        arr.push(file.coverUrl)
        return arr
      }, [])
      cacheSignedUrl.add(fileLinks)
      return response
    } catch (e) {
      return { data: [] }
    }
  }
}

function listUserProjects (userId, params = {}) {
  return dispatch => {
    return new ApiService(api.LIST_USER_PROJECTS,
      { userId }, params, true, dispatch)
      .get()
  }
}

function getPaymentIntent (projectId, projectSkuId, couponId = null) {
  return dispatch => {
    return new ApiService(api.PROJECT_PAYMENT,
      { projectId, projectSkuId }, {}, true, dispatch)
      .post({ couponId: couponId })
  }
}

function purchaseWithCoupon (projectId, projectSkuId, couponId) {
  return dispatch => {
    return new ApiService(api.PROJECT_COUPON_PURCHASE,
      { projectId, projectSkuId }, {}, true, dispatch)
      .post({ couponId: couponId })
  }
}

function downloadFilesSize (projectId, params = {}) {
  return dispatch => {
    return new ApiService(api.DOWNLOAD_SELECTED_FILES,
      { projectId }, { ...params, sizeOnly: true }, true, dispatch)
      .get()
  }
}
