import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ChatBubblePortion (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 11 27">
      <g><g><path d="M0.702636719,-9.39868664e-05 C0.850293528,-9.39868664e-05 0.99156957,-9.39868664e-05 1.12646484,-9.39868664e-05 C2.54883293,-9.39868664e-05 3.89937818,-9.39868664e-05 5.17810059,-9.39868664e-05 C10.8312988,-9.39868664e-05 13.3156738,6.35083008 7.54370117,9.21350098 C0.653076172,13.1555176 0.831298828,21.4204102 0.831298828,27.0910645"></path></g></g>
    </SvgIcon>
  )
}
