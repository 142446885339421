import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function WarningIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 15">
      <g><g><path d="M9.741 4.484L9.43 9.68a.928.928 0 0 1-.935.864.928.928 0 0 1-.934-.864l-.312-5.196a1.21 1.21 0 0 1 .338-.911c.236-.246.564-.384.908-.384.344 0 .672.138.908.384.236.245.358.575.338.911zm-1.236 8.697a.893.893 0 0 1-.823-.535.849.849 0 0 1 .193-.944.908.908 0 0 1 .97-.188.854.854 0 0 1 .29 1.413.905.905 0 0 1-.63.254zm1.984-12.058A2.307 2.307 0 0 0 8.5 0c-.82 0-1.579.428-1.99 1.123L.309 11.631c-.41.694-.41 1.55 0 2.246A2.307 2.307 0 0 0 2.298 15h12.405c.82 0 1.579-.428 1.99-1.123.41-.696.41-1.552 0-2.246z" /></g></g>
    </SvgIcon>
  )
}
