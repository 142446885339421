import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PlayIcon (props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" d="M.853 2.195c0-.949 1.055-1.533 1.877-1.038l17.354 10.454c.787.473.787 1.6 0 2.074l-6.044 3.64-11.31 6.813c-.822.494-1.877-.088-1.877-1.037V2.195z"/>
    </SvgIcon>
  )
}
