import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import UrlifeButton from './UrlifeButton'

/* eslint-disable sort-keys */
const styles = {
  dialogPaper: {
    padding: '40px 30px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '2px'
  },
  dialogBackdrop: {
    backgroundColor: '#01b7d7',
    opacity: '0.9 !important'
  },
  dialogText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    color: '#323232',
    lineHeight: '24px',
    textAlign: 'center',
    maxWidth: 292,
    marginBottom: 10,
    opacity: 0.6,
    '&._header': {
      fontSize: 16,
      opacity: 1,
      lineHeight: '26px',
      maxWidth: 368
    }
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '15px 0',
    marginTop: 10
  },
  button: {
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 28px',
    margin: '5px 8px',
    width: 210,
    height: 46
  }
}
/* eslint-enable sort-keys */

class UrlifeConfirmDialog extends React.Component {
  render () {
    const {
      classes,
      isOpen,
      header,
      text,
      children,
      cancelLabel,
      confirmLabel,
      onIgnore,
      onCancel,
      onConfirm,
      confirmDisabled
    } = this.props
    return (
      <Dialog
        open={isOpen}
        onClose={onIgnore}
        classes={{
          paper: classes.dialogPaper
        }}
        BackdropProps={{
          classes: {
            root: classes.dialogBackdrop
          }
        }}
      >
        {header && <div className={classNames(classes.dialogText, '_header')}>{header}</div>}
        {text && <div className={classes.dialogText}>{text}</div>}
        {children}
        <div className={classes.buttonBlock}>
          {onCancel &&
          <UrlifeButton
            className={classes.button}
            inverted
            onClick={onCancel}>
            {cancelLabel}
          </UrlifeButton>
          }
          {onConfirm &&
          <UrlifeButton
            className={classes.button}
            onClick={onConfirm}
            disabled={confirmDisabled || false}>
            {confirmLabel}
          </UrlifeButton>
          }
        </div>
      </Dialog>
    )
  }
}

UrlifeConfirmDialog.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object.isRequired,
  confirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string.isRequired,
  header: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onIgnore: PropTypes.func,
  text: PropTypes.string
}

UrlifeConfirmDialog.defaultProps = {
  cancelLabel: 'Cancel',
  children: null,
  confirmLabel: 'Confirm',
  header: '',
  isOpen: true,
  onCancel: null,
  onIgnore: null,
  text: ''
}

export default withStyles(styles)(UrlifeConfirmDialog)
