import React, { Component } from 'react'

import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  awaitingPaymentCol: {
    display: 'table-cell',
    maxWidth: 600,
    overflow: 'hidden',
    padding: '10px 20px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  awaitingPaymentRow: {
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    display: 'table-row',
    titleText: {
      margin: '10px 0px 10px 10px'
    }
  },
  headerCol: {
    backgroundColor: '#f6f6f6',
    display: 'table-cell',
    fontSize: '16px',
    fontWeight: '500',
    justifyContent: 'space-between',
    padding: '20px',
    position: 'sticky',
    top: 106
  },
  headerRow: {
    alignItems: 'center',
    display: 'table-row'
  },
  paper: {
    background: 'none'
  },
  titleText: {
    padding: '20px'
  },
  userProjectsLink: {
    '&:hover': {
      textDecoration: 'none'
    },
    color: '#01b7d7',
    textDecoration: 'underline'
  }
}

class ReportsAwaitingPayment extends Component {
  render () {
    const { classes } = this.props
    return (
        <div>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h4" component="h2" className={classes.titleText}>
              <span>Awaiting payment email cadence</span>
            </Typography>
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Date account started</div>
              <div className={classes.headerCol}>User</div>
              <div className={classes.headerCol}>Email</div>
              <div className={classes.headerCol}>Project started</div>
              <div className={classes.headerCol}>Follow-up email #1</div>
              <div className={classes.headerCol}>Follow-up email #2</div>
              <div className={classes.headerCol}>Follow-up email #3</div>
              <div className={classes.headerCol}>Follow-up email #4</div>
              <div className={classes.headerCol}>Follow-up email #5</div>
              <div className={classes.headerCol}>Follow-up email #6</div>
              <div className={classes.headerCol}>Follow-up email #7</div>
              <div className={classes.headerCol}>Notes</div>
            </div>
            <div
              data-testid="apl-awaitingpayment"
              key={''}
              className={classes.awaitingPaymentRow}
            >
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.userProjectsLink}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
              <div className={classes.awaitingPaymentCol}></div>
            </div>
          </Paper>
        </div>
    )
  }
}

ReportsAwaitingPayment.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReportsAwaitingPayment)
