import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../../helpers'
import Paper from '@material-ui/core/Paper'

/* eslint-disable sort-keys */
const styles = {
  container: {
    marginLeft: 130,
    position: 'absolute',
    width: '100%',
    boxShadow: '0 2px 32px rgba(0, 0, 0, 0.15)',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    padding: '60px 100px',
    ...commonStyles.media(1279, {
      marginLeft: '0',
      padding: '40px 40px',
      boxShadow: '0 2px 20px rgba(0, 0, 0, 0.15)'
    }),
    ...commonStyles.media(480, {
      marginLeft: '0',
      padding: '20px 20px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)'
    })
  },
  header: {
    width: '100%',
    color: '#323232',
    fontSize: '26px',
    fontWeight: 600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '&::after': {
      margin: '30px 0px 30px -160px',
      content: "''",
      display: 'block',
      width: '92%',
      height: '5px',
      backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)'
    },
    ...commonStyles.media(1279, {
      fontSize: '22px',
      margin: 0,
      '&::after': {
        margin: '30px auto 30px -100px'
      }
    }),
    ...commonStyles.media(480, {
      fontSize: '18px',
      '&::after': {
        margin: '15px auto 15px -40px',
        width: '66%'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: '18px'
    })
  },
  body: {
    width: '100%',
    color: '#323232'
  }
}
/* eslint-enable sort-keys */

class UrlifePaper extends React.PureComponent {
  render () {
    const { classes, children, header } = this.props
    return (
      <Paper className={`${classes.container} ${this.props.className}`}>
        <h3 className={`${classes.header} ${this.props.headerStyle}`}>{header}</h3>
        <div className={classes.body}>{children}</div>
      </Paper>
    )
  }
}

UrlifePaper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  header: PropTypes.string,
  headerStyle: PropTypes.string
}

export default withStyles(styles)(UrlifePaper)
