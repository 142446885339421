import ApiService from '../services/ApiService'
import { api } from '../constants'
import { updateSystemFile, uploadFile } from './index'

export {
  fetchInspiration,
  fetchSingleInspiration,
  createInspiration,
  updateInspiration,
  deleteInspiration,
  createInspirationFile
}

function fetchInspiration (params = {}) {
  return dispatch => {
    return new ApiService(api.INSPIRATION, {}, params, false, dispatch)
      .get()
  }
}

function fetchSingleInspiration (id) {
  return dispatch => {
    return new ApiService(api.ONE_INSPIRATION, { inspirationId: id }, {}, false, dispatch)
      .get()
  }
}

function createInspiration (data) {
  return dispatch => {
    return new ApiService(api.INSPIRATION, {}, {}, true, dispatch)
      .post(data)
  }
}

function updateInspiration (id, data) {
  return dispatch => {
    return new ApiService(api.ONE_INSPIRATION, { inspirationId: id }, {}, true, dispatch)
      .put(data)
  }
}

function deleteInspiration (id) {
  return dispatch => {
    return new ApiService(api.ONE_INSPIRATION, { inspirationId: id }, {}, true, dispatch)
      .delete()
  }
}

function createInspirationFile (fileType, file, fileObject) {
  return dispatch => {
    return new ApiService(api.SYSTEM_FILES, { fileType }, {}, true, dispatch)
      .post(file)
      .then(
        response => {
          const responseFileData = response.data[0]
          return dispatch(uploadFile(
            null,
            fileObject,
            responseFileData.id,
            responseFileData.fileExt,
            'inspiration',
            'system',
            'system/' + responseFileData.id + '.' + responseFileData.fileExt,
            (cognitoInfo) => {
              return dispatch(updateSystemFile(
                { id: responseFileData.id, status: 3 },
                cognitoInfo.IdentityId)
              )
            }
          ))
        }
      )
  }
}
