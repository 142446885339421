import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import FAQList from '../faq/FAQList'

/* eslint-disable sort-keys */
const styles = {
  title: {
    marginTop: 0,
    lineHeight: '52px',
    fontSize: 48,
    fontWeight: 300,
    color: '#323232',
    ...commonStyles.media(575, {
      fontSize: 38,
      lineHeight: '42px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 38,
      lineHeight: '42px'
    })
  },
  contact: {
    marginTop: 40
  },
  text: {
    color: '#323232',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 16,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 14,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    }
  }
}
/* eslint-enable sort-keys */

class Support extends React.Component {
  render () {
    const { classes } = this.props
    return (<div>
      <div className={classes.supportContainer}>
        <h2 className={classes.title}>Help & Support</h2>
        <FAQList/>
        <div className={classes.contact} >
          <h3 className={classes.title}>Contact Us</h3>
          <p className={classes.text}>Email: <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a></p>
        </div>
      </div>
    </div>)
  }
}
Support.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Support)
