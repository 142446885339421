import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from '../../common/UrlifeButton'
import { NoProjectsIcon } from '../../common/SVG'

import { commonStyles, history } from '../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  container: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    marginTop: 20,
    '& p': {
      margin: 0
    }
  },
  button: {
    marginTop: 30,
    borderRadius: 2,
    backgroundColor: '#01b7d7',
    border: 'solid 1px rgba(0,0,0,0)',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'none',
    padding: '13px 45px',
    ...commonStyles.media(480, {
      fontSize: 12
    }),
    '&:hover': {
      border: 'solid 1px #01b7d7',
      color: '#01b7d7',
      backgroundColor: '#fff'
    }
  },
  icon: {
    width: 56,
    height: 47
  }
}
/* eslint-enable sort-keys */

export class NoProjects extends React.Component {
  createProject () {
    history.push('/createmovie', {
      lastLocation: history.location
    })
  }

  render () {
    const { classes, type } = this.props
    let text1 = 'You currently have'
    let text2 = 'no projects in production'
    switch (type) {
      case 'complete':
        text2 = 'no projects completed'
        break
      case 'assigned':
        text2 = 'no projects assigned'
        break
      case 'unassigned':
        text1 = 'There are currently'
        text2 = 'no unassigned projects'
        break
      default:
        break
    }
    return (
      <div className={classes.container}>
        <NoProjectsIcon classes={{ root: classes.icon }}/>
        <div className={classes.text}>
          <p>{text1}</p>
          <p>{text2}</p>
        </div>
        {(type === 'complete' || type === 'inprogress') &&
        <UrlifeButton className={classes.button} onClick={this.createProject}>
          Start new project
        </UrlifeButton>
        }
      </div>
    )
  }
}

NoProjects.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

export default withStyles(styles)(NoProjects)
