import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function BellIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 17 21'>
      <path fill={ props.fill } d="M14.265 16.01H2.034a.839.839 0 0 1-.832-.844c0-.466.373-.845.832-.845h12.23c.46 0 .832.38.832.845a.839.839 0 0 1-.831.844zm-4.808 1.871c0 .735-.589 1.333-1.313 1.333a1.325 1.325 0 0 1-1.313-1.333v-.798h2.626zM8.144 1.236l.012.023h-.024l.012-.023zm6.461 12.043l-.84-1.717-.183-3.061a.538.538 0 0 0-.568-.508.54.54 0 0 0-.502.573l.19 3.17a.543.543 0 0 0 .054.208l.637 1.3H2.905l.621-1.238a.548.548 0 0 0 .057-.212l.307-5.115c.2-2.516 1.99-4.342 4.254-4.342 2.256 0 4.045 1.827 4.255 4.344a.538.538 0 0 0 .579.494.54.54 0 0 0 .489-.585c-.22-2.64-1.884-4.66-4.126-5.195C9.157.586 8.707.08 8.144.08c-.563 0-1.013.506-1.197 1.314-2.25.533-3.915 2.557-4.127 5.208l-.3 5.014-.833 1.662a1.92 1.92 0 0 0-1.557 1.89c0 1.06.854 1.923 1.903 1.923h3.726v.795c0 1.33 1.07 2.41 2.385 2.41 1.316 0 2.386-1.08 2.386-2.41v-.795h3.725c1.05 0 1.903-.862 1.903-1.923 0-.94-.67-1.723-1.553-1.89z"/>
    </SvgIcon>
  )
}

BellIcon.propTypes = {
  fill: PropTypes.string.isRequired
}
