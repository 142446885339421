import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Menu from '@material-ui/core/Menu'
import { BellIcon, CancelMenuIcon } from '../../common/SVG'
import NotificationList from '../../common/MoreMenu/components/NotificationList'
import { openMoreMenu } from '../../../actions'
import { commonStyles } from '../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    borderRadius: '10px',
    padding: '29px 12px 5px 12px',
    minWidth: '340px',
    marginLeft: 31,
    marginTop: -8,
    ...commonStyles.media(600, {
      minWidth: '240px'
    })
  },
  topmenuDropMenuClose: {
    position: 'absolute',
    right: '0px',
    top: '-12px',
    cursor: 'pointer',
    outline: 'none',
    '& path': {
      fill: '#5334b6'
    }
  },
  icon: {
    width: 20,
    height: 25,
    marginTop: 4
  }
}
/* eslint-enable sort-keys */

const Container = styled.div`
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  ${props => props.badge && `&:before {
    position: absolute;
    content: '${props.badge}';
    top: 1px;
    left: -3px;
    font-size: 10px;
    color: #fff;
    background-color: rgba(1, 183, 215, 1);
    padding: 1px 4px;
    text-align: center;
    border-radius: 50%;
    pointer-events: none;
  }
  `}
`

class NotificationMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      anchor: null
    }

    this.handleMenu = (event) => {
      if (window.innerWidth > 767) {
        this.setState({ anchor: event.currentTarget })
      } else {
        this.props.dispatch(openMoreMenu('notifications'))
      }
    }
    this.handleMenuClose = () => {
      this.setState({ anchor: null })
    }
  }

  render () {
    const { classes, notificationsBadge } = this.props
    const { anchor } = this.state

    return (
      <Container badge={notificationsBadge}>
        <BellIcon className={classes.icon} fill='#fff' onClick={this.handleMenu}/>
        <Menu
          disableAutoFocus={true}
          id="notification-drop-menu"
          classes={{
            paper: classes.paper
          }}
          anchorEl={anchor}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          open={Boolean(anchor)}
          onClose={this.handleMenuClose}
        >
          <NotificationList
            closeMenu={this.handleMenuClose}
            scrollElement={'grandparent'}
          />
          <CancelMenuIcon
            className={classes.topmenuDropMenuClose}
            onClick={this.handleMenuClose} />
        </Menu>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    notificationsBadge: state.notification.badge
  }
}

NotificationMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  notificationsBadge: PropTypes.string
}

export default withStyles(styles)(connect(mapStateToProps)(NotificationMenu))
