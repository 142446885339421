import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import ValidateForm from '../common/form/ValidateForm'
import { commonStyles } from '../../helpers'
import { emailSignUp } from '../../actions'
import {
  CloseIcon
} from '../common/SVG'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DateAndTimePicker from '../common/DateAndTimePicker'

/* eslint-disable sort-keys */
const styles = {
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalBackdrop: {
    backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)',
    opacity: '0.9 !important'
  },
  paper: {
    zIndex: 20,
    position: 'relative',
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    margin: 'auto',
    flexDirection: 'column',
    borderRadius: 2,
    maxWidth: '550px',
    minWidth: '550px',
    overflowX: 'hidden',
    paddingBottom: 30,
    ...commonStyles.media(575, {
      minWidth: 200,
      width: '90%'
    }),
    '&._center': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  paperHeader: {
    height: 40,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  datePickerPaper: {
    padding: 20
  },
  iconWrapper: {
    padding: 15,
    marginLeft: 'auto'
  },
  icon: {
    fill: '#333',
    width: 15,
    height: 15,
    '&._light': {
      fill: '#fff'
    }
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    padding: '0px 30px',
    ...commonStyles.media(575, {
      fontSize: 16,
      padding: '10px 10px 0px 10px',
      minWidth: 288
    }),
    '& h2': {
      color: '#55565a',
      ...commonStyles.media(575, {
        fontSize: 18
      })
    }
  },
  logo: {
    height: 70,
    width: 'auto',
    ...commonStyles.media(575, {
      height: 40
    })
  },
  content: {
    textAlign: 'center',
    padding: '0 30px 10px 30px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(575, {
      padding: 'auto'
    }),
    '&._subtitle': {
      minHeight: 50,
      color: '#55565a',
      fontWeight: 450,
      ...commonStyles.media(575, {
        fontSize: 12
      })
    },
    '&._textField': {
      width: '70%',
      margin: '0px auto',
      ...commonStyles.media(575, {
        width: '85%'
      })
    },
    '&._disclaimer': {
      color: '#a6a6a6',
      fontSize: 12,
      margin: '20px auto',
      ...commonStyles.media(575, {
        fontSize: 10
      })
    }
  },
  buttonHolder: {
    flexDirection: 'column',
    padding: '0 50px',
    justifyContent: 'center',
    ...commonStyles.media(575, {
      padding: '0 10px'
    })
  },
  submitButton: {
    marginBottom: 10,
    width: 200,
    height: 46,
    fontSize: 14,
    fontWeight: 700
  }
}
/* eslint-enable sort-keys */

class EmailModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dateAndTimeOpen: false,
      fields: {},
      formStatus: '', // '', sending, success, fail, resend-success, resend-fail
      showSuccess: false
    }
    this.dateRef = React.createRef()
  }

  send (event) {
    const { userEmail, fullName, startDate } = this.state.fields
    this.setState({
      formStatus: 'sending'
    }, () => {
      this.props.emailSignUpFunc(userEmail, fullName, this.props.settings.topics, startDate)
        .then(
          data => {
            this.setState({
              formStatus: data.status || 'fail',
              showSuccess: data.status === 'success'
            })
            if (data.status === 'success') {
              this.onClose()
            }
          }
        )
        .catch(e => {
          this.setState({
            formStatus: 'fail'
          })
        })
    })
  }

  onClose () {
    this.setState({
      fields: {},
      formStatus: ''
    }, this.props.onClose.bind(this))
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  closeSnackbar () {
    this.setState({ showSuccess: false })
  }

  setDate (date) {
    const { fields } = this.state
    if (this.dateRef && this.dateRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter.call(this.dateRef.current, date)

      // eslint-disable-next-line no-undef
      const ev2 = new Event('input', { bubbles: true })
      this.dateRef.current.dispatchEvent(ev2)
    }
    this.setState({
      dateAndTimeOpen: !date,
      fields: {
        ...fields,
        startDate: date
      }
    })
  }

  removeFocus () {
    this.dateRef.current.blur()
  }

  render () {
    const { fields, formStatus, showSuccess, dateAndTimeOpen } = this.state
    const {
      classes,
      open,
      settings
    } = this.props
    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={this.onClose.bind(this)}
          className={classes.modal}
          BackdropProps={{ className: classes.modalBackdrop }}
        >
          <Paper className={classes.paper}>
            <div className={classes.paperHeader}>
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.iconWrapper}
                onClick={this.onClose.bind(this)}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
            </div>
            <DialogTitle
              disableTypography
              className={classes.header}
            >
              {settings.logo && settings.logo({ className: classes.logo })}
              <h2>{settings.title}</h2>
            </DialogTitle>
            <DialogContent
              classes={{ root: classNames(classes.content, '_subtitle') }
            }>
              {settings.subTitle}
            </DialogContent>
            <ValidateForm
              onSubmit={this.send.bind(this)}
              onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
              validateOnMount={true}>
              {(form) => (
                <React.Fragment>
                  <DialogContent classes={{ root: classNames(classes.content, '_textField') }}>
                    {form.renderField(
                      'fullName',
                      [],
                      (field, fieldData) => {
                        const validationStatus = fields.fullName && fields.fullName.length > 0 ? 'success' : 'none'
                        return (
                          <UrlifeTextField
                            margin="dense"
                            name="fullName"
                            label="Full name"
                            type="text"
                            defaultValue={fields.fullName || ''}
                            onChange={e => field.onChange(e)}
                            onBlur={e => field.onBlur(e)}
                            validationStatus={validationStatus}
                          />
                        )
                      }
                    )}
                    {form.renderField(
                      'userEmail',
                      ['required', 'email'],
                      (field, fieldData) => {
                        let validationStatus = 'none'
                        let errorMessage = ''
                        if (formStatus === 'fail') {
                          validationStatus = 'error'
                          errorMessage = 'Registration failed, please try again later'
                        } else if (fields.userEmail && fields.userEmail.length > 0) {
                          if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                            validationStatus = 'error'
                            errorMessage = fieldData.errors[0]
                          } else {
                            validationStatus = 'success'
                          }
                        }
                        return (
                          <UrlifeTextField
                            margin="dense"
                            name="userEmail"
                            label="Email"
                            type="email"
                            defaultValue={fields.userEmail || ''}
                            onChange={e => field.onChange(e)}
                            onBlur={e => field.onBlur(e)}
                            validationStatus={validationStatus}
                            infoLabel={errorMessage || 'Required'}
                          />
                        )
                      }
                    )}
                    {settings.topics.includes('silversea') && <>
                      {form.renderField(
                        'startDate',
                        ['required', 'startDate'],
                        (field, fieldData) => {
                          let validationStatus = 'none'
                          let errorMessage = ''
                          if (formStatus === 'fail') {
                            validationStatus = 'error'
                            errorMessage = 'Registration failed, please try again later'
                          } else if (fields.startDate && fields.startDate.length > 0) {
                            if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                              validationStatus = 'error'
                              errorMessage = fieldData.errors[0]
                            } else {
                              validationStatus = 'success'
                            }
                          }
                          return (
                            <UrlifeTextField
                              inputRef={this.dateRef}
                              margin="dense"
                              name="startDate"
                              label="Voyage Start Date"
                              type="text"
                              defaultValue={fields.startDate || ''}
                              value={fields.startDate}
                              onClick={() => this.setState({ dateAndTimeOpen: true })}
                              onChange={e => {
                                field.onChange(e)
                                this.removeFocus.bind(this, e)
                              }}
                              onBlur={e => field.onBlur(e)}
                              validationStatus={validationStatus}
                              infoLabel={errorMessage || 'Required'}
                            />
                          )
                        }
                      )}
                      <Modal
                        open={dateAndTimeOpen}
                        className={classes.modal}
                      >
                        <Paper className={classes.datePickerPaper}>
                          <DateAndTimePicker
                            defaultTimeDate={null}
                            setAppointmentDate={this.setDate.bind(this)}
                            onlyDate
                          />
                        </Paper>
                      </Modal>
                    </>}
                  </DialogContent>
                  <DialogContent classes={{ root: classNames(classes.content, '_disclaimer') }}>
                    {settings.disclaimer}
                  </DialogContent>
                  <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                    <UrlifeButton
                      type="submit"
                      disabled={formStatus === 'sending' || !form.checkFormValid()}
                      className={classes.submitButton}
                    >
                      Submit
                    </UrlifeButton>
                  </DialogActions>
                </React.Fragment>
              )}
            </ValidateForm>
          </Paper>
        </Modal>
        <Snackbar
          open={showSuccess}
          autoHideDuration={6000}
          onClose={this.closeSnackbar.bind(this)}
          message='The email is on its way!'
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeSnackbar.bind(this)}
            >
              <CloseIcon className={classNames(classes.icon, '_light')} />
            </IconButton>
          }
        />
      </React.Fragment>
    )
  }
}

EmailModal.propTypes = {
  classes: PropTypes.object.isRequired,
  emailSignUpFunc: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    disclaimer: PropTypes.string.isRequired,
    logo: PropTypes.function,
    subTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    topics: PropTypes.array.isRequired
  })
}

function mapDispatchToProps (dispatch) {
  return {
    emailSignUpFunc: function (email, fullName, topics, startDate) {
      return dispatch(emailSignUp(email, fullName, topics, startDate))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EmailModal))
