import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Toolbar from '@material-ui/core/Toolbar'
import MoreMenu from '../../common/MoreMenu/MoreMenu'
import { connect } from 'react-redux'
import {
  commonStyles,
  history
} from '../../../helpers/index'
import { BackArrow, PlusIcon, UrlifeLogo } from '../../common/SVG/index'

/* eslint-disable sort-keys */
const styles = {
  container: {
    ...commonStyles.container(),
    minHeight: 0
  },
  topBarRow: {
    marginTop: -20,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  topBarLeft: {
    ...commonStyles.flex(),
    justifyContent: 'flex-start',
    flex: 1
  },
  topBarMiddle: {
    ...commonStyles.flex(),
    ...commonStyles.media(767, {
      margin: 'auto'
    })
  },
  topBarRight: {
    ...commonStyles.flex(),
    justifyContent: 'flex-end',
    flex: 1
  },
  createProjectFlowIcon: {
    position: 'absolute',
    color: '#fff',
    top: 42,
    cursor: 'pointer',
    ...commonStyles.media(767, {
      top: 38
    }),
    ...commonStyles.media(575, {
      top: 18
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 18
    })
  },
  headerWrap: {
    position: 'absolute',
    left: 200,
    right: 200,
    top: 28,
    textAlign: 'center',
    ...commonStyles.media(1280, {
      left: 100,
      right: 100
    }),
    ...commonStyles.media(575, {
      top: 18
    }),
    ...commonStyles.media(479, {
      left: 60,
      right: 60
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 18,
      left: 60,
      right: 60
    })
  },
  header: {
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '34px',
    '&._step': {
      fontSize: 14,
      lineHeight: '17px',
      letterSpacing: '0.51px',
      textTransform: 'uppercase',
      marginBottom: 6
    },
    ...commonStyles.media(575, {
      fontSize: 24,
      lineHeight: '28px',
      '&._step': {
        fontSize: 12,
        lineHeight: '14px'
      }
    }),
    ...commonStyles.media(420, {
      fontSize: 20,
      lineHeight: '24px',
      '&._step': {
        fontSize: 10,
        lineHeight: '12px'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 20,
      lineHeight: '24px',
      '&._step': {
        fontSize: 10,
        lineHeight: '12px'
      }
    })
  },
  headerLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerLogo: {
    '&._center': {
      height: 12,
      margin: '2px 14px',
      width: 'auto'
    },
    '&._side': {
      height: 14,
      width: 'auto',
      '&._celebrity': {
        height: 22
      }
    }
  }
}
/* eslint-enable sort-keys */

class ToolbarProject extends React.Component {
  constructor (props) {
    super(props)
    this.headerRef = React.createRef()

    this.onResize = () => {
      const element = this.headerRef.current
      if (element) {
        this.props.setHeaderSize(element.clientHeight > 34)
      }
    }
  }

  componentDidMount () {
    if (this.headerRef.current && !this.resizeEventListener) {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.header !== prevProps.header) {
      this.onResize()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  onBackClick () {
    history.replace(history.location.pathname, { type: 'inAppBack' })
    history.goBack()
  }

  render () {
    const {
      classes,
      stepHeader,
      header,
      step,
      totalSteps,
      isCreateProject,
      isProjectSummary,
      isProjectCheckout,
      skinData,
      moreMenuActions
    } = this.props
    const skinned = !!skinData
    const showSteps = isCreateProject && step !== 0 && totalSteps !== 0 && step <= totalSteps
    return (
      <Toolbar className={classes.container}>
        <div className={classes.topBarRow}>
          {/* left col */}
          <div className={classes.topBarLeft}>
            <BackArrow className={classes.createProjectFlowIcon} onClick={this.onBackClick} />
          </div>
          {/* middle col */}
          <div className={classes.topBarMiddle}>
            <div className={classes.headerWrap}>
              <div className={classNames(classes.header, '_step')}>
                {stepHeader === 'skinned' && skinned
                  ? (
                    <div className={classes.headerLogoContainer}>
                      {skinData.logo({ className: classNames(classes.headerLogo, '_side', skinData.logoClass) })}
                      <PlusIcon className={classNames(classes.headerLogo, '_center')}/>
                      <UrlifeLogo className={classNames(classes.headerLogo, '_side')}/>
                    </div>
                    )
                  : (
                      stepHeader + (showSteps ? ' ' + step + '/' + totalSteps : '')
                    )
                }
              </div>
              <div className={classes.header} ref={this.headerRef}>{header}</div>
            </div>
          </div>
          {/* right col */}
          <div className={classes.topBarRight}>
            {(isCreateProject || isProjectSummary || isProjectCheckout) &&
            <MoreMenu
              type={'project'}
              moreIconClassName={classes.createProjectFlowIcon}
              saveProject={moreMenuActions.saveProject}
              downloadProject={moreMenuActions.downloadProject}
              deleteProject={moreMenuActions.deleteProject}
            />
            }
          </div>
        </div>
      </Toolbar>
    )
  }
}

ToolbarProject.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string,
  isCreateProject: PropTypes.bool.isRequired,
  isProjectCheckout: PropTypes.bool.isRequired,
  isProjectSummary: PropTypes.bool.isRequired,
  moreMenuActions: PropTypes.object,
  setHeaderSize: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  step: PropTypes.number,
  stepHeader: PropTypes.string,
  totalSteps: PropTypes.number
}

ToolbarProject.defaultProps = {
  header: '',
  step: 0,
  stepHeader: '',
  totalSteps: 0
}

function mapStateToProps (state) {
  return {
    header: state.topBar.header,
    moreMenuActions: state.topBar.moreMenuActions,
    skinData: state.skinning.skinData,
    step: state.topBar.step,
    stepHeader: state.topBar.stepHeader,
    totalSteps: state.topBar.totalSteps
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ToolbarProject))
