import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import UrlifePaper from './components/UrlifePaper'
import ModalPreview from '../../common/ModalPreview'
import { ParallaxPlayIcon } from '../../common/SVG'
import vidThumb1 from '../../../assets/img/marketing/parallaxMovie1.jpg'

/* eslint-disable sort-keys */
const styles = {
  root: {
    ...commonStyles.container(),
    height: 'auto',
    position: 'relative'
  },
  group: {
    height: 1600,
    marginBottom: 84,
    ...commonStyles.media(1023, {
      height: 1650,
      marginBottom: 100
    }),
    ...commonStyles.media(480, {
      height: 1380,
      marginBottom: 0
    }),
    ...commonStyles.media(374, {
      height: 1500
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginBottom: 0
    })
  },
  layer: {
    height: 0,
    pointerEvents: 'auto'
  },
  video1: {
    position: 'absolute',
    top: 535,
    left: 0,
    backgroundSize: 'cover',
    boxShadow: '0 2px 32px rgba(0, 0, 0, 0.15)',
    width: 840,
    height: 473,
    display: 'flex',
    cursor: 'pointer',
    ...commonStyles.media(1279, {
      width: 630,
      height: 354
    }),
    ...commonStyles.media(1023, {
      marginLeft: 20,
      top: 435,
      width: 400,
      height: 240,
      marginRight: '15vw'
    }),
    ...commonStyles.media(480, {
      marginLeft: 0,
      marginRight: 0,
      top: 298,
      right: 0,
      width: '100%',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)'
    }),
    ...commonStyles.media(374, {
      top: 358
    })
  },
  video2: {
    position: 'absolute',
    top: 1200,
    right: 8,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    boxShadow: '0 2px 32px rgba(0, 0, 0, 0.15)',
    width: 682,
    height: 384,
    display: 'flex',
    cursor: 'pointer',
    ...commonStyles.media(1279, {
      width: 511,
      height: 263
    }),
    ...commonStyles.media(1023, {
      top: 1085,
      width: 400,
      height: 240
    }),
    ...commonStyles.media(480, {
      marginRight: 0,
      top: 832,
      right: 0,
      width: '100%',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)'
    }),
    ...commonStyles.media(374, {
      top: 943
    })
  },
  playIcon1: {
    display: 'inline-block',
    width: '5%',
    height: 'auto',
    margin: 'auto'
  },
  playIcon2: {
    display: 'inline-block',
    width: '5%',
    height: 'auto',
    margin: 'auto',
    filter: 'drop-shadow( 1px 1px 3px rgba(0, 0, 0, 1))'
  },
  paper1: {
    position: 'absolute',
    height: 400,
    width: 1010,
    top: 0,
    left: 0,
    ...commonStyles.media(1279, {
      top: 0,
      left: 100,
      width: 870,
      height: 'auto'
    }),
    ...commonStyles.media(1023, {
      width: 'calc(100% - 50px)',
      maxWidth: 700,
      top: 0,
      left: '3vw',
      borderRadius: '0 2px 2px 0'
    }),
    ...commonStyles.media(480, {
      width: 'calc(100% - 15px)',
      height: 320,
      top: -37,
      left: 0,
      borderRadius: '0 2px 2px 0'
    }),
    ...commonStyles.media(374, {
      height: 380,
      top: -37
    })
  },
  header1: {
    width: '100%',
    maxWidth: '720px',
    ...commonStyles.media(1279, {
      width: '100%',
      maxWidth: '650px'
    }),
    ...commonStyles.media(480, {
      width: '100%'
    })
  },
  header2: {
    width: '100%',
    maxWidth: '520px',
    ...commonStyles.media(1279, {
      width: '100%',
      maxWidth: '460px'
    }),
    ...commonStyles.media(480, {
      width: '100%',
      maxWidth: '244px'
    })
  },
  header3: {
    width: '100%',
    maxWidth: '520px',
    ...commonStyles.media(1279, {
      width: '100%',
      maxWidth: '460px'
    }),
    ...commonStyles.media(480, {
      width: '100%',
      maxWidth: '285px'
    })
  },
  para1: {
    width: '100%',
    color: '#323232',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '33px',
    ...commonStyles.media(1023, {
      fontSize: '16px',
      lineHeight: '28px',
      marginBottom: 20
    }),
    ...commonStyles.media(480, {
      fontSize: '14px',
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: '14px',
      lineHeight: '25px'
    })
  },
  paper2: {
    top: 540,
    right: 60,
    width: 650,
    ...commonStyles.media(1170, {
      width: 630
    }),
    ...commonStyles.media(1023, {
      height: 'auto',
      width: 'calc(100% - 50px)',
      maxWidth: 700,
      top: 675,
      bottom: 'auto',
      right: '3vw',
      borderRadius: '2px 0 0 2px'
    }),
    ...commonStyles.media(480, {
      width: 'calc(100% - 15px)',
      height: 260,
      top: 553,
      right: 0,
      borderRadius: '2px 0 0 2px'
    }),
    ...commonStyles.media(374, {
      height: 311,
      top: 613
    })
  },
  para2: {
    color: '#323232',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '33px',
    ...commonStyles.media(1023, {
      fontSize: '16px',
      width: '100%',
      lineHeight: '28px',
      marginBottom: 20
    }),
    ...commonStyles.media(480, {
      fontSize: '14px',
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: '14px',
      lineHeight: '25px'
    })
  },
  paper3: {
    top: 1140,
    left: 84,
    width: 785,
    marginLeft: 0,
    ...commonStyles.media(1170, {
      width: 571,
      left: 100,
      top: 1050
    }),
    ...commonStyles.media(1023, {
      height: 'auto',
      width: 'calc(100% - 80px)',
      maxWidth: 700,
      top: 1300,
      bottom: 'auto',
      left: 65,
      borderRadius: '2px 0 0 2px'
    }),
    ...commonStyles.media(480, {
      width: 'calc(100% - 15px)',
      height: 270,
      top: 1091,
      left: 0,
      borderRadius: '2px 0 0 2px'
    }),
    ...commonStyles.media(374, {
      height: 285,
      top: 1200
    })
  },
  para3: {
    color: '#323232',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '33px',
    ...commonStyles.media(1023, {
      fontSize: '16px',
      width: '100%',
      lineHeight: '28px',
      marginBottom: 20
    }),
    ...commonStyles.media(480, {
      fontSize: '14px',
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: '14px',
      lineHeight: '25px'
    })
  },
  link: {
    cursor: 'pointer',
    color: '#01B7D7'
  }
}
/* eslint-enable sort-keys */

const video1 = {
  src: 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8',
  thumbnail: vidThumb1
}
const video2 = {
  src: 'https://static.urlifemedia.com/marketing-2022-11-17/video/master.m3u8',
  thumbnail: 'https://static.urlifemedia.com/marketing-2022-11-17/thumbnail.jpg'
}

class SalesPitch extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    }
  }

  setVideo (video, title) {
    this.setState({
      chosenGaTitle: title,
      chosenVideo: video,
      openModal: true
    })
  }

  onClose () {
    this.setState({
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    })
  }

  scrollToInspiration (event) {
    const ref = this.props.getSectionRef('GetInspired')
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
  }

  render () {
    const { classes } = this.props
    const { chosenVideo, chosenGaTitle, openModal } = this.state
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('SalesPitch')} className={classes.group} index={28}>
        <ParallaxLayer layer='layer4' className={classes.layer}>
          <div className={classes.root}>
            <UrlifePaper
              className={classes.paper1}
              header="THE BEST WAY TO RELIVE YOUR MEMORIES"
              headerStyle={classes.header1}
            >
              <p className={classes.para1}>Our talented team of editors and producers create unforgettable
                personal movies using your photos and videos.  Adding music, soundbites, and custom
                graphics - we’ll do all the work for you, and help tell the story of your
                vacations, adventures, and family events.
              <span
                className={classes.link}
                onClick={() => this.scrollToInspiration()}
              > See what URLIFE can do.</span></p>
            </UrlifePaper>
          </div>
        </ParallaxLayer>
        <ParallaxLayer layer='layer5' className={classes.layer}>
          <div className={classes.root}>
            <div
              className={classes.video2}
              style={{ backgroundImage: `url(${video2.thumbnail})` }}
              onClick={() => {
                video2.src && this.setVideo(video2.src, 'WE MAKE IT EASY FOR YOU')
              }}
            >
              <ParallaxPlayIcon className={classes.playIcon2}/>
            </div>
            <div
              className={classes.video1}
              style={{ backgroundImage: `url(${video1.thumbnail})` }}
              onClick={() => {
                video1.src && this.setVideo(video1.src, 'YOUR MEMORIES DESERVE PERSONAL ATTENTION')
              }}
            >
              <ParallaxPlayIcon className={classes.playIcon1}/>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer layer='layer4' className={classes.layer}>
          <div className={classes.root}>
            <UrlifePaper
              className={classes.paper2}
              header="YOUR MEMORIES DESERVE PERSONAL ATTENTION"
              headerStyle={classes.header2}
            >
              <p className={classes.para2}>We take your memories personally. That’s why we offer a 100%
                satisfaction guarantee. We customize every project, delivering personal storytelling in
                a video you will treasure. Nichole can tell you how.
                <span
                  className={classes.link}
                  onClick={() => {
                    video2.src && this.setVideo(video1.src, 'YOUR MEMORIES DESERVE PERSONAL ATTENTION')
                  }}
                > Watch video.</span>
              </p>
            </UrlifePaper>
            <UrlifePaper className={classes.paper3} header="WE MAKE IT EASY FOR YOU" headerStyle={classes.header3}>
              <p className={classes.para3}>With a user-friendly App and
                effortless upload process - a urlife movie will be your new favorite keepsake.
                Conveniently saved in your personal in-app gallery, you can easily view, download
                or share your video with friends and family.
                <span
                  className={classes.link}
                  onClick={() => {
                    video2.src && this.setVideo(video2.src, 'WE MAKE IT EASY FOR YOU')
                  }}
                > See how simple it is.</span>
              </p>
            </UrlifePaper>
          </div>
        </ParallaxLayer>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          video={chosenVideo}
          gaVideoTitle={chosenGaTitle}
          title={''}
        />
      </ParallaxGroup>
    )
  }
}

SalesPitch.propTypes = {
  classes: PropTypes.object.isRequired,
  getSectionRef: PropTypes.func.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

export default withStyles(styles)(SalesPitch)
