import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import UrlifeButton from '../../common/UrlifeButton'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import {
  listUsers,
  setPackageAccessAction
} from '../../../actions'
import { ROLE_NAMES, ROLES } from '../../../constants'
import { commonStyles, history, inherits } from '../../../helpers'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import UrlifePageInfo from '../../common/UrlifePageInfo'
import UrlifeRoleDialog from './UrlifeRoleDialog'
import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core'

/* eslint-disable sort-keys */
const styles = inherits({})({
  paper: {
    background: 'none'
  },
  userContainer: {
    cursor: 'pointer',
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  userEmail: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell'
  },
  userName: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 150
  },
  userRole: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    paddingLeft: '7px'
  },
  usersTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 5px 0',
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  userPackageAccess: {
    display: 'table-cell',
    padding: '0 10px',
    color: 'rgba(218, 218, 218, 0.7)'
  },
  packageSelect: {
    width: 240,
    fontSize: 14
  },
  packageSelectLabel: {
    color: '#000'
  },
  formStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px'
  },
  submitButton: {
    margin: '10px 10px 5px 10px',
    // height: '3.4rem',
    // fontSize: '1.03rem',
    textTransform: 'none'
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  selectComponent: {
    '&:after': {
      borderBottomColor: '#01b7d7'
    }
  },
  select: {
    width: 130,
    padding: '7px 0',
    '&:focus': {
      background: 'none'
    }
  },
  dropDownSelectList: {
    backgroundColor: '#e3e9eb'
  },
  dropDownSelectItem: {
    ...commonStyles.text({
      color: '#37474e'
    }),
    padding: '6px 10px',
    '&._selected': {
      backgroundColor: 'rgba(55, 71, 78, 0.1)',
      fontWeight: 700
    }
  },
  button: {
    zIndex: 5,
    margin: '5px 5px'
  }
})
/* eslint-enable sort-keys */

class UrlifeUsersPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      filterRole: 0,
      isLoading: false,
      nextUser: null,
      role: null,
      search: '',
      sort: 'fullname',
      user: null,
      userEmail: '',
      users: []
    }
    this.toUserProjects = this.toUserProjects.bind(this)
    this.toUserCoupons = this.toUserCoupons.bind(this)
    this.changeUserRole = this.changeUserRole.bind(this)
    this.onChangePackageAccess = this.onChangePackageAccess.bind(this)
    this.handleClickSelector = this.handleClickSelector.bind(this)
    this.onClose = () => {
      this.setState({ role: null, user: null })
    }
    this.onSave = (user, newRole) => {
      const { users } = this.state
      const newUsers = users.map(use => {
        if (use.id === user) {
          return { ...use, roleId: parseInt(newRole, 10) }
        } else {
          return use
        }
      })
      this.setState({ savedRole: true, users: newUsers })
    }
    this.resetSavedUserRole = () => {
      this.setState({ savedRole: false })
    }

    this.fetchUsers = (add) => () => {
      const { users, nextUser, search, sort, filterRole } = this.state
      const { dispatch } = this.props
      const params = { queryUser: search, sort }
      if (filterRole !== 0) params.queryRole = filterRole
      if (add) {
        params.cursor = nextUser
      }
      this.setState({ isLoading: true })
      dispatch(listUsers(params)).then(
        data => {
          let newUsers = data.data
          if (add) {
            newUsers = [...users, ...data.data]
          }
          this.setState({
            isLoading: false,
            nextUser: data.nextCursor || null,
            users: newUsers
          })
        }
      )
    }

    this.onChangeSearch = () => (e) => {
      const { search } = this.state
      if (search && !e.target.value) {
        this.setState({ search: e.target.value }, this.fetchUsers(false))
      } else {
        this.setState({ search: e.target.value })
      }
    }
    this.handleChangeRole = () => (e) => {
      this.setState({ filterRole: e.target.value }, this.fetchUsers(false))
    }
    this.send = () => (e) => {
      e.preventDefault()
      this.fetchUsers(false)()
    }
  }

  changeUserRole (event, user, role, userEmail) {
    event.stopPropagation()
    this.setState({ role, user, userEmail })
  }

  componentDidMount () {
    this.fetchUsers(false)()
  }

  onChangePackageAccess (event, userId) {
    event.stopPropagation()
    const { users } = this.state
    const { dispatch, packages } = this.props
    this.setState({
      users: users.map(user => {
        if (user.id !== userId) return user
        const modifiedUser = { ...user, packageAccess: event.target.value }
        packages.forEach(pack => {
          const packageWasSelected = user.packageAccess.includes(pack.id)
          const packageIsSelected = modifiedUser.packageAccess.includes(pack.id)
          if (packageWasSelected !== packageIsSelected) {
            dispatch(setPackageAccessAction(
              userId,
              pack.id,
              { access: packageIsSelected }
            ))
          }
        })
        return modifiedUser
      })
    })
  }

  getPackageNameById (packageId) {
    const { packages } = this.props
    return packages.length > 0 ? packages.filter(pack => pack.id === packageId)[0].title : ''
  }

  toUserCoupons (event, user) {
    event.stopPropagation()
    history.push(`/admin/users/${user}/coupons`)
  }

  toUserProjects (event, user) {
    event.stopPropagation()
    history.push(`/admin/users/${user}`)
  }

  handleClickSelector (event) {
    event.stopPropagation()
  }

  render () {
    const {
      classes,
      packages
    } = this.props
    const {
      users,
      role,
      user,
      userEmail,
      savedRole,
      isLoading,
      nextUser,
      search,
      filterRole
    } = this.state
    let openDialog = false
    if (role && user) {
      openDialog = true
    }
    let hasMore = false
    if (nextUser) {
      hasMore = true
    }
    return (
      <div>
        <Paper elevation={0} className={classes.paper}>
          { openDialog &&
            <UrlifeRoleDialog
              open={openDialog}
              onClose={this.onClose}
              onSave={this.onSave}
              startRole={parseInt(role, 10)}
              user={user}
              userEmail={userEmail}
            />
          }
          <div className={classes.searchContainer}>
            <form onSubmit={this.send()} className={classes.formStyle}>
              <FormControl>
                <InputLabel shrink htmlFor="filterRole">
                  Role
                </InputLabel>
                <Select
                  className={classes.selectComponent}
                  style={{ ...styles, marginBottom: '4px', marginRight: '10px' }}
                  classes={{
                    root: classes.root,
                    select: classes.select
                  }}
                  value={filterRole}
                  id="filterRole"
                  onChange={this.handleChangeRole()}
                  inputProps={{
                    name: 'filterRole'
                  }}
                  MenuProps={{
                    MenuListProps: {
                      className: classes.dropDownSelectList
                    },
                    PaperProps: {
                      style: { borderRadius: 0 }
                    }
                  }}>
                  {[0, ...Object.values(ROLES)].map(item => (
                    <MenuItem
                      classes={{
                        root: classes.dropDownSelectItem,
                        selected: `${classes.dropDownSelectItem} _selected`
                      }}
                      key={item}
                      value={item}>{ROLE_NAMES[item] ? ROLE_NAMES[item].toLowerCase() : 'All'}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                name="search"
                placeholder="Search"
                label="Search"
                type="text" onChange={this.onChangeSearch()} value={search}
                InputProps={{
                  classes: {
                    input: classes.input,
                    underline: classes.underline
                  }
                }}
              />
              <UrlifeButton
                type="submit"
                disabled={isLoading}
                color="primary"
                variant="contained"
                className={classes.submitButton}
              >
                Search
              </UrlifeButton>
            </form>
          </div>

          <UrlifeInfiniteScroller
            loadMore={this.fetchUsers(true)}
            hasMore={hasMore}
            isLoading={isLoading}
            isEmpty={!users.length}
            className={classes.usersTable}
          >
            <div className={classes.headerRow}>
              <div className={classes.headerCell}>
                User
              </div>
              <div className={classes.headerCell}>
                Name
              </div>
              <div className={classes.headerCell}>
                Role
              </div>
              <div className={classes.headerCell}>
                Package access
              </div>
              <div className={classes.headerCell}>
                &nbsp;
              </div>
            </div>
            {users && users.map(use => {
              return (
                <div
                  onClick={(e) => this.toUserProjects(e, use.id)}
                  key={use.id}
                  className={classes.userContainer}
                >
                  <span
                    className={classes.userEmail}
                  >
                    {use.email}
                  </span>
                  <span className={classes.userName}>
                    {use.fullname}
                  </span>
                  <span className={classes.userRole}>{ROLE_NAMES[use.roleId].toLowerCase()}</span>
                  <div className={classes.userPackageAccess}>
                    {![ROLES.PRODUCER, ROLES.EDITOR].includes(use.roleId) &&
                    <span>Not applicable</span>
                    }
                    {packages && [ROLES.PRODUCER, ROLES.EDITOR].includes(use.roleId) &&
                    <FormControl className={classes.packageSelectFormControl}>
                      <Select
                        className={classes.packageSelect}
                        multiple
                        value={use.packageAccess}
                        onClick={e => this.handleClickSelector(e)}
                        onChange={e => this.onChangePackageAccess(e, use.id)}
                        input={<Input id={`select-package-access-${use.id}`} />}
                        renderValue={selected => selected.map(p => this.getPackageNameById(p)).join(', ')}
                      >
                        {packages.map(pack => (
                          <MenuItem key={pack.id} value={pack.id}>
                            <Checkbox checked={use.packageAccess.includes(pack.id)} />
                            <ListItemText primary={pack.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    }
                  </div>
                  <UrlifeButton
                    inverted
                    className={classes.button}
                    onClick={(e) => this.changeUserRole(e, use.id, use.roleId, use.email)}
                  >
                    Change
                  </UrlifeButton>
                  {[ROLES.USER].includes(use.roleId) &&
                    <UrlifeButton
                      className={classes.button}
                      onClick={(e) => this.toUserCoupons(e, use.id)}
                    >
                      Coupons
                    </UrlifeButton>
                  }
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
          {savedRole &&
            <UrlifePageInfo
              message='Role has been successfully updated.'
              open={true}
              onClose={this.resetSavedUserRole}
            />
          }
        </Paper>
      </div>
    )
  }
}

UrlifeUsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(Object)
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(UrlifeUsersPage))
