import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PlusCircleIconNotch ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 117 127'>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-374.000000, -1144.000000)" stroke={color || '#4A30B5'} strokeWidth="3">
          <g transform="translate(361.000000, 868.000000)">
            <g transform="translate(15.000000, 281.000000)">
              <ellipse id="Oval" cx="56.5" cy="54" rx="56.5" ry="54"></ellipse>
              <line x1="56.5" y1="72" x2="56.5" y2="36.521911" id="Path" strokeLinecap="round"></line>
              <line x1="38.3775871" y1="54.2609555" x2="67.2789276" y2="54.2609555" id="Path" strokeLinecap="round"></line>
              <line x1="72.4896515" y1="54.2609555" x2="74.6830027" y2="54.2609555" id="Path" strokeLinecap="round"></line>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

PlusCircleIconNotch.propTypes = {
  color: PropTypes.string
}
