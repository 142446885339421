import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  getLeastCompleteProjectSkuStatus,
  history,
  mapProjectStatusToStage
} from '../../helpers'
import classNames from 'classnames'
import {
  BackArrow,
  PencilIcon
} from './SVG'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MoreMenu from './MoreMenu/MoreMenu'
import ProjectChat from './chat/ProjectChat'
import {
  PROJECT_STAGES,
  ROLES
} from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  backButton: {
    color: '#333',
    cursor: 'pointer',
    marginTop: 17
  },
  titleTextHolder: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  projectTitle: {
    color: '#323232',
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '72px',
    marginBottom: -1,
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  projectDescription: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    padding: '0 10px',
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '&._userPrep': {
      justifyContent: 'center'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  projectForm: {
    width: '60vw',
    maxWidth: 850,
    marginTop: '-6px',
    '&._title': {
      marginTop: 2,
      marginBottom: 8
    }
  },
  editIcon: {
    cursor: 'pointer',
    marginTop: -14,
    '&._title': {
      marginLeft: 10,
      marginTop: 5
    }
  },
  iconHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 60
  },
  projectInput: {
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '72px'
  }
}
/* eslint-enable sort-keys */

class ProjectTitleRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      description: props.project && props.project.description ? props.project.description : '',
      editingDesc: false,
      editingTitle: false,
      title: props.project && props.project.title ? props.project.title : ''
    }
  }

  back () {
    history.goBack()
  }

  startEditTitle () {
    this.setState({ editingTitle: true })
  }

  stopEditTitle () {
    const { updateProject, project } = this.props
    const { title } = this.state
    if (title) {
      this.setState({ editingTitle: false })
      updateProject({ title: title })
    } else {
      this.setState({ editingTitle: false, title: project.title })
    }
  }

  onChangeTitle (e) {
    this.setState({ title: e.target.value })
  }

  startEditDesc () {
    this.setState({ editingDesc: true })
  }

  stopEditDesc () {
    const { updateProject } = this.props
    const { description } = this.state
    this.setState({ editingDesc: false })
    updateProject({ description: description })
  }

  onChangeDesc (e) {
    this.setState({ description: e.target.value })
  }

  render () {
    const {
      classes,
      project,
      roleId,
      openChat,
      saveProject,
      downloadProject,
      deleteProject
    } = this.props
    const { editingTitle, title, editingDesc, description } = this.state
    const statusId = getLeastCompleteProjectSkuStatus(project)
    const stage = mapProjectStatusToStage(statusId)
    const isUserPrep = stage === PROJECT_STAGES.PREP
    const isComplete = stage === PROJECT_STAGES.COMPLETE
    const isCustomer = roleId && roleId === ROLES.USER
    const canEdit = this.props.canEdit && !isComplete
    const canChat = !isComplete
    const chatDefault = openChat ? 'open' : 'closed'
    return (
      <div className={classNames(classes.titleRow, { _userPrep: isUserPrep })}>
        {!isUserPrep &&
          <div className={classes.iconHolder}>
            <BackArrow className={classes.backButton} onClick={this.back} />
          </div>
        }
        <div className={classes.titleTextHolder}>
          {!editingTitle &&
            <div className={classes.textContainer}>
              <span className={classNames(classes.projectTitle, { _clickable: canEdit })}
                onClick={canEdit ? this.startEditTitle.bind(this) : null}>
                {title}
              </span>
              {canEdit &&
              <PencilIcon className={classNames(classes.editIcon, '_title')}
                onClick={this.startEditTitle.bind(this)} />
              }
            </div>
          }
          {editingTitle &&
            <FormControl
              variant={'outlined'}
              className={classNames(classes.projectForm, '_title')}>
              <TextField
                autoFocus
                onChange={this.onChangeTitle.bind(this)}
                onBlur={this.stopEditTitle.bind(this)}
                className={classNames(classes.cssUnderline)}
                defaultValue={title}
                placeholder={project.title}
                InputProps={{
                  classes: { root: classes.projectInput }
                }}
                inputProps={{ maxLength: 50 }}
              />
            </FormControl>
          }
          {!editingDesc &&
            <div className={classes.textContainer}>
              <span className={classNames(classes.projectDescription, { _clickable: canEdit })}
                onClick={canEdit ? this.startEditDesc.bind(this) : null}>
                {description}
              </span>
              {canEdit &&
                <PencilIcon className={classes.editIcon}
                  onClick={this.startEditDesc.bind(this)} />
              }
            </div>
          }
          {editingDesc &&
            <FormControl
              variant={'outlined'}
              className={classes.projectForm}>
              <TextField
                autoFocus
                onChange={this.onChangeDesc.bind(this)}
                onBlur={this.stopEditDesc.bind(this)}
                className={classNames(classes.cssUnderline)}
                defaultValue={description}
                placeholder={'Add a short description'}
                inputProps={{ maxLength: 50 }}
              />
            </FormControl>
          }
        </div>
        {!isUserPrep &&
        <div className={classes.iconHolder}>
          {canChat && <ProjectChat project={project} view={'drawer'} defaultState={chatDefault} />}
          {isCustomer && <MoreMenu
            type={'project'}
            saveProject={saveProject}
            downloadProject={downloadProject}
            deleteProject={deleteProject}
            moreIconColor={'#000000'}
          />}
        </div>
        }
      </div>
    )
  }
}

ProjectTitleRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  deleteProject: PropTypes.func,
  downloadProject: PropTypes.func,
  openChat: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number,
  saveProject: PropTypes.func,
  updateProject: PropTypes.func
}

ProjectTitleRow.defaultProps = {
  canEdit: false,
  openChat: false
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProjectTitleRow))
