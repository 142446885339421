import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  BackArrow
} from '../common/SVG'
import VideoPlayerPreview from '../common/VideoPlayerPreview'
import { commonStyles, history } from '../../helpers'
import poster from '../../assets/img/marketing/parallaxMovie1.jpg'

/* eslint-disable sort-keys */
const styles = {
  aboutUsContainer: {
    marginTop: -20,
    ...commonStyles.media(575, {
      marginTop: 10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 10
    })
  },
  aboutUsHeader: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  aboutUsTitle: {
    margin: 0,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '72px'
    })
  },
  backArrow: {
    color: '#333',
    cursor: 'pointer',
    position: 'absolute',
    left: 5,
    top: 25
  },
  videoHolder: {
    marginTop: 70,
    marginBottom: 60
  },
  infoHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    ...commonStyles.media(650, {
      flexDirection: 'column'
    })
  },
  whoHolder: {
    width: '45%',
    ...commonStyles.media(650, {
      width: '100%'
    })
  },
  promiseHolder: {
    width: '45%',
    ...commonStyles.media(650, {
      marginTop: 20,
      width: '100%'
    })
  },
  infoTitle: {
    marginBottom: 5,
    color: '#323232',
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.5
  },
  infoText: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px'
  },
  contactHolder: {
    marginTop: 60
  },
  contactInfoHolder: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    ...commonStyles.media(650, {
      justifyContent: 'space-between'
    })
  },
  phoneInfoHolder: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 100,
    ...commonStyles.media(650, {
      marginRight: 0
    })
  },
  emailInfoHolder: {
    display: 'flex',
    flexDirection: 'column'
  }
}
/* eslint-enable sort-keys */

class AboutUs extends React.Component {
  goBack () {
    history.goBack()
  }

  render () {
    const { classes } = this.props
    const videoSrc = 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
    const videoJsOptions = {
      aspectRatio: '16:9',
      autoplay: false,
      controls: true,
      fluid: true,
      poster,
      preload: 'none'
    }
    return <div>
      <div className={classes.aboutUsContainer}>
        <div className={classes.aboutUsHeader}>
          <BackArrow className={classes.backArrow} onClick={this.goBack}/>
          <h2 className={classes.aboutUsTitle}>About Us</h2>
        </div>
        <div className={classes.videoHolder}>
          <VideoPlayerPreview
            {...videoJsOptions}
            video={videoSrc}
            sources={[{ src: videoSrc }]}
            wrapperWidth={'100%'}
          />
        </div>
        <div className={classes.infoHolder}>
          <div className={classes.whoHolder}>
            <div className={classes.infoTitle}>
              Who we are
            </div>
            <div className={classes.infoText}>
              We are editing professionals who are passionate about helping people tell their stories.
              We believe memories are important. They deserve to be preserved and shared.
              And we provide the human touch that makes it personal to each of our users.
            </div>
          </div>
          <div className={classes.promiseHolder}>
            <div className={classes.infoTitle}>
              Our promise
            </div>
            <div className={classes.infoText}>
              Honoring your life’s  best moments through thoughtful curation,
              expert storytelling and first-rate video editing.
              Bringing out the best of your memorable moments by crafting an
              unforgettable movie you can watch for years to come. 100% guaranteed.
            </div>
          </div>
        </div>
        <div className={classes.contactHolder}>
          <div className={classes.infoTitle}>
            Contact
          </div>
          <div className={classes.contactInfoHolder}>
            <div className={classes.phoneInfoHolder}>
              <div className={classes.infoText}>
                Phone
              </div>
              <div className={classes.infoText}>
                310-730-4501
              </div>
            </div>
            <div className={classes.emailInfoHolder}>
              <div className={classes.infoText}>
                Email
              </div>
              <div className={classes.infoText}>
                hello@urlifemedia.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
AboutUs.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AboutUs)
