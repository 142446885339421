import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  CoverIcon,
  NoProjectsIcon,
  PromoIcon,
  SettingsIcon,
  UserIcon
} from '../common/SVG/index'
import { history } from '../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  mainMenu: {
    display: 'flex',
    flexDirection: 'column'
  },
  subMenu: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 330
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0px',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      color: '#01b7d7'
    },
    '&._active': {
      color: '#01b7d7'
    }
  },
  menuText: {
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginLeft: 10
  },
  iconWrapper: {
    width: 47,
    height: 47,
    padding: 12,
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
  },
  optionHeader: {
    fontSize: 24,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#01b7d7',
    borderBottom: '2px solid #01b7d7',
    margin: '10px 0'
  },
  optionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0px',
    color: '#323232',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e7e7e7'
    }
  }
}
/* eslint-enable sort-keys */

const topMenu = [
  {
    icon: <UserIcon stroke={'#ffffff'}/>,
    options: [
      {
        path: '/admin/users',
        text: 'user list'
      },
      {
        path: '/admin/subscriptions',
        text: 'topic subscriptions'
      }
    ],
    text: 'users'
  },
  {
    icon: <NoProjectsIcon fill={'#ffffff'} />,
    options: [
      {
        path: '/admin/projects',
        text: 'project list'
      },
      {
        path: '/admin/negotiation',
        text: 'custom price negotiation'
      }
    ],
    text: 'projects'
  },
  {
    icon: <PromoIcon fill={'#ffffff'} />,
    options: [
      {
        path: '/admin/promocodes',
        text: 'promocodes'
      },
      {
        path: '/admin/projectcodes',
        text: 'projectcodes'
      }
    ],
    text: 'promotions'
  },
  {
    icon: <CoverIcon style={{ color: '#ffffff' }} />,
    options: [
      {
        path: '/admin/categories',
        text: 'categories'
      },
      {
        path: '/admin/prompts',
        text: 'prompts'
      },
      {
        path: '/admin/explore',
        text: 'explore videos'
      },
      {
        path: '/admin/inspiration',
        text: 'inspiration videos'
      },
      {
        path: '/admin/tips',
        text: 'tips videos'
      },
      {
        path: '/admin/emails',
        text: 'email templates'
      }
    ],
    text: 'system assets'
  },
  {
    icon: <SettingsIcon fill={'#ffffff'} />,
    options: [
      {
        path: '/admin/encoding',
        text: 'encoding queue'
      }
    ],
    text: 'system'
  },
  {
    icon: <NoProjectsIcon fill={'#ffffff'} />,
    options: [
      {
        path: '/admin/reports/usersprojectsbyyear',
        text: 'new users / projects by year'
      },
      {
        path: '/admin/reports/newusers',
        text: 'new users not started / completed'
      },
      {
        path: '/admin/reports/repeatingusers',
        text: 'repeating users'
      },
      {
        path: '/admin/reports/returningusers',
        text: 'returning users'
      },
      {
        path: '/admin/reports/firsttimeusers',
        text: 'first time users'
      },
      {
        path: '/admin/reports/prepurchaseusers',
        text: 'pre-purchase activity'
      }
      /* {
        path: '/admin/reports/projectapproval',
        text: 'post project approval email cadence'
      },
      {
        path: '/admin/reports/awaitingpayment',
        text: 'awaiting payment email cadence'
      } */
    ],
    text: 'reports'
  }
]

class AdminHome extends Component {
  constructor (props) {
    super(props)
    this.state = {
      expandedPanels: [],
      parent: -1
    }
  }

  onPanelChange (panel) {
    if (topMenu[panel].path) {
      return history.push(topMenu[panel].path)
    }
    this.setState({ expandedPanels: topMenu[panel].options, parent: panel })
  }

  render () {
    const { classes } = this.props
    const { expandedPanels, parent } = this.state
    return (
      <div>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.menu}>
            <div className={classes.mainMenu}>
              {topMenu.map((item, index) => (
                <div
                  key={index}
                  className={classNames(classes.menuItem, { _active: index === parent })}
                  onClick={this.onPanelChange.bind(this, index)}
                >
                  <div className={classes.iconWrapper}>{item.icon}</div>
                  <span className={classes.menuText}>{item.text}</span>
                </div>
              ))}
            </div>
            <div className={classes.subMenu}>
              {expandedPanels.length > 0 && <div className={classes.optionHeader}>{topMenu[parent].text}</div>}
              {expandedPanels && expandedPanels.map((option, index) => (
                <div
                  key={index}
                  className={classes.optionItem}
                  onClick={() => { history.push(option.path) }}
                >
                  <SettingsIcon fill={'#323232'}/>
                  <span className={classes.menuText}>{option.text}</span>
                </div>
              ))}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

AdminHome.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AdminHome)
