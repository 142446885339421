import { api } from './../constants'
import ApiService from '../services/ApiService'

export {
  updateEmailTemplate,
  fetchEmailTemplates,
  fetchEmailTemplate
}

function updateEmailTemplate (emailId, emailData) {
  return dispatch => {
    return new ApiService(api.ONE_EMAIL_TEMPLATE, { emailId }, {}, true, dispatch)
      .put(emailData)
  }
}

function fetchEmailTemplates (params = {}) {
  return dispatch => {
    return new ApiService(api.EMAIL_TEMPLATES, {}, params, true, dispatch)
      .get()
  }
}

function fetchEmailTemplate (emailId, params = {}) {
  return dispatch => {
    return new ApiService(api.ONE_EMAIL_TEMPLATE, { emailId }, params, true, dispatch)
      .get()
  }
}
