import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl/FormControl'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { listUsers } from '../../../actions'
import { withStyles } from '@material-ui/core'
import { PROJECT_STATUSES, ROLES } from '../../../constants'

/* eslint-disable sort-keys */
const styles = {
  selectProjectRole: {
    width: '100%',
    '&:after': {
      borderBottomColor: '#5334b6'
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: '#717171',
    lineHeight: '20px'
  }
}
/* eslint-enable sort-keys */

class SelectProjectRole extends Component {
  constructor (props) {
    super(props)
    let userId = ''
    switch (props.roleId) {
      case ROLES.PRODUCER:
        userId = props.project.projectSkus[0].producerId
        break
      case ROLES.EDITOR:
        userId = props.project.projectSkus[0].editorId
        break
      default:
        break
    }
    this.state = {
      error: '',
      isLoading: true,
      userId,
      userList: []
    }
  }

  async producerChangeHandler (nextProducer) {
    const { project, updateProjectSku } = this.props
    const { userId: prevProducer } = this.state

    if (prevProducer !== nextProducer) {
      let statusId = project.projectSkus[0].statusId
      if (prevProducer === null) {
        statusId = PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED
      }
      if (nextProducer === null) {
        statusId = PROJECT_STATUSES.PROJECT_QUEUED
      }
      this.setState({ error: '', userId: nextProducer })
      try {
        await updateProjectSku({ producerId: nextProducer, statusId })
      } catch (e) {
        this.setState({ error: 'Unable to change producer', userId: prevProducer })
      }
    }
  }

  async editorChangeHandler (nextEditor) {
    const { updateProjectSku } = this.props
    const { userId: prevEditor } = this.state

    if (prevEditor !== nextEditor) {
      let statusId = PROJECT_STATUSES.PRODUCTION_EDITING
      if (nextEditor === null) {
        statusId = PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED
      }
      this.setState({ error: '', userId: nextEditor })
      try {
        await updateProjectSku({ editorId: nextEditor, statusId })
      } catch (e) {
        this.setState({ error: 'Unable to change editor', userId: prevEditor })
      }
    }
  }

  projectRoleChangeHandler (e) {
    const { roleId } = this.props
    switch (roleId) {
      case ROLES.PRODUCER:
        this.producerChangeHandler(e.target.value)
        break
      case ROLES.EDITOR:
        this.editorChangeHandler(e.target.value)
        break
      default:
        break
    }
  }

  componentDidMount () {
    const { dispatch, roleId } = this.props
    dispatch(listUsers({ queryRole: roleId }))
      .then(
        respond => this.setState({
          isLoading: false,
          userList: respond.data
        })
      )
  }

  render () {
    const { userList, userId, isLoading, error } = this.state
    const { disabled, classes, roleId } = this.props
    const roleDescription = roleId === ROLES.PRODUCER ? 'producers' : 'editors'
    return (
      <FormControl margin="none" fullWidth={true} error={!!error}>
        <InputLabel
          classes={{ root: classes.label }}
        >
          Choose a name
          {!isLoading && !userList.length && ' (no ' + roleDescription + ')'}
        </InputLabel>
        <Select
          className={classes.selectProjectRole}
          disabled={disabled || !userList.length}
          value={userId || ''}
          onChange={e => this.projectRoleChangeHandler(e)}>
          <MenuItem value={null}><em>None</em></MenuItem>
          {userList.length > 0 &&
          userList.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.fullname || ''} ({user.email})
            </MenuItem>
          ))
          }
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )
  }
}

SelectProjectRole.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object,
  roleId: PropTypes.oneOf([ROLES.PRODUCER, ROLES.EDITOR]).isRequired,
  updateProjectSku: PropTypes.func.isRequired
}

export default withStyles(styles)(connect()(SelectProjectRole))
