import ApiService from '../services/ApiService'
import { abortUpload, uploadFile } from './index'
import { api } from './../constants'
import { cacheSignedUrl } from '../services/CacheSignedUrls'

export {
  createCast,
  createCastFile,
  deleteCast,
  deleteCastFile,
  listCast,
  updateCast,
  updateCastFile
}

function createCast (cast, projectId) {
  return dispatch => {
    return new ApiService(api.CAST, { projectId }, {}, true, dispatch)
      .post(cast)
      .then(
        data => {
          return data.data[0]
        }
      )
  }
}

function updateCast (cast, projectId) {
  return dispatch => {
    return new ApiService(api.ONE_CAST, { castId: cast.id, projectId }, {}, true, dispatch)
      .put(cast)
      .then(
        data => {
          return data.data[0]
        }
      )
  }
}

function deleteCast (castId, projectId, castFileId) {
  return (dispatch, getStore) => {
    if (castFileId) {
      const upload = Object.values(getStore().fileProgress).filter(file => {
        return file.id === castFileId && file.progressType === 'upload'
      })
      if (upload.length > 0) {
        dispatch(abortUpload(castFileId))
      }
    }
    return new ApiService(api.ONE_CAST, { castId, projectId }, {}, true, dispatch)
      .delete()
      .then(data => {
        return data
      })
  }
}

function listCast (projectId) {
  return dispatch => {
    return new ApiService(api.CAST, { projectId }, {}, true, dispatch)
      .get()
      .then(
        data => {
          // cache signed urls
          const fileLinks = data.data.reduce((arr, file) => {
            arr.push(file.signedThumbnailUrl)
            arr.push(file.signedUrl)
            return arr
          }, [])
          cacheSignedUrl.add(fileLinks)
          return data.data
        }
      )
  }
}

function createCastFile (castId, projectId, file, fileObject) {
  return dispatch => {
    return new ApiService(api.CAST_FILES, { castId, projectId }, {}, true, dispatch)
      .post(file)
      .then(
        response => {
          const responseFileData = response.data[0]
          dispatch({
            payload: {
              castFileId: responseFileData.id,
              id: castId,
              orientation: file.files[0].orientation || null,
              signedUrl: window.URL.createObjectURL(fileObject)
            },
            type: 'UPDATE_CAST'
          })
          dispatch(uploadFile(
            null,
            fileObject,
            responseFileData.id,
            responseFileData.fileExt,
            projectId,
            'cast',
            'cast/' + responseFileData.id + '.' + responseFileData.fileExt,
            (cognitoInfo) => {
              return dispatch(updateCastFile(castId,
                projectId,
                { id: responseFileData.id, status: 3 },
                cognitoInfo.IdentityId)
              )
            }
          ))
          return responseFileData.id
        }
      )
  }
}

function updateCastFile (castId, projectId, file, identityId) {
  return dispatch => {
    return new ApiService(api.ONE_CAST_FILE,
      { castId, fileId: file.id, projectId }, { identityId }, true, dispatch)
      .put(file)
  }
}

function deleteCastFile (fileId, castId, projectId) {
  return (dispatch, getStore) => {
    if (getStore().upload && getStore().upload[fileId]) {
      dispatch(abortUpload(fileId))
    }
    return new ApiService(api.ONE_CAST_FILE, { castId, fileId, projectId },
      {}, true, dispatch)
      .delete()
  }
}
