import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {
  commonStyles,
  formatPrice,
  history,
  toLocale
} from '../../../../helpers'
import { initProjectCreationStatus } from '../../../../constants'
import { connect } from 'react-redux'
import {
  createProject,
  createProjectSku,
  getAllPromptsCategory
} from '../../../../actions'
import UrlifeButton from '../../UrlifeButton'
import { CouponIcon, ExpandIcon, ParallaxListIcon, ParallaxPlayIcon } from '../../SVG'
import classNames from 'classnames'
import styled from 'styled-components'
import ModalPreview from '../../ModalPreview'
import PackageTitle from './PackageTitle'

/* eslint-disable sort-keys */
const styles = {
  itemHeader: {
    '&._fontSize-small': {
      padding: 10
    },
    '&._fontSize-medium': {
      padding: 21
    }
  },
  thumbnail: {
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  filter: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    width: '100%',
    height: 'calc(100% - 5px)',
    background: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.34) 0%, rgba(255, 255, 255, 0.14) 20%)',
    cursor: 'pointer'
  },
  playIcon: {
    display: 'inline-block',
    width: '10%',
    height: 'auto',
    margin: 'auto'
  },
  textContainer: {
    padding: '9px 0 2px'
  },
  text: {
    padding: '9px 16px 0px 16px',
    margin: 0,
    color: '#323232',
    fontWeight: 500,
    '&._fontSize-small': {
      fontSize: 16,
      lineHeight: '22px',
      padding: '5px 10px 0px 10px',
      ...commonStyles.media(1510, {
        fontSize: 15,
        lineHeight: '21px'
      }),
      ...commonStyles.media(1420, {
        fontSize: 14,
        lineHeight: '20px'
      }),
      ...commonStyles.media(1340, {
        fontSize: 13,
        lineHeight: '20px'
      })
    },
    '&._fontSize-medium': {
      fontSize: 16,
      lineHeight: '26px'
    }
  },
  textHeader: {
    padding: '9px 16px 9px 16px',
    margin: 0,
    color: '#323232',
    '&._fontSize-small': {
      fontSize: 16,
      fontWeight: 650,
      padding: '5px 10px 5px 10px',
      ...commonStyles.media(1510, {
        fontSize: 15
      }),
      ...commonStyles.media(1420, {
        fontSize: 14
      }),
      ...commonStyles.media(1340, {
        fontSize: 13
      })
    },
    '&._fontSize-medium': {
      fontSize: 16,
      fontWeight: 600
    },
    '&._noPadding': {
      padding: 0
    }
  },
  list: {
    padding: '11px 0 3px'
  },
  listItem: {
    color: '#323232',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    '&._fontSize-small': {
      fontSize: 14,
      paddingTop: 4,
      paddingBottom: 4,
      ...commonStyles.media(1510, {
        fontSize: 13
      }),
      ...commonStyles.media(1420, {
        fontSize: 12
      }),
      ...commonStyles.media(1340, {
        fontSize: 11
      })
    },
    '&._fontSize-medium': {
      fontSize: 14
    }
  },
  icon: {
    '&._fontSize-small': {
      width: 16,
      height: 16,
      marginRight: 10,
      ...commonStyles.media(1420, {
        width: 15,
        height: 15
      }),
      ...commonStyles.media(1340, {
        width: 14,
        height: 14
      })
    },
    '&._fontSize-medium': {
      width: 16,
      height: 16,
      marginRight: 15
    }
  },
  expandRow: {
    color: '#01b7d7',
    position: 'absolute',
    bottom: 0,
    '& span': {
      fontWeight: 500,
      position: 'relative'
    },
    '&._fontSize-small': {
      fontSize: 16,
      '& span': {
        left: -8
      },
      ...commonStyles.media(1510, {
        fontSize: 15
      }),
      ...commonStyles.media(1420, {
        fontSize: 14
      }),
      ...commonStyles.media(1340, {
        fontSize: 13
      })
    },
    '&._fontSize-medium': {
      fontSize: 16
    }
  },
  expandIcon: {
    width: 15,
    height: 15,
    position: 'relative',
    top: 2
  },
  details: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordionSummary-content': {
      margin: '20px 0'
    },
    '&._border-none': {
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
      borderRadius: 4
    },
    '&._border-purple': {
      boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid #5334b6',
      borderRadius: 0,
      ...commonStyles.media(575, {
        width: 'calc(100% + 60px)',
        marginLeft: -30,
        border: 'none',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
        borderRadius: 3
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        width: 'calc(100% + 60px)',
        marginLeft: -30,
        border: 'none',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)',
        borderRadius: 3
      })
    }
  },
  panel: {
    boxShadow: 'none',
    paddingBottom: 50,
    '&:before': {
      height: 0
    }
  },
  priceContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderTop: '1px solid #dfdfdf',
    '&._coming_soon': {
      padding: '15px 0',
      justifyContent: 'center'
    },
    '&._borderBottom': {
      borderBottom: '1px solid #dfdfdf'
    }
  },
  priceGrid: {
    flexWrap: 'nowrap'
  },
  priceGridItem: {
    flexGrow: 1
  },
  priceBlock: {
    padding: '15px 0px 0px 20px',
    margin: 'auto auto auto 0px'
  },
  price: {
    position: 'relative',
    display: 'inline-block',
    color: '#323232',
    fontSize: 30,
    fontWeight: 400,
    marginRight: 15,
    '&._coupon': {
      color: '#9455c8',
      marginTop: 10,
      '&:before': {
        content: '"now"',
        position: 'absolute',
        left: 1,
        top: -12,
        fontSize: 14,
        fontWeight: 600
      }
    }
  },
  priceText: {
    display: 'block',
    height: 33,
    padding: '0px 0px 15px 20px',
    margin: 'auto',
    color: '#323232',
    fontSize: 14,
    fontWeight: 500,
    '&._skinned': {
      height: 85,
      padding: '0px 0px 0px 20px',
      display: 'inline-flex',
      alignItems: 'center'
    },
    '&._empty': {
      height: 0
    }
  },
  chooseButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '8px 30px',
    margin: 'auto',
    marginRight: 20,
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    },
    '&._pricing': {
      fontSize: 12,
      padding: '8px 10px'
    }
  },
  couponBlock: {
    padding: '14px 20px 0',
    marginBottom: -10
  },
  couponHeader: {
    color: '#9455c8',
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  couponDescripton: {
    color: '#323232',
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 400,
    '& span': {
      fontWeight: 700
    }
  },
  couponIcon: {
    color: '#9455c8',
    width: 20,
    height: 20,
    marginRight: 9
  }
}
/* eslint-enable sort-keys */

const DescriptionContainer = styled.div`
  min-height: ${props => props.minHeight}px;
  @media (max-width: 960px) {
    min-height: 0px;
  };
`

const PriceStrikeThrough = styled.div`
  position: absolute;
  background-color: #9455c8;
  left: -${props => props.extend}px;
  right: -${props => props.extend}px;
  top: calc(50% - 1.5px);
  bottom: calc(50% - 1.5px);
  transform: rotate(${props => props.angle}rad);
`

class PriceBlock extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false,
      openModal: false,
      previewVideo: null,
      strikeThroughAngle: 0,
      strikeThroughExtend: 0
    }
    this.handleClickExpand = this.handleClickExpand.bind(this)
    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this)
    this.editPrice = this.editPrice.bind(this)
    this.splitText = this.splitText.bind(this)
    this.priceRef = React.createRef()

    this.descriptionRef = React.createRef()
    this.onResize = () => {
      const { pack, onDescriptionHeightChange } = this.props
      const descriptionElement = this.descriptionRef.current
      if (descriptionElement && onDescriptionHeightChange) {
        onDescriptionHeightChange(pack.id, descriptionElement.getBoundingClientRect().height)
      }
    }
  }

  componentDidMount () {
    if (this.props.onDescriptionHeightChange) {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    }

    const priceElement = this.priceRef.current
    if (priceElement) {
      const width = priceElement.getBoundingClientRect().width
      const height = priceElement.getBoundingClientRect().height
      const hypotenuse = Math.sqrt(width * width + height * height)
      this.setState({
        strikeThroughAngle: -0.7 * Math.atan(height / width),
        strikeThroughExtend: (hypotenuse - width) / 2 * 0.9
      })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  fromCreationFlow () {
    const { projectId } = this.props
    return projectId && history.location.pathname === `/createmovie/start/${projectId}`
  }

  handleOpenModalPreview () {
    const {
      skinData,
      pack
    } = this.props
    const skinned = !!skinData
    this.setState({
      openModal: true,
      previewVideo: skinned ? skinData.packageVideoUrl : pack.videoUrl
    })
  }

  closeModal () {
    this.setState({ openModal: false, previewVideo: null })
  }

  async editPrice (skuId) {
    const {
      pack,
      createProjectFunction,
      createProjectSkuFunction,
      nextStep,
      updateProjectSku
    } = this.props
    if (this.fromCreationFlow()) {
      try {
        await updateProjectSku({ packageSku: skuId })
        nextStep && nextStep()
      } catch (e) {}
    } else {
      try {
        const project = await createProjectFunction(new Date(), pack.id)
        const projectSku = await createProjectSkuFunction(project.id, skuId)
        project.projectSkus = [projectSku]
        nextStep && nextStep(false, project)
      } catch (e) {}
    }
  }

  handleClickExpand () {
    if (this.state.expanded === true) {
      this.setState({ expanded: false })
    } else {
      this.setState({ expanded: true })
    }
  }

  getButtonText (sku) {
    const { skinData, packageSkuId } = this.props
    const isPricingPage = history.location.pathname === '/pricing'
    const skinned = !!skinData
    if (sku.comingSoon) return 'Coming Soon'
    if (isPricingPage) return 'Start a project'
    if (skinned) return 'Start project'
    if (this.fromCreationFlow()) {
      if (packageSkuId === sku.id) return 'Continue'
      return 'Change'
    }
    return 'Choose'
  }

  splitText (text) {
    if (text && typeof text === 'string') {
      return text.split('|')
    }
    return ['']
  }

  render () {
    const {
      classes,
      pack,
      descriptionMinHeight,
      coupons,
      loggedIn,
      priceBlock,
      border,
      fontSize,
      skinData,
      packageOverride
    } = this.props
    const { openModal, previewVideo, strikeThroughExtend, strikeThroughAngle } = this.state
    const skinned = !!skinData
    let subtitle = []
    if (packageOverride && packageOverride.subtitle) {
      subtitle = packageOverride.subtitle.split('|')
    } else if (skinned && skinData.packageSubtitle) {
      subtitle = skinData.packageSubtitle.split('|')
    } else {
      subtitle = pack.subtitle.split('|')
    }
    const isPricingPage = history.location.pathname === '/pricing'
    const borderClass = '_border-' + border
    const fontSizeClass = '_fontSize-' + fontSize
    return (
      <Paper className={classNames(classes.paper, borderClass)}>
        <div className={classNames(classes.itemHeader, fontSizeClass)}>
          <PackageTitle packageId={pack.id} fontSize={fontSize} />
          {subtitle.length > 1 && fontSize === 'small' &&
          <div className={classNames(classes.textHeader, fontSizeClass, '_noPadding')}>{subtitle[0]}</div>
          }
        </div>
        {pack.thumbnailUrl && <div
          className={classes.thumbnail}
          onClick={() => {
            skinned
              ? skinData.packageVideoUrl && this.handleOpenModalPreview()
              : pack.videoUrl && this.handleOpenModalPreview()
          }}
        >
          <img src={skinned ? skinData.packageThumbnailUrl : pack.thumbnailUrl} alt="thumbnail"/>
          <div
            className={classes.filter}
            style={ { cursor: pack.videoUrl || skinData.packageVideoUrl ? 'pointer' : 'default' } }
          >
            {pack.videoUrl && <ParallaxPlayIcon className={classes.playIcon}/>}
          </div>
        </div> }
        <DescriptionContainer minHeight={descriptionMinHeight}>
          <div className={classes.textContainer} ref={this.descriptionRef}>
            {subtitle.map((item, index) => {
              const isHeader = subtitle.length > 1 && index === 0
              if (isHeader && fontSize === 'small') {
                return null
              }
              const className = classNames(isHeader ? classes.textHeader : classes.text, fontSizeClass)
              return <p key={index} className={className}>
                {item}
              </p>
            })}
          </div>
        </DescriptionContainer>

        <Accordion
          expanded={this.state.expanded}
          classes={{
            expanded: classes.panelExpanded,
            root: classes.panel
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandIcon className={classes.expandIcon} />}
            aria-controls={'panel-content'}
            id={'panel-header'}
            classes={{
              expanded: classes.expandRowExpanded,
              root: classNames(classes.expandRow, fontSizeClass)
            }}
            onClick={() => this.handleClickExpand()}
          >
            <span>{this.state.expanded ? 'Close details' : 'See details'}</span>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <List className={classes.list}>
              {pack.jsonBulletList.map((item, idx) => {
                return (
                  <ListItem key={idx} className={classNames(classes.listItem, fontSizeClass)}>
                    <ParallaxListIcon
                      color={skinned ? skinData.mainColor : null}
                      className={classNames(classes.icon, fontSizeClass)}
                    />
                    {item}
                  </ListItem>
                )
              })}
            </List>
          </AccordionDetails>
          {priceBlock === 'price-only' && pack.skus.map((priceInfo, idx) => {
            return (
              <div key={idx}
                className={classNames(classes.priceContainer, { _borderBottom: idx === pack.skus.length - 1 })}
              >
                <Grid
                  className={classes.priceGrid}
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={0}>
                </Grid>
                <Grid item className={classes.priceGridItem}>
                  <div className={classes.priceBlock}>
                    <span className={classes.price} ref={this.priceRef}>
                      {priceInfo.comingSoon ? 'Coming Soon' : priceInfo.price}
                    </span>
                  </div>
                  <span className={classNames(classes.priceText, { _empty: !priceInfo.description })}>
                    {this.splitText(priceInfo.description)[0]}
                  </span>
                </Grid>
              </div>
            )
          })}
        </Accordion>
        {priceBlock === 'full' && pack.skus.map((priceInfo, idx) => {
          const amountOffCoupons = coupons
            ? coupons.filter(coupon => {
              return coupon.amount_off && coupon.skus && coupon.skus.includes(priceInfo.id)
            })
            : []
          const percentOffCoupons = coupons
            ? coupons.filter(coupon => {
              return coupon.percent_off && coupon.skus && coupon.skus.includes(priceInfo.id)
            })
            : []

          let coupon = null
          let price = Number(priceInfo.price.replace('$', ''))
          if (!isNaN(price)) {
            price = Math.round(price * 100)
          }
          let biggestPercentOff = 0
          percentOffCoupons.forEach(c => {
            if (c.percent_off > biggestPercentOff) {
              biggestPercentOff = c.percent_off
              coupon = c
            }
          })
          let biggestSave = coupon && !isNaN(price) ? price * coupon.percent_off : 0
          amountOffCoupons.forEach(c => {
            if (c.amount_off > biggestSave) {
              biggestSave = c.amount_off
              coupon = c
            }
          })

          let amountOff = 0
          if (coupon && coupon.amount_off) {
            amountOff = isNaN(price) ? coupon.amount_off : Math.min(price, coupon.amount_off)
          }
          let couponOffText = ''
          if (coupon) {
            if (coupon.amount_off) {
              couponOffText = formatPrice(amountOff)
            } else {
              couponOffText = coupon.percent_off + '%'
            }
          }

          let couponPrice = null
          if (coupon && !isNaN(price)) {
            if (coupon.amount_off) {
              couponPrice = formatPrice(price - amountOff)
            } else {
              couponPrice = formatPrice(Math.floor(price - (price * coupon.percent_off / 100)))
            }
          }
          return (
            <div key={idx} className={classNames(classes.priceContainer, { _coming_soon: priceInfo.comingSoon })}>
              {!priceInfo.comingSoon && coupon &&
              <div className={classes.couponBlock}>
                <div className={classes.couponHeader}>
                  <CouponIcon className={classes.couponIcon} />
                  Code available
                </div>
                <div className={classes.couponDescripton}>
                  Available code <span>{'saves ' + couponOffText}</span>
                </div>
              </div>
              }
              <Grid
                className={classes.priceGrid}
                container
                alignItems="center"
                justify="space-between"
                spacing={0}>
                <Grid item className={classes.priceGridItem}>
                  {!priceInfo.comingSoon && !skinned && <div className={classes.priceBlock}>
                    <span className={classes.price} ref={this.priceRef}>
                      {couponPrice &&
                      <PriceStrikeThrough
                        extend={strikeThroughExtend}
                        angle={strikeThroughAngle}
                      />
                      }
                      {priceInfo.price}
                    </span>
                    {couponPrice &&
                    <span className={classNames(classes.price, '_coupon')}>{couponPrice}</span>
                    }
                  </div>}
                  <span className={classNames(classes.priceText, { _skinned: skinned })}>
                    {this.splitText(priceInfo.description)[0]}
                  </span>
                </Grid>
                <Grid item>
                  {loggedIn && <UrlifeButton
                    className={classNames(classes.chooseButton, { _pricing: isPricingPage })}
                    disabled={priceInfo.comingSoon}
                    onClick={() => this.editPrice(priceInfo.id)}>
                    {this.getButtonText(priceInfo)}
                  </UrlifeButton>}
                </Grid>
              </Grid>
            </div>
          )
        })}
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.closeModal.bind(this)}
          video={previewVideo}
          title={''}
        />
      </Paper>
    )
  }
}

PriceBlock.propTypes = {
  border: PropTypes.oneOf(['none', 'purple']).isRequired,
  classes: PropTypes.object.isRequired,
  coupons: PropTypes.arrayOf(Object),
  createProjectFunction: PropTypes.func.isRequired,
  createProjectSkuFunction: PropTypes.func.isRequired,
  descriptionMinHeight: PropTypes.number.isRequired,
  fontSize: PropTypes.oneOf(['small', 'medium']).isRequired,
  getAllPromptsCategoryFunction: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  nextStep: PropTypes.func,
  onDescriptionHeightChange: PropTypes.func,
  pack: PropTypes.object.isRequired,
  packageOverride: PropTypes.object,
  packageSkuId: PropTypes.string,
  priceBlock: PropTypes.oneOf(['none', 'full', 'price-only']).isRequired,
  projectId: PropTypes.string,
  skinData: PropTypes.object,
  updateProjectSku: PropTypes.func
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn,
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createProjectFunction: function (now, packageId, packageSku) {
      return dispatch(createProject({
        creationStatus: initProjectCreationStatus,
        packageId,
        packageSku,
        title: toLocale(now, { date: true })
      }))
    },
    createProjectSkuFunction: function (projectId, packageSku) {
      return dispatch(createProjectSku(projectId, packageSku))
    },
    getAllPromptsCategoryFunction: function (id) {
      return dispatch(getAllPromptsCategory(id))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PriceBlock))
