import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  sortField: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  sortFieldInputComponent: {
    width: 38,
    marginRight: 10
  },
  sortFieldInput: {
    fontSize: 14
  },
  sortFieldIcon: {
    fontSize: 18,
    color: '#01b7d7',
    cursor: 'pointer',
    transition: 'opacity .3s',
    '&._disabled': {
      opacity: 0.5,
      pointerEvents: 'none'
    },
    '& + &': {
      marginLeft: 6
    }
  },
  editSortFieldError: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '100%',
    marginTop: 4
  }
}
/* eslint-enable sort-keys */

class EditSortField extends Component {
  constructor (props) {
    super(props)
    this.errors = {
      duplicate: 'Not unique',
      required: 'Required'
    }
  }

  validateForRequired (value) {
    return value && value.toString().trim().length > 0
  }

  apply () {
    const { onApply, value, isDuplicate } = this.props
    if (!isDuplicate && this.validateForRequired(value)) {
      onApply(+value)
    }
  }

  render () {
    const {
      classes,
      onExchangeFieldPosition,
      value,
      disableSortTop,
      disableSortBot,
      isDuplicate,
      onChange
    } = this.props

    let error = ''
    if (!this.validateForRequired(value)) {
      error = this.errors.required
    } else if (isDuplicate) {
      error = this.errors.duplicate
    }

    return (
      <div className={classes.sortField}>
        <FormControl error={error.length > 0} className={classes.editSortFieldInputControl}>
          <TextField
            error={error.length > 0}
            value={value}
            className={classes.sortFieldInputComponent}
            InputProps={{
              classes: {
                input: classes.sortFieldInput
              }
            }}
            onChange={ e => { onChange(e.currentTarget.value) }}
            onBlur={ () => this.apply() }
          />
          {error &&
          <FormHelperText className={classes.editSortFieldError}>{error}</FormHelperText>}
        </FormControl>

        <ArrowUpward
          className={classNames(
            classes.sortFieldIcon,
            {
              _disabled: disableSortTop
            }
          )}
          onClick={() => onExchangeFieldPosition(-1)} />
        <ArrowDownward
          className={classNames(
            classes.sortFieldIcon,
            {
              _disabled: disableSortBot
            }
          )}
          onClick={ () => onExchangeFieldPosition(1)}/>
      </div>
    )
  }
}

EditSortField.propTypes = {
  classes: PropTypes.object.isRequired,
  disableSortBot: PropTypes.bool,
  disableSortTop: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onExchangeFieldPosition: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default withStyles(styles)(EditSortField)
