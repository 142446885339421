import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, toLocale } from '../../../../helpers/index'
import Typography from '@material-ui/core/Typography'
import UrlifeTextField from '../../../common/UrlifeTextField'
import UrlifeButton from '../../../common/UrlifeButton'

/* eslint-disable sort-keys */
const styles = {
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  expiration: {
    margin: '5px 0px'
  },
  errorBox: {
    margin: '10px 0',
    color: '#d70101',
    opacity: 0
  },
  submitButton: {
    width: 100,
    margin: '10px auto 0pc auto'
  }
}
/* eslint-enable sort-keys */

class ProjectCodeGroupingForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      couponTitle: '',
      formValid: false,
      grouping: {
        expiration: '',
        prefix: ''
      },
      mounted: false
    }
  }

  resetState () {
    const { grouping } = this.props
    const resetGrouping = {
      expiration: grouping ? grouping.expiration : '',
      prefix: grouping ? grouping.prefix : ''
    }
    this.setState({
      couponTitle: grouping ? grouping.couponSetting.title : '',
      formValid: false,
      grouping: resetGrouping,
      mounted: true
    }, () => {
      this.formValidator()
    })
  }

  formValidator () {
    const { grouping, couponTitle } = this.state

    const prefixValid = grouping.prefix.trim().length > 0
    const couponTitleValid = couponTitle.trim().length > 0

    this.setState({ formValid: prefixValid && couponTitleValid })
  }

  onValueChange (e) {
    const { grouping } = this.state
    const value = e.target.value
    const name = e.target.name

    if (name === 'couponTitle') {
      this.setState({
        couponTitle: value
      }, () => {
        this.formValidator()
      })
    } else {
      this.setState({
        grouping: {
          ...grouping,
          [name]: value
        }
      }, () => {
        this.formValidator()
      })
    }
  };

  onSubmit (e) {
    const { grouping, couponTitle } = this.state
    const isUpdate = !!this.props.grouping
    const couponSetting = {
      percent_off: 100,
      title: couponTitle
    }

    if (!(grouping.expiration && grouping.expiration.length > 0)) {
      if (isUpdate) {
        grouping.expiration = null
      } else {
        delete grouping.expiration
      }
    }
    if (isUpdate) {
      grouping.couponSetting = { ...couponSetting, id: this.props.grouping.couponSetting.id }
    } else {
      grouping.couponSetting = couponSetting
    }
    e.preventDefault()
    this.props.onSubmit(grouping)
  }

  componentDidMount () {
    this.resetState()
  }

  componentDidUpdate (prevProps) {
    if (this.props.grouping !== prevProps.grouping) {
      this.resetState()
    }
  }

  render () {
    const { classes, error } = this.props
    const { grouping, couponTitle, formValid, mounted } = this.state
    const dateOptions = {
      date: true,
      full: false,
      time: false
    }

    return (
      <div className={classes.formWrapper}>
        <Typography variant='h4' component='h2'>
          <span>{(this.props.grouping ? 'Edit' : 'Add') + ' Project Code'}</span>
        </Typography>
        {mounted && <form className={classes.container}>
          <UrlifeTextField
            id='project-code-prefix'
            name='prefix'
            label='Prefix'
            autoFocus
            onChange={this.onValueChange.bind(this)}
            placeholder={'Necessary to proceed'}
            defaultValue={grouping.prefix || ''}
          />
          <UrlifeTextField
            id='project-code-expiration'
            name='expiration'
            label='Expiration date'
            type='date'
            onChange={this.onValueChange.bind(this)}
            defaultValue={
              (grouping.expiration && toLocale(new Date(grouping.expiration), dateOptions)) ||
              ''
            }
            InputLabelProps={{ shrink: true }}
            className={classes.expiration}
          />
          <UrlifeTextField
            id='coupon-setting-title'
            name='couponTitle'
            label='Coupon title'
            autoFocus
            onChange={this.onValueChange.bind(this)}
            placeholder={'Necessary to proceed'}
            defaultValue={couponTitle || ''}
          />
          <div
            className={classes.errorBox}
            style={error ? { opacity: 1 } : {}}
          >
            {error}
          </div>
          <UrlifeButton
            disabled={!formValid}
            className={classes.submitButton}
            onClick={this.onSubmit.bind(this)}
          >
            {this.props.grouping ? 'Update' : 'Add'}
          </UrlifeButton>
        </form>}
      </div>
    )
  }
}

ProjectCodeGroupingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  grouping: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}

ProjectCodeGroupingForm.defaultProps = {
  grouping: null
}

export default withStyles(styles)(ProjectCodeGroupingForm)
