import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import PriceBlock from './PriceBlock'
import {
  commonStyles
} from '../../../../helpers'
import {
  fetchUserCoupons,
  getPackageSku
} from '../../../../actions'

/* eslint-disable sort-keys */
const styles = {
  text: {
    maxWidth: 688,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    margin: '0px auto 40px auto',
    textAlign: 'center'
  },
  packageRow: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 -15px',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    ...commonStyles.media(767, {
      flexDirection: 'column',
      margin: '-15px 0 0'
    })
  },
  packageCol: {
    width: '33.333333%',
    padding: '0 8px',
    '&._skinned': {
      margin: 'auto'
    },
    ...commonStyles.media(960, {
      width: 500,
      maxWidth: '100%',
      margin: '15px auto !important',
      padding: 0
    }),
    ...commonStyles.media(600, {
      width: '90%',
      maxWidth: '100%',
      margin: '15px auto !important',
      padding: 0
    })
  }
}
/* eslint-enable sort-keys */

class PackageList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      coupons: [],
      descriptionHeights: [],
      skinnedPackageSku: null
    }
    this.skinnedPackageSku = false
  }

  async loadUserCoupons () {
    const { userId, fetchUserCouponsFunction, priceBlock } = this.props
    if (priceBlock === 'full') {
      try {
        const response = await fetchUserCouponsFunction(userId)
        this.setState({ coupons: response.data })
      } catch (e) {}
    }
  }

  async setSkinnedSku () {
    const {
      projectSku,
      getPackageSkuFunction
    } = this.props
    if (projectSku && !this.skinnedPackageSku) {
      try {
        const data = await getPackageSkuFunction(projectSku.packageSku)
        this.skinnedPackageSku = true
        this.setState({ skinnedPackageSku: data })
      } catch (e) {}
    }
  }

  componentDidMount () {
    const { skinData } = this.props
    const skinned = !!skinData
    this.loadUserCoupons()
    if (skinned) {
      this.setSkinnedSku()
    }
  }

  onDescriptionHeightChange (packId, newHeight) {
    const { descriptionHeights } = this.state
    if (newHeight !== descriptionHeights[packId]) {
      descriptionHeights[packId] = newHeight
      this.setState({ descriptionHeights: descriptionHeights })
    }
  }

  render () {
    const {
      packages,
      classes,
      nextStep,
      priceBlock,
      border,
      fontSize,
      skinData,
      packageOverrides,
      updateProjectSku,
      projectId,
      projectSku
    } = this.props
    const {
      descriptionHeights,
      coupons,
      skinnedPackageSku
    } = this.state
    const skinned = !!skinData
    const skinnedText = skinned && skinData.giftText
    !this.skinnedPackageSku && skinned && this.setSkinnedSku()
    let descriptionMinHeight = 0
    packages && packages.forEach(pack => {
      if (descriptionMinHeight < descriptionHeights[pack.id]) {
        descriptionMinHeight = descriptionHeights[pack.id]
      }
    })
    const filteredPackages = packages.filter(pack => !pack.skus.filter(sku => !!sku.comingSoon).length)
    return (
      <React.Fragment>
        {skinnedText && <p className={classes.text}>{skinnedText}</p>}
        {filteredPackages && <div className={classes.packageRow}>
          {(!skinned || (skinned && this.skinnedPackageSku && skinnedPackageSku)) && filteredPackages.map(pack => {
            if (!skinned || (skinned && skinnedPackageSku.packageId === pack.id)) {
              return (
                <div
                  key={pack.id}
                  className={classNames(classes.packageCol, { _skinned: skinned })}
                >
                  <PriceBlock
                    pack={skinned ? { ...pack, skus: [skinnedPackageSku] } : pack}
                    nextStep={nextStep}
                    descriptionMinHeight={descriptionMinHeight}
                    onDescriptionHeightChange={this.onDescriptionHeightChange.bind(this)}
                    coupons={coupons}
                    priceBlock={priceBlock}
                    border={border}
                    fontSize={fontSize}
                    packageOverride={packageOverrides && packageOverrides.find(override => override.type === pack.type)}
                    updateProjectSku={updateProjectSku}
                    projectId={projectId}
                    packageSkuId={projectSku ? projectSku.packageSku : null}
                  />
                </div>
              )
            } else return null
          })}
        </div>}
      </React.Fragment>
    )
  }
}

PackageList.propTypes = {
  border: PropTypes.oneOf(['none', 'purple']).isRequired,
  classes: PropTypes.object.isRequired,
  fetchUserCouponsFunction: PropTypes.func.isRequired,
  fontSize: PropTypes.oneOf(['small', 'medium']).isRequired,
  getPackageSkuFunction: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  packageOverrides: PropTypes.array,
  packages: PropTypes.arrayOf(Object),
  priceBlock: PropTypes.oneOf(['none', 'full', 'price-only']).isRequired,
  projectId: PropTypes.string,
  projectSku: PropTypes.object,
  skinData: PropTypes.object,
  updateProjectSku: PropTypes.func,
  userId: PropTypes.string.isRequired
}

PackageList.defaultProps = {
  border: 'none',
  fontSize: 'medium',
  packages: [],
  priceBlock: 'full'
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages,
    skinData: state.skinning.skinData,
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUserCouponsFunction: function (userId) {
      return dispatch(fetchUserCoupons(userId, { state: ['unredeemed', 'unexpired'] }))
    },
    getPackageSkuFunction: function (id) {
      return dispatch(getPackageSku(id))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PackageList))
