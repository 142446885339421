import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

/* eslint-disable sort-keys */
const styles = {
  buttonBar: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '5px 0',
    borderTop: '1px solid #bebebe',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f6f6f6'
  }
}
/* eslint-enable sort-keys */

class StickyBottomBar extends React.Component {
  render () {
    const { classes, children } = this.props
    return (
      <div className={classes.buttonBar}>
        <div>
          {children}
        </div>
      </div>
    )
  }
}

StickyBottomBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(StickyBottomBar)
