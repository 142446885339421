import ApiService from '../services/ApiService'
import { api } from '../constants'

export {
  createNotification,
  deleteNotification,
  getAllNotifications,
  getNotification,
  updateNotification
}

function getNotification (id) {
  return dispatch => {
    return new ApiService(api.ONE_NOTIFICATION,
      { notificationId: id }, {}, true, dispatch)
      .get()
  }
}

function getAllNotifications (params = {}) {
  return dispatch => {
    return new ApiService(api.NOTIFICATIONS,
      {}, params, true, dispatch)
      .get()
  }
}

function createNotification (data) {
  return dispatch => {
    return new ApiService(api.NOTIFICATIONS,
      {}, {}, true, dispatch)
      .post(data)
  }
}

function updateNotification (id, data) {
  return dispatch => {
    return new ApiService(api.ONE_NOTIFICATION,
      { notificationId: id }, {}, true, dispatch)
      .put(data)
  }
}

function deleteNotification (id) {
  return dispatch => {
    return new ApiService(api.ONE_NOTIFICATION,
      { notificationId: id }, {}, true, dispatch)
      .delete()
  }
}
