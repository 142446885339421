import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

/* eslint-disable sort-keys */
const styles = {
  navlink: {
    position: 'relative',
    color: '#a2a2a2',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.64,
    lineHeight: '17px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    paddingBottom: 9,
    margin: 8,
    '&._active': {
      color: '#393939'
    },
    '&:hover:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    },
    '&._active:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    }
  }
}
/* eslint-enable sort-keys */

class UrlifeNavLink extends React.Component {
  render () {
    const { classes, label, ...otherProps } = this.props
    return (
      <NavLink
        activeClassName={classNames(classes.navlink, '_active')}
        className={classes.navlink}
        {...otherProps}>
        {label}
      </NavLink>
    )
  }
}

UrlifeNavLink.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
}

export default withStyles(styles)(UrlifeNavLink)
