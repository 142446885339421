import ApiService from '../services/ApiService'
import { api } from '../constants'
import { updateSystemFile, uploadFile } from './index'

export {
  fetchTips,
  fetchSingleTip,
  createTip,
  updateTip,
  deleteTip,
  createTipFile
}

function fetchTips (params = {}) {
  return dispatch => {
    return new ApiService(api.TIPS, {}, params, false, dispatch)
      .get()
  }
}

function fetchSingleTip (id) {
  return dispatch => {
    return new ApiService(api.ONE_TIP, { tipId: id }, {}, false, dispatch)
      .get()
  }
}

function createTip (data) {
  return dispatch => {
    return new ApiService(api.TIPS, {}, {}, true, dispatch)
      .post(data)
  }
}

function updateTip (id, data) {
  return dispatch => {
    return new ApiService(api.ONE_TIP, { tipId: id }, {}, true, dispatch)
      .put(data)
  }
}

function deleteTip (id) {
  return dispatch => {
    return new ApiService(api.ONE_TIP, { tipId: id }, {}, true, dispatch)
      .delete()
  }
}

function createTipFile (fileType, file, fileObject) {
  return dispatch => {
    return new ApiService(api.SYSTEM_FILES, { fileType }, {}, true, dispatch)
      .post(file)
      .then(
        response => {
          const responseFileData = response.data[0]
          return dispatch(uploadFile(
            null,
            fileObject,
            responseFileData.id,
            responseFileData.fileExt,
            'tip',
            'system',
            'system/' + responseFileData.id + '.' + responseFileData.fileExt,
            (cognitoInfo) => {
              return dispatch(updateSystemFile(
                { id: responseFileData.id, status: 3 },
                cognitoInfo.IdentityId)
              )
            }
          ))
        }
      )
  }
}
