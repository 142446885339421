import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TopMenuHome (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <g><g><g><g><path fill="#fff" d="M13.89 22.91v-7.238H9.65v7.238zm6.88-7.077v7.757c0 .379-.306.686-.682.686H3.45a.684.684 0 0 1-.682-.686V12.119c0-.38.305-.687.682-.687.376 0 .682.307.682.687v10.784h4.152v-7.92c0-.38.306-.688.682-.688h5.604c.377 0 .682.308.682.687v7.92h4.153v-7.07c0-.379.305-.686.682-.686.376 0 .682.307.682.687zm0-3.716v1.33a.683.683 0 0 1-.682.685.683.683 0 0 1-.682-.685v-1.33c0-.378.305-.685.682-.685.376 0 .682.307.682.685zm2.769-1.461v1.273c0 .38-.306.688-.682.688a.684.684 0 0 1-.682-.688v-.964L11.699 1.608 1.364 10.962v.967c0 .38-.306.687-.682.687A.684.684 0 0 1 0 11.93v-1.273a.69.69 0 0 1 .226-.512L11.241.176a.678.678 0 0 1 .908-.003l11.16 9.968a.69.69 0 0 1 .23.515z"/></g></g></g></g>
    </SvgIcon>
  )
}
