import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from './UrlifeButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = {
  warningText: {
    color: '#000',
    fontWeight: 600
  }
}

export class UploadMsg extends React.Component {
  close () {
    const { onClose } = this.props
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  render () {
    const { title, specText, warningText, classes, children } = this.props
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.close.bind(this)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
          <DialogContent>
            { specText }

            {warningText &&
            <DialogContentText className={classes.warningText}>
              {warningText}
            </DialogContentText>
            }
            {children}
          </DialogContent>
          <DialogActions>
            <UrlifeButton onClick={ this.close.bind(this) }>OK</UrlifeButton>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

UploadMsg.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  specText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired,
  warningText: PropTypes.string
}

export default withStyles(styles)(UploadMsg)
