import { CastIcon, DownloadIcon, IconInfo, UploadIcon } from './SVG/index'
import { commonStyles, getFileDimensions } from '../../helpers'
import {
  createCuratedFiles,
  createLog,
  fileProcessDone,
  getConnectionState,
  getFileBatchData,
  initialization,
  processFiles,
  updateFile,
  updateLocalFile,
  uploadProjectFiles
} from '../../actions'
import { DEBUG_LEVEL, FILE_STATUSES } from '../../constants'
import { fileProgressStatus, fileProgressType } from '../../reducers/fileProgress'

import classNames from 'classnames'
import { connect } from 'react-redux'
import { createFileFingerprint } from '../../helpers/FileFingerprinting'
import PropTypes from 'prop-types'
import React from 'react'
import shortid from 'shortid'
import UploadMsg from './UploadMsg'
import { withStyles } from '@material-ui/core/styles'

/* eslint-disable sort-keys */
const styles = {
  input: {
    marginLeft: 0,
    marginRight: 0,
    width: 371
  },
  createMovieUploadBlock: {
    width: '100%',
    height: 'calc(100vh - 258px)',
    minHeight: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'relative',
    transition: 'background-color 0.1s ease-in-out',
    ...commonStyles.media(575, {
      height: 350
    }),
    ...commonStyles.media(410, {
      height: 367
    }),
    ...commonStyles.media(396, {
      height: 387
    }),
    '&._dragover': {
      backgroundColor: '#e7e7e7',
      border: 'dashed 2px #d1d1d1',
      cursor: 'copy',
      transition: 'background-color 0.1s ease-in-out'
    }
  },
  createMovieUploadBlockContent: {
    paddingBottom: 20,
    pointerEvents: 'none'
  },
  createMovieUploadTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#323232',
    textAlign: 'center',
    margin: '30px 0 15px 0',
    ...commonStyles.media(575, {
      fontSize: '14px',
      marginBottom: 10
    })
  },
  createMovieUploadText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#323232',
    opacity: 0.6,
    textAlign: 'center',
    lineHeight: '24px',
    maxWidth: 514,
    margin: '0 0 64px 0',
    ...commonStyles.media(575, {
      fontSize: '12px',
      lineHeight: '20px',
      marginBottom: 40
    })
  },
  createMovieUploadBtn: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '12px 30px',
    margin: 'auto',
    width: 210,
    height: 46,
    pointerEvents: 'auto',
    display: 'block',
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      color: '#01b7d7',
      background: 'none'
    }
  },
  createMovieUploadBlockMore: {
    width: '100%',
    height: '75px',
    backgroundColor: '#f7f7f7',
    border: 'dashed 1px #e1e1e1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '81px',
    padding: '0 20px 0 40px',
    position: 'relative',
    ...commonStyles.media(767, {
      height: 'auto',
      padding: 30,
      flexDirection: 'column'
    }),
    ...commonStyles.media(575, {
      marginBottom: 30
    })
  },
  createMovieUploadBlockMoreLabel: {
    display: 'inline-flex',
    ...commonStyles.media(767, {
      marginBottom: 20
    })
  },
  createMovieUploadBlockMoreIcon: {
    '& path': {
      fill: '#5334b6'
    },
    marginRight: 35,
    ...commonStyles.media(767, {
      marginRight: 20
    })
  },
  createMovieUploadBlockMoreText: {
    textDecoration: 'underline',
    fontSize: '17px',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.9px',
    textAlign: 'center',
    color: '#5334b6',
    cursor: 'pointer'
  },
  createMovieUploadBlockMoreSubtext: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#afafaf'
  },
  createMovieUploadBlockMoreIconInfo: {
    fontSize: 15,
    marginRight: 6
  },
  error: {
    color: '#f13737'
  },
  createMovieUploadLinkLabel: {
    fontSize: 'inherit',
    display: 'inline-flex'
  },
  createMovieUploadLinkLabelText: {
    color: '#5334b6',
    cursor: 'pointer',
    '&._curated': {
      color: 'inherit'
    }
  },
  addFilesIcon: {
    width: 143,
    height: 118,
    display: 'block',
    margin: 'auto',
    '&._tile': {
      width: '60%',
      height: '60%'
    },
    '&._button': {
      pointerEvents: 'auto',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  createMovieTileBlock: {
    width: '100%',
    paddingTop: '25%',
    paddingBottom: '25%',
    display: 'block',
    borderRadius: '4px',
    border: 'dashed 2px #01b7d7',
    cursor: 'pointer'
  },
  iconHolder: {
    cursor: 'pointer',
    display: 'inline-flex',
    marginLeft: 20,
    marginRight: 20
  },
  uploadIcon: {
    width: 22,
    height: 22,
    color: '#333',
    transform: 'scale(1, -1)',
    cursor: 'pointer'
  }
}
/* eslint-enable sort-keys */

const projectFileTypes = [
  'audio/aac',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/m4v',
  'video/quicktime',
  'image/heic',
  'image/heif',
  '.heic',
  '.heif'
]

const curatedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  '.heic',
  '.heif'
]

class DropZone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dragOverCount: 0,
      duplicatedFiles: [],
      duplicatedFilesMsg: '',
      errorPopup: false,
      failedToOpenFiles: [],
      fileInputKey: 1,
      newFilesCount: 0,
      wrongFilesByType: []
    }
    this.uploadQueue = []
    this.processedFileFingerprints = []
    this.logMessageId = shortid.generate()
  }

  async fileUploadInit (fn, fnParams) {
    const {
      onInit,
      getConnectionStateFunction
    } = this.props
    let backend = getConnectionStateFunction()
    let retrying = true
    do {
      if (backend) {
        try {
          const data = await fn(...fnParams)
          onInit && onInit(data)
          retrying = false
          this.log({
            type: 'FILES UPLOAD STARTED'
          })
        } catch (e) {
          this.log({
            data: {
              error: e
            },
            type: 'FILES CREATION FAILED'
          })
        }
      }
      if (retrying) {
        await new Promise(resolve => setTimeout(resolve, 5000))
        backend = getConnectionStateFunction()
      }
    } while (retrying)
  }

  async fileUploadBatch (files, first) {
    const {
      filesServer,
      projectId,
      projectSkuId,
      uploadProjectFilesFunction,
      initializationFunction,
      createCuratedFilesFunction,
      isCurated,
      fileProgress,
      updateFileFunction,
      updateLocalFileFunction,
      fileProcessDoneFunction,
      getFileBatchDataFunction
    } = this.props
    const newWrongFilesByType = []
    const newDuplicatedFiles = []
    const newFailedToOpenFiles = []
    const reuploadFiles = []
    const newFiles = []
    const fileInfo = []
    const fileTypes = isCurated ? curatedFileTypes : projectFileTypes
    const iOSSafari = !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !!navigator.userAgent.match(/AppleWebKit/)
    const fileBatch = getFileBatchDataFunction()
    const projectFileBatch = fileBatch[projectId]

    if (DEBUG_LEVEL === 'upload') {
      /* eslint-disable-next-line no-console */
      console.debug('START FILTERING FILES: ' +
        (Date.now() - window.sessionStorage.getItem('debug_time_start')))
    }
    // filter invalid files and get files info
    for (let i = 0; i < files.length; i++) {
      let fileName = files[i].name
      // Make file names unique if taken with camera on iOS device
      if (iOSSafari) {
        if (fileName.startsWith('image.')) {
          fileName = fileName.replace('image', 'image-' + files[i].lastModified)
        }
      }

      // Check that file size is valid. iOS sometimes sets file size to 0 when something goes wrong
      if (files[i].size === 0) {
        newFailedToOpenFiles.push(fileName)
        continue
      }

      if (!files[i].type && /.*\.(HEIC|heic|HEIF|heif)$/.test(fileName)) {
        if (/.*\.(HEIC|heic)$/.test(fileName)) {
          files[i].mimeType = 'image/heic'
        } else if (/.*\.(HEIF|heif)$/.test(fileName)) {
          files[i].mimeType = 'image/heif'
        }
      }
      // validation by type
      if (!fileTypes.includes(files[i].type) && !fileTypes.includes(files[i].mimeType)) {
        newWrongFilesByType.push(fileName)
        continue
      }
      this.log({
        type: 'BEFORE FINGERPRINT CREATION'
      })
      const fingerprint = await createFileFingerprint(files[i])
      this.log({
        data: {
          fingerprint
        },
        type: 'AFTER FINGERPRINT CREATION'
      })
      // check for duplicates among selected files
      if (fingerprint && this.processedFileFingerprints.includes(fingerprint)) {
        newDuplicatedFiles.push(fileName)
        continue
      }
      // check for duplicates among existing files
      if (filesServer && filesServer.length && !isCurated) {
        const dupidx = fingerprint ? filesServer.findIndex(file => file.fingerprint === fingerprint) : -1
        if (dupidx !== -1) {
          const duplicate = filesServer[dupidx]
          let needsReupload = duplicate.status === FILE_STATUSES.UPLOAD_FAILED

          if (duplicate.status === FILE_STATUSES.CLIENT_UPLOADING) {
            needsReupload = !fileProgress || !fileProgress.some(file => {
              return file.id === duplicate.id &&
                file.progressType === fileProgressType.upload &&
                file.status !== fileProgressStatus.failed
            })
          }

          if (needsReupload) {
            const fileExt = duplicate.file_ext || duplicate.fileExt
            reuploadFiles.push({
              ...duplicate,
              fileExt,
              fileObject: files[i]
            })
            updateFileFunction({ id: duplicate.id, status: FILE_STATUSES.CLIENT_UPLOADING }, projectId)
            updateLocalFileFunction({
              id: duplicate.id,
              src: window.URL.createObjectURL(files[i]),
              status: FILE_STATUSES.CLIENT_UPLOADING
            })
            continue
          }

          newDuplicatedFiles.push(fileName)
          continue
        }
      }

      newFiles.push(files[i])
      fileInfo.push(await this.getFileInfo(files[i], fileName, fingerprint))
      this.processedFileFingerprints.push(fingerprint)
    }

    const uploadFilesCount = fileInfo.length + reuploadFiles.length
    const wrongFilesByType = [...this.state.wrongFilesByType, ...newWrongFilesByType]
    const duplicatedFiles = [...this.state.duplicatedFiles, ...newDuplicatedFiles]
    const failedToOpenFiles = [...this.state.failedToOpenFiles, ...newFailedToOpenFiles]
    const newFilesCount = this.state.newFilesCount + uploadFilesCount
    const totalFilesSelected = newFilesCount + duplicatedFiles.length
    let duplicatedFilesMsg = ''
    if (duplicatedFiles.length > 0 && projectFileBatch) {
      duplicatedFilesMsg = totalFilesSelected + ' new selected file' +
        (totalFilesSelected === 1 ? '' : 's') +
        ', ' + duplicatedFiles.length +
        ' file' + (duplicatedFiles.length === 1 ? '' : 's') +
        ' skipped because ' +
        (duplicatedFiles.length === 1 ? 'it was a' : 'they were') +
        ' duplicate' + (duplicatedFiles.length === 1 ? '' : 's')
    }

    this.log({
      data: {
        duplicatedFiles,
        failedToOpenFiles,
        newFilesCount,
        wrongFilesByType
      },
      type: 'AFTER FILES FILTERED'
    })

    this.setState({
      duplicatedFiles,
      duplicatedFilesMsg,
      errorPopup: wrongFilesByType.length > 0 || duplicatedFiles.length > 0 || failedToOpenFiles.length > 0,
      failedToOpenFiles,
      newFilesCount,
      wrongFilesByType
    })

    if (DEBUG_LEVEL === 'upload') {
      /* eslint-disable-next-line no-console */
      console.debug('FILES QUEUED FOR UPLOAD: ' +
        (Date.now() - window.sessionStorage.getItem('debug_time_start')))
    }

    if (DEBUG_LEVEL === 'fingerprint') {
      /* eslint-disable-next-line no-console */
      console.debug('CHECKING FOR DUPLICATE FINGERPRINTS')
      let duplicateCount = 0
      fileInfo.forEach((fInfo, index) => {
        for (let i = index + 1; i < fileInfo.length; i++) {
          const fInfo2 = fileInfo[i]
          if (fInfo.fingerprint === fInfo2.fingerprint) {
            /* eslint-disable-next-line no-console */
            console.debug(fInfo.originalName + ' had the same fingerprint as ' + fInfo2.originalName)
            duplicateCount++
          }
        }
      })
      /* eslint-disable-next-line no-console */
      console.debug('FOUND ' + duplicateCount + ' DUPLICATE FINGERPRINTS')
    }

    // start upload
    if (uploadFilesCount > 0) {
      const rawFiles = fileInfo.map((file, index) => ({ index, object: newFiles[index] }))
      const fileData = { files: fileInfo, type: 'file' }
      if (isCurated) {
        if (first) {
          await this.fileUploadInit(
            createCuratedFilesFunction,
            [
              fileData,
              projectId,
              projectSkuId,
              rawFiles
            ]
          )
        } else {
          this.fileUploadInit(
            createCuratedFilesFunction,
            [
              fileData,
              projectId,
              projectSkuId,
              rawFiles
            ]
          )
        }
      } else {
        if (fileInfo.length > 0) {
          if (first) {
            await this.fileUploadInit(
              initializationFunction,
              [
                fileData,
                projectId,
                rawFiles
              ]
            )
          } else {
            this.fileUploadInit(
              initializationFunction,
              [
                fileData,
                projectId,
                rawFiles
              ]
            )
          }
        }
        if (reuploadFiles.length > 0) {
          if (first) {
            await this.fileUploadInit(
              uploadProjectFilesFunction,
              [
                reuploadFiles,
                projectId
              ]
            )
          } else {
            this.fileUploadInit(
              uploadProjectFilesFunction,
              [
                reuploadFiles,
                projectId
              ]
            )
          }
        }
      }
    }
    fileProcessDoneFunction(projectId, files.length)
  }

  async getFileInfo (file, fileName = null, fingerprint = null) {
    const fileInfo = {
      fingerprint: fingerprint,
      lastModified: file.lastModified,
      mimeType: file.type || file.mimeType,
      originalName: fileName || file.name,
      uploadSource: 'web'
    }
    const metadata = await getFileDimensions(file, (type, message) => {
      this.log({
        data: {
          message,
          originalName: fileInfo.originalName
        },
        type
      })
    })
    return { ...fileInfo, ...metadata }
  }

  async processUploadQueue () {
    let first = true
    while (this.uploadQueue.length > 0) {
      const files = this.uploadQueue.shift()
      this.log({
        data: {
          filesLength: files.length
        },
        type: 'FILE UPLOAD INIT BATCH'
      })
      await this.fileUploadBatch(files, first)
      first = false
    }
    this.processedFileFingerprints = []
    this.log({
      type: 'FILE UPLOAD INIT BATCH NO MORE FILES'
    })
  }

  queueFiles (files) {
    const filesArray = [...files]
    const initialQueueLength = this.uploadQueue.length
    this.log({
      data: {
        filesArrayLength: filesArray ? filesArray.length : 0
      },
      type: 'QUEUE FILES FUNCTION'
    })
    for (let i = 0; i < filesArray.length; i += 5) {
      const batch = filesArray.slice(i, i + 5)
      this.uploadQueue.push(batch)
    }
    if (initialQueueLength === 0) {
      this.processUploadQueue()
    }
  }

  async fileUpload (files) {
    const { projectId, processFilesFunction } = this.props
    this.log({
      data: {
        filesLength: files ? files.length : 0,
        projectId
      },
      type: 'FILE UPLOAD FUNCTION'
    })
    await processFilesFunction(projectId, files.length)
    this.queueFiles(files)
  }

  fileUploadDrop (e) {
    e.preventDefault()
    if (DEBUG_LEVEL === 'upload') {
      window.sessionStorage.setItem('debug_time_start', Date.now())
      /* eslint-disable-next-line no-console */
      console.debug('FILES DROPPED: ' + window.sessionStorage.getItem('debug_time_start'))
    }
    this.log({
      files: e.dataTransfer.files ? e.dataTransfer.files.length : 0,
      type: 'FILE DROPPED'
    })
    if (!e.dataTransfer.files) {
      return false
    }
    this.fileUpload(e.dataTransfer.files)
  }

  onChangeFileInput (e) {
    e.preventDefault()
    if (DEBUG_LEVEL === 'upload') {
      window.sessionStorage.setItem('debug_time_start', Date.now())
      /* eslint-disable-next-line no-console */
      console.debug('FILE INPUT CLOSED: ' + window.sessionStorage.getItem('debug_time_start'))
    }
    const files = e.target.files
    const fileData = []
    if (files) {
      this.fileUpload(files)
      for (let i = 0; i < files.length; i++) {
        fileData.push({
          name: files[i].name,
          size: files[i].size,
          type: files[i].type
        })
      }
    }
    this.log({
      data: {
        fileData,
        filesLength: e.target.files ? e.target.files.length : 0,
        target: e.target.files.fileList
      },
      type: 'FILE INPUT CLOSED'
    })
    this.setState(prevState => {
      return { fileInputKey: prevState.fileInputKey + 1 }
    })
  }

  clickedFileInput () {
    this.log({
      type: 'FILE INPUT OPENED'
    })
  }

  closeErrorPopup () {
    this.setState({
      duplicatedFiles: [],
      duplicatedFilesMsg: '',
      errorPopup: false,
      failedToOpenFiles: [],
      newFilesCount: 0,
      wrongFilesByType: []
    })
  }

  onDragEnter (e) {
    e.preventDefault()
    this.setState(prevState => {
      return { dragOverCount: prevState.dragOverCount + 1 }
    })
  }

  onDragLeave (e) {
    e.preventDefault()
    this.setState(prevState => {
      return { dragOverCount: prevState.dragOverCount - 1 }
    })
  }

  log (message) {
    let memoryInfo = null
    if (global.performance && global.performance.memory) {
      const mem = global.performance.memory
      memoryInfo = {
        jsHeapSizeLimit: mem.jsHeapSizeLimit,
        totalJSHeapSize: mem.totalJSHeapSize,
        usedJSHeapSize: mem.usedJSHeapSize
      }
    }
    this.props.createLogFunction(this.logMessageId, { ...message, memoryInfo })
  }

  componentDidMount () {
    if (this.uploadBlock) {
      this.uploadBlock.addEventListener('dragover', function (e) {
        e.preventDefault()
      }, false)
      this.uploadBlock.addEventListener('drop', function (e) {
        e.preventDefault()
      }, false)
      document.getElementById('dragbox').addEventListener('drop', this.fileUploadDrop.bind(this))
      document.getElementById('dragbox').addEventListener('dragenter', this.onDragEnter.bind(this))
      document.getElementById('dragbox').addEventListener('dragleave', this.onDragLeave.bind(this))
    }
  }

  componentWillUnmount () {
    if (this.uploadBlock) {
      document.getElementById('dragbox').removeEventListener('drop', this.fileUploadDrop.bind(this))
      document.getElementById('dragbox').removeEventListener('dragenter', this.onDragEnter.bind(this))
      document.getElementById('dragbox').removeEventListener('dragleave', this.onDragLeave.bind(this))
    }
  }

  render () {
    const { classes, view, isCurated, projectId, fileBatch } = this.props
    const {
      wrongFilesByType,
      duplicatedFiles,
      duplicatedFilesMsg,
      failedToOpenFiles,
      errorPopup,
      fileInputKey,
      dragOverCount
    } = this.state
    const fileTypes = isCurated ? curatedFileTypes : projectFileTypes
    const projectFileBatch = fileBatch[projectId]
    const isProcessing = projectFileBatch && projectFileBatch.totalCount !== 0
    let errorTitle = ''
    if (failedToOpenFiles.length > 0) {
      errorTitle = 'Error'
    } else if (wrongFilesByType.length > 0) {
      errorTitle = 'Warning'
    }

    return (
      <React.Fragment>
        {errorPopup && !isProcessing &&
        <UploadMsg
          title={errorTitle}
          specText={ <React.Fragment>
            {failedToOpenFiles.length > 0 &&
              <span className={classes.error}>
                Failed to open the files listed below.
                Please try again and if they continue to fail try to upload from another browser and/or device.
                <br />
                {failedToOpenFiles.join(', ')}
              </span>}

            {wrongFilesByType.length > 0 &&
            <span className={classes.error}>Wrong type of files: <br /> {wrongFilesByType.join(', ')}</span>}

            {duplicatedFiles.length > 0 &&
            <span>{duplicatedFilesMsg}</span>}
          </React.Fragment>
          }
          onClose={this.closeErrorPopup.bind(this)} />
        }

        {
          view === 'big' && <div id='dragbox' ref={node => {
            this.uploadBlock = node
          }} className={classNames(classes.createMovieUploadBlock, { _dragover: dragOverCount > 0 })}>
            <div className={classes.createMovieUploadBlockContent}>
              <label htmlFor='fileUpload'>
                <CastIcon className={classNames(classes.addFilesIcon, '_button')} />
              </label>
              <p className={classes.createMovieUploadTitle}>Add as many photo, video and audio files as you want</p>
              <p className={classes.createMovieUploadText}>
                Don’t worry about sorting through your content.
                We’ll happily select, edit and enhance what’s best for your project</p>
              <label htmlFor='fileUpload'>
                <div className={classes.createMovieUploadBtn}>
                  Select files
                </div>
              </label>
              <input
                accept={fileTypes.join(',')}
                onChange={this.onChangeFileInput.bind(this)}
                onClick={this.clickedFileInput.bind(this)}
                style={{ display: 'none', pointerEvents: 'none' }}
                type='file'
                id='fileUpload'
                multiple
                key={fileInputKey}/>
            </div>
          </div>
        }
        { view === 'small' &&
          <div
            id='dragbox'
            ref={ node => { this.uploadBlock = node }}
            className={classes.createMovieUploadBlockMore}
          >
            <label htmlFor='fileUpload' className={classes.createMovieUploadBlockMoreLabel}>
              <UploadIcon className={classes.createMovieUploadBlockMoreIcon}/>
              <span className={classes.createMovieUploadBlockMoreText}>Choose more files</span>
            </label>
            <input
              accept={fileTypes.join(',')}
              onChange={this.onChangeFileInput.bind(this)}
              onClick={this.clickedFileInput.bind(this)}
              style={{ display: 'none', pointerEvents: 'none' }}
              type='file'
              id='fileUpload'
              multiple
              key={fileInputKey}/>
            <div className={classes.createMovieUploadBlockMoreSubtext}>
              <IconInfo className={classes.createMovieUploadBlockMoreIconInfo} />
              <span>you can upload videos or photos</span>
            </div>
          </div>
        }
        {
          view === 'link' &&
          <label
            htmlFor={isCurated ? 'fileUploadCuratedLink' : 'fileUploadLink'}
            className={classes.createMovieUploadLinkLabel}>
            <span className={classNames(classes.createMovieUploadLinkLabelText, { _curated: isCurated })}>
              {isCurated ? 'Upload curated files' : 'Upload more'}
            </span>
            <input
              accept={fileTypes.join(',')}
              onChange={this.onChangeFileInput.bind(this)}
              onClick={this.clickedFileInput.bind(this)}
              style={{ display: 'none', pointerEvents: 'none' }}
              type='file'
              id={isCurated ? 'fileUploadCuratedLink' : 'fileUploadLink'}
              multiple
              key={fileInputKey}/>
          </label>
        }
        {
          view === 'tile' &&
          <div className={classes.createMovieTileBlock}>
            <label htmlFor={isCurated ? 'fileUploadCuratedTile' : 'fileUploadTile'}>
              <CastIcon className={classNames(classes.addFilesIcon, '_tile', '_button')} />
              <input
                accept={fileTypes.join(',')}
                onChange={this.onChangeFileInput.bind(this)}
                onClick={this.clickedFileInput.bind(this)}
                style={{ display: 'none', pointerEvents: 'none' }}
                type='file'
                id={isCurated ? 'fileUploadCuratedTile' : 'fileUploadTile'}
                multiple
                key={fileInputKey} />
            </label>
          </div>
        }
        {
          view === 'icon' &&
          <div
            className={classes.iconHolder}
            data-testid='dz-upload-icon'
          >
            <label htmlFor={isCurated ? 'fileUploadCuratedIcon' : 'fileUploadIcon'}>
              <DownloadIcon className={classes.uploadIcon} />
              <input
                accept={fileTypes.join(',')}
                onChange={this.onChangeFileInput.bind(this)}
                onClick={this.clickedFileInput.bind(this)}
                style={{ display: 'none', pointerEvents: 'none' }}
                type='file'
                id={isCurated ? 'fileUploadCuratedIcon' : 'fileUploadIcon'}
                multiple
                key={fileInputKey} />
            </label>
          </div>
        }
      </React.Fragment>
    )
  }
}

DropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  createCuratedFilesFunction: PropTypes.func.isRequired,
  createLogFunction: PropTypes.func.isRequired,
  fileBatch: PropTypes.object,
  fileProcessDoneFunction: PropTypes.func.isRequired,
  fileProgress: PropTypes.array,
  filesServer: PropTypes.array,
  getConnectionStateFunction: PropTypes.func.isRequired,
  getFileBatchDataFunction: PropTypes.func.isRequired,
  initializationFunction: PropTypes.func.isRequired,
  isCurated: PropTypes.bool,
  onInit: PropTypes.func,
  processFilesFunction: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectSkuId: PropTypes.string,
  updateFileFunction: PropTypes.func.isRequired,
  updateLocalFileFunction: PropTypes.func.isRequired,
  uploadProjectFilesFunction: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['big', 'small', 'link', 'tile', 'icon', 'error-only']).isRequired
}

DropZone.defaultProps = {
  isCurated: false
}

function mapStateToProps (state) {
  return {
    fileBatch: state.fileBatch,
    fileProgress: Object.values(state.fileProgress)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createCuratedFilesFunction: function (files, projectId, projectSkuId, fileObjects) {
      return dispatch(createCuratedFiles(files, projectId, projectSkuId, fileObjects))
    },
    createLogFunction: function (messageId, message) {
      return dispatch(createLog(messageId, message))
    },
    fileProcessDoneFunction: function (projectId, count) {
      dispatch(fileProcessDone(projectId, count))
    },
    getConnectionStateFunction: function () {
      return dispatch(getConnectionState())
    },
    getFileBatchDataFunction: function () {
      return dispatch(getFileBatchData())
    },
    initializationFunction: function (files, projectId, fileObjects) {
      return dispatch(initialization(files, projectId, fileObjects))
    },
    processFilesFunction: function (projectId, count) {
      return dispatch(processFiles(projectId, count))
    },
    updateFileFunction: function (file, projectId) {
      return dispatch(updateFile(file, projectId))
    },
    updateLocalFileFunction: function (file) {
      dispatch(updateLocalFile(file))
    },
    uploadProjectFilesFunction: function (uploads, projectId) {
      return dispatch(uploadProjectFiles(uploads, projectId))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DropZone))
