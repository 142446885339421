import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function InstagramIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19">
      <defs><linearGradient id="vzkla" x1="0" x2="19.13" y1=".07" y2="19.09" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1"/><stop offset="1" stopColor="#bd6ad3"/></linearGradient></defs><g><g><path fill="url(#vzkla)" d="M17.343 13.402c.046-1.009.046-1.285.046-3.855 0-2.57-.013-2.853-.059-3.855a5.223 5.223 0 0 0-.33-1.76 3.16 3.16 0 0 0-1.818-1.806 5.327 5.327 0 0 0-1.773-.328c-1.03-.057-1.295-.057-3.883-.057-2.589 0-2.873.012-3.883.057a5.327 5.327 0 0 0-1.773.328 3.154 3.154 0 0 0-1.825 1.806 5.223 5.223 0 0 0-.33 1.78c-.058 1.02-.058 1.284-.058 3.854 0 2.57.013 2.853.058 3.855.008.602.12 1.197.33 1.761a3.154 3.154 0 0 0 1.825 1.805 5.33 5.33 0 0 0 1.773.328c1.029.058 1.294.058 3.883.058 2.588 0 2.873-.013 3.883-.058a5.33 5.33 0 0 0 1.773-.328A3.16 3.16 0 0 0 17 15.182c.213-.57.325-1.172.33-1.78h.013m1.728-7.716c.058 1.008.058 1.336.058 3.912 0 2.577-.013 2.898-.058 3.913a6.927 6.927 0 0 1-.446 2.307 4.875 4.875 0 0 1-2.796 2.776 7.07 7.07 0 0 1-2.323.443c-1.016.058-1.346.058-3.941.058-2.595 0-2.919-.013-3.941-.058a7.065 7.065 0 0 1-2.324-.475 4.875 4.875 0 0 1-2.795-2.776 6.927 6.927 0 0 1-.447-2.307C0 12.471 0 12.143 0 9.566 0 6.99.013 6.67.058 5.653a6.927 6.927 0 0 1 .447-2.306A4.875 4.875 0 0 1 3.3.57 7.067 7.067 0 0 1 5.624.128C6.64.07 6.97.07 9.564.07c2.596 0 2.92.013 3.942.058.794.016 1.58.166 2.323.443a4.875 4.875 0 0 1 2.796 2.776c.282.748.433 1.54.446 2.339m-4.407-2.32c.633 0 1.146.51 1.146 1.137 0 .628-.513 1.138-1.146 1.138-.632 0-1.145-.51-1.145-1.138 0-.628.513-1.137 1.145-1.137m-5.106 9.362c1.762 0 3.19-1.419 3.19-3.168 0-1.75-1.428-3.168-3.19-3.168s-3.19 1.419-3.19 3.168c0 1.75 1.428 3.168 3.19 3.168m0-8.045c2.713 0 4.912 2.184 4.912 4.877 0 2.693-2.2 4.877-4.912 4.877-2.712 0-4.912-2.184-4.912-4.877 0-2.693 2.2-4.877 4.912-4.877"/></g></g>
    </SvgIcon>
  )
}
