import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../../helpers'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import UrlifeListItem from './UrlifeListItem'

/* eslint-disable sort-keys */
const styles = {
  list: {
    paddingTop: 50,
    ...commonStyles.media(440, {
      paddingTop: 0
    })
  },
  divider: {
    margin: '18px 60px',
    height: 1,
    backgroundColor: '#cccccc',
    opacity: 0.6
  }
}
/* eslint-enable sort-keys */

class UrlifeList extends Component {
  render () {
    const { classes, items, handleClick } = this.props
    return (
      <List className={classes.list}>
        {items.map((item, idx) => {
          if (item.type === 'list-item') {
            return (
              <UrlifeListItem
                key={idx}
                text={item.text}
                action={item.action}
                icon={item.icon}
                badge={item.badge}
                disabled={item.disabled}
                mobileOnly={item.mobileOnly}
                handleClick={handleClick}
              />
            )
          } else if (item.type === 'divider') {
            return (
              <Divider className={classes.divider} key={idx} />
            )
          } else {
            return null
          }
        })}
      </List>
    )
  }
}

UrlifeList.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}

UrlifeList.defaultProps = {
  items: []
}

export default withStyles(styles)(UrlifeList)
