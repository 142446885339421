import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import VideoPlayerPreview from '../../common/VideoPlayerPreview'
import RegisterOrProjectButton from '../../common/RegisterOrProjectButton'
import { commonStyles } from '../../../helpers'
import {
  ArrowLeft,
  ArrowRight
} from '../../common/SVG'

/* eslint-disable sort-keys */
const styles = {
  image: {
    auto: 'auto',
    width: '100%',
    maxWidth: 640
  },
  tipContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 864,
    marginTop: 46,
    '&._hidden': {
      display: 'none'
    },
    ...commonStyles.media(575, {
      marginTop: 64,
      '&._hidden': {
        display: 'flex'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 64,
      '&._hidden': {
        display: 'flex'
      }
    })
  },
  title: {
    display: 'flex',
    fontSize: 26,
    lineHeight: '52px',
    fontWeight: 600,
    color: '#323232',
    '&._top': {
      display: 'none',
      marginBottom: 16
    },
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '40px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '32px',
      display: 'none',
      '&._top': {
        display: 'flex'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '32px',
      display: 'none',
      '&._top': {
        display: 'flex'
      }
    })
  },
  titleIndex: {
    minWidth: 36,
    ...commonStyles.media(767, {
      minWidth: 32
    }),
    ...commonStyles.media(575, {
      minWidth: 28
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      minWidth: 28
    })
  },
  videoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 42
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: 640
  },
  stepHeader: {
    fontSize: 16,
    lineHeight: '26px',
    height: 26,
    fontWeight: 500,
    color: '#323232',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2,
    ...commonStyles.media(575, {
      display: 'none'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'none'
    })
  },
  stepArrow: {
    width: 12,
    height: 20,
    color: '#01b7d7',
    cursor: 'pointer',
    marginLeft: 15
  },
  navButton: {
    fontSize: 12,
    fontWeight: 500,
    color: '#ffffff',
    backgroundColor: '#01b7d7',
    border: '1px solid #01b7d7',
    borderRadius: 1,
    cursor: 'pointer',
    width: 59,
    height: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    padding: '0 8px'
  },
  navButtonArrow: {
    width: 10,
    height: 16,
    color: '#ffffff'
  },
  description: {
    fontSize: 16,
    lineHeight: '26px',
    fontWeight: 500,
    color: '#323232',
    '& div': {
      marginLeft: 36,
      marginTop: -16
    },
    '& b': {
      fontWeight: 700
    },
    ...commonStyles.media(767, {
      '& div': {
        marginLeft: 32
      }
    }),
    ...commonStyles.media(575, {
      '& div': {
        marginLeft: 28,
        '&:first-child': {
          marginLeft: 0,
          marginTop: -32
        }
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      '& div': {
        marginLeft: 28,
        '&:first-child': {
          marginLeft: 0,
          marginTop: -32
        }
      }
    })
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 36,
    ...commonStyles.media(575, {
      justifyContent: 'flex-end'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      justifyContent: 'flex-end'
    })
  }
}
/* eslint-enable sort-keys */

class TopTenTip extends React.Component {
  render () {
    const { classes, tip, index, currentIndex, setTip } = this.props
    const hidden = currentIndex !== index
    const videoJsOptions = {
      aspectRatio: '16:9',
      autoplay: true,
      controls: true,
      fluid: true,
      loop: true,
      poster: tip.thumbnail,
      preload: 'metadata'
    }
    return (
      <div className={classNames(classes.tipContainer, { _hidden: hidden })}>
        {tip.title &&
          <div className={classNames(classes.title, '_top')}>
            <div className={classes.titleIndex}>{index + '.'}</div>
            <div>{tip.title}</div>
          </div>
        }
        <div className={classes.videoWrapper}>
          <div className={classes.videoContainer}>
            {index === 0 &&
            <div className={classes.stepHeader}>
              10 Tips and Tricks
              <div className={classes.navButton} onClick={() => setTip(1)}>
                start
                <ArrowRight className={classes.navButtonArrow} />
              </div>
            </div>
            }
            {index >= 1 && index <= 10 &&
            <div className={classes.stepHeader}>
              {index + ' of 11'}
              <ArrowLeft className={classes.stepArrow} onClick={() => setTip(index - 1)} />
              <ArrowRight className={classes.stepArrow} onClick={() => setTip(index + 1)} />
            </div>
            }
            {index === 11 &&
            <div className={classes.stepHeader}>
              {index + ' of 11'}
              <div className={classes.navButton} onClick={() => setTip(10)}>
                <ArrowLeft className={classes.navButtonArrow} />
                back
              </div>
            </div>
            }
            {tip.video && !tip.image &&
            <VideoPlayerPreview
              {...videoJsOptions}
              video={tip.video}
              sources={[{ src: tip.video }]}
              wrapperWidth={'100%'}
            />
            }
            {tip.image && tip.alt && !tip.video &&
            <img className={classes.image} src={tip.image} alt={tip.alt}/>
            }
          </div>
        </div>
        {tip.title &&
        <div className={classes.title}>
          <div className={classes.titleIndex}>{index + '.'}</div>
          <div>{tip.title}</div>
        </div>
        }
        <div className={classes.description}>{tip.description}</div>
        <div className={classes.buttonWrapper}>
          <RegisterOrProjectButton projectLabel={'Start a Project Today'} />
        </div>
      </div>
    )
  }
}

TopTenTip.propTypes = {
  classes: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setTip: PropTypes.func.isRequired,
  tip: PropTypes.object.isRequired
}

export default withStyles(styles)(TopTenTip)
