import { CelebrityIcon, SilverseaIcon } from '../components/common/SVG'
import silverPackBackground from '../assets/img/skins/silversea/backgroundV2.jpg'
import celebPackBackground from '../assets/img/skins/celebrity-cruises/background.jpg'
import celebrityProjectThumbnail from '../assets/img/defaultThumbnail/celebrity.jpg'
import celebrityProjectThumbnail16x9 from '../assets/img/defaultThumbnail/celebrity16x9.jpg'
import fireworks from '../assets/img/fireworks.jpg'
import silverseaFireworks from '../assets/img/silverseaFireworks.jpg'
import silverseaProjectThumbnail from '../assets/img/defaultThumbnail/silversea.jpg'
import silverseaProjectThumbnail16x9 from '../assets/img/defaultThumbnail/silversea16x9.jpg'

function getSkinData (skin) {
  switch (skin) {
    case 1:
      return {
        backgroundColor: '#231f20',
        backgroundImage: silverseaFireworks,
        backgroundPosition: '0% 0%',
        backgroundSize: 'auto 120vh',
        backgroundStyleClass: {
          background: `url(${silverPackBackground}) no-repeat 87% bottom`,
          backgroundSize: 'cover',
          marginBottom: '0px'
        },
        congratulationsSubtitle: 'The movie of your Silversea voyage will be ready soon',
        congratulationsTitle: 'Thank you!',
        giftText: 'To thank you for being part of our pilot program, you have received: a Hollywood mini-movie',
        gradientEndColor: '#231f20',
        gradientStartColor: '#231f20',
        loginButton: 'OK',
        loginTitle: 'Congratulations',
        logo: SilverseaIcon,
        mainColor: '#55565a',
        opacity: 0.1,
        packageThumbnailUrl: 'https://static.urlifemedia.com/thumbnails/silversea-hero-thumbnail.jpg',
        packageVideoUrl: 'https://static.urlifemedia.com/silversea-hero-video/master.m3u8',
        projectThumbnail: silverseaProjectThumbnail,
        projectThumbnail16x9: silverseaProjectThumbnail16x9,
        title: 'Silversea'
      }
    case 2:
      return {
        backgroundColor: '#000000',
        backgroundImage: fireworks,
        backgroundPosition: '50%',
        backgroundSize: 'auto 100vh',
        backgroundStyleClass: {
          background: `
                  linear-gradient(180deg, #172750 0%, rgba(49, 69, 141, 0) 100%) no-repeat left top,
                  url(${celebPackBackground}) no-repeat right bottom`,
          backgroundSize: '100% 400px, cover'
        },
        codeResultDescription: 'You can now get started on your custom video project',
        gradientEndColor: '#002859',
        gradientStartColor: '#002859',
        logo: CelebrityIcon,
        logoClass: '_celebrity',
        mainColor: '#002859',
        opacity: 0.49,
        packageSubtitle: `
          Your cruise memories brought to life|Upload your photos, videos and any fun details that will help us tell
          your story. Add things like music preferences, itinerary notes and favorite moments!
        `,
        packageThumbnailUrl: 'https://static.urlifemedia.com/thumbnails/celebrity-package-thumbnail.png',
        packageVideoUrl: 'https://static.urlifemedia.com/celebrity-hero-video/master.m3u8',
        projectThumbnail: celebrityProjectThumbnail,
        projectThumbnail16x9: celebrityProjectThumbnail16x9,
        successBody: 'You have unlocked your custom video purchase',
        successTitle: 'Welcome!',
        title: 'Celebrity Cruises'
      }
    default:
      return null
  }
}

const skinning = (state = {
  skinData: null
}, action) => {
  switch (action.type) {
    case 'SET_SKIN_ID':
      return {
        ...state,
        skinData: getSkinData(action.skinId)
      }
    default:
      return state
  }
}

export default skinning
export {
  getSkinData
}
