import ApiService from '../services/ApiService'
import shortid from 'shortid'
import { api } from './../constants'
import { fileProgressStatus, fileProgressType } from '../reducers/fileProgress'

export {
  createLog,
  sendCloseLog
}

function createLog (messageId, message) {
  return dispatch => {
    return new ApiService(api.LOGS, {}, {}, true, dispatch)
      .post({ message, messageId })
  }
}

function sendCloseLog () {
  return (dispatch, getStore) => {
    const store = getStore()
    const msgId = shortid.generate()
    const message = {
      batching: 0,
      complete: 0,
      failed: 0,
      text: 'closed web while uploading',
      type: 'WEB_WINDOW_CLOSED',
      uploading: 0
    }
    Object.values(store.fileProgress).forEach(f => {
      if (f.progressType === fileProgressType.upload) {
        if (f.status === fileProgressStatus.complete) {
          message.complete++
        } else if (f.status === fileProgressStatus.failed) {
          message.failed++
        } else {
          message.uploading++
        }
      }
    })
    message.batching = Object.values(store.fileBatch).reduce((acc = { completedCount: 0, totalCount: 0 }, cur) => {
      acc.totalCount = acc.totalCount + cur.totalCount
      acc.completedCount = acc.completedCount + cur.completedCount
      return acc
    }, {})
    if (!message.complete && !message.failed && !message.uploading && !message.batching.totalCount) {
      return false
    }
    return dispatch(createLog(msgId, message))
  }
}
