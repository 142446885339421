import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  privacyContainer: {
    '& a': {
      color: '#5334b6',
      '&:hover': {
        color: '#01b7d7'
      }
    }
  },
  privacyText: {
    ...commonStyles.text({
      lineHeight: 1.5,
      letterSpacing: 0.8,
      color: '#1c2025',
      fontSize: 16,
      marginBottom: 30
    }),
    display: 'block'
  },
  privacyList: {
    listStyle: 'none',
    ...commonStyles.text({
      lineHeight: 1.5,
      letterSpacing: 0.8,
      color: '#1c2025',
      fontSize: 16,
      marginBottom: 20
    })
  },
  privacyItem: {
    position: 'relative',
    marginBottom: 25,
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: "'—'",
      top: 0,
      left: -35,
      color: '#1c2025'
    }

  },
  privacyTitle: {
    marginTop: 0,
    ...commonStyles.text({
      lineHeight: 1.25,
      fontSize: 48,
      fontWeight: 300,
      color: '#000000'
    })
  },
  privacySubTitle: {
    ...commonStyles.text({
      lineHeight: 0.92,
      fontSize: 26,
      color: '#000000'
    }),
    margin: '50px 0 40px'
  },
  privacyLink: {
    color: '#5334b6',
    textDecoration: 'none'
  }
}
/* eslint-enable sort-keys */

class Privacy extends React.Component {
  render () {
    const { classes } = this.props
    return <div>
      <div className={classes.privacyContainer}>
        <h2 className={classes.privacyTitle}>URLIFE Media, Inc. Privacy Policy</h2>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}>Last Modified: October 10, 2022</h3>
        </div>
        <div className={classes.privacyTextBlock}>
          <span className={classes.privacyText}>
            URLIFE Media, Inc. and our subsidiaries and affiliates (“URLIFE”)
            are a leading provider of personal digital services, including editing
            and custom mini-movies for consumers.
            We care about protecting the personal information of our visitors
            who use our websites, products or services (collectively, our “Users”).
            This privacy policy provides details about how your personal information
            is collected, shared and used by us.
            If you have any questions about this privacy policy or the practices
            described herein, you may contact <a href="mailto:help@urlifemedia.com">help@urlifemedia.com</a> or
            3524 Hayden Avenue, Los Angeles, California 90232.
            For the purposes of the European {'Union\'s'} General Data Protection Regulation
            2016/679, or GDPR, our European local representative is Wenso Solutions.
          </span>
          <span className={classes.privacyText}>
            The purpose of this privacy policy (“Privacy Policy”) is to inform
            you what personally identifiable information or personal information
            we may collect from you when you use our application or visit this
            website, how we use such information, and the choices you have regarding
            our use of, and your ability to review and correct, the information.
          </span>
          <span className={classes.privacyText}>
            For purposes of this Privacy Policy, the terms “we,” “us” and “our”
            refer to URLIFE.
            “You” refers to you, as a user of our services or visitor to our website,
            as applicable and to people that you contact through our Services
            (or that we contact on your behalf), or a person who you might choose
            to contact (or that we contact on your behalf) at some point in the
            future through the use of our Services.
            Capitalized terms not defined in this Privacy Policy have the meanings
            set forth in the Terms of Use.
            This Privacy Policy is effective as of the date last modified, as listed in above.
          </span>
          <span className={classes.privacyText}>
            This privacy policy covers personal information, including any information
            we collect, use and share from you, as described further below.
            This privacy policy applies to our website, our products and services
            (collectively, the “Services”).
            This privacy policy does not cover how our Users may use or share data
            that they collect using our services. When you purchase a Service from
            us, your personal information will be collected, used, and shared consistent
            with the provisions of this privacy policy.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>INFORMATION WE RECEIVE</b></h3>
          <span className={classes.privacyText}>
            In the course of your use of the Services, we obtain the following
            information about you as described below. We may need to collect this
            information to provide you with services or information you request,
            or to facilitate commercial engagements with you. If you do not provide
            such information, we may not be able to provide services or information
            to you, or enter commercial engagements with you.
            We will only collect information about you that is necessary for one or
            more of our legitimate business purposes, or is required by law.
          </span>
          <h3 className={classes.privacySubTitle}>Signing Up for Services; User Information</h3>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              Information you provide prior to any registration process, such as your
              email when you provide it to us;
            </li>
            <li className={classes.privacyItem}>
              Information that you provide during any registration process, including
              in connection with a co-branded offer (such as your name and email
              address), when you call or email us (for support or otherwise) or when
              you use our products or services.
            </li>
          </ul>
          <h3 className={classes.privacySubTitle}>Use of Services, Website Visits and Support</h3>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              Data relating to your online activity on our website with respect to our
              Services, including the following:
            </li>
            <li>
              <ul className={classes.privacyList}>
                <li className={classes.privacyItem}>
                  IP address (on an anonymized basis)
                </li>
                <li className={classes.privacyItem}>
                  browser type and version
                </li>
                <li className={classes.privacyItem}>
                  geographic location
                </li>
                <li className={classes.privacyItem}>
                  pages you view on our website
                </li>
                <li className={classes.privacyItem}>
                  how you got to our Services and any links you click on to leave our Services
                </li>
                <li className={classes.privacyItem}>
                  when you update your information, communicate with us, or order new Services
                </li>
                <li className={classes.privacyItem}>
                  metadata about your use of our Services and the communication that you send in-site
                </li>
                <li className={classes.privacyItem}>
                  your interactions with any videos we offer
                </li>
                <li className={classes.privacyItem}>
                  issues you encounter requiring our support or assistance
                </li>
                <li className={classes.privacyItem}>
                  any device or other method of communication you use to interact with the Services
                </li>
              </ul>
            </li>
            <li className={classes.privacyItem}>
              We use various technologies to collect and store information when you use
              our Services, and this may include sending one or more cookies or anonymous
              identifiers to your device.
              We may store the data we collect in a variety of places within our
              infrastructure, including system log files, back end databases and
              analytics systems.
            </li>
          </ul>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}>Other Sources</h3>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              Information that is publicly available; and
            </li>
            <li className={classes.privacyItem}>
              Information you consent to us receiving from third parties.
            </li>
          </ul>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>HOW WE USE YOUR INFORMATION</b></h3>
          <span className={classes.privacyText}>
            We will never sell your data or private information to others.
            We have a legitimate interest in running a successful and efficient business
            and in providing you with Services and useful content, and we use the
            information we collect, both on its own and combined with any other
            information we collect about you, for the following purposes:
          </span>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              To provide the requested Services to you;
            </li>
            <li className={classes.privacyItem}>
              To provide you with useful content;
            </li>
            <li className={classes.privacyItem}>
              To ensure the proper functioning of our Services
            </li>
            <li className={classes.privacyItem}>
              To offer and improve our Services;
            </li>
            <li className={classes.privacyItem}>
              To provide you with requested information or technical support;
            </li>
            <li className={classes.privacyItem}>
              To facilitate your movement through our website or your use of our Services;
            </li>
            <li className={classes.privacyItem}>
              To do a better job of advertising and marketing our Services (our use of your
              information to do this is performed with your consent where required by applicable law);
            </li>
            <li className={classes.privacyItem}>
              To diagnose problems with our servers or our Services;
            </li>
            <li className={classes.privacyItem}>
              In connection with our security and compliance programs;
            </li>
            <li className={classes.privacyItem}>
              To administer our websites;
            </li>
            <li className={classes.privacyItem}>
              To communicate with you;
            </li>
            <li className={classes.privacyItem}>
              To target prospective customers with our products or services (such
              targeting is only performed with your permission where your consent
              is required by applicable law);
            </li>
            <li className={classes.privacyItem}>
              To assist us in offering you a personalized experience or otherwise
              tailor our Services to you; and
            </li>
            <li className={classes.privacyItem}>
              As otherwise described in this privacy policy.
            </li>
          </ul>
          <span className={classes.privacyText}>
            We also use the information we receive in aggregated and anonymized formats
            to produce reports on trends and statistics, such as mobile search trends,
            email open rates by industry, campaign best practices or the number of users
            that have been exposed to, or clicked on, our websites or evaluated or
            purchased our products and services.
          </span>
          <span className={classes.privacyText}>
            We do not collect payment information, including but not limited to credit
            card data.
            You will be directed to a third-party site to process any payments you make
            to URLIFE.
          </span>
          <span className={classes.privacyText}>
            We do not use your personal data for the purposes of automated decision-making
            or profiling.
            If we do so in the future, we will inform you of any such processing and
            provide you with an opportunity to object.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>SHARING OF INFORMATION</b></h3>
          <span className={classes.privacyText}>
            We will only share certain personal information as further described below.
          </span>
          <h3 className={classes.privacySubTitle}>Sharing with Third Party Service Providers and Vendors</h3>
          <span className={classes.privacyText}>
            Occasionally, we enter into contracts with carefully selected third parties
            so that they can assist us in servicing you (for example, providing you with
            customer service, fraud detection and deterrence or access to advertising
            assets and providing us with information technology and storage services) or
            to assist us in our own marketing and advertising activities (including
            providing us with analytic information and search engine optimization
            services).
            Our contracts with such third parties prohibit them from using any of your
            personal information for any purpose beyond the purpose for which it was
            shared.
          </span>
          <span className={classes.privacyText}>
            We also share non-personal information with certain third parties, including
            the media, industry observers, marketing and advertising partners, vendors,
            customers, potential customers or partners.
            For example, we disclose mobile search trends, email open rates by industry,
            campaign best practices or the number of users that have been exposed to, or
            clicked on, our websites or evaluated or purchased our products and services.
          </span>
          <h3 className={classes.privacySubTitle}>Corporate Reorganizations</h3>
          <span className={classes.privacyText}>
            If we are involved in a merger, acquisition, a sale of all or a substantial
            portion of our assets, or other similar sale transaction, your information
            will be transferred as part of that transaction.
            We will notify you by email and/or a prominent notice on our website of any
            such transfer and any choices you may have regarding your information.
          </span>
          <h3 className={classes.privacySubTitle}>Authorized Users</h3>
          <span className={classes.privacyText}>
            All users authorized by you to have access to your account can view
            personal information stored in the account.
          </span>
          <h3 className={classes.privacySubTitle}>Legal Process</h3>
          <span className={classes.privacyText}>
            Lastly, if legally required to do so, or if we have a good faith belief that
            such disclosure is reasonably necessary, we may disclose your personal
            information to courts of law, law enforcement authorities and other relevant
            third parties, such as internet service providers, to conduct an
            investigation, respond to a third party or law enforcement subpoena or court
            order, bring legal action, prevent harm to others or pursue other relief
            when you or a third party are or may be:
          </span>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              violating our terms and conditions of use;
            </li>
            <li className={classes.privacyItem}>
              causing injury or other harm to, or otherwise violating the property or
              other legal rights, of us, other users, or third parties; or
            </li>
            <li className={classes.privacyItem}>
              violating federal, state, local, or other applicable law.
            </li>
          </ul>
          <span className={classes.privacyText}>
            This disclosure can include transferring your information to the U.S. and
            other countries outside the European Economic Area.
            To the extent we are legally permitted to do so, it is our policy to notify
            you in the event that we are required to provide your personal information
            to third parties in connection with a subpoena.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>SECURITY</b></h3>
          <span className={classes.privacyText}>
            The transmission of information via the internet, email or text message is
            not completely secure.
            Although we will do our best to protect your personal information, we cannot
            guarantee the security of your information transmitted through the Services
            or over email; any transmission is at your own risk.
            Once we have received your information, we will take appropriate technical
            and organizational measures to safeguard your personal information against
            loss, theft and unauthorized use, access or modification.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>CHOICE/OPT-OUT</b></h3>
          <h3 className={classes.privacySubTitle}>Email</h3>
          <span className={classes.privacyText}>
            You always have the opportunity to opt out of our marketing communications
            with you or change your preferences by following a link in the footer of
            all non-transactional email messages from us or by emailing us
            at <a href="mailto:help@urlifemedia.com">help@urlifemedia.com</a>.
            Some communications from us are considered transactional or service
            communications (for example, important account notifications and video
            collaboration correspondence), and your account(s) for our products and
            services are provided to you upon the condition that you receive these
            communications from us.
            You must cancel your account(s) for our products and services, as applicable,
            if you do not wish to receive any transactional or service communications.
            To cancel your account(s), please follow the instructions found in the terms
            of service for our service.
            You may still receive marketing communications from us even after you cancel
            your account unless you also opt-out of our marketing communications, as
            described above.
          </span>
          <h3 className={classes.privacySubTitle}>Phone</h3>
          <span className={classes.privacyText}>
            We may contact you by telephone, with your consent where applicable, in order
            to provide our services (video creation discussions with a producer).
          </span>
          <h3 className={classes.privacySubTitle}>Information from Third Parties</h3>
          <span className={classes.privacyText}>
            To manage the information we receive about you from a social networking site
            or other third parties (if applicable), you will need to follow the
            instructions from that party for updating your information and changing your
            privacy settings, where available.
            The information we collect is covered by this privacy policy and the
            information the third party collects is subject to such third {'party\'s'}
            privacy practices.
            Privacy choices you have made on any third party site will not apply to our
            use of the information we have collected directly through our Services.
          </span>
          <h3 className={classes.privacySubTitle}>Unauthorized Accounts</h3>
          <span className={classes.privacyText}>
            If an account or profile was created without your knowledge or authorization,
            please contact customer support at the brand on which the account or profile
            is located to request removal of the account or profile.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>RETENTION OF PERSONAL INFORMATION</b></h3>
          <span className={classes.privacyText}>
            We retain your personal information to provide services to you and as
            otherwise necessary to comply with our legal obligations, resolve disputes,
            and enforce our agreements.
            We will retain your personal information for no more than one month following
            the date on which you terminate your use of the Services, unless we are
            otherwise required by law or regulation to retain your personal information
            for longer.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>YOUR RIGHTS</b></h3>
          <span className={classes.privacyText}>
            Where the European {'Union\'s'} General Data Protection Regulation 2016/679,
            or GDPR, applies, in certain circumstances and subject to data processing
            agreements, you have rights in relation to the personal information we hold
            about you.
            While we are a US-based service and not generally subject to GDPR, we
            endeavor to materially follow the guidelines of GDPR.
            We set out below an outline of those rights and how to exercise rights under
            GDPR.
            Please note that we will require you to verify your identity before
            responding to any requests to exercise your rights by providing details only
            known to the account holder.
            Please note that for each of the rights below we may have valid legal reasons
            to refuse your request, in such instances we will let you know if that is the
            case.
          </span>
          <h3 className={classes.privacySubTitle}>Access</h3>
          <span className={classes.privacyText}>
            You have the right to know whether we process personal information about you,
            and if we do, to access data we hold about you and certain information about
            how we use it and who we share it with.
          </span>
          <h3 className={classes.privacySubTitle}>Correction</h3>
          <span className={classes.privacyText}>
            You have the right to require us to correct any personal information held
            about you that is inaccurate and have incomplete data completed.
            Where you request correction, please explain in detail why you believe the
            personal information we hold about you to be inaccurate or incomplete so that
            we can assess whether a correction is required.
            Please note that while we assess whether the personal information we hold
            about you is inaccurate or incomplete, you may exercise your right to
            restrict our processing of the applicable data as described below.
          </span>
          <h3 className={classes.privacySubTitle}>Erasure</h3>
          <span className={classes.privacyText}>
            You may request that we erase the personal information we hold about you in
            the following circumstances:
          </span>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              where you believe it is no longer necessary for us to hold the personal
              information;
            </li>
            <li className={classes.privacyItem}>
              we are processing it on the basis of your consent and you wish to withdraw
              your consent;
            </li>
            <li className={classes.privacyItem}>
              we are processing your data on the basis of our legitimate interest and
              you object to such processing;
            </li>
            <li className={classes.privacyItem}>
              you no longer wish us to use your data to send you marketing; or
            </li>
            <li className={classes.privacyItem}>
              you believe we are unlawfully processing your data.
            </li>
          </ul>
          <span className={classes.privacyText}>
            Please provide as much detail as possible on your reasons for the request to
            assist us in determining whether you have a valid basis for erasure.
            Please send your request by e-mail to us
            at <a href="mailto:help@urlifemedia.com">help@urlifemedia.com</a>.
          </span>
          <h3 className={classes.privacySubTitle}>Restriction of Processing to Storage Only</h3>
          <span className={classes.privacyText}>
            You have a right to require us to stop processing the personal information we
            hold about you other than for storage purposes in the following circumstances:
          </span>
          <ul className={classes.privacyList}>
            <li className={classes.privacyItem}>
              You believe the personal information is not accurate for the period it
              takes for us to verify whether the data is accurate;
            </li>
            <li className={classes.privacyItem}>
              We wish to erase the personal information as the processing we are doing
              is unlawful but you want us to simply restrict the use of that data;
            </li>
            <li className={classes.privacyItem}>
              We no longer need the personal information for the purposes of the
              processing but you require us to retain the data for the establishment,
              exercise or defense of legal claims; or
            </li>
            <li className={classes.privacyItem}>
              You have objected to us processing personal information we hold about you
              on the basis of our legitimate interest and you wish us to stop processing
              the personal information while we determine whether there is an overriding
              interest in us retaining such personal information.
            </li>
          </ul>
          <h3 className={classes.privacySubTitle}>Objection</h3>
          <span className={classes.privacyText}>
            You have the right to object to our processing of data about you and we will
            consider your request.
            Please provide us with detail as to your reasoning so that we can assess
            whether there is a compelling overriding interest in us continuing to process
            such data or we need to process it in relation to legal claims.
          </span>
          <span className={classes.privacyText}>
            You also have the right, at any time, to object to our processing of data
            about you in order to send you marketing, including where we build profiles
            for such purposes and we will stop processing the data for that purpose.
          </span>
          <span className={classes.privacyText}>
            Please send any objections to us by e-mail
            at <a href="mailto:help@urlifemedia.com">help@urlifemedia.com</a>.
          </span>
          <h3 className={classes.privacySubTitle}>Withdrawal of Consent</h3>
          <span className={classes.privacyText}>
            Where you have provided your consent to us processing your personal data, you
            can withdraw your consent at any time by e-mailing us
            at <a href="mailto:help@urlifemedia.com">help@urlifemedia.com</a>.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}>
            <b>THIRD PARTY LINKS AND INFORMATION COLLECTION AND USE BY OUR USERS</b>
          </h3>
          <span className={classes.privacyText}>
            Some of our Services provide links to other websites.
            Because we do not control the information policies or practices of these
            third party sites, you should review their privacy policies to learn about
            how they collect and use personal information.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>CHILDREN</b></h3>
          <span className={classes.privacyText}>
            Our Services are not directed to persons under 18.
            We do not knowingly collect personal information from children under 18.
            If a parent or guardian becomes aware that his or her child has provided us
            with personal information without such parent or {'guardian\'s'} consent,
            he or she should contact us.
            If we become aware that a child under 18 has provided us with personal
            information, we will delete such information from our files.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>DATA TRANSFER</b></h3>
          <span className={classes.privacyText}>
            In order for us to provide the Services to you, your personal information
            will be transferred to, and stored at/processed in the United States.
            We will take all steps reasonably necessary to ensure that your personal
            data is treated securely and in accordance with this policy.
          </span>
          <span className={classes.privacyText}>
            For transfers of personal information within our corporate family, such
            transfer will be under the {'Commission\'s'} model contracts for the transfer
            of personal data to third countries (i.e., the standard contractual clauses),
            pursuant to Decision 2004/915/EC and 2010/87/EU.
          </span>
          <span className={classes.privacyText}>
            For transfers of data to third parties, such transfers will either (i) be
            under the {'Commission\'s'} model contracts for the transfer of personal data
            to third countries (i.e., the standard contractual clauses), pursuant to
            Decision 2004/915/EC and 2010/87/EU; or (ii) rely on the EU-US Privacy Shield
            Framework.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>COMPLAINTS</b></h3>
          <span className={classes.privacyText}>
            In the event that you wish to make a complaint about how we process your
            personal information, please contact us
            at <a href="help@urlifemedia.com">help@urlifemedia.com</a> and we will
            try to deal with your request.
          </span>
        </div>
        <div className={classes.privacyTextBlock}>
          <h3 className={classes.privacySubTitle}><b>NOTIFICATION OF CHANGES</b></h3>
          <span className={classes.privacyText}>
            We reserve the right to modify this privacy policy at any time, so please
            review it frequently.
            If we decide to change this privacy policy in any material way, we will
            notify you here, by email, or by means of a notice
            on <a href="/">www.urlifemedia.com</a> prior to the change becoming
            effective.
            In all cases, your continued use of any Services constitutes acceptance to
            any such changes.
          </span>
        </div>
      </div>
    </div>
  }
}
Privacy.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Privacy)
