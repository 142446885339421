import ApiService from '../services/ApiService'
import { abortUpload, uploadFile } from './index'
import { api, FILE_STATUSES } from '../constants'
import { cacheSignedUrl } from '../services/CacheSignedUrls'

export {
  clearProjectFiles,
  deleteFile,
  fetchAllProjectFiles,
  getProjectFilesTotalSize,
  getProducerFavoritesTotalSize,
  initialization,
  projectFiles,
  updateFile,
  updateLocalFile,
  uploadProjectFiles
}

const timeOutUpdateIds = {}

function uploadProjectFiles (uploads, projectId) {
  return dispatch => {
    return new Promise(resolve => {
      const updateProjectFile = (fileId, IdentityId) => {
        dispatch(updateFile(
          { id: fileId, status: FILE_STATUSES.CLIENT_UPLOAD_COMPLETE },
          projectId,
          IdentityId)
        ).then(
          data => {
            dispatch({
              file: { ...data },
              type: 'PROJECT_FILE_UPDATE'
            })
          }
        ).catch(e => {
          dispatch({ id: fileId, type: 'UPLOAD_FAILED' })
          // try to update status every 5 sec
          timeOutUpdateIds[fileId] = setTimeout(
            () => {
              updateProjectFile(fileId, IdentityId)
            },
            5000
          )
        })
      }
      const uploadProjectFile = file => {
        dispatch(uploadFile(
          null,
          file.fileObject,
          file.id,
          file.fileExt,
          projectId,
          'user',
          'project/' + file.id + '.' + file.fileExt,
          (cognitoInfo) => { updateProjectFile(file.id, cognitoInfo.IdentityId) },
          () => {
            dispatch(updateFile(
              { id: file.id, status: FILE_STATUSES.UPLOAD_FAILED },
              projectId,
              ''
            ))
          }
        )).catch(e => {
          // Catches any error in the uploadFile function.
          // This should never occur.
        })
      }

      uploads.forEach(file => uploadProjectFile(file))
      return resolve()
    })
  }
}

function initialization (files, projectId, fileObjects) {
  return dispatch => {
    return new ApiService(api.PROJECT_FILES, { projectId }, {}, true, dispatch)
      .post(files)
      .then(
        data => {
          const uploads = data.data.map((file, index) => {
            return { ...file, fileObject: fileObjects[index].object }
          })
          dispatch(uploadProjectFiles(uploads, projectId))
          const addedFiles = uploads.map((file, index) => {
            return {
              fileSize: file.fileObject.size,
              id: file.id,
              ...files.files[index],
              src: window.URL.createObjectURL(file.fileObject),
              status: 1
            }
          })
          dispatch({ files: addedFiles, type: 'PROJECT_FILES_APPEND' })
          return uploads
        }
      )
  }
}

function updateFile ({ id, ...body }, projectId, identityId = null) {
  return dispatch => {
    return new ApiService(api.ONE_PROJECT_FILE, { fileId: id, projectId }, { identityId }, true, dispatch)
      .put(body)
  }
}

function updateLocalFile (file) {
  return dispatch => {
    return dispatch({
      file,
      type: 'PROJECT_FILE_UPDATE'
    })
  }
}

function projectFiles (projectId, params = {}) {
  return dispatch => {
    dispatch({
      add: (params && params.add) || null,
      nextCursor: (params && params.cursor) || null,
      type: 'FETCHING_FILES'
    })
    return new ApiService(api.PROJECT_FILES, { projectId }, params, true, dispatch)
      .get()
      .then(
        files => {
          return dispatch({
            add: (params && params.add) || null,
            files: files.data,
            nextCursor: files.nextCursor,
            type: 'PROJECT_FILES'
          })
        },
        fail => {
          return dispatch({ type: 'PROJECT_FILES_FAILED' })
        }
      )
  }
}

function fetchAllProjectFiles (projectId, params = {}) {
  return dispatch => {
    dispatch({
      add: null,
      nextCursor: params.cursor || null,
      type: 'FETCHING_FILES'
    })
    const fetchParams = {
      count: params.count || 100,
      method: params.method || 'ASC',
      sort: params.sort || 'created_at'
    }
    if (params.cursor) fetchParams.cursor = params.cursor
    return new ApiService(api.PROJECT_FILES, { projectId }, fetchParams, true, dispatch)
      .get()
      .then(
        files => {
          params.files = [].concat(params.files || [], files.data)
          if (files.nextCursor) {
            params.cursor = files.nextCursor
            return dispatch(fetchAllProjectFiles(projectId, params))
          } else {
            // cache signed urls
            const fileLinks = params.files.reduce((arr, file) => {
              arr.push(file.signedUrl)
              arr.push(file.signedThumbnailUrl)
              return arr
            }, [])
            cacheSignedUrl.add(fileLinks)

            return dispatch({
              add: null,
              files: params.files,
              nextCursor: files.nextCursor,
              type: 'PROJECT_FILES'
            })
          }
        },
        fail => {
          return dispatch({ type: 'PROJECT_FILES_FAILED' })
        }
      )
  }
}

function deleteFile (projectId, fileId) {
  return (dispatch, getStore) => {
    const upload = Object.values(getStore().fileProgress).filter(file => {
      return file.id === fileId && file.progressType === 'upload'
    })
    if (Object.values(getStore().fileProgress) && upload.length > 0) {
      dispatch(abortUpload(fileId))
    }
    dispatch({ file: fileId, type: 'PROJECT_FILE_DELETE' })
    return new ApiService(api.ONE_PROJECT_FILE, { fileId, projectId }, {}, true, dispatch)
      .delete()
  }
}

function clearProjectFiles () {
  return dispatch => {
    return dispatch({ type: 'CLEAR_PROJECT_FILES' })
  }
}

function getProjectFilesTotalSize (projectId) {
  return dispatch => {
    return new ApiService(api.PROJECT_FILES_TOTAL_SIZE, { projectId }, {}, true, dispatch)
      .get()
  }
}

function getProducerFavoritesTotalSize (projectId) {
  return dispatch => {
    return new ApiService(api.PRODUCER_FAVORITES_TOTAL_SIZE, { projectId }, {}, true, dispatch)
      .get()
      .then(
        body => dispatch({ size: body.totalSize, type: 'SET_PRODUCER_FAVORITES_SIZE' })
      )
  }
}
