import ApiService from '../services/ApiService'
import { api, FILE_STATUSES } from './../constants'
import { uploadFile } from './index'

export {
  createProjectResources,
  updateProjectResource
}

const timeOutUpdateIds = {}

function createProjectResources (files, projectId, fileObjects) {
  return dispatch => {
    return new ApiService(api.PROJECT_RESOURCES, { projectId }, {}, true, dispatch)
      .post(files)
      .then(
        data => {
          const uploads = data.data.map((file, index) => {
            return { ...file, fileObject: fileObjects[index].object }
          })
          const updateResourceFile = (fileId, IdentityId) => {
            dispatch(updateProjectResource(
              { id: fileId, status: FILE_STATUSES.CLIENT_UPLOAD_COMPLETE },
              projectId,
              IdentityId)
            ).catch(e => {
              // try to update status every 5 sec
              timeOutUpdateIds[fileId] = setTimeout(
                () => {
                  updateResourceFile(fileId, IdentityId)
                },
                5000
              )
            })
          }
          const uploadResourceFile = file => {
            dispatch(uploadFile(
              null,
              file.fileObject,
              file.id,
              file.fileExt,
              projectId,
              'resource',
              'resource/' + file.id + '.' + file.fileExt,
              (cognitoInfo) => updateResourceFile(file.id, cognitoInfo.IdentityId),
              () => {
                dispatch(updateProjectResource(
                  { id: file.id, status: FILE_STATUSES.UPLOAD_FAILED },
                  projectId,
                  ''
                ))
              }
            )).catch(e => {
              // Catches any error in the uploadFile function.
              // This should never occur.
            })
          }

          uploads.forEach(file => uploadResourceFile(file))
          return uploads
        }
      )
  }
}

function updateProjectResource ({ id, ...body }, projectId, identityId = null) {
  return dispatch => {
    return new ApiService(api.ONE_PROJECT_RESOURCE, { fileId: id, projectId }, { identityId }, true, dispatch)
      .put(body)
  }
}
