import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ViewTile (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 25 22'>
      <g><g><g><g><path fill="#333" d="M22.763 9.284H19.39v3.37h3.373zm1.5-.75v4.878c0 .414-.335.75-.749.75H18.64a.749.749 0 0 1-.749-.75V8.535c0-.414.335-.75.749-.75h4.875a.75.75 0 0 1 .75.75zM22.774 1.64h-3.373v3.369h3.373zm1.5-.75v4.87a.748.748 0 0 1-.748.747H18.65a.748.748 0 0 1-.749-.748V.888c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748zm-1.5 15.997h-3.373v3.369h3.373zm1.5-.75v4.87a.748.748 0 0 1-.748.747H18.65a.748.748 0 0 1-.749-.748v-4.87c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748z"/></g></g><g><g><path fill="#333" d="M14.133 9.284H10.76v3.37h3.373zm1.5-.75v4.878c0 .414-.335.75-.749.75H10.01a.749.749 0 0 1-.749-.75V8.535c0-.414.335-.75.749-.75h4.875a.75.75 0 0 1 .75.75zM14.144 1.64h-3.373v3.369h3.373zm1.5-.75v4.87a.748.748 0 0 1-.748.747H10.02a.748.748 0 0 1-.749-.748V.888c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748zm-1.5 15.997h-3.373v3.369h3.373zm1.5-.75v4.87a.748.748 0 0 1-.748.747H10.02a.748.748 0 0 1-.749-.748v-4.87c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748z"/></g></g><g><g><path fill="#333" d="M5.515 9.284H2.138v3.37h3.377zm1.498-.75v4.878c0 .414-.335.75-.749.75H1.39a.749.749 0 0 1-.749-.75V8.535c0-.414.335-.75.749-.75h4.875a.75.75 0 0 1 .75.75zM5.527 1.64H2.149v3.369h3.378zm1.497-.75v4.87a.748.748 0 0 1-.748.747H1.4a.748.748 0 0 1-.749-.748V.888c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748zM5.527 16.885H2.149v3.369h3.378zm1.497-.75v4.87a.748.748 0 0 1-.748.747H1.4a.748.748 0 0 1-.749-.748v-4.87c0-.413.335-.748.75-.748h4.875c.413 0 .748.335.748.748z"/></g></g></g></g>
    </SvgIcon>
  )
}

ViewTile.propTypes = {
  fill: PropTypes.string
}
