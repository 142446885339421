import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogContent,
  FormControl,
  Tooltip,
  Zoom
} from '@material-ui/core'
import { commonStyles, getFilePermissions } from '../../../helpers'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import {
  ArrowRight,
  BackArrow,
  CoverIcon,
  DownloadIcon,
  HeartFilled,
  HeartOutline,
  ImageLoading,
  StarFilled,
  StarOutline,
  TrashIcon,
  VideoLoading
} from '../SVG'
import {
  downloadErrorMessage,
  downloadFile,
  updateFile,
  updateLocalFile
} from '../../../actions'
import classNames from 'classnames'
import { browserHandlesRotation, getImageRotationCss } from '../../../helpers/imageRotationCss'
import VideoPlayerPreview from '../VideoPlayerPreview'
import UploadedSourceType from './UploadSourceType'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import TextField from '@material-ui/core/TextField'
import { MAX_TRANSCODE_FILE_SIZE } from '../../../constants'

/* eslint-disable sort-keys */
const styles = {
  popupPaper: {
    backgroundColor: '#333333'
  },
  projectFilePopupContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  contentRow: {
    ...commonStyles.container(),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&._icons': {
      height: '10%',
      minHeight: 50
    },
    '&._file': {
      flexGrow: 1,
      padding: 0,
      height: 'auto'
    },
    '&._notes': {
      alignItems: 'flex-end',
      justifyContent: 'center',
      minHeight: 50
    }
  },
  iconWrapper: {
    display: 'inline-block'
  },
  icon: {
    cursor: 'pointer',
    color: '#ffffff',
    height: 22,
    marginLeft: 12,
    marginRight: 12,
    '&._disabled': {
      cursor: 'default'
    },
    '&._heart': {
      stroke: '#FFF',
      fill: '#FFF'
    }
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: '0,4px',
    textTransform: 'uppercase',
    padding: '7px 10px',
    borderRadius: 2
  },
  resolutionText: {
    verticalAlign: 'super'
  },
  changeFileCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10%',
    minWidth: 100,
    height: '100%',
    ...commonStyles.media(575, {
      minWidth: 80
    })
  },
  changeFileIcon: {
    cursor: 'pointer',
    color: '#ffffff',
    width: 50,
    height: 50,
    padding: 13,
    borderRadius: 25,
    backgroundColor: '#666666',
    '&._next': {
      transform: 'rotate(180deg)'
    },
    '&._disabled': {
      opacity: 0.5
    },
    ...commonStyles.media(575, {
      width: 40,
      height: 40,
      padding: 10,
      borderRadius: 20
    })
  },
  fileCell: {
    position: 'relative',
    flexGrow: 1,
    height: '100%'
  },
  fileInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 'calc(100% - 200px)',
    '& span': {
      margin: '0px 5px',
      fontSize: 15
    },
    ...commonStyles.media(767, {
      '& span': {
        fontSize: 13
      }
    })
  },
  projectFilePopupImage: {
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
    left: 0,
    '&._fullSize': {
      right: 0,
      bottom: 0
    }
  },
  notesBlock: {
    margin: 'auto',
    width: '90%',
    maxWidth: 800,
    boxShadow: 'none',
    backgroundColor: '#333333'
  },
  notesSummaryContent: {
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    flexGrow: 'unset'
  },
  expandIcon: {
    color: '#ffffff',
    width: 15,
    height: 15,
    transform: 'rotate(-90deg)'
  },
  notesDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  notesForm: {
    width: '100%'
  },
  notesTextField: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    margin: '8px 0',
    color: '#ffffff'
  },
  notesUnderline: {
    '&&&&:hover': {
      '&:before': {
        borderBottomColor: '#ffffff',
        opacity: 0.8
      },
      '&:after': {
        borderBottomColor: '#ffffff'
      }
    },
    '&:before': {
      borderBottomColor: '#ffffff',
      opacity: 0.8
    },
    '&:after': {
      borderBottomColor: '#ffffff'
    }
  },
  notesInfoLabel: {
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
    opacity: 0.8,
    marginTop: 10
  },
  fileStatusBackground: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#eff2f5'
  },
  fileStatus: {
    position: 'absolute',
    textAlign: 'center',
    color: '#dde3e8',
    left: 0,
    right: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
    fontSize: 16
  },
  fileStatusIcon: {
    fontSize: '3.8em'
  },
  fileStatusText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&._uploading': {
      color: '#aacb7b'
    },
    '&._failed': {
      color: '#ff3b30'
    }
  }
}
/* eslint-enable sort-keys */

class ModalPreviewProjectFile extends Component {
  constructor (props) {
    super(props)

    this.state = {
      additionalImageStyles: {},
      description: ''
    }
    this.prevImageRef = null
    this.imageRef = React.createRef()
    this.fitImageToContainer = () => {
      const file = this.getFile()
      if (this.isImage() && this.isRotatedOnSide()) {
        if (this.imageRef && file.width && file.height && !browserHandlesRotation) {
          this.parent = this.imageRef.parentNode
          this.setState({
            additionalImageStyles: {
              height: this.parent.offsetWidth,
              width: this.parent.offsetHeight
            }
          })
        } else if (this.imageRef && file.width && file.height && browserHandlesRotation) {
          this.parent = this.imageRef.parentNode
          this.setState({
            additionalImageStyles: {
              height: this.parent.offsetHeight,
              width: this.parent.offsetWidth
            }
          })
        }
      } else {
        this.setState({
          additionalImageStyles: {}
        })
      }
    }
  }

  getFile () {
    return this.props.files.find(file => file.id === this.props.fileId) || {}
  }

  getFileIndex () {
    return this.props.files.map(file => file.id).indexOf(this.props.fileId)
  }

  isVideo () {
    return this.getFile().mimeType.includes('video')
  }

  isImage () {
    return this.getFile().mimeType.includes('image')
  }

  isAudio () {
    return this.getFile().mimeType.includes('audio')
  }

  isRotatedOnSide () {
    const file = this.getFile()
    return this.isImage() && file.orientation && [6, 8, 5, 7].includes(file.orientation)
  }

  getSrc (linkOnly) {
    const file = this.getFile()
    if (linkOnly) {
      return file.src || cacheSignedUrl.get(
        (file.webviewCdnUrl || file.cdnUrl ||
          file.signedWebviewUrl || file.signedUrl)
      )
    } else {
      const toReturn = []
      if (file.src) {
        toReturn.push({
          src: file.src,
          type: file.type || file.mimeType
        })
      }
      if (file.webview && file.webviewCdnUrl) {
        toReturn.push({
          src: cacheSignedUrl.get(file.webviewCdnUrl)
        })
      }
      if (file.signedUrl) {
        toReturn.push({
          src: cacheSignedUrl.get(file.signedUrl),
          type: file.type || file.mimeType
        })
      }
      return toReturn
    }
  }

  closeModalPreview () {
    this.props.onClose()
  }

  toggleFavorite (e, favorite) {
    e.stopPropagation()
    const file = this.getFile()
    this.props.saveFavoriteStatus(file, favorite)
  }

  toggleProducerFavorite (e, favorite) {
    e.stopPropagation()
    const file = this.getFile()
    this.props.saveProducerFavoriteStatus(file, favorite)
  }

  makeCover () {
    const { updateProject } = this.props
    const file = this.getFile()
    updateProject({ coverFileId: file.id })
  }

  getVideoOptions () {
    if (!this.isVideo()) return {}
    const file = this.getFile()
    const sources = [
      ...this.getSrc(),
      { src: this.getSrc(true), type: file.type || file.mimeType },
      { src: this.getSrc(true), type: 'video/mp4' }
    ]
    /*
      * Pushing an additional source with hard coded mime of 'video/mp4' solves a bug
      * on chrome to play quicktime files. Since it's the second source it should
      * not cause a problem for other formats we're using.
      */
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      fill: true,
      height: '100%',
      html5: {
        vhs: {
          withCredentials: true
        }
      },
      skin: 'project-file',
      sources: sources,
      video: this.getSrc(true),
      wrapperHeight: '100%',
      wrapperWidth: '100%'
    }
    return videoJsOptions
  }

  getAudioOptions () {
    if (!this.isAudio()) return {}
    const file = this.getFile()
    const sources = [
      ...this.getSrc(),
      { src: this.getSrc(true), type: file.type || file.mimeType }
    ]
    const videoJsOptions = {
      audio: this.getSrc(true),
      autoplay: true,
      controls: true,
      fill: true,
      height: '100%',
      html5: {
        vhs: {
          withCredentials: true
        }
      },
      poster: 'https://static.urlifemedia.com/videoAudioPosters/audio_icon_white.png',
      skin: 'project-file',
      sources: sources,
      wrapperHeight: '100%',
      wrapperWidth: '100%'
    }
    return videoJsOptions
  }

  componentDidMount () {
    // image ref not available immediately after mount, Dialog feature
    window.addEventListener('resize', this.fitImageToContainer)
    this.prevImageRef = this.imageRef
    this.updateNotes()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.fileId !== prevProps.fileId) {
      this.fitImageToContainer()
      this.updateNotes()
    }
    if (this.prevImageRef !== this.imageRef) {
      this.prevImageRef = this.imageRef
      this.fitImageToContainer()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.fitImageToContainer)
  }

  updateNotes () {
    const file = this.getFile()
    this.setState({
      description: file ? file.description || '' : ''
    })
  }

  onNotesChange (e) {
    this.setState({ description: e.target.value })
  }

  onNotesBlur () {
    const { project, updateFileFunction, updateLocalFileFunction } = this.props
    const { description } = this.state
    const file = this.getFile()
    if (file && file.description !== description) {
      updateFileFunction({ description: description, id: file.id }, project.id)
        .then(() => updateLocalFileFunction({ description: description, id: file.id }))
    }
  }

  onFileDownload () {
    const { clientDownloadFunction, project, setFileError } = this.props
    const file = this.getFile()
    if (!file) { return }
    clientDownloadFunction({
      id: file.id,
      name: file.original_name,
      projectId: project.id,
      role: 'project',
      size: file.fileSize,
      url: file.signedUrl
    })
      .then(
        success => {},
        error => {
          setFileError(downloadErrorMessage(error), file.original_name)
        }
      )
  }

  render () {
    const {
      classes,
      onClose,
      files,
      project,
      onChangeFileIndex,
      onFileDelete,
      roleId,
      userId,
      isCurated,
      fileNotesState,
      toggleNoteExpand
    } = this.props
    const {
      description,
      additionalImageStyles
    } = this.state
    const file = this.getFile()
    const fileIndex = this.getFileIndex()
    const isFileUploadComplete = file.src || file.status === 3
    const isFileInUploading = file.status === 1
    const isFileUploadFailed = file.status === 2
    const permissions = getFilePermissions(file, project, userId, roleId, isCurated)
    const isCover = file.id === project.coverFileId
    const isTranscodable = file.fileSize <= MAX_TRANSCODE_FILE_SIZE
    const setFav = (e) => { this.toggleFavorite(e, true) }
    const unsetFav = (e) => { this.toggleFavorite(e, false) }
    const setProdFav = (e) => { this.toggleProducerFavorite(e, true) }
    const unsetProdFav = (e) => { this.toggleProducerFavorite(e, false) }
    return (
      <Dialog
        classes={{ paper: classes.popupPaper }}
        onClose={onClose}
        fullScreen
        open={true}>

        <DialogContent className={classes.projectFilePopupContent}>
          <div className={classNames(classes.contentRow, '_icons')}>
            <BackArrow
              className={classes.icon}
              onClick={this.closeModalPreview.bind(this)}
              data-testid='mppf-back-arrow'
            />
            <div className={classes.fileInfo}>
              {permissions.canDownload &&
                <span>{file.original_name}</span>
              }
              {(permissions.isAssignedProducer || permissions.isAdmin) && file.width && file.height &&
                <span>Original Resolution: {file.width} x {file.height}</span>
              }
              {(permissions.isAssignedProducer || permissions.isAdmin) && file.uploadSource &&
                <UploadedSourceType type={file.uploadSource} />
              }
            </div>
            {permissions.hasActions &&
            <div>
              {permissions.canDownload &&
              <Tooltip
                title={'Download'}
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip
                }}>
                <div className={classes.iconWrapper}>
                  <DownloadIcon
                    className={classes.icon}
                    onClick={this.onFileDownload.bind(this)}
                    data-testid='mppf-download'
                  />
                </div>
              </Tooltip>
              }
              {permissions.canSeeProdFavorite &&
              <Tooltip
                title={'Producer favorite'}
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip
                }}>
                <div className={classes.iconWrapper}>
                  {file.producerFavorite &&
                  <HeartFilled
                    className={classNames(classes.icon, { _disabled: !permissions.canProdFavorite })}
                    onClick={permissions.canProdFavorite ? unsetProdFav.bind(this) : null}
                    data-testid='mppf-heart-filled'
                  />
                  }
                  {!file.producerFavorite &&
                  <HeartOutline
                    className={classNames(classes.icon, '_heart', { _disabled: !permissions.canProdFavorite })}
                    onClick={permissions.canProdFavorite ? setProdFav.bind(this) : null}
                    data-testid='mppf-heart-outline'
                  />
                  }
                </div>
              </Tooltip>
              }
              {permissions.canSeeFavorite &&
              <Tooltip
                title={'Favorite'}
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip
                }}>
                <div className={classes.iconWrapper}>
                  {file.favorite &&
                  <StarFilled
                    className={classNames(classes.icon, { _disabled: !permissions.canFavorite })}
                    onClick={permissions.canFavorite ? unsetFav.bind(this) : null}
                    data-testid='mppf-star-filled'
                  />
                  }
                  {!file.favorite &&
                  <StarOutline
                    className={classNames(classes.icon, { _disabled: !permissions.canFavorite })}
                    onClick={permissions.canFavorite ? setFav.bind(this) : null}
                    data-testid='mppf-star-outline'
                  />
                  }
                </div>
              </Tooltip>
              }
              {permissions.canMakeCover && !isCover &&
              <Tooltip
                title={'Make cover image'}
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip
                }}>
                <div className={classes.iconWrapper}>
                  <CoverIcon
                    className={classes.icon}
                    onClick={this.makeCover.bind(this)}
                    data-testid='mppf-cover'
                  />
                </div>
              </Tooltip>
              }
              {permissions.canDelete &&
              <Tooltip
                title={'Delete'}
                TransitionComponent={Zoom}
                classes={{
                  tooltip: classes.tooltip
                }}>
                <div className={classes.iconWrapper}>
                  <TrashIcon
                    className={classes.icon}
                    onClick={onFileDelete}
                    data-testid='mppf-delete'
                  />
                </div>
              </Tooltip>
              }
            </div>
            }
          </div>
          <div className={classNames(classes.contentRow, '_file')}>
            {/* nav */}
            {files.length > 1 &&
            <div className={classes.changeFileCell}>
              <BackArrow
                onClick={() => onChangeFileIndex(-1)}
                data-testid='mppf-back-button'
                className={classNames(
                  classes.changeFileIcon,
                  { _disabled: fileIndex === 0 }
                )} />
            </div>
            }

            <div className={classes.fileCell}>
              {/* image */}
              {this.isImage() && isFileUploadComplete && isTranscodable &&
              <div
                ref={ref => { this.imageRef = ref }}
                className={classNames(
                  classes.projectFilePopupImage,
                  { _fullSize: !this.isRotatedOnSide() }
                )}
                style={{
                  backgroundImage: `url(${this.getSrc(true)})`,
                  ...additionalImageStyles,
                  ...getImageRotationCss(file.orientation)
                }}
              />
              }

              {/* video */}
              {this.isVideo() && isFileUploadComplete && isTranscodable &&
              <VideoPlayerPreview { ...this.getVideoOptions() } />
              }
              {/* audio */}
              {this.isAudio() && isFileUploadComplete && isTranscodable &&
              <VideoPlayerPreview { ...this.getAudioOptions() } mediaType="audio" />
              }

              {/* file statuses */}
              {(!isFileUploadComplete || !isTranscodable) &&
              <div className={classes.fileStatusBackground}>
                <div className={classes.fileStatus}>
                  {this.isImage() && <ImageLoading
                    className={classes.fileStatusIcon}
                    data-testid='mppf-image-loading'
                  />}
                  {this.isVideo() && <VideoLoading
                    className={classes.fileStatusIcon}
                    data-testid='mppf-video-loading'
                  />}

                  {isFileInUploading &&
                  <div className={`${classes.fileStatusText} _uploading`}>Uploading File</div>
                  }
                  {isFileUploadFailed &&
                  <div className={`${classes.fileStatusText} _failed`}>Uploading Failed</div>
                  }
                  {!isTranscodable &&
                  <div className={`${classes.fileStatusText} _uploading`}>This file is too large to preview</div>
                  }
                </div>
              </div>
              }
            </div>

            {/* nav */}
            {files.length > 1 &&
            <div className={classes.changeFileCell}>
              <BackArrow
                onClick={() => onChangeFileIndex(1)}
                data-testid='mppf-forward-button'
                className={classNames(
                  classes.changeFileIcon,
                  '_next',
                  { _disabled: fileIndex === files.length - 1 }
                )} />
            </div>
            }
          </div>

          <div className={classNames(classes.contentRow, '_notes')}>
            {permissions.canSeeNotes &&
            <Accordion
              classes={{ root: classes.notesBlock }}
              onChange={toggleNoteExpand}
              expanded={fileNotesState}
            >
              <AccordionSummary
                expandIcon={<ArrowRight className={classes.expandIcon} />}
                classes={{ content: classes.notesSummaryContent }}
              >
                File notes
              </AccordionSummary>
              <AccordionDetails className={classes.notesDetails}>
                <FormControl
                  variant={'outlined'}
                  className={classes.notesForm}>
                  <TextField
                    multiline
                    rowsMax={5}
                    fullWidth
                    value={description}
                    placeholder={'Any notes you would like to add?'}
                    onChange={this.onNotesChange.bind(this)}
                    onBlur={this.onNotesBlur.bind(this)}
                    className={classes.cssUnderline}
                    disabled={!permissions.canEditNotes}
                    InputProps={{
                      classes: {
                        input: classes.notesTextField,
                        underline: classNames(
                          classes.notesUnderline
                        )
                      }
                    }}
                  />
                </FormControl>
                <div className={classes.notesInfoLabel}>
                  Notes are not mandatory but may help the producer better understand the content
                </div>
              </AccordionDetails>
            </Accordion>
            }
          </div>

        </DialogContent>
      </Dialog>
    )
  }
}

ModalPreviewProjectFile.propTypes = {
  classes: PropTypes.object.isRequired,
  clientDownloadFunction: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
  fileNotesState: PropTypes.bool,
  files: PropTypes.array.isRequired,
  isCurated: PropTypes.bool,
  onChangeFileIndex: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onFileDelete: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  saveFavoriteStatus: PropTypes.func,
  saveProducerFavoriteStatus: PropTypes.func,
  setFileError: PropTypes.func.isRequired,
  toggleNoteExpand: PropTypes.func,
  updateFileFunction: PropTypes.func.isRequired,
  updateLocalFileFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clientDownloadFunction: function (fileData, needAuth = false) {
      return dispatch(downloadFile(fileData, needAuth))
    },
    updateFileFunction: function (file, projectId) {
      return dispatch(updateFile(file, projectId))
    },
    updateLocalFileFunction: function (file) {
      return dispatch(updateLocalFile(file))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ModalPreviewProjectFile))
