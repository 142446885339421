import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import quoteSilverSea from '../../../assets/img/quoteSilverSea.svg'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  container: {
    margin: '60px 0px'
  },
  headerBackground: {
    zIndex: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      width: 'calc(100vw - 4px)',
      left: '50%',
      marginLeft: 'calc(-50vw - 5px)',
      marginTop: -16,
      backgroundColor: '#D9D9D9',
      height: 260,
      ...commonStyles.media(991, {
        height: 209
      }),
      ...commonStyles.media(767, {
        height: 233
      }),
      ...commonStyles.media(575, {
        height: 255
      }),
      ...commonStyles.media(450, {
        height: 277
      })
    },
    position: 'relative',
    width: '100%',
    padding: '16px 0px'
  },
  header: {
    color: '#4D4E51'
  },
  title: {
    margin: 0,
    padding: 0,
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 600,
    ...commonStyles.media(991, {
      fontSize: 24
    })
  },
  quote: {
    fontSize: 25,
    fontWeight: 400,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 100,
      height: 100,
      background: `url(${quoteSilverSea})`,
      backgroundRepeat: 'no-repeat',
      top: 0,
      left: -40,
      transform: 'rotate(180deg)'
    },
    ...commonStyles.media(991, {
      fontSize: 18
    })
  },
  name: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'right'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#060707',
    fontSize: 20,
    fontWeight: 400,
    marginTop: 24,
    '& ul': {
      margin: 0,
      paddingLeft: 10
    },
    '& ul > li': {
      padding: '0px',
      listStyle: 'none'
    },
    ...commonStyles.media(991, {
      fontSize: 16
    }),
    ...commonStyles.media(767, {
      flexDirection: 'column'
    })
  },
  info: {
    marginLeft: 24,
    maxWidth: 600,
    ...commonStyles.media(991, {
      maxWidth: 400
    })
  },
  mainInfo: {
    fontSize: 22,
    fontWeight: 500,
    ...commonStyles.media(991, {
      fontSize: 18
    })
  },
  videoContainer: {
    maxWidth: 850
  },
  videoSubtitle: {
    position: 'relative',
    zIndex: 2,
    marginTop: -5,
    padding: '10px 0px',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: -0.44,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#231F20',
    ...commonStyles.media(991, {
      fontSize: 14
    }),
    ...commonStyles.media(767, {
      fontSize: 12
    }),
    ...commonStyles.media(575, {
      fontSize: 10
    }),
    ...commonStyles.media(450, {
      fontSize: 8
    })
  },
  thumbnail: {
    width: '100%'
  }
})
/* eslint-enable sort-keys */

const Experience = ({ thumbnail, video, videoSubtitle }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.headerBackground}>
        <div className={classes.header}>
          <h2 className={classes.title}>GUEST EXPERIENCE</h2>
          <p className={classes.quote}>Thanks to urlife… the overall show is really great - professional,
            polished, great pacing! And tells a story. You all have more than delivered on your promise of
            a wonderful keepsake. It’s spectacular.</p>
          <p className={classes.name}>- Joan F.</p>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.video}>
          <img className={classes.thumbnail} src={thumbnail}/>
        </div>
        <div className={classes.info}>
          <p className={classes.mainInfo}>{`Created using the photos and videos you've captured,
            this personal mini-movie will tell the story of your
            entire expedition experience`}</p>
          <p>
            Video editing services include:
            <ul>
              <li>• Producer call to discuss all of your favorite moments</li>
              <li>• Organizing, editing and enhancing content</li>
              <li>• Custom music selections, audio mixing, soundbites</li>
              <li>• Motion graphics and visual effects</li>
              <li>• Round of revisions</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  )
}

Experience.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  videoSubtitle: PropTypes.string
}

export default Experience
