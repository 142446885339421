import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'
import { toggleModal } from '../../actions'
import { ONLY_MARKETING } from '../../constants'
import UrlifeButton from '../common/UrlifeButton'

/* eslint-disable sort-keys */
const styles = {
  button: {
    width: 210,
    height: 46,
    ...commonStyles.media(480, {
      width: 181,
      height: 40,
      fontSize: 12
    })
  }
}
/* eslint-enable sort-keys */

class MarketingPageCTA extends Component {
  openInviteDialog () {
    const { dispatch } = this.props
    dispatch(toggleModal(true, 'inviteComingSoon'))
  }

  render () {
    const { classes, children } = this.props
    return (
      ONLY_MARKETING
        ? (
        <UrlifeButton
          className={`${classes.button} ${this.props.className}`}
          onClick={this.openInviteDialog.bind(this)}
        >
          Request Invite
        </UrlifeButton>
          )
        : (
            children
          )
    )
  }
}

MarketingPageCTA.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(connect()(MarketingPageCTA))
