const topBar = (state = {
  header: '',
  moreMenuActions: {},
  step: 0,
  stepHeader: '',
  totalSteps: 0,
  type: 'normal'
}, action) => {
  switch (action.type) {
    case 'SET_TOP_BAR_HEADERS':
      return {
        ...state,
        header: action.header,
        stepHeader: action.stepHeader
      }
    case 'SET_TOP_BAR_STEPS':
      return {
        ...state,
        step: action.step,
        totalSteps: action.totalSteps
      }
    case 'SET_TOP_BAR_TYPE':
      return {
        ...state,
        type: action.topBarType
      }
    case 'SET_MORE_MENU_ACTION':
      return {
        ...state,
        moreMenuActions: {
          ...state.moreMenuActions,
          [action.name]: action.func
        }
      }
    case 'CLEAR_MORE_MENU_ACTIONS':
      return {
        ...state,
        moreMenuActions: {}
      }
    default:
      return state
  }
}

export default topBar
