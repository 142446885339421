import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  commonStyles,
  getLeastCompleteProjectSku,
  getPackageType,
  history,
  mapProjectStatusToStage,
  toLocale
} from '../../../helpers'
import { Link, Route, Switch } from 'react-router-dom'
import ProjectDetailsBox from '../../common/ProjectDetailsBox'
import EditUploadFiles from './EditUploadFiles'
import EditStars from './EditStars'
import ProjectStatusBar from '../../common/ProjectStatusBar/ProjectStatusBar'
import ProjectSidebar from './ProjectSidebar'
import UrlifeNavLink from '../../common/UrlifeNavLink'
import {
  FILE_STATUSES,
  PACKAGE_TYPES,
  PROJECT_STAGES,
  PROJECT_STATUSES_NAME,
  ROLES
} from '../../../constants'
import styled from 'styled-components'
import { ProjectPlaceholder } from '../../common/SVG/ProjectPlaceholder'
import { getImageRotationPureCss } from '../../../helpers/imageRotationCss'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import { setTopBarType, userInfoById } from '../../../actions'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import ProjectChat from '../../common/chat/ProjectChat'
import ProjectNotes from './ProjectNotes'

/* eslint-disable sort-keys */
const styles = {
  projectPageContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    ...commonStyles.media(991, {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    })
  },
  projectContainer: {
    width: 'calc(66.666% - 60px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(991, {
      width: '100%',
      marginTop: 50
    })
  },
  projectHeaderBox: {
    position: 'relative',
    width: '100%',
    height: 156,
    backgroundColor: '#ffffff',
    borderRadius: 2,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.07)',
    '&._appointment': {
      height: 228
    }
  },
  projectPlaceholder: {
    position: 'absolute',
    left: 20,
    top: 20,
    bottom: 20,
    width: 116,
    height: 116,
    color: '#dde3e8',
    fontSize: 107
  },
  projectInfoContainer: {
    position: 'absolute',
    left: 136,
    top: 0,
    right: 0,
    bottom: 0,
    padding: '30px 25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&._appointment': {
      paddingBottom: 10
    }
  },
  projectStatus: {
    fontSize: 12,
    fontWeight: 600,
    color: '#ffffff',
    lineHeight: '15px',
    padding: '5px 14px',
    backgroundColor: '#01b7d7',
    borderRadius: 15,
    marginRight: 20
  },
  projectInfo: {
    fontSize: 15,
    fontWeight: 500,
    color: '#4a4a4a',
    opacity: 0.8,
    marginRight: 20,
    lineHeight: '24px',
    '&._link': {
      color: '#01b7d7',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  projectTitle: {
    fontSize: 28,
    fontWeight: 500,
    color: '#393939'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: 'calc(100% + 16px)',
    marginLeft: -8,
    marginTop: 50,
    marginBottom: 30
  },
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.333%',
    minWidth: 300,
    maxWidth: 369,
    marginLeft: 60,
    ...commonStyles.media(991, {
      width: '100%',
      marginLeft: 0
    })
  },
  statusWrapper: {
    marginBottom: 16
  }
}
/* eslint-enable sort-keys */

const CoverImage = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  background-size: cover;
  background-position: center center;
  width: 116px;
  height: 116px;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  ${props => getImageRotationPureCss(props.rotation)}
`

class ProjectPageProducer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      customer: ''
    }

    this.isAdmin = props.roleId === ROLES.ADMIN
    this.isProducer = props.roleId === ROLES.PRODUCER
    this.isEditor = props.roleId === ROLES.EDITOR
    this.categories = [
      {
        menuName: 'Summary',
        to: 'summary'
      },
      {
        menuName: 'Notes',
        to: 'notes'
      },
      {
        additionalName: this.isAdmin ? ' ( :finishedUpload / :totalUpload )' : '',
        menuName: 'Files',
        to: 'files'
      },
      {
        menuName: 'Featured',
        to: 'featured'
      },
      ((!this.isEditor) &&
      {
        additionalName: this.isAdmin ? ' ( :customerMessagesCount )' : '',
        menuName: this.isAdmin || this.isProducer ? 'Customer msg' : 'Messages',
        to: 'customermessages'
      }
      ),
      ((this.isAdmin || this.isProducer || this.isEditor) &&
      {
        additionalName: this.isAdmin ? ' ( :editorMessagesCount )' : '',
        menuName: this.isAdmin || this.isProducer ? 'Editor msg' : 'Messages',
        to: 'editormessages'
      }
      )
    ].filter(item => !!item)
  }

  componentDidMount () {
    const {
      userInfoByIdFunction,
      project,
      setTopBarTypeFunction
    } = this.props

    if (this.isProducer || this.isAdmin) {
      userInfoByIdFunction(project.customerId)
        .then(response => {
          this.setState({ customer: response.data })
        })
    }
    setTopBarTypeFunction('normal')
  }

  getActiveName () {
    const { location } = this.props
    const lastPath = location.pathname.split('/')
    const toTest = lastPath[lastPath.length - 1]
    for (const cat of this.categories) {
      if (cat.to === toTest) {
        return cat.menuName
      }
    }
    return this.categories[0].menuName
  }

  getAdditionalName (additionalName) {
    const { project } = this.props
    if (!additionalName || !project) return ''

    if (project) {
      const projectReplace = {
        customerMessagesCount: project.messageCounts.customer,
        editorMessagesCount: project.messageCounts.editor,
        finishedUpload: project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE],
        totalUpload: project.fileCounts[FILE_STATUSES.CLIENT_UPLOADING] +
          project.fileCounts[FILE_STATUSES.UPLOAD_FAILED] +
          project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE]
      }
      for (const projectReplaceName in projectReplace) {
        additionalName = additionalName.replace(`:${projectReplaceName}`, projectReplace[projectReplaceName])
      }
    }
    return additionalName
  }

  getFormatedDate (date) {
    const stringToDate = new Date(date)
    return toLocale(stringToDate, { full: true })
  }

  getFormatedPhoneNumber (number) {
    const phoneNumber = parsePhoneNumberFromString(number)
    return phoneNumber ? phoneNumber.formatInternational() : number
  }

  scheduleCall (e) {
    const { project } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    e.preventDefault()
    history.push(
      `/projects/${project.id}/skus/${projectSku.id}/movie-schedule-call`,
      { type: 'projectStatusBar' }
    )
  }

  render () {
    const {
      classes,
      match,
      project,
      user,
      packages,
      updateProject,
      updateProjectSku
    } = this.props
    const { customer } = this.state
    const projectDate = project.summaryDate || toLocale(new Date(project.createdAt), { forMyMovie: true })
    const projectSku = getLeastCompleteProjectSku(project)
    const statusId = projectSku && projectSku.statusId
    const stage = mapProjectStatusToStage(statusId)
    const isAdmin = user && user.roleId === ROLES.ADMIN
    const isProducer = user && user.roleId === ROLES.PRODUCER
    const hasAssignedProducer = !!(projectSku && projectSku.producerId)
    const isAssignedProducer = user && projectSku && projectSku.producerId === user.userid
    const isAllowedProducer = ((hasAssignedProducer && isAssignedProducer) || (!hasAssignedProducer && isProducer))
    const isHollywoodMovie = projectSku && getPackageType(packages, projectSku.packageId) === PACKAGE_TYPES.HOLLYWOOD
    const appointment = projectSku && projectSku.appointment
    const showAppointment = isHollywoodMovie &&
      (isAdmin || isAllowedProducer) &&
      stage !== PROJECT_STAGES.COMPLETE
    return (
      <div className={classes.projectPageContainer}>
        <div className={classes.projectContainer}>
          <div className={classNames(classes.projectHeaderBox, { _appointment: showAppointment })}>
            {project.coverUrl &&
            <CoverImage
              thumbnail={cacheSignedUrl.get(project.coverUrl)}
              rotation={project.coverFileOrientation} />
            }
            {!project.coverUrl &&
            <ProjectPlaceholder className={classes.projectPlaceholder} fill={'#dde3e8'} />
            }
            <div className={classNames(classes.projectInfoContainer, { _appointment: showAppointment })}>
              <div>
                <span className={classes.projectStatus}>
                  {'Movie ' + PROJECT_STATUSES_NAME[statusId].toLocaleLowerCase()}
                </span>
                <span className={classes.projectInfo}>{projectDate}</span>
              </div>
              <div>
                <span className={classes.projectTitle}>{project.title}</span>
              </div>
              <div>
                <span className={classes.projectInfo}>
                  {isAdmin && <Link to={`/admin/users/${customer.id}`}>{customer.fullname}</Link>}
                  {!isAdmin && customer.fullname}
                </span>
                <span className={classes.projectInfo}>
                  {isAdmin && <Link to={`/admin/users/${customer.id}`}>{customer.email}</Link>}
                  {!isAdmin && customer.email}
                </span>
              </div>
              {showAppointment &&
              <div>
                <div className={classes.projectInfo}>Appointment information:</div>
                {appointment && <React.Fragment>
                  <div className={classes.projectInfo}>
                    {'Date: ' + this.getFormatedDate(appointment)}
                  </div>
                  <div className={classes.projectInfo}>
                    {projectSku.phoneNumber
                      ? 'Phone number: ' + this.getFormatedPhoneNumber(projectSku.phoneNumber)
                      : 'Customer has not added a phone number'}
                  </div>
                </React.Fragment>}
                {!appointment && <React.Fragment>
                  <div className={classes.projectInfo}>No appointment scheduled</div>
                </React.Fragment>}
                <div
                  className={classNames(classes.projectInfo, '_link')}
                  onClick={this.scheduleCall.bind(this)}
                >
                  {appointment
                    ? 'Change appointment'
                    : 'Schedule appointment'
                  }
                </div>
              </div>
              }
            </div>
          </div>
          <div className={classes.tabsContainer}>
            {this.categories.map((category, idx) => {
              return (
                <UrlifeNavLink
                  key={idx}
                  to={`${match.url}/${category.to}`}
                  label={category.menuName + this.getAdditionalName(category.additionalName)}
                />
              )
            })}
          </div>
          <Switch>
            <Route exact path={`${match.path}/summary`}
              render={() => {
                return (<React.Fragment>
                  <ProjectDetailsBox project={project} show={['producer-info']} canEdit={true} />
                  <ProjectDetailsBox project={project} show={['prompts']} />
                </React.Fragment>)
              }} />
            <Route exact path={`${match.path}/notes`}
              render={() => <ProjectNotes projectId={project.id} />} />
            <Route exact path={`${match.path}/files`}
              render={() => <EditUploadFiles project={project} updateProject={updateProject} />} />
            <Route exact path={`${match.path}/featured`}
              render={() => <EditStars projectId={project.id} />} />
            <Route exact path={`${match.path}/customermessages`}
              render={() => <ProjectChat project={project} view={'tab'} thread={'customer'} />} />
            <Route exact path={`${match.path}/editormessages`}
              render={() => <ProjectChat project={project} view={'tab'} thread={'editor'} />} />
          </Switch>
        </div>
        <div className={classes.sidebarContainer}>
          <div className={classes.statusWrapper}>
            <ProjectStatusBar project={project} type={'standalone-resizable'} />
          </div>
          <ProjectSidebar
            project={project}
            updateProject={updateProject}
            updateProjectSku={updateProjectSku}
          />
        </div>
      </div>
    )
  }
}

ProjectPageProducer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  packages: PropTypes.array,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  setTopBarTypeFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired,
  user: PropTypes.object,
  userInfoByIdFunction: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages,
    roleId: state.user.roleId,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarTypeFunction: function (type) {
      dispatch(setTopBarType(type))
    },
    userInfoByIdFunction: function (userId) {
      return dispatch(userInfoById(userId))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectPageProducer))
