import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function StarFilled (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 19.35 19.35">
      <g><g transform="translate(0, -1.5)"><path d="M10.42,0.3844v0l3.5961,5.10418v0l6.1039,1.8119v0l-3.82882,4.89791v0l0.12357,6.29269v0l-5.99475,-2.08917v0l-5.99475,2.08917v0l0.14803,-6.16534v0l-3.85328,-5.02525v0l6.10142,-1.8119v0z"></path></g></g>
    </SvgIcon>
  )
}
