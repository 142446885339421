import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CardViewIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><path d="M23.068 15.817H1.653v7.248h21.415zm1.736-.868v8.984c0 .48-.389.868-.868.868H.785a.868.868 0 0 1-.868-.868v-8.984c0-.48.388-.868.868-.868h23.15c.48 0 .869.388.869.868zM23.068 2.427v-.5H1.653v7.249h21.415V5.031a.868.868 0 1 1 1.736 0v5.013c0 .48-.389.868-.868.868H.785a.868.868 0 0 1-.868-.868V1.06c0-.48.388-.868.868-.868h23.15c.48 0 .869.388.869.868v1.367a.868.868 0 1 1-1.736 0z" /></g></g>
    </SvgIcon>
  )
}
