import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import ModalVideo from './ModalVideo'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  title: {
    marginTop: 0,
    color: '#55565A',
    fontSize: 36,
    fontWeight: 600,
    ...commonStyles.media(767, {
      fontSize: 24
    }),
    ...commonStyles.media(575, {
      fontSize: 18
    })
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 60,
    color: '#55565A',
    ...commonStyles.media(991, {
      display: 'block'
    })
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    ...commonStyles.media(991, {
      marginBottom: 20
    }),
    ...commonStyles.media(767, {
      display: 'block'
    })
  },
  shotListWrapper: {
    display: 'block',
    textAlign: 'center'
  },
  shotList: {
    maxWidth: 267,
    height: 350,
    width: 'auto',
    ...commonStyles.media(767, {
      maxWidth: 170,
      height: 220
    })
  },
  listInfo: {
    maxWidth: 267,
    padding: '24px',
    ...commonStyles.media(767, {
      margin: 'auto'
    })
  },
  listTitle: {
    minWidth: 220,
    fontSize: 16,
    fontWeight: 700,
    ...commonStyles.media(575, {
      fontSize: 14
    })
  },
  listText: {
    fontSize: 16,
    fontWeight: 400,
    ...commonStyles.media(575, {
      fontSize: 14
    })
  },
  listLink: {
    color: '#55565A',
    margin: 'auto',
    fontSize: 18,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(575, {
      fontSize: 16
    })
  },
  videoContainer: {
    maxWidth: 850
  },
  videoSubtitle: {
    position: 'relative',
    zIndex: 2,
    marginTop: -5,
    padding: '10px 0px',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: -0.44,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#231F20',
    ...commonStyles.media(991, {
      fontSize: 14
    }),
    ...commonStyles.media(767, {
      fontSize: 12
    }),
    ...commonStyles.media(575, {
      fontSize: 10
    }),
    ...commonStyles.media(450, {
      fontSize: 8
    })
  }
})
/* eslint-enable sort-keys */

const TipsVideoAndShotList = ({
  linkLinkText,
  listIconSrc,
  listSrc,
  listText,
  listTitle,
  thumbnail,
  title,
  video,
  videoSubtitle
}) => {
  const classes = useStyles()
  return (
    <>
      {title && <h1 className={classes.title}>{title}</h1>}
      <div className={classes.container}>
        {listSrc && listIconSrc && linkLinkText && <div className={classes.listContainer}>
          <a target="_blank" rel="noreferrer" href={listSrc} className={classes.shotListWrapper}>
            <img className={classes.shotList} src={listIconSrc} alt='SHOT LIST'/>
          </a>
          <div className={classes.listInfo}>
            {listTitle && <h2 className={classes.listTitle}>{listTitle}</h2>}
            {listText && <p className={classes.listText}>{listText}</p>}
            <a className={classes.listLink} target="_blank" rel="noreferrer" href={listSrc}>{linkLinkText}</a>
          </div>
        </div>}
        {video && thumbnail && <div className={classes.videoContainer}>
          <ModalVideo
            thumbnail={thumbnail}
            video={video}
          />
          {videoSubtitle && <div className={classes.videoSubtitle} >
            {videoSubtitle}
          </div>}
        </div>}
      </div>
    </>
  )
}

TipsVideoAndShotList.propTypes = {
  linkLinkText: PropTypes.string,
  listIconSrc: PropTypes.string,
  listSrc: PropTypes.string,
  listText: PropTypes.string,
  listTitle: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.string,
  videoSubtitle: PropTypes.string
}

export default TipsVideoAndShotList
