import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import classNames from 'classnames'
import LinearProgress from '@material-ui/core/LinearProgress'
import { DEBUG_LEVEL } from '../../../../constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import {
  history,
  humanFileSize
} from '../../../../helpers'
import {
  fileProgressStatus,
  fileProgressType
} from '../../../../reducers/fileProgress'
import {
  clearFileProcess,
  clearUpload
} from '../../../../actions'

const StyledCircularProgress = styled(
  ({ strokeColor, ...otherProps }) => <CircularProgress {...otherProps} />
)(props => ({
  color: props.strokeColor
}))

/* eslint-disable sort-keys */
const styles = {
  fileProgressContainer: {
    position: 'relative',
    width: '100%',
    '&._large': {
      height: 59
    },
    '&._medium': {
      height: 57
    },
    '&._small': {
      height: 57
    }
  },
  progressCircle: {
    position: 'absolute',
    left: 15,
    top: 15
  },
  progressCircleIcon: {
    position: 'absolute',
    left: 20,
    top: 21,
    width: 14,
    height: 14
  },
  progressInfoTextContainer: {
    position: 'absolute',
    left: 55,
    top: 9,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '&._fullWidth': {
      left: 15,
      width: 'calc(100% - 30px)'
    },
    '&._center': {
      left: 200
    },
    '&._rightSide': {
      left: 'auto',
      right: 15,
      justifyContent: 'flex-end',
      textAlign: 'right'
    }
  },
  progressInfoText: {
    color: '#323232',
    '&._large': {
      fontSize: 14
    },
    '&._medium': {
      fontSize: 13
    },
    '&._small': {
      fontSize: 13
    },
    '&._extra': {
      paddingTop: 2,
      color: '#222222',
      opacity: 0.5,
      '&._large': {
        fontSize: 13,
        letterSpacing: '0.85px'
      },
      '&._medium': {
        fontSize: 11,
        letterSpacing: '0.76px'
      },
      '&._small': {
        fontSize: 11,
        letterSpacing: '0.76px'
      }
    }
  }
}
/* eslint-enable sort-keys */

class FileProgress extends React.Component {
  constructor (props) {
    super(props)
    this.timeOutClearUploadId = null
    this.timeOutClearProcessId = null
    this.timeStartProgress = null
    this.lastInterval = Date.now()
    this.speedList = []
    this.progress = {
      failedFileCount: 0,
      finishedFileCount: 0,
      finishedPercent: 0,
      speed: 0,
      timeRemaining: 0,
      totalFileCount: 0
    }
  }

  componentDidUpdate (prevProps) {
    const { fileProgress, project, clearUploadFunction, fileBatch, clearFileProcessFunction } = this.props
    if (DEBUG_LEVEL === 'upload' && project) {
      const uploads = fileProgress.filter(file => {
        if (project.id === 'admin') {
          return (file.role === 'system' && file.progressType === fileProgressType.upload)
        } else {
          return (file.projectId === project.id && file.progressType === fileProgressType.upload)
        }
      })
      const prevUploads = prevProps.fileProgress.filter(file => {
        if (project.id === 'admin') {
          return (file.role === 'system' && file.progressType === fileProgressType.upload)
        } else {
          return (file.projectId === project.id && file.progressType === fileProgressType.upload)
        }
      })
      if (uploads.length < 1 && prevUploads.length > 0) {
        /* eslint-disable-next-line no-console */
        console.debug('COMPLETED UPLOADING FILES: ' +
          (Date.now() - window.sessionStorage.getItem('debug_time_start')))
      }
    }

    const projectFileBatch = project ? fileBatch[project.id] : null
    const isProcessing = projectFileBatch && projectFileBatch.totalCount !== 0
    const processingDone = isProcessing && projectFileBatch.completedCount === projectFileBatch.totalCount

    if (!isProcessing || processingDone) {
      const inProgressFiles = fileProgress.filter(file => file.status !== fileProgressStatus.complete)
      if (inProgressFiles.length === 0) {
        if (!this.timeOutClearUploadId) {
          this.timeOutClearUploadId = window.setTimeout(
            () => {
              this.timeOutClearUploadId = null
              fileProgress.length && clearUploadFunction()
            },
            2000
          )
        }
      }
    }

    if (processingDone) {
      if (!this.timeOutClearProcessId) {
        this.timeOutClearProcessId = window.setTimeout(
          () => {
            this.timeOutClearProcessId = null
            const projectFileBatch = project ? fileBatch[project.id] : null
            if (projectFileBatch && projectFileBatch.completedCount === projectFileBatch.totalCount) {
              clearFileProcessFunction(project.id)
            }
          },
          500
        )
      }
    }
  }

  getFiles (progressType) {
    const { fileProgress, project } = this.props
    const files = fileProgress.filter(file => {
      if (project.id === 'admin') {
        return (file.role === 'system' && file.progressType === progressType)
      } else {
        return (file.projectId === project.id && file.progressType === progressType)
      }
    })
    return files
  }

  calculateProgress (files, force = false) {
    const now = Date.now()
    if ((now - this.lastInterval >= 1000 && files && files.length > 0) || force) {
      let finishedFileCount = 0
      let failedFileCount = 0
      let totalSize = 0
      let finishedSize = 0
      let speed = 0
      this.lastInterval = now
      const speedList = this.speedList
      let timeRemaining = 0

      files.forEach(file => {
        finishedFileCount += file.status === fileProgressStatus.complete ? 1 : 0
        failedFileCount += file.status === fileProgressStatus.failed ? 1 : 0
        finishedSize += file.loaded || 0
        totalSize += file.total || 0
      })

      if (!this.timeStartProgress) {
        this.timeStartProgress = now
        this.initialLoaded = finishedSize
      }
      if (finishedSize - this.initialLoaded > 0) {
        const loaded = finishedSize - this.initialLoaded
        const total = totalSize - this.initialLoaded
        const timeElapsed = now - this.timeStartProgress
        speed = loaded / (timeElapsed / 1000)
        if (speedList.length > 8) speedList.pop()
        speedList.unshift(speed)
        speedList.forEach(s => { speed += s })
        speed = speed / speedList.length
        this.speedList = speedList
        timeRemaining = (total - loaded) / speed
      }

      this.progress = {
        failedFileCount: failedFileCount,
        finishedFileCount: finishedFileCount,
        finishedPercent: totalSize ? Math.round(finishedSize / totalSize * 100 * 100) / 100 : 0,
        speed: speed,
        timeRemaining: timeRemaining,
        totalFileCount: files.length
      }
    }
    return this.progress
  }

  render () {
    const {
      classes,
      project,
      sizeClass,
      skinData,
      fileBatch
    } = this.props

    const projectFileBatch = project ? fileBatch[project.id] : null
    const isProcessing = projectFileBatch && (
      projectFileBatch.totalCount !== 0 ||
      projectFileBatch.completedCount < projectFileBatch.totalCount
    )

    const downloadingFiles = this.getFiles(fileProgressType.download)
    const uploadingFiles = this.getFiles(fileProgressType.upload)
    const generatingZipFile = this.getFiles(fileProgressType.generateZip)
    const isDownloading = downloadingFiles.length > 0
    const isUploading = uploadingFiles.length > 0
    const isGeneratingZip = generatingZipFile.length > 0
    const isQueuedForZipping = isGeneratingZip && (
      generatingZipFile[0].status === fileProgressStatus.inQueue ||
      generatingZipFile[0].status === fileProgressStatus.toProgress
    )
    const isActive = isUploading || isDownloading || isGeneratingZip
    const progress = isUploading
      ? this.calculateProgress(uploadingFiles)
      : isDownloading
        ? this.calculateProgress(downloadingFiles)
        : this.calculateProgress(generatingZipFile, isGeneratingZip)
    const minutesRemaining = Math.round(progress.timeRemaining / 60)

    let type = ''
    let progressInfo = ''
    if (isProcessing) {
      type = 'processing'
      progressInfo = projectFileBatch.completedCount + '/' + projectFileBatch.totalCount +
        ' file' + (projectFileBatch.totalCount !== 1 ? 's' : '')
    } else if (isActive) {
      type = 'active'
      progressInfo = progress.finishedFileCount + '/' + progress.totalFileCount +
        ' file' + (progress.totalFileCount !== 1 ? 's' : '')
      if (isQueuedForZipping) {
        progressInfo = 'In queue'
      }
      if (progress.failedFileCount > 0 &&
        progress.failedFileCount + progress.finishedFileCount === progress.totalFileCount) {
        type = 'failed'
        progressInfo = 'We had some issues ' +
          (isUploading
            ? 'uploading to'
            : isDownloading
              ? 'downloading from'
              : 'generating the zip on'
          ) +
          ' the server.'
      }
    }

    const isCreationFlow = history.location.state &&
      history.location.state.type &&
      history.location.state.type === 'creationFlow'
    const skinned = isCreationFlow && !!skinData
    const circularProgressColor = skinned ? skinData.mainColor : '#01b7d7'

    return (
      <div className={classNames(classes.fileProgressContainer, sizeClass)}>
        {type === 'processing' && <div className={classNames(classes.progressInfoTextContainer, '_fullWidth')}>
          <div className={classNames(classes.progressInfoText, sizeClass)}>
            Processing...
          </div>
          <div className={classNames(classes.progressInfoText, '_extra', sizeClass)}>
            {progressInfo}
          </div>
          <div className={classNames(classes.progressInfoText, '_extra', sizeClass)}>
            <LinearProgress
              variant="determinate"
              value={(projectFileBatch.completedCount / projectFileBatch.totalCount) * 100}
              data-testid='processing-progress'
            />
          </div>
        </div>}
        {type === 'active' && <React.Fragment>
          <StyledCircularProgress
            strokeColor={circularProgressColor}
            variant={isQueuedForZipping ? 'indeterminate' : 'determinate'}
            color="inherit"
            className={classes.progressCircle}
            value={progress.finishedPercent}
            size={25}
            data-testid='active-progress'
          />
          <div className={classes.progressInfoTextContainer}>
            <div className={classNames(classes.progressInfoText, sizeClass)}>
              {isUploading
                ? 'Uploading files'
                : isDownloading
                  ? 'Downloading files'
                  : 'Generating zip'
              }
            </div>
            <div className={classNames(classes.progressInfoText, '_extra', sizeClass)}>
              {progressInfo}
            </div>
          </div>
          {!isQueuedForZipping && <div className={classNames(classes.progressInfoTextContainer, '_rightSide')}>
            <div className={classNames(classes.progressInfoText, sizeClass)}>
              {
                (progress.timeRemaining === 0
                  ? '--'
                  : minutesRemaining === 0
                    ? '< 1 min'
                    : (minutesRemaining + ' min' + (minutesRemaining !== 1 ? 's' : ''))) +
                (sizeClass === '_small' ? '' : ' remaining')
              }
            </div>
            <div className={classNames(classes.progressInfoText, '_extra', sizeClass)}>
              {humanFileSize(progress.speed, true) + '/s'}
            </div>
          </div>}
        </React.Fragment>}
        {type === 'failed' && <div className={classes.progressInfoTextContainer}>
          <div className={classNames(classes.progressInfoText, sizeClass)}>
            Please try again
          </div>
          <div className={classNames(classes.progressInfoText, '_extra', sizeClass)}>
            {progressInfo}
          </div>
        </div>}
      </div>
    )
  }
}

FileProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  clearFileProcessFunction: PropTypes.func.isRequired,
  clearUploadFunction: PropTypes.func.isRequired,
  fileBatch: PropTypes.object,
  fileProgress: PropTypes.array,
  project: PropTypes.object.isRequired,
  sizeClass: PropTypes.oneOf(['_small', '_medium', '_large']).isRequired,
  skinData: PropTypes.object
}

const mapStateToProps = state => {
  return {
    fileBatch: state.fileBatch,
    fileProgress: Object.values(state.fileProgress),
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearFileProcessFunction (projectId) {
      dispatch(clearFileProcess(projectId))
    },
    clearUploadFunction: function () {
      dispatch(clearUpload())
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FileProgress))
