import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeTextField from '../../common/UrlifeTextField'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeConfirmDialog from '../../common/UrlifeConfirmDialog'
import {
  setUserInfo,
  toggleModal,
  verifyPassword
} from '../../../actions'

/* eslint-disable sort-keys */
const styles = {
  root: {
    marginTop: 15,
    maxWidth: 350
  },
  text: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  forgotText: {
    opacity: 0.6,
    color: '#666666',
    fontSize: 12,
    fontWeight: 500,
    cursor: 'pointer',
    marginTop: 10,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  button: {
    marginTop: 20,
    marginBottom: 10
  }
}
/* eslint-enable sort-keys */

const SET_PASSWORD_STAGES = {
  ENTER_NEW: 2,
  NOT_STARTED: 0,
  PASSWORD_UPDATED: 3,
  VERIFY_CURRENT: 1
}

class ChangePassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      stage: SET_PASSWORD_STAGES.NOT_STARTED,
      values: {}
    }
    this.timeoutId = null
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }
  }

  reset () {
    this.setState({
      error: '',
      stage: SET_PASSWORD_STAGES.NOT_STARTED,
      values: {}
    })
  }

  onFieldChange (e) {
    const { values } = this.state
    const value = e.target.value
    const name = e.target.name
    let error = ''
    if (name === 'new' || name === 'confirm') {
      const newPassword = name === 'new' ? value : values.new
      const confirmPassword = name === 'confirm' ? value : values.confirm
      if (newPassword && confirmPassword && newPassword !== confirmPassword) {
        error = '"New password" and "Confirm password" field values are different'
      }
    }
    this.setState({
      error,
      values: {
        ...values,
        [name]: value
      }
    })
  }

  async verifyPassword () {
    const { email, verifyPasswordFunction } = this.props
    const { values } = this.state
    const password = values.current
    if (!password) { return }
    const isValid = await verifyPasswordFunction(email, password)
    if (isValid) {
      this.timeoutId = window.setTimeout(this.reset.bind(this), 15 * 60 * 1000)
      this.setState({
        error: '',
        stage: SET_PASSWORD_STAGES.ENTER_NEW
      })
    } else {
      this.setState({ error: 'Wrong password' })
    }
  }

  async updatePassword () {
    const { setPasswordFunction } = this.props
    const { values } = this.state
    const password = values.new
    if (!password) { return }
    try {
      await setPasswordFunction(password)
      this.setState({
        error: '',
        stage: SET_PASSWORD_STAGES.PASSWORD_UPDATED
      })
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId)
      }
    } catch (e) {
      this.setState({ error: 'Failed to update password' })
    }
  }

  render () {
    const { classes, email, forgotPasswordFunction } = this.props
    const { stage, values, error } = this.state
    let newPasswordValid = false
    if (stage === SET_PASSWORD_STAGES.ENTER_NEW && values.new && values.confirm) {
      newPasswordValid = values.new === values.confirm
    }
    return (
      <React.Fragment>
        {email && <div className={classes.root}>
          {stage === SET_PASSWORD_STAGES.NOT_STARTED && <div
            className={classes.text}
            onClick={() => this.setState({ stage: SET_PASSWORD_STAGES.VERIFY_CURRENT })}
          >
            Change password
          </div>}
          {(stage === SET_PASSWORD_STAGES.VERIFY_CURRENT || stage === SET_PASSWORD_STAGES.ENTER_NEW) &&
          <UrlifeTextField
            id={'current'}
            label={'Current password'}
            name={'current'}
            type={'password'}
            autoFocus
            defaultValue={''}
            onChange={this.onFieldChange.bind(this)}
            disabled={stage !== SET_PASSWORD_STAGES.VERIFY_CURRENT}
            validationStatus={
              stage > SET_PASSWORD_STAGES.VERIFY_CURRENT
                ? 'success'
                : error
                  ? 'error'
                  : 'none'
            }
            infoLabel={stage === SET_PASSWORD_STAGES.VERIFY_CURRENT ? error : ''}
          />}
          {stage === SET_PASSWORD_STAGES.ENTER_NEW && <React.Fragment>
            <UrlifeTextField
              id={'new'}
              label={'New password'}
              name={'new'}
              type={'password'}
              autoFocus
              defaultValue={''}
              onChange={this.onFieldChange.bind(this)}
              validationStatus={
                error
                  ? 'error'
                  : newPasswordValid
                    ? 'success'
                    : 'none'
              }
            />
            <UrlifeTextField
              id={'confirm'}
              label={'Confirm password'}
              name={'confirm'}
              type={'password'}
              defaultValue={''}
              onChange={this.onFieldChange.bind(this)}
              validationStatus={
                error
                  ? 'error'
                  : newPasswordValid
                    ? 'success'
                    : 'none'
              }
              infoLabel={error}
            />
          </React.Fragment>}
          {stage === SET_PASSWORD_STAGES.VERIFY_CURRENT && <React.Fragment>
            <div
              className={classes.forgotText}
              onClick={forgotPasswordFunction}
            >
                Forgot password?
            </div>
            <UrlifeButton
              className={classes.button}
              onClick={this.verifyPassword.bind(this)}
              disabled={!values.current}
            >
              Verify password
            </UrlifeButton>
          </React.Fragment>}
          {stage === SET_PASSWORD_STAGES.ENTER_NEW &&
          <UrlifeButton
            className={classes.button}
            onClick={this.updatePassword.bind(this)}
            disabled={!newPasswordValid}
          >
            Update password
          </UrlifeButton>}
        </div>}
        <UrlifeConfirmDialog
          isOpen={stage === SET_PASSWORD_STAGES.PASSWORD_UPDATED}
          text={'Password updated'}
          onIgnore={this.reset.bind(this)}
          confirmLabel={'OK'}
          onConfirm={this.reset.bind(this)}
        />
      </React.Fragment>
    )
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string,
  forgotPasswordFunction: PropTypes.func.isRequired,
  setPasswordFunction: PropTypes.func.isRequired,
  verifyPasswordFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    email: state.user.email
  }
}

function mapDispatchToProps (dispatch) {
  return {
    forgotPasswordFunction: function () {
      return dispatch(toggleModal(true, 'forgotten'))
    },
    setPasswordFunction: function (password) {
      return dispatch(setUserInfo({ password }))
    },
    verifyPasswordFunction: function (email, password) {
      return dispatch(verifyPassword(email, password))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
