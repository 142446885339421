import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function BackArrow (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 22">
      <g><g transform="matrix(-1 0 0 1 25 0)"><g><g><path d="M3.787 10.084H.977c-.523 0-.947.41-.947.917 0 .506.424.916.947.916h2.81c.523 0 .947-.41.947-.916 0-.507-.424-.917-.947-.917zm21.178.896a.82.82 0 0 0-.06-.298l-.01-.033a.909.909 0 0 0-.081-.146l-.006-.01a.936.936 0 0 0-.12-.143L14.325.32a.968.968 0 0 0-1.34 0 .896.896 0 0 0 0 1.296l8.748 8.468H7.007c-.523 0-.947.41-.947.917 0 .506.424.916.947.916h14.728l-8.749 8.468a.895.895 0 0 0 0 1.296.96.96 0 0 0 .67.269c.242 0 .484-.09.67-.269L24.69 11.65l.023-.027a.914.914 0 0 0 .094-.11c.012-.019.021-.039.032-.057.02-.033.04-.066.055-.1.01-.024.015-.048.023-.07.011-.035.024-.067.032-.102a.909.909 0 0 0 .019-.181c0-.008-.002-.015-.003-.022z" /></g></g></g></g>
    </SvgIcon>
  )
}
