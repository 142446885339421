import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import EditTextField from './EditTable/Fields/EditTextField'

import { getPrePurchaseUsers, updateProject } from '../../actions'
import { history } from '../../helpers'

const styles = {
  headerCol: {
    backgroundColor: '#f6f6f6',
    display: 'table-cell',
    fontSize: '16px',
    fontWeight: '500',
    padding: '20px',
    top: 106
  },
  headerRow: {
    alignItems: 'center',
    display: 'table-row'
  },
  paper: {
    background: 'none'
  },
  reportPrePurchaseCol: {
    display: 'table-cell',
    maxWidth: 600,
    overflow: 'hidden',
    padding: '10px 20px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  reportPrePurchaseRow: {
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    display: 'table-row',
    titleText: {
      margin: '10px 0px 10px 10px'
    }
  },
  titleText: {
    padding: '20px'
  },
  userProjectsLink: {
    '&:hover': {
      textDecoration: 'none'
    },
    color: '#01b7d7',
    textDecoration: 'underline'
  }
}

class ReportsPrePurchaseUsers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      prePurchaseUsers: null
    }
  }

  async fetchPrePurchaseUsers () {
    const { getPrePurchaseUsersFunction } = this.props
    try {
      const data = await getPrePurchaseUsersFunction()
      this.setState({ prePurchaseUsers: data })
    } catch (e) {}
  }

  componentDidMount () {
    this.fetchPrePurchaseUsers()
  }

  async onChangeField (projectId, value) {
    const {
      updateProjectFunction
    } = this.props
    await updateProjectFunction({ id: projectId, reportNote: value })
  }

  render () {
    const { classes } = this.props
    const { prePurchaseUsers } = this.state

    const formatDate = (dateString) => {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
      return new Date(dateString).toLocaleDateString('en-US', options)
    }

    return (
        <div>
          <Paper className={classes.paper} elevation={0}>
              <Typography variant="h4" component="h2" className={classes.titleText}>
                <span>Pre-purchase activity</span>
              </Typography>
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Date account started</div>
              <div className={classes.headerCol}>User</div>
              <div className={classes.headerCol}>Email</div>
              <div className={classes.headerCol}>Project started</div>
              <div className={classes.headerCol}>Custom project submitted NOT PAID</div>
              <div className={classes.headerCol}>Awaiting Payment</div>
              <div className={classes.headerCol}>Notes</div>
            </div>
              {prePurchaseUsers &&
              prePurchaseUsers.map((data) => {
                return (
                  <div
                    data-testid="apl-prepurchaseusers"
                    key={`${data.project.projectId}`}
                    className={classes.reportPrePurchaseRow}
                  >
                    <div className={classes.reportPrePurchaseCol}>
                      {formatDate(data.createdAt)}
                    </div>
                    <div className={(classes.userProjectsLink)}
                      onClick={(e) => {
                        e.stopPropagation()
                        history.push('/admin/users/' + data.id)
                      }}
                      style={{ cursor: 'pointer', padding: '10px 20px', textAlign: 'left', width: '200px' }}
                    >
                      {data.name}
                    </div>
                    <div className={classes.reportPrePurchaseCol}>
                      {data.email}
                    </div>
                    <div className={classes.reportPrePurchaseCol} style={{ textAlign: 'right' }}>
                      {formatDate(data.project.createdAt)}
                    </div>
                    <div className={classes.reportPrePurchaseCol} style={{ textAlign: 'right' }}>
                    </div>
                    <div className={classes.reportPrePurchaseCol} style={{ textAlign: 'right' }}>
                    </div>
                    <div className={classes.reportPrePurchaseCol}>
                      <EditTextField
                        value={data.project.reportNote || ''}
                        required={false}
                        onApply={this.onChangeField.bind(this, data.project.projectId)}
                      />
                    </div>
                  </div>
                )
              })}
          </Paper>
        </div>
    )
  }
}

ReportsPrePurchaseUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  getPrePurchaseUsersFunction: PropTypes.func.isRequired,
  updateProjectFunction: PropTypes.func
}

function mapDispatchToProps (dispatch) {
  return {
    getPrePurchaseUsersFunction: function () {
      return dispatch((getPrePurchaseUsers()))
    },
    updateProjectFunction: function (project) {
      return dispatch(updateProject(project))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(ReportsPrePurchaseUsers))
