import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ParallaxArrowRight (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40" >
      <g><g><title>Brand/UI/Icons//Other</title><g><title>Brand/UI/Icons/Arrow</title><g><title>Shape</title><path d="M6.05939,18.33451h-4.49576c-0.83697,0 -1.51515,0.746 -1.51515,1.66667c0,0.92067 0.67818,1.66667 1.51515,1.66667h4.49576c0.83697,0 1.51515,-0.746 1.51515,-1.66667c0,-0.92067 -0.67818,-1.66667 -1.51515,-1.66667zM39.94424,19.96384c-0.00182,-0.098 -0.00909,-0.196 -0.02667,-0.292c-0.01515,-0.08667 -0.04121,-0.16867 -0.06909,-0.25c-0.00667,-0.01933 -0.0097,-0.04067 -0.01636,-0.06c-0.03576,-0.094 -0.08,-0.182 -0.1297,-0.266c-0.00364,-0.00667 -0.00606,-0.01335 -0.0097,-0.01935c-0.05697,-0.09333 -0.12121,-0.18 -0.19333,-0.25865l-16.57818,-18.236c-0.59212,-0.65135 -1.55091,-0.65135 -2.14242,0c-0.59152,0.65133 -0.59212,1.706 0,2.35667l13.99576,15.396h-23.56303c-0.83697,0 -1.51515,0.746 -1.51515,1.66667c0,0.92067 0.67818,1.66667 1.51515,1.66667h23.56424l-13.99818,15.396c-0.59212,0.65067 -0.59212,1.706 0,2.35667c0.29576,0.32533 0.68364,0.488 1.07152,0.488c0.38788,0 0.77515,-0.16267 1.07152,-0.488l16.58364,-18.24001c0.01333,-0.01467 0.02364,-0.034 0.03697,-0.04932c0.05394,-0.064 0.10606,-0.12867 0.1503,-0.20135c0.01939,-0.03265 0.03333,-0.06932 0.05091,-0.10332c0.03152,-0.06001 0.06424,-0.11867 0.08848,-0.18267c0.01576,-0.04133 0.02364,-0.08535 0.03636,-0.12735c0.01758,-0.06132 0.03879,-0.12067 0.05091,-0.18333c0.01939,-0.108 0.0303,-0.21867 0.0303,-0.33c0,0 0,0 0,-0.00065c-0.00061,-0.014 -0.00364,-0.02533 -0.00424,-0.03867z" fill="#333333" fillOpacity="1"></path></g></g></g></g>
    </SvgIcon>
  )
}
