import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ArrowLeft (props) {
  return (
    <SvgIcon {...props} viewBox="-15 0 17 30">
      <g><g><path transform="scale(-1,1)" d="M2.037 3.464a1.247 1.247 0 0 0 1.768 0 1.25 1.25 0 0 0 0-1.768L2.545.436A1.249 1.249 0 1 0 .777 2.204zm14.188 10.653L6.262 4.154a1.25 1.25 0 1 0-1.768 1.768l9.08 9.08L.776 27.797a1.25 1.25 0 1 0 1.768 1.767l13.682-13.68a1.25 1.25 0 0 0 0-1.768z"/></g></g>
    </SvgIcon>
  )
}
