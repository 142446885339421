import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../../helpers'
import classNames from 'classnames'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

/* eslint-disable sort-keys */
const styles = {
  listItem: {
    cursor: 'pointer',
    padding: '18px 60px',
    height: 60,
    transition: 'all ease .3s',
    '&:hover': {
      backgroundColor: '#e2e2e2'
    },
    '&._disabled': {
      cursor: 'default',
      opacity: 0.3,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    '&._mobileOnly': {
      display: 'none',
      ...commonStyles.media(767, {
        display: 'flex'
      })
    },
    ...commonStyles.media(440, {
      padding: '10px 60px',
      height: 44
    })
  },
  listItemIcon: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    '& svg': {
      width: 25,
      height: 'auto',
      '& path': {
        fill: '#999999'
      }
    }
  },
  listItemIconText: {
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.5,
    textTransform: 'uppercase'
  },
  badge: {
    position: 'absolute',
    top: -1,
    left: -3,
    fontSize: 11,
    color: '#ffffff',
    backgroundColor: 'rgba(1, 183, 215, 1)',
    padding: '1px 4px',
    textAlign: 'center',
    borderRadius: '50%'
  }
}
/* eslint-enable sort-keys */

class UrlifeListItem extends PureComponent {
  render () {
    const {
      classes,
      text,
      action,
      icon,
      badge,
      disabled,
      mobileOnly,
      handleClick
    } = this.props
    return (
      <ListItem
        className={classNames(
          classes.listItem,
          { _disabled: disabled },
          { _mobileOnly: mobileOnly }
        )}
        onClick={action ? () => handleClick(action) : null}
      >
        <ListItemIcon className={classes.listItemIcon}>
          {icon}
          {badge &&
            <div className={classes.badge}>
              {badge}
            </div>
          }
        </ListItemIcon>
        <ListItemText className={classes.listItemIconText} primary={text} />
      </ListItem>
    )
  }
}

UrlifeListItem.propTypes = {
  action: PropTypes.string,
  badge: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  mobileOnly: PropTypes.bool,
  text: PropTypes.string.isRequired
}

export default withStyles(styles)(UrlifeListItem)
