import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TrashIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 24">
      <g><g><path d="M4.53 1.676c0-.915.744-1.66 1.66-1.66h5.888c.915 0 1.66.745 1.66 1.66a.638.638 0 1 1-1.277 0 .383.383 0 0 0-.383-.383H6.19a.383.383 0 0 0-.383.383.638.638 0 1 1-1.277 0zm13.417 1.828a.638.638 0 0 1-.638.638H.959a.638.638 0 1 1 0-1.277h16.35c.352 0 .638.286.638.639zm-1.479 7.87l-.34 10.568c0 1.116-.924 2.04-2.06 2.04H4.202a2.064 2.064 0 0 1-2.061-2.06L1.63 6.077a.638.638 0 0 1 1.275-.041L3.416 21.9a.79.79 0 0 0 .785.805h9.868a.785.785 0 0 0 .783-.784l.34-10.588a.638.638 0 0 1 1.276.04zm.171-5.296l-.09 2.82a.639.639 0 0 1-.638.619h-.021a.639.639 0 0 1-.618-.66l.09-2.82a.64.64 0 0 1 1.276.041zm-5.65 15.114a.638.638 0 0 1-.626-.649l.255-14.497a.64.64 0 0 1 1.277.022l-.256 14.497a.638.638 0 0 1-.638.627h-.011zm-4.361-.627L6.374 6.068a.637.637 0 0 1 .624-.65.63.63 0 0 1 .646.628l.254 14.497a.637.637 0 0 1-.624.65h-.011a.637.637 0 0 1-.635-.628z" /></g></g>
    </SvgIcon>
  )
}
