import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function DownloadIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 25 25'>
      <g><g><path d="M24.005 18.22a.762.762 0 0 0-.762.762v3.811H1.75v-3.81a.762.762 0 1 0-1.524 0v4.572c0 .422.341.763.762.763h23.018a.762.762 0 0 0 .763-.763v-4.573a.762.762 0 0 0-.763-.762zm-4.953-4.626a.765.765 0 0 0 .541-.223l.572-.57a.76.76 0 0 0 0-1.078.767.767 0 0 0-1.082 0l-.572.57a.76.76 0 0 0 0 1.078c.15.149.345.223.54.223zm-7.09 6.33l.022.018a.789.789 0 0 0 .092.075c.015.01.032.017.048.027.027.015.054.031.083.044l.06.018c.028.01.054.02.082.025.05.01.1.016.151.016l.018-.002a.692.692 0 0 0 .246-.048l.03-.009a.757.757 0 0 0 .12-.064l.01-.006a.77.77 0 0 0 .118-.097l5.156-5.152a.761.761 0 1 0-1.078-1.078l-3.856 3.853V.837a.762.762 0 0 0-1.526 0v16.707l-5.824-5.822A.762.762 0 0 0 4.835 12.8z" /></g></g>
    </SvgIcon>
  )
}
