import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { commonStyles } from '../../../helpers'
import Drawer from '@material-ui/core/Drawer'
import UrlifeInfiniteScroller from '../UrlifeInfiniteScroller'
import ProjectChatMessage from './ProjectChatMessage'
import ChatInput from './ChatInput'
import { ROLES } from '../../../constants'
import {
  ChatIcon,
  CloseIcon,
  LoadMore
} from '../SVG/index'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: '#f5f5f5'
  },
  chatIcon: {
    color: '#323232',
    marginTop: 17,
    cursor: 'pointer'
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    width: 440,
    height: '100%',
    background: '#f5f5f5',
    ...commonStyles.media(440, {
      width: '100vw'
    })
  },
  close: {
    width: 20,
    height: 20,
    margin: 34,
    cursor: 'pointer',
    color: '#333333'
  },
  scrollerBlock: {
    width: '100%',
    borderTop: '1px solid #dfdfdf',
    borderBottom: '1px solid #dfdfdf',
    flexGrow: 1,
    padding: '10px 44px',
    overflowY: 'auto'
  },
  scroller: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end'
  },
  threadBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 44,
    marginRight: 44
  },
  threadTitle: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: '0.47px',
    color: '#323232',
    textTransform: 'uppercase',
    '&._clickable': {
      cursor: 'pointer',
      opacity: 0.4,
      transition: 'opacity ease .3s',
      '&._selected': {
        opacity: 1
      },
      '&:hover': {
        opacity: 1
      }
    }
  },
  topBlock: {
    width: '100%',
    paddingBottom: 15
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '20px 0'
  }
}
/* eslint-enable sort-keys */

class ChatDrawerView extends Component {
  constructor (props) {
    super(props)

    const drawerOpen = props.defaultState === 'open'

    this.state = {
      drawerOpen: drawerOpen
    }

    props.setPolling(drawerOpen)
  }

  toggleDrawer () {
    const drawerOpen = !this.state.drawerOpen
    this.setState({ drawerOpen }, () => this.props.setPolling(drawerOpen))
  }

  componentWillUnmount () {
    this.props.setPolling(false)
  }

  render () {
    const {
      classes,
      userId,
      roleId,
      thread,
      messages,
      users,
      hasMore,
      isLoading,
      shouldScrollToEnd,
      setThread,
      loadMore,
      onSend
    } = this.props
    const { drawerOpen } = this.state
    const isProducer = roleId === ROLES.PRODUCER
    const isAdmin = roleId === ROLES.ADMIN
    return (
      <React.Fragment>
        <ChatIcon className={classes.chatIcon} onClick={this.toggleDrawer.bind(this)} />
        <Drawer
          classes={{ paper: classes.paper }}
          open={drawerOpen} anchor="right"
          onClose={this.toggleDrawer.bind(this)}
        >
          <div className={classes.drawer}>
            <div className={classes.topBlock}>
              <CloseIcon
                onClick={this.toggleDrawer.bind(this)}
                className={classes.close}
                data-testid='cdw-close'
              />
              <div className={classes.threadBlock}>
                {(isProducer || isAdmin) &&
                  <React.Fragment>
                    <div
                      className={
                        classNames(classes.threadTitle, '_clickable', { _selected: thread === 'customer' })
                      }
                      onClick={() => setThread('customer')}>
                      Customer chat
                    </div>
                    <div
                      className={
                        classNames(classes.threadTitle, '_clickable', { _selected: thread === 'editor' })
                      }
                      onClick={() => setThread('editor')}>
                      Editor chat
                    </div>
                  </React.Fragment>
                }
                {(!isProducer && !isAdmin) &&
                <div className={classes.threadTitle}>
                  Chat
                </div>
                }
              </div>
            </div>
            <div className={classes.scrollerBlock}>
              <UrlifeInfiniteScroller
                loadMore={loadMore}
                hasMore={hasMore}
                className={classes.scroller}
                scrollElement={'parent'}
                shouldScrollToEnd={shouldScrollToEnd}
              >
                {
                  messages.map(msg => {
                    return <ProjectChatMessage
                      key={msg.id}
                      message={msg}
                      messageUserId={msg.user_id}
                      messageUser={users[msg.user_id]}
                      currentUserId={userId}
                      thread={thread}
                    />
                  })
                }
                {isLoading &&
                  <div className={classes.loader}><LoadMore /></div>
                }
              </UrlifeInfiniteScroller>
            </div>
            {!isAdmin && <ChatInput onSend={onSend} />}
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

ChatDrawerView.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultState: PropTypes.oneOf(['open', 'closed']).isRequired,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  roleId: PropTypes.number,
  setPolling: PropTypes.func.isRequired,
  setThread: PropTypes.func.isRequired,
  shouldScrollToEnd: PropTypes.bool,
  thread: PropTypes.oneOf(['customer', 'editor']).isRequired,
  userId: PropTypes.string,
  users: PropTypes.object.isRequired
}

ChatDrawerView.defaultProps = {
  defaultState: 'closed'
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ChatDrawerView))
