import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  fetchProjectCodeGrouping,
  updateProjectCodeGrouping
} from '../../../actions'
import { history } from '../../../helpers/index'
import { BackArrow } from '../../common/SVG/index'
import ProjectCodeGroupingForm from './components/ProjectCodeGroupingForm'

const styles = {
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  paper: {
    background: 'none'
  }
}
/* eslint-enable sort-keys */

class EditProjectCodeGrouping extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      grouping: null
    }
  }

  componentDidMount () {
    const { match, fetchProjectCodeGroupingFunction } = this.props
    fetchProjectCodeGroupingFunction(match.params.groupId)
      .then(
        data => {
          if (data.data && data.data.length > 0) {
            this.setState({ grouping: data.data[0] })
          }
        }
      )
  }

  onSubmit (grouping) {
    const { match, updateProjectCodeGroupingFunction } = this.props
    updateProjectCodeGroupingFunction(match.params.groupId, grouping)
      .then(
        response => {
          history.goBack()
        }
      ).catch(e => {
        let error = 'Something went wrong when creating the project code grouping.'
        if (e.error === 'The prefix needs to be unique.') {
          error = 'Error: Prefix must be unique'
        }
        this.setState({
          error: error,
          grouping: grouping
        })
      })
  }

  render () {
    const { classes } = this.props
    const { grouping, error } = this.state
    return (
      <Paper className={classes.paper} elevation={0}>
        <BackArrow
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        {grouping && <ProjectCodeGroupingForm grouping={grouping} error={error} onSubmit={this.onSubmit.bind(this)} />}
        {!grouping && <div>Loading project code grouping</div>}
      </Paper>
    )
  }
}

EditProjectCodeGrouping.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchProjectCodeGroupingFunction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  updateProjectCodeGroupingFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchProjectCodeGroupingFunction: function (groupId) {
      return dispatch(fetchProjectCodeGrouping(groupId))
    },
    updateProjectCodeGroupingFunction: function (groupId, grouping) {
      return dispatch(updateProjectCodeGrouping(groupId, grouping))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EditProjectCodeGrouping))
