import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import connect from 'react-redux/es/connect/connect'
import { commonStyles, inherits } from '../../../helpers'
import StarsMovie from '../../common/Stars/StarsMovie'

/* eslint-disable sort-keys */
const styles = inherits({
})({
  container: {
    width: '100%',
    maxWidth: '1210px',
    margin: '0 auto',
    padding: '0 0px 40px'
  },
  titleData: {
    ...commonStyles.text({
      fontSize: 24,
      color: '#000000',
      lineHeight: 1.5,
      textAlign: 'left',
      marginBottom: 30
    })
  },
  detailsText: {
    ...commonStyles.text({
      fontSize: 17,
      color: '#000000',
      lineHeight: 1.71,
      textAlign: 'left'
    }),
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  detailsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%'
  }
})
/* eslint-enable sort-keys */

class EditStars extends React.Component {
  render () {
    const { classes, projectId } = this.props
    return (
      <React.Fragment>
        <div className={classes.container}>
          <div>
            <h4 className={classes.titleData}>
              Featured
            </h4>
            <div className={classes.detailsBlock}>
              <div className={classes.detailsText}>
                <StarsMovie projectId={projectId} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

EditStars.propTypes = {
  classes: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
}

function mapStateTopProps (state) {
  return {
    category: state.category.category,
    files: state.file.files
  }
}

export default withStyles(styles)(connect(mapStateTopProps)(EditStars))
