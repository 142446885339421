import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { parse } from 'query-string'
import { commonStyles, history } from '../../helpers'
import classNames from 'classnames'
import FAQList from '../faq/FAQList'
// import UrlifeVideoSlider from '../common/UrlifeVideoSlider'
import InterestedButton from './components/InterestedButton'
import {
  Boat,
  CheckBox,
  ClapperBoard,
  House,
  ProducerChairV2,
  SilverseaIcon
} from '../common/SVG'
import Hero from './components/Hero'
import GetStarted from './components/GetStarted'
// import backgroundImage from '../../assets/img/silverspirit.jpg'
// import { PACKAGE_TYPES } from '../../constants'
// import ExclusivePackage from './components/ExclusivePackage'
// import TitledVideo from './components/TitledVideo'
// import ShotList from './components/ShotList'
import TipsVideoAndShotList from './components/TipsVideoAndShotList'
import Experience from './components/Experience'

/* eslint-disable sort-keys */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  sectionContainer: {
    marginTop: 80
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    color: '#512cb9',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    '& span': {
      fontWeight: 600
    }
  },
  subHeader: {
    fontSize: 24,
    lineHeight: '33px',
    fontWeight: 600,
    color: '#512cb9',
    ...commonStyles.media(991, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  buttonContainer: {
    marginTop: 65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  inspirationHolder: {
    backgroundColor: '#f0f0f0',
    marginTop: 50,
    overflowX: 'hidden',
    position: 'relative'
  },
  faqHeader: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
    color: '#55565a',
    ...commonStyles.media(991, {
      fontSize: 24,
      lineHeight: '30px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '24px'
    })
  },
  faqText: {
    marginTop: 5,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    textAlign: 'left',
    ...commonStyles.media(991, {
      fontSize: 14,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#55565a'
    },
    '&._marginBottom': {
      marginBottom: 30
    }
  },
  contactContainer: {
    marginTop: 47,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  }
}
/* eslint-enable sort-keys */

/*
const movies = [
  {
    imageWebviewUrl: 'https://static.urlifemedia.com/travelink/JacksonHole.jpeg',
    subtitle: 'Cool',
    title: 'Beans',
    videoWebviewUrl: 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
  },
  {
    imageWebviewUrl: 'https://static.urlifemedia.com/travelink/JacksonHole.jpeg',
    subtitle: 'Cool',
    title: 'Beans',
    videoWebviewUrl: 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
  },
  {
    imageWebviewUrl: 'https://static.urlifemedia.com/travelink/JacksonHole.jpeg',
    subtitle: 'Cool',
    title: 'Beans',
    videoWebviewUrl: 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
  },
  {
    imageWebviewUrl: 'https://static.urlifemedia.com/travelink/JacksonHole.jpeg',
    subtitle: 'Cool',
    title: 'Beans',
    videoWebviewUrl: 'https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
  }
]
*/

const steps = [
  {
    icon: CheckBox,
    text: 'Click the Make My Movie button to make a personal movie'
  },
  {
    icon: Boat,
    text: 'Enjoy your cruise and take lots of photos and videos '
  },
  {
    icon: House,
    text: 'When you get home, you will receive an email with easy-to-use instructions '
  },
  {
    icon: ProducerChairV2,
    text: 'Sit back and relax while our team makes the magic'
  }
]

const emailModalSettings = {
  disclaimer: `By submitting this form I agree to receive emails from URLIFE Media regarding the
    Silversea program. You can opt-out at any time`,
  logo: ClapperBoard,
  subTitle: `We are excited to share this complimentary added benefit with you! Please enter your
    email address below and we will send a unique project code and instructions once your voyage is over.`,
  title: "Yes, I'd like to make a personal movie of my Voyage!",
  topics: ['silversea']
}

const shotListText = `From the breathtaking landscape to 
  the fascinating wildlife, we’ve included some easy-to-follow 
  tips and fun shot list suggestions based on your itinerary. 
  These tips will help you more easily capture your favorite 
  moments so you can step back and enjoy the beauty.
`

class Silversea extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      autoplay: false
    }
  }

  componentDidMount () {
    const parsed = parse(history.location.search)
    if (parsed.autoplay && parsed.autoplay === 'true') {
      this.setState({ autoplay: true })
    }
  }

  render () {
    const { classes } = this.props
    const { autoplay } = this.state
    return (
      <div className={classes.container}>
        <Hero
          autoplay={autoplay}
          extraClass='_silversea'
          filter={false}
          headerAfter='RELIVE THE MAGIC OF YOUR JOURNEY WITH A CUSTOM MOVIE'
          headerSubHeader=''
          headerLogo={SilverseaIcon}
          logoColor='#000000'
          thumbnail='https://static.urlifemedia.com/silversea-hero-video/2022-11-03/thumbnail.png'
          textColor='#55565a'
          video='https://static.urlifemedia.com/silversea-hero-video/2022-11-03/master.m3u8'
          videoDisclaimer='Silver Origin, Galapagos Expedition'
        />
        <GetStarted
          steps={steps}
          extraClass='_silversea'
          title='We’ve made it easy to make your voyage last forever'
          subtitle='You provide the photos and videos. Our expert editors do the rest.'
          color='#55565a'
        />
        <div className={classes.buttonContainer}>
          <InterestedButton buttonText='Make My Movie' modalSettings={emailModalSettings}/>
        </div>
        {/*
        <ExclusivePackage
          headerTitle='Another all-inclusive benefit from'
          headerLogo={SilverseaIcon}
          headerSubtitle={'Lorem ipsum dolor sit amet, consectetur adipiscing elit,' +
            'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' +
            ' Ut enim ad minim veniam, quis nostrud exercitation'
          }
          headerColor='#173f6b'
          packageTitle='Hollywood movie'
          packageSubtitle='A bespoke movie of your luxury cruise memories'
          packageBackground={`
            linear-gradient(0deg, rgba(216, 216, 216, 0.75), rgba(216, 216, 216, 0.75)),
            url(${backgroundImage}) no-repeat left bottom
          `}
          gift='An added extra gift valued at $595'
          packageType={PACKAGE_TYPES.HOLLYWOOD}
        />
        */}
        {/*
        <div className={classes.inspirationHolder}>
          <UrlifeVideoSlider
            header='LIFETIME TRIPS. PRESERVED FOREVER.'
            movies={movies}
          />
        </div>
        */}
        {/*
        <TitledVideo
          color='#55565a'
          title='TIPS AND TECHNIQUES for Filming Your Cruise'
          thumbnail='https://static.urlifemedia.com/thumbnails/silversea-tips-thumbnail.jpg'
          video='https://static.urlifemedia.com/silversea-tips-video/master.m3u8'
          videoSubtitle='Watch this video for helpful filming tips from our URLIFE Co-Founder'
        />
        */}
        {/*
        <div className={classes.sectionContainer}>
          <div className={classes.faqHeader}>Still have questions?</div>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            We have categorized our frequently asked questions to make it easier to find what you are after.
          </div>
          <FAQList/>
        </div>
        */}
        {/*
        <ShotList
          listIconSrc='https://static.urlifemedia.com/silversea-shot-list/shot-list-icon.png'
          listSrc='https://static.urlifemedia.com/silversea-shot-list/Galapagos-Shot-List.pdf'
          subtitle=' for some great ideas and tips on how to capture your amazing Galapagos expedition!'
          title='YOUR CUSTOM GALAPAGOS SHOT LIST'
        />
        */}
        <Experience
          video='https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
          thumbnail='https://static.urlifemedia.com/thumbnails/silversea-nicole.png'
        />
        <TipsVideoAndShotList
          title='TIPS FOR FILMING YOUR VOYAGE'
          video='https://static.urlifemedia.com/silversea-tips-video/master.m3u8'
          thumbnail='https://static.urlifemedia.com/thumbnails/silversea-tips-thumbnail.jpg'
          videoSubtitle='Watch this video for helpful filming tips from our URLIFE Co-Founder'
          listIconSrc='https://static.urlifemedia.com/silversea-shot-list/2022-11-03/thumbnail.png'
          listSrc='https://static.urlifemedia.com/silversea-shot-list/2022-11-03/how-to-shot-list.pdf'
          listTitle='The Antarctica Shot List'
          listText={shotListText}
          linkLinkText='Download Now'
        />
        <div className={classes.buttonContainer}>
          <InterestedButton buttonText='Make My Movie' modalSettings={emailModalSettings}/>
        </div>
        <div className={classes.contactContainer}>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            <div className={classes.faqHeader}>FAQ’s</div>
            {'Learn a bit more about how the URLIFE process works. Contact us at '}
            <a href="mailto:silverseasupport@urlifemedia.com">silverseasupport@urlifemedia.com</a>
            {' if you have additional questions, and our helpful staff will reach out.'}
          </div>
        </div>
        <FAQList
          questionsType='silverseaPre'
        />
      </div>
    )
  }
}

Silversea.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Silversea)
