import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { history } from '../../../helpers/index'
import { createPromocode } from '../../../actions'
import { BackArrow } from '../../common/SVG/index'
import PromocodeForm from './components/PromocodeForm'

const styles = {
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  paper: {
    background: 'none'
  }
}

class CreatePromocode extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  onSubmit (promo) {
    const { createPromocodeFunction } = this.props
    createPromocodeFunction(promo)
      .then(
        response => {
          history.goBack()
        }
      ).catch(e => {
        this.setState({
          error: 'Something went wrong when creating the promocode.',
          promo: promo
        })
      })
  }

  render () {
    const { classes } = this.props
    const { error } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <BackArrow
            className={classes.backButton}
            onClick={() => history.goBack()}
          />
          <PromocodeForm error={error} onSubmit={this.onSubmit.bind(this)} />
        </Paper>
      </div>
    )
  }
}

CreatePromocode.propTypes = {
  classes: PropTypes.object.isRequired,
  createPromocodeFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    createPromocodeFunction: function (promocodeData) {
      return dispatch(createPromocode(promocodeData))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CreatePromocode))
