import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import ReactGA from 'react-ga4'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  parallax: {
    display: 'block',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    scrollBehavior: 'smooth',
    perspective: '300px',
    perspectiveOrigin: '50% 50%',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '&._vertical': {
      overflowX: 'hidden',
      overflowY: 'scroll'
    },
    '&._horizontal': {
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      overflowY: 'hidden'
    },
    ...commonStyles.media(480, {
      perspective: 'none'
    })
  }
}
/* eslint-enable sort-keys */

class Parallax extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      childInView: false,
      childPos: undefined,
      sectionsTimeCount: {}
    }
    if (props.scrollRef === undefined) {
      this.scrollRef = React.createRef()
    }
    this.sectionRefs = {}
    this.done = true
  }

  getScrollRef () {
    return this.props.scrollRef || this.scrollRef
  }

  handleSectionRefs () {
    const { sectionsTimeCount } = this.state
    const refs = this.sectionRefs
    const sections = Object.keys(refs)
    if (sections.length > 0) {
      for (const name of sections) {
        if (refs[name].current) {
          const pos = refs[name].current.getBoundingClientRect()
          const style =
            refs[name].current.currentStyle ||
            window.getComputedStyle(refs[name].current)
          const marginBottom = parseInt(style.marginBottom)
          const inView = pos.y < window.innerHeight && pos.y + pos.height + marginBottom > 0
          if (!sectionsTimeCount[name] && inView && this.done) {
            this.done = false
            this.setState({
              sectionsTimeCount: {
                ...sectionsTimeCount,
                [name]: {
                  inView: true,
                  timeEntered: new Date()
                }
              }
            }, () => { this.done = true })
          } else {
            if (sectionsTimeCount[name] && sectionsTimeCount[name].inView && !inView && this.done) {
              this.done = false
              const now = new Date()
              const secondsInView = parseInt((now - sectionsTimeCount[name].timeEntered) / 1000, 10)
              ReactGA.event({
                action: 'timeOnSection',
                category: 'marketingPage',
                label: name,
                value: secondsInView
              })
              this.setState({
                sectionsTimeCount: {
                  ...sectionsTimeCount,
                  [name]: {
                    inView: false,
                    timeEntered: false
                  }
                }
              }, () => { this.done = true })
            }
          }
        }
      }
    }
  }

  handleSectionsInView () {
    const { sectionsTimeCount } = this.state
    for (const name in sectionsTimeCount) {
      if (sectionsTimeCount[name].inView) {
        const now = new Date()
        const secondsInView = parseInt((now - sectionsTimeCount[name].timeEntered) / 1000, 10)
        ReactGA.event({
          action: 'timeOnSection',
          category: 'marketingPage',
          label: name,
          value: secondsInView
        })
      }
    }
  }

  componentWillUnmount () {
    this.handleSectionsInView()
  }

  componentDidMount () {
    this.handleSectionRefs()
  }

  handleScroll () {
    this.handleSectionRefs()
    const { childPos, childInView } = this.state
    if (!childInView) {
      const currentScroll = this.scrollRef && this.scrollRef.current
        ? this.scrollRef.current.scrollTop
        : null
      const clientHeight = this.scrollRef && this.scrollRef.current
        ? this.scrollRef.current.clientHeight
        : null
      if (childPos && currentScroll + (clientHeight / 3) * 2 > childPos) {
        this.setState({ childInView: true })
      }
    }
  }

  childInView (name) {
    const { sectionsTimeCount } = this.state
    if (sectionsTimeCount[name] && sectionsTimeCount[name].inView) {
      return true
    } else {
      return false
    }
  }

  setSectionRef (name) {
    this.sectionRefs[name] = React.createRef()
    return this.sectionRefs[name]
  }

  getSectionRef (name) {
    if (this.sectionRefs[name]) {
      return this.sectionRefs[name]
    }
    return null
  }

  getChildRef (childRef) {
    this.setState({ childPos: childRef.current.getBoundingClientRect().y })
  }

  render () {
    const { classes, children, direction, scrollRef } = this.props
    const newChildren = React.Children.map(children, child => {
      return React.cloneElement(child, {
        getScrollRef: this.getScrollRef.bind(this),
        getSectionRef: this.getSectionRef.bind(this),
        inView: this.childInView.bind(this),
        setSectionRef: this.setSectionRef.bind(this)
      })
    })
    return (
      <div
        onScroll={this.handleScroll.bind(this)}
        ref={scrollRef || this.scrollRef}
        className={`${classes.parallax} _${direction} ${this.props.className}`}
      >
        {newChildren}
      </div>
    )
  }
}

Parallax.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  scrollRef: PropTypes.object
}

export default withStyles(styles)(Parallax)
