import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import UrlifeButton from '../../../common/UrlifeButton'
import UrlifeTextField from '../../../common/UrlifeTextField'
import { commonStyles, toLocale } from '../../../../helpers/index'

/* eslint-disable sort-keys */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  formRow: {
    display: 'block'
  },
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  switch: {
    margin: '5px 0px 10px 0px'
  },
  switchText: {
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#666666'
  },
  expiration: {
    margin: '5px 0px'
  },
  addButton: {
    width: 100,
    margin: '10px auto 0pc auto'
  },
  underline: {
    '&:before': {
      borderBottomColor: '#666666'
    },
    '&:after': {
      borderBottomColor: '#000000'
    },
    '&._success': {
      '&:before': {
        borderBottomColor: '#01b7d7'
      },
      '&:after': {
        borderBottomColor: '#01b7d7'
      }
    },
    '&._error': {
      '&:before': {
        borderBottomColor: '#d70101'
      },
      '&:after': {
        borderBottomColor: '#d70101'
      }
    }
  },
  errorBox: {
    margin: '10px 0',
    color: '#d70101',
    opacity: 0
  },
  couponHeader: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    lineHeight: 1.334,
    paddingTop: 10,
    margin: '0px 0px 0px 15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  FormGroup: {
    marginLeft: 15
  }
}
/* eslint-enable sort-keys */

class PromocodeForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allSkus: [],
      couponType: false,
      formValid: false,
      mounted: false,
      promo: {
        active: false,
        code: '',
        couponSetting: {
          amount_off: '',
          description: '',
          expiration: '',
          percent_off: '',
          skus: [],
          title: ''
        },
        expiration: '',
        title: '',
        userLimit: ''
      },
      selectAll: false
    }
    this.handleChangeValuePromo = this.handleChangeValuePromo.bind(this)
    this.handleChangeValueCouponSetting = this.handleChangeValueCouponSetting.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  resetState () {
    const { promo, packages } = this.props
    const allSkus = []
    if (packages) {
      packages.forEach(pack => {
        pack.skus.forEach(sku => {
          allSkus.push(sku.id)
        })
      })
    }
    const resetPromo = {
      active: promo ? promo.active : false,
      code: promo ? promo.code : '',
      couponSetting: {
        amount_off: promo ? promo.couponSetting.amount_off / 100 : '',
        description: promo ? promo.couponSetting.description : '',
        expiration: promo ? promo.couponSetting.expiration : '',
        percent_off: promo ? promo.couponSetting.percent_off : '',
        skus: promo ? [...promo.couponSetting.skus] : [],
        title: promo ? promo.couponSetting.title : ''
      },
      expiration: promo ? promo.expiration : '',
      title: promo ? promo.title : '',
      userLimit: promo ? promo.userLimit : ''
    }
    this.setState({
      allSkus,
      couponType: parseFloat(resetPromo.couponSetting.amount_off) > 0,
      formValid: false,
      mounted: true,
      promo: resetPromo,
      selectAll: resetPromo.couponSetting.skus.length === allSkus.length
    }, () => {
      this.formValidator()
    })
  }

  formValidator () {
    const { promo } = this.state
    const { couponSetting } = promo

    const amount = parseFloat(couponSetting.amount_off)
    const percent = parseInt(couponSetting.percent_off, 10)
    const limit = parseInt(promo.userLimit, 10)

    const promoTitleValid = promo.title.trim().length > 0
    const couponTitleValid = couponSetting.title.trim().length > 0
    const codeValid = promo.code.trim().length > 0
    const isSkusValid = couponSetting.skus.length > 0
    const isPercent = percent > 0 && percent <= 100
    const isAmount = amount > 0
    const limitIsEmptyOrNumber = !promo.userLimit || promo.userLimit === '' || !Number.isNaN(limit)

    if (
      promoTitleValid &&
      couponTitleValid &&
      codeValid &&
      isSkusValid &&
      limitIsEmptyOrNumber &&
      (isAmount || isPercent)
    ) {
      this.setState({ formValid: true })
    } else if (this.state.formValid) {
      this.setState({ formValid: false })
    }
  }

  handleChangeSwitch (type) {
    const { promo } = this.state
    switch (type) {
      case 'active':
        const updatedPromo = {
          ...promo,
          active: !promo.active
        }
        this.setState({ promo: updatedPromo })
        break
      case 'couponType':
        promo.couponSetting.amount_off = ''
        promo.couponSetting.percent_off = ''
        this.setState({ couponType: !this.state.couponType, promo }, () => {
          this.formValidator()
        })
        break
      default:
        break
    }
  }

  handleChangeValuePromo (event) {
    const { promo } = this.state
    const value = event.target.value
    const name = event.target.name
    this.setState({
      promo: {
        ...promo,
        [name]: value
      }
    }, () => {
      this.formValidator()
    })
  };

  handleChangeValueCouponSetting (event) {
    const { promo } = this.state
    const { couponSetting } = promo
    const value = event.target.value
    const name = event.target.name
    this.setState({
      promo: {
        ...promo,
        couponSetting: {
          ...couponSetting,
          [name]: value
        }
      }
    }, () => {
      this.formValidator()
    })
  };

  handleCheckBox (event) {
    const { allSkus, promo } = this.state
    const { couponSetting } = promo
    const { skus } = couponSetting

    if (event.target.checked) {
      skus.push(event.target.name)
      const selectAll = skus.length === allSkus.length
      this.setState({
        promo: {
          ...promo,
          couponSetting: {
            ...couponSetting,
            skus
          }
        },
        selectAll
      }, () => {
        this.formValidator()
      })
    } else {
      const newArr = skus.filter(e => e !== event.target.name)
      this.setState({
        promo: {
          ...promo,
          couponSetting: {
            ...couponSetting,
            skus: newArr
          }
        },
        selectAll: false
      }, () => {
        this.formValidator()
      })
    }
  }

  handleSelectAll (event) {
    const { promo, allSkus } = this.state
    const { couponSetting } = promo
    if (event.target.checked) {
      this.setState({
        promo: {
          ...promo,
          couponSetting: {
            ...couponSetting,
            skus: allSkus
          }
        },
        selectAll: true
      }, () => {
        this.formValidator()
      })
    } else {
      this.setState({
        promo: {
          ...promo,
          couponSetting: {
            ...couponSetting,
            skus: []
          }
        },
        selectAll: false
      }, () => {
        this.formValidator()
      })
    }
  }

  handleSubmit (event) {
    const { promo } = this.state
    const { couponSetting } = promo
    const isUpdate = this.props.promo
    const amountOff = couponSetting.amount_off
    const percentOff = couponSetting.percent_off
    const limit = parseInt(promo.userLimit, 10)
    couponSetting.amount_off = parseFloat(amountOff)
    couponSetting.percent_off = parseInt(percentOff, 10)
    if (couponSetting.amount_off === 0) {
      if (isUpdate) {
        couponSetting.amount_off = null
      } else {
        delete couponSetting.amount_off
      }
    } else {
      couponSetting.amount_off = Math.round(couponSetting.amount_off * 100)
    }
    if (couponSetting.percent_off === 0) {
      if (isUpdate) {
        couponSetting.percent_off = null
      } else {
        delete couponSetting.percent_off
      }
    }
    if (!(couponSetting.expiration && couponSetting.expiration.length > 0)) {
      if (isUpdate) {
        couponSetting.expiration = null
      } else {
        delete couponSetting.expiration
      }
    }
    if (!(promo.expiration && promo.expiration.length > 0)) {
      if (isUpdate) {
        promo.expiration = null
      } else {
        delete promo.expiration
      }
    }
    if (promo.userLimit === '' || Number.isNaN(limit)) {
      if (isUpdate) {
        promo.userLimit = null
      } else {
        delete promo.userLimit
      }
    }
    promo.couponSetting = couponSetting
    this.props.onSubmit(promo)
  }

  componentDidMount () {
    this.resetState()
  }

  componentDidUpdate (prevProps) {
    if (this.props.promo !== prevProps.promo) {
      this.resetState()
    }
  }

  render () {
    const { classes, packages, error } = this.props
    const { promo, formValid, couponType, selectAll, mounted } = this.state
    const skus = promo.couponSetting.skus
    const dateOptions = {
      date: true,
      full: false,
      time: false
    }
    return (
      <div className={classes.formWrapper}>
        <Typography variant="h4" component="h2">
          <span>{(this.props.promo ? 'Edit' : 'Add') + ' Promocode'}</span>
        </Typography>
        {mounted && <form className={classes.container}>
          <UrlifeTextField
            id="promo-title"
            name="title"
            label="Title"
            autoFocus
            onChange={e => this.handleChangeValuePromo(e)}
            placeholder={'Necessary to proceed'}
            defaultValue={promo.title || ''}
          />
          <UrlifeTextField
            id="promo-code"
            name="code"
            label="Code"
            onChange={e => this.handleChangeValuePromo(e)}
            placeholder={'Necessary to proceed'}
            defaultValue={promo.code || ''}
          />
          <UrlifeTextField
            id="promo-expiration"
            name="expiration"
            label="Expiration date"
            type="date"
            onChange={e => this.handleChangeValuePromo(e)}
            defaultValue={
              (promo.expiration && toLocale(new Date(promo.expiration), dateOptions)) ||
              ''
            }
            InputLabelProps={{ shrink: true }}
            className={`${classes.textField} ${classes.expiration}`}
          />
          <UrlifeTextField
            id="promo-userLimit"
            name="userLimit"
            label="User limit"
            onChange={e => this.handleChangeValuePromo(e)}
            defaultValue={promo.userLimit || ''}
          />
          <FormControl className={classes.formRow}>
            <FormControlLabel
              classes={{
                label: classes.switchText,
                root: classes.switch
              }}
              control={
                <Switch
                  checked={promo.active}
                  onChange={() => this.handleChangeSwitch('active')}
                />
              }
              label="Active"
              labelPlacement="start"
            />
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              classes={{
                root: classes.couponHeader
              }}
            >
              Coupon Setting
            </FormLabel>
            <FormGroup className={classes.FormGroup}>
              <UrlifeTextField
                id="couponSetting-title"
                name="title"
                label="Title"
                onChange={e => this.handleChangeValueCouponSetting(e)}
                placeholder={'Necessary to proceed'}
                defaultValue={promo.couponSetting.title || ''}
              />
              <UrlifeTextField
                id="couponSetting-description"
                label="Description"
                name="description"
                onChange={e => this.handleChangeValueCouponSetting(e)}
                defaultValue={promo.couponSetting.description || ''}
              />
              <FormControl className={classes.formRow}>
                <Grid
                  className={classes.switch}
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={classes.switchText}>Percent off</Grid>
                  <Grid item>
                    <Switch
                      checked={this.state.couponType}
                      onChange={() => this.handleChangeSwitch('couponType')}
                      name="couponType"
                    />
                  </Grid>
                  <Grid item className={classes.switchText}>Amount off</Grid>
                </Grid>
              </FormControl>
              {couponType
                ? (
                <FormControl className={classes.formRow}>
                  <Input
                    id="couponSetting-amount"
                    name="amount_off"
                    value={promo.couponSetting.amount_off}
                    onChange={e => this.handleChangeValueCouponSetting(e)}
                    fullWidth
                    placeholder={'Necessary to proceed'}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    classes={
                      {
                        underline: classes.underline
                      }
                    }
                  />
                </FormControl>
                  )
                : (
                <FormControl className={classes.formRow}>
                  <Input
                    id="couponSetting-percent"
                    name="percent_off"
                    value={promo.couponSetting.percent_off}
                    onChange={e => this.handleChangeValueCouponSetting(e)}
                    fullWidth
                    placeholder={'Necessary to proceed'}
                    endAdornment={<InputAdornment position="start">%</InputAdornment>}
                    classes={
                      {
                        underline: classes.underline
                      }
                    }
                  />
                </FormControl>
                  )}
              <UrlifeTextField
                id="couponSetting-expiration"
                name="expiration"
                label="Expiration date"
                type="date"
                onChange={e => this.handleChangeValueCouponSetting(e)}
                defaultValue={
                  (
                    promo.couponSetting.expiration &&
                    toLocale(new Date(promo.couponSetting.expiration), dateOptions)
                  ) || ''
                }
                InputLabelProps={{ shrink: true }}
                className={`${classes.textField} ${classes.expiration}`}
              />
              <FormControl className={classes.formRow}>
                <FormLabel component="legend">{"SKU's (at least one must be selected)"}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={e => this.handleSelectAll(e)} name={'selectAll'}
                      />}
                    label={'Select All'}
                  />
                  {packages.map((pack, idxPack) => {
                    return (
                      pack.skus.map((sku, idxSku) => {
                        const packageSkuPrice = parseFloat(sku.price.replace('$', ''))
                        if (!isNaN(packageSkuPrice) && !sku.hidden) {
                          return (
                            <FormControlLabel
                              key={`${idxPack}${idxSku}`}
                              className={classes.checkBox}
                              control={
                                <Checkbox
                                  checked={skus && skus.includes(sku.id)}
                                  onChange={e => this.handleCheckBox(e)} name={sku.id}
                                />}
                              label={`${pack.title}, ${sku.producerDescription || sku.description} ${sku.price}`}
                            />
                          )
                        } else return null
                      })
                    )
                  })}
                </FormGroup>
              </FormControl>
            </FormGroup>
          </FormControl>
          <div
            className={classes.errorBox}
            style={error ? { opacity: 1 } : {}}
          >
            {error}
          </div>
          <UrlifeButton
            disabled={!formValid}
            className={classes.addButton}
            onClick={e => this.handleSubmit(e)}
          >
            {this.props.promo ? 'Update' : 'Add'}
          </UrlifeButton>
        </form>}
      </div>
    )
  }
}

PromocodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  promo: PropTypes.object
}

PromocodeForm.defaultProps = {
  promo: null
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(PromocodeForm))
