import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function ClapperBoard ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='5 5 90 85'>
      <g>
        <path d="M86.3,33.3H25.1l60.3-10.6c0.3,0,0.5-0.2,0.6-0.4c0.2-0.2,0.2-0.5,0.2-0.7l-1.4-8.2c-0.1-0.5-0.6-0.9-1.2-0.8L12.1,25.1  c-0.3,0-0.5,0.2-0.6,0.4c-0.2,0.2-0.2,0.5-0.2,0.7l1.4,8.1v52c0,0.6,0.4,1,1,1h72.6c0.6,0,1-0.4,1-1V34.3  C87.3,33.7,86.9,33.3,86.3,33.3z M70.3,41.5l7-6.2h5.9l-7,6.2H70.3z M61.4,41.5l7-6.2h5.9l-7,6.2H61.4z M52.5,41.5l7-6.2h5.9l-7,6.2  H52.5z M43.6,41.5l7-6.2h5.9l-7,6.2H43.6z M34.6,41.5l7-6.2h5.9l-7,6.2H34.6z M25.7,41.5l7-6.2h5.9l-7,6.2H25.7z M16.8,41.5l7-6.2  h5.9l-7,6.2H16.8z M21.1,25.5l8.1,5l-5.8,1l-8.1-5L21.1,25.5z M29.9,24l8.1,5l-5.8,1l-8.1-5L29.9,24z M38.7,22.4l8.1,5l-5.8,1  l-8.1-5L38.7,22.4z M47.5,20.9l8.1,5l-5.8,1l-8.1-5L47.5,20.9z M56.3,19.3l8.1,5l-5.8,1l-8.1-5L56.3,19.3z M65.1,17.8l8.1,5l-5.8,1  l-8.1-5L65.1,17.8z M73.9,16.2l8.1,5l-5.8,1l-8.1-5L73.9,16.2z M14.7,35.3h6.1l-6.1,5.4V35.3z M20.4,32.1l-5.9,1l-0.9-5.3L20.4,32.1  z M85.3,36.1v5.4h-6.1L85.3,36.1z M83.9,20.1l-7.1-4.4l6.1-1.1L83.9,20.1z M14.7,85.4V43.5h70.6v41.9H14.7z"/>
        <path d="M62.1,64.5L44,54.1c-0.3-0.2-0.7-0.2-1,0c-0.3,0.2-0.5,0.5-0.5,0.9v20.9c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.3,0.1,0.5,0.1  s0.3,0,0.5-0.1l18.1-10.4c0.3-0.2,0.5-0.5,0.5-0.9S62.4,64.7,62.1,64.5z M44.5,74.1V56.7l15.1,8.7L44.5,74.1z"/>
      </g>
    </SvgIcon>
  )
}

ClapperBoard.propTypes = {
  color: PropTypes.string
}
