import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ListViewIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 27 25">
      <g><g><path d="M6.326 5.568h-3.87V1.653h3.87zm.858-5.651H1.599a.863.863 0 0 0-.858.868v5.65c0 .48.384.869.858.869h5.585a.863.863 0 0 0 .857-.868V.785a.863.863 0 0 0-.857-.868zm-.858 23.315h-3.87v-3.915h3.87zm.858-5.65H1.599a.863.863 0 0 0-.858.867V24.1c0 .48.384.868.858.868h5.585a.863.863 0 0 0 .857-.868v-5.65a.863.863 0 0 0-.857-.869z" /></g><g><path d="M7.175 11.911a.863.863 0 0 0 .858-.868V9.676a.863.863 0 0 0-.858-.868H1.591a.863.863 0 0 0-.858.868v5.651c0 .48.384.868.858.868h5.584a.863.863 0 0 0 .858-.868v-1.68a.863.863 0 0 0-.858-.867.863.863 0 0 0-.858.868v.811H2.45v-3.915h3.868v.5c0 .479.384.867.858.867z" /></g><g><path d="M25.835 11.725c.455 0 .824.373.824.833 0 .46-.37.834-.824.834H11.447a.829.829 0 0 1-.823-.834c0-.46.368-.833.823-.833zm0 8.698c.455 0 .824.373.824.833 0 .46-.37.834-.824.834H11.447a.829.829 0 0 1-.823-.834c0-.46.368-.833.823-.833zm0-17.5c.455 0 .824.373.824.833 0 .46-.37.834-.824.834H11.447a.829.829 0 0 1-.823-.834c0-.46.368-.833.823-.833z" /></g></g>
    </SvgIcon>
  )
}
