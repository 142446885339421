import ApiService from '../services/ApiService'
import { api } from '../constants'
import download from 'downloadjs'
import streamSaver from 'streamsaver/StreamSaver'
import { createLog } from './logs'
import { fileProgressStatus, fileProgressType } from '../reducers/fileProgress'
import { getManagedUploads, uploadFileService } from '../services/upload'
import {
  closeWebSocket,
  openWebSocket,
  sendWebSocketCommand
} from '../services/WebSocketService'
import { downloadLink } from '../helpers'

export {
  abortUpload,
  clearUpload,
  downloadErrorMessage,
  downloadFile,
  generateZip,
  uploadFile
}

const queue = []
let uploadQueueCheck = null
const timeOutUploadIds = {}
function uploadFile (cognitoInfo, file, fileId, fileExt, projectId, role, fileKey, onComplete, onFailure) {
  return (dispatch, getStore) => {
    const getUploadsObject = () => {
      const startStore = getStore().fileProgress
      const toReturn = {}
      for (const key in startStore) {
        if (startStore[key].progressType === fileProgressType.upload) {
          toReturn[key] = startStore[key]
        }
      }
      return toReturn
    }
    const upload = getUploadsObject()
    if (!upload[fileId]) {
      dispatch({
        fileId,
        name: file.name,
        progressType: fileProgressType.upload,
        projectId,
        role,
        total: file.size,
        type: 'FILE_PROGRESS_ADD'
      })
    }

    const info = {
      fileKey: fileKey,
      id: fileId,
      onComplete: () => {
        dispatch({ fileId, type: 'FILE_PROGRESS_COMPLETE' })
      },
      onError: () => {
        dispatch({ fileId, type: 'FILE_PROGRESS_FAILED' })
      },
      onProgress: (loaded, total) => {
        dispatch({ fileId, loaded, type: 'FILE_PROGRESS_CONTINUE' })
      }
    }

    const maxQueue = 5
    // for big files queue work per file size
    // because for each 5 mb will created separate request (up to 4 requests)
    const maxFileSize = maxQueue * 5 * 1024 * 1024
    const maxRetryCount = 4

    const canUpload = (fileId = '') => {
      const upload = getUploadsObject()
      let amountUploading = 0
      let totalUploadNeed = 0
      let fileIncludedInTotal = false
      const retry = []
      for (const file in upload) {
        if (upload[file].loaded < upload[file].total) {
          if (upload[file].status === fileProgressStatus.inProgress ||
            (upload[file].status === fileProgressStatus.failed && upload[file].retryCount <= maxRetryCount)) {
            amountUploading++
            totalUploadNeed += upload[file].total
            if (fileId && fileId === file) {
              fileIncludedInTotal = true
            }
          }
          if (upload[file].status === fileProgressStatus.failed) {
            retry.push(file)
          }
        }
      }
      const maxQueueExceeded = fileIncludedInTotal ? (amountUploading > maxQueue) : (amountUploading >= maxQueue)
      return [(!maxQueueExceeded && totalUploadNeed <= maxFileSize), retry]
    }
    const uploadError = (e) => {
      dispatch(createLog(
        fileId,
        {
          errorCode: e.code,
          errorMessage: e.message,
          fileId,
          type: 'UPLOAD_ERROR'
        }
      ))
      if (
        e.code === 'NetworkingError' ||
        e.code === 'TimeoutError' ||
        e.code === 'AccessDenied' ||
        e.code === 'CredentialsError' ||
        e.code === 'RequestTimeTooSkewed'
      ) {
        // try to upload every 5 sec
        timeOutUploadIds[fileId] = window.setTimeout(() => {
          dispatch(uploadFile(cognitoInfo, file, fileId, fileExt, projectId, role, fileKey, onComplete, onFailure))
        }, 5000)
      } else {
        // clear time outs on abort
        if (e.code === 'RequestAbortedError') {
          if (timeOutUploadIds[fileId]) {
            window.clearTimeout(timeOutUploadIds[fileId])
            timeOutUploadIds[fileId] = null
          }
        } else {
          onFailure()
        }
      }
    }

    const queueCheck = () => {
      const [newFile, failedFiles] = canUpload()
      if (newFile && queue.length > 0) {
        const queueFile = queue.shift()
        if (queue.length < 1) {
          window.clearInterval(uploadQueueCheck)
          uploadQueueCheck = null
        }
        if (failedFiles.includes(queueFile.fileId)) {
          dispatch({
            fileId: queueFile.fileId,
            type: 'FILE_PROGRESS_RETRY'
          })
        } else {
          dispatch({
            fileId: queueFile.fileId,
            type: 'FILE_PROGRESS_START'
          })
        }
        dispatch(uploadFileService(queueFile.file, queueFile.info))
          .then(
            ({ data, cognitoInfo }) => {
              if (queueFile.onComplete && typeof queueFile.onComplete === 'function') queueFile.onComplete(cognitoInfo)
            },
            queueFile.uploadError
          )
      }
    }

    const [newFileUpload, oldFileUploads] = canUpload(fileId)
    let shouldQueue = !newFileUpload
    if (oldFileUploads.includes(fileId)) {
      shouldQueue = upload[fileId].retryCount >= maxRetryCount
    }
    if (shouldQueue) {
      dispatch({
        fileId: fileId,
        type: 'FILE_PROGRESS_QUEUED'
      })
      queue.push({ file, fileId, info, onComplete, uploadError })
      if (!uploadQueueCheck) uploadQueueCheck = setInterval(queueCheck, 300)
      return new Promise(resolve => resolve(true))
    }
    if (upload[fileId] && upload[fileId].status === fileProgressStatus.failed) {
      dispatch({
        fileId: fileId,
        type: 'FILE_PROGRESS_RETRY'
      })
    } else {
      dispatch({
        fileId: fileId,
        type: 'FILE_PROGRESS_START'
      })
    }
    return dispatch(uploadFileService(file, info))
      .then(
        ({ data, cognitoInfo }) => {
          if (onComplete && typeof onComplete === 'function') onComplete(cognitoInfo)
        },
        uploadError
      )
  }
}

function abortUpload (fileId) {
  return dispatch => {
    // abort upload
    const uploadService = getManagedUploads(fileId)
    if (uploadService) uploadService.abort()

    // remove from queue
    const queueIndex = queue.indexOf(fileId)
    if (queueIndex > -1) queue.splice(queueIndex, 1)

    // remove from redux
    dispatch({
      fileId: fileId,
      type: 'FILE_PROGRESS_REMOVE'
    })
  }
}

function clearUpload () {
  return { type: 'FILE_PROGRESS_CLEAR' }
}

const downloadQueue = []

/**
 * Download file with progress and open user save dialog
 * @param {object} fileData
 * @param {string} fileData.url - file url
 * @param {string} [fileData.projectId = '']
 * @param {number} [fileData.total] - total file size
 * @param {number} fileData.name - total file size
 * @param {'user'|'finished'|'zip'} [fileData.role]
 * @param {boolean} needAuth - send auth headers in request
 * @return {Function}
 */
function downloadFile (fileData, needAuth = false) {
  return (dispatch, getStore) => {
    // can be several files in store with one fileId, but only one in progress
    const fileId = (fileData.id || fileData.url) + '-' + Date.now()
    const downloads = Object.values(getStore().fileProgress)
      .filter(file => file.progressType === fileProgressType.download)
    const queryParams = fileData.queryParams || {}
    const urlVars = fileData.urlVars || {}
    const sameFilesInProgress = downloads.filter(file => {
      return file.url === fileData.url && file.status === fileProgressStatus.inProgress
    })
    if (sameFilesInProgress.length > 0) {
      return new Promise((resolve, reject) => reject(new Error('Duplicate download')))
    }

    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

    dispatch({
      fileId,
      name: fileData.name || '',
      progressType: fileProgressType.download,
      projectId: fileData.projectId || '',
      role: fileData.role || '',
      total: fileData.size || 1, // need to set minimum 1 size for correct progress view
      type: 'FILE_PROGRESS_ADD',
      url: fileData.url
    })

    const startDownload = () => {
      dispatch({
        fileId,
        type: 'FILE_PROGRESS_START'
      })
      if (isChrome) {
        streamSaver.mitm = `${window.location.origin}/mitm.html`
        const fileStream = streamSaver.createWriteStream(fileData.name)

        let loaded = 0

        return new ApiService(fileData.url, urlVars, queryParams, needAuth, dispatch)
          .setHeaders({ 'Content-Type': '' })
          .get()
          .then(res => {
            if (res.ok) {
              downloadQueue.shift()
              const writer = fileStream.getWriter()

              const reader = res.body.getReader()
              const pump = () => reader.read()
                .then(res => {
                  if (res.done) {
                    writer.close()
                    dispatch({ fileId, type: 'FILE_PROGRESS_COMPLETE' })
                  } else {
                    dispatch({
                      fileId,
                      loaded: loaded += res.value.length,
                      total: fileData.total,
                      type: 'FILE_PROGRESS_CONTINUE'
                    })
                    writer.write(res.value).then(pump)
                  }
                })

              pump()
            } else {
              dispatch({ fileId, type: 'FILE_PROGRESS_FAILED' })
            }
          })
      } else {
        return new ApiService(fileData.url, urlVars, queryParams, needAuth, dispatch)
          .setHeaders({ 'Content-Type': '' })
          .download(e => {
            dispatch({
              fileId,
              loaded: e.loaded,
              total: e.total || fileData.total,
              type: 'FILE_PROGRESS_CONTINUE'
            })
          })
          .then(
            blob => {
              downloadQueue.shift()
              dispatch({ fileId, type: 'FILE_PROGRESS_COMPLETE' })
              download(blob, fileData.name, blob.type)
            },
            () => {
              dispatch({ fileId, type: 'FILE_PROGRESS_FAILED' })
            }
          )
      }
    }

    downloadQueue.push(fileData.url)
    // add to queue if another file is in download
    let intervalId
    if (downloadQueue.length > 1) {
      dispatch({
        fileId,
        type: 'FILE_PROGRESS_QUEUED'
      })
      return new Promise(resolve => {
        intervalId = setInterval(() => {
          if (downloadQueue[0] === fileData.url) {
            window.clearInterval(intervalId)
            return resolve(startDownload())
          }
        }, 300)
      })
    }

    return startDownload()
  }
}

function downloadErrorMessage (error) {
  if (error.message === 'Duplicate download') {
    return 'This file is already downloading:'
  } else {
    return 'Something went wrong downloading file:'
  }
}

function generateZip (project, include, filter = null) {
  return (dispatch) => {
    function defaultError () {
      closeWebSocket()
      dispatch({ fileId: project.id, type: 'FILE_PROGRESS_FAILED' })
    }
    dispatch({
      fileId: project.id,
      name: project.id,
      progressType: fileProgressType.generateZip,
      projectId: project.id,
      role: 'generate',
      type: 'FILE_PROGRESS_ADD'
    })

    let zipTimeoutId = null
    openWebSocket(async (type, data = null) => {
      switch (type) {
        case 'open':
          sendWebSocketCommand({ action: 'getConnectionId' })
          zipTimeoutId = window.setTimeout(() => {
            defaultError()
          }, 60 * 60 * 1000)
          break
        case 'message':
          switch (data.status) {
            case 'success':
              switch (data.action) {
                case 'getConnectionId':
                  dispatch({
                    fileId: project.id,
                    type: 'FILE_PROGRESS_QUEUED'
                  })
                  const { data: { connectionId } } = data
                  const res = await new ApiService(
                    api.GENERATE_ZIP,
                    { projectId: project.id },
                    {},
                    true,
                    dispatch
                  ).post({
                    connectionId,
                    filter,
                    include
                  })
                  if (!res.status || res.status !== 'success') {
                    defaultError()
                  }
                  if (
                    res.data &&
                    res.data.status &&
                    res.data.status === 'completed' &&
                    res.data.url
                  ) {
                    dispatch({
                      fileId: project.id,
                      type: 'FILE_PROGRESS_REMOVE'
                    })
                    closeWebSocket()
                    downloadLink(res.data.url)
                  }
                  break
                case 'zipProgress':
                  if (data.data.progress === 0) {
                    dispatch({
                      fileId: project.id,
                      type: 'FILE_PROGRESS_START'
                    })
                  }
                  dispatch({
                    fileId: project.id,
                    loaded: data.data.progress,
                    total: data.data.total,
                    type: 'FILE_PROGRESS_CONTINUE'
                  })
                  break
                case 'zipStatus':
                  dispatch({
                    fileId: project.id,
                    type: 'FILE_PROGRESS_COMPLETE'
                  })
                  closeWebSocket()
                  downloadLink(data.data.url)
                  break
                default:
                  break
              }
              break
            case 'fail':
              defaultError()
              break
            default:
              if (data.message === 'Internal server error') {
                defaultError()
              }
              break
          }
          break
        case 'close':
          window.clearTimeout(zipTimeoutId)
          break
        case 'error':
          defaultError()
          break
        default:
          break
      }
    })
  }
}
