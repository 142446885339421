import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChatDrawerView from './ChatDrawerView'
import ChatTabView from './ChatTabView'
import { ROLES } from '../../../constants'
import {
  createMessage,
  getMessages,
  userInfoById
} from '../../../actions'

class ProjectChat extends Component {
  constructor (props) {
    super(props)

    this.users = {}
    this.shouldScrollToEnd = false
    this.updateInterval = null
    this.state = {
      isLoading: false,
      lastNewMessage: null,
      messages: [],
      nextMessage: null,
      thread: props.thread || (props.roleId === ROLES.EDITOR ? 'editor' : 'customer')
    }
  }

  setPolling (shouldPoll) {
    this.shouldScrollToEnd = shouldPoll
    if (shouldPoll) {
      this.startUpdateLoop()
    } else if (this.updateInterval) {
      clearInterval(this.updateInterval)
      this.updateInterval = null
    }
  }

  handleSend (message) {
    const { project, createMessageFunction } = this.props
    const { thread } = this.state
    if (message.length > 0) {
      createMessageFunction(project.id, thread, message).then(
        () => {
          this.checkForNewMessages()
        }
      )
    }
  }

  async getUsersData (newMessages) {
    const { userId, userInfoByIdFunction } = this.props

    return new Promise(resolve => {
      const arUserIds = newMessages.map(message => message.user_id)
      let loadingUserData = 0
      Array.from(new Set(arUserIds))
        .filter(messageUserId => !this.users[userId] && messageUserId !== userId)
        .forEach(messageUserId => {
          loadingUserData++
          userInfoByIdFunction(messageUserId)
            .then(
              respond => {
                if (respond.status === 'success') {
                  this.users = { ...this.users, [messageUserId]: respond.data }
                  if (!--loadingUserData) {
                    resolve()
                  }
                }
              }
            )
        })
      if (!loadingUserData) resolve()
    })
  }

  loadMessages (add) {
    const { project, getMessagesFunction } = this.props
    const { thread, nextMessage, messages } = this.state
    this.setState({ isLoading: true })
    const params = { method: 'DESC', sort: 'created_at' }
    if (add) {
      params.cursor = nextMessage
    }
    getMessagesFunction(project.id, thread, params).then(
      async data => {
        let newMessages = data.data
        let lastNewMessage = this.state.lastNewMessage
        if (add) {
          newMessages = [...messages, ...data.data]
        } else if (newMessages && newMessages.length > 0) {
          lastNewMessage = newMessages[0].id
        }
        await this.getUsersData(newMessages)
        this.shouldScrollToEnd = !add
        this.setState({
          isLoading: false,
          lastNewMessage: lastNewMessage,
          messages: newMessages,
          nextMessage: data.nextCursor || null
        })
      }
    )
  }

  checkForNewMessages () {
    const { project, getMessagesFunction } = this.props
    const { thread, messages, lastNewMessage } = this.state
    const params = { cursor: lastNewMessage, method: 'ASC', sort: 'created_at' }
    getMessagesFunction(project.id, thread, params).then(
      async data => {
        const newMessages = data.data
        if (newMessages && newMessages.length > 0) {
          newMessages.reverse()
          await this.getUsersData(newMessages)
          this.shouldScrollToEnd = true
          this.setState({
            lastNewMessage: newMessages[0].id,
            messages: [...newMessages, ...messages]
          })
        }
      }
    )
  }

  startUpdateLoop () {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
    this.updateInterval = setInterval(() => {
      this.forceUpdate()
      this.checkForNewMessages()
    }, 5000)
  }

  componentDidMount () {
    this.loadMessages(false)
  }

  componentWillUnmount () {
    if (this.updateInterval) {
      clearInterval(this.updateInterval)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.thread !== prevProps.thread) {
      this.setThread(this.props.thread)
    }
  }

  setThread (thread) {
    if (this.state.thread !== thread) {
      this.setState({
        isLoading: false,
        messages: [],
        nextMessage: null,
        thread: thread
      }, () => {
        this.loadMessages(false)
      })
    }
  }

  render () {
    const { project, view, defaultState } = this.props
    const { thread, messages, isLoading, nextMessage } = this.state
    const shouldScrollToEnd = this.shouldScrollToEnd
    this.shouldScrollToEnd = false
    return (
      <React.Fragment>
        {view === 'drawer' &&
        <ChatDrawerView
          thread={thread}
          messages={messages}
          users={this.users}
          hasMore={!!nextMessage}
          isLoading={isLoading}
          shouldScrollToEnd={shouldScrollToEnd}
          setPolling={this.setPolling.bind(this)}
          setThread={this.setThread.bind(this)}
          loadMore={this.loadMessages.bind(this, true)}
          onSend={this.handleSend.bind(this)}
          defaultState={defaultState}
        />
        }
        {view === 'tab' &&
        <ChatTabView
          project={project}
          thread={thread}
          messages={messages}
          users={this.users}
          hasMore={!!nextMessage}
          isLoading={isLoading}
          shouldScrollToEnd={shouldScrollToEnd}
          setPolling={this.setPolling.bind(this)}
          loadMore={this.loadMessages.bind(this, true)}
          onSend={this.handleSend.bind(this)}
        />
        }
      </React.Fragment>
    )
  }
}

ProjectChat.propTypes = {
  classes: PropTypes.object.isRequired,
  createMessageFunction: PropTypes.func.isRequired,
  defaultState: PropTypes.oneOf(['open', 'closed']).isRequired,
  getMessagesFunction: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number,
  thread: PropTypes.oneOf(['customer', 'editor']),
  userId: PropTypes.string,
  userInfoByIdFunction: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['drawer', 'tab']).isRequired
}

ProjectChat.defaultProps = {
  defaultState: 'closed'
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}
function mapDispathToProps (dispatch) {
  return {
    createMessageFunction: function (projectId, thread, message) {
      return dispatch(createMessage(projectId, thread, message))
    },
    getMessagesFunction: function (projectId, thread, params) {
      return dispatch(getMessages(projectId, thread, params))
    },
    userInfoByIdFunction: function (userId) {
      return dispatch(userInfoById(userId))
    }
  }
}

export default connect(mapStateToProps, mapDispathToProps)(ProjectChat)
