import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { commonStyles, history } from '../../helpers/index'
import Footer from './Footer'
import classNames from 'classnames'
import { toggleModal } from '../../actions'
import { connect } from 'react-redux'

/* eslint-disable sort-keys */
const styles = {
  homeContainer: {
    '&._home': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 116,
      bottom: 0,
      overflowY: 'scroll'
    }
  },
  root: {
    paddingTop: 166,
    minHeight: 'calc(100vh - 151px)',
    '&._home': {
      paddingTop: 0
    },
    '&._createProject': {
      minHeight: 'calc(100vh - 220px)'
    },
    ...commonStyles.media(959, {
      minHeight: 'calc(100vh - 231px)'
    }),
    ...commonStyles.media(575, {
      paddingTop: 90,
      minHeight: 'calc(100vh)',
      '&._home': {
        paddingTop: 0
      },
      '&._createProject': {
        paddingTop: 120
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      paddingTop: 90,
      minHeight: 'calc(100vh)',
      '&._home': {
        paddingTop: 0
      },
      '&._createProject': {
        paddingTop: 120
      }
    })
  },
  container: {
    ...commonStyles.container()
  },
  content: {
    paddingBottom: 80,
    ...commonStyles.media(575, {
      paddingBottom: 50
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      paddingBottom: 50
    }),
    '&._createProject': {
      paddingBottom: 20
    }
  }
}
/* eslint-enable sort-keys */

class PrivateRoute extends React.Component {
  constructor (props) {
    super(props)
    if (props.getNotifications) {
      props.getNotifications()
    }
  }

  render () {
    const {
      component: Component,
      loggedIn,
      role,
      requiredRole,
      classes,
      isMainPage,
      isHomePage,
      isCreateProjectPage,
      layout,
      componentProperties,
      showFooter,
      ...rest
    } = this.props

    return (
      <Route {...rest} render={props => {
        if (!requiredRole.length || (loggedIn && requiredRole.includes(role))) {
          return (
            <React.Fragment>
              <div className={classNames(classes.homeContainer, { _home: isHomePage })}>
                <main
                  className={
                    classNames(
                      classes.root,
                      { _home: isHomePage },
                      { _createProject: isCreateProjectPage }
                    )
                  }
                >
                  { !layout || isMainPage
                    ? <Component {...props} {...componentProperties}/>
                    : (
                      <div className={classes.container}>
                        <div className={classNames(classes.content, { _createProject: isCreateProjectPage })}>
                          <Component {...props} {...componentProperties}/>
                        </div>
                      </div>
                      )
                  }
                </main>
                {showFooter && <Footer />}
              </div>
            </React.Fragment>
          )
        } else {
          this.props.toggleModal(!loggedIn, 'login')
          return <Redirect to={{ pathname: '/', state: { from: history.location } }} />
        }
      }} />
    )
  }
}

PrivateRoute.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object,
  componentProperties: PropTypes.object,
  getNotifications: PropTypes.func,
  isCreateProjectPage: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isMainPage: PropTypes.bool,
  layout: PropTypes.bool,
  loggedIn: PropTypes.bool,
  requiredRole: PropTypes.arrayOf(PropTypes.number),
  role: PropTypes.number,
  showFooter: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired
}

PrivateRoute.defaultProps = {
  componentProperties: {},
  isCreateProjectPage: false,
  isHomePage: false,
  isMainPage: false,
  layout: true,
  requiredRole: [],
  showFooter: true
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(PrivateRoute))
