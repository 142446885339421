import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { LoadMore } from '../common/SVG'

const styles = {
  emptyText: {
    color: '#000',
    margin: '20px 0'
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    marginBottom: '40px',
    width: '100%'
  }
}

class UrlifeInfiniteScroller extends Component {
  constructor (props) {
    super(props)
    this.scrollListener = this.scrollListener.bind(this)
    this.mousewheelListener = this.mousewheelListener.bind(this)
    this.handleScroll = false
  }

  componentDidMount () {
    this.attachScrollListener()
    if (this.props.initialLoad) {
      this.props.loadMore()
    }
    this.scrollIfNeeded()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.isLoading && prevProps.isLoading !== this.isLoading) {
      this.attachScrollListener()
    }
    this.scrollIfNeeded()
  }

  componentWillUnmount () {
    this.detachScrollListener()
    this.detachMousewheelListener()
  }

  detachMousewheelListener () {
    let scrollEl = window
    if (this.props.scrollElement !== 'window') {
      scrollEl = this.getParentOrGrandparentElement(this.scrollComponent)
    }

    scrollEl.removeEventListener(
      'mousewheel',
      this.mousewheelListener,
      this.props.useCapture
    )
  }

  detachScrollListener () {
    let scrollEl = window
    if (this.props.scrollElement !== 'window') {
      scrollEl = this.getParentOrGrandparentElement(this.scrollComponent)
    }
    scrollEl.removeEventListener(
      'scroll',
      this.scrollListener,
      this.props.useCapture
    )
    scrollEl.removeEventListener(
      'resize',
      this.scrollListener,
      this.props.useCapture
    )
  }

  getParentOrGrandparentElement (el) {
    const { scrollElement } = this.props
    const parent = el && el.parentNode
    if (scrollElement === 'grandparent') {
      return parent && parent.parentNode
    } else {
      return parent
    }
  }

  filterProps (props) {
    return props
  }

  attachScrollListener () {
    if (!this.getParentOrGrandparentElement(this.scrollComponent)) {
      return
    }

    let scrollEl = window
    if (this.props.scrollElement !== 'window') {
      scrollEl = this.getParentOrGrandparentElement(this.scrollComponent)
    }
    scrollEl.addEventListener(
      'mousewheel',
      this.mousewheelListener,
      this.props.useCapture
    )
    scrollEl.addEventListener(
      'scroll',
      this.scrollListener,
      this.props.useCapture
    )
    scrollEl.addEventListener(
      'resize',
      this.scrollListener,
      this.props.useCapture
    )
  }

  mousewheelListener (e) {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1) {
      e.preventDefault()
    }
    if (!this.handleScroll && this.scrollListener) {
      this.scrollListener()
    }
  }

  scrollListener () {
    if (!this.handleScroll) {
      this.handleScroll = true
      const { hasMore, isLoading, scrollElement, isReverse, threshold } = this.props
      if (!hasMore || isLoading) {
        this.handleScroll = false
        return
      }
      const el = this.scrollComponent
      const scrollEl = window
      const parentNode = this.getParentOrGrandparentElement(el)

      let offset
      if (scrollElement === 'window') {
        const doc =
          document.documentElement || document.body.parentNode || document.body
        const scrollTop =
          scrollEl.pageYOffset !== undefined
            ? scrollEl.pageYOffset
            : doc.scrollTop
        if (isReverse) {
          offset = scrollTop
        } else {
          offset = this.calculateOffset(el, scrollTop)
        }
      } else if (isReverse) {
        offset = parentNode.scrollTop
      } else {
        offset = el.scrollHeight - parentNode.scrollTop - parentNode.clientHeight
      }

      // Here we make sure the element is visible as well as checking the offset
      if (
        offset < Number(threshold) &&
        (el && el.offsetParent !== null)
      ) {
        this.detachScrollListener()
        // Call loadMore after detachScrollListener to allow for non-async loadMore functions
        if (typeof this.props.loadMore === 'function') {
          this.props.loadMore((this.pageLoaded += 1))
        }
      }
      this.handleScroll = false
    }
  }

  calculateOffset (el, scrollTop) {
    if (!el) {
      return 0
    }

    return (
      this.calculateTopPosition(el) +
      (el.offsetHeight - scrollTop - window.innerHeight)
    )
  }

  calculateTopPosition (el) {
    if (!el) {
      return 0
    }
    return el.offsetTop + this.calculateTopPosition(el.offsetParent)
  }

  scrollIfNeeded () {
    const { scrollElement, shouldScrollToEnd } = this.props
    if (shouldScrollToEnd) {
      let scrollEl = window
      if (scrollElement !== 'window') {
        scrollEl = this.getParentOrGrandparentElement(this.scrollComponent)
      }
      scrollEl.scrollTo(0, scrollEl.scrollHeight)
    }
  }

  render () {
    const renderProps = this.filterProps(this.props)
    const {
      children,
      element,
      shouldScrollToEnd,
      hasMore,
      initialLoad,
      isReverse,
      loader,
      loadMore,
      pageStart,
      ref,
      threshold,
      useCapture,
      scrollElement,
      isLoading,
      classes,
      isEmpty,
      emptyText,
      emptyComponent,
      emptyComponentProps,
      ...props
    } = renderProps

    props.ref = node => {
      this.scrollComponent = node
      if (ref) {
        ref(node)
      }
    }

    return <React.Fragment>
      {React.createElement(
        element,
        props,
        [children])}
      {isLoading &&
      <div className={classes.loader}><LoadMore/></div>}
      {!isLoading && isEmpty && !emptyComponent &&
      <div className={classes.emptyText}>{emptyText || 'No items'}</div>
      }
      {!isLoading && isEmpty && emptyComponent && React.createElement(emptyComponent, emptyComponentProps)}
    </React.Fragment>
  }
}

UrlifeInfiniteScroller.propTypes = {
  children: PropTypes.any.isRequired,
  element: PropTypes.node,
  emptyComponent: PropTypes.object,
  emptyComponentProps: PropTypes.object,
  emptyText: PropTypes.string,
  hasMore: PropTypes.bool.isRequired,
  initialLoad: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isReverse: PropTypes.bool,
  loader: PropTypes.node,
  loadMore: PropTypes.func.isRequired,
  pageStart: PropTypes.number,
  ref: PropTypes.func,
  scrollElement: PropTypes.oneOf(['window', 'parent', 'grandparent']).isRequired,
  shouldScrollToEnd: PropTypes.bool,
  threshold: PropTypes.number,
  useCapture: PropTypes.bool
}

UrlifeInfiniteScroller.defaultProps = {
  element: 'div',
  hasMore: false,
  initialLoad: false,
  isLoading: false,
  isReverse: false,
  loader: null,
  pageStart: 0,
  ref: null,
  scrollElement: 'window',
  shouldScrollToEnd: false,
  threshold: 250,
  useCapture: false
}

export default withStyles(styles)(UrlifeInfiniteScroller)
