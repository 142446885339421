import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import infoBackground from '../../../assets/img/parallaxQuote.svg'
import infoBackgroundSmall from '../../../assets/img/parallaxQuoteDark.svg'

/* eslint-disable sort-keys */
const styles = {
  root: {
    width: '100vw',
    height: 'auto'
  },
  layer: {
    position: 'static',
    height: 'auto'
  },
  group: {
    height: 'auto'
  },
  carouselContainer: {
    marginTop: 0,
    width: '100%',
    height: 'auto',
    ...commonStyles.media(480, {
      height: 550,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 400,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    })
  },
  carouselImg: {
    ...commonStyles.media(1279, {
      height: 560,
      objectFit: 'cover'
    }),
    ...commonStyles.media(480, {
      height: 200
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 400
    })
  },
  container: {
    zIndex: 200,
    ...commonStyles.container(),
    position: 'relative',
    left: -60,
    bottom: '-10vw',
    ...commonStyles.media(1279, {
      ...commonStyles.container(),
      bottom: '-10vw'
    }),
    ...commonStyles.media(480, {
      ...commonStyles.container(),
      bottom: 0
    })
  },
  info: {
    background: `url(${infoBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 85px',
    position: 'absolute',
    paddingLeft: 60,
    width: 590,
    height: 350,
    '& span': {
      display: 'block',
      marginTop: 14,
      color: '#ffffff',
      fontSize: 34,
      fontWeight: 600
    },
    '& p': {
      margin: '4px 0 4px 0',
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 1,
      width: '100%',
      textTransform: 'uppercase'
    },
    ...commonStyles.media(1279, {
      backgroundSize: '51px 30px',
      backgroundPosition: '0 65px',
      paddingLeft: 30,
      paddingTop: 12,
      margin: '0px auto auto 9vw',
      width: 370,
      height: 'auto',
      '& span': {
        marginTop: -1,
        letterSpacing: 'normal',
        fontSize: 32
      },
      '& p': {
        letterSpacing: '0.4px',
        fontSize: 14
      }
    }),
    ...commonStyles.media(480, {
      background: `url(${infoBackgroundSmall})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '51px 30px',
      backgroundPosition: '0 52px',
      paddingLeft: 20,
      paddingTop: 12,
      margin: '0px auto auto 60px',
      width: 313,
      top: 200,
      height: 165,
      '& span': {
        marginTop: 23,
        color: '#323232',
        letterSpacing: 'normal',
        fontSize: 20
      },
      '& p': {
        color: '#323232',
        letterSpacing: '0.4px',
        fontSize: 11
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      backgroundSize: '51px 30px',
      backgroundPosition: '0 52px',
      paddingLeft: 20,
      paddingTop: 12,
      margin: '0px auto auto 50px',
      width: 313,
      top: -40,
      height: 165,
      '& span': {
        marginTop: 23,
        letterSpacing: 'normal',
        fontSize: 20
      },
      '& p': {
        letterSpacing: '0.4px',
        fontSize: 11
      }
    })
  },
  dotsContainer: {
    ...commonStyles.container(),
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    bottom: 50,
    left: 0,
    right: 0
  },
  ...commonStyles.media(1279, {
    ...commonStyles.container(),
    dotsContainer: {
      bottom: 40
    }
  }),
  ...commonStyles.media(480, {
    ...commonStyles.container(),
    dotsContainer: {
      bottom: 35
    }
  }),
  ...commonStyles.mediaDimensions(896, 414, {
    ...commonStyles.container(),
    dotsContainer: {
      bottom: 35
    }
  }),
  dots: {
    paddingLeft: 0,
    listStyle: 'none',
    ...commonStyles.media(1279, {
      paddingLeft: 30,
      marginLeft: '5vw'
    }),
    ...commonStyles.media(480, {
      marginLeft: -10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginLeft: -10
    }),
    '& li': {
      display: 'inline-block'
    },
    '& li > button': {
      color: 'transparent',
      padding: 0,
      border: 'none',
      marginRight: 7,
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: 4,
      background: '#fff',
      opacity: 0.4,
      ...commonStyles.media(480, {
        background: '#666'
      })
    },
    '& li > button:before': {
      display: 'none'
    },
    '& li > button:focus': {
      outline: 0
    },
    '& li.slick-active > button': {
      opacity: 1
    }
  },
  header: {
    width: '100%',
    color: '#fff',
    fontSize: '26px',
    fontWeight: 600,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    zIndex: 2,
    maxWidth: 345,
    '&::after': {
      margin: '15px 0px 30px -100px',
      content: "''",
      display: 'block',
      width: '100%',
      height: '5px',
      backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)'
    },
    ...commonStyles.media(1279, {
      fontSize: '22px',
      margin: 0,
      maxWidth: 305,
      '&::after': {
        margin: '10px auto 30px -60px'
      }
    }),
    ...commonStyles.media(480, {
      color: '#323232',
      fontSize: '18px',
      maxWidth: 260,
      '&::after': {
        margin: '8px auto 15px -30px'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: '18px',
      maxWidth: 260,
      '&::after': {
        margin: '8px auto 15px -30px'
      }
    })
  }
}
/* eslint-enable sort-keys */

const quotes = [
  {
    img: 'https://static.urlifemedia.com/quote_images/Erin.jpg',
    subtitle: ['Erin', 'Family Vacation – Oaxaca, Mexico'],
    text: `These movies are treasures to my family!
        And I love knowing that my tens of thousands of images and video won’t be forgotten.
      `
  },
  {
    img: 'https://static.urlifemedia.com/quote_images/Gwen.jpg',
    subtitle: ['Gwen', 'Ironman Recap'],
    text: 'John absolutely LOVED the video. He said OMG like 15 times. It was perfect!'
  },
  {
    img: 'https://static.urlifemedia.com/quote_images/Kelly.jpg',
    subtitle: ['Kelly', 'Hudson’s First Year'],
    text: 'I absolutely love it – it totally captured Hudson’s personality. It’s perfect!'
  },
  {
    img: 'https://static.urlifemedia.com/quote_images/Tim.jpg',
    subtitle: ['Tim', 'Surprise 25th Anniversary Trip'],
    text: 'The end made me tear up... You guys really blew me away!'
  },
  {
    img: 'https://static.urlifemedia.com/quote_images/Jillian.jpg',
    subtitle: ['Jillian', 'Greece Honeymoon'],
    text: 'I LOVED my URLIFE experience.'
  }
]

class Quotes extends React.PureComponent {
  render () {
    const { classes } = this.props
    const settings = {
      appendDots: dots => (
        <div>
          <ul className={classes.dots}> {dots} </ul>
        </div>
      ),
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      className: 'current',
      dots: true,
      dotsClass: classes.dotsContainer,
      focusOnSelect: true,
      infinite: true,
      pauseOnHover: false,
      slidesToShow: 1,
      speed: 1500,
      swipeToSlide: true
    }
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('Quotes')} className={classes.group} index={30}>
        <ParallaxLayer className={classes.layer} layer='layer5'>
          <div className={classes.root}>
            <Slider {...settings}>
              {quotes.map((item, idx) => {
                return (
                  <div key={idx} className={classes.carouselContainer}>
                    <div className={classes.container}>
                      <div className={classes.info}>
                        <h3 className={classes.header}>User Shout Outs</h3>
                        <span>
                          {item.text}
                        </span>
                        {item.subtitle.map((text, tidx) => {
                          return (
                            <p key={tidx}>{text}</p>
                          )
                        })}
                      </div>
                    </div>
                    <img src={item.img} width="100%" alt="carousel" className={classes.carouselImg}/>
                  </div>
                )
              })}
            </Slider>
          </div>
        </ParallaxLayer>
      </ParallaxGroup >
    )
  }
}

Quotes.propTypes = {
  classes: PropTypes.object.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

export default withStyles(styles)(Quotes)
