import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

import { commonStyles, history } from '../../helpers'
import { getYearStatistics } from '../../actions'

const styles = {
  divider: {
    backgroundColor: '#cccccc',
    height: 1,
    margin: '50px 0px',
    opacity: 0.6
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    backgroundColor: '#f6f6f6',
    display: 'table-cell',
    fontSize: '16px',
    fontWeight: '500',
    justifyContent: 'space-between',
    padding: '20px',
    position: 'sticky',
    top: 106,
    whiteSpace: 'nowrap',
    width: 'auto',
    zIndex: '1',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  headerRow: {
    alignItems: 'center',
    display: 'table-row'
  },
  paper: {
    background: 'none'
  },
  titleText: {
    padding: '20px'
  },
  userProjectsLink: {
    '&:hover': {
      textDecoration: 'none'
    },
    color: '#01b7d7',
    textDecoration: 'underline'
  },
  yearStatisticsCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    maxWidth: 600,
    overflow: 'hidden',
    padding: '10px 20px',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  yearStatisticsRow: {
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    display: 'table-row',
    titleText: {
      margin: '10px 0px 10px 10px'
    }
  },
  yearStatisticsTable: {
    textAlign: 'right'
  }
}

class AdminReports extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      totalCompletedProjects: 0,
      totalProjectCreatedCount: 0,
      totalUserAccounts: 0,
      yearStatistics: null
    }
  }

  async fetchYearStatistics () {
    const { getYearStatisticsFunction } = this.props
    try {
      const data = await getYearStatisticsFunction()
      this.setState({ yearStatistics: data })
    } catch (e) {}
  }

  componentDidMount () {
    this.fetchYearStatistics()
  }

  calculateTotals () {
    const { yearStatistics } = this.state
    let totalUserAccounts = 0
    let totalCompletedProjects = 0
    let totalProjectCreatedCount = 0
    if (yearStatistics) {
      Object.keys(yearStatistics).forEach((year) => {
        totalUserAccounts += yearStatistics[year]?.count?.userCreatedCount || 0
        totalCompletedProjects += yearStatistics[year]?.count?.projectCompletedCount || 0
        totalProjectCreatedCount += yearStatistics[year]?.count?.projectCreatedCount
      })
    }
    this.setState({
      totalCompletedProjects,
      totalProjectCreatedCount,
      totalUserAccounts
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps !== this.props || prevState.yearStatistics !== this.state.yearStatistics) {
      this.calculateTotals()
    }
  }

  render () {
    const { classes } = this.props
    const { yearStatistics, totalUserAccounts, totalCompletedProjects, totalProjectCreatedCount } = this.state

    const formatDate = (dateString) => {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
      return new Date(dateString).toLocaleDateString('en-US', options)
    }

    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h4" component="h2" className={classes.titleText}>
            <span>New user / Projects by year</span>
          </Typography>
          <div className={classes.yearStatisticsTable}>
            <div className={classes.headerRow}>
              <div className={classes.headerCol} style={{ textAlign: 'left' }}>Year</div>
              <div className={classes.headerCol}>TOTAL New user account started</div>
              <div className={classes.headerCol}>TOTAL Completed projects</div>
              <div className={classes.headerCol}>TOTAL Created projects</div>
            </div>
            {yearStatistics &&
            Object.keys(yearStatistics).map((year) => {
              return (
                <div
                  data-testid="apl-yearstatistics"
                  key={`${year}-totals`}
                  className={classes.yearStatisticsRow}
                >
                  <div className={classes.yearStatisticsCol} style={{ textAlign: 'left' }}>{year}</div>
                  <div className={classes.yearStatisticsCol}>
                    {yearStatistics[year].count.userCreatedCount || 0}
                  </div>
                  <div className={classes.yearStatisticsCol}>
                    {yearStatistics[year].count.projectCompletedCount || 0}
                  </div>
                  <div className={classes.yearStatisticsCol}>
                    {yearStatistics[year].count.projectCreatedCount || 0}
                  </div>
                </div>
              )
            })}
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Total</div>
              <div className={classes.headerCol}>{totalUserAccounts}</div>
              <div className={classes.headerCol}>{totalCompletedProjects}</div>
              <div className={classes.headerCol}>{totalProjectCreatedCount}</div>
            </div>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.yearStatisticsTable}>
            <div className={classes.headerRow}>
              <div className={classes.headerCol} style={{ textAlign: 'left' }}>User</div>
              <div className={classes.headerCol} style={{ textAlign: 'left' }}>Email</div>
              <div className={classes.headerCol}>Date account started</div>
              <div className={classes.headerCol}>Completed projects</div>
              <div className={classes.headerCol}>Created projects</div>
            </div>
            {yearStatistics &&
            Object.keys(yearStatistics).map((year) => {
              if (yearStatistics[year].users.length > 0) {
                return (
                  <>
                    <div
                      data-testid="apl-yearstatistics"
                      key={`${year}-statistics`}
                      className={classes.yearStatisticsRow}
                    >
                      <div className={classes.headerCol} style={{ textAlign: 'left' }}>
                        {year}
                      </div>
                    </div>
                    {yearStatistics[year].users.map((user) => {
                      return (
                        <div
                          data-testid="apl-yearstatistics"
                          key={`${year}-${user.name}`}
                          className={classes.yearStatisticsRow}
                        >
                          <div className={(classes.userProjectsLink)}
                            onClick={(e) => {
                              e.stopPropagation()
                              history.push('/admin/users/' + user.id)
                            }}
                            style={{ cursor: 'pointer', textAlign: 'left' }}
                          >
                            {user.name}
                          </div>
                          <div className={classes.yearStatisticsCol} style={{ textAlign: 'left' }}>
                            {user.email}
                          </div>
                          <div className={classes.yearStatisticsCol}>
                            {formatDate(user.createdAt)}
                          </div>
                          <div className={classes.yearStatisticsCol}>
                            {user.count.projectCompletedCount || 0}
                          </div>
                          <div className={classes.yearStatisticsCol}>
                            {user.count.projectCreatedCount || 0}
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              }
              return null
            })}
          </div>
        </Paper>
      </div>
    )
  }
}

AdminReports.propTypes = {
  classes: PropTypes.object.isRequired,
  getYearStatisticsFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    getYearStatisticsFunction: function () {
      return dispatch(getYearStatistics())
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(AdminReports))
