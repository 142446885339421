import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PasswordShow (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <g><g><g><g><path d="M8.567 11.293c-1.626 0-2.949-1.31-2.949-2.92 0-1.609 1.323-2.918 2.95-2.918 1.626 0 2.948 1.31 2.948 2.919 0 .314-.257.57-.575.57a.573.573 0 0 1-.576-.57 1.79 1.79 0 0 0-1.798-1.78 1.79 1.79 0 0 0-1.797 1.78c0 .98.806 1.78 1.797 1.78A1.8 1.8 0 0 0 9.95 9.51a.58.58 0 0 1 .81-.074c.246.201.279.56.076.802a2.955 2.955 0 0 1-2.269 1.054zM8.39 13.6c-2.908 0-5.683-1.6-8.27-4.724a.617.617 0 0 1 .002-.734C.254 7.954 3.696 3.4 8.619 3.4c2.908 0 5.684 1.6 8.25 4.743a.588.588 0 0 1 .02.715c-.134.188-3.575 4.742-8.498 4.742zM1.357 8.482c2.243 2.615 4.6 3.932 7.034 3.932 3.574 0 6.387-2.88 7.262-3.896-2.243-2.615-4.6-3.932-7.034-3.932-3.574 0-6.388 2.879-7.262 3.896z" /></g></g><g><path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="20" d="M16 1.5L.5 15.5" /></g></g></g>
    </SvgIcon>
  )
}
