import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  createPrompt,
  deletePrompt, getAllPromptsCategory, updatePrompt
} from '../../actions'
import { commonStyles } from '../../helpers'
import { MenuItem, Select } from '@material-ui/core'
import EditTable from './EditTable/EditTable'

const styles = {
  selectCategoryBlock: {
    alignItems: 'center',
    display: 'flex'
  },
  selectCategoryLabel: {
    ...commonStyles.text({
      color: '#000',
      fontSize: 14,
      fontWeight: 700,
      marginRight: 10
    })
  }
}

class EditPrompts extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSortAnimationId: false,
      creatingNewItem: false,
      duplicateDisplayOrder: [],
      error: '',
      items: [],
      newItem: false,
      selectedCategory: props.categories && props.categories.length > 0 ? props.categories[0].id : 0,
      sortAnimationToBottomId: false,
      sortAnimationToTopId: false
    }
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }

      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
    this.fieldSettings = [
      {
        default: true,
        field: 'isHidden',
        name: 'Active',
        type: 'checkboxInverted',
        width: 90
      },
      {
        field: 'id',
        name: 'ID',
        type: 'string',
        width: 90
      },
      {
        field: 'name',
        name: 'Name',
        type: 'text',
        width: 200
      },
      {
        field: 'prompt',
        name: 'Prompt text',
        type: 'textMultiLine'
      },
      {
        field: 'displayOrder',
        name: 'Sorting',
        type: 'sort',
        width: 130
      }
    ]
  }

  componentDidMount () {
    const { categories } = this.props
    const { selectedCategory } = this.state
    if (categories.length > selectedCategory) {
      this.getPrompts(categories[selectedCategory].id)
    }
  }

  /**
   * @param {object} field
   * @param {object} fieldsData
   * @param {boolean} [apply] - save to sever after updating data
   * @param {boolean} [needSort] - sort after changing
   */
  onChangeField (id, fieldsData, apply = false, needSort = false, callback = null) {
    const { items, duplicateDisplayOrder } = this.state

    const newStateData = {}

    newStateData.items = items.map(field => (
      field.id === id
        ? { ...field, ...fieldsData }
        : field
    ))
    if (needSort) newStateData.items = newStateData.items.sort(this.compareFields)

    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = items.filter(it => it.id !== id &&
        it.displayOrder === +fieldsData.displayOrder
      ).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(id)
        ? duplicateDisplayOrder.concat(id)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== id)
    }

    this.setState(newStateData, callback)

    if (apply) this.applyChanges(id, fieldsData)
  }

  /* exchange 2 items positions with animation */
  exchangeFieldOrderPosition (currentItemIndex, changing) {
    const { items } = this.state

    const exchangeItemIndex = currentItemIndex + changing
    const currentItemPosition = items[currentItemIndex].displayOrder
    const exchangeItemPosition = items[exchangeItemIndex].displayOrder
    const target = changing > 0 ? 'bottom' : 'top'

    // start animation
    this.setState({
      activeSortAnimationId: items[currentItemIndex].id,
      sortAnimationToBottomId: items[target === 'bottom' ? currentItemIndex : exchangeItemIndex].id,
      sortAnimationToTopId: items[target === 'top' ? currentItemIndex : exchangeItemIndex].id
    })

    // end animation with timeout
    window.setTimeout(() => {
      this.setState({
        activeSortAnimationId: false,
        items: items.map((field, fieldIndex) => {
          if (fieldIndex === currentItemIndex) return { ...field, displayOrder: exchangeItemPosition }
          if (fieldIndex === exchangeItemIndex) return { ...field, displayOrder: currentItemPosition }
          return field
        }).sort(this.compareFields),
        sortAnimationToBottomId: false,
        sortAnimationToTopId: false
      })
    }, 600)

    // apply
    if (items[currentItemIndex].id) {
      this.applyChanges(
        items[currentItemIndex].id,
        { displayOrder: exchangeItemPosition }
      )
    }
    if (items[exchangeItemIndex].id) {
      this.applyChanges(
        items[exchangeItemIndex].id,
        { displayOrder: currentItemPosition }
      )
    }
  }

  // check filing items fields
  validateItem (item) {
    const { duplicateDisplayOrder } = this.state
    // filter errors
    return this.fieldSettings.filter(fieldSettingsItem => {
      const fieldName = fieldSettingsItem.field
      const value = item[fieldName]
      if (fieldName === 'id') return false

      return value.toString().length === 0
    }).length === 0 && !duplicateDisplayOrder.includes(item.tempId || item.id)
  }

  onChangeNewItem (fieldsData) {
    const { newItem, items, duplicateDisplayOrder } = this.state

    const newStateData = {
      newItem: { ...newItem, ...fieldsData }
    }
    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = fieldsData.displayOrder &&
        items.filter(it => it.id !== newItem.tempId &&
        it.displayOrder === +fieldsData.displayOrder).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(newItem.tempId)
        ? duplicateDisplayOrder.concat(newItem.tempId)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== newItem.tempId)
    }
    this.setState(newStateData)
  }

  /**
   * @param {string|null} id
   * @param {object} fieldsData
   */
  applyChanges (id, fieldsData) {
    const { updatePromptFunction } = this.props
    const { selectedCategory } = this.state
    updatePromptFunction(selectedCategory, id, fieldsData)
  }

  /**
   * add new item without saving
   * */
  addNewTempItem () {
    const { items } = this.state
    const sortOrderValues = items.map(item => item.displayOrder ? +item.displayOrder : 0)
    const maxSortOrder = items.length ? Math.max(...sortOrderValues) : 0

    const newItem = this.fieldSettings.reduce((ob, fieldSettingsItem) => {
      if (fieldSettingsItem.field === 'displayOrder') {
        ob[fieldSettingsItem.field] = maxSortOrder + 100
      } else {
        ob[fieldSettingsItem.field] = typeof fieldSettingsItem.default !== 'undefined' ? fieldSettingsItem.default : ''
      }
      return ob
    }, {
      tempId: `newItem-${Date.now()}`
    })
    this.setState({ newItem })
  }

  onSubmitNewItem (callback = null) {
    const { newItem: { tempId, id, ...useItem }, selectedCategory } = this.state
    const { createPromptFunction } = this.props

    this.setState({
      creatingNewItem: true,
      error: ''
    }, () => {
      createPromptFunction(selectedCategory, useItem)
        .then(
          response => {
            const newItem = response.data[0]
            const newState = {
              creatingNewItem: false,
              newItem: false
            }
            if (response.data && response.data.length) {
              newState.items = this.state.items
                .concat(newItem)
                .sort(this.compareFields)
            }
            this.setState(
              newState,
              () => {
                callback && callback(newItem.id)
              }
            )
          }
        ).catch(e => {
          this.setState({
            creatingNewItem: false,
            error: e.message
          })
        })
    })
  }

  onDeleteItem (id) {
    const { deletePromptFunction } = this.props
    const { selectedCategory } = this.state

    this.setState({
      items: this.state.items.filter(item => item.id !== id)
    })
    deletePromptFunction(selectedCategory, id)
  }

  async getPrompts (categoryId) {
    const { getAllPromptsCategoryFunction } = this.props
    const prompts = await getAllPromptsCategoryFunction(categoryId, 'all')
    if (prompts && Array.isArray(prompts)) {
      this.setState({
        items: prompts
      })
    }
  }

  handleChangeCategory (e) {
    this.setState({
      selectedCategory: e.target.value
    })
    this.getPrompts(e.target.value)
  }

  render () {
    const { classes, categories } = this.props
    const {
      selectedCategory,
      items,
      newItem,
      creatingNewItem,
      sortAnimationToTopId,
      sortAnimationToBottomId,
      activeSortAnimationId,
      duplicateDisplayOrder,
      error
    } = this.state

    return (
      <EditTable
        title={'Edit prompts'}
        filterComponent={categories.length > 0
          ? <div className={classes.selectCategoryBlock}>
            <div className={classes.selectCategoryLabel}>Parent Category:</div>
            <Select value={selectedCategory} onChange={this.handleChangeCategory.bind(this)}>
              {categories.map(cat => (
                <MenuItem key={cat.id} value={cat.id}>{cat.name || `No name ${cat.id}`}</MenuItem>
              ))}
            </Select>
          </div>
          : null
        }
        items={items}
        fieldList={this.fieldSettings}
        newItem={newItem}
        newItemIsValid={!creatingNewItem && newItem && this.validateItem(newItem)}
        sortAnimationToTopId={sortAnimationToTopId}
        sortAnimationToBottomId={sortAnimationToBottomId}
        activeSortAnimationId={activeSortAnimationId}
        duplicateDisplayOrder={duplicateDisplayOrder}
        errorMessage={error}
        onChangeField={this.onChangeField.bind(this)}
        exchangeFieldOrderPosition={this.exchangeFieldOrderPosition.bind(this)}
        addNewTempItem={this.addNewTempItem.bind(this)}
        newButtonLabel={'New prompt'}
        onChangeNewItem={this.onChangeNewItem.bind(this)}
        onSubmitNewItem={this.onSubmitNewItem.bind(this)}
      />
    )
  }
}

EditPrompts.propTypes = {
  categories: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  createPromptFunction: PropTypes.func.isRequired,
  deletePromptFunction: PropTypes.func.isRequired,
  getAllPromptsCategoryFunction: PropTypes.func.isRequired,
  updatePromptFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createPromptFunction: function (categoryId, promptData) {
      return dispatch(createPrompt(categoryId, promptData))
    },
    deletePromptFunction: function (categoryId, promptId) {
      return dispatch(deletePrompt(categoryId, promptId))
    },
    getAllPromptsCategoryFunction: function (categoryId, filter) {
      return dispatch(getAllPromptsCategory(categoryId, filter))
    },
    updatePromptFunction: function (categoryId, promptId, promptData) {
      return dispatch(updatePrompt(categoryId, promptId, promptData))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditPrompts))
