import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  deleteCuratedFile,
  deleteFile,
  getProducerFavoritesTotalSize,
  updateFile,
  updateLocalFile
} from '../../../actions'
import ModalPreviewProjectFile from './ModalPreviewProjectFile'
import FilesHeader from './FilesHeader'
import TileView from './TileView'
import CarouselView from './CarouselView'
import { getFilePermissions } from '../../../helpers'
import UploadMsg from '../UploadMsg'

const styles = {
  filesViewContainer: {
    margin: '10px 0 30px'
  }
}

class FilesView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      errorFileName: '',
      fileNotesState: false,
      openFileId: false,
      selectedFiles: new Set(),
      view: props.type === 'toggle' ? 'tile' : props.type
    }
  }

  toggleView () {
    const { view } = this.state

    this.setState({
      view: view === 'carousel' ? 'tile' : 'carousel'
    })
  }

  openFile (fileId) {
    this.setState({ openFileId: fileId })
  }

  onCloseFile () {
    this.setState({ fileNotesState: false, openFileId: false })
  }

  onChangeOpenSideIndex (changeIndex) {
    const { files } = this.props
    const openFileIndex = this.getOpenFileIndex()
    const nextFileIndex = openFileIndex + changeIndex

    if (nextFileIndex < files.length && nextFileIndex >= 0) {
      this.setState({ openFileId: files[nextFileIndex].id })
    }
  }

  getOpenFileIndex () {
    return this.props.files.map(file => file.id).indexOf(this.state.openFileId)
  }

  saveFavoriteStatus (file, favorite) {
    const {
      project,
      userId,
      roleId,
      isCurated,
      updateLocalFileFunction,
      updateFileFunction
    } = this.props
    const permissions = getFilePermissions(file, project, userId, roleId, isCurated)

    if (permissions.canFavorite) {
      updateFileFunction({ favorite: favorite, id: file.id }, project.id)
        .then(
          () => {
            updateLocalFileFunction({ favorite: favorite, id: file.id })
          }
        )
    }
  }

  saveProducerFavoriteStatus (file, favorite) {
    const {
      project,
      userId,
      roleId,
      isCurated,
      updateLocalFileFunction,
      updateFileFunction,
      getProducerFavoritesTotalSizeFunction
    } = this.props
    const permissions = getFilePermissions(file, project, userId, roleId, isCurated)

    if (permissions.canProdFavorite) {
      updateFileFunction({ id: file.id, producerFavorite: favorite }, project.id)
        .then(
          () => {
            updateLocalFileFunction({ id: file.id, producerFavorite: favorite })
            getProducerFavoritesTotalSizeFunction(project.id)
          }
        )
    }
  }

  async onFileDelete () {
    const {
      files,
      project,
      deleteFileFunction,
      updateProject,
      isCurated,
      deleteCuratedFileFunction
    } = this.props
    const { openFileId } = this.state
    const openFileIndex = this.getOpenFileIndex()

    if (files.length === 1) {
      this.onCloseFile()
    } else {
      this.onChangeOpenSideIndex(openFileIndex === files.length - 1 ? -1 : 1)
    }
    if (isCurated) {
      deleteCuratedFileFunction(project.id, project.projectSkus[0].id, openFileId)
    } else {
      await deleteFileFunction(project.id, openFileId)
      if (project.coverFileId === openFileId) {
        updateProject({ coverFileId: '' })
      }
    }
  }

  onSelect (fileId, selected) {
    const { selectedFiles } = this.state
    if (selected) {
      selectedFiles.add(fileId)
    } else {
      selectedFiles.delete(fileId)
    }
    this.setState({ selectedFiles: selectedFiles })
  }

  deselectAll () {
    const { selectedFiles } = this.state
    selectedFiles.clear()
    this.setState({ selectedFiles: selectedFiles })
  }

  toggleNoteExpand () {
    this.setState({ fileNotesState: !this.state.fileNotesState })
  }

  openFileNotes (fileId) {
    this.setState({ fileNotesState: true, openFileId: fileId })
  }

  setFileError (errorMsg, fileName) {
    this.setState({ error: errorMsg, errorFileName: fileName })
  }

  closeErrorPopup () {
    this.setState({ error: '', errorFileName: '' })
  }

  sortFiles (files) {
    const sortedFiles = files.sort((a, b) => {
      const aLastModified = a.lastModified || a.last_modified
      const bLastModified = b.lastModified || b.last_modified
      return new Date(aLastModified) - new Date(bLastModified)
    })
    return sortedFiles
  }

  render () {
    const {
      classes,
      type,
      project,
      edit,
      isCurated,
      canUpload,
      title,
      userId,
      roleId,
      updateProject
    } = this.props
    const {
      view,
      openFileId,
      selectedFiles,
      fileNotesState,
      error,
      errorFileName
    } = this.state
    const files = this.sortFiles(this.props.files)
    const permissions = getFilePermissions(null, project, userId, roleId, isCurated)
    return (
      <div className={classes.filesViewContainer}>
        {openFileId &&
          <ModalPreviewProjectFile
            toggleNoteExpand={this.toggleNoteExpand.bind(this)}
            fileNotesState={fileNotesState}
            onFileDelete={this.onFileDelete.bind(this)}
            onChangeFileIndex={this.onChangeOpenSideIndex.bind(this)}
            saveFavoriteStatus={this.saveFavoriteStatus.bind(this)}
            saveProducerFavoriteStatus={this.saveProducerFavoriteStatus.bind(this)}
            fileId={openFileId}
            onClose={this.onCloseFile.bind(this)}
            project={project}
            files={files}
            isCurated={isCurated}
            setFileError={this.setFileError.bind(this)}
            updateProject={updateProject}
          />
        }
        <FilesHeader
          projectId={project.id}
          projectTitle={project.title}
          projectSkuId={project.projectSkus[0].id}
          files={files}
          coverFileId={project.coverFileId}
          selectedFiles={selectedFiles}
          isCurated={isCurated}
          title={title}
          view={view}
          permissions={permissions}
          onViewChange={type === 'toggle' ? this.toggleView.bind(this) : null}
          deselectAll={this.deselectAll.bind(this)}
          canUpload={canUpload}
          saveFavoriteStatus={this.saveFavoriteStatus.bind(this)}
          saveProducerFavoriteStatus={this.saveProducerFavoriteStatus.bind(this)}
          setFileError={this.setFileError.bind(this)}
          updateProject={updateProject}
        />
        {view === 'tile' &&
        <TileView
          files={files}
          selectedFiles={selectedFiles}
          project={project}
          edit={edit}
          isCurated={isCurated}
          canUpload={canUpload}
          openFileNotes={this.openFileNotes.bind(this)}
          onOpen={this.openFile.bind(this)}
          onSelected={this.onSelect.bind(this)}
          saveFavoriteStatus={this.saveFavoriteStatus.bind(this)}
          saveProducerFavoriteStatus={this.saveProducerFavoriteStatus.bind(this)}
          setFileError={this.setFileError.bind(this)}
          updateProject={updateProject}
        />
        }
        {view === 'carousel' &&
        <CarouselView
          project={project}
          edit={edit}
          files={files}
          showNotes={!isCurated}
          isCurated={isCurated}
          onOpen={this.openFile.bind(this)}
          saveFavoriteStatus={this.saveFavoriteStatus.bind(this)}
          saveProducerFavoriteStatus={this.saveProducerFavoriteStatus.bind(this)}
          setFileError={this.setFileError.bind(this)}
          updateProject={updateProject}
        />
        }
        {error &&
        <UploadMsg
          title={''}
          specText={error}
          warningText={errorFileName}
          onClose={this.closeErrorPopup.bind(this)}
        />
        }
      </div>
    )
  }
}

FilesView.propTypes = {
  canUpload: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  deleteCuratedFileFunction: PropTypes.func,
  deleteFileFunction: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  files: PropTypes.array.isRequired,
  getProducerFavoritesTotalSizeFunction: PropTypes.func.isRequired,
  isCurated: PropTypes.bool,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['tile', 'carousel', 'toggle']).isRequired,
  updateFileFunction: PropTypes.func,
  updateLocalFileFunction: PropTypes.func,
  updateProject: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

FilesView.defaultProps = {
  canUpload: false,
  edit: false,
  isCurated: false,
  title: 'Files'
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    deleteCuratedFileFunction: function (projectId, projectSkuId, fileId) {
      dispatch(deleteCuratedFile(projectId, projectSkuId, fileId))
    },
    deleteFileFunction: function (projectId, fileId) {
      return dispatch(deleteFile(projectId, fileId))
    },
    getProducerFavoritesTotalSizeFunction: function (projectId) {
      return dispatch(getProducerFavoritesTotalSize(projectId))
    },
    updateFileFunction (file, projectId) {
      return dispatch(updateFile(file, projectId))
    },
    updateLocalFileFunction (file) {
      dispatch(updateLocalFile(file))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FilesView))
