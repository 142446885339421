import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PackageList from './components/PackageList'
import { setTopBarHeaders } from '../../../actions'
import { history } from '../../../helpers'

class ChoosePackage extends React.Component {
  constructor (props) {
    super(props)
    this.skinned = false
  }

  setHeader () {
    const {
      setTopBarHeadersFunction,
      skinData,
      projectId
    } = this.props
    const fromCreationFlow = projectId &&
      history.location.pathname === `/createmovie/start/${projectId}`
    const skinned = !!skinData
    this.skinned = skinned
    const header = skinned
      ? ['skinned', 'Your custom video']
      : fromCreationFlow
        ? ['My Project', 'Change your package']
        : ['New Project', 'Choose your package']
    setTopBarHeadersFunction(...header)
  }

  componentDidMount () {
    this.setHeader()
  }

  render () {
    const {
      nextStep,
      skinData,
      updateProjectSku,
      projectId,
      projectSku
    } = this.props
    const skinned = !!skinData
    !this.skinned && skinned && this.setHeader()
    return (
      <PackageList
        nextStep={nextStep}
        updateProjectSku={updateProjectSku}
        projectId={projectId}
        projectSku={projectSku}
      />
    )
  }
}

ChoosePackage.propTypes = {
  nextStep: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  projectSku: PropTypes.object,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  updateProjectSku: PropTypes.func
}

function mapStateToProps (state) {
  return {
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function (stepHeader, header) {
      dispatch(setTopBarHeaders(stepHeader, header))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePackage)
