import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  updateSystemFile
}

function updateSystemFile (file, identityId) {
  return dispatch => {
    return new ApiService(api.ONE_SYSTEM_FILE,
      { fileId: file.id }, { identityId }, true, dispatch)
      .put(file)
  }
}
