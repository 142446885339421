import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function DateIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 23">
      <defs><linearGradient id="elxva" x1=".17" x2="24.82" y1="-.08" y2="23.13" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1" /><stop offset="1" stopColor="#bd6ad3" /></linearGradient></defs><g><g><path fill="url(#elxva)" d="M7.446 4.154a.451.451 0 1 1-.902 0 .451.451 0 0 1 .902 0zm1.359 0c0-.998-.812-1.81-1.81-1.81s-1.81.812-1.81 1.81.813 1.81 1.81 1.81c.998 0 1.81-.812 1.81-1.81zm9.647 0c0 .249-.2.45-.446.45a.45.45 0 0 1-.447-.45c0-.248.2-.45.447-.45a.45.45 0 0 1 .446.45zm1.36 0c0-.998-.81-1.81-1.806-1.81a1.81 1.81 0 0 0 0 3.62 1.81 1.81 0 0 0 1.805-1.81zm3.65 4.25v12.83a.538.538 0 0 1-.537.539H2.071a.539.539 0 0 1-.538-.539V8.404zm1.36 12.822V6.824a.68.68 0 1 0-1.359 0v.22H1.533v-5.23c0-.297.242-.539.538-.539h20.854c.297 0 .538.242.538.538v3.14a.68.68 0 1 0 1.359 0v-3.14c0-1.046-.85-1.896-1.897-1.896H2.071C1.026-.083.175.767.175 1.813v19.413c0 1.046.85 1.897 1.896 1.897h20.854c1.046 0 1.897-.85 1.897-1.897z" /></g></g>
    </SvgIcon>
  )
}
