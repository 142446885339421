import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { commonStyles } from '../../../../helpers'
import classNames from 'classnames'
import styled from 'styled-components'

const Header = styled.h2(props => ({
  backgroundImage: `
    linear-gradient(
      135deg,
      ${props.gradientStartColor} 20%,
      ${props.gradientEndColor} 80%
    )
  `
}))

/* eslint-disable sort-keys */
const styles = {
  title: {
    color: 'transparent',
    margin: 0,
    fontFamily: 'Montserrat',
    fontWeight: 900,
    letterSpacing: 1,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    position: 'relative',
    borderRadius: '2px',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',
    '&._fontSize-small': {
      fontSize: 32,
      ...commonStyles.media(1510, {
        fontSize: 30
      }),
      ...commonStyles.media(1420, {
        fontSize: 28
      }),
      ...commonStyles.media(1340, {
        fontSize: 23
      })
    },
    '&._fontSize-medium': {
      fontSize: 32
    }
  }
}
/* eslint-enable sort-keys */

class PackageTitle extends React.Component {
  render () {
    const { classes, packageId, packages, fontSize, skinData } = this.props
    const pack = packages ? packages.filter(pack => pack.id === packageId)[0] : null
    const fontSizeClass = '_fontSize-' + fontSize
    const skinned = !!skinData
    return (
      <Header
        className={classNames(classes.title, fontSizeClass)}
        gradientStartColor={skinned ? skinData.gradientStartColor : '#3d29b1'}
        gradientEndColor={skinned ? skinData.gradientEndColor : '#bd6ad3'}
      >
        {pack && pack.title}
      </Header>
    )
  }
}
PackageTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  fontSize: PropTypes.oneOf(['small', 'medium']).isRequired,
  packageId: PropTypes.string,
  packages: PropTypes.array,
  skinData: PropTypes.object
}

PackageTitle.defaultProps = {
  fontSize: 'medium'
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages,
    skinData: state.skinning.skinData
  }
}

export default withStyles(styles)(connect(mapStateToProps)(PackageTitle))
