import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import UrlifeButton from '../../common/UrlifeButton'
import EmailModal from '../../emailSignUp/EmailModal'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  button: {
    padding: '8px 40px'
  },
  link: {
    color: '#01b7d7',
    cursor: 'pointer',
    display: 'inline',
    margin: 0
  }
})
/* eslint-enable sort-keys */

function InterestedButton ({ modalSettings, buttonText, type }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const text = buttonText || "I'm Interested!"
  return (
    <React.Fragment>
      <EmailModal
        open={open}
        settings={modalSettings}
        onClose={() => setOpen(false)}
      />
      {type === 'button' &&
      <UrlifeButton
        className={classes.button}
        onClick={() => setOpen(true)}
      >
        {text}
      </UrlifeButton>
      }
      {type === 'link' &&
      <div
        className={classes.link}
        onClick={() => setOpen(true)}
      >
        {text}
      </div>
      }
    </React.Fragment>
  )
}

InterestedButton.propTypes = {
  buttonText: PropTypes.string,
  modalSettings: PropTypes.shape({
    disclaimer: PropTypes.string.isRequired,
    logo: PropTypes.function,
    subTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    topics: PropTypes.array.isRequired
  }),
  type: PropTypes.oneOf(['button', 'link']).isRequired
}

InterestedButton.defaultProps = {
  type: 'button'
}

export default InterestedButton
