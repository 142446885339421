import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers/index'
import Parallax from './Parallax'
import Cover from './groups/Cover'
import SalesPitch from './groups/SalesPitch'
import WhatMakesUsDifferent from './groups/WhatMakesUsDifferent'
import Quotes from './groups/Quotes'
import HowItWorks from './groups/HowItWorks'
import GetInspired from './groups/GetInspired'
import ExplorePackages from './groups/ExplorePackages'
import TipsAndTricks from './groups/TipsAndTricks'
import TopQuestions from './groups/TopQuestions'
import Founders from './groups/Founders'

const styles = {
  root: {
    backgroundColor: '#f6f6f6',
    marginTop: '-166px',
    ...commonStyles.media(575, {
      marginTop: '-90px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: '-90px'
    })
  }
}

class UrlifeParallax extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <Parallax direction='vertical' className={classes.root}>
        <Cover/>
        <SalesPitch/>
        <WhatMakesUsDifferent/>
        <Quotes/>
        <HowItWorks/>
        <GetInspired/>
        <ExplorePackages/>
        <TipsAndTricks/>
        <TopQuestions/>
        <Founders/>
      </Parallax>
    )
  }
}

UrlifeParallax.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UrlifeParallax)
