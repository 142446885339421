import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputAdornment } from '@material-ui/core'
import classNames from 'classnames'
import {
  CheckMark,
  CloseIcon,
  PasswordHide,
  PasswordShow
} from './SVG'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  formControl: {
    width: '100%'
  },
  inputLabel: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#666666',
    top: 8,
    '&._focused': {
      color: '#666666'
    },
    '&._error': {
      color: '#666666'
    },
    '&._shrink': {
      transform: 'translate(0, -3.5px) scale(0.875)'
    },
    ...commonStyles.media(480, {
      top: 4
    })
  },
  textField: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    margin: '8px 0',
    color: '#333333',
    ...commonStyles.media(480, {
      margin: '4px 0'
    })
  },
  underline: {
    '&:before': {
      borderBottomColor: '#666666'
    },
    '&:after': {
      borderBottomColor: '#000000'
    },
    '&._success': {
      '&:before': {
        borderBottomColor: '#01b7d7'
      },
      '&:after': {
        borderBottomColor: '#01b7d7'
      }
    },
    '&._error': {
      '&:before': {
        borderBottomColor: '#d70101'
      },
      '&:after': {
        borderBottomColor: '#d70101'
      }
    }
  },
  endAdornment: {
    width: 18,
    display: 'flex',
    alignItems: 'center'
  },
  endIcon: {
    width: 18,
    height: 18,
    color: '#333333',
    cursor: 'default',
    '&._clickable': {
      cursor: 'pointer'
    },
    '&._success': {
      color: '#01b7d7'
    },
    '&._error': {
      color: '#d70101'
    }
  },
  labelBlock: {
    width: '100%',
    height: 22,
    top: 6,
    position: 'relative',
    ...commonStyles.media(480, {
      top: 4
    })
  },
  infoLabel: {
    position: 'absolute',
    left: 0,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    color: '#666666',
    '&._error': {
      color: '#d70101'
    }
  },
  limitLabel: {
    position: 'absolute',
    right: 0,
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    color: '#666666'
  }
}
/* eslint-enable sort-keys */

class UrlifeTextField extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showPassword: false,
      value: props.defaultValue
    }
  }

  handleChange (e) {
    const { onChange } = this.props
    this.setState({ value: e.target.value })
    onChange && onChange(e)
  }

  setShowPassword (show) {
    this.setState({ showPassword: show })
  }

  endAdornment () {
    const { classes, type, validationStatus, disabled } = this.props
    const { showPassword } = this.state

    if (type === 'password' && !disabled) {
      return (
        <InputAdornment position='end' className={classes.endAdornment}>
          {showPassword &&
            <PasswordShow
              className={classNames(classes.endIcon, '_clickable')}
              onClick={this.setShowPassword.bind(this, false)} />
          }
          {!showPassword &&
            <PasswordHide
              className={classNames(classes.endIcon, '_clickable')}
              onClick={this.setShowPassword.bind(this, true)} />
          }
        </InputAdornment>
      )
    }

    if (validationStatus === 'success') {
      return (
        <InputAdornment position='end' className={classes.endAdornment}>
          <CheckMark className={classNames(classes.endIcon, '_success')} />
        </InputAdornment>
      )
    }

    if (validationStatus === 'error') {
      return (
        <InputAdornment position='end' className={classes.endAdornment}>
          <CloseIcon className={classNames(classes.endIcon, '_error')} />
        </InputAdornment>
      )
    }

    return ''
  }

  render () {
    const {
      classes,
      defaultValue,
      infoLabel,
      InputLabelProps,
      maxLength,
      onChange,
      type,
      validationStatus,
      ...otherProps
    } = this.props
    const { value, showPassword } = this.state
    const textType = type === 'password' && showPassword ? 'text' : type
    const valueLength = value ? value.length : 0
    const endAdornment = this.endAdornment()
    return (
      <div className={this.props.className}>
        <FormControl
          variant={'outlined'}
          className={classes.formControl}>
          <TextField
            className={classes.cssUnderline}
            onChange={this.handleChange.bind(this)}
            type={textType}
            value={type === 'date' && this.props.value ? this.props.value : value}
            inputProps={{
              maxLength: maxLength
            }}
            InputProps={{
              classes: {
                input: classes.textField,
                underline: classNames(
                  classes.underline,
                  { _success: validationStatus === 'success' },
                  { _error: validationStatus === 'error' }
                )
              },
              endAdornment: endAdornment
            }}
            InputLabelProps={{
              ...InputLabelProps,
              classes: {
                error: classNames(classes.inputLabel, '_error'),
                focused: classNames(classes.inputLabel, '_focused'),
                root: classes.inputLabel,
                shrink: classNames(classes.inputLabel, '_shrink')
              }
            }}
            {...otherProps}
          />
        </FormControl>
        <div className={classes.labelBlock}>
          {infoLabel.length > 0 &&
          <InputLabel className={classNames(classes.infoLabel, { _error: validationStatus === 'error' })}>
            {infoLabel}
          </InputLabel>
          }
          {maxLength &&
            <InputLabel className={classes.limitLabel}>
              {valueLength + '/' + maxLength}
            </InputLabel>
          }
        </div>
      </div>
    )
  }
}

UrlifeTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  infoLabel: PropTypes.string,
  InputLabelProps: PropTypes.object,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.string,
  validationStatus: PropTypes.oneOf(['none', 'success', 'error']),
  value: PropTypes.string
}

UrlifeTextField.defaultProps = {
  infoLabel: '',
  maxLength: null,
  type: 'text',
  validationStatus: 'none'
}

export default withStyles(styles)(UrlifeTextField)
