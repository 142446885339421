import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PromoIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><g><g><path fill={props.fill ? props.fill : '#999'} d="M20.275 6.06c0 .736-.598 1.335-1.334 1.335a1.336 1.336 0 0 1-1.335-1.335c0-.735.6-1.334 1.335-1.334.736 0 1.334.598 1.334 1.334zm1.603 0a2.94 2.94 0 0 0-2.937-2.937 2.94 2.94 0 0 0-2.937 2.938 2.94 2.94 0 0 0 2.937 2.936 2.94 2.94 0 0 0 2.937-2.937zM9.212 24.694c.843 0 1.632-.325 2.22-.915L22.28 12.932a.801.801 0 1 0-1.133-1.133L10.3 22.647a1.523 1.523 0 0 1-1.087.445H9.21c-.415 0-.801-.158-1.088-.445L2.355 16.88a1.524 1.524 0 0 1-.444-1.088c0-.415.158-.802.445-1.089L14.708 2.351a1.5 1.5 0 0 1 1.003-.44l5.85.003c.842 0 1.528.686 1.53 1.529v5.823a1.515 1.515 0 0 1-.44 1.03.8.8 0 1 0 1.131 1.133c.56-.558.884-1.308.91-2.137v-5.85a3.137 3.137 0 0 0-3.13-3.13L15.685.308a3.11 3.11 0 0 0-2.112.91L1.224 13.57a3.118 3.118 0 0 0-.916 2.22c0 .844.324 1.633.914 2.223L6.99 23.78c.59.59 1.378.914 2.22.914h.002z"/></g></g></g></g>
    </SvgIcon>
  )
}

PromoIcon.propTypes = {
  fill: PropTypes.string
}
