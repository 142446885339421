import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import MarketingPageCTA from '../../marketing/MarketingPageCTA'
import { toggleModal } from '../../../actions'
import UrlifeButton from '../../common/UrlifeButton'
import { AppStoreBadge } from '../../common/SVG'
import different1 from '../../../assets/img/parallaxDifferent1.svg'
import different2 from '../../../assets/img/parallaxDifferent2.svg'
import different3 from '../../../assets/img/parallaxDifferent3.svg'
import different4 from '../../../assets/img/parallaxDifferent4.svg'

/* eslint-disable sort-keys */
const styles = {
  root: {
    width: '100vw',
    height: 'auto'
  },
  layer: {
    position: 'static',
    height: 'auto'
  },
  group: {
    height: 'auto',
    ...commonStyles.media(480, {
      marginBottom: 45
    })
  },
  container: {
    ...commonStyles.container(),
    '& div': {
      opacity: 0,
      transform: 'translate3d(0,20px,0)'
    }
  },
  containerLoaded: {
    '& div': {
      opacity: 1,
      transform: 'translate3d(0,0,0)'
    }
  },
  background: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)',
    ...commonStyles.media(480, {
      marginBottom: -45
    })
  },
  header: {
    margin: '80px auto 56px auto',
    color: '#ffffff',
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    textAlign: 'center',
    '& div': {
      marginRight: 11,
      display: 'inline-block',
      position: 'relative',
      opacity: 0,
      transform: 'translate3d(0,20px,0)'
    },
    ...commonStyles.media(1279, {
      margin: '50px auto 40px auto'
    }),
    ...commonStyles.media(480, {
      margin: '40px auto 35px auto',
      fontSize: 18,
      width: 250
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      margin: '40px auto 35px auto',
      fontSize: 18
    })
  },
  headerLoaded: {
    '& div': {
      opacity: 1,
      transform: 'translate3d(0,0,0)'
    }
  },
  iconWrapper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    width: 100,
    height: 100,
    margin: '0 auto 40px auto',
    ...commonStyles.media(1279, {
      width: 120,
      height: 120,
      marginBottom: 10
    }),
    ...commonStyles.media(480, {
      width: 70,
      height: 70,
      marginBottom: 23
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 70,
      height: 70,
      marginBottom: 23
    })
  },
  iconText: {
    width: '95%',
    margin: '0px auto',
    color: '#ffffff',
    fontSize: 19,
    fontWeight: 600,
    textAlign: 'center',
    ...commonStyles.media(1279, {
      width: 150,
      margin: '0 auto 40px auto',
      fontWeight: 500,
      fontSize: 16
    }),
    ...commonStyles.media(480, {
      width: 134,
      margin: 'auto',
      fontSize: 14,
      lineHeight: '21px',
      marginBottom: 40
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      margin: 'auto',
      fontSize: 14,
      lineHeight: '21px',
      marginBottom: 40
    })
  },
  download: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    ...commonStyles.media(480, {
      display: 'none'
    })
  },
  button: {
    margin: '40px 0px 80px 0px',
    width: 210,
    height: 46,
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      color: '#ffffff',
      border: 'solid 1px #ffffff'
    },
    ...commonStyles.media(480, {
      width: 181,
      height: 40,
      fontSize: 12
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      margin: '20px 0px 40px 0px',
      width: 181,
      height: 40,
      fontSize: 12
    })
  },
  appStoreBadge: {
    margin: '40px 0px 80px 0px',
    ...commonStyles.mediaDimensions(896, 414, {
      margin: '20px 0px 40px 0px'
    })
  }
}
/* eslint-enable sort-keys */

class WhatMakesUsDifferent extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false
    }
  }

  openGetStartedModal () {
    this.props.toggleModal(true, 'getStarted')
  }

  render () {
    const { classes, loggedIn } = this.props
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('WhatMakeUsDifferent')} className={classes.group} index={30}>
        <ParallaxLayer className={classes.layer} layer='layer5'>
          <div className={classes.root}>
            <div className={classes.background}>
              <Grid
                className={`${classes.container} ${classes.containerLoaded}`}
                justify="center"
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <h1 className={`${classes.header} ${classes.headerLoaded}`}>
                    <div>EXPERIENCE</div>
                    <div>THE</div>
                    <div>URLIFE</div>
                    <div>DIFFERENCE</div>
                  </h1>
                </Grid>
                <Grid className={classes.iconWrapper} item xs={6} md={3}>
                  <img
                    src={different1}
                    alt="different1"
                    className={classes.icon}
                  />
                  <p className={classes.iconText}>Talented human producers and editors</p>
                </Grid>
                <Grid className={classes.iconWrapper} item xs={6} md={3}>
                  <img
                    src={different2}
                    alt="different2"
                    className={classes.icon}
                  />
                  <p className={classes.iconText}>Collaborative Storytellers with a Personal Touch</p>
                </Grid>
                <Grid className={classes.iconWrapper} item xs={6} md={3}>
                  <img
                    src={different3}
                    alt="different3"
                    className={classes.icon}
                  />
                  <p className={classes.iconText}>World-Class Tech: Innovative, Easy-to-Use</p>
                </Grid>
                <Grid className={classes.iconWrapper} item xs={6} md={3}>
                  <img
                    src={different4}
                    alt="different4"
                    className={classes.icon}
                  />
                  <p className={classes.iconText}>Delivering Must-See Memories Guaranteed </p>
                </Grid>
                <Grid className={classes.download} item xs={12}>
                  {!loggedIn && <MarketingPageCTA className={classes.button}>
                    <UrlifeButton className={classes.button}
                      onClick={this.openGetStartedModal.bind(this)}>
                      Get Started
                    </UrlifeButton>
                  </MarketingPageCTA>}
                  {loggedIn && <AppStoreBadge className={classes.appStoreBadge} />}
                </Grid>
              </Grid>
            </div>
          </div>
        </ParallaxLayer>
      </ParallaxGroup >
    )
  }
}

WhatMakesUsDifferent.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  setSectionRef: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WhatMakesUsDifferent))
