import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeTextField from '../../common/UrlifeTextField'
import UrlifeButton from '../../common/UrlifeButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import {
  fetchEmailTemplate,
  updateEmailTemplate
} from '../../../actions'
import {
  commonStyles,
  history
} from '../../../helpers/index'
import { BackArrow } from '../../common/SVG/index'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20
  },
  tab: {
    position: 'relative',
    color: '#a2a2a2',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.64,
    lineHeight: '17px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    paddingBottom: 9,
    margin: '8px 16px',
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: 0
    },
    '&._active': {
      color: '#393939'
    },
    '&:hover:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    },
    '&._active:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    }
  },
  wrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  errorBox: {
    margin: '10px 0',
    color: '#d70101',
    opacity: 0
  },
  button: {
    width: 100,
    margin: '10px auto 0 auto'
  },
  variableInsertContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 35
  },
  variableSelect: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 250px)',
    marginLeft: 10,
    marginRight: 25,
    '&:after': {
      borderBottomColor: '#5334b6'
    }
  },
  previewBody: {
    backgroundColor: '#ffffff'
  },
  headerImage: {
    width: '100%'
  },
  previewContent: {
    padding: '30px 23px'
  },
  previewTitle: {
    color: '#323232',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '27px',
    textAlign: 'center',
    '& span': {
      fontWeight: 700
    }
  },
  previewTextBefore: {
    paddingTop: 30,
    color: '#323232',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    '& span': {
      fontWeight: 600
    }
  },
  previewButtonContainer: {
    width: '100%',
    paddingTop: 40,
    display: 'flex',
    justifyContent: 'center'
  },
  previewButton: {
    color: '#ffffff',
    backgroundColor: '#01b7d7',
    width: 255,
    padding: '13px 0 14px',
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '17px',
    textAlign: 'center'
  },
  previewTextAfter: {
    paddingTop: 40,
    color: '#323232',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    '& span': {
      fontWeight: 600
    }
  },
  previewBottomText: {
    paddingTop: 40,
    color: '#323232',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    '& span': {
      color: '#01b7d7',
      textDecoration: 'underline'
    }
  },
  helpTitle: {
    marginTop: 20,
    color: '#323232',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '27px'
  },
  helpText: {
    marginTop: 10,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    '& span': {
      fontWeight: 700
    }
  }
}
/* eslint-enable sort-keys */

class EditEmailTemplate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentTab: 'edit',
      email: null,
      error: '',
      focusedFieldRef: null,
      formValid: false,
      selectedVariable: '',
      taaError: '',
      tbaError: '',
      textAfterAction: [],
      textBeforeAction: [],
      title: '',
      titleError: ''
    }
    this.titleRef = React.createRef()
    this.tbaRef = React.createRef()
    this.taaRef = React.createRef()
  }

  async fetchEmail () {
    const { match, fetchEmailTemplateFunction } = this.props
    try {
      const response = await fetchEmailTemplateFunction(match.params.emailId)
      if (response.data && response.data.length > 0) {
        const email = response.data[0]
        const title = email.title.replace('{{', '[').replace('}}', ']')
        const textBeforeAction = email.textBeforeAction.split('<p>').filter(text => text.length > 0).map(text => {
          return text.trim().replace('</p>', '').replace('{{', '[').replace('}}', ']')
        }).join('\n')
        const textAfterAction = email.textAfterAction.split('<p>').filter(text => text.length > 0).map(text => {
          return text.trim().replace('</p>', '').replace('{{', '[').replace('}}', ']')
        }).join('\n')
        this.setState({
          email,
          selectedVariable: email.availableVariables.length > 0 ? email.availableVariables[0].name : '',
          textAfterAction,
          textBeforeAction,
          title
        }, () => {
          this.formValidator()
        })
      }
    } catch (e) {}
  }

  componentDidMount () {
    this.fetchEmail()
  }

  validateVariableNames (text) {
    const { email } = this.state
    if (text.includes('[]')) {
      return 'Empty variable tag not allowed'
    }
    let splitIndex = text.search(/\[([^\]]+)]/)
    while (splitIndex !== -1) {
      if (splitIndex > 0) {
        text = text.substring(splitIndex)
      }
      splitIndex = text.indexOf(']') + 1
      const variableName = text.substring(0, splitIndex).replace('[', '').replace(']', '')
      if (!email.availableVariables.find(variable => variable.name === variableName)) {
        return 'Unknown variable tag: [' + variableName + ']'
      }
      text = text.substring(splitIndex)
      splitIndex = text.search(/\[([^\]]+)]/)
    }
    return ''
  }

  formValidator () {
    const { email, title, textBeforeAction, textAfterAction } = this.state

    const emailSubjectValid = email.subject.trim().length > 0
    // const emailActionValid = email.action.trim().length > 0

    const titleError = this.validateVariableNames(title)
    const tbaError = this.validateVariableNames(textBeforeAction)
    const taaError = this.validateVariableNames(textAfterAction)

    const formValid =
      emailSubjectValid &&
      // emailActionValid &&
      !titleError &&
      !tbaError &&
      !taaError

    this.setState({ formValid, taaError, tbaError, titleError })
  }

  onValueChange (e, callback = null) {
    const { email } = this.state
    const value = e.target.value
    const name = e.target.name
    const onStateSet = () => {
      this.formValidator()
      callback && callback()
    }

    switch (name) {
      case 'title':
        this.setState({ title: value }, onStateSet)
        break
      case 'tba':
        this.setState({ textBeforeAction: value }, onStateSet)
        break
      case 'taa':
        this.setState({ textAfterAction: value }, onStateSet)
        break
      default:
        this.setState({
          email: {
            ...email,
            [name]: value
          }
        }, onStateSet)
        break
    }
  }

  async onSubmit (e) {
    const { updateEmailTemplateFunction } = this.props
    const { email, title, textBeforeAction, textAfterAction } = this.state
    e.preventDefault()
    email.title = title.trim().replace('[', '{{').replace(']', '}}')
    email.textBeforeAction = ''
    if (textBeforeAction.length > 0) {
      const parts = textBeforeAction.split('\n').filter(text => text.length > 0)
      email.textBeforeAction = '<p>' + parts.join('</p><p>') + '</p>'
      email.textBeforeAction = email.textBeforeAction.replaceAll('[', '{{').replaceAll(']', '}}')
    }
    email.textAfterAction = ''
    if (textAfterAction.length > 0) {
      const parts = textAfterAction.split('\n').filter(text => text.length > 0)
      email.textAfterAction = '<p>' + parts.join('</p><p>') + '</p>'
      email.textAfterAction = email.textAfterAction.replaceAll('[', '{{').replaceAll(']', '}}')
    }
    try {
      await updateEmailTemplateFunction(email.id, email)
      history.goBack()
    } catch (e) {
      this.setState({
        email: email,
        error: 'Something went wrong when updating the email template.'
      })
    }
  }

  insertVariable () {
    const { selectedVariable, focusedFieldRef } = this.state
    if (!focusedFieldRef || !selectedVariable) {
      return
    }
    const selectionStart = focusedFieldRef.current.selectionStart
    const selectionEnd = focusedFieldRef.current.selectionEnd
    const beginning = focusedFieldRef.current.value.substring(0, selectionStart)
    const end = focusedFieldRef.current.value.substring(selectionEnd)
    focusedFieldRef.current.value = beginning + '[' + selectedVariable + ']' + end
    this.onValueChange({ target: focusedFieldRef.current }, () => {
      focusedFieldRef.current.focus()
      const newSelectionStart = selectionStart + selectedVariable.length + 2
      focusedFieldRef.current.setSelectionRange(newSelectionStart, newSelectionStart)
    })
  }

  renderVariableSelector () {
    const { classes } = this.props
    const { email, selectedVariable } = this.state
    if (email.availableVariables.length === 0) {
      return null
    }
    return (
      <div className={classes.variableInsertContainer}>
        <div className={classes.helpText}>Variable tag:</div>
        <Select
          className={classes.variableSelect}
          name='promocodefilter'
          value={selectedVariable}
          onChange={(e) => { this.setState({ selectedVariable: e.target.value }) }}>
          {email.availableVariables.map((variable, index) => (
            <MenuItem key={index} value={variable.name}>
              {'[' + variable.name + '] - ' + variable.description}
            </MenuItem>
          ))}
        </Select>
        <UrlifeButton
          className={classes.button}
          onClick={this.insertVariable.bind(this)}
        >
          Insert
        </UrlifeButton>
      </div>
    )
  }

  formatVariableName (name) {
    const formatted = name.slice(0, 1).toUpperCase() + name.slice(1)
    return formatted.split(/(?=[A-Z])/).join(' ')
  }

  formatForPreview (text) {
    if (text.includes('\n')) {
      return text.split('\n').filter(text => text.length > 0).map((text, index) => (
        <p key={index}>{this.formatForPreview(text)}</p>
      ))
    }
    const textParts = []
    let splitIndex = text.search(/\[([^\]]+)]/)
    while (splitIndex !== -1) {
      if (splitIndex > 0) {
        textParts.push(text.substring(0, splitIndex))
        text = text.substring(splitIndex)
      }
      splitIndex = text.indexOf(']') + 1
      textParts.push(text.substring(0, splitIndex))
      text = text.substring(splitIndex)
      splitIndex = text.search(/\[([^\]]+)]/)
    }
    if (text.length > 0) {
      textParts.push(text)
    }
    return textParts.map(text => {
      if (text.startsWith('[') && text.endsWith(']')) {
        return (<span>[{this.formatVariableName(text.replace('[', '').replace(']', ''))}]</span>)
      }
      return text
    })
  }

  render () {
    const { classes } = this.props
    const {
      email,
      title,
      textBeforeAction,
      textAfterAction,
      currentTab,
      focusedFieldRef,
      titleError,
      tbaError,
      taaError,
      error,
      formValid
    } = this.state
    return (
      <Paper className={classes.paper} elevation={0}>
        <BackArrow
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        {email && <React.Fragment>
          <div className={classes.wrapper}>
            <Typography variant='h4' component='h2'>
              <span>{'Edit Email Template: ' + email.configName}</span>
            </Typography>
            <div className={classes.tabsContainer}>
              <div
                className={classNames(classes.tab, { _active: currentTab === 'edit' })}
                onClick={() => this.setState({ currentTab: 'edit' })}
              >
                Edit
              </div>
              <div
                className={classNames(classes.tab, { _active: currentTab === 'preview' })}
                onClick={() => this.setState({ currentTab: 'preview' })}
              >
                Preview
              </div>
              <div
                className={classNames(classes.tab, { _active: currentTab === 'help' })}
                onClick={() => this.setState({ currentTab: 'help' })}
              >
                Help
              </div>
            </div>
            {currentTab === 'edit' &&
            <form className={classes.formContainer}>
              <UrlifeTextField
                id='email-template-subject'
                name='subject'
                label='Subject'
                autoFocus
                autoComplete={'off'}
                onFocus={() => { this.setState({ focusedFieldRef: null }) }}
                onChange={this.onValueChange.bind(this)}
                defaultValue={email.subject}
                validationStatus={email.subject.length === 0 ? 'error' : 'none'}
                infoLabel={email.subject.length === 0 ? 'Cannot be empty' : ''}
              />
              <UrlifeTextField
                id='email-template-title'
                name='title'
                label='Title'
                autoComplete={'off'}
                inputRef={this.titleRef}
                onChange={this.onValueChange.bind(this)}
                onFocus={() => { this.setState({ focusedFieldRef: this.titleRef }) }}
                value={title}
                validationStatus={titleError ? 'error' : 'none'}
                infoLabel={titleError}
              />
              {focusedFieldRef === this.titleRef && this.renderVariableSelector()}
              <UrlifeTextField
                id='email-template-tba'
                name='tba'
                label='Text before button'
                autoComplete={'off'}
                multiline
                rowsMax={5}
                inputRef={this.tbaRef}
                onChange={this.onValueChange.bind(this)}
                onFocus={() => { this.setState({ focusedFieldRef: this.tbaRef }) }}
                value={textBeforeAction}
                validationStatus={tbaError ? 'error' : 'none'}
                infoLabel={tbaError}
              />
              {focusedFieldRef === this.tbaRef && this.renderVariableSelector()}
              <UrlifeTextField
                id='email-template-action'
                name='action'
                label='Button'
                autoComplete={'off'}
                onFocus={() => { this.setState({ focusedFieldRef: null }) }}
                onChange={this.onValueChange.bind(this)}
                defaultValue={email.action}
                // validationStatus={email.action.length === 0 ? 'error' : 'none'}
                // infoLabel={email.action.length === 0 ? 'Cannot be empty' : ''}
              />
              <UrlifeTextField
                id='email-template-taa'
                name='taa'
                label='Text after button'
                autoComplete={'off'}
                multiline
                rowsMax={5}
                inputRef={this.taaRef}
                onChange={this.onValueChange.bind(this)}
                onFocus={() => { this.setState({ focusedFieldRef: this.taaRef }) }}
                value={textAfterAction}
                validationStatus={taaError ? 'error' : 'none'}
                infoLabel={taaError}
              />
              {focusedFieldRef === this.taaRef && this.renderVariableSelector()}
              <div
                className={classes.errorBox}
                style={error ? { opacity: 1 } : {}}
              >
                {error}
              </div>
              <UrlifeButton
                disabled={!formValid}
                className={classes.button}
                onClick={this.onSubmit.bind(this)}
              >
                Update
              </UrlifeButton>
            </form>
            }
            {currentTab === 'preview' &&
            <React.Fragment>
              <div className={classes.previewBody}>
                <img
                  src='https://static.urlifemedia.com/email/header-gradient.png'
                  alt="header"
                  className={classes.headerImage}
                />
                <div className={classes.previewContent}>
                  {title &&
                  <div className={classes.previewTitle}>{this.formatForPreview(title)}</div>
                  }
                  {textBeforeAction &&
                  <div className={classes.previewTextBefore}>{this.formatForPreview(textBeforeAction)}</div>
                  }
                  {email.action &&
                  <div className={classes.previewButtonContainer}>
                    <div className={classes.previewButton}>{email.action}</div>
                  </div>
                  }
                  {textAfterAction &&
                  <div className={classes.previewTextAfter}>{this.formatForPreview(textAfterAction)}</div>
                  }
                  <div className={classes.previewBottomText}>
                    <p>Best regards,</p>
                    <div>URLIFE Media Team</div>
                    <span><div>hello@urlifemedia.com</div></span>
                  </div>
                </div>
              </div>
            </React.Fragment>
            }
            {currentTab === 'help' &&
            <React.Fragment>
              <div className={classes.helpTitle}>Variable tags</div>
              <div className={classes.helpText}>
                <p>Variable tags are key words in brackets that can be inserted in the “Title”, “Text before button”
                  and “Text after button” fields. Variable tags can be used to include things like user names and
                  project titles in the email. For example, by using the tag <span>[userName]</span> the name of the
                  recipient will be inserted at this point in the email when it is sent.</p>
                {email.availableVariables.length > 0 && <React.Fragment>
                  <p>Different email templates have different tags available.
                    For this template the available tags are:</p>
                  <ul>
                    {email.availableVariables.map((variable, index) => (
                      <li key={index}>
                        <span>{'[' + variable.name + ']'}</span>
                        {' - ' + variable.description}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>}
                {email.availableVariables.length === 0 &&
                <p>Different email templates have different tags available.
                  This template has no available tags.</p>
                }
              </div>
            </React.Fragment>
            }
          </div>
        </React.Fragment>}
        {!email && <div>Loading email template</div>}
      </Paper>
    )
  }
}

EditEmailTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchEmailTemplateFunction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  updateEmailTemplateFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchEmailTemplateFunction: function (emailId) {
      return dispatch(fetchEmailTemplate(emailId))
    },
    updateEmailTemplateFunction: function (groupId, grouping) {
      return dispatch(updateEmailTemplate(groupId, grouping))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EditEmailTemplate))
