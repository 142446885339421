import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import {
  createNotification,
  getAllNotifications,
  setUserInfo,
  updateNotification
} from '../../actions'
import { commonStyles } from '../../helpers'
import { BUILD_ID, NOTIFICATION_TYPES, ROLES } from '../../constants'
import UrlifePageInfo from '../common/UrlifePageInfo'
import ChangePassword from './components/ChangePassword'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'transparent',
    padding: '0px 60px'
  },
  wrapperWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonWrapper: {
    position: 'relative'
  },
  saveButton: {
  },
  textField: {
    margin: 0
  },
  notificationsTable: {
    marginTop: '20px',
    border: 'none',
    width: '100%',
    borderRadius: 4,
    borderCollapse: 'collapse',
    ...commonStyles.text({
      color: '#979797'
    })
  },
  notificationsHeader: {
    fontWeight: 400,
    color: '#afafaf',
    fontSize: 14,
    padding: '15px 0px',
    textAlign: 'left',
    position: 'sticky',
    top: 89,
    zIndex: 2
  },
  notificationCell: {
    padding: '10px 0px'
  },
  buildNumber: {
    color: '#979797',
    fontSize: 12,
    padding: '0px 60px',
    position: 'absolute',
    bottom: 5
  }
}
/* eslint-enable sort-keys */

export class UrlifeSettingsPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      failedSave: false,
      fullname: props.fullname || '',
      notifications: [],
      saved: false,
      saving: false
    }
    this.handleChange = () => (event) => {
      const { name, value } = event.target
      this.setState({ failedSave: false, [name]: value, saved: false })
    }
    this.handleSave = () => {
      const { fullname } = this.state
      const { setUserInfoFunction } = this.props
      this.setState({ failedSave: false, saved: false, saving: true })
      setUserInfoFunction({ fullname }).then(
        user => {
          this.setState({
            fullname: user.data.fullname || '',
            saved: true,
            saving: false
          })
        },
        fail => {
          this.setState({
            failedSave: true,
            saving: false
          })
        }
      )
    }
    this.closePageInfo = (name) => () => {
      this.setState({ [name]: false })
    }
  }

  changeNotification (type, email = false, push = false) {
    const { notifications } = this.state
    const notification = notifications.filter(
      note => note.notificationType === NOTIFICATION_TYPES[type])[0]
    if (notification && notification.id) {
      const values = {
        newEmail: email,
        newPush: push,
        oldEmail: notification.email,
        oldPush: notification.push
      }
      const newNotifications = notifications.map(note => {
        if (note.notificationType === NOTIFICATION_TYPES[type]) {
          note.email = email
          note.push = push
        }
        return note
      })
      this.setState({ notifications: newNotifications }, () =>
        this.updateNotification(notification.id, values)
      )
    } else if ((email || push) && !notification) {
      const newNotification = {
        email,
        notificationType: NOTIFICATION_TYPES[type],
        push
      }
      this.setState({ notifications: [...notifications, newNotification] },
        () => this.createNotification(NOTIFICATION_TYPES[type], email, push)
      )
    }
  }

  createNotification (notificationType, email, push) {
    const { createNotificationFunction } = this.props
    const { notifications } = this.state
    createNotificationFunction({ email, notificationType, push })
      .then(resp => {
        const newNotifications = notifications.map(note => {
          if (resp.data && resp.data.id && !note.id &&
            note.notificationType === resp.data.notificationType) {
            return resp.data
          }
          return note
        })
        this.setState({ notifications: newNotifications })
      },
      fail => {
        const newNotifications = notifications.filter(note => !!(note.id))
        this.setState({ notifications: newNotifications })
      })
  }

  updateNotification (id, values) {
    const { updateNotificationFunction } = this.props
    const { notifications } = this.state
    updateNotificationFunction(id, { email: values.newEmail, push: values.newPush })
      .then(resp => {
      },
      fail => {
        const newNotifications = notifications.map(note => {
          if (note.id === id) {
            note.email = values.oldEmail
            note.push = values.oldPush
          }
          return note
        })
        this.setState({ notifications: newNotifications })
      })
  }

  componentDidMount () {
    const { getAllNotificationsFunction } = this.props
    getAllNotificationsFunction().then(notifications => {
      this.setState({ notifications: notifications.data })
    })
  }

  render () {
    const { classes, roleId } = this.props
    const { fullname, saved, saving, failedSave, notifications } = this.state
    const isUser = roleId === ROLES.USER
    const buttonClass = classes.saveButton
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <TextField
            className={classes.textField}
            value={fullname}
            name="fullname"
            label="Full Name"
            onChange={this.handleChange()}
          />
          <div className={classes.wrapperWrapper}>
            <div className={classes.buttonWrapper}>
              <Button
                className={buttonClass}
                disabled={saving}
                onClick={this.handleSave}>
                Save
              </Button>
              {saved && <UrlifePageInfo
                message='Settings saved.'
                open={true}
                onClose={this.closePageInfo('saved')} />}
              {failedSave && <UrlifePageInfo
                message='Failed to save settings.'
                open={true}
                onClose={this.closePageInfo('failedSave')} />}
            </div>
          </div>
          <ChangePassword />
          <table className={classes.notificationsTable}>
            <thead>
              <tr>
                <th className={classes.notificationsHeader}>
                  Notification Type
                </th>
                <th className={classes.notificationsHeader}>
                  Email
                </th>
                {isUser &&
                <th className={classes.notificationsHeader}>
                  Push
                </th>
                }
              </tr>
            </thead>
            <tbody>
              {Object.keys(NOTIFICATION_TYPES).map(type => {
                const notification = notifications.filter(
                  note => note.notificationType === NOTIFICATION_TYPES[type])[0]
                const notificationType = type === 'ALL' ? 'All Types' : ''
                const email = !!(notification && notification.email)
                const push = !!(notification && notification.push)
                return (<tr key={NOTIFICATION_TYPES[type]} className={classes.notificationRow}>
                  <td className={classes.notificationCell}>
                    {notificationType}
                  </td>
                  <td className={classes.notificationCell}>
                    <Checkbox
                      checked={email}
                      onChange={e => this.changeNotification(type, !email, push)}
                    />
                  </td>
                  {isUser &&
                  <td className={classes.notificationCell}>
                    <Checkbox
                      checked={push}
                      onChange={e => this.changeNotification(type, email, !push)}
                    />
                  </td>
                  }
                </tr>)
              })
              }
            </tbody>
          </table>

        </Paper>
        <div className={classes.buildNumber}>
          Build: {BUILD_ID}
        </div>
      </div>
    )
  }
}

UrlifeSettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  createNotificationFunction: PropTypes.func.isRequired,
  fullname: PropTypes.string,
  getAllNotificationsFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  setUserInfoFunction: PropTypes.func.isRequired,
  updateNotificationFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    createNotificationFunction: function (data) {
      return dispatch(createNotification(data))
    },
    getAllNotificationsFunction: function () {
      return dispatch(getAllNotifications())
    },
    setUserInfoFunction: function (info) {
      return dispatch(setUserInfo(info))
    },
    updateNotificationFunction: function (id, data) {
      return dispatch(updateNotification(id, data))
    }
  }
}

const mapStateToProps = state => {
  return {
    fullname: state.user.fullname,
    roleId: state.user.roleId
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeSettingsPage))
