import ApiService from '../services/ApiService'
import { api, FILE_STATUSES } from './../constants'
import { uploadFile } from './index'

export {
  createFinishedMovie,
  finishedFiles,
  getMovieFile,
  updateFinishedMovie
}

const timeOutUpdateIds = {}

function createFinishedMovie (fileInfo, file, projectId, projectSkuId, onComplete) {
  return dispatch => {
    return new ApiService(api.FINISHED_MOVIE_SKU, { projectId, projectSkuId }, {}, true, dispatch)
      .post(fileInfo)
      .then(
        data => {
          const updateMovieFileSuccess = (fileId, IdentityId) => {
            dispatch(updateFinishedMovie(
              projectId,
              projectSkuId,
              { id: fileId, status: FILE_STATUSES.CLIENT_UPLOAD_COMPLETE },
              IdentityId)
            ).then(
              data => {
                dispatch({
                  file: { ...data },
                  type: 'FINISHED_MOVIE_FILE_UPDATE'
                })
                if (typeof onComplete === 'function') {
                  onComplete()
                }
              }
            ).catch(e => {
              // try to update status every 5 sec
              timeOutUpdateIds[fileId] = setTimeout(
                () => {
                  updateMovieFileSuccess(fileId, IdentityId)
                },
                5000
              )
            })
          }
          const fileId = data.data[0].id
          dispatch({
            file: { ...data.data[0], status: FILE_STATUSES.CLIENT_UPLOADING },
            type: 'FINISHED_MOVIE_FILES_PREPEND'
          })
          return dispatch(uploadFile(
            null,
            file,
            fileId,
            data.data[0].fileExt,
            projectId,
            'finished',
            'movie/' + fileId + '.' + data.data[0].fileExt,
            (cognitoInfo) => updateMovieFileSuccess(fileId, cognitoInfo.IdentityId),
            () => {
              dispatch(updateFinishedMovie(
                projectId,
                projectSkuId,
                { status: FILE_STATUSES.UPLOAD_FAILED }
              ))
            }
          ))
        },
        fail => Promise.reject(fail)
      )
  }
}

function updateFinishedMovie (projectId, projectSkuId, file, identityId) {
  return dispatch => {
    return new ApiService(api.ONE_FINISHED_MOVIE_SKU, { fileId: file.id, projectId, projectSkuId },
      { identityId }, true, dispatch)
      .put(file)
  }
}

function finishedFiles (projectId, projectSkuId, params = {}) {
  return dispatch => {
    params.sort = params.sort || 'updated_at'
    params.method = params.method || 'DESC'
    if (projectSkuId) {
      return new ApiService(api.FINISHED_MOVIE_SKU, { projectId, projectSkuId }, params, true, dispatch)
        .get()
        .then(data => {
          dispatch({
            files: data.data,
            type: 'FINISHED_MOVIE_FILES'
          })
          return data
        })
    } else {
      return new ApiService(api.FINISHED_MOVIE, { projectId }, params, true, dispatch)
        .get()
        .then(data => {
          dispatch({
            files: data.data,
            type: 'FINISHED_MOVIE_FILES'
          })
          return data
        })
    }
  }
}

function getMovieFile (projectId, projectSkuId, fileId) {
  return dispatch => {
    return new ApiService(api.ONE_FINISHED_MOVIE_SKU,
      { fileId, projectId, projectSkuId }, {}, true, dispatch)
      .get()
      .then(data => {
        dispatch({
          file: data,
          type: 'FINISHED_MOVIE_FILE_UPDATE'
        })
        return data
      })
  }
}
