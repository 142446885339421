import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TravelinkLogo (props) {
  return (
    <SvgIcon {...props} viewBox={props.hideAmEx ? '0 0 644.5 95.4' : '0 0 835.5 95.4'}>
      {!props.hideAmEx && <line fill="none" stroke={props.color || '#000000'} x1="692.8" y1="0" x2="692.8" y2="95.4" />}
      <g>
        <path fill={props.color || '#000000'} d="M30.9,9.9h-30V1.6h68.9v8.3h-30v84.6h-8.9V9.9z" />
        <path fill={props.color || '#000000'} d="M82.6,1.6h31.1c20.7,0,32.3,8.8,32.3,25.1c0,15.1-8.8,22.6-22.2,25.1l23.5,42.7h-10l-22.7-41.7H91.4v41.7h-8.8V1.6z M113.1,44.9c15.3,0,24-4.4,24-17.8c0-12.8-8.5-17.3-24.1-17.3H91.4v35.1H113.1z"/>
        <path fill={props.color || '#000000'} d="M191,1.2h9.7l32.9,93.3h-9.3l-9.9-28.6h-37.7l-9.9,28.6h-8.7L191,1.2z M211.6,58l-15.9-46.2h-0.3L179.5,58H211.6z" />
        <path fill={props.color || '#000000'} d="M230.2,1.6h9.2l27.7,81.1h0.3l27.9-81.1h8.7l-32.6,93.3h-8.4L230.2,1.6z" />
        <path fill={props.color || '#000000'} d="M316.7,1.6h55.5v8.3h-46.6v31.5h34.8v8h-34.8v36.9h49.2v8.2h-58.1V1.6z" />
        <path fill={props.color || '#000000'} d="M388.1,1.6h8.9v84.7h45.4v8.2h-54.4V1.6z" />
        <path fill={props.color || '#000000'} d="M455.9,1.6h8.9v92.9h-8.9V1.6z" />
        <path fill={props.color || '#000000'} d="M485.6,1.6h9.5l51.2,76.6h0.3V1.6h8.4v92.9h-7.9l-52.8-79h-0.3v79h-8.4V1.6z" />
        <path fill={props.color || '#000000'} d="M575.6,1.6h8.9v50.1l44.4-50.1h10.9l-31.9,35.1l36.5,57.7h-10.3l-32.3-50.8l-17.4,19.1v31.8h-8.9V1.6z" />
      </g>
      {!props.hideAmEx && <g>
        <polygon fill="#ffffff" points="835.4,95.1 788.8,95.1 740.7,95.1 740.7,47.2 740.7,0.4 787.9,0.4 835.4,0.4 835.4,47.2 " />
        <rect x="740.7" y="0.4" fill="#ffffff" width="94.7" height="94.7" />
        <path fill="#0070CF" d="M832.1,67.2h-31.2l-2-2.6v2.6h-6.8v-4.1c-0.9,0.3-1.9,0.5-2.9,0.5h-1.1v3.6h-10l-1.5-2.1l-1.5,2.1h-16.3V51.4 h16.4l1.5,2.1l1.5-2.1h57.5V0h-95v38.6L744,31h6.6l1.7,3.9V31h8l1.1,3.1l1.1-3.1h34.9v1.5c1.3-1,3-1.5,4.9-1.5h10.8l1.7,3.9V31h6.5 l1.9,2.9V31h6.9v15.8H823l-1.8-2.8v2.8h-9.7l-0.9-2.4h-2l-0.9,2.4h-5.8c-1.5,0-3.3-0.3-4.6-1.4v1.4h-9.8l-2-2.6v2.6h-36.2l-0.9-2.4 h-2.1l-0.9,2.4h-4.7v48.4h95v-29C834.6,66.9,833.4,67.2,832.1,67.2z"/>
        <path fill="#0070CF" d="M831.9,62.5h-5.8v2.5h5.8c2.4,0,3.9-1.5,3.9-3.6c0-2-1.3-3.2-3.5-3.2h-2.6c-0.5,0-1-0.3-1-0.9c0,0,0-0.1,0-0.1 c0-0.5,0.4-0.9,0.9-0.9c0,0,0.1,0,0.1,0h4.8l1.1-2.5h-5.9c-2.4,0-3.9,1.5-3.9,3.5c0,2.1,1.4,3.3,3.5,3.3h2.6c0.5-0.1,1,0.3,1,0.8 c0,0,0,0.1,0,0.1C832.9,62.2,832.4,62.5,831.9,62.5 M821.6,62.5h-5.8v2.5h5.8c2.4,0,3.9-1.5,3.9-3.6c0-2-1.3-3.2-3.5-3.2h-2.6 c-0.5,0-1-0.3-1-0.9c0,0,0-0.1,0-0.1c0-0.5,0.4-0.9,0.9-0.9c0,0,0.1,0,0.1,0h4.8l1.1-2.5h-5.9c-2.4,0-3.9,1.5-3.9,3.5 c0,2.1,1.4,3.3,3.5,3.3h2.6c0.5-0.1,1,0.3,1,0.8c0,0,0,0.1,0,0.1C822.6,62.2,822.2,62.5,821.6,62.5 M814.6,56.4v-2.5h-9.1v11.2h9.1 v-2.5h-6.3v-1.9h6.1v-2.4h-6.1v-1.8L814.6,56.4z M800.2,56.4c0.9,0,1.3,0.5,1.3,1.1s-0.4,1.1-1.3,1.1h-3.1v-2.3H800.2z M797.1,61.1 h1.1l3.3,4h3.5l-3.6-4.1c1.8-0.4,2.9-1.8,2.9-3.5c0-2.1-1.5-3.6-3.9-3.6h-6.2v11.2h2.8L797.1,61.1L797.1,61.1z M790.2,57.7 c0,0.7-0.5,1.2-1.1,1.2c-0.1,0-0.1,0-0.2,0h-3.2v-2.5h3.2c0.7-0.1,1.2,0.4,1.3,1.1C790.2,57.6,790.2,57.6,790.2,57.7 M782.9,53.9 v11.1h2.8v-3.6h3.3c2.4,0,4-1.6,4-3.8c0-2.2-1.5-3.8-3.9-3.8L782.9,53.9z M779.1,65.1h3.6l-4.5-5.6l4.5-5.5h-3.5l-2.6,3.4l-2.6-3.4 h-3.5l4.5,5.6l-4.5,5.6h3.5l2.6-3.5L779.1,65.1L779.1,65.1z M770.2,56.4v-2.5h-9.1v11.2h9.1v-2.5h-6.3v-1.9h6.1v-2.4h-6.1v-1.8 L770.2,56.4z"/>
        <path fill="#0070CF" d="M823.8,44.7h3.4V33.5h-2.8v6.9l-4.5-6.9h-3.6v11.2h2.8v-7.2L823.8,44.7L823.8,44.7z M807.8,39.9l1.5-3.8 l1.6,3.8H807.8z M812.9,44.7h3.2l-4.8-11.2h-3.7l-4.8,11.2h3.1l0.9-2.3h5.1L812.9,44.7L812.9,44.7z M801.4,44.7h0.2l1.2-2.5h-0.9 c-1.8,0-2.8-1.1-2.8-3V39c0-1.7,0.9-2.9,2.8-2.9h3v-2.6h-3.2c-3.5,0-5.4,2.3-5.4,5.5v0.2C796.2,42.6,798.2,44.7,801.4,44.7 M792,44.7h2.8V33.5H792V44.7L792,44.7z M786.7,36c0.9,0,1.3,0.5,1.3,1.1s-0.4,1.1-1.3,1.1h-3.1V36L786.7,36z M783.6,40.7h1.1 l3.3,4h3.5l-3.6-4.1c1.8-0.4,2.9-1.8,2.9-3.5c0-2.1-1.5-3.6-3.9-3.6h-6.2v11.2h2.8L783.6,40.7L783.6,40.7z M779.2,36v-2.5h-9.1 v11.2h9.1v-2.5h-6.3v-1.9h6.1v-2.4h-6.1V36H779.2z M760,44.7h2.5l2.9-8.3v8.3h2.8V33.5h-4.5l-2.5,7.1l-2.5-7.1h-4.5v11.2h2.8v-8.3 L760,44.7L760,44.7z M745.7,40l1.5-3.8l1.5,3.8H745.7z M750.7,44.7h3.2l-4.8-11.2h-3.7l-4.8,11.1h3.1l0.9-2.3h5.1L750.7,44.7 L750.7,44.7z"/>
      </g>}
    </SvgIcon>
  )
}

TravelinkLogo.propTypes = {
  color: PropTypes.string,
  hideAmEx: PropTypes.bool
}
