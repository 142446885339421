const packages = (state = {}, action) => {
  switch (action.type) {
    case 'GETTING_PACKAGES':
      return {
        ...state,
        getPackagesFailed: false,
        gettingPackages: true,
        gotPackages: false,
        packages: []
      }
    case 'GOT_PACKAGES':
      return {
        ...state,
        gettingPackages: false,
        gotPackages: true,
        packages: action.packages
      }
    case 'GET_PACKAGES_FAILED':
      return {
        ...state,
        getPackagesFailed: true,
        gettingPackages: false
      }
    case 'GOT_SKUS':
      return {
        ...state,
        packages: state.packages.map(pack => {
          if (pack.id !== action.packageId) return pack
          return {
            ...pack,
            skus: action.skus
          }
        })
      }
    default:
      return state
  }
}

export default packages
