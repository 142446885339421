import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import MarketingPageCTA from '../../marketing/MarketingPageCTA'
import { AppStoreBadge, ScrollDown } from '../../common/SVG'
import { toggleModal } from '../../../actions'
import UrlifeButton from '../../common/UrlifeButton'
// import Slider from 'react-slick'
import classNames from 'classnames'
import coverThumb from '../../../assets/img/marketing/cover/thumb.jpg'
// import cover2 from '../../../assets/img/marketing/cover/2.jpg'
// import cover3 from '../../../assets/img/marketing/cover/3.jpg'
// import cover4 from '../../../assets/img/marketing/cover/4.jpg'

const words = [
  {
    name: 'ADVENTURE',
    time: 2200
  },
  {
    name: 'EXPEDITION',
    time: 2150
  },
  {
    name: 'TRAVEL',
    time: 2780
  },
  {
    name: 'FAMILY',
    time: 2300
  },
  {
    name: 'VACATIONS',
    time: 2800
  },
  {
    name: 'CELEBRATIONS',
    time: 3000
  },
  {
    name: 'HOLIDAYS',
    time: 2470
  }
]

/* eslint-disable sort-keys */
const styles = {
  root: {
    width: '100%',
    height: '100vh'
  },
  background: {
    height: 'calc(100vh - 90px)',
    position: 'relative',
    zIndex: -2,
    overflow: 'hidden',
    marginTop: 90,
    ...commonStyles.media(575, {
      height: 'calc(100vh - 70px)',
      marginTop: 70
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 'calc(100vh - 70px)',
      marginTop: 70
    })
  },
  slider: {
    position: 'relative',
    width: '100%',
    border: 'none',
    '&::after': {
      content: '\'\'',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundImage: 'linear-gradient( 135deg, #7c62e4 20%, #b77be4 80% )',
      opacity: '0.3'
    }
  },
  coverImage: {
    display: 'block',
    width: '100%'
  },
  backgroundVideo: {
    left: '50%',
    top: '25%',
    transform: 'translateX(-50%) translateY(-25%)',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    zIndex: -5
  },
  container: {
    ...commonStyles.container(),
    marginTop: 134,
    ...commonStyles.media(1510, {
      ...commonStyles.container(),
      fontSize: 50
    }),
    ...commonStyles.media(1279, {
      ...commonStyles.container(),
      margin: '70px auto auto auto'
    }),
    ...commonStyles.media(575, {
      ...commonStyles.container(),
      margin: '120px auto auto auto'
    }),
    ...commonStyles.media(480, {
      ...commonStyles.container(),
      margin: '10px auto auto auto'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      ...commonStyles.container(),
      margin: '10px auto auto auto'
    })
  },
  title: {
    // maxWidth: 883,
    color: '#ffffff',
    display: 'flex',
    fontSize: 58,
    fontWeight: 400,
    justifyContent: 'flex-start',
    margin: 0,
    paddingTop: '20vh',
    '& div': {
      marginRight: 16,
      display: 'inline-block'
    },
    '& div:nth-child(2)': {
      fontWeight: 600
    },
    ...commonStyles.media(1510, {
      paddingTop: '25vh',
      fontSize: 50
    }),
    ...commonStyles.media(1279, {
      fontSize: 34,
      // maxWidth: 450,
      paddingTop: '30vh',
      '& div': {
        marginRight: 11
      }
    }),
    ...commonStyles.media(767, {
      fontSize: 24
    }),
    ...commonStyles.media(575, {
      fontSize: 20,
      // maxWidth: 380,
      paddingTop: '33vw',
      textShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      '& div': {
        marginRight: 5
      }
    }),
    ...commonStyles.media(480, {
      flexDirection: 'column',
      // maxWidth: 330,
      paddingTop: '33vw',
      '& div': {
        marginRight: 5
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      // maxWidth: 330,
      fontSize: 20,
      paddingTop: '30vh',
      '& div': {
        marginRight: 5
      }
    })
  },
  '@keyframes inToScreen': {
    from: { top: '-100%' },
    to: { top: '0%' }
  },
  '@keyframes outOfScreen': {
    from: { top: '0%' },
    to: { top: '100%' }
  },
  wordContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: 71,
    width: 480,
    ...commonStyles.media(1510, {
      height: 61,
      width: 405
    }),
    ...commonStyles.media(1279, {
      height: 37,
      width: 285
    }),
    ...commonStyles.media(767, {
      height: 30,
      width: 200
    }),
    ...commonStyles.media(575, {
      width: 162,
      height: 25
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 162,
      height: 25
    })
  },
  word: {
    '&._before': {
      position: 'absolute',
      top: '-100%'
    },
    position: 'relative',
    top: '0%',
    textShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
    '&._loaded': {
      '&._before': {
        animation: '$inToScreen 0.5s ease-in'
      },
      animation: '$outOfScreen 0.5s ease-in'
    }

  },
  subtitle: {
    color: '#ffffff',
    fontSize: 38,
    fontWeight: 400,
    opacity: 0,
    transform: 'translate3d(0px,20px,0px)',
    transition: 'opacity 0.8s ease, transform 0.8s linear',
    transitionDelay: '1.8s',
    marginTop: 14,
    marginBottom: 8,
    '&._loaded': {
      opacity: 1,
      transform: 'translate3d(0px,0px,0px)'
    },
    '&._second': {
      marginTop: 0
    },
    ...commonStyles.media(1510, {
      fontSize: 33,
      fontWeight: 400
    }),
    ...commonStyles.media(1279, {
      fontSize: 23,
      marginTop: 10,
      marginBottom: 6
    }),
    ...commonStyles.media(767, {
      fontSize: 16,
      marginBottom: 6
    }),
    ...commonStyles.media(575, {
      fontSize: 13,
      maxWidth: 380,
      marginTop: 8,
      marginBottom: 4,
      textShadow: '0 0 8px rgba(0, 0, 0, 0.5)'
    }),
    ...commonStyles.media(480, {
      fontSize: 12,
      maxWidth: 310
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      marginTop: 8,
      marginBottom: 4,
      maxWidth: 310
    })
  },
  scrollDown: {
    position: 'absolute',
    bottom: 10,
    left: 'calc(50% - 12px)',
    ...commonStyles.media(575, {
      display: 'none'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'none'
    })
  },
  buttons: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  button: {
    width: 210,
    height: 46,
    display: 'inline-block',
    '&:hover': {
      color: '#01b7d7',
      background: '#ffffff',
      border: 'solid 1px #01b7d7'
    },
    ...commonStyles.media(575, {
      width: 181,
      height: 40,
      fontSize: 12
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 181,
      height: 40,
      fontSize: 12
    })
  },
  appStoreBadgeLink: {
    display: 'inline-block',
    height: 46,
    '&._margin': {
      marginLeft: 15
    },
    ...commonStyles.media(1510, {
      height: 49
    }),
    ...commonStyles.media(575, {
      height: 40
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 40
    })
  },
  appStoreBadge: {
    height: 46,
    width: 'auto',
    display: 'inline-block',
    ...commonStyles.media(575, {
      height: 40
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 40
    })
  }
}
/* eslint-enable sort-keys */

class Cover extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      animating: false,
      currWordIdx: 0,
      mobile: null,
      mounted: false
    }
    this.videoSrc = 'https://static.urlifemedia.com/farsta_homepage/hero-20220829-1919.mp4'
    this.videoRatio = 1
    this.video = React.createRef()
  }

  openGetStartedModal () {
    this.props.toggleModal(true, 'getStarted')
  }

  handleResize () {
    const { mobile } = this.state
    if (window.innerWidth <= 575) {
      if (!mobile) {
        this.setState({ mobile: true })
      }
    } else {
      if (mobile || mobile === null) {
        this.setState({ mobile: false })
      }
    }
  }

  handleAnimation (e) {
    const { currWordIdx } = this.state
    this.setState({
      ...this.state,
      animating: false,
      currWordIdx: currWordIdx + 1 === words.length ? 0 : currWordIdx + 1
    })
  }

  loop () {
    const { currWordIdx } = this.state
    setTimeout(() => {
      this.setState({ animating: true })
      this.loop()
    }, words[currWordIdx].time)
  }

  onPlay () {
    this.loop()
  }

  componentDidMount () {
    this.setState({
      ...this.state,
      mounted: true
    })
    this.handleResize()
    window.addEventListener('resize', this.handleResize.bind(this), true)
    this.video.current.addEventListener('play', this.onPlay.bind(this), true)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize.bind(this), true)
    if (this.video && this.video.current) {
      this.video.current.removeEventListener('play', this.onPlay.bind(this), true)
    }
  }

  render () {
    const { classes, loggedIn } = this.props
    const { animating, currWordIdx, mobile } = this.state
    // const sliderSettings = {
    //   className: classes.slider,
    //   infinite: false,
    //   arrows: false,
    //   swipeToSlide: false,
    //   dots: false,
    //   slidesToShow: 1,
    //   speed: 1250,
    //   focusOnSelect: false,
    //   pauseOnHover: false,
    //   autoplay: false,
    //   autoplaySpeed: 3000
    // }
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('Cover')} index={20}>
        <ParallaxLayer layer='layer5'>
          <div className={`${classes.root} ${classes.background}`}>
            {mobile && <div>
              <div className={classes.slider}>
                <img
                  src={coverThumb}
                  alt="cover1"
                  className={classes.coverImage}
                />
              </div>
              {/*
              <Slider {...sliderSettings}>
                <img
                  src={cover2}
                  alt="cover2"
                  className={classes.coverImage}
                />
                <img
                  src={cover3}
                  alt="cover3"
                  className={classes.coverImage}
                />
                <img
                  src={cover4}
                  alt="cover4"
                  className={classes.coverImage}
                />
              </Slider>
              */}
            </div>
            }
            {!mobile &&
            <video
              autoPlay={true}
              loop
              muted
              playsInline="playsinline"
              preload='metadata'
              // style={{ width: videoWidth, height: videoHeight }}
              ref={this.video}
              className={classes.backgroundVideo}
              src={this.videoSrc}>
              <source src={this.videoSrc} type="video/mp4"/>
            </video>
            }
            <div className={classes.scrollDown}>
              <ScrollDown />
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer layer='layer5'>
          <div className={classes.root}>
            <div className={classes.container}>
              <div className={classNames(classes.title, '_loaded')}>
                <div>Make a movie of your </div>
                <div className={classes.wordContainer}>
                  <span
                    className={classNames(classes.word, '_before', { _loaded: animating })}
                  >
                    {words[currWordIdx + 1 === words.length ? 0 : currWordIdx + 1].name}
                  </span>
                  <span
                    className={classNames(classes.word, { _loaded: animating })}
                    onAnimationEnd={this.handleAnimation.bind(this)}
                  >
                    {words[currWordIdx].name}
                  </span>
                </div>
              </div>
              <p
                className={classNames(classes.subtitle, '_loaded')}
              >
                Your photos and videos. Your experience.
              </p>
              <p
                className={classNames(classes.subtitle, '_second', '_loaded')}
              >
                Our creative team of storytellers do all the work.
              </p>
              <div className={classes.buttons}>
                <MarketingPageCTA className={classes.button}>
                  {!loggedIn && <UrlifeButton
                    className={classes.button}
                    onClick={this.openGetStartedModal.bind(this)}
                  >
                    Get Started
                  </UrlifeButton>}
                </MarketingPageCTA>
                <AppStoreBadge
                  className={classes.appStoreBadge}
                  linkClass={classNames(classes.appStoreBadgeLink, { _margin: !loggedIn })}
                />
              </div>
            </div>
          </div>
        </ParallaxLayer>
      </ParallaxGroup>
    )
  }
}

Cover.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  setSectionRef: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Cover))
