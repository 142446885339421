import React from 'react'
import PropTypes from 'prop-types'
import {
  commonStyles,
  getNoteStepsArray
} from '../../../../helpers'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  createPromptAnswer,
  deletePromptAnswer,
  getProjectAnswers,
  setTopBarHeaders,
  updatePromptAnswer
} from '../../../../actions'
import UrlifeTextField from '../../../common/UrlifeTextField'
import UrlifeButton from '../../../common/UrlifeButton'
import { PROJECT_DATA } from '../../../../constants'

/* eslint-disable sort-keys */
const styles = {
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  inputBlock: {
    width: '100%',
    display: 'block',
    paddingBottom: 40
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  button: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

class DetailsMovie extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      answers: {},
      answersFetched: false
    }
    // protect from updating unmounted component
    this.mounted = false
  }

  componentDidMount () {
    const { setTopBarHeadersFunction, categories, categoryId, projectCategory } = this.props
    this.fetchAnswers()
    let cat = categories.find(e => e.id === categoryId)
    if (!cat && projectCategory && projectCategory.id === categoryId) {
      cat = projectCategory
    }
    if (cat && cat.promptsTitle) {
      setTopBarHeadersFunction(cat.promptsTitle)
    }
    this.mounted = true
  }

  componentDidUpdate (prevProps) {
    if (this.props.projectCategory && !prevProps.projectCategory) {
      this.props.setTopBarHeadersFunction(this.props.projectCategory.promptsTitle)
    }
  }

  componentWillUnmount () {
    this.mounted = false
  }

  async fetchAnswers () {
    const {
      projectId,
      getProjectAnswersFunction
    } = this.props
    const data = await getProjectAnswersFunction(projectId)
    if (data) {
      const answers = {}
      data.forEach(answer => {
        answers[answer.prompt_id] = {
          id: answer.id,
          value: answer.answer
        }
      })
      this.setState({ answers: answers, answersFetched: true })
    }
  }

  handleChangeInput (promptId, e) {
    const { answers } = this.state
    this.setState({
      answers: {
        ...answers,
        [promptId]: {
          ...answers[promptId],
          value: e.target.value
        }
      }
    })
  }

  async handleBlurInput (promptId, e) {
    const {
      projectId,
      updatePromptAnswerFunction,
      createPromptAnswerFunction,
      deletePromptAnswerFunction
    } = this.props
    const { answers } = this.state
    const answer = answers[promptId] || { value: '' }
    if (answer.id) {
      if (answer.value.trim() === '') {
        // delete answer on clear
        await deletePromptAnswerFunction(projectId, answer.id)
        if (this.mounted) {
          const newAnswer = this.state.answers[promptId]
          delete newAnswer.id
          this.setState({
            answers: {
              ...this.state.answers,
              [promptId]: newAnswer
            }
          })
        }
      } else {
        // update if it was created
        await updatePromptAnswerFunction(projectId, answer.id, answer.value)
      }
    } else if (answer.value.trim() !== '') {
      // create answer
      const response = await createPromptAnswerFunction(projectId, {
        answer: answer.value.trim(),
        prompt_id: promptId
      })
      if (this.mounted) {
        this.setState({
          // add id to state answer
          answers: {
            ...this.state.answers,
            [promptId]: {
              ...this.state.answers[promptId],
              id: response.id
            }
          }
        })
      }
    }
  }

  onSkip () {
    this.onNext(true)
  }

  async onNext (skip = false) {
    const {
      updateProject,
      totalNumberOfPrompts,
      step,
      nextStep
    } = this.props
    const stepArr = getNoteStepsArray(totalNumberOfPrompts)
    const promptsArrIdx = step - 1
    let promptsAnsCount = 0
    stepArr.forEach((item, idx) => {
      if (idx <= promptsArrIdx) {
        promptsAnsCount += item
      }
    })
    const promptsAnswered = skip
      ? totalNumberOfPrompts
      : promptsAnsCount
    await updateProject({}, { promptsAnswered })
    nextStep(skip)
  }

  onSubmit (e) {
    e.preventDefault()
    this.onNext()
  }

  render () {
    const { classes, prompts, step } = this.props
    const { answers, answersFetched } = this.state
    const firstPrompt = (step - 1) * PROJECT_DATA.MAX_PROMPTS_PER_PAGE
    const lastPrompt = firstPrompt + PROJECT_DATA.MAX_PROMPTS_PER_PAGE - 1
    return (
      <div>
        {answersFetched &&
          <div className={classes.formWrapper}>
            <form onSubmit={this.onSubmit.bind(this)}>
              {prompts.map((e, i) => {
                if (i < firstPrompt || i > lastPrompt) {
                  return ''
                }
                return (
                  <div
                    key={i}
                    className={classes.inputBlock}>
                    <UrlifeTextField
                      id={'prompt-' + i + '-input'}
                      autoFocus={i === firstPrompt}
                      label={e.name}
                      multiline
                      rowsMax={5}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handleChangeInput.bind(this, e.id)}
                      onBlur={this.handleBlurInput.bind(this, e.id)}
                      defaultValue={answers[e.id] ? answers[e.id].value : ''}
                      placeholder={e.prompt}
                    />
                  </div>
                )
              })}
            </form>
            <div className={classes.buttonBlock}>
              <UrlifeButton
                inverted
                className={classes.button}
                onClick={() => this.onSkip()}>
                Skip
              </UrlifeButton>
              <UrlifeButton
                className={classes.button}
                onClick={() => this.onNext()}>
                Next
              </UrlifeButton>
            </div>
          </div>
        }
      </div>
    )
  }
}

DetailsMovie.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  createPromptAnswerFunction: PropTypes.func.isRequired,
  deletePromptAnswerFunction: PropTypes.func.isRequired,
  getProjectAnswersFunction: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  projectCategory: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  prompts: PropTypes.array,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  totalNumberOfPrompts: PropTypes.number.isRequired,
  updateProject: PropTypes.func.isRequired,
  updatePromptAnswerFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createPromptAnswerFunction: function (projectId, prompt) {
      return dispatch(createPromptAnswer(projectId, prompt))
    },
    deletePromptAnswerFunction: function (projectId, id) {
      dispatch(deletePromptAnswer(projectId, id))
    },
    getProjectAnswersFunction: function (projectId) {
      return dispatch(getProjectAnswers(projectId))
    },
    setTopBarHeadersFunction: function (title) {
      dispatch(setTopBarHeaders('Notes', title))
    },
    updatePromptAnswerFunction: function (projectId, id, answer) {
      dispatch(updatePromptAnswer(projectId, id, answer))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DetailsMovie))
