import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MoreMenu from '../../common/MoreMenu/MoreMenu'
import { connect } from 'react-redux'
import { commonStyles } from '../../../helpers/index'
import { toggleModal } from '../../../actions'
import MarketingPageCTA from '../../marketing/MarketingPageCTA'
import UrlifeButton from '../../common/UrlifeButton'
import TopBarLogo from './TopBarLogo'

/* eslint-disable sort-keys */
const styles = {
  container: {
    ...commonStyles.container(),
    minHeight: 0
  },
  topBarRow: {
    marginTop: -20,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  topBarLeft: {
    ...commonStyles.flex(),
    justifyContent: 'flex-start',
    flex: 1
  },
  topBarMiddle: {
    ...commonStyles.flex()
  },
  topBarRight: {
    ...commonStyles.flex(),
    justifyContent: 'flex-end',
    flex: 1
  },
  topBarButton: {
    marginRight: 39,
    marginLeft: 39,
    width: 170,
    height: 40,
    fontSize: 12,
    border: '1px solid #ffffff',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#9455c8'
    },
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  inviteButton: {
    width: 170,
    height: 40,
    marginRight: 39,
    marginLeft: 39,
    lineHeight: '3.3',
    fontSize: 12,
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  login: {
    fontWeight: 700,
    fontSize: 12,
    cursor: 'pointer',
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  menuIcon: {
    cursor: 'pointer',
    marginTop: 4
  }
}
/* eslint-enable sort-keys */

class ToolbarLoggedOut extends React.Component {
  openLoginModal () {
    this.props.toggleModal(true, 'login')
  }

  openGetStartedModal () {
    this.props.toggleModal(true, 'getStarted')
  }

  render () {
    const { classes, hideButtons } = this.props
    return (
      <Toolbar className={classes.container}>
        <div className={classes.topBarRow}>
          {/* left col */}
          <div className={classes.topBarLeft}>
            <TopBarLogo />
          </div>
          {/* middle col */}
          <div className={classes.topBarMiddle}>
          </div>
          {/* right col */}
          {!hideButtons && <div className={classes.topBarRight}>
            <MarketingPageCTA className={classes.inviteButton}>
              <React.Fragment>
                <div className={classes.login} onClick={this.openLoginModal.bind(this)}>Log in</div>
                <UrlifeButton
                  className={classes.topBarButton}
                  inverted={true}
                  onClick={this.openGetStartedModal.bind(this)}
                >
                  Get Started
                </UrlifeButton>
              </React.Fragment>
            </MarketingPageCTA>
            <MoreMenu moreIconClassName={classes.menuIcon}/>
          </div>}
        </div>
      </Toolbar>
    )
  }
}

ToolbarLoggedOut.propTypes = {
  classes: PropTypes.object.isRequired,
  hideButtons: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(ToolbarLoggedOut))
)
