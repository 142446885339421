
export const fileProgressStatus = {
  complete: 5,
  failed: 4,
  inProgress: 2,
  inQueue: 3,
  toProgress: 1
}

export const fileProgressType = {
  download: 'download',
  generateZip: 'generateZip',
  upload: 'upload'
}

const fileProgress = (state = {}, action) => {
  switch (action.type) {
    case 'FILE_PROGRESS_ADD': {
      if (!action.fileId) return state
      return {
        ...state,
        [action.fileId]: {
          id: action.fileId,
          latest: Date.now(),
          loaded: 0,
          name: action.name,
          progressType: action.progressType,
          projectId: action.projectId || '',
          retryCount: 0,
          role: action.role,
          status: fileProgressStatus.toProgress,
          total: action.total,
          url: action.url || ''
        }
      }
    }
    case 'FILE_PROGRESS_START':
      if (!state[action.fileId]) return state
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          latest: Date.now(),
          status: fileProgressStatus.inProgress
        }
      }
    case 'FILE_PROGRESS_RETRY':
      if (!state[action.fileId]) return state
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          latest: Date.now(),
          retryCount: state[action.fileId].retryCount + 1
        }
      }
    case 'FILE_PROGRESS_QUEUED':
      if (!state[action.fileId]) return state
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          loaded: 0,
          retryCount: 0,
          status: fileProgressStatus.inQueue
        }
      }
    case 'FILE_PROGRESS_CONTINUE':
      if (!state[action.fileId]) return state
      const toReturn = {}
      for (const file in state) {
        if (file === action.fileId) {
          toReturn[file] = {
            id: state[action.fileId].id,
            latest: Date.now(),
            loaded: action.loaded,
            name: state[action.fileId].name,
            progressType: state[action.fileId].progressType,
            projectId: state[action.fileId].projectId,
            retryCount: state[action.fileId].retryCount,
            role: state[action.fileId].role,
            status: fileProgressStatus.inProgress,
            total: action.total || state[action.fileId].total,
            url: state[action.fileId].url
          }
          continue
        }
        toReturn[file] = state[file]
      }
      return toReturn
    case 'FILE_PROGRESS_COMPLETE':
      if (!state[action.fileId]) return state
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          retryCount: 0,
          status: fileProgressStatus.complete
        }
      }
    case 'FILE_PROGRESS_FAILED':
      if (!state[action.fileId]) return state
      return {
        ...state,
        [action.fileId]: {
          ...state[action.fileId],
          status: fileProgressStatus.failed
        }
      }
    case 'FILE_PROGRESS_REMOVE':
      if (!state[action.fileId]) return state
      delete state[action.fileId]
      return {
        ...state
      }
    case 'FILE_PROGRESS_CLEAR':
      return {}
    default:
      return state
  }
}

export default fileProgress
