import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ViewCarousel (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 24 22'>
      <defs><clipPath id="4psra"><path fill="#fff" d="M3 2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/></clipPath></defs><g><g><g><path fill="#333" d="M0 0h1.5v22H0z"/></g><g><path fill="none" stroke="#333" strokeMiterlimit="20" strokeWidth="3" d="M3 2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" clipPath="url(&quot;#4psra&quot;)"/></g><g><path fill="#333" d="M22.5 0H24v22h-1.5z"/></g></g></g>
    </SvgIcon>
  )
}

ViewCarousel.propTypes = {
  fill: PropTypes.string
}
