import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import {
  CloseIcon
} from './SVG/index'

/* eslint-disable sort-keys */
const styles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  },
  closeIcon: {
    width: 14,
    height: 14,
    position: 'relative',
    top: -2
  }
})
/* eslint-enable sort-keys */

/*
 * This class shows a "snackbar" at the bottom center of
 * the screen. Displaying some sort of information to the user.
 * This information is usually received from the server in some
 * kind of way.
 */

class UrlifePageInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: props.open
    }
    this.handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      if (this.props.onClose && typeof this.props.onClose === 'function') {
        this.props.onClose()
      }
      this.setState({ open: false })
    }
  }

  render () {
    const { classes, message } = this.props
    return (
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ]}
      />
    )
  }
}

UrlifePageInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
}

export default withStyles(styles)(UrlifePageInfo)
