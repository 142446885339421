import ApiService from '../services/ApiService'
import ReactGA from 'react-ga4'
import { api } from './../constants'
import { codeIsOffer } from '../helpers'

export {
  verifyProjectCode,
  useCode
}

function verifyProjectCode (body) {
  return dispatch => {
    return new ApiService(api.VERIFY_PROJECT_CODE, {}, {}, false, dispatch)
      .post(body)
  }
}

function useCode (code) {
  return async dispatch => {
    const codeResult = await new ApiService(api.USE_CODE, {}, {}, true, dispatch)
      .post({ code })
    if (codeIsOffer(code)) {
      ReactGA.event({
        action: 'redeemCode',
        category: 'conversionRate',
        label: code
      })
    }
    return codeResult
  }
}
