import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ScrollDown (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 38">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Hero" transform="translate(-711.000000, -905.000000)" fill="#FFFFFF">
          <g id="Group-5" transform="translate(711.000000, 905.000000)">
            <path d="M10,2.5672 C5.858,2.5672 2.5,5.925 2.5,10.0672 L2.5,20.0672 C2.5,24.2092 5.8578,27.5672 10,27.5672 C14.1422,27.5672 17.5,24.2094 17.5,20.0672 L17.5,10.0672 C17.5,5.9252 14.1422,2.5672 10,2.5672 Z M10,0.0672 C15.5236,0.0672 20,4.5436 20,10.0672 L20,20.0672 C20,25.5892 15.5236,30.0672 10,30.0672 C4.4764,30.0672 0,25.5892 0,20.0672 L0,10.0672 C0,4.5436 4.4764,0.0672 10,0.0672 Z" id="Fill-1"></path>
            <path d="M8.75,7.5672 C8.75,6.87656 9.30936,6.3172 10,6.3172 C10.69064,6.3172 11.25,6.87656 11.25,7.5672 L11.25,14.2328 C11.25,14.92344 10.69064,15.4828 10,15.4828 C9.30936,15.4828 8.75,14.92344 8.75,14.2328 L8.75,7.5672 Z" id="Fill-2"></path>
            <path d="M10.6204,36.9859984 C10.0204,37.3281864 9.25792,37.1203744 8.91416,36.5203584 C8.571972,35.9203584 8.779784,35.1578784 9.3798,34.8141184 L15.2126,31.48132 C15.8126,31.139132 16.57668,31.346944 16.91884,31.94696 C17.261028,32.5454 17.053216,33.30944 16.4532,33.65164 L10.6204,36.9859984 Z" id="Fill-3"></path>
            <path d="M10.6204,34.8140016 C11.2204,35.1577536 11.4282,35.9202416 11.08604,36.5202416 C10.742288,37.1202416 9.9798,37.3280416 9.3798,36.9858816 L3.547,33.65152 C2.947,33.309332 2.7392,32.54528 3.08136,31.94684 C3.423548,31.34684 4.1876,31.13904 4.7876,31.4812 L10.6204,34.8140016 Z" id="Fill-4"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
