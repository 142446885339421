import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'

const styles = {
  error: {
    color: '#f13737',
    fontSize: '0.63rem',
    textAlign: 'center'
  }
}

function UrlifeDialogError (props) {
  return (
    <DialogContentText className={props.classes.error}>
      {props.message}
    </DialogContentText>
  )
}

UrlifeDialogError.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

export default withStyles(styles)(UrlifeDialogError)
