import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UrlifeConfirmDialog from '../common/UrlifeConfirmDialog'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  PROJECT_STATUSES
} from '../../constants'

const typeInfo = {
  price: {
    cancel: 'Cancel',
    confirm: 'Set price',
    header: 'Set a custom price',
    text: `Setting the price will make the customer able to pay the project in the normal payment flow.
      (this will also send an email to customer with an invoice)`
  },
  queued: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    header: 'Move project to production queue',
    text: `By doing this the project will be moved to production queue and the
      customer will not check out or pay for the project on the site.`
  }
}

/* eslint-disable sort-keys */
const styles = {
  error: {
    margin: '10px 0',
    color: '#d70101',
    opacity: 0
  },
  underline: {
    '&:before': {
      borderBottomColor: '#666666'
    },
    '&:after': {
      borderBottomColor: '#000000'
    },
    '&._success': {
      '&:before': {
        borderBottomColor: '#01b7d7'
      },
      '&:after': {
        borderBottomColor: '#01b7d7'
      }
    },
    '&._error': {
      '&:before': {
        borderBottomColor: '#d70101'
      },
      '&:after': {
        borderBottomColor: '#d70101'
      }
    }
  }
}
/* eslint-enable sort-keys */

class AdminActionDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      price: '',
      priceError: null
    }
  }

  reset () {
    this.setState({
      price: '',
      priceError: null
    })
  }

  onClose () {
    this.reset()
    this.props.onClose()
  }

  onComplete () {
    this.reset()
    this.props.onComplete()
  }

  onConfirm () {
    const { type } = this.props
    if (type === 'price') {
      this.setPrice()
    } else if (type === 'queued') {
      this.setStatusQueued()
    }
  }

  async setStatusQueued () {
    const { updateProjectSku } = this.props
    await updateProjectSku({ statusId: PROJECT_STATUSES.PROJECT_QUEUED })
    this.onComplete()
  }

  async setPrice () {
    const { price } = this.state
    const { updateProjectSku } = this.props
    await updateProjectSku({ price: parseInt(price, 10), statusId: PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION })
    this.onComplete()
  }

  onPriceChange (e) {
    const value = e.target.value.replace(',', '.')
    const price = Math.round(parseFloat(value) * 100)
    this.setState({
      price: price.toString(),
      priceError: price >= 0 ? null : 'Not a valid price, price must be $0 or above'
    })
  }

  render () {
    const { classes, isOpen, type } = this.props
    const {
      priceError,
      price
    } = this.state
    return (
      <div>
        <UrlifeConfirmDialog
          isOpen={isOpen}
          header={typeInfo[type].header}
          text={typeInfo[type].text}
          cancelLabel={typeInfo[type].cancel}
          confirmLabel={typeInfo[type].confirm}
          onIgnore={this.onClose.bind(this)}
          onCancel={this.onClose.bind(this)}
          onConfirm={this.onConfirm.bind(this)}
          confirmDisabled={priceError && price.length > 0}
        >
          {type === 'price' &&
            <React.Fragment>
              <Input
                id='price'
                name='price'
                defaultValue={price}
                onChange={this.onPriceChange.bind(this)}
                placeholder='Price in dollars'
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                classes={
                  {
                    underline: classes.underline
                  }
                }
              />
              <div
                className={classes.error}
                style={priceError ? { opacity: 1 } : {}}
              >
                {priceError || ''}
              </div>
            </React.Fragment>
          }
        </UrlifeConfirmDialog>
      </div>
    )
  }
}

AdminActionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  type: PropTypes.string.isRequired,
  updateProjectSku: PropTypes.func.isRequired
}

export default withStyles(styles)(AdminActionDialog)
