import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { parse } from 'query-string'
import { commonStyles, history } from '../../helpers'
import classNames from 'classnames'
import FAQList from '../faq/FAQList'
import InterestedButton from './components/InterestedButton'
import {
  ClapperBoard,
  PlusCircleIconNotch,
  ProducerChairV2,
  ProjectCodeV2,
  SilverseaIcon,
  UploadMovieArrowIcon
} from '../common/SVG'
import Hero from './components/Hero'
import GetStarted from './components/GetStarted'
import TitledVideo from './components/TitledVideo'

/* eslint-disable sort-keys */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  sectionContainer: {
    marginTop: 80
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    color: '#512cb9',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    '& span': {
      fontWeight: 600
    }
  },
  subHeader: {
    fontSize: 24,
    lineHeight: '33px',
    fontWeight: 600,
    color: '#512cb9',
    ...commonStyles.media(991, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  buttonContainer: {
    marginTop: 65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  actionSection: {
    marginTop: 65,
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    backgroundColor: '#d8d8d8'
  },
  actionContainer: {
    padding: '25px 0px',
    maxWidth: 755,
    width: 'auto',
    color: '#55565a',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& h3': {
      margin: '0px',
      fontSize: 29,
      fontWeight: 400,
      ...commonStyles.media(991, {
        fontSize: 27
      }),
      ...commonStyles.media(767, {
        fontSize: 23
      }),
      ...commonStyles.media(575, {
        fontSize: 18
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 20
      })
    },
    '& p': {
      margin: '10px 0px 25px 0px',
      fontSize: 18,
      fontWeight: 500,
      ...commonStyles.media(991, {
        fontSize: 16
      }),
      ...commonStyles.media(767, {
        fontSize: 14
      }),
      ...commonStyles.media(575, {
        fontSize: 12
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 14
      })
    },
    ...commonStyles.media(767, {
      maxWidth: '90%'
    })
  },
  inspirationHolder: {
    backgroundColor: '#f0f0f0',
    marginTop: 50,
    overflowX: 'hidden',
    position: 'relative'
  },
  faqHeader: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
    color: '#55565a',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 24,
      lineHeight: '30px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '24px'
    })
  },
  faqText: {
    marginTop: 5,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 14,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#55565a'
    },
    '&._marginBottom': {
      marginBottom: 30
    }
  },
  contactContainer: {
    marginTop: 47,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  }
}
/* eslint-enable sort-keys */

const steps = [
  {
    icon: ProjectCodeV2,
    text: 'Use project code included in your email to start an account'
  },
  {
    icon: PlusCircleIconNotch,
    text: 'Follow on-screen prompts to start your project'
  },
  {
    icon: UploadMovieArrowIcon,
    text: 'Upload photos/videos using the website or iOS App'
  },
  {
    icon: ProducerChairV2,
    text: 'Sit back and relax while our team makes the magic'
  }
]

const emailModalSettings = {
  disclaimer: `By submitting this form I agree to receive emails from URLIFE Media regarding the
    Silversea program. You can opt-out at any time`,
  logo: ClapperBoard,
  subTitle: 'Please enter your email address below and we will send instructions and your unique project code',
  title: "Yes, I'd like to make a personal movie of my Voyage",
  topics: ['silversea']
}

class SilverseaPostVoyage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      autoplay: false,
      tutorial: false
    }
  }

  componentDidMount () {
    const parsed = parse(history.location.search)
    if (parsed.autoplay && parsed.autoplay === 'true') {
      this.setState({ autoplay: true })
    }
    if (parsed.tutorial && parsed.tutorial === 'true') {
      this.setState({ tutorial: true })
    }
  }

  render () {
    const { classes } = this.props
    const { autoplay, tutorial } = this.state
    return (
      <div className={classes.container}>
        <Hero
          autoplay={autoplay}
          extraClass='_postvoyage'
          headerAfter='MAKE YOUR MEMORIES LAST FOREVER WITH A MINI-MOVIE'
          headerLogo={SilverseaIcon}
          logoColor='#000000'
          thumbnail='https://static.urlifemedia.com/lee-martin/thumbnail.png'
          textColor='#55565a'
          video='https://static.urlifemedia.com/lee-martin/video/master.m3u8'
        />
        <div className={classes.actionSection}>
          <div className={classes.actionContainer}>
            <h3>Our team is ready to make a custom movie of your voyage created from your photos and videos</h3>
            <p>Click the button to enter your email and receive instructions.</p>
            <InterestedButton buttonText='Make My Movie' modalSettings={emailModalSettings}/>
          </div>
        </div>
        <GetStarted
          steps={steps}
          title='SIMPLE AND EASY STEPS'
          autoplay={tutorial}
          setAutoplay={(value) => this.setState({ tutorial: value })}
          subtitle='View Tutorial'
          subtitleVideo='https://static.urlifemedia.com/silversea-postvoyage-tutorial/video/master.m3u8'
          subtitleVideoThumbnail='https://static.urlifemedia.com/silversea-postvoyage-tutorial/thumbnail.png'
          color='#55565a'
        />
        <TitledVideo
          color='#55565a'
          extraClass='_postvoyage'
          title='ENJOY THE PERSONAL ATTENTION OF THE URLIFE TEAM AS PART OF YOUR COMPLIMENTARY MOVIE'
          thumbnail='https://static.urlifemedia.com/thumbnails/silversea-nicole.png'
          video='https://static.urlifemedia.com/nichole_video/14-02-2020/master.m3u8'
        />
        <div className={classes.buttonContainer}>
          <InterestedButton buttonText='Make My Movie' modalSettings={emailModalSettings}/>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.faqHeader}>LEARN MORE</div>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            While there are a lot of helpful answers below, you can always contact the URLIFE team
            and someone will reach out to help.  Whether starting an account or uploading your photos,
            we’ll guide you from the very beginning until you have your completed movie delivered.
            Our team can be reached below.
          </div>
          <FAQList questionsType='silversea' />
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.faqText}>
            <div className={classes.faqHeader}>FOR MORE INFORMATION</div>
            {'If you have any questions, email us at '}
            <a href="mailto:silverseasupport@urlifemedia.com">silverseasupport@urlifemedia.com</a>
          </div>
        </div>
      </div>
    )
  }
}

SilverseaPostVoyage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SilverseaPostVoyage)
