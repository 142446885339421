import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PencilIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21">
      <g><g><path fill="#999" d="M14.07 5.994l-7.842 7.842-1.392-1.392 7.842-7.842zm2.32 2.32l-7.842 7.842-1.392-1.392 7.842-7.842zM6.369 18.337l-4.652.94.94-4.653 1.252-1.252 3.713 3.712zM20.582 5.05L15.942.41a.675.675 0 0 0-.928 0l-2.798 2.799h-.002v.002L1.587 13.836a.655.655 0 0 0-.18.334L.234 19.986a.656.656 0 0 0 .774.773l5.815-1.175a.656.656 0 0 0 .334-.18l1.856-1.856 8.77-8.77a.654.654 0 0 0 0-.928l-4.175-4.176 1.871-1.872 3.713 3.713-.855.854a.656.656 0 1 0 .928.928l1.318-1.318a.656.656 0 0 0 0-.928z" /></g></g>
    </SvgIcon>
  )
}
