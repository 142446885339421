import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DialogContentText from '@material-ui/core/DialogContentText'

const styles = {
  success: {
    color: '#333',
    margin: '16px 0px 50px 0px',
    textAlign: 'center'
  }
}

function UrlifeDialogSuccess (props) {
  return (
    <DialogContentText className={props.classes.success}>
      {props.message}
    </DialogContentText>
  )
}

UrlifeDialogSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

export default withStyles(styles)(UrlifeDialogSuccess)
