import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { AlertTriangle, PlayIcon } from '../../../common/SVG'
import { Button, FormControl, FormHelperText, TextField } from '@material-ui/core'
import ModalPreview from '../../../common/ModalPreview'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  fieldThumbnail: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  fieldThumbnailImage: {
    width: 64,
    height: 64,
    flexShrink: 0,
    borderRadius: 10,
    backgroundColor: '#eff2f5',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    position: 'relative',
    border: '1px solid transparent',
    '&._error': {
      borderColor: '#eb5757'
    },
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  fieldThumbnailIcon: {
    fontSize: 20,
    position: 'absolute',
    color: '#eb5757',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    margin: 'auto'
  },
  changeFileFormContainer: {
    position: 'relative',
    paddingTop: 6
  },
  openFileFormLink: {
    color: '#01b7d7',
    border: 'none',
    fontSize: 14,
    background: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      borderBottom: 'none'
    }
  },
  changeFileForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    width: 350,
    top: '100%',
    marginTop: 10,
    left: -22,
    backgroundColor: '#fff',
    boxShadow: '0px 10px 23px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.1)',
    padding: '10px 22px',
    zIndex: 1,
    '&:before': {
      content: '""',
      border: '6px solid transparent',
      borderBottom: '6px solid white',
      position: 'absolute',
      top: -12,
      left: 70
    }
  },
  changeFileInputContainer: {
    width: '100%',
    '& + &': {
      marginTop: 30
    }
  },
  changeFileInputComponent: {
    width: '100%'
  },
  changeFileInput: {
    fontSize: 14
  },
  submitButton: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    color: '#01b7d7',
    fontWeight: 700,
    marginTop: 10,
    '&[disabled]': {
      background: 'none'
    }
  },
  editFilePlayIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    color: '#ffffff'
  }
}
/* eslint-enable sort-keys */

class EditFileField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chosenVideo: null,
      localImage: props.image || '',
      localVideo: props.video || '',
      openForm: false,
      openModal: false
    }
    this.isFocused = false
    this.documentClickHandler = e => {
      if (!this.isFocused && this.changeFileFormContainerRef !== e.target &&
        !this.changeFileFormContainerRef.contains(e.target) &&
        this.state.openForm) {
        this.setState({
          localImage: this.props.image,
          localVideo: this.props.video || '',
          openForm: false
        })
      }
    }
  }

  onChangeField (fieldName, fieldValue) {
    this.setState({
      [fieldName]: fieldValue
    })
  }

  toggleForm () {
    this.setState({
      openForm: !this.state.openForm
    })
  }

  onSubmitForm (e) {
    const { onApply, image, video, withVideo } = this.props
    const { localImage, localVideo } = this.state
    e.preventDefault()

    if (image !== localImage || video !== localVideo) {
      onApply(withVideo ? [localImage, localVideo] : localImage)
    }

    this.setState({ openForm: false })
  }

  openPopupVideoPreview () {
    const { withVideo, video } = this.props
    withVideo && video && this.setState({ chosenVideo: video, openModal: true })
  }

  onClose () {
    this.setState({
      chosenVideo: null,
      openModal: false
    })
  }

  componentDidMount () {
    document.body.addEventListener('click', this.documentClickHandler)
  }

  componentWillUnmount () {
    document.body.removeEventListener('click', this.documentClickHandler)
  }

  render () {
    const { classes, image, withVideo, video, fieldTitle } = this.props
    const { openForm, localImage, localVideo, openModal, chosenVideo } = this.state
    const isValidImage = localImage.trim().length > 0
    const isValidVideo = !withVideo || localVideo.trim().length > 0
    const isValidForm = isValidImage && isValidVideo

    return (
      <div className={classes.fieldThumbnail}>
        <div
          className={classNames(
            classes.fieldThumbnailImage,
            {
              _clickable: withVideo && video,
              _error: !isValidForm
            }
          )}
          onClick={() => this.openPopupVideoPreview()}
          style={image ? { backgroundImage: `url(${image})` } : {}}>

          {(!image || !video) && <AlertTriangle className={classes.fieldThumbnailIcon} />}
          {withVideo && image && video &&
          <PlayIcon className={classes.editFilePlayIcon}/>
          }
        </div>

        <div
          ref={ref => { this.changeFileFormContainerRef = ref }}
          className={classes.changeFileFormContainer}>
          <button
            onClick={this.toggleForm.bind(this)}
            className={classes.openFileFormLink}>Change {fieldTitle.toLowerCase()}</button>

          {openForm &&
          <form onSubmit={ e => this.onSubmitForm(e)} className={classes.changeFileForm}>
            <FormControl error={!isValidImage} className={classes.changeFileInputContainer}>
              <TextField
                error={!isValidImage}
                onChange={e => this.onChangeField('localImage', e.target.value)}
                value={localImage}
                autoFocus={true}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  classes: {
                    root: classes.changeFileInput
                  }
                }}
                onFocus={() => { this.isFocused = true }}
                onBlur={() => { this.isFocused = false }}
                placeholder={'Enter link'}
                label='Image'
                className={classes.changeFileInputComponent}/>

              {!isValidImage &&
              <FormHelperText>Required</FormHelperText>
              }
            </FormControl >

            {withVideo &&
            <FormControl error={!isValidImage} className={classes.changeFileInputContainer}>
              <TextField
                error={!isValidVideo}
                onChange={e => this.onChangeField('localVideo', e.target.value)}
                value={localVideo}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  classes: {
                    root: classes.changeFileInput
                  }
                }}
                onFocus={() => { this.isFocused = true }}
                onBlur={() => { this.isFocused = false }}
                placeholder={'Enter link'}
                label='Video'
                className={classes.changeFileInputComponent}/>

              {!isValidVideo &&
              <FormHelperText>Required</FormHelperText>
              }
            </FormControl >
            }

            <Button
              disabled={!isValidForm}
              className={classes.submitButton}
              type={'submit'}>APPLY</Button>
          </form>
          }
        </div>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          video={chosenVideo}
          title={''}
        />
      </div>
    )
  }
}

EditFileField.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fieldTitle: PropTypes.string,
  image: PropTypes.string,
  onApply: PropTypes.func.isRequired,
  video: PropTypes.string,
  withVideo: PropTypes.bool.isRequired
}

export default withStyles(styles)(connect()(EditFileField))
