import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import Typography from '@material-ui/core/Typography'
import { PROJECT_STATUSES, PROJECT_STATUSES_NAME, ROLES } from '../../../constants'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import {
  CardViewIcon,
  ListViewIcon
} from '../../common/SVG'
import UrlifeCheckbox from '../../common/UrlifeCheckbox'

/* eslint-disable sort-keys */
const styles = {
  projectListHeader: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 6
  },
  header: {
    color: '#323232',
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '72px',
    ...commonStyles.media(575, {
      fontSize: 28,
      padding: '0 30px'
    }),
    ...commonStyles.media(410, {
      fontSize: 22
    })
  },
  projectNavBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 14,
    ...commonStyles.media(767, {
      marginBottom: 0
    })
  },
  projectTypeButton: {
    color: '#989898',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.64,
    textTransform: 'uppercase',
    minWidth: 150,
    margin: '0px 5px',
    '&._active': {
      color: '#323232'
    },
    '&:hover:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    },
    '&._active:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 3,
      borderRadius: 2,
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    }
  },
  statusSelect: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 17
  },
  checkbox: {
    marginLeft: 6,
    marginRight: 15
  },
  viewIcon: {
    width: 27,
    height: 27,
    position: 'absolute',
    right: 0,
    top: 23,
    cursor: 'pointer'
  }
}
/* eslint-enable sort-keys */

class ProjectListHeader extends React.Component {
  render () {
    const {
      classes,
      roleId,
      type,
      view,
      setView,
      statuses,
      setStatus,
      deleted,
      setDeleted,
      setProjectPath,
      assignedOnly,
      inProductionOnly
    } = this.props
    let title = 'My Gallery'
    let filterList = []
    switch (type) {
      case 'assigned':
        title = 'Project Gallery'
        if (inProductionOnly) {
          filterList = [
            PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
            PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
          ]
        } else {
          filterList = [
            PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
            PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL,
            PROJECT_STATUSES.COMPLETE
          ]
        }
        break
      case 'unassigned':
        title = 'Project Gallery'
        if (roleId === ROLES.PRODUCER) {
          filterList = [
            PROJECT_STATUSES.PROJECT_QUEUED,
            PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION,
            PROJECT_STATUSES.PREP_WAIT_PAYMENT
          ]
        }
        break
      default:
        break
    }
    return (
      <div className={classes.projectListHeader}>
        <Typography component="h1" variant="h1" classes={{ h1: classes.header }}>
          {title}
        </Typography>
        <div className={classes.projectNavBar}>
          {(type === 'complete' || type === 'inprogress') && <React.Fragment>
            <Button
              className={classNames(classes.projectTypeButton, { _active: type === 'complete' })}
              onClick={() => { setProjectPath('complete') }}>
              Complete
            </Button>
            <Button
              className={classNames(classes.projectTypeButton, { _active: type === 'inprogress' })}
              onClick={() => { setProjectPath('inprogress') }}>
              In Production
            </Button>
          </React.Fragment>}
          {!assignedOnly && (type === 'assigned' || type === 'unassigned') && <React.Fragment>
            <Button
              className={classNames(classes.projectTypeButton, { _active: type === 'assigned' })}
              onClick={() => { setProjectPath('assigned') }}>
              Assigned
            </Button>
            <Button
              className={classNames(classes.projectTypeButton, { _active: type === 'unassigned' })}
              onClick={() => { setProjectPath('unassigned') }}>
              Unassigned
            </Button>
          </React.Fragment>}
        </div>
        {filterList.length > 0 &&
        <div className={classes.statusSelect}>
          {filterList.map(status => (
            <UrlifeCheckbox
              key={+status}
              className={classes.checkbox}
              label={PROJECT_STATUSES_NAME[status]}
              checked={statuses.indexOf(+status) > -1}
              onChange={setStatus}
              value={+status}
            />
          ))}
          {type === 'assigned' && !inProductionOnly &&
          <UrlifeCheckbox
            className={classes.checkbox}
            label='Deleted'
            checked={deleted}
            onChange={setDeleted}
            value={deleted}
          />
          }
        </div>
        }
        {(type === 'assigned' || type === 'unassigned') && <React.Fragment>
          {view === 'card' && <ListViewIcon className={classes.viewIcon} onClick={() => { setView('list') }} />}
          {view === 'list' && <CardViewIcon className={classes.viewIcon} onClick={() => { setView('card') }} />}
        </React.Fragment>}
      </div>
    )
  }
}

ProjectListHeader.propTypes = {
  assignedOnly: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  deleted: PropTypes.bool.isRequired,
  inProductionOnly: PropTypes.bool.isRequired,
  roleId: PropTypes.number,
  setDeleted: PropTypes.func.isRequired,
  setProjectPath: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['card', 'list']).isRequired
}

const mapStateToProps = state => {
  return {
    roleId: state.user.roleId
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProjectListHeader))
