import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function UserIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 600 600" stroke={props.stroke || '#333'} strokeWidth="30" fill="none">
      <g><g><g><circle cx="300" cy="300" r="265" fill="none" /><circle cx="300" cy="230" r="115" fill="none" /><path d="M106.81863443903,481.4 a205,205 1 0,1 386.36273112194,0" strokeLinecap="butt" fill="none" /></g></g></g>
    </SvgIcon>
  )
}

UserIcon.propTypes = {
  stroke: PropTypes.string
}
