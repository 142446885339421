import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function AppleIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 18 22' >
      <path fillRule="evenodd" d="M15.051 11.76c0-1.723.915-3.345 2.44-4.258-.915-1.318-2.44-2.129-4.066-2.23-1.729-.203-3.356 1.014-4.27 1.014-.916 0-2.237-1.014-3.66-1.014-1.933.101-3.661 1.115-4.576 2.737-1.932 3.346-.509 8.415 1.423 11.152C3.257 20.48 4.376 22 5.8 22c1.424-.101 1.932-.912 3.66-.912 1.729 0 2.136.912 3.66.912 1.526 0 2.44-1.318 3.356-2.737.712-.913 1.22-2.028 1.525-3.143-1.83-.609-2.949-2.433-2.949-4.36zM12.204 3.548c.814-1.013 1.22-2.23 1.119-3.548a4.72 4.72 0 0 0-3.254 1.724c-.813.912-1.22 2.129-1.118 3.446 1.322 0 2.44-.608 3.253-1.622z"/>
    </SvgIcon>
  )
}

AppleIcon.propTypes = {
  fill: PropTypes.string
}
