import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function IconInfo (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path fillRule="evenodd" d="M13.893 2.877A7.735 7.735 0 1 0 2.955 13.818a7.734 7.734 0 0 0 10.938 0 7.737 7.737 0 0 0 0-10.941zM8.299 1.95c.817 0 1.482.664 1.482 1.481a1.49 1.49 0 0 1-1.482 1.496 1.498 1.498 0 0 1-1.496-1.496c0-.831.657-1.481 1.496-1.481zm2.381 10.895c0 .311-.151.462-.463.462H6.396c-.311 0-.462-.151-.462-.462v-.974c0-.312.151-.463.462-.463h.707V7.297h-.722c-.311 0-.462-.151-.462-.463V5.86c0-.312.151-.462.462-.462h2.668c.31 0 .462.15.462.462v5.548h.706c.312 0 .463.151.463.463v.974z"/>
    </SvgIcon>
  )
}
