const projectList = (state = {
  projects: []
}, action) => {
  switch (action.type) {
    case 'APPEND_PROJECTS':
      if (!action.projects || !Array.isArray(action.projects)) return state
      return {
        projects: [...state.projects, ...action.projects]
      }
    case 'PREPEND_PROJECTS':
      if (!action.projects || !Array.isArray(action.projects)) return state
      return {
        projects: [...action.projects, ...state.projects]
      }
    case 'UPDATE_PROJECT_IN_LIST':
      if (!action.id && !action.project) return state
      return {
        projects: state.projects.map(project => {
          if (project.id === action.id) {
            return { ...project, ...action.project }
          } else {
            return project
          }
        })
      }
    case 'CLEAR_PROJECT_LIST':
      return {
        projects: []
      }
    default:
      return state
  }
}

export default projectList
