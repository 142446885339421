import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function BoxedCheckMarkIcon ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 487.78 467'>
      <style type="text/css">
        .st0{`{fill:none;stroke:${color};stroke-width:7;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}`}
        .st1{`{fill:none;stroke:${color};stroke-width:7;stroke-linejoin:round;stroke-miterlimit:10;}`}
      </style>
      <g
        id="Layer_1"
        transform="translate(-173.5,-160.5)">
        <g
          id="g631">
          <rect
            x="177"
            y="234.84"
            className="st0"
            width="413.72"
            height="389.16"
            id="rect625" />
          <polyline
            className="st0"
            points="260.12,443.27 355.52,524.82 529.32,332.13   "
            id="polyline627" />
          <polyline
            className="st1"
            points="468.87,164 657.78,164 657.78,343.47   "
            id="polyline629" />
        </g>
      </g>
    </SvgIcon>
  )
}

BoxedCheckMarkIcon.propTypes = {
  color: PropTypes.string
}
