import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import UrlifeVideoSlider from '../../common/UrlifeVideoSlider'
import { fetchInspiration } from '../../../actions'
import connect from 'react-redux/es/connect/connect'

/* eslint-disable sort-keys */
const styles = {
  group: {
    height: 'auto',
    position: 'static',
    marginBottom: 450,
    boxSizing: 'border-box',
    ...commonStyles.media(599, {
      marginBottom: 350
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 280,
      marginTop: -35,
      marginBottom: 10
    }),
    ...commonStyles.media(480, {
      height: 345,
      marginTop: -35,
      marginBottom: 10
    })
  },
  layer: {
    position: 'relative',
    height: 'auto'
  }
}
/* eslint-enable sort-keys */

class GetInspired extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      movies: null
    }
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }
      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
  }

  componentDidMount () {
    this.props.fetchInspirationFunction()
      .then(res => {
        this.setState({ movies: res.data.sort(this.compareFields) })
      })
  }

  render () {
    const { classes } = this.props
    const { movies } = this.state
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('GetInspired')} className={classes.group} index={34}>
        <ParallaxLayer layer='layer5' className={classes.layer}>
          <UrlifeVideoSlider
            header='REAL LIFE EXPERIENCES. URLIFE MAGIC.'
            movies={movies}
            subheader='See what our editors can do for you.'
          />
        </ParallaxLayer>
      </ParallaxGroup>
    )
  }
}

GetInspired.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchInspirationFunction: PropTypes.func.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchInspirationFunction: function () {
      return dispatch(fetchInspiration())
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(GetInspired))
