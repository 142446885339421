import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  PACKAGE_TYPES,
  PROJECT_STAGES,
  ROLES
} from '../../../constants'
import { connect } from 'react-redux'
import {
  getLeastCompleteProjectSku,
  getPackageIdFromPackageSkuId,
  getPackageType,
  mapProjectStatusToStage
} from '../../../helpers'
import { clearUpload } from '../../../actions'
import { fileProgressStatus, fileProgressType } from '../../../reducers/fileProgress'
import ProjectStage from './components/ProjectStage'
import InfoBar from './components/InfoBar'
import FileProgress from './components/FileProgress'
import AppointmentInfo from './components/AppointmentInfo'
import InfoBox from './components/InfoBox'

/* eslint-disable sort-keys */
const styles = {
  root: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#ffffff',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#323232',
    overflow: 'hidden',
    '&._fixedWidth': {
      width: '352px'
    },
    '&._standalone': {
      borderRadius: '2px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.07)'
    },
    '&._hidden': {
      display: 'none'
    }
  }
}
/* eslint-enable sort-keys */

class ProjectStatusBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      completeTimeout: false,
      rootWidth: 0,
      showComplete: true
    }
    this.rootElement = React.createRef()
  }

  getComponentWidth () {
    const rootWidth = this.rootElement.current ? this.rootElement.current.clientWidth : 352
    this.setState({ rootWidth: rootWidth })
  }

  componentDidMount () {
    this.checkProgress()
    this.getComponentWidth()
    window.addEventListener('resize', this.getComponentWidth.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.getComponentWidth.bind(this))
  }

  componentDidUpdate (prevProps, prevState) {
    const wasUploading = prevProps.fileProgress.some(file => (
      file.projectId === prevProps.project.id &&
      file.progressType === fileProgressType.upload
    ))
    const isUploading = this.hasFiles(fileProgressType.upload)

    if (wasUploading && !isUploading) {
      if (!this.state.showComplete) {
        this.setState({ showComplete: true })
      } else {
        this.setState({
          completeTimeout: true
        }, () => {
          setTimeout(() => {
            this.setState({
              completeTimeout: false
            })
          }, 5000)
        })
      }
    }
  }

  checkProgress () {
    const { fileProgress, project, clearUploadFunction } = this.props
    if (fileProgress && fileProgress.length > 0 && project.id !== 'admin') {
      const fileProgressDone = fileProgress.filter(file => (
        file.projectId === project.id &&
        file.status !== fileProgressStatus.complete
      )).length === 0
      if (fileProgressDone) {
        this.setState({
          showComplete: false
        }, clearUploadFunction)
      }
    }
  }

  hasFiles (progressType) {
    const { fileProgress, project } = this.props
    if (project.id === 'admin') {
      return fileProgress.some(file => file.role === 'system' && file.progressType === progressType)
    } else {
      return fileProgress.some(file => file.projectId === project.id && file.progressType === progressType)
    }
  }

  render () {
    const {
      classes,
      project,
      type,
      user,
      hideInactive,
      packages,
      packageType,
      fileBatch
    } = this.props
    const {
      completeTimeout,
      rootWidth
    } = this.state

    let queuedBegun = false
    let productionBegun = false
    let showAppointment = false
    const isCustomer = user.roleId === ROLES.USER
    const isProducer = user.roleId === ROLES.PRODUCER
    const isAdmin = user.roleId === ROLES.ADMIN
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const stage = mapProjectStatusToStage(projectSku.statusId)
      queuedBegun = stage >= PROJECT_STAGES.QUEUED
      productionBegun = stage >= PROJECT_STAGES.PRODUCTION
      const packageId = getPackageIdFromPackageSkuId(projectSku.packageSku, packages)
      const isHollywoodMovie = getPackageType(packages, packageId) === PACKAGE_TYPES.HOLLYWOOD ||
        packageType === PACKAGE_TYPES.HOLLYWOOD
      const hasAssignedProducer = !!projectSku.producerId
      const isAssignedProducer = projectSku.producerId === user.userid
      const isAllowedProducer = ((hasAssignedProducer && isAssignedProducer) || (!hasAssignedProducer && isProducer))
      showAppointment = isHollywoodMovie &&
        (isCustomer || isAllowedProducer || isAdmin) &&
        stage !== PROJECT_STAGES.COMPLETE &&
        !completeTimeout
    }

    const projectFileBatch = fileBatch[project.id]
    const isProcessing = projectFileBatch && (
      projectFileBatch.totalCount !== 0 ||
      projectFileBatch.completedCount < projectFileBatch.totalCount
    )
    const isDownloading = this.hasFiles(fileProgressType.download)
    const isUploading = this.hasFiles(fileProgressType.upload)
    const isGeneratingZip = this.hasFiles(fileProgressType.generateZip)
    const isActive = isUploading || isDownloading || isGeneratingZip || isProcessing

    let sizeClass = rootWidth >= 353 ? '_large' : rootWidth >= 300 ? '_medium' : '_small'
    if (type === 'standalone' || type === 'standalone-resizable') {
      sizeClass = '_medium'
    }

    let extraInfo = ''
    if (isCustomer) {
      if (productionBegun) {
        extraInfo = 'Your project is now with our producers'
      } else if (queuedBegun) {
        extraInfo = 'Producer will contact you within 24hrs'
      }
    }

    return (
      <div
        className={
          classNames(
            classes.root,
            { _fixedWidth: type === 'standalone' },
            { _standalone: type === 'standalone' || type === 'standalone-resizable' },
            { _hidden: hideInactive && !isActive }
          )
        }
        ref={this.rootElement}
      >
        {project.id !== 'admin' && <React.Fragment>
          {!isDownloading && <ProjectStage projectSku={projectSku} sizeClass={sizeClass} />}
          {isDownloading && <InfoBar text={'Do not leave this page'} sizeClass={sizeClass} />}
        </React.Fragment>}
        {isActive && <FileProgress project={project} sizeClass={sizeClass} />}
        {!isActive && showAppointment && <AppointmentInfo project={project} sizeClass={sizeClass} />}
        {!isActive && !showAppointment && <InfoBox text={extraInfo} sizeClass={sizeClass} />}
      </div>
    )
  }
}

ProjectStatusBar.propTypes = {
  classes: PropTypes.object.isRequired,
  clearUploadFunction: PropTypes.func.isRequired,
  fileBatch: PropTypes.object,
  fileProgress: PropTypes.array,
  hideInactive: PropTypes.bool,
  packages: PropTypes.array,
  packageType: PropTypes.number,
  project: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['regular', 'standalone', 'standalone-resizable']),
  user: PropTypes.object.isRequired
}

ProjectStatusBar.defaultProps = {
  type: 'regular'
}

const mapStateToProps = state => {
  return {
    fileBatch: state.fileBatch,
    fileProgress: Object.values(state.fileProgress),
    packages: state.packages.packages,
    skinData: state.skinning.skinData,
    user: state.user
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearUploadFunction: function () {
      dispatch(clearUpload())
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectStatusBar))
