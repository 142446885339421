import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeInfiniteScroller from '../common/UrlifeInfiniteScroller'
import { listTopicSubscriptions } from '../../actions'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  titleText: {
    margin: '10px 0px 30px 10px'
  },
  subscriptionsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: 10,
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  subscriptionRow: {
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  subscriptionCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    maxWidth: 275,
    padding: 10,
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&._red': {
      color: '#d70101'
    },
    '&._link': {
      color: '#01b7d7',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}

class AdminTopicSubscriptions extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      nextSubscription: null,
      subscriptions: []
    }
  }

  async fetchTopicSubscriptions () {
    const { listTopicSubscriptionFunction } = this.props
    const { subscriptions, nextSubscription } = this.state
    const params = {
      cursor: nextSubscription
    }
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await listTopicSubscriptionFunction(params)
        const newSubscription = response.data
        this.setState({
          isLoading: false,
          nextSubscription: response.nextCursor,
          subscriptions: [...subscriptions, ...newSubscription]
        })
      } catch (e) {
        this.setState({ isLoading: false })
      }
    })
  }

  componentDidMount () {
    this.fetchTopicSubscriptions()
  }

  render () {
    const { classes } = this.props
    const {
      subscriptions,
      nextSubscription,
      isLoading
    } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h4" component="h2" className={classes.titleText}>
            <span>Topic subscriptions</span>
          </Typography>
          <UrlifeInfiniteScroller
            loadMore={this.fetchTopicSubscriptions.bind(this)}
            hasMore={!!nextSubscription}
            isLoading={isLoading}
            isEmpty={!subscriptions.length}
            className={classes.subscriptionsTable}
          >
            <div
              data-testid='apl-header'
              className={classes.headerRow}
            >
              <div className={classes.headerCol}>Name</div>
              <div className={classes.headerCol}>Email</div>
              <div className={classes.headerCol}>Topic</div>
              <div className={classes.headerCol}>Date</div>
            </div>
            {subscriptions && subscriptions.map(subscription => {
              return (
                <div
                  data-testid='apl-subscription'
                  key={subscription.id}
                  className={classes.subscriptionRow}
                >
                  <div className={classes.subscriptionCol}>
                    {subscription.fullname || '[ No name ]'}
                  </div>
                  <div className={classes.subscriptionCol}>
                    {subscription.email || '[ No email ]'}
                  </div>
                  <div className={classes.subscriptionCol}>
                    {(subscription.topics && subscription.topics.join(',')) || '[ No topics ]'}
                  </div>
                  <div className={classes.subscriptionCol}>
                    {(subscription.start_date && subscription.start_date.split('T')[0]) || '[ No date ]'}
                  </div>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
        </Paper>
      </div>
    )
  }
}

AdminTopicSubscriptions.propTypes = {
  classes: PropTypes.object.isRequired,
  listTopicSubscriptionFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    listTopicSubscriptionFunction: function (params) {
      return dispatch(listTopicSubscriptions(params))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(AdminTopicSubscriptions))
