import React from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga4'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  videoPlayerWrapper: {
    fontFamily: 'Montserrat',
    maxWidth: '100%',
    position: 'relative',
    width: 600
  }
})

const errorMessage = `
The media could not be loaded, either because the server or network failed or because the format is not supported.
`

class VideoPlayerPreview extends React.Component {
  componentDidMount () {
    videojs.addLanguage('en', {
      [errorMessage]:
        'Unable to playback video due to a network or server issue.'
    })
    const options = this.props
    if (options && options.html5 && options.html5.hls) {
      options.html5.hls.overrideNative = !videojs.browser.IS_SAFARI
    }
    this.player = videojs(this.videoNode, options, () => {
      this.props.onMount && this.props.onMount(this.player)
    })
    this.player.on('error', (e) => {
      const playerError = this.player.error()
      /* eslint-disable-next-line no-console */
      console.error('VideoJS: ', playerError, '\nJS: ', e)
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (Array.isArray(this.props.sources) && Array.isArray(prevProps.sources) &&
      this.props.sources[0].src !== prevProps.sources[0].src
    ) {
      this.player.pause()
      this.player.src(this.props.sources)
    }
    const prevOptions = prevProps
    const options = this.props
    if (prevOptions.poster !== options.poster) {
      this.player.poster(options.poster)
    }
    if (prevOptions.autoplay !== options.autoplay) {
      this.player.autoplay(options.autoplay)
    }
  }

  componentWillUnmount () {
    const { gaVideoTitle } = this.props
    if (this.player) {
      if (gaVideoTitle) {
        ReactGA.event({
          action: 'timeOnVideo',
          category: 'marketingPage',
          label: gaVideoTitle,
          value: parseInt(this.player.currentTime())
        })
      }
      this.player.dispose()
    }
  }

  render () {
    const { wrapperWidth = 600, wrapperHeight = 'auto', skin = 'default', classes, mediaType } = this.props
    return (
      <div
        className={classes.videoPlayerWrapper}
        style={{ height: wrapperHeight, width: wrapperWidth }}>
        <div data-vjs-player>
          {mediaType === 'video' &&
            <video ref={ (node) => { this.videoNode = node } } className={`video-js vjs-${skin}`} />
          }
          {mediaType === 'audio' &&
            <audio ref={ (node) => { this.videoNode = node } } className={`video-js vjs-${skin}`} />
          }
        </div>
      </div>
    )
  }
}

VideoPlayerPreview.propTypes = {
  autoplay: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  gaVideoTitle: PropTypes.string,
  html5: PropTypes.object,
  mediaType: PropTypes.oneOf(['video', 'audio']).isRequired,
  onMount: PropTypes.func,
  poster: PropTypes.string,
  price: PropTypes.string,
  skin: PropTypes.string,
  sources: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  video: PropTypes.string,
  wrapperHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapperWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

VideoPlayerPreview.defaultProps = {
  mediaType: 'video'
}

export default withStyles(styles)(VideoPlayerPreview)
