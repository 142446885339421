import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  deleteCuratedFile,
  deleteFile,
  downloadErrorMessage,
  downloadFile,
  downloadFilesSize
} from '../../../actions'
import SwitchViewButton from './SwitchViewButton'
import DropZone from '../DropZone'
import classNames from 'classnames'
import {
  CloseIcon,
  DownloadIcon,
  HeartOutline,
  StarOutline,
  TrashIcon
} from '../SVG'
import { api } from '../../../constants'
import { is1080p, is4K } from '../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  headerContainer: {
    display: 'block',
    position: 'relative',
    height: 36
  },
  headerRow: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,
    visibility: 'hidden',
    '&._visible': {
      visibility: 'visible'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headerText: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    color: '#323232',
    letterSpacing: '0.58px',
    textTransform: 'uppercase',
    display: 'inline',
    '&._fileCounts': {
      marginTop: 5,
      textTransform: 'none'
    },
    '&._producerFileCounts': {
      color: '#414141',
      fontSize: 12,
      letterSpacing: 0.44,
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none'
    }
  },
  selectedIcon: {
    position: 'relative',
    top: 4,
    width: 22,
    height: 22,
    marginRight: 15,
    display: 'inline-block',
    cursor: 'pointer',
    color: '#333333',
    '&._close': {
      width: 18,
      height: 18
    },
    '&._trash': {
      top: 2,
      marginRight: 0
    },
    '&._heart': {
      stroke: '#333',
      fill: '#333'
    }
  },
  download: {
    display: 'inline-block',
    marginLeft: 15,
    cursor: 'pointer',
    color: '#323232',
    '& svg': {
      width: 20,
      height: 20
    },
    '& :hover': {
      color: '#848484'
    }
  }
}
/* eslint-enable sort-keys */

class FilesHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fileCounts: this.countFileTypes(props.files)
    }
  }

  countFileTypes (files) {
    const fileCounts = {
      images: 0,
      other: 0,
      videos: {
        res1080p: 0,
        res4k: 0,
        total: 0
      }
    }
    files.forEach(file => {
      const isImage = (file.mimeType && file.mimeType.includes('image')) || (file.type && file.type.includes('image'))
      const isVideo = (file.mimeType && file.mimeType.includes('video')) || (file.type && file.type.includes('video'))
      if (isImage) {
        fileCounts.images += 1
      } else if (isVideo) {
        if (is4K(file)) {
          fileCounts.videos.res4k += 1
        }
        if (is1080p(file)) {
          fileCounts.videos.res1080p += 1
        }
        fileCounts.videos.total += 1
      } else {
        fileCounts.other += 1
      }
    })
    return fileCounts
  }

  componentDidUpdate (prevProps) {
    if (prevProps.files.length !== this.props.files.length) {
      this.setState({ fileCounts: this.countFileTypes(this.props.files) })
    }
  }

  getSelectedFiles () {
    const { files, selectedFiles } = this.props
    return files.filter(file => selectedFiles.has(file.id))
  }

  favoriteSelected () {
    const { saveFavoriteStatus, deselectAll } = this.props
    const selectedFiles = this.getSelectedFiles()
    let favCount = 0
    selectedFiles.forEach(file => { favCount += file.favorite ? 1 : 0 })
    const isFavorite = favCount !== selectedFiles.length
    selectedFiles.forEach(file => {
      if (file.favorite !== isFavorite) {
        saveFavoriteStatus(file, isFavorite)
      }
    })
    deselectAll()
  }

  prodFavoriteSelected () {
    const { saveProducerFavoriteStatus, deselectAll } = this.props
    const selectedFiles = this.getSelectedFiles()
    let favCount = 0
    selectedFiles.forEach(file => { favCount += file.producerFavorite ? 1 : 0 })
    const isFavorite = favCount !== selectedFiles.length
    selectedFiles.forEach(file => {
      if (file.producerFavorite !== isFavorite) {
        saveProducerFavoriteStatus(file, isFavorite)
      }
    })
    deselectAll()
  }

  deleteSelected () {
    const {
      projectId,
      projectSkuId,
      coverFileId,
      deleteFileFunction,
      updateProject,
      isCurated,
      deleteCuratedFileFunction,
      deselectAll
    } = this.props
    const selectedFiles = this.getSelectedFiles()
    selectedFiles.forEach(async file => {
      if (isCurated) {
        deleteCuratedFileFunction(projectId, projectSkuId, file.id)
      } else {
        await deleteFileFunction(projectId, file.id)
        if (coverFileId === file.id) {
          updateProject({ coverFileId: '' })
        }
      }
    })
    deselectAll()
  }

  async downloadFiles () {
    const {
      projectId,
      projectTitle,
      isCurated,
      clientDownloadFunction,
      downloadFilesSizeFunction,
      setFileError
    } = this.props
    const data = {
      queryParams: {
        include: isCurated ? 'curated' : 'project'
      },
      url: api.DOWNLOAD_SELECTED_FILES,
      urlVars: {
        projectId
      }
    }

    try {
      const res = await downloadFilesSizeFunction(projectId, data.queryParams)
      if (res.totalSize > 0 && res.numFiles > 0) {
        await clientDownloadFunction({
          ...data,
          name: projectTitle + '.zip',
          projectId,
          role: 'zip',
          total: res.totalSize
        }, true)
      }
    } catch (e) {
      setFileError(downloadErrorMessage(e), projectTitle + '.zip')
    }
  }

  render () {
    const {
      classes,
      files,
      selectedFiles,
      projectId,
      projectSkuId,
      isCurated,
      title,
      permissions,
      view,
      onViewChange,
      deselectAll,
      canUpload
    } = this.props
    const { fileCounts } = this.state

    const selectedCount = selectedFiles ? selectedFiles.size : 0
    const selectionMode = selectedCount > 0

    let countText = ''
    if (fileCounts.images > 0) {
      countText += 'Photos: ' + fileCounts.images
      if (fileCounts.videos.total > 0 || fileCounts.other > 0) {
        countText += ', '
      }
    }
    if (fileCounts.videos.total > 0) {
      countText += 'Videos: ' + fileCounts.videos.total
      if (permissions.isNonCustomer && (fileCounts.videos.res1080p > 0 || fileCounts.videos.res4k > 0)) {
        countText += ' ('
        if (fileCounts.videos.res1080p > 0) {
          countText += fileCounts.videos.res1080p + ' >= 1080p'
          if (fileCounts.videos.res4k > 0) {
            countText += ', '
          }
        }
        if (fileCounts.videos.res4k > 0) {
          countText += fileCounts.videos.res4k + ' >= 4K'
        }
        countText += ')'
      }
      if (fileCounts.other > 0) {
        countText += ', '
      }
    }
    if (fileCounts.other > 0) {
      countText += 'Other: ' + fileCounts.other
    }

    return (
      <div className={classes.headerContainer}>
        <div
          data-testid='fh-non-selection-mode'
          className={classNames(classes.headerRow, { _visible: !selectionMode })}
        >
          <div className={classes.titleContainer}>
            <div className={classNames(classes.headerText)}>{title}</div>
            {permissions.canDownload &&
              <div className={classes.download}>
                <DownloadIcon
                  data-testid='fh-download-icon'
                  onClick={this.downloadFiles.bind(this)}
                />
              </div>
            }
          </div>
          <div className={classNames(classes.headerText, {
            _fileCounts: permissions.isProjectCreator,
            _producerFileCounts: !permissions.isProjectCreator
          })}>
            {countText}
            {permissions.isNonCustomer && canUpload &&
              <React.Fragment>
                <DropZone
                  view={'icon'}
                  filesServer={files}
                  projectId={projectId}
                  projectSkuId={projectSkuId}
                  isCurated={isCurated} />
                <SwitchViewButton onChange={onViewChange} view={view} />
              </React.Fragment>
            }
          </div>
        </div>
        <div
          data-testid='fh-selection-mode'
          className={classNames(classes.headerRow, { _visible: selectionMode })}
        >
          <div>
            <CloseIcon
              data-testid='fh-close-icon'
              className={classNames(classes.selectedIcon, '_close')}
              onClick={deselectAll}
            />
            <div className={classes.headerText}>
              {selectedCount + (selectedCount === 1 ? ' FILE SELECTED' : ' FILES SELECTED')}
            </div>
          </div>
          <div>
            {permissions.canProdFavorite &&
              <HeartOutline
                data-testid='fh-heart-outlined-icon'
                className={classNames(classes.selectedIcon, '_heart')}
                onClick={this.prodFavoriteSelected.bind(this)}
              />
            }
            {permissions.canFavorite &&
              <StarOutline
                data-testid='fh-star-outlined-icon'
                className={classes.selectedIcon}
                onClick={this.favoriteSelected.bind(this)}
              />
            }
            {permissions.canDelete &&
              <TrashIcon
                data-testid='fh-trash-icon'
                className={classNames(classes.selectedIcon, '_trash')}
                onClick={this.deleteSelected.bind(this)}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

FilesHeader.propTypes = {
  canUpload: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  clientDownloadFunction: PropTypes.func.isRequired,
  coverFileId: PropTypes.string,
  deleteCuratedFileFunction: PropTypes.func,
  deleteFileFunction: PropTypes.func.isRequired,
  deselectAll: PropTypes.func,
  downloadFilesSizeFunction: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  isCurated: PropTypes.bool.isRequired,
  onViewChange: PropTypes.func,
  permissions: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projectSkuId: PropTypes.string,
  projectTitle: PropTypes.string.isRequired,
  saveFavoriteStatus: PropTypes.func,
  saveProducerFavoriteStatus: PropTypes.func,
  selectedFiles: PropTypes.object,
  setFileError: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateProject: PropTypes.func.isRequired,
  view: PropTypes.string
}

function mapDispatchToProps (dispatch) {
  return {
    clientDownloadFunction: function (fileData, needAuth = false) {
      return dispatch(downloadFile(fileData, needAuth))
    },
    deleteCuratedFileFunction: function (projectId, projectSkuId, fileId) {
      dispatch(deleteCuratedFile(projectId, projectSkuId, fileId))
    },
    deleteFileFunction: function (projectId, fileId) {
      return dispatch(deleteFile(projectId, fileId))
    },
    downloadFilesSizeFunction: function (projectId, params, needAuth = false) {
      return dispatch(downloadFilesSize(projectId, params, needAuth))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(FilesHeader))
