export {
  clearMoreMenuActions,
  setMoreMenuAction,
  setTopBarHeaders,
  setTopBarSteps,
  setTopBarType
}

function setTopBarHeaders (stepHeader, header) {
  return dispatch => {
    return dispatch({ header: header, stepHeader: stepHeader, type: 'SET_TOP_BAR_HEADERS' })
  }
}

function setTopBarSteps (step, totalSteps) {
  return dispatch => {
    return dispatch({ step: step, totalSteps: totalSteps, type: 'SET_TOP_BAR_STEPS' })
  }
}

function setTopBarType (type) {
  return dispatch => {
    return dispatch({ topBarType: type, type: 'SET_TOP_BAR_TYPE' })
  }
}

function setMoreMenuAction (name, func) {
  return dispatch => {
    return dispatch({ func, name, type: 'SET_MORE_MENU_ACTION' })
  }
}

function clearMoreMenuActions () {
  return dispatch => {
    return dispatch({ type: 'CLEAR_MORE_MENU_ACTIONS' })
  }
}
