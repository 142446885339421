export {
  clearThumbnails,
  queueThumbnail
}

function generateCanvas (dispatch, file) {
  return new Promise((resolve, reject) => {
    const sourceImage = new window.Image()
    sourceImage.crossOrigin = 'anonymous'
    sourceImage.addEventListener('load', function loadImage () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const outcomeWidth = sourceImage.height * (400 / sourceImage.width)
      canvas.width = 400
      canvas.height = outcomeWidth

      ctx.mozImageSmoothingEnabled = true
      ctx.webkitImageSmoothingEnabled = true
      ctx.msImageSmoothingEnabled = true
      ctx.imageSmoothingEnabled = true

      ctx.drawImage(sourceImage, 0, 0, 400, outcomeWidth)

      const thumbnail = canvas.toDataURL('image/jpeg')
      sourceImage.removeEventListener('load', loadImage)
      dispatch({ fileId: file.id, thumbnail: thumbnail, type: 'ADD_THUMBNAIL' })
      resolve()
    })

    sourceImage.src = file.src
  })
}

function batchLoad (dispatch, getStore) {
  const queue = getStore().thumbnails.queue
  const count = queue.length > 5 ? 5 : queue.length
  const promises = []
  for (let i = 0; i < count; i++) {
    promises.push(generateCanvas(dispatch, queue[i]))
  }
  Promise.all(promises)
    .then(() => {
      if (getStore().thumbnails.queue.length !== 0) {
        batchLoad(dispatch, getStore)
      }
    })
}

function queueThumbnail (file) {
  return (dispatch, getStore) => {
    const thumbnails = getStore().thumbnails.thumbnails
    if ((thumbnails && thumbnails[file.id]) ||
      !file.src || !file.mimeType || !file.mimeType.includes('image') ||
      file.mimeType.includes('heic') || file.mimeType.includes('heif')) {
      return
    }

    const queue = getStore().thumbnails.queue
    dispatch({ file: file, type: 'ADD_THUMBNAIL_TO_QUEUE' })
    if (queue.length === 0) {
      batchLoad(dispatch, getStore)
    }
  }
}

function clearThumbnails () {
  return { type: 'CLEAR_THUMBNAILS' }
}
