import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { commonStyles } from '../../../../helpers'
import {
  setTopBarHeaders
} from '../../../../actions'
import UrlifeButton from '../../../common/UrlifeButton'
import UrlifeTextField from '../../../common/UrlifeTextField'

/* eslint-disable sort-keys */
const styles = {
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  inputBlock: {
    width: '100%',
    display: 'block',
    paddingBottom: 80
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  nextButton: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

class NameProject extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      maxLength: 50,
      title: props.title || ''
    }
    this.handlingEvent = false
  }

  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  handleChangeInputTitle (e) {
    this.setState({ title: e.target.value })
  }

  onNext () {
    if (!this.handlingEvent) {
      this.saveTitle(true)
    }
  }

  async saveTitle (next = false) {
    const {
      nextStep,
      updateProject
    } = this.props
    const { title } = this.state
    const titleValue = title.trim()
    if (titleValue.length > 0) {
      await updateProject(
        { title: titleValue },
        { title: true }
      )
      this.handlingEvent = false
      if (next) {
        nextStep()
      }
    } else {
      this.handlingEvent = false
    }
  }

  handleBlur (e) {
    this.handlingEvent = true
    // this is needed to allow user to click next when onBlur is called on field
    const { relatedTarget } = e
    if (relatedTarget && (relatedTarget.getAttribute('type')) === 'button') {
      this.saveTitle(true)
    } else {
      this.saveTitle(false)
    }
  }

  onSubmit (e) {
    e.preventDefault()
    this.onNext()
  }

  render () {
    const { classes } = this.props
    const { title, maxLength } = this.state
    const titleValue = title.trim()
    const isTitleValid = titleValue.length > 0
    return (
      <div className={classes.createMovieSteptwo}>
        <div className={classes.createMovieContent}>
          <div className={classes.formWrapper}>
            <form onSubmit={this.onSubmit.bind(this)}>
              <div className={classes.inputBlock}>
                <UrlifeTextField
                  id="input"
                  autoFocus
                  label={'Project title'}
                  onChange={this.handleChangeInputTitle.bind(this)}
                  onBlur={this.handleBlur.bind(this)}
                  placeholder={'Necessary to proceed'}
                  defaultValue={titleValue}
                  maxLength={maxLength}
                />
              </div>
            </form>
            <div className={classes.buttonBlock}>
              <UrlifeButton
                className={classes.nextButton}
                disabled={!isTitleValid}
                onClick={() => this.onNext()}>
                  Next
              </UrlifeButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
NameProject.propTypes = {
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  title: PropTypes.string,
  updateProject: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Step', 'Name your project'))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(NameProject))
