import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ExpandIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 9">
      <path transform="rotate(90 8 3)" fill="#01b7d7" d="M5.018-2.268a.623.623 0 0 0 .884 0 .625.625 0 0 0 0-.884l-.63-.63a.625.625 0 1 0-.883.884zm7.095 5.327L7.13-1.923a.625.625 0 1 0-.884.884l4.54 4.54-6.399 6.398a.625.625 0 1 0 .884.884l6.841-6.84a.625.625 0 0 0 0-.884z"/>
    </SvgIcon>
  )
}
