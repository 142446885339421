if (!window.Element.prototype.scrollIntoViewIfNeeded) {
  window.Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
    function makeRange (start, length) {
      return { end: start + length, length: length, start: start }
    }

    function coverRange (inner, outer) {
      if (
        centerIfNeeded === false ||
        (outer.start < inner.end && inner.start < outer.end)
      ) {
        return Math.max(
          inner.end - outer.length,
          Math.min(outer.start, inner.start)
        )
      }
      return (inner.start + inner.end - outer.length) / 2
    }

    function makePoint (x, y) {
      return {
        translate: function translate (dX, dY) {
          return makePoint(x + dX, y + dY)
        },
        x: x,
        y: y
      }
    }

    function absolute (elem, pt) {
      while (elem) {
        pt = pt.translate(elem.offsetLeft, elem.offsetTop)
        elem = elem.offsetParent
      }
      return pt
    }

    let target = absolute(this, makePoint(0, 0))
    const extent = makePoint(this.offsetWidth, this.offsetHeight)
    let elem = this.parentNode

    let origin

    while (elem instanceof window.HTMLElement) {
      // Apply desired scroll amount.
      origin = absolute(elem, makePoint(elem.clientLeft, elem.clientTop))
      elem.scrollLeft = coverRange(
        makeRange(target.x - origin.x, extent.x),
        makeRange(elem.scrollLeft, elem.clientWidth)
      )
      elem.scrollTop = coverRange(
        makeRange(target.y - origin.y, extent.y),
        makeRange(elem.scrollTop, elem.clientHeight)
      )

      // Determine actual scroll amount by reading back scroll properties.
      target = target.translate(-elem.scrollLeft, -elem.scrollTop)
      elem = elem.parentNode
    }
  }
}
