import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { getImageRotationCss } from '../../../helpers/imageRotationCss'
import {
  AudioIcon,
  CheckMark,
  DownloadIcon,
  FilmCameraIcon,
  HeartFilled,
  HeartOutline,
  NoThumbnailIcon,
  StarFilled,
  StarOutline,
  TrashIcon,
  UploadingIcon
} from '../SVG'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import classNames from 'classnames'
import {
  deleteCuratedFile,
  deleteFile,
  downloadErrorMessage,
  downloadFile,
  queueThumbnail
} from '../../../actions'
import UrlifeButton from '../UrlifeButton'
import UploadSourceType from './UploadSourceType'
import {
  getFilePermissions,
  is4K
} from '../../../helpers'
import { MAX_TRANSCODE_FILE_SIZE } from '../../../constants'

/* eslint-disable sort-keys */
const styles = {
  filesViewItem: {
    paddingBottom: '100%',
    backgroundColor: '#d8d8d8',
    position: 'relative',
    cursor: 'pointer',
    boxShadow: '0 1px 8px rgba(0, 0, 0, 0.07)',
    borderRadius: '2px',
    '&._carousel': {
      paddingBottom: '66.666%',
      border: '2px solid #f6f6f6',
      '&._first': {
        border: '2px solid #01b7d7',
        borderRadius: 2
      }
    },
    '&._uploading': {
      backgroundColor: 'transparent'
    }
  },
  filesViewVideo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#000'
  },
  fileStatus: {
    position: 'absolute',
    textAlign: 'center',
    color: '#dde3e8',
    left: 0,
    right: 0,
    top: '50%',
    transform: 'translate(0, -50%)'
  },
  fileStatusText: {
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&._uploading': {
      color: '#aacb7b'
    },
    '&._failed': {
      color: '#ff3b30'
    }
  },
  filesViewItemBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '&._uploading': {
      opacity: 0.4
    }
  },
  noThumbnailContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadingIcon: {
    width: 25,
    height: 40,
    marginBottom: 10
  },
  noThumbnailIcon: {
    width: '50%',
    height: '50%'
  },
  noThumbnailText: {
    fontSize: 10,
    fontWeight: 500,
    color: '#b1b1b1',
    '&._uploading': {
      color: '#323232'
    }
  },
  imageStyles: {
    width: '100%',
    height: '100%',
    objectPosition: 'center center',
    objectFit: 'cover'
  },
  uploadProgressBarContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '4px',
    overflow: 'hidden'
  },
  uploadProgressBar: {
    position: 'absolute',
    top: 0,
    backgroundImage: 'linear-gradient(to right, #01bbd7, #02dad8)',
    height: '4px',
    borderRadius: '0 2px 2px 0'
  },
  gradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    height: 30,
    backgroundImage: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    transition: 'opacity ease .3s',
    opacity: 0.3,
    '&._bottom': {
      top: 'auto',
      bottom: 0,
      backgroundImage: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
    },
    '&._selected': {
      opacity: 0
    }
  },
  iconContainer: {
    position: 'absolute',
    left: 5,
    right: 5,
    top: 5,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    transition: 'opacity ease .3s',
    opacity: 1,
    '&._bottom': {
      top: 'auto',
      bottom: 5
    },
    '&._selected': {
      opacity: 0
    }
  },
  icon: {
    width: 18,
    height: 20,
    padding: '0 2px',
    color: '#ffffff'
  },
  iconWrapperLeft: {
    position: 'absolute',
    left: 0
  },
  badgeWrapper: {
    position: 'relative',
    height: 24
  },
  iconBadge: {
    position: 'absolute',
    left: -8,
    bottom: -2,
    fontSize: 10,
    color: '#ffffff',
    backgroundColor: 'rgba(1, 183, 215, 1)',
    padding: '1px 4px',
    textAlign: 'center',
    borderRadius: '50%',
    pointerEvents: 'none'
  },
  selectBoxContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity ease .3s',
    opacity: 0,
    '&:hover': {
      opacity: 1
    },
    '&._selected': {
      opacity: 1
    }
  },
  selectBox: {
    position: 'absolute',
    left: 5,
    top: 5,
    width: '25px',
    height: '25px',
    borderRadius: '2px',
    border: '1px solid #ffffff',
    transition: 'opacity ease .2s',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    },
    '&._selected': {
      opacity: 0
    }
  },
  selectBoxOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity ease .3s',
    opacity: 0,
    '&._selected': {
      opacity: 0.7,
      backgroundColor: '#01b7d7'
    }
  },
  selectBoxIcon: {
    display: 'block',
    margin: 'auto',
    width: '65%',
    height: '65%',
    paddingTop: '30%',
    color: '#ffffff',
    transition: 'opacity ease .3s',
    opacity: 0,
    '&._selected': {
      opacity: 0.99
    }
  },
  selectBoxButtonContainer: {
    position: 'absolute',
    left: 5,
    right: 5,
    top: 5,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  notesButton: {
    position: 'absolute',
    bottom: 0,
    color: '#ffffff',
    border: 'solid 1px #ffffff',
    margin: 5,
    width: 'calc(100% - 10px)'
  },
  starButton: {
    display: 'block',
    width: 25,
    margin: '2px 3px 0',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    },
    '&._disabled:hover': {
      opacity: 0.6
    }
  },
  trashButton: {
    display: 'block',
    width: 18,
    margin: '0 3px',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    }
  },
  heartButton: {
    display: 'block',
    width: 25,
    margin: '1px 3px 0',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    },
    '&._outline': {
      stroke: '#FFF',
      fill: '#FFF'
    },
    '&._disabled:hover': {
      opacity: 0.6
    }
  },
  downloadButton: {
    display: 'block',
    width: 25,
    margin: '2px 3px 0',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    }
  },
  audioIcon: {
    width: '50%',
    height: '50%',
    fill: '#333333'
  }
}
/* eslint-enable sort-keys */

class FilesViewItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hover: false
    }
    const { file, thumbnail, dispatch } = props
    if (file.src && !thumbnail &&
      (file.mimeType && file.mimeType.includes('image'))) {
      dispatch(queueThumbnail(file))
    }
  }

  componentDidUpdate (prevProps) {
    const { file, thumbnail, dispatch } = this.props
    if (file.src && file.src !== prevProps.file.src &&
      !thumbnail &&
      (file.mimeType && file.mimeType.includes('image'))) {
      dispatch(queueThumbnail(file))
    }
  }

  toggleHover () {
    this.setState({ hover: !this.state.hover })
  }

  toggleFavorite (e, favorite) {
    const { file, saveFavoriteStatus } = this.props
    e.stopPropagation()
    saveFavoriteStatus(file, favorite)
  }

  toggleProdFavorite (e, producerFavorite) {
    const { file, saveProducerFavoriteStatus } = this.props
    e.stopPropagation()
    saveProducerFavoriteStatus(file, producerFavorite)
  }

  toggleSelected (e, fileId, selected) {
    if (selected !== this.props.isSelected) {
      const { onSelected } = this.props
      e.stopPropagation()
      onSelected && onSelected(fileId, selected)
    }
  }

  fileProgress (fileId) {
    const { fileProgress } = this.props
    return fileProgress && fileProgress.total
      ? Math.round((fileProgress.loaded / fileProgress.total * 100 * 100)) / 100
      : 0
  }

  deleteFile (e) {
    const {
      file,
      project,
      dispatch,
      isCurated,
      onFileDelete,
      updateProject
    } = this.props
    e.stopPropagation()
    onFileDelete && onFileDelete(file.id)
    if (isCurated) {
      dispatch(deleteCuratedFile(project.id, file.project_sku_id, file.id))
    } else {
      dispatch(deleteFile(project.id, file.id))
        .then(() => {
          if (project.coverFileId === file.id) {
            updateProject({ coverFileId: '' })
          }
        })
    }
  }

  downloadCuratedFile (e) {
    const { file, project, dispatch, setFileError } = this.props
    e.stopPropagation()
    dispatch(downloadFile({
      id: file.id,
      name: file.id + '.' + file.file_ext,
      projectId: project.id,
      size: file.fileSize,
      url: file.signedUrl
    }, false))
      .then(
        success => {},
        error => {
          setFileError(downloadErrorMessage(error), file.id + '.' + file.file_ext)
        }
      )
  }

  render () {
    const {
      classes,
      view,
      file,
      edit,
      onOpen,
      isCurated,
      isSelected,
      selectionMode,
      thumbnail,
      openFileNotes,
      first,
      roleId,
      project,
      userId
    } = this.props
    const isTile = view === 'tile'
    const isCarousel = view === 'carousel'
    const isVideo = file.mimeType && file.mimeType.includes('video')
    const isAudio = file.mimeType && file.mimeType.includes('audio')
    const is4KVideo = isVideo && is4K(file)
    let src = ''
    if (file.src || thumbnail) {
      src = thumbnail || ''
    } else if (file.thumbnailCdnUrl || file.signedThumbnailUrl) {
      src = cacheSignedUrl.get(file.thumbnailCdnUrl || file.signedThumbnailUrl)
    }
    const { hover } = this.state
    const isFileUploadComplete = !edit || file.status === 3
    const isFileInUploading = file.status === 1
    const isFileUploadFailed = file.status === 2
    const isTranscodable = file.fileSize <= MAX_TRANSCODE_FILE_SIZE

    const permissions = getFilePermissions(file, project, userId, roleId, isCurated)

    return (
      <div
        className={
          classNames(
            classes.filesViewItem,
            { _carousel: isCarousel },
            { _first: first },
            { _uploading: !isFileUploadComplete && !isFileUploadFailed }
          )
        }
        onClick={() => onOpen(file.id)}>

        {src &&
          <div
            className={classNames(classes.filesViewItemBackground, { _uploading: !isFileUploadComplete })}
            style={{
              ...getImageRotationCss(!file.thumbnailCdnUrl || src === thumbnail ? file.orientation : 0)
            }}
          >
            <img
              alt=""
              className={classes.imageStyles}
              src={src}
              loading="lazy"
              decoding="async"
              data-testid='fvi-thumbnail-image'
            />
          </div>
        }

        {/* thumbnail missing */}
        {!src &&
        <div className={classes.noThumbnailContainer}>
          {!isFileUploadComplete && !isFileUploadFailed &&
          <React.Fragment>
            <UploadingIcon className={classes.uploadingIcon} data-testid='fvi-uploading-icon' />
            <div className={classNames(classes.noThumbnailText, '_uploading')}>Uploading...</div>
          </React.Fragment>
          }
          {isFileUploadComplete && !isAudio &&
            <React.Fragment>
              <NoThumbnailIcon className={classes.noThumbnailIcon} data-testid='fvi-no-thumbnail-icon' />
              <div className={classes.noThumbnailText}>Upload complete.</div>
              <div className={classes.noThumbnailText}>
                {isTranscodable ? 'Generating thumbnail...' : 'This file is too large to preview'}
              </div>
            </React.Fragment>
          }
          {isFileUploadComplete && isAudio &&
            <React.Fragment>
              <AudioIcon className={classes.audioIcon} data-testid='fvi-audio-icon' />
            </React.Fragment>
          }
          {isFileUploadFailed &&
            <React.Fragment>
              {!isAudio &&
                <NoThumbnailIcon className={classes.noThumbnailIcon} data-testid='fvi-no-thumbnail-icon-failed' />
              }
              {isAudio && <AudioIcon className={classes.audioIcon} data-testid='fvi-audio-icon-failed' />}
            </React.Fragment>
          }
        </div>
        }

        {/* icons */}
        <div className={classNames(classes.gradient, { _selected: isSelected })}/>
        <div className={classNames(classes.iconContainer, { _selected: isSelected })}>
          {file.uploadSource && permissions.isNonCustomer && !hover && !first && isTile &&
            <div className={classes.iconWrapperLeft}>
              <UploadSourceType
                className={classes.icon}
                type={file.uploadSource}
                icon
              />
            </div>}
          {permissions.canSeeFavorite && file.favorite && !hover && !first &&
            <StarFilled
              className={classes.icon}
              data-testid='fvi-star-filled'
            />}
          {permissions.canSeeProdFavorite && file.producerFavorite && !hover && !first &&
            <HeartFilled
              className={classes.icon}
              data-testid='fvi-heart-filled'
            />}
          {isVideo && !hover && !first && isTile &&
            <div className={classes.badgeWrapper}>
              <FilmCameraIcon
                style={hover ? { display: 'none' } : {}}
                data-testid='fvi-film-camera-icon'
              />
              {permissions.isNonCustomer && is4KVideo && <div className={classes.iconBadge}>4K</div>}
            </div>}
        </div>
        {isCarousel &&
        <React.Fragment>
          <div className={classNames(classes.gradient, '_bottom', { _selected: isSelected })} />
          <div className={classNames(classes.iconContainer, '_bottom', { _selected: isSelected })}>
            {isVideo &&
              <div className={classes.badgeWrapper}>
                <FilmCameraIcon data-testid='fvi-film-camera-icon-carousel'/>
                {permissions.isNonCustomer && is4KVideo && <div className={classes.iconBadge}>4K</div>}
              </div>}
          </div>
        </React.Fragment>
        }

        {/* select box */}
        <div
          onMouseEnter={this.toggleHover.bind(this)}
          onMouseLeave={this.toggleHover.bind(this)}
          className={classNames(classes.selectBoxContainer, { _selected: isSelected || selectionMode })}
          style={first === true ? { opacity: 1 } : {}}
          data-testid='fvi-select-box-container'
        >
          {!isCurated &&
          <React.Fragment>
            <div
              className={classNames(classes.selectBoxOverlay, { _selected: isSelected })}
              onClick={e => this.toggleSelected(e, file.id, false)}
              data-testid='fvi-select-box-overlay'
            />
            <CheckMark
              className={classNames(classes.selectBoxIcon, { _selected: isSelected })}
              onClick={e => this.toggleSelected(e, file.id, false)}
              data-testid='fvi-check-mark'
            />
          </React.Fragment>
          }
          {(hover || first) && !selectionMode &&
            <React.Fragment>
              <div className={classes.selectBoxButtonContainer}>
                {!file.favorite && permissions.canSeeFavorite &&
                  <StarOutline
                    onClick={permissions.canFavorite ? e => this.toggleFavorite(e, true) : null}
                    className={classNames(classes.starButton, { _disabled: !permissions.canFavorite })}
                    data-testid='fvi-star-outline-action'
                  />
                }
                {file.favorite && permissions.canSeeFavorite &&
                  <StarFilled
                    onClick={permissions.canFavorite ? e => this.toggleFavorite(e, false) : null}
                    className={classNames(classes.starButton, { _disabled: !permissions.canFavorite })}
                    data-testid='fvi-star-filled-action'
                  />
                }
                {!file.producerFavorite && permissions.canSeeProdFavorite &&
                  <HeartOutline
                    onClick={permissions.canProdFavorite ? e => this.toggleProdFavorite(e, true) : null}
                    className={
                      classNames(classes.heartButton, '_outline', { _disabled: !permissions.canProdFavorite })
                    }
                    data-testid='fvi-heart-outline-action'
                  />
                }
                {file.producerFavorite && permissions.canSeeProdFavorite &&
                  <HeartFilled
                    onClick={permissions.canProdFavorite ? e => this.toggleProdFavorite(e, false) : null}
                    className={classNames(classes.heartButton, { _disabled: !permissions.canProdFavorite })}
                    data-testid='fvi-heart-filled-action'
                  />
                }

                {isCurated && permissions.canDownload && <DownloadIcon
                  onClick={e => this.downloadCuratedFile(e)}
                  className={classes.downloadButton}
                   data-testid='fvi-download-icon'
                />}

                {permissions.canDelete && <TrashIcon
                  onClick={e => this.deleteFile(e)}
                  className={classes.trashButton}
                  data-testid='fvi-trash-icon'
                />}
              </div>
              {permissions.canSeeNotes && isTile &&
              <UrlifeButton
                inverted
                onClick={() => openFileNotes(file.id)}
                className={classes.notesButton}
              >
                Notes
              </UrlifeButton>
              }
            </React.Fragment>
          }
          {permissions.hasActions && !isCurated && isTile &&
          <div
            className={classNames(classes.selectBox, { _selected: isSelected })}
            onClick={e => this.toggleSelected(e, file.id, !isSelected)}
            data-testid='fvi-select-box'
          />
          }
        </div>

        {/* file statuses */}
        {!isFileUploadComplete && isFileInUploading &&
          <div className={classes.uploadProgressBarContainer}>
            <div
              className={classes.uploadProgressBar}
              style={{
                width: this.fileProgress(file.id) + '%'
              }}
              data-testid='fvi-progress-bar'
            />
          </div>
        }
        {!isFileUploadComplete && isFileUploadFailed &&
          <div className={classes.fileStatus}>
            <div className={`${classes.fileStatusText} _failed`}>Uploading failed</div>
          </div>
        }
      </div>
    )
  }
}

FilesViewItem.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  fileProgress: PropTypes.object,
  first: PropTypes.bool,
  isCurated: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFileDelete: PropTypes.func,
  onOpen: PropTypes.func.isRequired,
  onSelected: PropTypes.func,
  openFileNotes: PropTypes.func,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  saveFavoriteStatus: PropTypes.func,
  saveProducerFavoriteStatus: PropTypes.func,
  selectionMode: PropTypes.bool,
  setFileError: PropTypes.func.isRequired,
  thumbnail: PropTypes.string,
  updateProject: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  view: PropTypes.oneOf(['tile', 'carousel']).isRequired
}

FilesViewItem.defaultProps = {
  first: false,
  isSelected: false,
  selectionMode: false
}

const mapStateToProps = state => {
  return {
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(FilesViewItem))
