import AWS from 'aws-sdk'
import { awsInfo } from '../constants'
import { getCognito } from '../actions'

const managedUploads = {}
export const uploadFileService = (file, info) => {
  return async dispatch => {
    if (!info || !info.fileKey) {
      throw new Error('Missing info, and fileKey.')
    }
    if (!file) {
      throw new Error('Missing file to upload.')
    }

    const cognitoInfo = await dispatch(getCognito())
    if (!AWS.config.credentials) {
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityId: cognitoInfo.IdentityId,
        Logins: {
          'cognito-identity.amazonaws.com': cognitoInfo.Token
        }
      })
      AWS.config.update({ region: 'us-west-2' })
    } else if (AWS.config.credentials.params.Logins['cognito-identity.amazonaws.com'] !== cognitoInfo.Token) {
      AWS.config.credentials.IdentityId = cognitoInfo.IdentityId
      AWS.config.credentials.params.Logins['cognito-identity.amazonaws.com'] = cognitoInfo.Token
      if (!AWS.config.credentials.params.IdentityId) {
        AWS.config.credentials.params.IdentityId = cognitoInfo.IdentityId
      }
      AWS.config.credentials.refresh()
    }
    if (info && info.start && info.start.type) {
      dispatch(info.start)
    }
    const s3 = new AWS.S3({ params: { Bucket: awsInfo.BUCKET } })
    const key = awsInfo.UPLOAD + '/' + cognitoInfo.IdentityId + '/' + info.fileKey
    const upload = s3.upload({
      Body: file,
      Bucket: awsInfo.BUCKET,
      ContentType: file.type,
      Key: key
    })
    managedUploads[info.id] = upload
    const promise = upload.promise()
    if (info.onProgress && typeof info.onProgress === 'function') {
      upload.on('httpUploadProgress', (progress) => {
        if (progress.total && progress.loaded) {
          info.onProgress(progress.loaded, progress.total)
        }
      })
    }
    return promise.then(
      data => {
        if (info.onComplete && typeof info.onComplete === 'function') {
          info.onComplete(data, cognitoInfo)
        }
        return { cognitoInfo, data }
      },
      err => {
        if (info.onError && typeof info.onError === 'function') {
          info.onError()
        }
        return Promise.reject(err)
      }
    )
  }
}

export const getManagedUploads = fileId => {
  return managedUploads[fileId]
}
