import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import classNames from 'classnames'
import Slider from 'react-slick'
import styled from 'styled-components'

/* eslint-disable sort-keys */
const styles = {
  testimonialSlider: {
    width: 'calc(100% - 120px)'
  },
  testimonialWrapper: {
    width: '100%',
    padding: '0 5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...commonStyles.media(767, {
      flexDirection: 'column'
    })
  },
  testimonialPart: {
    display: 'flex',
    justifyItems: 'center',
    width: '50%',
    ...commonStyles.media(767, {
      width: '100%'
    })
  },
  testimonialQuote: {
    flexDirection: 'column',
    paddingLeft: '7%',
    ...commonStyles.media(1420, {
      paddingLeft: '5%'
    }),
    ...commonStyles.media(767, {
      paddingLeft: 0
    })
  },
  testimonialText: {
    color: '#8D70E1',
    fontSize: 34,
    fontWeight: 300,
    lineHeight: 'normal',
    ...commonStyles.media(1510, {
      fontSize: 28
    }),
    ...commonStyles.media(1280, {
      fontSize: 24
    }),
    ...commonStyles.media(991, {
      fontSize: 20
    }),
    '&._bold': {
      fontWeight: 600,
      fontSize: 16,
      textTransform: 'uppercase',
      ...commonStyles.media(1510, {
        fontSize: 14
      }),
      ...commonStyles.media(1280, {
        fontSize: 13
      }),
      ...commonStyles.media(991, {
        fontSize: 12
      })
    }
  }
}
/* eslint-enable sort-keys */

const TestimonialImage = styled.div`
  background-color: '#888888';
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.image});
  background-position: ${props => props.position};
  padding-top: 74%;
  width: 100%;
  display: flex;
  margin: 0 auto;
`

class TestimonialSlider extends React.Component {
  render () {
    const { classes, settings, testimonials } = this.props
    const useSettings = {
      className: classes.testimonialSlider,
      ...settings
    }
    return (
      <Slider {...useSettings}>
        {testimonials.map((testimonial, index) => (
          <React.Fragment key={index}>
            <div className={classes.testimonialWrapper}>
              <div className={classes.testimonialPart}>
                <TestimonialImage position={testimonial.imagePosition} image={testimonial.image} />
              </div>
              <div className={classNames(classes.testimonialPart, classes.testimonialQuote)}>
                <p>
                  <div className={classes.testimonialText}>
                    {'“' + testimonial.quote + '”'}
                  </div>
                </p>
                <p>
                  <div className={classNames(classes.testimonialText, '_bold')}>
                    {testimonial.name + ','}
                  </div>
                  <div className={classNames(classes.testimonialText, '_bold')}>
                    {testimonial.project}
                  </div>
                </p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Slider>
    )
  }
}

TestimonialSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  testimonials: PropTypes.array.isRequired
}

export default withStyles(styles)(TestimonialSlider)
