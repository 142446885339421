import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from './UrlifeButton'
import { toggleModal, useCode } from '../../actions'
import { history } from '../../helpers'
import UrlifeConfirmDialog from './UrlifeConfirmDialog'
import { ROLES } from '../../constants'
import ReactGA from 'react-ga4'

/* eslint-disable sort-keys */
const styles = {
  button: {
    width: 210,
    height: 46,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center'
  },
  nonUserMessage: {
    width: 210,
    fontSize: 12,
    fontWeight: 500,
    color: '#d70101',
    textAlign: 'center'
  }
}
/* eslint-enable sort-keys */

class RegisterOrProjectButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dialogOpen: false,
      errorMessage: ''
    }
  }

  handlePromocodeError (res) {
    if (res.status === 'fail') {
      let errorMessage = ''
      const duplicateError = res.error.match(/Duplicate entry/)
      if (duplicateError) {
        errorMessage = 'You have already activated this offer.'
      } else {
        errorMessage = 'This offer is no longer available.'
      }
      this.setState({
        dialogOpen: true,
        errorMessage
      })
    }
  }

  onButtonClick () {
    const {
      roleId,
      loggedIn,
      modal,
      toggleModalFunction,
      promocode,
      useCodeFunction
    } = this.props
    const isUser = roleId && roleId === ROLES.USER
    if (loggedIn) {
      if (!isUser) { return }
      let label = 'marketing'
      if (history.location.pathname && history.location.pathname !== '/') {
        label = history.location.pathname
      }
      ReactGA.event({
        action: 'startProject',
        category: 'conversionRate',
        label
      })
      if (promocode) {
        useCodeFunction(promocode)
          .then(res => {
            if (res.status === 'success') {
              history.push('/createmovie')
            } else {
              this.handlePromocodeError(res)
            }
          })
          .catch(e => {
            this.handlePromocodeError(e)
          })
      } else {
        history.push('/createmovie')
      }
    } else {
      toggleModalFunction(true, modal, promocode)
    }
  }

  render () {
    const {
      classes,
      className,
      roleId,
      loggedIn,
      registerLabel,
      projectLabel
    } = this.props
    const {
      dialogOpen,
      errorMessage
    } = this.state
    const isUser = roleId && roleId === ROLES.USER
    const disabled = loggedIn && !isUser
    return (
      <React.Fragment>
        <UrlifeConfirmDialog
          isOpen={dialogOpen}
          text={errorMessage}
          onIgnore={() => this.setState({ dialogOpen: false })}
          cancelLabel={'Cancel'}
          onCancel={() => this.setState({ dialogOpen: false })}
          confirmLabel={'Start Project'}
          onConfirm={() => history.push('/createmovie')}
        />
        <UrlifeButton
          className={className || classes.button}
          onClick={this.onButtonClick.bind(this)}
          disabled={disabled}
        >
          {loggedIn ? projectLabel : registerLabel}
        </UrlifeButton>
        {disabled && <div className={classes.nonUserMessage}>Must be logged in as a customer</div>}
      </React.Fragment>
    )
  }
}

RegisterOrProjectButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  modal: PropTypes.oneOf(['register', 'getStarted']).isRequired,
  projectLabel: PropTypes.string,
  promocode: PropTypes.string,
  registerLabel: PropTypes.string,
  roleId: PropTypes.number,
  toggleModalFunction: PropTypes.func.isRequired,
  useCodeFunction: PropTypes.func.isRequired
}

RegisterOrProjectButton.defaultProps = {
  modal: 'register',
  projectLabel: 'Start Project',
  promocode: '',
  registerLabel: 'Get Started'
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn,
    roleId: state.user.roleId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModalFunction: function (isOpenModal, componentModal, code) {
      dispatch(toggleModal(isOpenModal, componentModal, code))
    },
    useCodeFunction: function (code) {
      return dispatch(useCode(code))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RegisterOrProjectButton))
