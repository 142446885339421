import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  closeMoreMenu,
  openMoreMenu,
  userLogout
} from '../../../../actions'
import { history } from '../../../../helpers'
import { ROLES } from '../../../../constants'
import UrlifeList from './UrlifeList'
import {
  BellIcon,
  InfoIcon,
  LogOutIcon,
  PackageIcon,
  PromoIcon,
  SettingsIcon
} from '../../SVG/index'

const menus = {
  adminMenu: [
    {
      action: 'admin-settings',
      icon: <SettingsIcon />,
      text: 'admin settings',
      type: 'list-item'
    }
  ],
  lowerMenu: [
    {
      action: 'notifications',
      icon: <BellIcon fill="#fff" />,
      mobileOnly: true,
      text: 'notifications',
      type: 'list-item'
    },
    {
      action: 'settings',
      icon: <SettingsIcon />,
      text: 'settings',
      type: 'list-item'
    },
    {
      action: 'logout',
      icon: <LogOutIcon />,
      text: 'log out',
      type: 'list-item'
    }
  ],
  upperMenu: [
    {
      action: 'packages',
      icon: <PackageIcon />,
      text: 'packages',
      type: 'list-item'
    },
    {
      action: 'about',
      icon: <InfoIcon />,
      text: 'about',
      type: 'list-item'
    },
    {
      action: 'faq',
      icon: <InfoIcon />,
      text: 'faq',
      type: 'list-item'
    }
  ],
  userMenu: [
    {
      action: 'use-promo',
      icon: <PromoIcon />,
      text: 'promo',
      type: 'list-item'
    }
  ]
}

class MainMoreMenu extends Component {
  handleClick (action) {
    const {
      logOutFunction,
      openMoreMenuFunction,
      closeMoreMenuFunction
    } = this.props
    switch (action) {
      case 'logout':
        logOutFunction()
        closeMoreMenuFunction()
        break
      case 'settings':
        openMoreMenuFunction('settings')
        break
      case 'use-promo':
        openMoreMenuFunction('use-promo')
        break
      case 'admin-settings':
        openMoreMenuFunction('admin-settings')
        break
      case 'packages':
        history.push('/pricing')
        closeMoreMenuFunction()
        break
      case 'about':
        history.push('/aboutus')
        closeMoreMenuFunction()
        break
      case 'faq':
        history.push('/faq')
        closeMoreMenuFunction()
        break
      case 'notifications':
        openMoreMenuFunction('notifications')
        break
      default:
        break
    }
  }

  render () {
    const { roleId, notificationsBadge } = this.props
    const isAdmin = roleId && roleId === ROLES.ADMIN
    const isCustomer = roleId && roleId === ROLES.USER
    const isProducer = roleId && roleId === ROLES.PRODUCER
    const isEditor = roleId && roleId === ROLES.EDITOR
    let items = []
    if (!isProducer && !isEditor) {
      items = [...items, ...menus.upperMenu]
      items.push({ type: 'divider' })
    }
    if (isCustomer) {
      items = [...items, ...menus.userMenu]
    }
    if (isAdmin) {
      items = [...items, ...menus.adminMenu]
    }
    items = [...items, ...menus.lowerMenu]
    const notificationsIndex = items.findIndex(item => item.action === 'notifications')
    if (notificationsIndex !== -1) {
      items[notificationsIndex].badge = notificationsBadge
    }
    return (
      <UrlifeList
        items={items}
        handleClick={this.handleClick.bind(this)}
      />
    )
  }
}

MainMoreMenu.propTypes = {
  closeMoreMenuFunction: PropTypes.func.isRequired,
  logOutFunction: PropTypes.func.isRequired,
  notificationsBadge: PropTypes.string,
  openMoreMenuFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number
}

function mapStateToProps (state) {
  return {
    notificationsBadge: state.notification.badge,
    roleId: state.user.roleId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    closeMoreMenuFunction: function () {
      dispatch(closeMoreMenu())
    },
    logOutFunction: function () {
      dispatch(userLogout())
    },
    openMoreMenuFunction: function (menu) {
      dispatch(openMoreMenu(menu))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMoreMenu)
