import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { commonStyles } from '../../../helpers/index'
import { UrlifeLogo } from '../../common/SVG/index'

/* eslint-disable sort-keys */
const styles = {
  logoLink: {
    marginRight: 78,
    ...commonStyles.flex(),
    ...commonStyles.media(991, {
      marginRight: 40
    })
  },
  logoImg: {
    width: 138,
    height: 60,
    '&._link': {
      cursor: 'pointer'
    },
    ...commonStyles.media(991, {
      width: 116
    }),
    ...commonStyles.media(575, {
      width: 80
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 80
    })
  }
}
/* eslint-enable sort-keys */

class TopBarLogo extends React.Component {
  onLogoClick () {
    const event = new window.Event('scrollToTop')
    document.dispatchEvent(event)
  }

  render () {
    const { classes, isLink } = this.props
    return (
      <React.Fragment>
        {isLink &&
        <Link to="/" className={classes.logoLink} onClick={this.onLogoClick}>
          <UrlifeLogo className={classNames(classes.logoImg, '_link')} />
        </Link>
        }
        {!isLink &&
        <UrlifeLogo className={classes.logoImg} />
        }
      </React.Fragment>
    )
  }
}

TopBarLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  isLink: PropTypes.bool.isRequired
}

TopBarLogo.defaultProps = {
  isLink: true
}

export default withStyles(styles)(TopBarLogo)
