import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  closeMoreMenu,
  openMoreMenu
} from '../../../../actions'
import { history } from '../../../../helpers'
import UrlifeList from './UrlifeList'
import { SettingsIcon } from '../../SVG/index'

const adminMenu = [
  {
    action: 'users',
    icon: <SettingsIcon />,
    text: 'users',
    type: 'list-item'
  },
  {
    action: 'admin-projects',
    icon: <SettingsIcon />,
    text: 'projects',
    type: 'list-item'
  },
  {
    action: 'admin-promotions',
    icon: <SettingsIcon />,
    text: 'promotions',
    type: 'list-item'
  },
  {
    action: 'admin-system-assets',
    icon: <SettingsIcon />,
    text: 'system assets',
    type: 'list-item'
  },
  {
    action: 'admin-system',
    icon: <SettingsIcon />,
    text: 'system',
    type: 'list-item'
  },
  {
    action: 'admin-reports',
    icon: <SettingsIcon />,
    text: 'reports',
    type: 'list-item'
  }
]

const systemAssetsMenu = [
  {
    action: 'categories',
    icon: <SettingsIcon />,
    text: 'categories',
    type: 'list-item'
  },
  {
    action: 'prompts',
    icon: <SettingsIcon />,
    text: 'prompts',
    type: 'list-item'
  },
  {
    action: 'explore',
    icon: <SettingsIcon />,
    text: 'explore videos',
    type: 'list-item'
  },
  {
    action: 'inspiration',
    icon: <SettingsIcon />,
    text: 'inspiration videos',
    type: 'list-item'
  },
  {
    action: 'tips',
    icon: <SettingsIcon />,
    text: 'tips videos',
    type: 'list-item'
  },
  {
    action: 'email',
    icon: <SettingsIcon />,
    text: 'email templates',
    type: 'list-item'
  }
]

const promoMenu = [
  {
    action: 'promocodes',
    icon: <SettingsIcon />,
    text: 'promocodes',
    type: 'list-item'
  },
  {
    action: 'projectcodes',
    icon: <SettingsIcon />,
    text: 'project codes',
    type: 'list-item'
  }
]

const projectsMenu = [
  {
    action: 'project-list',
    icon: <SettingsIcon />,
    text: 'project list',
    type: 'list-item'
  },
  {
    action: 'negotiation',
    icon: <SettingsIcon />,
    text: 'custom price negotiation',
    type: 'list-item'
  }
]

const systemMenu = [
  {
    action: 'encoding',
    icon: <SettingsIcon />,
    text: 'encoding queue',
    type: 'list-item'
  }
]

const reportsMenu = [
  {
    action: 'reports',
    icon: <SettingsIcon />,
    text: 'new users / projects by year',
    type: 'list-item'
  },
  {
    action: 'reports-newusers',
    icon: <SettingsIcon />,
    text: 'new users not started / completed',
    type: 'list-item'
  },
  {
    action: 'reports-repeatingusers',
    icon: <SettingsIcon />,
    text: 'repeating users',
    type: 'list-item'
  },
  {
    action: 'reports-returningusers',
    icon: <SettingsIcon />,
    text: 'returning users',
    type: 'list-item'
  },
  {
    action: 'reports-firsttimeusers',
    icon: <SettingsIcon />,
    text: 'first time users',
    type: 'list-item'
  },
  {
    action: 'reports-prepurchaseusers',
    icon: <SettingsIcon />,
    text: 'pre-purchase activity',
    type: 'list-item'
  }
  /* {
    action: 'reports-projectapproval',
    icon: <SettingsIcon />,
    text: 'post project approval email cadence',
    type: 'list-item'
  },
  {
    action: 'reports-awaitingpayment',
    icon: <SettingsIcon />,
    text: 'awaiting payment email cadence',
    type: 'list-item'
  } */
]

class AdminSettings extends PureComponent {
  handleClick (action) {
    const {
      openMoreMenuFunction,
      closeMoreMenuFunction
    } = this.props
    switch (action) {
      case 'users':
        history.push('/admin/users')
        closeMoreMenuFunction()
        break
      case 'admin-projects':
        openMoreMenuFunction('admin-projects')
        break
      case 'admin-promotions':
        openMoreMenuFunction('admin-promotions')
        break
      case 'admin-system-assets':
        openMoreMenuFunction('admin-system-assets')
        break
      case 'admin-system':
        openMoreMenuFunction('admin-system')
        break
      case 'admin-reports':
        openMoreMenuFunction('admin-reports')
        break
      case 'invites':
        history.push('/admin/invites')
        closeMoreMenuFunction()
        break
      case 'categories':
        history.push('/admin/categories')
        closeMoreMenuFunction()
        break
      case 'prompts':
        history.push('/admin/prompts')
        closeMoreMenuFunction()
        break
      case 'explore':
        history.push('/admin/explore')
        closeMoreMenuFunction()
        break
      case 'inspiration':
        history.push('/admin/inspiration')
        closeMoreMenuFunction()
        break
      case 'tips':
        history.push('/admin/tips')
        closeMoreMenuFunction()
        break
      case 'promocodes':
        history.push('/admin/promocodes')
        closeMoreMenuFunction()
        break
      case 'projectcodes':
        history.push('/admin/projectcodes')
        closeMoreMenuFunction()
        break
      case 'negotiation':
        history.push('/admin/negotiation')
        closeMoreMenuFunction()
        break
      case 'email':
        history.push('/admin/emails')
        closeMoreMenuFunction()
        break
      case 'project-list':
        history.push('/admin/projects')
        closeMoreMenuFunction()
        break
      case 'encoding':
        history.push('/admin/encoding')
        closeMoreMenuFunction()
        break
      case 'reports':
        history.push('/admin/reports/usersprojectsbyyear')
        closeMoreMenuFunction()
        break
      case 'reports-projectapproval':
        history.push('/admin/reports/projectapproval')
        closeMoreMenuFunction()
        break
      case 'reports-awaitingpayment':
        history.push('/admin/reports/awaitingpayment')
        closeMoreMenuFunction()
        break
      case 'reports-repeatingusers':
        history.push('/admin/reports/repeatingusers')
        closeMoreMenuFunction()
        break
      case 'reports-returningusers':
        history.push('/admin/reports/returningusers')
        closeMoreMenuFunction()
        break
      case 'reports-firsttimeusers':
        history.push('/admin/reports/firsttimeusers')
        closeMoreMenuFunction()
        break
      case 'reports-prepurchaseusers':
        history.push('/admin/reports/prepurchaseusers')
        closeMoreMenuFunction()
        break
      case 'reports-newusers':
        history.push('/admin/reports/newusers')
        closeMoreMenuFunction()
        break
      default:
        break
    }
  }

  render () {
    const { menu } = this.props
    let items
    switch (menu) {
      case 'admin-projects':
        items = projectsMenu
        break
      case 'admin-promotions':
        items = promoMenu
        break
      case 'admin-system-assets':
        items = systemAssetsMenu
        break
      case 'admin-system':
        items = systemMenu
        break
      case 'admin-reports':
        items = reportsMenu
        break
      default:
        items = adminMenu
        break
    }
    return (
      <UrlifeList
        items={items}
        handleClick={this.handleClick.bind(this)}
      />
    )
  }
}

AdminSettings.propTypes = {
  closeMoreMenuFunction: PropTypes.func.isRequired,
  menu: PropTypes.string,
  openMoreMenuFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    menu: state.moreMenu.menu
  }
}

function mapDispatchToProps (dispatch) {
  return {
    closeMoreMenuFunction: function () {
      dispatch(closeMoreMenu())
    },
    openMoreMenuFunction: function (menu) {
      dispatch(openMoreMenu(menu))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings)
