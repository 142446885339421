import classNames from 'classnames'
import { commonStyles } from '../../helpers'
import ModalPreview from './ModalPreview'
import { ParallaxPlayIcon } from '../common/SVG'
import PropTypes from 'prop-types'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

/* eslint-disable sort-keys */
const styles = {
  videoWrap: {
    overflow: 'hidden',
    boxShadow: '0 2px 13px rgba (0, 0, 0, 0.07)',
    borderRadius: '3px'
  },
  video: {
    position: 'relative',
    cursor: 'pointer'
  },
  videoBlock: {
    width: '100%',
    paddingTop: '66.666%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eff2f5'
  },
  thumbnail: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  gradient: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(170, 170, 170, 0)',
    backgroundImage: `
      radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.34) 0%,
      rgba(255, 255, 255, 0.14) 20%),
      linear-gradient(4deg, rgba(37, 37, 37, 0.75) 0%, rgba(37, 37, 37, 0) 50%)
    `
  },
  playIcon: {
    position: 'relative',
    display: 'inline-block',
    width: '15%',
    height: 'auto',
    margin: 'auto'
  },
  infoText: {
    position: 'absolute',
    left: 15,
    right: 15,
    bottom: 16,
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    color: '#ffffff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&._title': {
      bottom: 33
    },
    '&._subtitle': {
      fontSize: 12,
      opacity: 0.8
    },
    ...commonStyles.media(991, {
      fontSize: 14,
      bottom: 14,
      '&._title': {
        bottom: 31
      },
      '&._subtitle': {
        fontSize: 10
      }
    }),
    ...commonStyles.media(575, {
      fontSize: 13,
      bottom: 12,
      '&._title': {
        bottom: 28
      },
      '&._subtitle': {
        fontSize: 9
      }
    })
  }
}
/* eslint-enable sort-keys */

class ModalVideo extends React.Component {
  constructor (props) {
    super(props)
    const { video } = props
    this.state = {
      openModal: false,
      previewVideo: null,
      thumbnail: this.thumbnailPriority(video)
    }
  }

  setVideo (video) {
    this.setState({
      openModal: true,
      previewVideo: video
    })
  }

  onClose () {
    this.setState({
      openModal: false,
      previewVideo: null
    })
  }

  thumbnailPriority (video) {
    if (video.imageWebviewUrl) return video.imageWebviewUrl
    else if (video.imageUrl) return video.imageUrl
    else if (video.imageThumbnailUrl) return video.imageThumbnailUrl
    else return video.videoThumbnailUrl
  }

  render () {
    const { classes, video } = this.props
    const { thumbnail, previewVideo, openModal } = this.state
    return (
      <div className={classes.videoWrap}>
        {video && (
          <div className={classes.video}>
            <div className={classes.videoBlock}
              onClick={() => {
                (video.videoWebviewUrl || video.videoUrl) &&
                this.setVideo(video.videoWebviewUrl ? video.videoWebviewUrl : video.videoUrl)
              }}>
              {thumbnail &&
                <div className={classes.thumbnail}
                  style={{ backgroundImage: `url(${thumbnail})` }} >
                </div>
              }
              <div className={classes.gradient}>
                <ParallaxPlayIcon className={classes.playIcon} />
              </div>
              <div className={classNames(classes.infoText, '_title')}>{video.title}</div>
              <div className={classNames(classes.infoText, '_subtitle')}>{video.subtitle}</div>
            </div>
            <ModalPreview
              isOpenModalPreview={openModal}
              closeModalPreview={this.onClose.bind(this)}
              video={previewVideo}
              title={''}
            />
          </div>
        )}
      </div>
    )
  }
}

ModalVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired
}

export default withStyles(styles)(ModalVideo)
