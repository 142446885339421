import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'
import classNames from 'classnames'
import {
  ArrowLeft,
  ArrowRight
} from './SVG'
import moment from 'moment-timezone'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

/* eslint-disable sort-keys */
const styles = {
  root: {
    width: '100%'
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,
    '&._subHeader': {
      paddingBottom: 0
    }
  },
  sectionHeaderLabel: {
    fontFamily: 'Montserrat',
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    '&._subHeader': {
      fontSize: 14,
      color: '#666666'
    }
  },
  arrowContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  arrowIcon: {
    width: 12,
    height: 20,
    color: '#666666',
    opacity: 0.4,
    '&._enabled': {
      color: '#333333',
      opacity: 1,
      cursor: 'pointer'
    }
  },
  month: {
    padding: '0 5px',
    fontFamily: 'Montserrat',
    color: '#323232',
    fontWeight: 500,
    fontSize: 16
  },
  timeZoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    paddingTop: 2
  },
  timeZoneForm: {
    paddingLeft: 15,
    maxWidth: 250,
    ...commonStyles.media(575, {
      maxWidth: 170
    })
  },
  timeZoneSelector: {
    fontFamily: 'Montserrat',
    color: '#323232',
    fontSize: 14,
    fontWeight: 500
  },
  pickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40
  },
  pickerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 5px'
  },
  pickerCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    ...commonStyles.media(575, {
      width: 40,
      height: 40,
      borderRadius: 20,
      fontSize: 14
    }),
    '&._header': {
      color: '#666666'
    },
    '&._unselectable': {
      color: '#999999',
      opacity: 0.6
    },
    '&._unselected': {
      backgroundColor: '#e5e5e5',
      color: '#666666',
      cursor: 'pointer'
    },
    '&._selected': {
      boxShadow: '0 3px 12px rgba(0, 0, 0, 0.1)',
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)',
      color: '#ffffff',
      fontWeight: 600,
      cursor: 'pointer'
    }
  }
}
/* eslint-enable sort-keys */

const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const producerHours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
const producerTimeZone = 'America/Los_Angeles'

class DateAndTimePicker extends React.Component {
  constructor (props) {
    super(props)
    const defaultTimeZone = moment.tz.guess()
    const hours = this.getHours(defaultTimeZone)
    const today = this.getToday(defaultTimeZone)
    let defaultDay = moment(props.defaultTimeDate).startOf('hour') || this.checkHourLeftToday(today, hours)
    let selectable = this.isSelectable(defaultDay, defaultDay.hours(), defaultTimeZone)
    if (!selectable) {
      defaultDay = this.checkHourLeftToday(today, hours)
      selectable = this.isSelectable(defaultDay, defaultDay.hours(), defaultTimeZone)
    }
    const firstSelectable = this.checkHourLeftToday(today, hours)
    const oldestDisplayDate = firstSelectable.clone().startOf('week')
    const timeZoneList = this.getTimeZoneList()
    this.state = {
      firstDisplayDate: oldestDisplayDate,
      hours,
      oldestDisplayDate: oldestDisplayDate,
      selectedDateTime: defaultDay,
      selectedTimeZoneIndex: timeZoneList.indexOf(defaultTimeZone),
      timeZoneList: timeZoneList
    }
    this.props.setAppointmentDate(selectable ? defaultDay.toDate() : null)
    props.defaultTimeDate && props.dateValid(selectable)
  }

  getTimeZoneList () {
    const timeZoneList = moment.tz.names().filter(tz => (
      tz.match(/^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+)|(GMT)|(UTC))$/)
    ))
    timeZoneList.sort((a, b) => {
      const offsetDiff = this.getTimeZoneDiff(a, b)
      return offsetDiff === 0 ? a > b : offsetDiff
    })
    return timeZoneList
  }

  getTimeZone (index) {
    const { timeZoneList } = this.state
    return index >= 0 && index < timeZoneList.length ? timeZoneList[index] : ''
  }

  getTimeZoneDiff (timeZoneA, timeZoneB) {
    const todayA = this.getToday(timeZoneA)
    const todayB = this.getToday(timeZoneB)
    return todayA.utcOffset() - todayB.utcOffset()
  }

  getHours (timeZone) {
    const amHours = []
    const pmHours = []
    for (let i = 0; i < 12; i++) {
      const text = i === 0 ? '12' : i.toString()
      amHours.push({ text, value: -1 })
      pmHours.push({ text, value: -1 })
    }
    const timeDiff = Math.floor(this.getTimeZoneDiff(timeZone, producerTimeZone) / 60)
    producerHours.forEach(hour => {
      let convertedHour = hour + timeDiff
      if (convertedHour < 0) {
        convertedHour += 24
      } else if (convertedHour > 23) {
        convertedHour -= 24
      }
      if (convertedHour < 12) {
        amHours[convertedHour].value = convertedHour
      } else {
        pmHours[convertedHour - 12].value = convertedHour
      }
    })
    const hours = []
    amHours.forEach((hour, index) => {
      if (index === 0) {
        hours.push({ text: 'AM', value: -1 })
      } else if (index === 6) {
        hours.push({ text: '', value: -1 })
      }
      hours.push(hour)
    })
    pmHours.forEach((hour, index) => {
      if (index === 0) {
        hours.push({ text: 'PM', value: -1 })
      } else if (index === 6) {
        hours.push({ text: '', value: -1 })
      }
      hours.push(hour)
    })
    return hours
  }

  getToday (timeZone) {
    return this.getTodayTimeDate(timeZone).startOf('day')
  }

  getTodayTimeDate (timeZone) {
    if (!timeZone) {
      const { selectedTimeZoneIndex } = this.state
      timeZone = this.getTimeZone(selectedTimeZoneIndex)
    }
    return moment().tz(timeZone)
  }

  checkHourLeftToday (date, hours) {
    const fourHoursLater = moment(date.clone()).add(4, 'hours')
    // get last hour selectable
    const lastHour = Math.max.apply(Math, hours.map(obj => { return obj.value }))
    if (fourHoursLater.hours() >= lastHour || fourHoursLater.date() !== date.date()) {
      date = moment(date).add(1, 'days')
    } else if (date.isBefore(fourHoursLater)) {
      date = fourHoursLater.clone()
    }
    return date
  }

  selectDate (date) {
    const { onlyDate } = this.props
    const {
      selectedDateTime
    } = this.state

    if (onlyDate) {
      const newDateTime = date.clone()
      this.props.setAppointmentDate(newDateTime.format('YYYY-MM-DD'))
    } else {
      if (date.isSameOrAfter(this.getToday())) {
        const newDateTime = date.clone()
        if (this.isSelectable(newDateTime, selectedDateTime.hours())) {
          newDateTime.hour(selectedDateTime.hours())
          this.props.setAppointmentDate(newDateTime.toDate())
        } else {
          this.props.setAppointmentDate(null)
        }
        this.setState({ selectedDateTime: newDateTime })
      } else {
        this.props.setAppointmentDate(null)
      }
    }
  }

  selectTime (hour) {
    const {
      selectedDateTime
    } = this.state
    if (this.isSelectable(selectedDateTime, hour)) {
      const newDateTime = selectedDateTime.clone()
      newDateTime.hour(hour)
      this.setState({ selectedDateTime: newDateTime })
      this.props.setAppointmentDate(newDateTime.toDate())
    }
  }

  changeDisplayedDates (offset, type = 'day') {
    const { firstDisplayDate, oldestDisplayDate } = this.state
    let newFirstDate = firstDisplayDate.clone()
    newFirstDate.add(offset, type)
    if (newFirstDate.isBefore(oldestDisplayDate)) {
      newFirstDate = oldestDisplayDate.clone()
    }
    this.setState({ firstDisplayDate: newFirstDate })
  }

  getDateTimeWithTimeZone (dateTime, timeZone) {
    const newDateTime = dateTime.clone().tz(timeZone)
    const offset = dateTime.utcOffset() - newDateTime.utcOffset()
    newDateTime.add(offset, 'minutes')
    return newDateTime
  }

  setTimeZone (newTimeZoneIndex) {
    const {
      selectedDateTime,
      firstDisplayDate,
      oldestDisplayDate
    } = this.state
    const newTimeZone = this.getTimeZone(newTimeZoneIndex)
    const hours = this.getHours(newTimeZone)
    let newSelectedDateTime = this.getDateTimeWithTimeZone(selectedDateTime, newTimeZone)
    const now = moment()

    if (newSelectedDateTime.isAfter(now)) {
      const timeDateNow = this.getTodayTimeDate(newTimeZone)
      const timeLeftToday = this.checkHourLeftToday(timeDateNow.clone(), hours)
      const isStillSameDay = timeLeftToday.date() === selectedDateTime.date()
      const selectedHourStillSelectable = this.isSelectable(selectedDateTime, selectedDateTime.hours())
      if (isStillSameDay && selectedHourStillSelectable) {
        this.props.setAppointmentDate(newSelectedDateTime.toDate())
      } else {
        this.props.setAppointmentDate(null)
      }
    } else {
      newSelectedDateTime = this.getToday(newTimeZone)
      newSelectedDateTime = this.checkHourLeftToday(newSelectedDateTime, hours)
      this.props.setAppointmentDate(null)
    }
    this.setState({
      firstDisplayDate: this.getDateTimeWithTimeZone(firstDisplayDate, newTimeZone),
      hours,
      oldestDisplayDate: this.getDateTimeWithTimeZone(oldestDisplayDate, newTimeZone),
      selectedDateTime: newSelectedDateTime,
      selectedTimeZoneIndex: newTimeZoneIndex
    })
  }

  onTimeZoneChange (e) {
    this.setTimeZone(e.target.value)
  }

  subClass (selectable, selected) {
    return selectable
      ? selected
        ? '_selected'
        : '_unselected'
      : '_unselectable'
  }

  isSelectable (dateTime, timeValue, timeZone = null) {
    if (!dateTime.isValid() || timeValue < 0 || timeValue > 23) {
      return false
    }
    const now = this.getTodayTimeDate(timeZone)
    const selectedDate = dateTime.clone().startOf('day')
    const dateToday = now.clone().startOf('day')
    if (selectedDate.isBefore(dateToday)) {
      return false
    }
    const dateIsToday = selectedDate.isSame(dateToday)
    if (dateIsToday) {
      const isFourHoursFromNow = moment(now).add(4, 'hours').hours() < timeValue
      if (!isFourHoursFromNow) {
        return false
      }
    }
    const prodTZNow = this.getTodayTimeDate(producerTimeZone)
    const prodTZSelectedDate = selectedDate.clone().tz(producerTimeZone).startOf('day')
    selectedDate.add(timeValue, 'hours')
    const prodTZSelectedHour = selectedDate.clone().tz(producerTimeZone).hours()
    const prodTZToday = prodTZNow.clone().startOf('day')
    const prodTZDateIsToday = prodTZSelectedDate.isSame(prodTZToday)
    if (prodTZDateIsToday) {
      if (prodTZSelectedHour < 9 || prodTZSelectedHour > 16) {
        return false
      }
    }
    if (prodTZNow.hours() > 16) {
      const prodTZTomorrow = prodTZToday.add(1, 'day')
      const prodTZDateIsTomorrow = prodTZSelectedDate.isSame(prodTZTomorrow)
      if (prodTZDateIsTomorrow) {
        if (prodTZSelectedHour < 9) {
          return false
        }
      }
    }
    return true
  }

  getMonth () {
    const { onlyDate } = this.props
    const { firstDisplayDate } = this.state
    const firstMonth = firstDisplayDate.format('MMM')
    const firstYear = firstDisplayDate.format('YY')
    const lastDate = firstDisplayDate.clone()
    lastDate.add(14, 'day')
    const lastMonth = lastDate.format('MMM')
    if (onlyDate) {
      return firstMonth + ' ' + firstYear
    } else {
      return firstMonth === lastMonth ? firstMonth : firstMonth + ' - ' + lastMonth
    }
  }

  renderDaysRow (dayOffset) {
    const { classes, onlyDate } = this.props
    const { selectedDateTime, firstDisplayDate, hours } = this.state
    const selectedDate = selectedDateTime.clone().startOf('day')
    const displayDate = onlyDate
      ? firstDisplayDate.clone().startOf('month').startOf('week')
      : firstDisplayDate.clone()
    displayDate.add(dayOffset - 1, 'day')
    return (
      <div className={classes.pickerRow}>
        {weekdays.map((day, idx) => {
          displayDate.add(1, 'day')
          const today = this.getTodayTimeDate()
          const firstSelectableDate = this.checkHourLeftToday(today, hours)
          const selectable =
            onlyDate
              ? displayDate.month() === firstDisplayDate.month()
              : displayDate.day() !== 0 &&
                displayDate.day() !== 6 &&
                displayDate.isAfter(firstSelectableDate.subtract(1, 'days'))
          const selected = selectedDate.isSame(displayDate)
          return (
            <React.Fragment key={idx}>
              <div
                className={classNames(classes.pickerCell, this.subClass(selectable, selected))}
                onClick={selectable ? this.selectDate.bind(this, displayDate.clone()) : null}>
                {displayDate.date()}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  renderHoursRow (firstIndex) {
    const { classes } = this.props
    const { selectedDateTime, hours } = this.state
    const renderHours = hours.slice(firstIndex, firstIndex + 7)
    return (
      <div className={classes.pickerRow}>
        {renderHours.map((time, idx) => {
          const selected = selectedDateTime.hour() === time.value
          const selectable = this.isSelectable(selectedDateTime, time.value)
          return (
            <React.Fragment key={idx}>
              <div
                className={classNames(classes.pickerCell, this.subClass(selectable, selected))}
                onClick={selectable ? this.selectTime.bind(this, time.value) : null}>
                {time.text}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  render () {
    const { classes, onlyDate } = this.props
    const {
      selectedDateTime,
      firstDisplayDate,
      oldestDisplayDate,
      timeZoneList,
      selectedTimeZoneIndex
    } = this.state
    const month = this.getMonth()
    const backEnabled = firstDisplayDate.isAfter(oldestDisplayDate)
    return (
      <div className={classes.root}>
        <div className={classes.sectionHeader}>
          <div className={classes.sectionHeaderLabel}>
            Pick a day
          </div>
          <div className={classes.arrowContainer}>
            <ArrowLeft
              className={classNames(classes.arrowIcon, { _enabled: backEnabled })}
              onClick={
                backEnabled
                  ? this.changeDisplayedDates.bind(
                    this,
                    onlyDate ? -1 : -14,
                    onlyDate ? 'month' : 'day'
                  )
                  : null
              } />
            <span className={classes.month}>{month}</span>
            <ArrowRight
              className={classNames(classes.arrowIcon, '_enabled')}
              onClick={this.changeDisplayedDates.bind(
                this,
                onlyDate ? 1 : 14,
                onlyDate ? 'month' : 'day'
              )} />
          </div>
        </div>
        <div className={classes.pickerContainer}>
          <div className={classes.pickerRow}>
            {weekdays.map((day, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className={classNames(classes.pickerCell, '_header')}>
                    {day}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
          {this.renderDaysRow(0)}
          {this.renderDaysRow(7)}
          {onlyDate && this.renderDaysRow(14)}
          {onlyDate && this.renderDaysRow(21)}
          {onlyDate && this.renderDaysRow(28)}
        </div>
        {!onlyDate && <><div className={classes.sectionHeader}>
          <div className={classes.sectionHeaderLabel}>
            Pick a time
          </div>
          <div className={classes.timeZoneContainer}>
            <FormControl className={classes.timeZoneForm}>
              <Select
                native
                value={selectedTimeZoneIndex}
                onChange={this.onTimeZoneChange.bind(this)}
                className={classes.timeZoneSelector}
              >
                {timeZoneList && Object.keys(timeZoneList).map(key => {
                  const timeZone = timeZoneList[key]
                  const convertedDate = this.getDateTimeWithTimeZone(selectedDateTime, timeZone)
                  const offset = convertedDate.utcOffset() / 60
                  const offsetStr = '(GMT' + (offset >= 0 ? '+' : '') + offset + ') '
                  return (
                    <option key={key} value={key}>
                      {offsetStr + timeZone.replace('_', ' ')}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classNames(classes.sectionHeader, '_subHeader')}>
          <div className={classNames(classes.sectionHeaderLabel, '_subHeader')}>
            Our producers are available at:
          </div>
        </div>
        <div className={classes.pickerContainer}>
          {this.renderHoursRow(0)}
          {this.renderHoursRow(7)}
          {this.renderHoursRow(14)}
          {this.renderHoursRow(21)}
        </div>
        </>
        }
      </div>
    )
  }
}

DateAndTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  dateValid: PropTypes.func,
  defaultTimeDate: PropTypes.object,
  onlyDate: PropTypes.bool,
  setAppointmentDate: PropTypes.func.isRequired
}

export default withStyles(styles)(DateAndTimePicker)
