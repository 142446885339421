import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  ExpandIcon,
  ParallaxListIcon
} from '../../common/SVG'
import ModalVideo from './ModalVideo'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { formatPrice } from '../../../helpers'
import { PACKAGE_TYPES } from '../../../constants'

/* eslint-disable sort-keys */
const styles = {
  packageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: 400
  },
  packageBackground: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#8D70E1',
    opacity: 0.12
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: '#512CB9',
    textTransform: 'uppercase',
    marginTop: 31,
    lineHeight: '29px'
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 300,
    color: '#512CB9',
    lineHeight: '17px',
    marginBottom: 30
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 360
  },
  bulletPoint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 5
  },
  listIcon: {
    width: 16,
    height: 16,
    marginTop: 6,
    marginRight: 13
  },
  packageText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#323232',
    lineHeight: '26px'
  },
  skuTextContainer: {
    width: '100%'
  },
  skuDescriptionText: {
    padding: 0,
    margin: 0,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 400,
    color: '#323232',
    lineHeight: '26px'
  },
  skuExtraInfo: {
    padding: 0,
    margin: 0,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 400

  },
  skuNoDiscount: {
    padding: '5px 0px',
    margin: 0,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 800
  },
  panelWrapper: {
    width: '100%',
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    }
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 50,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      height: 0
    },
    '& .MuiCollapse-container': {
      width: '100%'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
  },
  expandRow: {
    color: '#01b7d7',
    position: 'absolute',
    bottom: 0,
    '& span': {
      fontSize: 16,
      fontWeight: 500,
      position: 'relative'
    }
  },
  expandIcon: {
    width: 15,
    height: 15,
    position: 'relative',
    top: 2
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0
  },
  skusContainer: {
    width: '100%',
    position: 'relative'
  },
  skuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '15px 0',
    borderTop: '1px solid #dfdfdf',
    '&:nth-child(2)': {
      marginTop: 50
    },
    '&:last-child': {
      borderBottom: '1px solid #dfdfdf'
    }
  },
  discountWrapper: {
    position: 'absolute',
    top: 20,
    width: '100%',
    textAlign: 'center'
  },
  skuInfo: {
    width: 360,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  skuPrice: {
    width: 160,
    fontSize: 40,
    fontWeight: 400,
    color: '#323232',
    lineHeight: '49px',
    marginRight: 30
  }
}
/* eslint-enable sort-keys */

class Package extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bulletSectionHeight: 0,
      expanded: false
    }
    this.bulletRef = React.createRef()
    this.onResize = () => {
      const { pack, onBulletSectionHeightChange } = this.props
      const bulletElement = this.bulletRef.current
      if (bulletElement) {
        const bulletSectionHeight = bulletElement.getBoundingClientRect().height
        this.setState({ bulletSectionHeight }, () => {
          onBulletSectionHeightChange(pack.id, bulletSectionHeight)
        })
      }
    }
  }

  splitText (text) {
    if (text && typeof text === 'string') {
      return text.split('|')
    }
    return ['']
  }

  componentDidMount () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  toggleExpanded () {
    this.setState({ expanded: !this.state.expanded })
  }

  render () {
    const {
      classes,
      pack,
      bulletSectionMinHeight
    } = this.props
    const {
      expanded,
      bulletSectionHeight
    } = this.state
    const alwaysBullets = pack.jsonBulletList.slice(0, 5)
    const expandedBullets = pack.jsonBulletList.slice(5)
    const expansionPanelMinHeight = 50 + bulletSectionMinHeight - bulletSectionHeight
    return (
      <div className={classes.packageContainer}>
        <div className={classes.packageBackground} />
        <ModalVideo thumbnail={pack.thumbnailUrl} video={pack.videoUrl} />
        <div className={classes.title}>{pack.title}</div>
        <div className={classes.subtitle}>{pack.subtitle}</div>
        <div className={classes.bulletContainer} ref={this.bulletRef}>
          {alwaysBullets.map((bullet, index) => (
            <div key={index} className={classes.bulletPoint}>
              <ParallaxListIcon className={classes.listIcon} />
              <div className={classes.packageText}>{bullet}</div>
            </div>
          ))}
        </div>
        <div className={classes.panelWrapper}>
          <Accordion
            expanded={expanded}
            classes={{
              expanded: classes.panelExpanded,
              root: classes.panel
            }}
            style={{ minHeight: expansionPanelMinHeight }}
          >
            <AccordionSummary
              expandIcon={<ExpandIcon className={classes.expandIcon} />}
              aria-controls={'panel-content'}
              id={'panel-header'}
              classes={{
                expanded: classes.expandRowExpanded,
                root: classes.expandRow
              }}
              onClick={this.toggleExpanded.bind(this)}
            >
              <span>{expanded ? 'See less' : 'See more'}</span>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.bulletContainer}>
                {expandedBullets.map((bullet, index) => (
                  <div key={index} className={classes.bulletPoint}>
                    <ParallaxListIcon className={classes.listIcon} />
                    <div className={classes.packageText}>{bullet}</div>
                  </div>
                ))}
              </div>
              <div className={classes.skusContainer}>
                <div className={classes.discountWrapper}>
                  <div className={classes.packageText}>20% discount applied</div>
                </div>
                {pack.skus.map((sku, index) => {
                  const noDiscount = pack.type === PACKAGE_TYPES.RECAP && index === 0
                  let price = Number(sku.price.replace('$', ''))
                  const [description, extraInfo] = this.splitText(sku.description)
                  if (!isNaN(price)) {
                    if (noDiscount) {
                      price = formatPrice(Math.round(price * 100))
                    } else {
                      price = formatPrice(Math.round(price * 80))
                    }
                  } else {
                    price = sku.price
                  }
                  return (
                    <div key={index} className={classes.skuWrapper}>
                      <div className={classes.skuInfo}>
                        <div className={classes.skuPrice}>{price}</div>
                        <div className={classes.skuTextContainer}>
                          {description && <p className={classes.skuDescriptionText}>{description}</p>}
                          {extraInfo && <p className={classes.skuExtraInfo}>{extraInfo}</p>}
                          {noDiscount && <p className={classes.skuNoDiscount}>
                            Not eligible for discount
                          </p>}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    )
  }
}

Package.propTypes = {
  bulletSectionMinHeight: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  onBulletSectionHeightChange: PropTypes.func.isRequired,
  pack: PropTypes.object.isRequired
}

export default withStyles(styles)(Package)
