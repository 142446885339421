import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { toLocale } from '../../../helpers'
import classNames from 'classnames'
import {
  ChatBubblePortion
} from '../SVG/index'

/* eslint-disable sort-keys */
const styles = {
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#323232',
    marginBottom: 10,
    marginRight: '15%',
    marginLeft: 0,
    '&._rightSide': {
      alignItems: 'flex-end',
      marginRight: 0,
      marginLeft: '15%'
    }
  },
  sender: {
    fontSize: 11,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    marginBottom: 6
  },
  messageBubbleWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative'
  },
  messageBubble: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 7px rgba(0, 0, 0, 0.07)',
    borderRadius: '0 2px 2px 2px',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    '&._rightSide': {
      backgroundColor: '#9455c8',
      color: '#ffffff',
      borderRadius: '2px 0 2px 2px',
      '&._editor': {
        backgroundColor: '#666666'
      }
    }
  },
  bubblePortion: {
    width: 11,
    height: 27,
    color: '#ffffff',
    transform: 'scale(-1, 1)',
    marginRight: -1,
    zIndex: 1,
    '&._rightSide': {
      color: '#9455c8',
      transform: 'scale(1)',
      marginLeft: -1,
      marginRight: 0,
      '&._editor': {
        color: '#666666'
      }
    }
  },
  message: {
    fontSize: 14,
    lineHeight: '20px'
  },
  timestamp: {
    fontSize: 12,
    lineHeight: '20px',
    opacity: 0.5,
    marginTop: 3
  }
}
/* eslint-enable sort-keys */

class ProjectChatMessage extends React.Component {
  formatTimestamp (timestamp) {
    const date = new Date(timestamp)
    const now = Date.now()
    let diff = Math.floor((now - date.getTime()) / 1000)

    if (diff < 60) {
      return 'Just now'
    }

    diff = Math.floor(diff / 60)
    if (diff === 1) {
      return diff + ' minute ago'
    } else if (diff < 60) {
      return diff + ' minutes ago'
    }

    diff = Math.floor(diff / 60)
    if (diff === 1) {
      return diff + ' hour ago'
    } else if (diff < 24) {
      return diff + ' hours ago'
    }

    diff = Math.floor(diff / 24)
    if (diff === 1) {
      return diff + ' day ago'
    } else if (diff < 24) {
      return diff + ' days ago'
    }

    return toLocale(date, { full: true })
  }

  render () {
    const { classes, message, messageUserId, messageUser = {}, currentUserId, thread } = this.props
    const isYourMessage = messageUserId === currentUserId
    const messageParts = message.message.split('\n')

    return (
      <div className={classNames(classes.messageContainer, { _rightSide: isYourMessage })}>
        <div className={classes.sender}>
          {isYourMessage ? 'You' : messageUser.fullname}
          {!isYourMessage ? `, URLIFE ${messageUser.role}` : ''}
        </div>
        <div className={classes.messageBubbleWrapper}>
          {!isYourMessage && <ChatBubblePortion className={classes.bubblePortion} />}
          <div className={classNames(
            classes.messageBubble,
            { _rightSide: isYourMessage },
            { _editor: thread === 'editor' }
          )}>
            {messageParts.map((msg, index) => {
              return (<React.Fragment key={index}>
                {msg && <div className={classes.message}>{msg}</div>}
                {!msg && <br/>}
              </React.Fragment>)
            })}
            <div className={classes.timestamp}>
              {this.formatTimestamp(message.created_at)}
            </div>
          </div>
          {isYourMessage &&
            <ChatBubblePortion className={classNames(
              classes.bubblePortion,
              '_rightSide',
              { _editor: thread === 'editor' }
            )} />
          }
        </div>
      </div>
    )
  }
}

ProjectChatMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  messageUser: PropTypes.object,
  messageUserId: PropTypes.string.isRequired,
  thread: PropTypes.oneOf(['customer', 'editor']).isRequired
}

export default withStyles(styles)(ProjectChatMessage)
