import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function PencilIconLarge ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox="0 0 124 127">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-370.000000, -1489.000000)" stroke={color || '#4A30B5'} strokeWidth="3">
          <g transform="translate(361.000000, 868.000000)">
            <g transform="translate(11.000000, 623.000000)">
              <line x1="49.5" y1="103.5" x2="19" y2="73" id="Path"></line>
              <line x1="34" y1="87" x2="86.5" y2="33.5" id="Path"></line>
              <path d="M19.2734827,71.2918217 L7.46126665,83.5405863 L0.242690172,118.962689 C0.242690172,118.962689 -1.39789539,123.597357 3.85197841,122.935262 L36.0074554,116.314308 L95.7247699,54.0773416 L99.3340582,50.435817 C99.3340582,50.435817 101.630878,48.1184831 97.6934726,44.8080062 C93.7560673,41.828577 70.4597523,17 70.4597523,17 L19.2734827,71.2918217 Z" id="Path"></path>
              <path d="M71.5759277,15.7756348 L86.7018463,0.294503423 C86.7018463,0.294503423 88.6939584,-0.695355305 90.0220332,0.954409242 C91.0180892,2.27422088 117.579584,27.6805949 117.579584,27.6805949 C117.579584,27.6805949 120.567752,30.3202182 119.903715,30.980124 C114.259397,36.5893235 110.450864,40 110.450864,40" id="Path" strokeLinecap="round"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

PencilIconLarge.propTypes = {
  color: PropTypes.string
}
