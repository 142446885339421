import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import {
  CheckMark,
  CloseIcon
} from '../common/SVG'

/* eslint-disable sort-keys */
const styles = {
  cardElementWrapper: {
    height: 'auto',
    color: '#3a3a3a',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    padding: '0px 0px 6px 0px',
    margin: '10px 0px 20px 0px',
    width: 'auto',
    borderBottom: '1px solid #666666',
    ...commonStyles.media(480, {
      fontSize: 16
    }),
    '&._error': {
      borderBottom: '2px solid #d70101',
      marginBottom: 19
    },
    '&._focus': {
      borderBottom: '2px solid #666666',
      marginBottom: 19
    },
    '&:hover': {
      borderBottom: '2px solid #666666',
      marginBottom: 19
    }
  },
  label: {
    color: '#666666',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    ...commonStyles.media(480, {
      fontSize: 14
    })
  },
  icon: {
    width: 16,
    height: 16,
    ...commonStyles.media(480, {
      width: 14,
      height: 14
    })
  },
  errorIcon: {
    color: '#d70101'
  },
  successIcon: {
    color: '#01b7d7'
  }
}
/* eslint-enable sort-keys */

class ElementWrapper extends React.Component {
  render () {
    const { classes, children, complete, error, focus, label } = this.props
    return (
      <label className={classNames(classes.label, this.props.className)}>
        {label}
        <div className={classNames(
          classes.cardElementWrapper,
          {
            _error: error,
            _focus: focus
          }
        )}>
          {children}
          {complete && <CheckMark
            data-testid='ew-complete-icon'
            className={classNames(classes.icon, classes.successIcon)}
          />}
          {error && <CloseIcon
            data-testid='ew-error-icon'
            className={classNames(classes.icon, classes.errorIcon)}
          />}
        </div>
      </label>
    )
  }
}

ElementWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  complete: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  focus: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
}

export default withStyles(styles)(ElementWrapper)
