import ApiService from '../services/ApiService'
import { api } from '../constants'
import { projectInfo } from './project'

export {
  checkForNewUserNotifications,
  getUserNotifications,
  updateUserNotification
}

function getUserNotifications (userId, params = {}, add = false) {
  return dispatch => {
    return new ApiService(api.USER_NOTIFICATIONS,
      { userId }, params, true, dispatch)
      .get().then(
        data => {
          if (add) {
            return dispatch({ items: data.data, type: 'ADD_NOTIFICATION_ITEMS' })
          } else {
            return dispatch({ items: data.data, type: 'SET_NOTIFICATION_ITEMS' })
          }
        }
      ).catch(e => { /* Ignore this error */ })
  }
}

function checkForNewUserNotifications (userId) {
  return async (dispatch, getState) => {
    const notifications = getState().notification.items
    let cursor = notifications.length > 0 ? notifications[0].id : null
    const newNotifications = []
    let done = false
    while (!done) {
      let nextCursor = null
      try {
        const data = await new ApiService(api.USER_NOTIFICATIONS,
          { userId }, { count: 1, cursor, method: 'ASC' }, true, dispatch)
          .get()
        if (data.data && data.data.length > 0) {
          const notification = data.data[0]
          if (!notifications.find(n => n.id === notification.id)) {
            newNotifications.push(notification)
            nextCursor = notification.id
            const type = notification.notification.type
            if ((type === 'MOVIE' || type === 'PAYMENT' || 'MOVIE_APPROVED') && notification.notification.projectId) {
              dispatch(projectInfo(notification.notification.projectId))
            }
          }
        }
      } catch (e) {
        /* Ignore this error */
      }
      cursor = nextCursor
      done = !nextCursor
    }
    if (newNotifications.length > 0) {
      dispatch({ items: newNotifications, type: 'ADD_NOTIFICATION_ITEMS' })
    }
  }
}

function updateUserNotification (userId, notificationId, notification) {
  return dispatch => {
    return new ApiService(api.ONE_USER_NOTIFICATION,
      { notificationId, userId }, {}, true, dispatch)
      .put(notification)
  }
}
