import ApiService from '../services/ApiService'
import { api } from './../constants'

export { getFirstTimeUsers }
export { getNewUsers }
export { getPrePurchaseUsers }
export { getRepeatingUsers }
export { getReturningUsers }
export { getYearStatistics }

function getFirstTimeUsers () {
  return dispatch => {
    return new ApiService(api.REPORTS_FIRST_TIME_USERS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}

function getNewUsers () {
  return dispatch => {
    return new ApiService(api.REPORTS_NEW_USERS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}

function getPrePurchaseUsers () {
  return dispatch => {
    return new ApiService(api.REPORTS_PRE_PURCHASE_USERS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}

function getRepeatingUsers () {
  return dispatch => {
    return new ApiService(api.REPORTS_REPEATING_USERS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}

function getReturningUsers () {
  return dispatch => {
    return new ApiService(api.REPORTS_RETURNING_USERS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}

function getYearStatistics () {
  return dispatch => {
    return new ApiService(api.REPORTS_YEAR_STATISTICS, {}, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        }
      )
  }
}
