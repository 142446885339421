import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'

/* eslint-disable sort-keys */
const styles = {
  editTextFieldControl: {
    width: '100%',
    position: 'relative',
    marginBottom: 10
  },
  editTextField: {
    width: '100%'
  },
  editTextFieldInput: {
    fontSize: 14
  },
  editTextFieldError: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '100%',
    marginTop: 4
  }
}
/* eslint-enable sort-keys */

class EditTextField extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      localValue: props.value || ''
    }
  }

  checkValid () {
    const { localValue } = this.state
    const { required } = this.props
    return required
      ? localValue && localValue.toString().trim().length > 0
      : true
  }

  onChange (value) {
    this.setState({
      localValue: value
    })
  }

  apply (value) {
    const { onApply } = this.props
    if (this.checkValid()) {
      onApply(value)
    }
  }

  render () {
    const { classes, placeholder, rows } = this.props
    const { localValue } = this.state
    const isValid = this.checkValid()

    return (
      <FormControl className={classes.editTextFieldControl} error={!isValid}>
        <TextField
          rows={rows}
          multiline
          error={!isValid}
          value={localValue}
          className={classes.editTextField}
          InputProps={{
            classes: {
              input: classes.editTextFieldInput
            }
          }}
          placeholder={placeholder}
          onChange={ e => { this.onChange(e.currentTarget.value) }}
          onBlur={ e => { this.apply(e.currentTarget.value) }}
        />
        {!isValid &&
        <FormHelperText className={classes.editTextFieldError}>Required</FormHelperText>
        }
      </FormControl>
    )
  }
}

EditTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

EditTextField.defaultProps = {
  required: true
}

export default withStyles(styles)(EditTextField)
