import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import LineTitle from './LineTitle'
import ModalVideo from './ModalVideo'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 120
  },
  title: {
    padding: '0px 40px',
    marginBottom: 40,
    width: '90vw',
    position: 'relative',
    ...commonStyles.media(991, {
      padding: '0px 40px'
    }),
    ...commonStyles.media(767, {
      padding: '0px 20px'
    }),
    ...commonStyles.media(575, {
      padding: '0px 10px'
    })
  },
  videoContainer: {
    maxWidth: 850
  },
  videoSubtitle: {
    position: 'relative',
    zIndex: 2,
    marginTop: -5,
    padding: '10px 0px',
    fontSize: 22,
    fontWeight: 300,
    letterSpacing: -0.44,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#55565a',
    ...commonStyles.media(991, {
      fontSize: 18
    }),
    ...commonStyles.media(767, {
      fontSize: 13
    }),
    ...commonStyles.media(575, {
      fontSize: 10
    }),
    ...commonStyles.media(450, {
      fontSize: 8
    })
  }
})
/* eslint-enable sort-keys */

const TitledVideo = ({
  color,
  extraClass,
  thumbnail,
  title,
  video,
  videoSubtitle
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <LineTitle
        extraClass={extraClass}
        className={classes.title}
        color={color}
        text={title}
        multiLine={true}
      />
      <div className={classes.videoContainer}>
        <ModalVideo
          thumbnail={thumbnail}
          video={video}
        />
        {videoSubtitle && <div className={classes.videoSubtitle} >
          {videoSubtitle}
        </div>}
      </div>
    </div>
  )
}

TitledVideo.propTypes = {
  color: PropTypes.string,
  extraClass: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  videoSubtitle: PropTypes.string
}

export default TitledVideo
