import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  deleteProjectNotes,
  getProjectNotes,
  updateProjectNotes
}

function getProjectNotes (projectId) {
  return dispatch => {
    return new ApiService(api.PROJECT_NOTES, { projectId }, {}, true, dispatch)
      .get()
  }
}

function updateProjectNotes (projectId, notes) {
  return dispatch => {
    return new ApiService(api.PROJECT_NOTES, { projectId }, {}, true, dispatch)
      .put({ notes })
  }
}

function deleteProjectNotes (projectId) {
  return dispatch => {
    return new ApiService(api.PROJECT_NOTES, { projectId }, {}, true, dispatch)
      .delete()
  }
}
