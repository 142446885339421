import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function ProducerChairV2 ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 101 113'>
      <g>
        <g>
          <path d="M64,35.51245c0.08081,0.48755 -0.06775,0.95569 -0.42395,1.27429l-5.31946,5.21326l1.26672,7.40515c0.09412,0.48785 -0.10388,0.9856 -0.52332,1.27429l-0.74341,0.25391h-0.63342l-6.62317,-3.56903l-6.67786,3.43951c-0.44043,0.27875 -1.00452,0.25885 -1.42517,-0.04974c-0.41565,-0.30865 -0.60364,-0.84625 -0.47498,-1.349l1.26672,-7.40509l-5.31946,-5.21332c-0.36926,-0.34851 -0.46008,-0.86121 -0.29687,-1.3241c0.16333,-0.46259 0.5741,-0.7865 1.05896,-0.84125l7.47192,-1.14484l3.29565,-6.75476c0.20776,-0.44299 0.6532,-0.72174 1.10107,-0.72174c0.52197,0 1,0.27875 1.17517,0.72174l3.29565,6.75476l7.4718,1.14484c0.47998,0.07465 0.87585,0.37866 1.05737,0.89111zM60.15674,36.66217l-5.57178,-0.76654c-0.42554,-0.02985 -0.80164,-0.2688 -1.0144,-0.63715l-2.57056,-5.09711l-2.49646,5.09711c-0.1781,0.41815 -0.56409,0.74152 -1.0144,0.74152l-5.57178,0.78662l4.08264,3.95233c0.28174,0.26105 0.42529,0.72174 0.35596,1.14484l-0.88574,5.6048l4.93835,-2.67804h1.13818l4.93835,2.67804l-0.88574,-5.6048c-0.07422,-0.4231 0.06921,-0.88379 0.40063,-1.14484zM99.75464,62.49188c0.68591,0 1.24536,0.57288 1.24536,1.27533c0,0.7074 -0.55945,1.23279 -1.24536,1.23279h-11.45642l-35.37097,22l38.35864,23.44604c0.54004,0.26398 0.82214,0.88177 0.71411,1.47455c-0.19849,0.5929 -0.73853,0.99139 -1.33691,0.94659h-0.66309l-39.43652,-24.48535l-39.47693,24.61816h-0.62268c-0.59839,0 -1.13843,-0.36145 -1.28918,-0.94928c-0.17468,-0.5929 0.12646,-1.21564 0.6665,-1.47968l38.35852,-23.57104l-35.49792,-22h-11.45642c-0.68591,0 -1.24536,-0.52539 -1.24536,-1.23279c0,-0.70245 0.55945,-1.27533 1.24536,-1.27533h10.58569v-61.21655c0,-0.70239 0.55945,-1.27533 1.24536,-1.27533c0.6908,0 1.24536,0.57294 1.24536,1.27533v9.18146h72.48413v-9.18146c0,-0.70239 0.55457,-1.27533 1.24536,-1.27533c0.68591,0 1.24536,0.57294 1.24536,1.27533v61.21655zM14.32178,62.49188h72.48413v-49.49188h-72.48413zM83.56677,65h-66.00464l33.00232,20.44806z" fill={color} fillOpacity="1"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

ProducerChairV2.propTypes = {
  color: PropTypes.string
}
