import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function NoProjectsIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 56 47">
      <defs><clipPath id="92lwa"><path d="M0 11.87h43.931v34.745H0z" /></clipPath><clipPath id="92lwb"><path d="M11.78.47h43.914v34.745H11.78z" /></clipPath></defs><g><g><g><g /><g clipPath="url(#92lwa)"><path fill={props.fill || '#333'} d="M43.914 40.79c.022.133.024.268 0 .399v1.005a4.426 4.426 0 0 1-4.42 4.421H4.437a4.426 4.426 0 0 1-4.42-4.42v-1.006a1.166 1.166 0 0 1 0-.398v-24.5a4.426 4.426 0 0 1 4.42-4.421h35.055a4.426 4.426 0 0 1 4.421 4.42zm-2.317-24.5c0-1.154-.94-2.093-2.094-2.093H4.438c-1.154 0-2.094.94-2.094 2.094v25.904c0 1.154.94 2.094 2.094 2.094h35.065c1.155 0 2.094-.94 2.094-2.094z" /></g></g><g><path fill={props.fill || '#333'} d="M45.376 40.915a4.427 4.427 0 0 0 4.421-4.42v-1.006a1.168 1.168 0 0 0 0-.399v-24.5a4.426 4.426 0 0 0-4.42-4.42H10.32A4.426 4.426 0 0 0 5.9 10.59h2.327c0-1.154.94-2.093 2.094-2.093h35.055c1.154 0 2.094.94 2.094 2.094V36.495c0 1.154-.94 2.094-2.094 2.094z" /></g><g><g /><g clipPath="url(#92lwb)"><path fill={props.fill || '#333'} d="M51.256 35.215a4.426 4.426 0 0 0 4.421-4.42v-1.006c.024-.131.022-.266 0-.398V4.89A4.426 4.426 0 0 0 51.257.47H16.2a4.426 4.426 0 0 0-4.421 4.42h2.327c0-1.154.94-2.093 2.094-2.093h35.055c1.155 0 2.094.94 2.094 2.094V30.795c0 1.154-.94 2.094-2.094 2.094z"/></g></g></g></g>
    </SvgIcon>
  )
}

NoProjectsIcon.propTypes = {
  fill: PropTypes.string
}
