import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { commonStyles } from '../../../helpers'
import TextField from '@material-ui/core/TextField'
import { FormControl } from '@material-ui/core'
import UrlifeButton from '../UrlifeButton'

/* eslint-disable sort-keys */
const styles = {
  inputBlock: {
    width: '100%',
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  inputForm: {
    flexGrow: 1
  },
  textField: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: '#333333'
  },
  sendButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 30px',
    width: 115,
    height: 40,
    marginLeft: 15,
    fontSize: 12,
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    },
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

class ChatInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      message: ''
    }
  }

  handleMessageChange (e) {
    this.setState({ message: e.target.value })
  }

  handleKeyDown (e) {
    if (e.keyCode === 13) {
      if (e.altKey) {
        this.setState({ message: this.state.message + '\n' })
      } else {
        e.preventDefault()
        this.handleSend()
      }
    }
  }

  handleSend () {
    const { onSend } = this.props
    const { message } = this.state
    onSend(message)
    this.setState({ message: '' })
  }

  render () {
    const { classes } = this.props
    const { message } = this.state
    return (
      <div className={classes.inputBlock}>
        <FormControl
          variant={'outlined'}
          className={classes.inputForm}>
          <TextField
            autoFocus
            multiline
            rowsMax={5}
            onChange={this.handleMessageChange.bind(this)}
            onKeyDown={this.handleKeyDown.bind(this)}
            className={classNames(classes.cssUnderline)}
            value={message}
            placeholder={'Write a message'}
            InputProps={{
              classes: {
                input: classes.textField
              }
            }}
          />
        </FormControl>
        <UrlifeButton
          className={classes.sendButton}
          disabled={message.length === 0}
          onClick={() => this.handleSend()}>
          Send
        </UrlifeButton>
      </div>
    )
  }
}

ChatInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired
}

export default withStyles(styles)(ChatInput)
