import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { commonStyles, history } from '../../helpers'
import { toggleModal } from '../../actions'
import classNames from 'classnames'
import Hero from './components/Hero'
import FAQList from '../faq/FAQList'
import UrlifeButton from '../common/UrlifeButton'
import {
  CelebrityIcon,
  PencilIconLarge,
  PlusCircleIconNotch,
  ProjectCode,
  UploadMovieArrowIcon
} from '../common/SVG'
import GetStarted from './components/GetStarted'
import { APP_STORE_LINK } from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  sectionContainer: {
    marginTop: 80
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    color: '#512cb9',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    '& span': {
      fontWeight: 600
    }
  },
  subHeader: {
    fontSize: 24,
    lineHeight: '33px',
    fontWeight: 600,
    color: '#512cb9',
    ...commonStyles.media(991, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  buttonContainer: {
    marginTop: 65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  actionSection: {
    marginTop: 65,
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    backgroundColor: '#002859'
  },
  actionContainer: {
    padding: '25px 0px',
    maxWidth: 939,
    width: 'auto',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& h3': {
      margin: '0px',
      fontSize: 29,
      fontWeight: 300,
      ...commonStyles.media(991, {
        fontSize: 27
      }),
      ...commonStyles.media(767, {
        fontSize: 23
      }),
      ...commonStyles.media(575, {
        fontSize: 18
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 20
      })
    },
    '& p': {
      margin: '17px 0px 5px 0px',
      fontSize: 23,
      fontWeight: 700,
      ...commonStyles.media(991, {
        fontSize: 21
      }),
      ...commonStyles.media(767, {
        fontSize: 16
      }),
      ...commonStyles.media(575, {
        fontSize: 12
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 14
      })
    },
    ...commonStyles.media(767, {
      maxWidth: '90%'
    })
  },
  faqHeader: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
    color: '#55565a',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 24,
      lineHeight: '30px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '24px'
    })
  },
  faqText: {
    marginTop: 5,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 14,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    },
    '&._marginBottom': {
      marginBottom: 30
    }
  },
  contactContainer: {
    marginTop: 47,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  getStartedTitle: {
    color: '#323232',
    fontWeight: 400
  },
  getStartedSubtitle: {
    color: '#323232',
    fontWeight: 400
  },
  getStartedItem: {
    width: '100%'
  },
  getStartedList: {
    color: '#323232',
    textAlign: 'left',
    fontSize: 21,
    fontWeight: 400,
    width: '100%'
  },
  getStartedListText: {
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    }
  },
  startHere: {
    color: '#01b7d7',
    cursor: 'pointer',
    display: 'inline'
  },
  button: {
    padding: '8px 40px'
  }
}
/* eslint-enable sort-keys */

function getSteps (classes, onClickStartHere, scrollToFAQ) {
  return [
    {
      icon: ProjectCode,
      text: <div className={classes.getStartedItem}>
              <ul className={classes.getStartedList}>
                <li>
                  <span className={classes.getStartedListText}>
                    Click <span className={classes.startHere} onClick={onClickStartHere}>Start here</span> (or
                    button below) and enter your custom project code
                  </span>
                </li>
                <li>
                  <span className={classes.getStartedListText}>
                    Enter name/email address to start your account and complete by setting a password
                    using the confirmation email you receive
                  </span>
                </li>
              </ul>
            </div>
    },
    {
      icon: PlusCircleIconNotch,
      text: <div className={classes.getStartedItem}>
              <ul className={classes.getStartedList}>
                <li>
                  <span className={classes.getStartedListText}>
                    Follow the on-screen prompts to create your project!
                    <ul>
                      <li>
                        <span className={classes.getStartedListText}>
                          For iPhones: Download
                          our <a target="_blank" rel="noopener noreferrer" href={APP_STORE_LINK}>App</a> and
                          log in
                        </span>
                      </li>
                      <li>
                        <span className={classes.getStartedListText}>
                          For Android Phones/Computers: Log in on the website
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
    },
    {
      icon: UploadMovieArrowIcon,
      text: <div className={classes.getStartedItem}>
              <ul className={classes.getStartedList}>
                <li>
                  <span className={classes.getStartedListText}>
                    Upload photos and videos - add as many as you’d like us to consider
                    - we’ll find the best to tell your story!{' '}
                    <span className={classes.startHere} onClick={() => scrollToFAQ('UploadingTips')}>
                      See Uploading Tips
                    </span>
                  </span>
                </li>
              </ul>
            </div>
    },
    {
      icon: PencilIconLarge,
      text: <div className={classes.getStartedItem}>
              <ul className={classes.getStartedList}>
                <li>
                  <span className={classes.getStartedListText}>
                    Share highlights and favorite moments
                  </span>
                </li>
                <li>
                  <span className={classes.getStartedListText}>
                    Let us know preferences for music
                  </span>
                </li>
                <li>
                  <span className={classes.getStartedListText}>
                    Star or add notes to favorite files
                  </span>
                </li>
              </ul>
            </div>
    }
  ]
}

class Celebrity extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      autoplay: false,
      scrollToId: ''
    }
  }

  componentDidMount () {
    const parsed = parse(history.location.search)
    if (parsed.autoplay && parsed.autoplay === 'true') {
      this.setState({ autoplay: true })
    }
  }

  openProjectCodeModal () {
    this.props.toggleModalFunction(true, 'projectcode')
  }

  scrollToFAQ (scrollToId) {
    this.setState({ scrollToId })
  }

  render () {
    const { classes } = this.props
    const { autoplay, scrollToId } = this.state
    const steps = getSteps(classes, this.openProjectCodeModal.bind(this), this.scrollToFAQ.bind(this))
    return (
      <div className={classes.container}>
        <Hero
          autoplay={autoplay}
          extraClass='_celebrity'
          headerBefore='WE’RE EXCITED TO MAKE A RECAP MOVIE OF YOUR'
          headerLogo={CelebrityIcon}
          logoColor='#002859'
          thumbnail='https://static.urlifemedia.com/thumbnails/celebrity-hero-thumbnail.jpg'
          textColor='#002859'
          video='https://static.urlifemedia.com/celebrity-hero-video/master.m3u8'
        />
        <div className={classes.actionSection}>
          <div className={classes.actionContainer}>
            <h3>Our creative storytellers make awesome personal movies.</h3>
            <h3>You provide the photo and videos. We do the rest.</h3>
            <p>Let’s get started.</p>
          </div>
        </div>
        <GetStarted
          steps={steps}
          title={<span className={classes.getStartedTitle}>Simple and easy steps</span>}
          subtitle={<span className={classes.getStartedSubtitle}>View App Tutorial Here</span>}
          color='#4a30b5'
          layout='vertical'
        />
        <div className={classes.buttonContainer}>
          <UrlifeButton
            className={classes.button}
            onClick={this.openProjectCodeModal.bind(this)}
          >
            Start Here
          </UrlifeButton>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.faqHeader}>FAQ</div>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            If you have any additional questions, there are lots of helpful answers below.
            But you can always contact the <b>urlife Media</b> team
            at <a href="mailto:celebritysupport@urlifemedia.com">celebritysupport@urlifemedia.com</a> and
            someone will reach out to help.
          </div>
          <FAQList questionsType='celebrity' scrollToId={scrollToId} />
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.faqText}>
            <div className={classes.faqHeader}>FOR MORE INFORMATION</div>
            {'If you have any questions, email us at '}
            <a href="mailto:celebritysupport@urlifemedia.com">celebritysupport@urlifemedia.com</a>
          </div>
        </div>
      </div>
    )
  }
}

Celebrity.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleModalFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModalFunction: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(Celebrity))
