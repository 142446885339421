import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function House ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 109 109'>
      <g>
        <g>
          <path d="M5.26599,42.24835l49.23401,-39.14514l49.23401,39.14514zM96.18573,106.27502h-83.37085v-61.27502h83.37085zM55.34821,0.29639c-0.49762,-0.3952 -1.19879,-0.3952 -1.69641,0l-53.13727,42.24799c-0.45306,0.3606 -0.62933,0.96869 -0.43842,1.51556c0.19098,0.54681 0.70721,0.94006 1.28668,0.94006h8.72711v62.63751c0,0.75244 0.61005,1.36249 1.36249,1.36249h86.09589c0.75244,0 1.36249,-0.61005 1.36249,-1.36249v-62.63751h8.72644c0.57947,0 1.0957,-0.39325 1.28668,-0.94006c0.19092,-0.54687 0.01465,-1.15497 -0.43842,-1.51556z" fill={color} fillOpacity="1"></path>
        </g>
        <g>
          <path d="M52.66602,58.76526v15.82544h-15.90076v-15.82544zM71.23474,74.5907h-15.80353v-15.82544h15.80353zM55.43121,93.23474v-15.87878h15.80353v15.87878zM36.76526,77.35596h15.90076v15.87878h-15.90076zM35.38263,96h37.23474c0.76355,0 1.38263,-0.61908 1.38263,-1.38263v-37.23474c0,-0.76355 -0.61908,-1.38263 -1.38263,-1.38263h-37.23474c-0.76355,0 -1.38263,0.61908 -1.38263,1.38263v37.23474c0,0.76355 0.61908,1.38263 1.38263,1.38263z" fill={color} fillOpacity="1"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

House.propTypes = {
  color: PropTypes.string
}
