import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import {
  fetchEmailTemplates
} from '../../../actions'
import {
  commonStyles,
  history
} from '../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  titleText: {
    margin: '10px 0px 30px 10px'
  },
  emailsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: 10,
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  emailRow: {
    cursor: 'pointer',
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  emailCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    padding: 10,
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}
/* eslint-enable sort-keys */

class EmailTemplates extends Component {
  constructor (props) {
    super(props)
    this.state = {
      emails: []
    }
  }

  async fetchEmails () {
    const { fetchEmailTemplatesFunction } = this.props
    try {
      const response = await fetchEmailTemplatesFunction()
      const emails = response.data
      emails.sort((a, b) => {
        const configNameA = a.configName.toLowerCase()
        const configNameB = b.configName.toLowerCase()
        if (configNameA < configNameB) { return -1 }
        if (configNameA > configNameB) { return 1 }
        return 0
      })
      this.setState({ emails })
    } catch (e) {}
  }

  componentDidMount () {
    this.fetchEmails()
  }

  render () {
    const { classes } = this.props
    const { emails } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h4" component="h2" className={classes.titleText}>
            <span>Email templates</span>
          </Typography>
          <div className={classes.emailsTable}>
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Description</div>
              <div className={classes.headerCol}>Subject</div>
            </div>
            {emails && emails.map(email => (
              <div
                key={email.id}
                className={classes.emailRow}
                onClick={() => history.push('/admin/emails/' + email.id)}
              >
                <div className={classes.emailCol}>
                  {email.configName}
                </div>
                <div className={classes.emailCol}>
                  {email.subject}
                </div>
              </div>
            ))}
          </div>
        </Paper>
      </div>
    )
  }
}

EmailTemplates.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchEmailTemplatesFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchEmailTemplatesFunction: function () {
      return dispatch(fetchEmailTemplates())
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EmailTemplates))
