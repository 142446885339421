import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import UrlifeInfiniteScroller from '../../UrlifeInfiniteScroller'
import Notification from './Notification'
import { getUserNotifications, updateUserNotification } from '../../../../actions'
import { history } from '../../../../helpers'
import { ROLES } from '../../../../constants'

class NotificationList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      nextCursor: props.notifications.length > 0 ? props.notifications[props.notifications.length - 1].id : null
    }
  }

  seeNotification (notification) {
    const { dispatch, userId, notifications } = this.props
    dispatch(updateUserNotification(
      userId,
      notification.id,
      { acknowledged: true }))
      .then(data => {
        const filtered = notifications.filter(note => note.id !== notification.id)
        this.props.dispatch({
          items: filtered,
          type: 'SET_NOTIFICATION_ITEMS'
        })
        if (filtered.length === 99) {
          this.getNotifications(1, filtered[98].id)
        }
        this.followNotification(notification)
      })
  }

  followNotification (notification) {
    if (notification.notification.type === 'MESSAGE') {
      let path = ''
      switch (this.props.roleId) {
        case ROLES.USER:
          path = '/chat'
          break
        case ROLES.PRODUCER:
          path = notification.notification.senderRoleId === ROLES.EDITOR ? '/editormessages' : '/customermessages'
          break
        case ROLES.EDITOR:
          path = '/editormessages'
          break
        default:
          break
      }
      history.push(`/projects/${notification.notification.projectId}${path}`)
    } else if (notification.notification.type === 'MOVIE') {
      history.push(`/projects/${notification.notification.projectId}/movie`)
    } else if (notification.notification.type === 'MOVIE_APPROVED') {
      history.push(`/projects/${notification.notification.projectId}`)
    } else if (notification.notification.type === 'COUPON') {
      history.push('/pricing')
    } else if (notification.notification.type === 'PAYMENT') {
      history.push(`/projects/${notification.notification.projectId}/summary`)
    }
    if (window.pageYOffset > 0) window.scroll(0, 0)
    this.props.closeMenu()
  }

  getNotifications (count, cursor) {
    const { dispatch, userId } = this.props
    this.setState({ isLoading: true }, async () => {
      const result = await dispatch(getUserNotifications(userId, { count, cursor }, true))
      this.setState({
        isLoading: false,
        nextCursor: result ? result.nextCursor : cursor
      })
    })
  }

  loadMore () {
    const { nextCursor } = this.state
    this.getNotifications(25, nextCursor)
  }

  render () {
    const { notifications, scrollElement } = this.props
    const { isLoading, nextCursor } = this.state
    const hasMore = notifications.length >= 100 && !!nextCursor

    return (
      <UrlifeInfiniteScroller
        loadMore={this.loadMore.bind(this)}
        hasMore={hasMore}
        isLoading={isLoading}
        isEmpty={!notifications.length}
        emptyText={'No notifications'}
        scrollElement={scrollElement}
      >
        {notifications.map((note, index) => (
          <Notification
            key={index}
            notification={note}
            onClick={this.seeNotification.bind(this, note)} />
        ))}
      </UrlifeInfiniteScroller>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notification.items,
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

NotificationList.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  path: PropTypes.string,
  roleId: PropTypes.number,
  scrollElement: PropTypes.oneOf(['window', 'parent', 'grandparent']).isRequired,
  userId: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(NotificationList)
