import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  ParallaxLayer: {
    position: 'absolute',
    width: '100vw',
    top: 0,
    right: 0,
    bottom: 0
  },
  layer1: {
    transform: 'translateZ(-200px) scale(2)'
  },
  layer2: {
    transform: 'translateZ(-175px) scale(1.5)'
  },
  layer3: {
    transform: 'translateZ(-150px) scale(1.5)'
  },
  layer4: {
    pointerEvents: 'none',
    transform: 'translateZ(30px) scale(1)'
  },
  layer5: {
    transform: 'translateZ(0px) scale(1)'
  },
  ...commonStyles.media(480, {
    layer1: {
      transform: 'none'
    },
    layer2: {
      transform: 'none'
    },
    layer3: {
      transform: 'none'
    },
    layer4: {
      transform: 'none'
    },
    layer5: {
      transform: 'none'
    }
  })
}
/* eslint-enable sort-keys */

class ParallaxLayer extends React.Component {
  render () {
    const { classes, children, layer } = this.props
    return (
      <div className={`${classes.ParallaxLayer} ${classes[layer]} ${this.props.className}`}>
        {children}
      </div>
    )
  }
}

ParallaxLayer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  layer: PropTypes.string.isRequired
}

export default withStyles(styles)(ParallaxLayer)
