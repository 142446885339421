import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PlusIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g><g><path fill="#fff" d="M8.677 15.998V9.49h6.48V7.382h-6.48V.84H6.48v6.542H0v2.11h6.481v6.506z"/></g></g>
    </SvgIcon>
  )
}
