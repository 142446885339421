import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import ModalPreview from '../../common/ModalPreview'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  container: {
    marginTop: 80,
    width: '95vw',
    maxWidth: 1150
  },
  title: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 500,
    textAlign: 'center',
    color: ({ textColor }) => textColor || '#000',
    textTransform: 'uppercase',
    '&._silversea': {
      fontWeight: 600,
      textTransform: 'none'
    },
    ...commonStyles.media(959, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    })
  },
  subtitle: {
    fontSize: 24,
    lineHeight: '33px',
    color: ({ textColor }) => textColor || '#000',
    fontWeight: 650,
    marginBottom: 60,
    textAlign: 'center',
    '&._link': {
      display: 'block',
      cursor: 'pointer'
    },
    '&._linkVideo': {
      display: 'block',
      cursor: 'pointer',
      color: '#01B7D7'
    },
    '&._silversea': {
      fontWeight: 400,
      fontSize: 22
    },
    ...commonStyles.media(959, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  grid: {
    ...commonStyles.media(959, {
      width: '70%',
      margin: 'auto'
    }),
    ...commonStyles.media(767, {
      width: 'auto',
      margin: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: '70%',
      margin: 'auto'
    })
  },
  iconWrapper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    '&._verticalLayout': {
      flexDirection: 'row'
    }
  },
  icon: {
    width: 'auto',
    height: 127,
    margin: '0 auto 20px auto',
    ...commonStyles.media(959, {
      height: 120,
      marginBottom: 10
    }),
    ...commonStyles.media(480, {
      height: 70,
      marginBottom: 14
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 70,
      marginBottom: 14
    })
  },
  iconText: {
    width: '95%',
    margin: '0px auto',
    color: ({ textColor }) => textColor || '#000',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    '&._verticalLayout': {
      width: 'calc(100% - 200px)',
      margin: 0
    },
    ...commonStyles.media(1050, {
      width: 150,
      margin: '0 auto 40px auto',
      fontWeight: 500,
      fontSize: 16,
      '&._verticalLayout': {
        width: 'calc(100% - 200px)',
        margin: 0
      }
    }),
    ...commonStyles.media(480, {
      width: 134,
      margin: '0px auto auto auto',
      fontSize: 14,
      lineHeight: '21px',
      marginBottom: 40,
      '&._verticalLayout': {
        width: 'calc(100% - 200px)',
        margin: 0
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      margin: '0px auto auto auto',
      fontSize: 14,
      lineHeight: '21px',
      marginBottom: 40,
      '&._verticalLayout': {
        width: 'calc(100% - 200px)',
        margin: 0
      }
    })
  }
})
/* eslint-enable sort-keys */

const GetStarted = ({
  color,
  extraClass,
  layout,
  steps,
  subtitle,
  subtitleLink,
  subtitleVideo,
  subtitleVideoThumbnail,
  title,
  autoplay,
  setAutoplay
}) => {
  const [modal, setModal] = useState(false)
  const classes = useStyles({ textColor: color })
  const isVertical = layout === 'vertical'
  const xs = isVertical ? 12 : 6
  const md = isVertical ? 12 : 3
  return (
    <div className={classes.container}>
      <div className={classNames(classes.title, { [extraClass]: !!extraClass })}>{title}</div>
      {!subtitleLink && !subtitleVideo && <div
        className={classNames(classes.subtitle, { [extraClass]: !!extraClass })}
      >{subtitle}</div>}
      {subtitleLink && <a
        className={classNames(classes.subtitle, '_link')}
        target='_blank'
        rel='noopener noreferrer'
        href={subtitleLink}
      >
        {subtitle}
      </a>}
      {subtitle && subtitleVideo && <div
        className={classNames(classes.subtitle, '_linkVideo')}
        onClick={() => setModal(modal => !modal)}
      >
        {subtitle}
      </div>}
      <Grid
        className={classes.grid}
        justify="center"
        container
        spacing={2}
      >
        {steps && steps.length > 0 && steps.map((step, idx) => (
          <Grid
            key={idx}
            className={classNames(classes.iconWrapper, { _verticalLayout: isVertical })}
            item xs={xs} md={md}
          >
            {step.icon && step.icon({ className: classNames(classes.icon, step.extraClasses), color })}
            {step.text && <p className={classNames(classes.iconText, { _verticalLayout: isVertical })}>{step.text}</p>}
          </Grid>
        ))}
      </Grid>
      <ModalPreview
        isOpenModalPreview={modal || autoplay}
        closeModalPreview={() => {
          setModal(false)
          if (setAutoplay) {
            setAutoplay(false)
          }
        }}
        poster={subtitleVideoThumbnail}
        video={subtitleVideo}
        videoWidth={1125}
        title={''}
      />
    </div>
  )
}

GetStarted.propTypes = {
  autoplay: PropTypes.bool,
  color: PropTypes.string,
  extraClass: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  setAutoplay: PropTypes.func,
  steps: PropTypes.array,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitleLink: PropTypes.string,
  subtitleVideo: PropTypes.string,
  subtitleVideoThumbnail: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

GetStarted.defaultProps = {
  layout: 'horizontal'
}

export default GetStarted
