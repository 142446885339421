import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import classNames from 'classnames'
import PackageList from '../common/Packages/components/PackageList'
import FAQList from '../faq/FAQList'
import RegisterOrProjectButton from '../common/RegisterOrProjectButton'
import styled from 'styled-components'
import { ParallaxPlayIcon } from '../common/SVG'
import VideoPlayerPreview from '../common/VideoPlayerPreview'
import TestimonialSlider from '../common/TestimonialSlider'
import { PACKAGE_TYPES } from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  momsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  headerContainer: {
    width: '100%'
  },
  header: {
    fontSize: 26,
    lineHeight: '48px',
    fontWeight: 600,
    color: '#5334b6',
    ...commonStyles.media(1420, {
      fontSize: 24,
      lineHeight: '44px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 22,
      lineHeight: '42px'
    }),
    ...commonStyles.media(991, {
      fontSize: 18,
      lineHeight: '34px'
    }),
    ...commonStyles.media(767, {
      fontSize: 16,
      lineHeight: '30px'
    }),
    ...commonStyles.media(575, {
      fontSize: 14,
      lineHeight: '28px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '28px'
    }),
    '&._uppercase': {
      textTransform: 'uppercase'
    },
    '&._dark': {
      color: '#323232'
    }
  },
  subHeader: {
    fontSize: 46,
    lineHeight: '54px',
    fontWeight: 400,
    color: '#323232',
    whiteSpace: 'nowrap',
    ...commonStyles.media(1420, {
      fontSize: 42,
      lineHeight: '52px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 36,
      lineHeight: '46px'
    }),
    ...commonStyles.media(991, {
      fontSize: 28,
      lineHeight: '40px'
    }),
    ...commonStyles.media(767, {
      fontSize: 26,
      lineHeight: '32px',
      whiteSpace: 'normal'
    }),
    ...commonStyles.media(575, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 22,
      lineHeight: '28px'
    })
  },
  text: {
    color: '#323232',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 16,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 14,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    }
  },
  moviesContainer: {
    width: '82vw',
    maxWidth: 1575,
    backgroundColor: '#ffffff',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 2,
    margin: '45px 0 21px',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    position: 'relative',
    ...commonStyles.media(1280, {
      width: 'calc(100% + 60px)'
    }),
    ...commonStyles.media(767, {
      width: 'calc(100% + 20px)',
      flexDirection: 'column',
      padding: 10
    }),
    ...commonStyles.media(575, {
      width: 'calc(100% + 40px)',
      padding: '0px 0px 5px 0px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 'calc(100% + 40px)',
      padding: '0px 0px 5px 0px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)'
    })
  },
  selectedMovieWrapper: {
    width: '58%',
    paddingLeft: 10,
    paddingRight: 5,
    ...commonStyles.media(767, {
      width: '100%',
      padding: 0
    })
  },
  selectedMovie: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative'
  },
  movieListMobileWrapper: {
    ...commonStyles.media(767, {
      width: '100%',
      paddingTop: 8,
      overflowX: 'auto'
    })
  },
  movieListWrapper: {
    width: '42%',
    padding: '0 5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    overflowY: 'auto',
    position: 'absolute',
    right: 0,
    top: 10,
    bottom: 10,
    ...commonStyles.media(767, {
      width: 'fit-content',
      padding: 0,
      position: 'relative',
      right: 'auto',
      top: 'auto',
      bottom: 'auto',
      flexWrap: 'nowrap',
      overflowY: 'visible'
    })
  },
  listedMovieWrapper: {
    width: '50%',
    padding: 5,
    '&:nth-child(1)': {
      paddingTop: 0
    },
    '&:nth-child(2)': {
      paddingTop: 0
    },
    '&:nth-last-child(1)': {
      paddingBottom: 0
    },
    '&:nth-last-child(2)': {
      paddingBottom: 0
    },
    ...commonStyles.media(767, {
      width: 150,
      padding: 0,
      margin: '0 2px',
      '&:first-child': {
        marginLeft: 0
      },
      '&:last-child': {
        marginRight: 0
      }
    })
  },
  listedMovieSize: {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative'
  },
  playIconWrapper: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 'calc(10% + 6px)',
    paddingTop: 'calc(10% + 6px)'
  },
  gradient: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundImage: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 75%)'
  },
  playIcon: {
    position: 'relative',
    display: 'inline-block',
    width: 'calc(100% - 6px)',
    height: 'calc(100% - 6px)',
    margin: 'auto'
  },
  movieTitleContainer: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity ease .3s',
    '&:hover': {
      opacity: 1
    }
  },
  movieTitle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 650,
    ...commonStyles.media(1280, {
      fontSize: 14
    }),
    ...commonStyles.media(991, {
      fontSize: 12
    }),
    '& div': {
      zIndex: 2,
      whiteSpace: 'nowrap'
    },
    '&:before': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      left: 7,
      top: 7,
      right: 7,
      bottom: 7,
      borderRadius: 5,
      boxShadow: '0px 0px 8px 8px rgba(0, 0, 0, 0.45)',
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      zIndex: 1
    }
  },
  movieTitleLine: {
    '&._underline': {
      borderBottom: '1px solid #ffffff'
    }
  },
  textContainer: {
    width: '85%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonWrapper: {
    marginTop: 21
  },
  testimonialsContainer: {
    width: '82vw',
    maxWidth: 1575,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 68,
    ...commonStyles.media(575, {
      width: 'calc(100% + 40px)'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 'calc(100% + 40px)'
    })
  },
  testimonialsHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 68,
    ...commonStyles.media(991, {
      marginBottom: 48
    }),
    ...commonStyles.media(767, {
      marginBottom: 20
    }),
    ...commonStyles.media(575, {
      marginBottom: 10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginBottom: 10
    })
  },
  sectionHeader: {
    fontSize: 48,
    lineHeight: '56px',
    fontWeight: 600,
    color: '#5334b6',
    textTransform: 'uppercase',
    ...commonStyles.media(1420, {
      fontSize: 42,
      lineHeight: '52px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 38,
      lineHeight: '48px'
    }),
    ...commonStyles.media(991, {
      fontSize: 28,
      lineHeight: '40px'
    }),
    ...commonStyles.media(767, {
      fontSize: 26,
      lineHeight: '32px'
    }),
    ...commonStyles.media(575, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    '&._testimonials': {
      padding: '0 40px',
      ...commonStyles.media(991, {
        padding: '0 30px'
      }),
      ...commonStyles.media(767, {
        padding: '0 20px'
      }),
      ...commonStyles.media(575, {
        padding: '0 10px'
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        padding: '0 10px'
      })
    },
    '&._offer': {
      fontSize: 42,
      lineHeight: '56px',
      ...commonStyles.media(1510, {
        fontSize: 36,
        lineHeight: '48px'
      }),
      ...commonStyles.media(1280, {
        fontSize: 28,
        lineHeight: '40px'
      }),
      ...commonStyles.media(1125, {
        fontSize: 26,
        lineHeight: '32px'
      })
    }
  },
  testimonialsLine: {
    backgroundColor: '#5334b6',
    height: 1,
    flexGrow: 1
  },
  promoContainer: {
    position: 'relative',
    width: '99vw',
    maxWidth: 1575,
    marginTop: 56,
    backgroundColor: '#ffffff',
    padding: '56px 80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...commonStyles.media(1280, {
      padding: '40px 50px'
    }),
    ...commonStyles.media(1125, {
      padding: '40px 15px'
    }),
    ...commonStyles.media(1023, {
      flexDirection: 'column',
      padding: '30px 40px'
    }),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 'calc(50% - 50vw)',
      width: '99vw',
      backgroundColor: '#ffffff'
    }
  },
  offerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 385,
    zIndex: 0,
    ...commonStyles.media(1510, {
      minWidth: 300
    }),
    ...commonStyles.media(1280, {
      minWidth: 250
    })
  },
  offerDivider: {
    width: 'calc(100% - 20px)',
    height: 1,
    backgroundColor: '#5334b6'
  },
  offerHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  offerTextBig: {
    fontSize: 80,
    lineHeight: '96px',
    fontWeight: 800,
    color: '#5334b6',
    textTransform: 'uppercase',
    ...commonStyles.media(1510, {
      fontSize: 64,
      lineHeight: '88px'
    }),
    ...commonStyles.media(1280, {
      fontSize: 54,
      lineHeight: '80px'
    }),
    ...commonStyles.media(575, {
      fontSize: 48,
      lineHeight: '72px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 48,
      lineHeight: '72px'
    })
  },
  offerTextMedium: {
    fontSize: 52,
    lineHeight: '62px',
    fontWeight: 400,
    color: '#5334b6',
    textTransform: 'uppercase',
    ...commonStyles.media(1510, {
      fontSize: 40,
      lineHeight: '44px'
    }),
    ...commonStyles.media(1280, {
      fontSize: 36,
      lineHeight: '40px'
    }),
    ...commonStyles.media(575, {
      fontSize: 32,
      lineHeight: '36px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 32,
      lineHeight: '36px'
    })
  },
  offerTextSmall: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 450,
    color: '#5334b6',
    marginTop: 39,
    marginBottom: 31,
    maxWidth: 385,
    ...commonStyles.media(1510, {
      fontSize: 20,
      lineHeight: '30px',
      marginTop: 31,
      marginBottom: 25,
      maxWidth: 300
    }),
    ...commonStyles.media(1280, {
      fontSize: 16,
      lineHeight: '26px',
      marginTop: 25,
      marginBottom: 19,
      maxWidth: 250
    })
  },
  dividerWrapper: {
    padding: '0 56px',
    zIndex: 0,
    ...commonStyles.media(1280, {
      padding: '0 35px'
    }),
    ...commonStyles.media(1125, {
      padding: '0 20px'
    }),
    ...commonStyles.media(1023, {
      padding: '40px 0',
      width: '100%'
    }),
    ...commonStyles.media(575, {
      padding: '20px 0'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      padding: '20px 0'
    })
  },
  promoDivider: {
    backgroundColor: '#5334b6',
    width: 1,
    position: 'absolute',
    top: 60,
    bottom: 60,
    ...commonStyles.media(1023, {
      width: '100%',
      height: 1,
      position: 'static',
      top: 0,
      bottom: 0
    }),
    ...commonStyles.media(575, {
      display: 'none'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'none'
    })
  },
  packagesWrapper: {
    zIndex: 0,
    flexGrow: 1
  },
  faqContainer: {
    marginTop: 60
  },
  faqText: {
    marginTop: 5,
    marginBottom: 30
  },
  contactContainer: {
    marginTop: 110
  }
}
/* eslint-enable sort-keys */

const ListedMovie = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
`
const movies = [
  {
    subtitle: 'September 2016',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/graeme-welcome-to-the-world.jpg',
    title1: 'Graeme Welcome',
    title2: 'to the World',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/graeme-welcome-to-the-world/master.m3u8'
  },
  {
    subtitle: 'August 2017',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/graeme-walks.jpg',
    title1: 'Graeme',
    title2: 'Walks',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/graeme-walks/master.m3u8'
  },
  {
    subtitle: 'September 2017',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/graemes-1st-birthday.jpg',
    title1: "Graeme's",
    title2: '1st Birthday',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/graemes-1st-birthday/master.m3u8'
  },
  {
    subtitle: 'August 2018',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/baby-announcement.jpg',
    title1: 'Baby',
    title2: 'Announcement',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/baby-announcement/master.m3u8'
  },
  {
    subtitle: 'September 2018',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/mck-welcome-to-the-world.jpg',
    title1: 'McK Welcome',
    title2: 'to the World',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/mck-welcome-to-the-world/master.m3u8'
  },
  {
    subtitle: 'October 2018',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/halloween.jpg',
    title1: 'Halloween',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/halloween/master.m3u8'
  },
  {
    subtitle: 'December 2018',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/2018-holiday-card.jpg',
    title1: '2018',
    title2: 'Holiday Card',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/2018-holiday-card/master.m3u8'
  },
  {
    subtitle: 'June 2019',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/super-dad-fathers-day.jpg',
    title1: 'Super Dad,',
    title2: "Father's Day",
    videoUrl: 'https://static.urlifemedia.com/moms/videos/super-dad-fathers-day/master.m3u8'
  },
  {
    subtitle: 'June 2019',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/graemes-1st-school-year.jpg',
    title1: "Graeme's",
    title2: '1st School Year',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/graemes-1st-school-year/master.m3u8'
  },
  {
    subtitle: '',
    thumbnail: 'https://static.urlifemedia.com/moms/thumbnails/steph-testimonial.jpg',
    title1: 'Stephanie',
    title2: 'Testimonial',
    videoUrl: 'https://static.urlifemedia.com/moms/videos/steph-testimonial/master.m3u8'
  }
]
const testimonials = [
  {
    image: 'https://static.urlifemedia.com/moms/Theo_2020-09-10.jpg',
    imagePosition: '50% 15%',
    name: 'Jessica',
    project: "Baby Theo's Year in Review",
    quote: 'I love everything about it... It made me laugh and cry. Thank you guys so much.'
  },
  {
    image: 'https://static.urlifemedia.com/moms/Hudson_2020-09-10.jpg',
    imagePosition: '50% 30%',
    name: 'Kelly',
    project: "Hudson's First Year",
    quote: "I absolutely love it - it totally captured Hudson's personality. It's perfect!"
  },
  {
    image: 'https://static.urlifemedia.com/moms/Oaxaca_2020-09-10.png',
    imagePosition: '70% 50%',
    name: 'Erin',
    project: 'Oaxaca Mexico - Family Vacation',
    quote: `These movies are treasures to our family.
        And I love knowing that my tens of thousands of images and videos won’t be forgotten.`
  }
]
const packages = [
  {
    subtitle: `
        Let us clean up the mess|
        Upload a copy of your cluttered camera roll and we’ll clean it up for you -
        delivering a show-off worthy digital collection of the best!
      `,
    type: PACKAGE_TYPES.CURATED
  },
  {
    subtitle: `
        Your family’s highlight reel|
        Perfect for showing the highlights and telling the story of a great family vacation,
        birthday party or event you don’t want to forget!
      `,
    type: PACKAGE_TYPES.RECAP
  },
  {
    subtitle: `
        The can’t-live-without luxury|
        Epic, fully-customizable movie with personal Producer concierge services and all the Hollywood magic,
        for a true family masterpiece.
      `,
    type: PACKAGE_TYPES.HOLLYWOOD
  }
]

class Moms extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      autoplay: false,
      selectedMovie: movies.length - 1
    }
  }

  render () {
    const { classes } = this.props
    const { selectedMovie, autoplay } = this.state
    const movie = movies.length > selectedMovie ? movies[selectedMovie] : null
    const movieUrl = movie ? movie.videoUrl : ''
    const videoJsOptions = {
      aspectRatio: '16:9',
      autoplay: autoplay,
      controls: true,
      fluid: true,
      poster: movie ? movie.thumbnail : null,
      preload: 'none'
    }
    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 6000,
      dots: false,
      focusOnSelect: true,
      infinite: true,
      pauseOnHover: false,
      slidesToShow: 1,
      speed: 1500,
      swipeToSlide: true
    }
    const christmas = false
    return (
      <div className={classes.momsContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.subHeader}>
            {christmas
              ? 'Your Christmas Memories + Our Movie Making Magic'
              : 'Your Family Memories + Our Movie Making Magic'
            }
          </div>
          <div className={classNames(classes.header, '_dark')}>
            {christmas
              ? 'The modern heirloom. Your 2020 Holiday. Saved forever.'
              : 'The modern heirloom. Your upgraded photobook. Saved forever.'
            }
          </div>
        </div>
        <div className={classes.moviesContainer}>
          <div className={classes.selectedMovieWrapper}>
            <div className={classes.selectedMovie}>
              {movie && <VideoPlayerPreview
                {...videoJsOptions}
                video={movieUrl}
                sources={[{ src: movieUrl }]}
                wrapperWidth={'100%'}
              />}
            </div>
          </div>
          <div className={classes.movieListMobileWrapper}>
            <div className={classes.movieListWrapper}>
              {movies.map((movie, index) => {
                return (
                  <div className={classes.listedMovieWrapper} key={index}>
                    <div className={classes.listedMovieSize}>
                      <ListedMovie
                        thumbnail={movie.thumbnail}
                        onClick={() => this.setState({ autoplay: true, selectedMovie: index })}
                      >
                        <div className={classes.playIconWrapper}>
                          <div className={classes.gradient}>
                            <ParallaxPlayIcon className={classes.playIcon} />
                          </div>
                        </div>
                        <div className={classes.movieTitleContainer}>
                          <div className={classes.movieTitle}>
                            <div>
                              <span className={classNames(classes.movieTitleLine, { _underline: !movie.title2 })}>
                                {movie.title1}
                              </span>
                            </div>
                            {movie.title2 && <div>
                              <span className={classNames(classes.movieTitleLine, '_underline')}>
                                {movie.title2}
                              </span>
                            </div>}
                            <div>{movie.subtitle}</div>
                          </div>
                        </div>
                      </ListedMovie>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={classes.textContainer}>
          <div className={classes.text}>
            <p>
              URLIFE is a team of professional producers and editors who collaborate with you,
              and then do all the work for you! We take your photos and videos,
              add a sprinkle of Hollywood magic and help tell your stories -
              preserving them just the way you want to remember them. Forever!
            </p>
          </div>
          <div className={classes.buttonWrapper}>
            <RegisterOrProjectButton promocode='FAMILYGALLERY' />
          </div>
        </div>
        <div className={classNames(classes.testimonialsContainer, 'testimonial')}>
          <div className={classes.testimonialsHeaderWrapper}>
            <div className={classes.testimonialsLine} />
            <div className={classNames(classes.sectionHeader, '_testimonials')}>
              Testimonials
            </div>
            <div className={classes.testimonialsLine} />
          </div>
          <TestimonialSlider settings={settings} testimonials={testimonials} />
        </div>
        <div className={classes.promoContainer}>
          <div className={classes.offerWrapper}>
            <div className={classes.offerHeaderWrapper}>
              <div className={classNames(classes.sectionHeader, '_offer')}>
                Limited Offer
              </div>
              <div className={classes.offerDivider} />
            </div>
            <div className={classes.offerTextBig}>
              50% Off
            </div>
            <div className={classes.offerTextMedium}>
              Any URLIFE
            </div>
            <div className={classes.offerTextMedium}>
              Project!
            </div>
            <div className={classes.offerTextSmall}>
              Start making memories that will last a lifetime today!
            </div>
            <div className={classes.buttonWrapper}>
              <RegisterOrProjectButton promocode='FAMILYGALLERY' />
            </div>
          </div>
          <div className={classes.dividerWrapper}>
            <div className={classes.promoDivider} />
          </div>
          <div className={classes.packagesWrapper}>
            <PackageList
              priceBlock={'price-only'}
              border={'purple'}
              fontSize={'small'}
              packageOverrides={packages}
            />
          </div>
        </div>
        <div className={classes.faqContainer}>
          <div className={classNames(classes.header, '_uppercase')}>
            FAQ
          </div>
          <div className={classNames(classes.text, classes.faqText)}>
            We have categorized our frequently asked questions to make it easier to find what you are after.
          </div>
          <FAQList />
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.text}>
            For more information, email us at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a>.
          </div>
        </div>
      </div>
    )
  }
}

Moms.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Moms)
