import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import { getNewUsers } from '../../actions'
import { history } from '../../helpers'

const styles = {
  headerCol: {
    backgroundColor: '#f6f6f6',
    display: 'table-cell',
    fontSize: '16px',
    fontWeight: '500',
    padding: '20px',
    top: 106,
    whiteSpace: 'nowrap',
    width: 'auto',
    zIndex: '1'
  },
  headerRow: {
    alignItems: 'center',
    display: 'table-row'
  },
  paper: {
    background: 'none'
  },
  reportNewUsersCol: {
    display: 'table-cell',
    maxWidth: 600,
    overflow: 'hidden',
    padding: '10px 20px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  reportNewUsersRow: {
    '&:hover': {
      backgroundColor: '#eeeeee'
    },
    display: 'table-row',
    titleText: {
      margin: '10px 0px 10px 10px'
    }
  },
  titleText: {
    padding: '20px'
  },
  userProjectsLink: {
    '&:hover': {
      textDecoration: 'none'
    },
    color: '#01b7d7',
    textDecoration: 'underline'
  }
}

class ReportsNewUsers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      newUsers: null
    }
  }

  async fetchNewUsers () {
    const { getNewUsersFunction } = this.props
    try {
      const data = await getNewUsersFunction()
      this.setState({ newUsers: data })
    } catch (e) {}
  }

  componentDidMount () {
    this.fetchNewUsers()
  }

  render () {
    const { classes } = this.props
    const { newUsers } = this.state

    const formatDate = (dateString) => {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
      return new Date(dateString).toLocaleDateString('en-US', options)
    }

    return (
        <div>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h4" component="h2" className={classes.titleText}>
              <span>New users not started / completed</span>
            </Typography>
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Date account started</div>
              <div className={classes.headerCol}>User</div>
              <div className={classes.headerCol}>Email</div>
            </div>
            {newUsers &&
              newUsers.map((data) => {
                return (
                  <div
                    data-testid="apl-newusers"
                    key={`${data.id}`}
                    className={classes.reportNewUsersRow}
                  >
                    <div className={classes.reportNewUsersCol}>
                      {formatDate(data.createdAt)}
                    </div>
                    <div className={(classes.userProjectsLink)}
                      onClick={(e) => {
                        e.stopPropagation()
                        history.push('/admin/users/' + data.id)
                      }}
                      style={{ cursor: 'pointer', padding: '10px 20px', textAlign: 'left' }}
                    >
                      {data.name}
                    </div>
                    <div className={classes.reportNewUsersCol}>
                      {data.email}
                    </div>
                  </div>
                )
              })}
          </Paper>
        </div>
    )
  }
}

ReportsNewUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  getNewUsersFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    getNewUsersFunction: function () {
      return dispatch((getNewUsers()))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(ReportsNewUsers))
