import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ToTopIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 54 54">
      <defs><mask id="d7f8b" width="2" height="2" x="-1" y="-1"><path fill="#fff" d="M3 1h48v48H3z"/><path d="M27 49c13.255 0 24-10.745 24-24S40.255 1 27 1 3 11.745 3 25s10.745 24 24 24z"/></mask><filter id="d7f8a" width="76" height="78" x="-11" y="-13" filterUnits="userSpaceOnUse"><feOffset dy="2" in="SourceGraphic" result="FeOffset1073Out"/><feGaussianBlur in="FeOffset1073Out" result="FeGaussianBlur1074Out" stdDeviation="1.6 1.6"/></filter><linearGradient id="d7f8c" x1="3" x2="51" y1="1" y2="49" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1"/><stop offset="1" stopColor="#bd6ad3"/></linearGradient></defs><g><g><g><g filter="url(#d7f8a)"><path fill="none" d="M27 49c13.255 0 24-10.745 24-24S40.255 1 27 1 3 11.745 3 25s10.745 24 24 24z" mask="url(&quot;#d7f8b&quot;)"/><path fillOpacity=".07" d="M27 49c13.255 0 24-10.745 24-24S40.255 1 27 1 3 11.745 3 25s10.745 24 24 24z"/></g><path fill="url(#d7f8c)" d="M27 49c13.255 0 24-10.745 24-24S40.255 1 27 1 3 11.745 3 25s10.745 24 24 24z"/></g><g transform="rotate(-90 27.095 27.92)"><g><g><path fill="#fff" d="M20.058 27.08H17.79c-.422 0-.765.377-.765.84 0 .465.343.84.765.84h2.27c.422 0 .764-.375.764-.84 0-.463-.342-.84-.765-.84zm17.104.822a.826.826 0 0 0-.048-.273l-.009-.03a.871.871 0 0 0-.065-.135l-.005-.01a.84.84 0 0 0-.098-.13l-8.368-9.19a.717.717 0 0 0-1.08 0 .9.9 0 0 0 0 1.187l7.063 7.76H22.66c-.422 0-.765.376-.765.84 0 .464.343.84.765.84h11.894l-7.066 7.76a.899.899 0 0 0 0 1.187.73.73 0 0 0 .541.246.73.73 0 0 0 .541-.246l8.37-9.193c.008-.007.013-.017.02-.025a.82.82 0 0 0 .075-.101c.01-.017.017-.035.026-.052.016-.03.032-.06.045-.093.008-.02.012-.043.018-.064.009-.03.02-.06.026-.092a.94.94 0 0 0 .015-.166l-.002-.02z"/></g></g></g><g><path fill="#fff" d="M17 13h20.596v1.613H17z"/></g></g></g>
    </SvgIcon>
  )
}
