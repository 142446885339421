import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import { ROLES } from '../../../constants'
import classNames from 'classnames'
import {
  closeMoreMenu,
  openMoreMenu
} from '../../../actions'
import {
  CloseIcon,
  MenuIcon,
  ParallaxArrowRight,
  TopMenuMore,
  UrlifeLogoGradient
  // YoutubeIcon,
  // InstagramIcon,
  // FacebookIcon,
  // TwitterIcon,
} from '..//SVG/index'
import Drawer from '@material-ui/core/Drawer'
import MainMoreMenu from './components/MainMoreMenu'
import LoggedOutMoreMenu from './components/LoggedOutMoreMenu'
import ProjectMoreMenu from './components/ProjectMoreMenu'
import UsePromo from '../../promo/usePromo'
import NotificationList from './components/NotificationList'
import UrlifeSettingsPage from '../../settings/UrlifeSettingsPage'
import AdminSettings from './components/AdminSettings'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: '#f5f5f5'
  },
  moreIconWrapper: {
    opacity: 0.6,
    width: 80,
    height: 50,
    margin: '17px 10px 0 10px',
    transition: 'opacity ease .3s',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    },
    '& span': {
      color: '#fff',
      marginTop: 8,
      textAlign: 'center',
      display: 'block',
      fontSize: 12
    },
    '& svg': {
      display: 'block',
      margin: 'auto'
    },
    ...commonStyles.media(991, {
      width: 50,
      margin: '17px 5px 0 5px',
      '& span': {
        fontSize: 10
      }
    }),
    ...commonStyles.media(480, {
      margin: '16px 0px 0 0px',
      '& span': {
        marginTop: 5
      }
    })
  },
  moreIconTextOnly: {
    margin: '0px 15px 0 15px',
    width: 'auto',
    height: 'auto',
    opacity: 1,
    '& span': {
      fontSize: 14,
      borderBottom: '2px solid transparent',
      transition: '.3s ease',
      '&:hover': {
        borderBottomColor: '#fff'
      }
    },
    '& svg': {
      margin: 0,
      display: 'none'
    }
  },
  moreIcon: {
    fill: 'none',
    '&._vertical': {
      transform: 'rotateZ(-90deg)'
    },
    '&._dark': {
      stroke: '#000'
    },
    '&._loggedOut': {
      fontSize: 22
    }
  },
  drawer: {
    width: 440,
    height: '100%',
    background: '#f5f5f5',
    ...commonStyles.media(440, {
      width: '100vw'
    })
  },
  close: {
    width: 20,
    height: 20,
    margin: 34,
    cursor: 'pointer',
    color: '#333333'
  },
  back: {
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: 2,
    width: 20,
    height: 20,
    margin: 34,
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    display: 'inline-block'
  },
  mainMenuContainer: {
    background: '#f5f5f5',
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  menuFooter: {
    padding: '20px 60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  urlifeIcon: {
    height: 40,
    width: 'auto',
    ...commonStyles.media(480, {
      height: 35
    })
  },
  // socialIcon: {
  //   margin: '17px 17px 4px 0px'
  // },
  copyright: {
    marginTop: 5,
    opacity: 0.6,
    color: '#333333',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400
  },
  title: {
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 20,
    margin: '34px auto',
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.5,
    textTransform: 'uppercase'
  },
  notificationListWrapper: {
    position: 'absolute',
    left: 0,
    top: 70,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  }
}
/* eslint-enable sort-keys */

class MoreMenu extends Component {
  toggleDrawer () {
    const {
      type,
      menu,
      openMoreMenuFunction,
      closeMoreMenuFunction
    } = this.props
    if (menu) {
      closeMoreMenuFunction()
    } else {
      if (type === 'project') {
        openMoreMenuFunction('project')
      } else {
        openMoreMenuFunction('main')
      }
    }
  }

  toMainMenu () {
    const {
      menu,
      openMoreMenuFunction
    } = this.props
    if (menu.startsWith('admin') && menu !== 'admin-settings') {
      openMoreMenuFunction('admin-settings')
    } else {
      openMoreMenuFunction('main')
    }
  }

  adminMenuTitle () {
    const { menu } = this.props
    switch (menu) {
      case 'admin-projects':
        return 'Projects'
      case 'admin-promotions':
        return 'Promotions'
      case 'admin-system-assets':
        return 'System assets'
      case 'admin-system':
        return 'System'
      case 'admin-reports':
        return 'reports'
      default:
        return 'Admin settings'
    }
  }

  render () {
    const {
      classes,
      loggedIn,
      roleId,
      type,
      menu,
      moreIconClassName,
      moreIconColor,
      saveProject,
      downloadProject,
      deleteProject,
      closeMoreMenuFunction
    } = this.props
    const drawerOpen = !!menu
    const isAdmin = roleId && roleId === ROLES.ADMIN
    const currentYear = new Date().getFullYear()
    let moreClass = moreIconClassName
    if (!moreClass) {
      moreClass = classNames(classes.moreIconWrapper, isAdmin ? classes.moreIconTextOnly : null)
    }

    return (
      <React.Fragment>
        {/* more icon */}
        <div
          onClick={this.toggleDrawer.bind(this)}
          className={moreClass}
          style={drawerOpen
            ? { opacity: 1 }
            : null}
        >
          {type === 'more' && loggedIn &&
            <div>
              <TopMenuMore className={classes.moreIcon} stroke={moreIconColor} />
              <span>More</span>
            </div>
          }
          {type === 'more' && !loggedIn &&
            <MenuIcon className={classNames(classes.moreIcon, '_loggedOut')} stroke={moreIconColor} />
          }
          {type === 'project' &&
            <TopMenuMore className={classNames(classes.moreIcon, '_vertical')} stroke={moreIconColor} />
          }
        </div>
        {/* drawer */}
        <Drawer
          classes={{ paper: classes.paper }}
          open={drawerOpen} anchor="right"
          onClose={this.toggleDrawer.bind(this)}
        >
          <div className={classes.drawer}>
            {(menu === 'main' || menu === 'project')
              ? <CloseIcon onClick={this.toggleDrawer.bind(this)} className={classes.close} />
              : <ParallaxArrowRight onClick={this.toMainMenu.bind(this)} className={classes.back} />}
            {(menu === 'main' || menu === 'project') && <div className={classes.mainMenuContainer}>
              {menu === 'main' && loggedIn && <MainMoreMenu />}
              {menu === 'main' && !loggedIn && <LoggedOutMoreMenu />}
              {menu === 'project' &&
              <ProjectMoreMenu
                saveProject={saveProject}
                downloadProject={downloadProject}
                deleteProject={deleteProject}
              />}
              {/* footer */}
              <div className={classes.menuFooter}>
                <UrlifeLogoGradient className={classes.urlifeIcon}/>
                {/* <div>
                  <YoutubeIcon className={classes.socialIcon}/>
                  <InstagramIcon className={classes.socialIcon}/>
                  <FacebookIcon className={classes.socialIcon}/>
                  <TwitterIcon className={classes.socialIcon}/>
                </div> */}
                <span className={classes.copyright}>©2018-{currentYear} URLIFE Media, Inc. All rights reserved.</span>
              </div>
            </div>}
            {/* user promo page */}
            {menu === 'use-promo' && <React.Fragment>
              <span className={classes.title}>Promo</span>
              <UsePromo />
            </React.Fragment>}
            {/* notifications page */}
            {menu === 'notifications' && <React.Fragment>
              <span className={classes.title}>Notifications</span>
              <div className={classes.notificationListWrapper}>
                <NotificationList
                  closeMenu={() => closeMoreMenuFunction()}
                  scrollElement={'parent'}
                />
              </div>
            </React.Fragment>}
            {/* settings page */}
            {menu === 'settings' && <React.Fragment>
              <span className={classes.title}>Settings</span>
              <UrlifeSettingsPage />
            </React.Fragment>}
            {/* admin settings */}
            {menu.startsWith('admin') && <React.Fragment>
              <span className={classes.title}>
                {this.adminMenuTitle()}
              </span>
              <AdminSettings />
            </React.Fragment>}
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

MoreMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  closeMoreMenuFunction: PropTypes.func.isRequired,
  deleteProject: PropTypes.func,
  downloadProject: PropTypes.func,
  loggedIn: PropTypes.bool.isRequired,
  menu: PropTypes.string,
  moreIconClassName: PropTypes.string,
  moreIconColor: PropTypes.string,
  openMoreMenuFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number,
  saveProject: PropTypes.func,
  type: PropTypes.oneOf(['more', 'project'])
}

MoreMenu.defaultProps = {
  moreIconClassName: null,
  moreIconColor: '#ffffff',
  roleId: null,
  type: 'more'
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn,
    menu: state.moreMenu.menu,
    roleId: state.user.roleId
  }
}

function mapDispathToProps (dispatch) {
  return {
    closeMoreMenuFunction: function () {
      dispatch(closeMoreMenu())
    },
    openMoreMenuFunction: function (menu) {
      dispatch(openMoreMenu(menu))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispathToProps)(MoreMenu))
