import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import { saveUserRole } from '../../../actions'
import { commonStyles } from '../../../helpers'
import UrlifeDialogError from '../../common/UrlifeDialogError'
import { ROLE_NAMES, ROLES } from '../../../constants'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    minWidth: 120
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...commonStyles.text({
      color: '#000',
      fontSize: '2rem'
    })
  }
})

export class UrlifeRoleDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      role: props.startRole,
      saveFailed: false
    }
    this.onChange = (event) => {
      this.setState({ role: event.target.value })
    }
    this.toSave = () => {
      const { role } = this.state
      const { onSave, user, onClose } = this.props
      this.props.dispatch(saveUserRole(user, role)).then(
        data => {
          onSave(user, role)
          onClose()
        },
        fail => {
          this.setState({ saveFailed: true })
        }
      )
    }
  }

  render () {
    const { role, saveFailed } = this.state
    const { onClose, open, classes, userEmail } = this.props
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true} className={classes.title}>{userEmail}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change user role.
          </DialogContentText>
          {saveFailed && <UrlifeDialogError message={'Error saving role.'} />}
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="role-native-simple">Role</InputLabel>
              <Select
                native
                value={role}
                onChange={this.onChange}
                input={<Input id="role-native-simple" />}
              >
                {Object.values(ROLES).map(role => {
                  return (
                    <option key={role} value={role}>{ROLE_NAMES[role].toLowerCase()}</option>
                  )
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.toSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UrlifeRoleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startRole: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired
}

export default connect()(withStyles(styles)(UrlifeRoleDialog))
