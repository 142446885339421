import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { commonStyles, history } from '../../helpers'

import UrlifeDialogSuccess from '../common/UrlifeDialogSuccess'
import ValidateForm from '../common/form/ValidateForm'
import {
  forgottenPassword,
  registerUser,
  setSkinId,
  toggleModal
} from '../../actions'
import ReactGA from 'react-ga4'

/* eslint-disable sort-keys */
const styles = {
  registrationHeader: {
    textAlign: 'left',
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    padding: '40px 50px 0px 50px',
    '&._skinned': {
      padding: '0px 50px 0px 50px'
    },
    '&._center': {
      textAlign: 'center'
    },
    ...commonStyles.media(480, {
      fontSize: 16,
      padding: '20px 20px 0px 20px',
      minWidth: 288,
      '&._skinned': {
        padding: '0px 20px 0px 20px'
      }
    })
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 40px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  emailText: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
    color: '#666666',
    textAlign: 'center',
    '& span': {
      marginLeft: 10,
      color: '#01b7d7',
      textTransform: 'underline',
      cursor: 'pointer'
    }
  },
  buttonHolder: {
    flexDirection: 'column',
    padding: '0 50px',
    justifyContent: 'center',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  useButton: {
    margin: 0,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  submitButton: {
    marginBottom: 10,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  loginButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '20px 0',
    textTransform: 'none'
  }
}
/* eslint-enable sort-keys */

class UrlifeRegisterDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      formStatus: '' // '', sending, success, fail, resend-success, resend-fail
    }
    this.resend = false
    this.resendCode = ''

    this.send = () => (event) => {
      const { userEmail, fullName } = this.state.fields
      const { code, skinData } = this.props
      if (this.resend) {
        this.props.forgottenPasswordFunction(userEmail, this.resendCode)
          .then(
            data => {
              this.setState({
                formStatus: 'resend-' + (data.status || 'fail')
              })
            }
          )
      } else {
        this.setState({
          formStatus: 'sending'
        }, () => {
          this.props.registerUser(userEmail, fullName, code)
            .then(
              data => {
                this.resend = true
                this.resendCode = code
                this.props.setCode('')
                this.setState({
                  formStatus: data.status || 'fail'
                })
                let label = 'marketing'
                if (skinData) {
                  label = skinData.title
                } else if (history.location.pathname && history.location.pathname !== '/') {
                  label = history.location.pathname
                }
                ReactGA.event({
                  action: 'createAccount',
                  category: 'conversionRate',
                  label
                })
              }
            )
            .catch(e => {
              this.setState({
                formStatus: e.message === 'Account already exists for user'
                  ? 'accountAlreadyExists'
                  : 'fail'
              })
            })
        })
      }
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  closeModal () {
    this.props.setSkinIdFunction(null)
    this.props.toggleModal(false, null)
  }

  useCode () {
    this.props.toggleModal(true, 'useCode')
  }

  render () {
    const { fields, formStatus } = this.state
    const { classes, loginSwitch, skinData } = this.props
    const skinned = !!skinData
    const didResend = formStatus.match(/resend/)
    return (
      <div>
        <DialogTitle
          disableTypography
          className={classNames(
            classes.registrationHeader,
            { _center: formStatus === 'success', _skinned: skinned }
          )}
        >
          {(formStatus === 'success' || didResend)
            ? skinned
              ? 'Nearly there'
              : 'Thank you for registering'
            : skinned
              ? 'Your details'
              : 'Create account'}
        </DialogTitle>

        {formStatus === 'success' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            message={skinned
              ? 'We have sent you a confirmation email. Please follow the instructions to continue'
              : 'An email will be sent with instructions to complete your registration.'
            }
          />
          {skinned &&
          <div className={classes.emailText}>
            Can’t find the email?
            <span onClick={this.send()}>Send again</span>
          </div>
          }
          {!skinned && <UrlifeButton
            onClick={this.closeModal.bind(this)}
            classes={{ root: classes.submitButton }}
          >OK</UrlifeButton>}
        </DialogContent>
        }
        {formStatus === 'resend-success' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            message={
              'We have sent a new confirmation email to ' + fields.userEmail +
              '. If this is not the correct email address, please contact hello@urlifemedia.com for assistance.'
            }
          />
          {skinned &&
            <div className={classes.emailText}>
              Can’t find the email?
              <span onClick={this.send()}>Send again</span>
            </div>
          }
          {!skinned && <UrlifeButton
            onClick={this.closeModal.bind(this)}
            classes={{ root: classes.submitButton }}
          >OK</UrlifeButton>}
        </DialogContent>
        }
        {formStatus === 'resend-fail' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            message={
              'We were not able to send a new email. Please contact hello@urlifemedia.com for assistance.'
            }
          />
          {<UrlifeButton
            onClick={this.closeModal.bind(this)}
            classes={{ root: classes.submitButton }}
          >OK</UrlifeButton>}
        </DialogContent>
        }
        {formStatus !== 'success' && !didResend &&
          <ValidateForm
            onSubmit={this.send()}
            onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
            validateOnMount={true}>
            {(form) => (
              <React.Fragment>
                <DialogContent classes={{ root: classes.content }}>
                  {form.renderField(
                    'fullName',
                    ['required'],
                    (field, fieldData) => {
                      const validationStatus = fields.fullName && fields.fullName.length > 0 ? 'success' : 'none'
                      return (
                        <UrlifeTextField
                          autoFocus
                          margin="dense"
                          name="fullName"
                          label="Full name"
                          type="text"
                          fullWidth
                          defaultValue={fields.fullName || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          validationStatus={validationStatus}
                        />
                      )
                    }
                  )}
                  {form.renderField(
                    'userEmail',
                    ['required', 'email'],
                    (field, fieldData) => {
                      let validationStatus = 'none'
                      let errorMessage = ''
                      if (formStatus === 'accountAlreadyExists') {
                        validationStatus = 'error'
                        errorMessage = 'Account already exists for user'
                      } else if (formStatus === 'fail') {
                        validationStatus = 'error'
                        errorMessage = 'Create account failed, please try again later'
                      } else if (fields.userEmail && fields.userEmail.length > 0) {
                        if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                          validationStatus = 'error'
                          errorMessage = fieldData.errors[0]
                        } else {
                          validationStatus = 'success'
                        }
                      }
                      return (
                        <UrlifeTextField
                          margin="dense"
                          name="userEmail"
                          label="Email"
                          type="email"
                          fullWidth
                          defaultValue={fields.userEmail || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          validationStatus={validationStatus}
                          infoLabel={errorMessage}
                        />
                      )
                    }
                  )}
                </DialogContent>
                <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                  <UrlifeButton
                    type="submit"
                    disabled={formStatus === 'sending' || !form.checkFormValid()}
                    className={classes.submitButton}
                  >
                    {skinned ? 'Continue' : 'Create Account'}
                  </UrlifeButton>
                  {!skinned && <UrlifeButton
                    inverted
                    className={classes.useButton}
                    onClick={this.useCode.bind(this)}
                  >
                    Use Project Code
                  </UrlifeButton>}
                  <Button
                    onClick={loginSwitch}
                    classes={{ root: classes.loginButton }}
                  >
                    {skinned ? 'Already have an account?' : 'Log in'}
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
          </ValidateForm>
        }
      </div>
    )
  }
}

UrlifeRegisterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  forgottenPasswordFunction: PropTypes.func.isRequired,
  loginSwitch: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    forgottenPasswordFunction (email, code) {
      return dispatch(forgottenPassword(email, code))
    },
    registerUser: function (email, fullName, code) {
      return dispatch(registerUser(email, fullName, code))
    },
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeRegisterDialog))
