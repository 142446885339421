import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function CheckMark ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox="0 0 42 44">
      <g><g><path fill={color} d="M37.296 1.78L19.018 37.166 4.875 27.995a2.613 2.613 0 0 0-3.63.619 2.585 2.585 0 0 0 .622 3.613l16.265 10.667c.456.323.98.476 1.502.476.814 0 1.617-.38 2.125-1.09L41.543 4.782a2.584 2.584 0 0 0-.615-3.614 2.615 2.615 0 0 0-3.632.61z" /></g></g>
    </SvgIcon>
  )
}

CheckMark.propTypes = {
  color: PropTypes.string
}
