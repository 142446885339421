function getBadge (notificationCount) {
  if (notificationCount > 99) {
    return '99+'
  } else if (notificationCount > 0) {
    return notificationCount.toString()
  } else {
    return ''
  }
}

const notification = (state = { badge: '', items: [] }, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION_ITEMS':
      return {
        ...state,
        badge: getBadge(action.items.length),
        items: action.items
      }
    case 'ADD_NOTIFICATION_ITEMS':
      const newItems = action.items.filter(newItem => !state.items.find(item => item.id === newItem.id))
      const items = [...state.items, ...newItems]
      items.sort((a, b) => {
        const aCreatedAt = new Date(a.createdAt)
        const bCreatedAt = new Date(b.createdAt)
        return bCreatedAt.getTime() - aCreatedAt.getTime()
      })
      return {
        ...state,
        badge: getBadge(items.length),
        items: items
      }
    default:
      return state
  }
}

export default notification
