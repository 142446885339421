import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Confirmation from '../../../common/Confirmation'
import { CONTACT_CHOICE, PACKAGE_TYPES } from '../../../../constants'
import moment from 'moment'
import { getLeastCompleteProjectSku } from '../../../../helpers'

class Congratulations extends React.Component {
  render () {
    const { project, packageType, isCustomPrice, skinData } = this.props
    const isCurated = packageType === PACKAGE_TYPES.CURATED
    const title = (!!skinData && skinData.congratulationsTitle) || 'Congratulations!'
    const contactChoice = project.creationStatus
      ? project.creationStatus.contactChoice
      : null
    const projectSku = getLeastCompleteProjectSku(project)
    const appointment = projectSku && projectSku.appointment
    const subHeaderText = !!skinData && skinData.congratulationsSubtitle
      ? skinData.congratulationsSubtitle
      : isCustomPrice
        ? 'Your project has been submitted'
        : (isCurated ? 'Curated folder' : 'Movie magic') + ' now in the making'
    let infoText = ''
    if (isCustomPrice) {
      infoText = 'A Producer will be in touch to discuss your custom project!'
    } else if (contactChoice === CONTACT_CHOICE.EMAIL_ME) {
      infoText = 'A URLIFE producer will connect with you soon.'
    } else if (appointment && contactChoice === CONTACT_CHOICE.APPOINTMENT) {
      const appointmentDate = moment(appointment).format('MMM D') + ' at ' + moment(appointment).format('ha')
      infoText = 'A producer will call you on ' + appointmentDate
    }
    return (
      <Confirmation
        headerText={title}
        subHeaderText={subHeaderText}
        infoText={infoText}
        project={project}
        hasStatusBar={true}
      />
    )
  }
}

Congratulations.propTypes = {
  classes: PropTypes.object.isRequired,
  isCustomPrice: PropTypes.bool.isRequired,
  packageType: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  skinData: PropTypes.object
}

function mapStateToProps (state) {
  return {
    skinData: state.skinning.skinData
  }
}

export default connect(mapStateToProps, null)(Congratulations)
