import ApiService from '../services/ApiService'
import jwt from 'jsonwebtoken'
import { api } from '../constants'
import { history } from '../helpers'

export {
  emailSignUp,
  forgottenPassword,
  getCognito,
  getUserInvite,
  getUserComingSoonInvite,
  getPackageAccessAction,
  registerUser,
  resetLogin,
  resetPassword,
  setPackageAccessAction,
  setUserInfo,
  verifyPassword,
  userLogin,
  userLogout,
  userInfo,
  userInfoById,
  wantsLogin
}

function userLogin (email, password, callback, closeModal = true) {
  return dispatch => {
    dispatch({ email, type: 'LOGIN_REQUEST' })

    return new ApiService(api.LOGIN, {}, {}, false, dispatch)
      .post({ access_type: 'offline', email, password })
      .then(
        user => {
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            window.localStorage.setItem('lastRequest', Date.now())
            window.localStorage.setItem('auth', JSON.stringify(user))
          }
          if (callback && typeof callback === 'function') { callback(null) }
          dispatch({ email, type: 'LOGIN_SUCCESS', user })
          if (closeModal) {
            dispatch({ componentModal: 'login', isOpenModal: false, type: 'TOGGLE_MODAL' })
          }
          return dispatch(userInfo())
        },
        fail => {
          if (callback && typeof callback === 'function') { callback(fail) }
          return dispatch({ fail, type: 'LOGIN_FAILURE' })
        }
      )
  }
}

function userLogout () {
  return dispatch => {
    // remove user from local storage to log user out
    window.localStorage.removeItem('auth')
    window.localStorage.removeItem('lastRequest')
    history.push('/')
    return dispatch({ type: 'LOGOUT', wasAutomaticLogout: false, wasManualLogout: true })
  }
}

function userInfo () {
  return dispatch => {
    return new ApiService(api.CURRENT_USER_INFO, {}, {}, true, dispatch)
      .get()
      .then(
        user => {
          return dispatch({ type: 'RECEIVED_INFO', user })
        },
        fail => {
          if (fail === 'Unauthorized') {
            dispatch({ type: 'FAILED_INFO' })
            return dispatch(userLogout())
          }
          return dispatch({ type: 'FAILED_INFO' })
        }
      )
  }
}

function userInfoById (userId) {
  return dispatch => {
    return new ApiService(api.USER_INFO, { userId }, {}, true, dispatch)
      .get()
      .then(
        user => user,
        fail => Promise.reject(fail)
      )
  }
}

function setUserInfo (info) {
  return dispatch => {
    return new ApiService(api.CURRENT_USER_INFO, {}, {}, true, dispatch)
      .post(info)
      .then(
        user => {
          dispatch({ type: 'RECEIVED_INFO', user })
          return user
        },
        fail => Promise.reject(fail)
      )
  }
}

function registerUser (email, fullname, code = null) {
  return dispatch => {
    return new ApiService(api.REGISTER, {}, {}, false, dispatch)
      .post({ code, email, fullname })
      .then(success => success, fail => Promise.reject(fail))
  }
}

function emailSignUp (email, fullname, topics, startDate = null) {
  return dispatch => {
    return new ApiService(api.SIGN_UP, {}, {}, false, dispatch)
      .post({ email, fullname, startDate, topics })
      .then(success => success, fail => Promise.reject(fail))
  }
}

function getUserInvite (email, fullname) {
  return dispatch => {
    return new ApiService(api.INVITE, {}, {}, false, dispatch)
      .post({ email, fullname })
      .then(success => success, fail => Promise.reject(fail))
  }
}

function getUserComingSoonInvite (email, fullname) {
  return dispatch => {
    return new ApiService(api.INVITE_COMING_SOON, {}, {}, false, dispatch)
      .post({ email, fullname })
      .then(success => success, fail => Promise.reject(fail))
  }
}

function forgottenPassword (email, code) {
  return dispatch => {
    return new ApiService(api.FORGOTTEN, {}, {}, false, dispatch)
      .post({ code, email })
      .then(
        success => success,
        fail => Promise.reject(fail)
      )
  }
}
function resetPassword (password, token, autoLogin = false) {
  return async dispatch => {
    dispatch({ type: 'RESET_PASS_START' })
    try {
      const result = await new ApiService(api.RESET, {}, {}, false, dispatch)
        .post({ password, token })
      dispatch({ type: 'RESET_PASS_SUCCESS' })
      if (autoLogin) {
        const decodedToken = jwt.decode(token)
        if (decodedToken.email) {
          dispatch(userLogin(decodedToken.email, password, null, false))
        }
      }
      return { code: result.code || '', codeResult: result.codeResult || null }
    } catch (e) {
      dispatch({ type: 'RESET_PASS_FAILED' })
      return { code: e.code || '', codeResult: null }
    }
  }
}

function verifyPassword (email, password) {
  return async dispatch => {
    let response
    try {
      response = await new ApiService(api.LOGIN, {}, {}, false, dispatch)
        .post({ email, password })
    } catch (e) {}
    return !!response && !!response.access_token
  }
}

function resetLogin () {
  return dispatch => {
    return dispatch({ type: 'RESET_LOGIN' })
  }
}

function wantsLogin (wants) {
  return dispatch => {
    return dispatch({ type: 'WANTS_LOGIN', wantsLogin: wants })
  }
}

const verifyCognito = info => info && info.expires && info.expires > Date.now()

let cognitoPromise = false
/**
 * If cognito data is actual, resolve promise with it
 * If not, fetch from server
 * @return {Promise}
 */
function getCognito () {
  return async (dispatch, getState) => {
    const stateCognito = getState().user.cognito
    const gettingCognito = getState().user.gettingCognito

    // return from redux store if actual
    if (stateCognito && verifyCognito(stateCognito)) {
      return stateCognito
    }

    // get cognito from server
    if (!cognitoPromise || !gettingCognito) {
      dispatch({ type: 'GETTING_COGNITO' })
      cognitoPromise = new ApiService(api.COGNITO, {}, {}, true, dispatch)
        .get()
        .then(
          response => {
            const { expiresIn, ...cognito } = response.data
            // Set cognito expire from now
            cognito.expires = Date.now() + expiresIn * 1000
            dispatch({ cognito, type: 'GOT_COGNITO' })
            return cognito
          },
          fail => {
            return dispatch({ type: 'GET_COGNITO_FAILED' })
          }
        )
    }
    return cognitoPromise
  }
}

function getPackageAccessAction (userId) {
  return dispatch => {
    return new ApiService(api.GET_PACKAGE_ACCESS, { userId }, {}, true, dispatch)
      .get()
      .then(
        success => success,
        fail => Promise.reject(fail)
      )
  }
}

function setPackageAccessAction (userId, packageId, body) {
  return dispatch => {
    return new ApiService(api.SET_USER_PACKAGE_ACCESS, { packageId, userId },
      {}, true, dispatch)
      .put(body)
      .then(
        success => success,
        fail => Promise.reject(fail)
      )
  }
}
