import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import MomentUtils from '@material-ui/pickers/adapter/moment'
import moment from 'moment'
import {
  DatePicker,
  LocalizationProvider
} from '@material-ui/pickers'

/* eslint-disable sort-keys */
const styles = {
  editTextFieldControl: {
    width: '100%',
    position: 'relative'
  },
  editTextField: {
    width: '100%'
  },
  editTextFieldError: {
    fontSize: 10,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '100%',
    marginTop: 4
  },
  editDateInput: {
    fontSize: 14,
    lineHeight: '19px',
    marginTop: 1.5
  }
}
/* eslint-enable sort-keys */

class EditDateField extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      localValue: props.value || ''
    }
  }

  checkValid (calledWith) {
    const { localValue } = this.state
    const toUse = calledWith || localValue
    return toUse && toUse.toString().trim().length > 0
  }

  onChange (e) {
    this.setState({ localValue: e })
  }

  onApply (e) {
    const { onApply } = this.props
    if (this.checkValid(e)) {
      onApply(e.format('YYYY-MM-DD HH:mm:ss'))
    }
  }

  componentDidMount () {
    if (!this.props.value) {
      const value = moment(new Date())
      this.onApply(value)
      this.setState({ localValue: value })
    }
  }

  render () {
    const { classes } = this.props
    const { localValue } = this.state
    const isValid = this.checkValid()

    return (
      <LocalizationProvider dateAdapter={MomentUtils}>
        <FormControl className={classes.editTextFieldControl} error={!isValid}>
        <DatePicker
          InputProps={{
            className: classes.editDateInput
          }}
          InputAdornmentProps={{ position: 'end' }}
          inputFormat="DD MMMM YYYY"
          format={'DD MMMM YYYY'}
          disableFuture
          value={localValue}
          onChange={e => this.onChange(e)}
          onAccept={e => this.onApply(e)}
          disableMaskedInput={true}
          renderInput={(params) => <TextField {...params} helperText={''} />}
        />
        {!isValid &&
        <FormHelperText className={classes.editTextFieldError}>Required</FormHelperText>
        }
        </FormControl>
      </LocalizationProvider>
    )
  }
}

EditDateField.propTypes = {
  classes: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
}

export default withStyles(styles)(EditDateField)
