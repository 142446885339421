import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  fetchPromocode,
  updatePromocode
} from '../../../actions'
import { history } from '../../../helpers/index'
import { BackArrow } from '../../common/SVG/index'
import PromocodeForm from './components/PromocodeForm'

const styles = {
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  paper: {
    background: 'none'
  }
}

class EditPromocode extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      promo: null
    }
  }

  componentDidMount () {
    const { match, fetchPromocodeFunction } = this.props
    fetchPromocodeFunction(match.params.promoId)
      .then(
        data => {
          if (data.data && data.data.length > 0) {
            this.setState({ promo: data.data[0] })
          }
        }
      )
  }

  onSubmit (promo) {
    const { match, updatePromocodeFunction } = this.props
    updatePromocodeFunction(match.params.promoId, promo)
      .then(
        response => {
          history.goBack()
        }
      ).catch(e => {
        this.setState({
          error: 'Something went wrong when updating the promocode.',
          promo: promo
        })
      })
  }

  render () {
    const { classes } = this.props
    const { promo, error } = this.state
    return (
      <Paper className={classes.paper} elevation={0}>
        <BackArrow
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        {promo && <PromocodeForm promo={promo} error={error} onSubmit={this.onSubmit.bind(this)} />}
        {!promo && <div>Loading promocode</div>}
      </Paper>
    )
  }
}

EditPromocode.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchPromocodeFunction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  updatePromocodeFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchPromocodeFunction: function (promoId) {
      return dispatch(fetchPromocode(promoId))
    },
    updatePromocodeFunction: function (promoId, promocodeData) {
      return dispatch(updatePromocode(promoId, promocodeData))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(EditPromocode))
