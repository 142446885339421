import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  BackArrow,
  SmallArrow
} from '../common/SVG'
import { commonStyles, history } from '../../helpers'
import classNames from 'classnames'
import styled from 'styled-components'
import curationBefore from '../../assets/img/curation-before.jpg'
import curationAfter from '../../assets/img/curation-after.jpg'

/* eslint-disable sort-keys */
const styles = {
  curationContainer: {
    marginTop: -40,
    ...commonStyles.media(575, {
      marginTop: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 0
    })
  },
  curationHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  curationTitle: {
    margin: 0,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '72px'
    })
  },
  backArrow: {
    color: '#333',
    cursor: 'pointer',
    position: 'absolute',
    left: 5,
    top: 25
  },
  infoHeader: {
    marginTop: 30,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: '0.5px'
    })
  },
  infoText: {
    marginTop: 10,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px'
    })
  },
  beforeAfterContainer: {
    position: 'relative',
    marginTop: 40
  },
  afterImage: {
    width: '100%'
  },
  smallArrow: {
    position: 'absolute',
    left: 27,
    top: 17,
    width: 17,
    height: 16,
    '&._left': {
      left: 6,
      transform: 'rotate(180deg)'
    }
  },
  beforeAfterText: {
    ...commonStyles.text({
      color: '#ffffff',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px',
      position: 'absolute',
      bottom: 6,
      '&._before': {
        left: 15
      },
      '&._after': {
        right: 15
      }
    })
  },
  gradient: {
    position: 'absolute',
    left: 0,
    top: 'calc(100% - 50px)',
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 95%)'
  }
}
/* eslint-enable sort-keys */

const BeforeOverlayImage = styled.div.attrs(props => ({ right: 100 - props.sliderPercent }))`
  position: absolute;
  left: 0;
  top: 0;
  right: ${props => props.right}%;
  bottom: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${curationBefore});
`

// On Safari, using an image for the overlay causes a lot of flickering, so this filter is used instead.
// The values have been tweaked to make it look like curation-before.jpg.
// However, this means that if curation-before.jpg ever changes, the values in this filter will also
// have to be updated to match the new image.
const BeforeOverlayFilter = styled.div.attrs(props => ({ right: 100 - props.sliderPercent }))`
  position: absolute;
  left: 0;
  top: 0;
  right: ${props => props.right}%;
  bottom: 4px;
  backdrop-filter: blur(1px) saturate(58%) contrast(90%) brightness(95%);
  -webkit-backdrop-filter: blur(1px) saturate(58%) contrast(90%) brightness(95%);
`

const Divider = styled.div`
  position: absolute;
  left: calc(${props => props.sliderPercent}% - 1px);
  top: 0;
  bottom: 4px;
  width: 2px;
  background-color: #ffffff;
`

const Slider = styled.div`
  position: absolute;
  left: calc(${props => props.sliderPercent}% - 25px);
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%);
  cursor: pointer;
`

const TextContainer = styled.div.attrs(props => ({ right: 100 - props.rightPercent }))`
  position: absolute;
  left: ${props => props.leftPercent}%;
  top: 0;
  right: ${props => props.right}%;
  bottom: 4px;
  overflow: hidden;
`

class CurationBeforeAfter extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      sliderPercent: 50
    }
    this.beforeAfterRef = React.createRef()

    this.onSliderDrag = e => {
      e.preventDefault()
      this.setSliderX(e.clientX)
    }
    this.onSliderTouchDrag = e => {
      e.preventDefault()
      e.stopPropagation()
      this.setSliderX(e.touches[0].clientX)
    }
    this.endSliderDrag = e => {
      e.preventDefault()
      window.removeEventListener('mousemove', this.onSliderDrag)
      window.removeEventListener('mouseup', this.endSliderDrag)
    }
    this.endSliderTouchDrag = e => {
      e.preventDefault()
      e.stopPropagation()
      window.removeEventListener('touchmove', this.onSliderTouchDrag)
      window.removeEventListener('touchend', this.endSliderTouchDrag)
    }
  }

  setSliderX (newX) {
    const element = this.beforeAfterRef.current
    if (element) {
      const boundingRect = element.getBoundingClientRect()
      let x = newX - boundingRect.left
      if (x < 25) {
        x = 25
      }
      if (x > boundingRect.width - 25) {
        x = boundingRect.width - 25
      }
      this.setState({ sliderPercent: (x * 100) / boundingRect.width })
    }
  }

  goBack () {
    history.goBack()
  }

  startSliderDrag (e) {
    e.preventDefault()
    window.addEventListener('mousemove', this.onSliderDrag)
    window.addEventListener('mouseup', this.endSliderDrag)
  }

  startSliderTouchDrag (e) {
    e.preventDefault()
    e.stopPropagation()
    window.addEventListener('touchmove', this.onSliderTouchDrag)
    window.addEventListener('touchend', this.endSliderTouchDrag)
  }

  render () {
    const { classes } = this.props
    const { sliderPercent } = this.state
    const isSafari = !!window.safari
    const iOSSafari = !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !!navigator.userAgent.match(/AppleWebKit/)
    const useFilterOverlay = isSafari || iOSSafari
    return (
      <div className={classes.curationContainer}>
        <div className={classes.curationHeader}>
          <BackArrow className={classes.backArrow} onClick={this.goBack} />
          <h2 className={classes.curationTitle}>Curation</h2>
          <div className={classes.infoHeader}>
            Before & After
          </div>
          <div className={classes.infoText}>
            Supply us with your not-so-perfect files and we will happily enhance them
          </div>
        </div>
        <div className={classes.beforeAfterContainer} ref={this.beforeAfterRef}>
          <img src={curationAfter} alt="" className={classes.afterImage} />
          {useFilterOverlay && <BeforeOverlayFilter sliderPercent={sliderPercent} />}
          {!useFilterOverlay && <BeforeOverlayImage sliderPercent={sliderPercent} />}
          <TextContainer leftPercent={0} rightPercent={sliderPercent}>
            <div className={classes.gradient} />
            <div className={classNames(classes.beforeAfterText, '_before')}>Before</div>
          </TextContainer>
          <TextContainer leftPercent={sliderPercent} rightPercent={100}>
            <div className={classes.gradient} />
            <div className={classNames(classes.beforeAfterText, '_after')}>After</div>
          </TextContainer>
          <Divider sliderPercent={sliderPercent} />
          <Slider
            sliderPercent={sliderPercent}
            onMouseDown={this.startSliderDrag.bind(this)}
            onTouchStart={this.startSliderTouchDrag.bind(this)}
          >
            <SmallArrow className={classNames(classes.smallArrow, '_left')} />
            <SmallArrow className={classes.smallArrow} />
          </Slider>
        </div>
      </div>
    )
  }
}
CurationBeforeAfter.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CurationBeforeAfter)
