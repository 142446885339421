import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { commonStyles } from '../../helpers'
import UrlifeButton from './UrlifeButton'
import UrlifeTextField from './UrlifeTextField'
import DateAndTimePicker from './DateAndTimePicker'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

/* eslint-disable sort-keys */
const styles = {
  scheduleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formWrapper: {
    width: 460,
    margin: 'auto',
    ...commonStyles.media(575, {
      width: 350
    })
  },
  inputBlock: {
    width: '100%',
    display: 'block',
    paddingBottom: 80
  },
  text: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    marginTop: -20,
    marginBottom: 35
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  nextButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    },
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  button: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  errorText: {
    margin: 'auto',
    marginBottom: 10,
    fontSize: 12,
    height: 12,
    textAlign: 'center',
    color: '#d70101'
  }
}
/* eslint-enable sort-keys */

class ScheduleCallForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phone: props.phoneNumber,
      phoneIsValid: this.phoneNumberIsValid(props.phoneNumber),
      phoneNumberFocus: true,
      showCancelButton: props.isBooked
    }
  }

  phoneNumberIsValid (phone) {
    const parsedNumber = parsePhoneNumberFromString(phone, 'US')
    return parsedNumber ? parsedNumber.isPossible() : false
  }

  dateValid (valid) {
    if (!valid) {
      const {
        updateProject
      } = this.props
      updateProject && updateProject({}, { madeAppointment: false })
    }
  }

  handleChangeInputPhone (e) {
    // 'US' means it will parse it as a US number by default.
    // If the user enters a country code the number will be parsed accordingly
    const parsedNumber = parsePhoneNumberFromString(e.target.value, 'US')
    const isValid = parsedNumber ? parsedNumber.isPossible() : false
    this.setState({
      phone: e.target.value,
      phoneIsValid: isValid
    })
  }

  async scheduleCall () {
    const {
      onDone,
      appointmentDate,
      updateProjectSku
    } = this.props
    const { phone } = this.state
    const onDoneSkuData = onDone && await onDone()
    let skuUpdateData = { appointment: appointmentDate, phoneNumber: phone }
    if (onDoneSkuData) {
      skuUpdateData = {
        ...skuUpdateData,
        ...onDoneSkuData
      }
    }

    await updateProjectSku(skuUpdateData)
  }

  async cancelCall () {
    const {
      onCancelBooking,
      updateProjectSku
    } = this.props
    const { phone } = this.state
    await updateProjectSku({ appointment: null, phoneNumber: phone })
    onCancelBooking && onCancelBooking()
  }

  onSubmit (e) {
    e.preventDefault()
  }

  handleBlur () {
    const { setPhoneNumber } = this.props
    const { phone } = this.state
    this.setState({ phoneNumberFocus: false })
    setPhoneNumber && setPhoneNumber(phone)
  }

  handleFocus () {
    this.setState({ phoneNumberFocus: true })
  }

  render () {
    const { classes, appointmentDate, setAppointmentDate } = this.props
    const { phone, phoneIsValid, phoneNumberFocus, showCancelButton } = this.state
    return (
      <div className={classes.scheduleContainer}>
        <div className={classes.formWrapper}>
          <form onSubmit={this.onSubmit.bind(this)}>
            <div className={classes.inputBlock}>
              <UrlifeTextField
                id="input"
                autoFocus
                onBlur={this.handleBlur.bind(this)}
                onFocus={this.handleFocus.bind(this)}
                label={'Phone number'}
                onChange={this.handleChangeInputPhone.bind(this)}
                defaultValue={phone}
                infoLabel={
                  phoneIsValid
                    ? 'We keep your private information safe and secure'
                    : 'Please enter a valid phone number'
                }
                validationStatus={phone.length === 0 && phoneNumberFocus ? 'none' : phoneIsValid ? 'success' : 'error'}
              />
            </div>
          </form>
          <div className={classes.text}>Please let us know a good time for our producer to call</div>
          <DateAndTimePicker
            defaultTimeDate={appointmentDate}
            dateValid={this.dateValid.bind(this)}
            setAppointmentDate={setAppointmentDate}
          />
          <p className={classes.errorText}>
            {
              !phoneIsValid
                ? 'Please enter a valid phone number'
                : !appointmentDate
                    ? 'Please select date and time'
                    : ' '
            }
          </p>
          <div className={classes.buttonBlock}>
            {showCancelButton &&
              <UrlifeButton
                id='skip'
                inverted
                className={classes.button}
                onClick={() => this.cancelCall()}>
                Cancel call
              </UrlifeButton>
            }
            <UrlifeButton
              className={classes.nextButton}
              disabled={!phoneIsValid || !appointmentDate}
              onClick={() => this.scheduleCall()}>
              Done
            </UrlifeButton>
          </div>
        </div>
      </div>
    )
  }
}
ScheduleCallForm.propTypes = {
  appointmentDate: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isBooked: PropTypes.bool,
  onCancelBooking: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  setAppointmentDate: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func,
  updateProject: PropTypes.func,
  updateProjectSku: PropTypes.func.isRequired
}

export default withStyles(styles)(ScheduleCallForm)
