import { ZIP_WEBSOCKET_ENDPOINT } from '../constants'

export {
  closeWebSocket,
  openWebSocket,
  sendWebSocketCommand
}

let webSocket = null
let keepAliveTimeoutId = null

function closeWebSocket () {
  if (webSocket) {
    webSocket.close()
  }
}

function keepAliveWebSocket () {
  keepAliveTimeoutId = null
  if (webSocket) {
    sendWebSocketCommand({ action: 'ping' })
    keepAliveTimeoutId = window.setTimeout(keepAliveWebSocket, 9 * 60 * 1000)
  }
}

function openWebSocket (onEvent) {
  if (!webSocket) {
    const auth = JSON.parse(window.localStorage.getItem('auth'))
    webSocket = new window.WebSocket(ZIP_WEBSOCKET_ENDPOINT, ['urlife-token', auth.access_token])
  }

  if (!webSocket) {
    throw new Error('Failed to create web socket')
  }

  keepAliveTimeoutId = window.setTimeout(keepAliveWebSocket, 9 * 60 * 1000)

  webSocket.onopen = (event) => {
    /* eslint-disable-next-line no-console */
    console.debug('Web socket onopen: ', event)
    onEvent(event.type)
  }

  webSocket.onmessage = (event) => {
    /* eslint-disable-next-line no-console */
    console.debug('Web socket onmessage: ', event)
    onEvent(event.type, JSON.parse(event.data))
  }

  webSocket.onclose = (event) => {
    /* eslint-disable-next-line no-console */
    console.debug('Web socket onclose: ', event)
    onEvent(event.type, { code: event.code, reason: event.reason })
    webSocket = null
    if (keepAliveTimeoutId) {
      window.clearTimeout(keepAliveTimeoutId)
      keepAliveTimeoutId = null
    }
  }

  webSocket.onerror = (event) => {
    /* eslint-disable-next-line no-console */
    console.debug('Web socket onerror: ', event)
    onEvent(event.type)
  }
}

function sendWebSocketCommand (body) {
  if (!webSocket) {
    throw new Error('Web socket is not open')
  }
  /* eslint-disable-next-line no-console */
  console.debug('Send web socket command: ', body)
  webSocket.send(JSON.stringify(body))
}
