import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeTextField from '../../common/UrlifeTextField'
import { commonStyles } from '../../../helpers/index'
import {
  createCoupon
} from '../../../actions'
import { BackArrow } from '../../common/SVG/index'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  userTitleText: {
    marginRight: 10
  },
  userName: {
    marginRight: 4
  },
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formRow: {
    display: 'block'
  },
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  switch: {
    margin: '5px 0px 10px 0px'
  },
  switchText: {
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: 500
  },
  expiration: {
    margin: '5px 0px'
  },
  addButton: {
    width: 100,
    margin: '10px auto 0pc auto'
  },
  underline: {
    '&:before': {
      borderBottomColor: '#666666'
    },
    '&:after': {
      borderBottomColor: '#000000'
    },
    '&._success': {
      '&:before': {
        borderBottomColor: '#01b7d7'
      },
      '&:after': {
        borderBottomColor: '#01b7d7'
      }
    },
    '&._error': {
      '&:before': {
        borderBottomColor: '#d70101'
      },
      '&:after': {
        borderBottomColor: '#d70101'
      }
    }
  },
  errorBox: {
    margin: '10px 0px 0px 0px',
    color: '#d70101',
    opacity: 0
  }
}
/* eslint-enable sort-keys */

class CouponForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allSkus: [],
      coupon: {
        amount_off: '',
        description: '',
        expiration: '',
        percent_off: '',
        skus: [],
        title: ''
      },
      couponType: false,
      error: false,
      formValid: false,
      selectAll: false,
      user: null
    }
    this.handleChangeValue = this.handleChangeValue.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  formValidator () {
    const { coupon } = this.state
    const { title, skus } = coupon

    const amount = parseFloat(coupon.amount_off)
    const percent = parseInt(coupon.percent_off, 10)

    const isTitleValid = title.trim().length > 0
    const isSkusValid = skus.length > 0
    const isPercent = percent > 0 && percent <= 100
    const isAmount = amount > 0

    if (isTitleValid && isSkusValid && (isAmount || isPercent)) {
      this.setState({ formValid: true })
    } else if (this.state.formValid) {
      this.setState({ formValid: false })
    }
  }

  onBackClick () {
    this.props.handleCloseForm()
  }

  handleChangeSwitch (event) {
    const { coupon } = this.state
    coupon.amount_off = ''
    coupon.percent_off = ''
    this.setState({ coupon, couponType: !this.state.couponType }, () => {
      this.formValidator()
    })
  }

  handleChangeValue (event) {
    const coupon = this.state.coupon
    const value = event.target.value
    const name = event.target.name
    this.setState({
      coupon: {
        ...coupon,
        [name]: value
      }
    }, () => {
      this.formValidator()
    })
  };

  handleCheckBox (event) {
    const { allSkus } = this.state
    const { skus } = this.state.coupon

    if (event.target.checked) {
      skus.push(event.target.name)
      const selectAll = skus.length === allSkus.length
      this.setState({
        coupon: {
          ...this.state.coupon,
          skus
        },
        selectAll
      }, () => {
        this.formValidator()
      })
    } else {
      const newArr = skus.filter(e => e !== event.target.name)
      this.setState({
        coupon: {
          ...this.state.coupon,
          skus: newArr
        },
        selectAll: false
      }, () => {
        this.formValidator()
      })
    }
  }

  handleSelectAll (event) {
    const { coupon, allSkus } = this.state
    if (event.target.checked) {
      this.setState({
        coupon: {
          ...coupon,
          skus: allSkus
        },
        selectAll: true
      }, () => {
        this.formValidator()
      })
    } else {
      this.setState({
        coupon: {
          ...coupon,
          skus: []
        },
        selectAll: false
      }, () => {
        this.formValidator()
      })
    }
  }

  handleSubmit (event) {
    const { dispatch, user, handleCloseForm } = this.props
    const { coupon } = this.state
    const amountOff = coupon.amount_off
    const percentOff = coupon.percent_off
    coupon.amount_off = parseFloat(amountOff)
    coupon.percent_off = parseInt(percentOff, 10)
    if (coupon.amount_off === 0) {
      delete coupon.amount_off
    } else {
      coupon.amount_off = Math.round(coupon.amount_off * 100)
    }
    if (coupon.percent_off === 0) {
      delete coupon.percent_off
    }
    if (coupon.expiration.length === 0) {
      delete coupon.expiration
    }
    user.id && dispatch(createCoupon(user.id, coupon))
      .then(
        response => {
          if (response.status === 'success') {
            handleCloseForm()
          } else {
            this.setState({ error: true })
          }
        }
      )
  }

  componentDidMount () {
    if (this.props.packages) {
      const allSkus = []
      this.props.packages.forEach(pack => {
        pack.skus.forEach(sku => {
          allSkus.push(sku.id)
        })
      })
      this.setState({ allSkus })
    }
  }

  render () {
    const { classes, packages, user } = this.props
    const { coupon, formValid, couponType, error, selectAll } = this.state
    const skus = coupon.skus
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <BackArrow
            className={classes.backButton}
            onClick={this.onBackClick.bind(this)}
          />
          <div className={classes.formWrapper}>
            <Typography variant="h5" component="h3">
              <span className={classes.userTitleText}>Add coupon to user:</span>
              {user && <span className={classes.userName}>{user.fullname}</span>}
            </Typography>
            <form className={classes.container}>
              <div
                className={classes.errorBox}
                style={error ? { opacity: 1 } : {}}
              >
                Something went wrong when creating the coupon.
              </div>
              <UrlifeTextField
                id="coupon-title"
                name="title"
                label="Title"
                autoFocus
                onChange={ e => this.handleChangeValue(e)}
                placeholder={'Necessary to proceed'}
                defaultValue={''}
              />
              <UrlifeTextField
                id="coupon-description"
                label="Description"
                name="description"
                onChange={ e => this.handleChangeValue(e)}
                defaultValue={''}
              />
              <FormControl className={classes.formRow}>
                <Grid
                  className={classes.switch}
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={classes.switchText}>Percent off</Grid>
                  <Grid item>
                    <Switch
                      checked={this.state.couponType}
                      onChange={this.handleChangeSwitch.bind(this)}
                      name="couponType"
                    />
                  </Grid>
                  <Grid item className={classes.switchText}>Amount off</Grid>
                </Grid>
              </FormControl>
              {couponType
                ? (
                <FormControl className={classes.formRow}>
                  <Input
                    id="coupon-amount"
                    name="amount_off"
                    value={this.state.coupon.amount_off}
                    onChange={ e => this.handleChangeValue(e)}
                    fullWidth
                    placeholder={'Necessary to proceed'}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    classes={
                      {
                        underline: classes.underline
                      }
                    }
                  />
                </FormControl>
                  )
                : (
                <FormControl className={classes.formRow}>
                  <Input
                    id="coupon-percent"
                    name="percent_off"
                    value={this.state.coupon.percent_off}
                    onChange={ e => this.handleChangeValue(e)}
                    fullWidth
                    placeholder={'Necessary to proceed'}
                    endAdornment={<InputAdornment position="start">%</InputAdornment>}
                    classes={
                      {
                        underline: classes.underline
                      }
                    }
                  />
                </FormControl>
                  )}
              <UrlifeTextField
                id="coupon-expiration"
                name="expiration"
                label="Expiration date"
                type="date"
                autoFocus
                onChange={ e => this.handleChangeValue(e)}
                defaultValue={coupon.expiration}
                InputLabelProps={{ shrink: true }}
                className={`${classes.textField} ${classes.expiration}`}
              />
              <FormControl className={classes.formRow}>
                <FormLabel component="legend">{"SKU's (at least one must be selected)"}</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={e => this.handleSelectAll(e)} name={'selectAll'}
                      />}
                    label={'Select All'}
                  />
                  {packages.map((pack, idxPack) => {
                    return (
                      pack.skus.map((sku, idxSku) => {
                        return (
                          <FormControlLabel
                            key={`${idxPack}${idxSku}`}
                            className={classes.checkBox}
                            control={
                              <Checkbox
                                checked={skus && skus.includes(sku.id)}
                                onChange={e => this.handleCheckBox(e)} name={sku.id}
                              />}
                            label={`${pack.title}, ${sku.description} ${sku.price}`}
                          />
                        )
                      })
                    )
                  })}
                </FormGroup>
              </FormControl>
              <UrlifeButton
                disabled={!formValid}
                className={classes.addButton}
                onClick={ e => this.handleSubmit(e)}
              >
                Add
              </UrlifeButton>
            </form>
          </div>
        </Paper>
      </div>
    )
  }
}

CouponForm.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CouponForm))
