import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import { connect } from 'react-redux'
import { commonStyles, history } from '../../helpers/index'
import { ROLES } from '../../constants'
import ToolbarLoggedOut from './topBarComponents/ToolbarLoggedOut'
import ToolbarUser from './topBarComponents/ToolbarUser'
import ToolbarProject from './topBarComponents/ToolbarProject'
import ToolbarProducer from './topBarComponents/ToolbarProducer'
import ToolbarAdmin from './topBarComponents/ToolbarAdmin'
import overlayBackground from './../../assets/img/cliffs-in-sea.jpg'

const AppBarBackground = styled.div(props => ({
  '&::before': {
    backgroundImage: `
      linear-gradient(
        135deg,
        ${props.gradientStartColor} 20%,
        ${props.gradientEndColor} 80%
      )
    `
  }
}))

/* eslint-disable sort-keys */
const styles = {
  root: {
    flexGrow: 1,
    position: 'fixed',
    transform: 'translate3d(0,0,0)',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: 'transparent',
    '&._home': {
      zIndex: 0
    }
  },
  appBar: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    background: 'none',
    boxShadow: 'none'
  },
  appBarBackground: {
    position: 'absolute',
    top: 0,
    background: 'none',
    boxShadow: 'none',
    '&::before': {
      position: 'absolute',
      zindex: -200,
      content: "''",
      display: 'block',
      width: '140vw',
      left: '-20vw',
      borderRadius: '0 0 500% 500%',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)'
    },
    '&._home': {
      '&::before': {
        height: '512px',
        width: '220vw',
        left: '-60vw'
      }
    },
    '&._overlay': {
      opacity: 0,
      background: 'none',
      boxShadow: 'none',
      '&._home': {
        opacity: 0.15,
        '&::before': {
          backgroundImage: `url(${overlayBackground})`,
          backgroundPosition: '44% 60%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '130vw',
          ...commonStyles.media(575, {
            backgroundPosition: '44% 50%',
            backgroundSize: '230vw'
          }),
          ...commonStyles.mediaDimensions(896, 414, {
            backgroundPosition: '44% 50%',
            backgroundSize: '230vw'
          })
        }
      }
    },
    ...commonStyles.media(575, {
      '&::before': {
        width: '160vw',
        left: '-30vw'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      '&::before': {
        width: '160vw',
        left: '-30vw'
      }
    })
  },
  appBarSizeMenu: {
    height: 116,
    '&::before': {
      height: '116px'
    },
    ...commonStyles.media(575, {
      height: 90,
      '&::before': {
        height: '90px'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 90,
      '&::before': {
        height: '90px'
      }
    })
  },
  appBarSizeDoubleLine: {
    height: 136,
    '&::before': {
      height: '136px'
    },
    ...commonStyles.media(575, {
      height: 108,
      '&::before': {
        height: '108px'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 108,
      '&::before': {
        height: '108px'
      }
    })
  }
}
/* eslint-enable sort-keys */

class UrlifeTopBar extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      headerIsDoubleLine: false
    }
  }

  setHeaderSize (isDouble) {
    this.setState({ headerIsDoubleLine: isDouble })
  }

  isCreateProject () {
    return history.location.pathname.startsWith('/createmovie')
  }

  isProjectReview () {
    if (history.location.pathname.match(/\/projects\/.*\/movie-.*/i)) {
      return true
    } else {
      const useProjectTopBar = this.props.topBarType === 'project'
      return !!(useProjectTopBar && history.location.pathname.match(/\/projects\/.*\/movie/i))
    }
  }

  isProjectSummary () {
    const useProjectTopBar = this.props.topBarType === 'project'
    return !!(useProjectTopBar && history.location.pathname.match(/\/projects\/.*\/summary/i))
  }

  isProjectCheckout () {
    const useProjectTopBar = this.props.topBarType === 'project'
    return !!(useProjectTopBar && history.location.pathname.match(/\/projects\/.*\/checkout/i))
  }

  hasProjectTopBar () {
    return (
      this.isCreateProject() ||
      this.isProjectReview() ||
      this.isProjectSummary() ||
      this.isProjectCheckout()
    )
  }

  skinnedTopBar () {
    const {
      skinData
    } = this.props
    return (
      !!skinData && (
        this.hasProjectTopBar() ||
        !!history.location.pathname.match(/\/projects\/.*\/movie/i) ||
        !!history.location.pathname.match(/\/projects\/.*\/summary/i) ||
        !!history.location.pathname.match(/\/projects\/.*\/checkout/i) ||
        !!history.location.pathname.match(/\/projects\/.*\/movie-schedule-call/i)
      )
    )
  }

  render () {
    const {
      classes,
      roleId,
      skinData
    } = this.props
    const { headerIsDoubleLine } = this.state
    const isLoggedIn = this.props.loggedIn
    const isProducer = roleId && roleId === ROLES.PRODUCER
    const isEditor = roleId && roleId === ROLES.EDITOR
    const isUser = roleId && roleId === ROLES.USER
    const isAdmin = roleId && roleId === ROLES.ADMIN
    const isHomeDashboard = history.location.pathname === '/home'
    const pathPrefixesToHide = ['/celebritycruises', '/silversea']
    const hideButtons = pathPrefixesToHide.some(prefix => history.location.pathname.startsWith(prefix))
    const hasProjectTopBar = this.hasProjectTopBar()
    let appBarSizeClass = classes.appBarSizeMenu
    if (hasProjectTopBar && headerIsDoubleLine) {
      appBarSizeClass = classes.appBarSizeDoubleLine
    }
    const gradientStartColor = this.skinnedTopBar() ? skinData.gradientStartColor : '#3d29b1'
    const gradientEndColor = this.skinnedTopBar() ? skinData.gradientEndColor : '#bd6ad3'
    return (
      <div className={classNames(classes.root, { _home: isHomeDashboard })}>
        <AppBarBackground
          position="sticky"
          gradientStartColor={gradientStartColor}
          gradientEndColor={gradientEndColor}
          className={classNames(classes.appBarBackground, appBarSizeClass, { _home: isHomeDashboard }, 'mui-fixed')}
          elevation={0} />
        <div
          position="sticky"
          className={
            classNames(
              classes.appBarBackground,
              appBarSizeClass,
              '_overlay',
              { _home: isHomeDashboard },
              'mui-fixed'
            )
          }
          elevation={0} />
        <AppBar
          position="sticky"
          className={classNames(classes.appBar, appBarSizeClass, 'mui-fixed')}
          elevation={0}>
          {!isLoggedIn && <ToolbarLoggedOut hideButtons={hideButtons} />}
          {isLoggedIn && <React.Fragment>
            {!hasProjectTopBar && <React.Fragment>
              {isUser && <ToolbarUser hideButtons={hideButtons}/>}
              {(isProducer || isEditor) && <ToolbarProducer />}
              {isAdmin && <ToolbarAdmin />}
            </React.Fragment>}
            {hasProjectTopBar &&
            <ToolbarProject
              setHeaderSize={this.setHeaderSize.bind(this)}
              isCreateProject={this.isCreateProject()}
              isProjectSummary={this.isProjectSummary()}
              isProjectCheckout={this.isProjectCheckout()}
            />
            }
          </React.Fragment>}
        </AppBar>
      </div>
    )
  }
}
UrlifeTopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  roleId: PropTypes.number,
  skinData: PropTypes.object,
  topBarType: PropTypes.string
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn,
    roleId: state.user.roleId,
    skinData: state.skinning.skinData,
    topBarType: state.topBar.type
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(UrlifeTopBar))
)
