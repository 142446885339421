import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ParallaxPlayIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 71 71" >
      <g><g clipPath="url(#clip-1714BEBD-0A42-4B42-A323-42C2D11B617E)"><g><g transform="rotate(-180 35.5 35.5)"><path d="M66.669,35.50788c0,1.08947 0.88277,1.97224 1.97221,1.97224c1.08947,0 1.97224,-0.88277 1.97224,-1.97224c0,-19.35697 -15.7486,-35.10555 -35.10557,-35.10555c-19.35697,0 -35.10555,15.74858 -35.10555,35.10555c0,19.35697 15.74858,35.10557 35.10555,35.10557c16.44677,0 30.51265,-11.17699 34.20543,-27.1796c0.24535,-1.06183 -0.41653,-2.12053 -1.47758,-2.36508c-1.06578,-0.24691 -2.12053,0.41653 -2.36508,1.47758c-3.27783,14.20317 -15.76359,24.12265 -30.36277,24.12265c-17.182,0 -31.1611,-13.97912 -31.1611,-31.16112c0,-17.182 13.9791,-31.1611 31.1611,-31.1611c17.182,0 31.16112,13.9791 31.16112,31.1611z" fill="#ffffff" fillOpacity="1"></path></g></g><g><path d="M28.93943,20.70414c-0.50886,-0.27841 -1.04713,-0.27841 -1.55779,0c-0.50886,0.27839 -0.73298,0.7934 -0.73298,1.35021v27.64863c0,0.55681 0.22412,1.07182 0.7348,1.35021c0.25534,0.13921 0.49416,0.20881 0.77887,0.20881c0.28471,0 0.54561,-0.0696 0.80093,-0.20881l20.5346,-13.82429c0.50883,-0.27841 0.81747,-0.79342 0.81747,-1.35023c0,-0.55681 -0.31964,-1.07182 -0.82848,-1.35023z" fill="#ffffff" fillOpacity="1"></path></g></g></g>
    </SvgIcon>
  )
}
