import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TopMenuMore (props) {
  return (
    <SvgIcon {...props} viewBox="14 14 30 10">
      <g><g transform="rotate(-270 29 19.5)"><g><path strokeMiterlimit="50" strokeWidth="1.56" d="M29 13.25c1.841 0 3.333-1.4 3.333-3.125C32.333 8.399 30.841 7 29 7c-1.84 0-3.333 1.4-3.333 3.125 0 1.726 1.492 3.125 3.333 3.125z"/></g><g transform="rotate(-150 29 19.5)"><path strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" strokeWidth="1.56" d="M29 22.625c1.841 0 3.333-1.4 3.333-3.125 0-1.726-1.492-3.125-3.333-3.125-1.84 0-3.333 1.4-3.333 3.125 0 .614.188 1.186.514 1.669"/></g><g><path strokeMiterlimit="50" strokeWidth="1.56" d="M29 32c1.841 0 3.333-1.4 3.333-3.125 0-1.726-1.492-3.125-3.333-3.125-1.84 0-3.333 1.4-3.333 3.125C25.667 30.601 27.159 32 29 32z"/></g></g></g>
    </SvgIcon>
  )
}
