import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function StarOutline (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g><g transform="rotate(36 12 12)"><path d="M11.998 23.16a.682.682 0 0 1-.563-.296L7.471 17.08.745 15.098a.682.682 0 0 1-.348-1.07L4.674 8.47 4.48 1.46A.682.682 0 0 1 5.39.8l6.607 2.349 6.607-2.35a.683.683 0 0 1 .91.661l-.2 7.252a.691.691 0 0 1-.7.663.682.682 0 0 1-.663-.7l.172-6.257-5.898 2.097a.681.681 0 0 1-.457 0L5.872 2.418l.172 6.257a.681.681 0 0 1-.142.435L2.085 14.07l6.004 1.77c.15.044.281.139.37.268l3.539 5.164 3.539-5.164a.682.682 0 0 1 .37-.269l6.004-1.769-2.822-3.667a.682.682 0 1 1 1.08-.832l3.429 4.456a.681.681 0 0 1-.348 1.07l-6.726 1.982-3.964 5.784a.682.682 0 0 1-.562.297z" /></g></g>
    </SvgIcon>
  )
}
