import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function AudioIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 100 100' style={{ enableBackground: 'new 0 0 100 100' }}>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M31,32.3V7.9c0-1.2-0.8-2.2-2-2.2c-1.2,0-2,1-2,2.2v24.5c-3,0.9-5.4,3.8-5.4,7.1c0,4.1,3.3,7.5,7.4,7.5    c4.1,0,7.4-3.3,7.4-7.5C36.4,36.1,34,33.3,31,32.3z M28.9,42.5c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1    c1.7,0,3.1,1.4,3.1,3.1C32,41.2,30.6,42.5,28.9,42.5z"></path>
          <path d="M29,53.1c-1.2,0-2,1-2,2.2v36.9c0,1.2,0.8,2.2,2,2.2c1.2,0,2-1,2-2.2V55.3C31,54.1,30.2,53.1,29,53.1z"></path>
          <path d="M52,53.4V7.9c0-1.2-0.8-2.2-2-2.2s-2,1-2,2.2v45.5c-3,0.9-5.4,3.8-5.4,7.1c0,4.1,3.3,7.5,7.4,7.5c4.1,0,7.3-3.3,7.3-7.5    C57.4,57.2,55,54.3,52,53.4z M50,63.6c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1S51.7,63.6,50,63.6z"></path>
          <path d="M50,74.1c-1.2,0-2,1-2,2.2v15.8c0,1.2,0.8,2.2,2,2.2s2-1,2-2.2V76.3C52,75.1,51.2,74.1,50,74.1z"></path>
          <path d="M73,16.5V7.9c0-1.2-0.8-2.2-2-2.2s-2,1-2,2.2v8.7c-3,0.9-5.3,3.8-5.3,7.1c0,4.1,3.3,7.5,7.4,7.5c4.1,0,7.3-3.3,7.3-7.5    C78.4,20.3,76,17.5,73,16.5z M71.1,26.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1    C74.1,25.4,72.8,26.7,71.1,26.7z"></path>
          <path d="M71,37.3c-1.2,0-2,1-2,2.2v52.7c0,1.2,0.8,2.2,2,2.2s2-1,2-2.2V39.5C73,38.3,72.2,37.3,71,37.3z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M85,25.7c0.1,0,0.1-0.2,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1    c0.2-0.2,0.5-0.4,0.6-0.6c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2    c0-0.1,0.1-0.3,0.1-0.4c0,0,0,0,0,0l0,0c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2    c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3l-18-18c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1-0.1-0.2-0.1    c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.3,0-0.4,0H25.3C20.3,3,16,7,16,11.9v76.7    c0,4.9,4.3,9.4,9.3,9.4h52.7c4.9,0,9.1-4.5,9.1-9.4V39c0-1.2-1.3-2.3-2.5-2.3c-1.2,0-2.5,1-2.5,2.3v49.6c0,2.4-1.7,4.4-4.1,4.4    H25.3c-2.4,0-4.3-2-4.3-4.4V11.9C21,9.5,22.8,8,25.3,8H64v8.6c0,4.9,4.3,9.4,9.3,9.4h11.3C84.7,26,84.9,25.7,85,25.7z M69,16.6    v-5.9L79.1,21h-5.9C70.8,21,69,19,69,16.6z"></path>
          <path d="M30.6,26h24c1.2,0,2.3-1.3,2.3-2.5c0-1.2-1-2.5-2.3-2.5h-24c-1.2,0-2.3,1.3-2.3,2.5C28.3,24.7,29.3,26,30.6,26z"></path>
          <path d="M74.8,35.5c0-1.2-1-2.5-2.3-2.5h-42c-1.2,0-2.3,1.3-2.3,2.5c0,1.2,1,2.5,2.3,2.5h42C73.8,38,74.8,36.7,74.8,35.5z"></path>
          <path d="M30.6,50h30c1.2,0,2.3-1.3,2.3-2.5c0-1.2-1-2.5-2.3-2.5h-30c-1.2,0-2.3,1.3-2.3,2.5C28.3,48.7,29.3,50,30.6,50z"></path>
          <path d="M68.8,59.5c0-1.2-1-2.5-2.3-2.5h-36c-1.2,0-2.3,1.3-2.3,2.5c0,1.2,1,2.5,2.3,2.5h36C67.8,62,68.8,60.7,68.8,59.5z"></path>
          <path d="M62.8,71.5c0-1.2-1-2.5-2.3-2.5h-30c-1.2,0-2.3,1.3-2.3,2.5s1,2.5,2.3,2.5h30C61.8,74,62.8,72.7,62.8,71.5z"></path>
          <path d="M30.6,81c-1.2,0-2.3,1.3-2.3,2.5c0,1.2,1,2.5,2.3,2.5h12c1.2,0,2.3-1.3,2.3-2.5c0-1.2-1-2.5-2.3-2.5H30.6z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M84.1,25.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.2-0.2,0.5-0.4,0.6-0.6    c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.5,0.1-0.5h0c0,0,0,0.2,0,0.2    c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2    c-0.1-0.1-0.2-0.2-0.3-0.3L67.4,3.7c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1    c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1C66.1,3.1,66,3,65.8,3l0,0h-41C19.9,3,16,7.1,16,11.9v76.1c0,4.9,3.9,8.9,8.8,8.9    h52.3c4.9,0,8.9-4.1,8.9-8.9V38.8c0-1.2-1.3-2.2-2.5-2.2c-1.2,0-2.5,1-2.5,2.2v49.2c0,2.4-1.5,3.9-3.9,3.9H24.8    c-2.4,0-4.8-1.5-4.8-3.9V11.9C20,9.5,22.4,8,24.8,8H64v8.6c0,4.9,3.6,8.4,8.4,8.4h11.2C83.8,25,84,25.2,84.1,25.2z M68,16.6v-5.8    L78.3,21h-5.8C70,21,68,19,68,16.6z"></path>
          <path d="M30.1,25h23.8c1.2,0,2.2-0.8,2.2-2c0-1.2-1-2-2.2-2H30.1c-1.2,0-2.2,0.8-2.2,2C27.9,24.2,28.9,25,30.1,25z"></path>
          <path d="M71.8,33H30.1c-1.2,0-2.2,0.8-2.2,2s1,2,2.2,2h41.7c1.2,0,2.2-0.8,2.2-2S73,33,71.8,33z"></path>
          <path d="M71.8,45H30.1c-1.2,0-2.1,0.8-2.1,2v35.7c0,0.1-0.1,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2    c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.2,0.2,0.4,0.5,0.6,0.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1    c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.3,0,0.4,0h41.7c1.2,0,2.2-1,2.2-2.2V47C74,45.8,73,45,71.8,45z M66.8,81    L53.3,67.3c0.2,0.1,0.4,0,0.6,0c0.6,0,1.1-0.3,1.6-0.7l4.6-4.4l9.9,9.6V81H66.8z M48,68.5L60.4,81H35.5L48,68.5z M61.7,57.3    c-0.4-0.4-1.1-0.7-1.7-0.7c-0.6,0-1.2,0.2-1.6,0.7l-6,6c-0.6,0.6-0.8,1.5-0.6,2.2l-2.2-2.2c-0.9-0.9-2.5-0.9-3.3,0L32,77.3V49h38    v16.4L61.7,57.3z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M50,31.4c7.6,0,14.5,4.7,17.3,11.8c0.5,1.2,1.8,1.8,3,1.3c1.2-0.5,1.8-1.8,1.3-3C68.2,32.6,59.5,26.7,50,26.7    c-12.8,0-23.3,10.5-23.3,23.3c0,12.8,10.5,23.3,23.3,23.3c9.6,0,18.1-5.7,21.6-14.6c0.5-1.2-0.1-2.5-1.3-3c-1.2-0.5-2.5,0.1-3,1.3    c-2.8,7-9.8,11.7-17.3,11.7c-10.3,0-18.6-8.4-18.6-18.6C31.4,39.7,39.7,31.4,50,31.4z"></path>
          <path d="M16.7,13.4c-0.9-0.9-2.4-0.9-3.3,0c-0.9,0.9-0.9,2.4,0,3.3l7,7c0.5,0.5,1.1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7    c0.9-0.9,0.9-2.4,0-3.3L16.7,13.4z"></path>
          <path d="M78,24.4c0.6,0,1.2-0.2,1.6-0.7l7-7c0.9-0.9,0.9-2.4,0-3.3c-0.9-0.9-2.4-0.9-3.3,0l-7,7c-0.9,0.9-0.9,2.4,0,3.3    C76.8,24.1,77.4,24.4,78,24.4z"></path>
          <path d="M20.4,76.3l-7,7c-0.9,0.9-0.9,2.4,0,3.3c0.5,0.5,1.1,0.7,1.6,0.7s1.2-0.2,1.6-0.7l7-7c0.9-0.9,0.9-2.4,0-3.3    C22.8,75.4,21.3,75.4,20.4,76.3z"></path>
          <path d="M79.6,76.3c-0.9-0.9-2.4-0.9-3.3,0c-0.9,0.9-0.9,2.4,0,3.3l7,7c0.5,0.5,1.1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7    c0.9-0.9,0.9-2.4,0-3.3L79.6,76.3z"></path>
          <path d="M17.4,50c0-1.3-1-2-2.3-2H5.2c-1.3,0-2.3,0.7-2.3,2c0,1.3,1,2,2.3,2H15C16.3,52,17.4,51.3,17.4,50z"></path>
          <path d="M50,82.6c-1.3,0-2,1-2,2.3v9.9c0,1.3,0.7,2.3,2,2.3c1.3,0,2-1,2-2.3V85C52,83.7,51.3,82.6,50,82.6z"></path>
          <path d="M50,17.4c1.3,0,2-1,2-2.3V5.2c0-1.3-0.7-2.3-2-2.3c-1.3,0-2,1-2,2.3V15C48,16.3,48.7,17.4,50,17.4z"></path>
          <path d="M94.8,48H85c-1.3,0-2.3,0.7-2.3,2c0,1.3,1,2,2.3,2h9.9c1.3,0,2.3-0.7,2.3-2C97.2,48.7,96.1,48,94.8,48z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M68.6,48h-10c-1.3,0-2.4,1.2-2.4,2.5c0,1.3,1.1,2.5,2.4,2.5h10c1.3,0,2.4-1.2,2.4-2.5C71,49.2,69.9,48,68.6,48z"></path>
          <path d="M66.9,59.3c-1.1-0.7-2.6-0.3-3.2,0.8c-3.4,5.8-9.6,9.3-16.3,9.3c-10.4,0-18.9-8.5-18.9-18.9c0-10.4,8.5-18.9,18.9-18.9    c6.5,0,12.6,3.4,16,8.9c0.7,1.1,2.2,1.4,3.3,0.7c1.1-0.7,1.4-2.2,0.8-3.3C63,31,55.5,26.8,47.3,26.8c-13,0-23.7,10.6-23.7,23.7    c0,13.1,10.6,23.7,23.7,23.7c8.3,0,16.2-4.5,20.4-11.7C68.4,61.4,68,59.9,66.9,59.3z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M90.4,28.9L73.9,12.4c-2.7-2.7-7-2.7-9.7,0L11.5,65.1c-2.7,2.7-2.7,7,0,9.7L28,91.3c0.3,0.3,0.7,0.4,1.1,0.4    s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L13.6,72.7c-1.5-1.5-1.5-4,0-5.5l1.2-1.2l11,11c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1    l-11-11l3.9-3.9l5,5c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1l-5-5l3.9-3.9l11,11c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1l-11-11l3.9-3.9    l5,5c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1l-5-5l3.9-3.9l11,11c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1l-11-11l3.9-3.9l5,5    c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-5-5l3.9-3.9l11,11c0.6,0.6,1.5,0.6,2.1,0s0.6-1.5,0-2.1l-11-11l3.9-3.9l5,5    c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1l-5-5l3.9-3.9l11,11c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4    c0.6-0.6,0.6-1.5,0-2.1l-11-11l1.2-1.2c1.5-1.5,4-1.5,5.5,0L88.3,31c0.7,0.7,1.1,1.7,1.1,2.7c0,1-0.4,2-1.1,2.7L40,84.7    c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l48.2-48.2C93.1,35.9,93.1,31.6,90.4,28.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M85.5,24.1c-0.6-0.6-1.5-0.6-2.1,0s-0.6,1.5,0,2.1c0.7,0.7,1,1.6,1,2.5c0,0.9-0.4,1.8-1,2.4c0,0,0-0.1-0.1-0.1l-5.7-5.7    c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l5.7,5.7c0,0,0.1,0,0.1,0.1L40.2,74.5L28.6,62.9l43.2-43.2c0.6-0.6,0.6-1.5,0-2.1    c-0.6-0.6-1.5-0.6-2.1,0L25.4,61.8c0,0,0,0,0,0c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0,0,0.1c0,0,0,0,0,0.1l-6.8,20.5c0,0,0,0,0,0    c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0    c0,0.1,0.1,0.3,0.2,0.4c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0,0,0,0    c0.1,0,0.3,0.1,0.5,0.1s0.3,0,0.5-0.1c0,0,0,0,0,0L40.6,78c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0    l44.3-44.3c1.2-1.2,1.9-2.9,1.9-4.6C87.4,27,86.8,25.3,85.5,24.1z M22,81.1l5.1-15.4l10.2,10.2L22,81.1z"></path>
          <path d="M69.9,31.1L52.8,48.2c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4L72,33.2c0.6-0.6,0.6-1.5,0-2.1    S70.5,30.5,69.9,31.1z"></path>
          <path d="M47.1,53.9l-5.7,5.7c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l5.7-5.7c0.6-0.6,0.6-1.5,0-2.1    S47.7,53.3,47.1,53.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M92.3,48.2C91.6,47.2,76,23.5,49,23.5c-27,0-42.6,23.7-43.3,24.7L5.2,49l0.5,0.8C6.4,50.8,22,74.5,49,74.5    c27,0,42.6-23.7,43.3-24.7l0.5-0.8L92.3,48.2z M49,71.5C26.4,71.5,11.8,53.1,8.8,49c3-4.1,17.5-22.5,40.2-22.5    c22.6,0,37.2,18.4,40.2,22.5C86.2,53.1,71.7,71.5,49,71.5z"></path>
          <path d="M60.9,46.9c-0.8,0-1.5,0.8-1.4,1.6c0,0.2,0,0.4,0,0.5c0,5.8-4.7,10.5-10.5,10.5c-5.8,0-10.5-4.7-10.5-10.5    c0-5.8,4.7-10.5,10.5-10.5c1.9,0,3.8,0.5,5.5,1.6c0.7,0.4,1.6,0.2,2.1-0.5s0.2-1.6-0.5-2.1c-2.1-1.3-4.6-2-7.1-2    c-7.4,0-13.5,6.1-13.5,13.5c0,7.4,6.1,13.5,13.5,13.5c7.4,0,13.5-6.1,13.5-13.5c0-0.2,0-0.5,0-0.7C62.4,47.5,61.7,46.9,60.9,46.9z    "></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M84.1,84.2L59.5,59.6c-0.8-0.8-2.1-0.8-2.9,0s-0.8,2.1,0,2.9l24.7,24.7c0.4,0.4,0.9,0.6,1.5,0.6c0.5,0,1.1-0.2,1.5-0.6    C85,86.3,85,85,84.1,84.2z"></path>
          <path d="M39.5,19.9C52,19.9,62.1,30,62.1,42.5c0,3.2-0.6,6.2-1.9,9.1c-0.5,1,0,2.3,1.1,2.7c1,0.5,2.3,0,2.7-1.1    c1.5-3.4,2.3-7,2.3-10.8c0-14.7-12-26.7-26.7-26.7c-14.7,0-26.7,12-26.7,26.7c0,14.7,12,26.7,26.7,26.7c3.7,0,7.3-0.8,10.8-2.3    c1-0.5,1.5-1.7,1.1-2.7c-0.5-1-1.7-1.5-2.7-1.1c-2.9,1.3-5.9,1.9-9.1,1.9C27,65.1,16.9,55,16.9,42.5C16.9,30,27,19.9,39.5,19.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M49,12.4c-17.2,0-31.2,14-31.2,31.2c0,16.8,28.4,43.9,29.6,45l1.7,1.6l1.7-1.6c1.2-1.1,29.6-28.2,29.6-45    C80.2,26.4,66.2,12.4,49,12.4z M49,83.5C40.3,75,22.6,55.1,22.6,43.6C22.6,29,34.4,17.2,49,17.2c14.6,0,26.4,11.9,26.4,26.4    C75.4,55,57.7,75,49,83.5z"></path>
          <path d="M63.1,39.8c-1.3,0.1-2.3,1.3-2.1,2.6c0,0.4,0.1,0.8,0.1,1.2c0,6.6-5.4,12-12,12s-12-5.4-12-12c0-6.6,5.4-12,12-12    c2.5,0,4.8,0.7,6.8,2.1c1.1,0.8,2.6,0.5,3.3-0.6c0.8-1.1,0.5-2.6-0.6-3.3c-2.8-2-6.1-3-9.6-3c-9.3,0-16.8,7.5-16.8,16.8    c0,9.3,7.5,16.8,16.8,16.8c9.3,0,16.8-7.5,16.8-16.8c0-0.6,0-1.1-0.1-1.7C65.6,40.6,64.4,39.6,63.1,39.8z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M52.6,67.6L37.8,82.4c-4.5,4.5-11.9,4.5-16.5,0c-4.5-4.5-4.5-11.9,0-16.5l14.8-14.8c0.9-0.9,0.9-2.4,0-3.3s-2.4-0.9-3.3,0    L18,62.7c-6.4,6.4-6.4,16.7,0,23c3.2,3.2,7.3,4.8,11.5,4.8s8.3-1.6,11.5-4.8l14.8-14.8c0.9-0.9,0.9-2.4,0-3.3S53.5,66.7,52.6,67.6    z"></path>
          <path d="M85.5,18.3c-6.4-6.4-16.7-6.4-23,0L47.6,33.1c-0.9,0.9-0.9,2.4,0,3.3c0.9,0.9,2.4,0.9,3.3,0l14.8-14.8    c4.5-4.5,11.9-4.5,16.5,0c4.5,4.5,4.5,11.9,0,16.5L67.4,52.8c-0.9,0.9-0.9,2.4,0,3.3c0.5,0.5,1.1,0.7,1.6,0.7s1.2-0.2,1.6-0.7    l14.8-14.8C91.8,35,91.8,24.6,85.5,18.3z"></path>
          <path d="M32.8,70.9c0.5,0.5,1.1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7l34.6-34.6c0.9-0.9,0.9-2.4,0-3.3c-0.9-0.9-2.4-0.9-3.3,0    L32.8,67.6C31.9,68.5,31.9,70,32.8,70.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M52,64.4c6.8,0,12.4-5.6,12.4-12.4c0-6.8-5.6-12.4-12.4-12.4c-1.1,0-2,0.9-2,2s0.9,2,2,2c4.6,0,8.4,3.8,8.4,8.4    c0,4.6-3.8,8.4-8.4,8.4c-4.6,0-8.4-3.8-8.4-8.4c0-2.2,0.9-4.3,2.4-5.9c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0    c-2.3,2.3-3.5,5.4-3.5,8.7C39.6,58.8,45.2,64.4,52,64.4z"></path>
          <path d="M93.6,50h-8.5c-0.5-8-3.5-14.6-8.3-20l5.2-5.2c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L74,27.2c-5.4-4.8-12-7.9-20-8.3    v-8.5c0-1.1-0.9-2-2-2s-2,0.9-2,2v23.8c0,1.1,0.9,2,2,2s2-0.9,2-2V22.8c7,0.4,12.5,3.1,17.2,7.2l-5,5c-0.8,0.8-0.8,2,0,2.8    c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l5-5c4.1,4.7,6.7,10.2,7.2,17.2H69.8c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h11.3    c-0.4,7-3.1,12.5-7.2,17.2l-5-5c-0.8-0.8-2-0.8-2.8,0s-0.8,2,0,2.8l5,5C66.5,78.1,61,80.7,54,81.2V69.8c0-1.1-0.9-2-2-2    s-2,0.9-2,2v11.3c-7-0.4-12.5-3.1-17.2-7.2l5-5c0.8-0.8,0.8-2,0-2.8s-2-0.8-2.8,0l-5,5C25.9,66.5,23.3,61,22.8,54h11.3    c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H22.8c0.4-6,2.9-12.4,7.1-17.2l5,5c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6    c0.8-0.8,0.8-2,0-2.8l-13-13c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l5.2,5.2c-5,5.6-7.9,13-8.3,20h-8.5c-1.1,0-2,0.9-2,2    c0,1.1,0.9,2,2,2h8.5c0.5,8,3.5,14.6,8.3,20L22,79.2c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l5.2-5.2    c5.4,4.8,12,7.9,20,8.3v8.5c0,1.1,0.9,2,2,2s2-0.9,2-2v-8.5c8-0.5,14.6-3.5,20-8.3l5.2,5.2c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6    c0.8-0.8,0.8-2,0-2.8L76.8,74c4.8-5.4,7.9-12,8.3-20h8.5c1.1,0,2-0.9,2-2C95.6,50.9,94.7,50,93.6,50z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M90,47c-1.2,0-2,0.9-2,2.1v19.6c0,1.9-1.9,3.3-3.8,3.3H19.8c-1.9,0-3.8-1.4-3.8-3.3V49.1c0-1.2-0.8-2.1-2-2.1    s-2,0.9-2,2.1v19.6c0,4.2,3.6,7.3,7.8,7.3h64.3c4.2,0,7.8-3.1,7.8-7.3V49.1C92,47.9,91.2,47,90,47z"></path>
          <path d="M52,63.8c1.2,0,2-0.9,2-2.1V35.3l9.1,9c0.4,0.4,1,0.6,1.5,0.6s1.1-0.2,1.5-0.6c0.8-0.8,0.8-2.1,0-3L53.5,28.7    c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1    c-0.3-0.1-0.6-0.1-0.8,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1    c-0.2,0.2-0.4,0.4-0.6,0.6c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2    c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0v31.5C50,62.8,50.8,63.8,52,63.8z"></path>
          <path d="M39.4,44.9c0.5,0,1.1-0.2,1.5-0.6l6.3-6.3c0.8-0.8,0.8-2.1,0-3c-0.8-0.8-2.1-0.8-3,0l-6.3,6.3c-0.8,0.8-0.8,2.1,0,3    C38.3,44.7,38.9,44.9,39.4,44.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M90,47c-1.2,0-2,0.9-2,2.1v19.6c0,1.9-1.8,3.3-3.8,3.3H19.8c-1.9,0-3.8-1.4-3.8-3.3V49.1c0-1.2-0.8-2.1-2-2.1    s-2,0.9-2,2.1v19.6c0,4.2,3.5,7.3,7.8,7.3h64.4c4.2,0,7.8-3,7.8-7.3V49.1C92,47.9,91.2,47,90,47z"></path>
          <path d="M44.2,56.9c0.4,0.4,0.9,0.6,1.5,0.6s1.1-0.2,1.5-0.6c0.8-0.8,0.8-2.2,0-3l-6.3-6.3c-0.8-0.8-2.2-0.8-3,0    c-0.8,0.8-0.8,2.2,0,3L44.2,56.9z"></path>
          <path d="M49.9,62.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2    c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1    c0.1,0,0.3,0,0.4,0s0.3,0,0.4,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1    c0.1-0.1,0.2-0.2,0.3-0.3l12.6-12.6c0.8-0.8,0.8-2.2,0-3c-0.8-0.8-2.2-0.8-3,0l-9.1,9V30.2c0-1.2-0.8-2.1-2-2.1s-2,0.9-2,2.1v31.5    c0,0,0,0,0,0C50,61.9,49.9,62,49.9,62.1z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M85.9,33H68c-1.7,0-3-0.9-3-2.6V22H45.8c-3.7,0-6.8,3.4-6.8,7.2v1.2c0,1.7-1.3,2.6-3,2.6H18.1c-1,0-1.9,1-1.9,2    s0.8,2,1.9,2H36c3.7,0,7-2.9,7-6.6v-1.2c0-1.7,1.1-3.2,2.8-3.2H61v4.4c0,3.7,3.3,6.6,7,6.6h17.9c1.7,0,3.1,1.6,3.1,3.3v34.6    c0,1.7-1.4,3.2-3.1,3.2H18.1c-1.7,0-3.1-1.4-3.1-3.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3V46.4c0-1-1-1.9-2-1.9s-2,0.8-2,1.9v27.8    c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.4,0,0.5c0,3.7,3.1,7,6.9,7h67.9c3.7,0,7.1-3.4,7.1-7.2V40.3C93,36.5,89.7,33,85.9,33z"></path>
          <path d="M42.8,51.1c-0.9-0.4-2-0.1-2.5,0.9c-0.8,1.7-1.3,3.7-1.3,5.6c0,7.1,5.8,13,13,13c7.1,0,13-5.8,13-13c0-7.1-5.8-13-13-13    c-2.2,0-4.3,0.5-6.2,1.6c-0.9,0.5-1.2,1.6-0.7,2.5c0.5,0.9,1.6,1.2,2.5,0.7c1.4-0.7,2.9-1.1,4.4-1.1c5.1,0,9.3,4.2,9.3,9.3    s-4.2,9.3-9.3,9.3s-9.3-4.2-9.3-9.3c0-1.4,0.3-2.8,0.9-4C44.1,52.6,43.7,51.5,42.8,51.1z"></path>
          <path d="M68.7,48h11.1c1,0,1.9-0.5,1.9-1.5S80.8,45,79.8,45H68.7c-1,0-1.9,0.5-1.9,1.5S67.6,48,68.7,48z"></path>
          <path d="M68.7,59h11.1c1,0,1.9-0.5,1.9-1.5S80.8,56,79.8,56H68.7c-1,0-1.9,0.5-1.9,1.5S67.6,59,68.7,59z"></path>
          <path d="M68.7,71h5.6c1,0,1.9-1,1.9-2s-0.8-2-1.9-2h-5.6c-1,0-1.9,1-1.9,2S67.6,71,68.7,71z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M93.3,83.2l-5.2-47c-0.4-4-3.8-7.2-7.9-7.2h-6.7C70.7,18,62.6,9.6,53,9.6C43.4,9.6,35.3,18,32.5,29h-6.7    c-1.2,0-2.2,1.3-2.2,2.5c0,1.2,1,2.5,2.2,2.5h5.9c-0.2,1-0.3,2.7-0.3,4.2c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-0.9,2.2-2.1    c0-1.5,0.1-3.2,0.3-4.2H70c0.2,1,0.3,2.7,0.3,4.2c0,1.2,1,2.1,2.2,2.1s2.2-0.9,2.2-2.1c0-1.5-0.1-3.2-0.3-4.2h5.9    c1.8,0,3.4,1.2,3.6,3.1L89,83.8c0.1,1-0.2,2.1-0.9,2.9c-0.7,0.8-1.6,1.3-2.7,1.3H20.6c-1,0-2-0.6-2.7-1.4c-0.7-0.8-1-1.8-0.9-2.7    l5.8-32.5c0.2-1.2-0.6-2.3-1.7-2.5c-1.2-0.2-2.3,0.6-2.5,1.7l-5.8,32.6c-0.2,2.2,0.5,4.5,2,6.1c1.5,1.7,3.6,2.6,5.9,2.6h64.8    c2.2,0,4.4-1,5.9-2.6C92.8,87.7,93.5,85.4,93.3,83.2z M36.9,29c2.5-8,8.8-15.1,16.1-15.1c7.3,0,13.6,7.1,16.1,15.1H36.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M86.6,18.4l-18.4-4.6c-1.2-0.3-2.4-0.1-3.4,0.5c-1-0.6-2.2-0.8-3.4-0.5l-18.5,4.6c-0.7,0.2-1.3,0.5-1.8,1    c-0.5-0.4-1.1-0.8-1.8-1l-18.5-4.6c-1.4-0.3-2.8,0-4,0.8c-1.1,0.9-1.8,2.2-1.8,3.6c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2    c0-0.3,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.2,0.6-0.1l18.7,4.6c0.3,0.1,0.7,0.3,0.7,0.6v43.3c-6-0.8-8.1-5.6-8.1-15.7c0-1.1-1-2-2.1-2    s-1.8,0.9-1.8,2c0,5.1,0,18.4,12,19.7v12.6c0,0.3-0.3,0.4-0.4,0.5s-0.4,0.2-0.7,0.1l-18.4-4.6c-0.3-0.1-0.5-0.3-0.5-0.6V32.7    c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v45.5c0,2.1,1.4,4,3.4,4.5l18.4,4.6c0.4,0.1,0.7,0.1,1.1,0.1c1,0,2-0.3,2.8-1    c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.8,0.6,1.8,1,2.8,1c0.4,0,0.8,0,1.1-0.1l18.5-4.6c0.4-0.1,0.8-0.3,1.2-0.5    c0.4,0.2,0.8,0.4,1.2,0.5l18.5,4.6c0.4,0.1,0.8,0.1,1.1,0.1c1,0,2-0.3,2.8-1c1.1-0.9,1.7-2.2,1.7-3.6V22.9    C90,20.7,88.6,18.9,86.6,18.4z M43.6,22.4L62.2,18h0c0.1,0-0.1-0.2,0-0.2c0,0.2-0.3,0.2-0.3,0.5v18.7c-6,1.2-11.1,6.9-11.1,13.6    c0,7.5-2.9,14-7.9,15.5V22.9C43,22.6,43.3,22.5,43.6,22.4z M44.1,83.4c-0.3,0.1-0.6,0-0.7-0.1C43.3,83.2,43,83,43,82.8V70.1    c7-1.3,11.8-9.4,11.8-19.6c0-4.5,3.2-8.4,7.2-9.5v37.2c0,0.2,0.2,0.4,0.3,0.7L44.1,83.4z M86,82.8c0,0.3-0.1,0.4-0.2,0.5    c-0.1,0.1-0.3,0.2-0.5,0.1l-18.4-4.6c0-0.2,0.1-0.4,0.1-0.7V41c4,1,6.3,3.9,7.1,7.5L73,47.4c-0.8-0.8-2-0.8-2.8,0    c-0.8,0.8-0.8,2,0,2.8l3.1,3.1l-3.5,2.8c-0.8,0.7-1,1.9-0.3,2.8c0.4,0.5,1,0.7,1.5,0.7c0.4,0,0.9-0.1,1.2-0.4l3.8-3.1l3.4,3.4    c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8l-3.1-3.1l3.5-2.8c0.8-0.7,1-1.9,0.3-2.8c-0.7-0.8-1.9-1-2.8-0.3    l-2,1.6C77.7,43.1,73,38.1,67,36.9V18.2c0-0.2,0-0.4,0-0.7c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0l18.4,4.6c0.3,0.1,0.4,0.3,0.4,0.6    V82.8z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M66.2,36.6c1.2-2.7,2.1-5.5,2.1-8.2c0-10.1-6.7-18.3-15.1-18.3c-8.4,0-15.1,8.2-15.1,18.3c0,3,0.6,5.7,1.5,8.1    C31.1,38.7,25,46.5,25,55.3v24.4c0,1.2,0.8,2.2,2,2.2s2-1,2-2.2V55.3c0-7.6,6.1-14.2,13.9-15l3.3-0.3l-1.8-2.9    c-1.4-2.3-2.1-5.3-2.1-8.6c0-7.7,4.9-14,10.8-14c6,0,10.8,6.3,10.8,14c0,3.5-1.5,6.7-2.7,8.6L59.4,40l3.4,0.3    c7.6,0.7,13.2,7.2,13.2,15v24.4c0,1.2,0.8,2.2,2,2.2s2-1,2-2.2V55.3C80,46.3,74.4,38.8,66.2,36.6z"></path>
          <path d="M38.8,81.7l-6.5,6.5c-0.8,0.8-0.8,2.2,0,3c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6l6.5-6.5c0.8-0.8,0.8-2.2,0-3    C41,80.9,39.6,80.9,38.8,81.7z"></path>
          <path d="M53,81.1c-1.2,0-2,1-2,2.2v6.5c0,1.2,0.8,2.2,2,2.2s2-1,2-2.2v-6.5C55,82.1,54.2,81.1,53,81.1z"></path>
          <path d="M67.6,81.7c-0.8-0.8-2.2-0.8-3,0c-0.8,0.8-0.8,2.2,0,3l6.5,6.5c0.4,0.4,1,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6    c0.8-0.8,0.8-2.2,0-3L67.6,81.7z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M87.5,52H38.6c-1-7-7.2-12.5-14.7-12.5c-8.2,0-14.8,6.7-14.8,14.9c0,8.2,6.7,14.9,14.8,14.9c0.9,0,1.9-0.1,2.8-0.2    c1.1-0.2,1.9-1.3,1.7-2.5c-0.2-1.2-1.3-1.9-2.5-1.7c-0.6,0.1-1.3,0.2-2,0.2c-5.8,0-10.6-4.8-10.6-10.6c0-5.8,4.8-10.6,10.6-10.6    c5.8,0,10.6,4.8,10.6,10.6c0,2.2-0.7,4.4-2,6.2c-0.7,0.9-0.5,2.3,0.5,3c0.9,0.7,2.3,0.7,3-0.3c1.4-2,2.3-4.4,2.7-6.4H60v6    c0,1.2,0.8,2.1,2,2.1c1.2,0,2-0.9,2-2.1v-6h2v10.2c0,1.2,1.3,2.1,2.5,2.1c1.2,0,2.5-0.9,2.5-2.1V57h2v6c0,1.2,0.8,2.1,2,2.1    c1.2,0,2-0.9,2-2.1v-6h2v10.2c0,1.2,0.8,2.1,2,2.1c1.2,0,2-0.9,2-2.1V57h4.5c1.2,0,2.1-1.3,2.1-2.5C89.6,53.3,88.7,52,87.5,52z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M71.8,40.2c-2.3,0-4.5,0.5-6.4,1.4L43.9,23c0.9-1.6,1.4-3.4,1.4-5.4c0-6.1-5-11.1-11.1-11.1c-0.5,0-1,0-1.4,0.1    c-1.3,0.2-2.2,1.3-2,2.6c0.2,1.3,1.3,2.2,2.6,2c0.3,0,0.6-0.1,0.8-0.1c3.6,0,6.5,2.9,6.5,6.5c0,3.6-2.9,6.5-6.5,6.5    c-3.6,0-6.5-2.9-6.5-6.5c0-0.8,0.2-1.7,0.5-2.4c0.5-1.2-0.1-2.5-1.3-3c-1.2-0.5-2.6,0.1-3,1.3c-0.5,1.3-0.8,2.7-0.8,4.2    c0,6.1,5,11.1,11.1,11.1c2.5,0,4.8-0.8,6.7-2.2l20.5,17.8c-3.1,2.8-5.1,6.9-5.1,11.5c0,2.9,0.8,5.7,2.3,8l-23.6,9.9    c-1.9-3.3-5.5-5.5-9.6-5.5c-6.1,0-11.2,5-11.2,11.1c0,6.1,5,11.2,11.2,11.2c6.1,0,11.1-5,11.1-11.2c0-0.4,0-0.8-0.1-1.2l25.2-10.6    c2.7,2.4,6.3,3.8,10.2,3.8c8.6,0,15.6-7,15.6-15.6C87.3,47.2,80.4,40.2,71.8,40.2z M25.3,85.9c-3.6,0-6.5-2.9-6.5-6.5    c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5C31.8,83,28.9,85.9,25.3,85.9z M71.8,66.7c-6,0-10.9-4.9-10.9-10.9    s4.9-10.9,10.9-10.9s10.9,4.9,10.9,10.9S77.8,66.7,71.8,66.7z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M80,20.2c-2.3-2.3-5.6-3-8.6-1.8c0,0,0,0-0.1,0l-21.2,7.8c-1.4,0.5-2.1,2.1-1.6,3.4c0.4,1.1,1.4,1.8,2.5,1.8    c0.3,0,0.6-0.1,0.9-0.2l21.2-7.8c0,0,0,0,0,0c0,0,0,0,0.1,0c1.5-0.6,2.6,0.3,2.9,0.6s1.2,1.4,0.6,2.9L63.8,58.7l-9.1-7    c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0    c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0    c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1    c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3    c0,0.1-0.1,0.1-0.1,0.2l-4.6,16.1l-3.4,1.4l6.7-23.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3    c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.2-0.2    c0-0.1-0.1-0.1-0.1-0.2l-8.3-10.5c0-0.1,0-0.2-0.1-0.2c-0.4-0.9-1.2-1.5-2.2-1.6c-0.8-0.2-1.6,0-2.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.4    c-1.4,0.9-2.5,2.2-3.1,3.7L18.2,71.5c-1.3,3.1-0.6,6.4,1.8,8.8c1.6,1.6,3.6,2.4,5.7,2.4c1,0,2.1-0.2,3.1-0.6l34.5-14.2    c2-0.8,3.6-2.4,4.4-4.4L81.8,29C83.1,25.9,82.4,22.6,80,20.2z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M59.6,12.1c-2.8-1.4-6-1-8.4,1L29.4,30.4c-0.6,0.5-1.4,0.6-2.3,0.6H16c-1.2,0-2.2,1.3-2.2,2.5s1,2.5,2.2,2.5h11.1    c1.8,0,3.6-0.8,5-1.9l21.8-17.5c1.1-0.9,2.6-1.1,3.9-0.5c1.3,0.6,2.1,1.8,2.1,3.3v61.2c0,1.4-0.8,2.7-2.1,3.3    c-1.3,0.6-2.8,0.5-3.9-0.4L32.1,65.9c-1.4-1.1-3.2-1.9-5-1.9H16c-2,0-4-1.4-4-3.4V43.9c0-1.2-0.8-2.2-2-2.2s-2,1-2,2.2v16.7    C8,65,11.6,69,16,69h11.1c0.8,0,1.6,0.1,2.3,0.6L51.2,87c1.5,1.2,3.2,1.7,5,1.7c1.2,0,2.2-0.3,3.4-0.8c2.8-1.4,4.4-4.2,4.4-7.3    V19.4C64,16.3,62.4,13.5,59.6,12.1z"></path>
          <path d="M76.3,37.8c-1.1,0.5-1.5,1.9-1,2.9c1.4,2.9,2.2,6.5,2.2,10.4c0,4-0.9,7.9-2.4,10.8c-0.6,1.1-0.2,2.4,0.9,3    c0.3,0.2,0.7,0.3,1,0.3c0.8,0,1.5-0.4,1.9-1.2c1.9-3.5,2.9-8.1,2.9-12.9c0-4.5-0.9-8.9-2.7-12.3C78.7,37.7,77.4,37.3,76.3,37.8z"></path>
          <path d="M87,26c-0.5-1.1-1.9-1.5-2.9-1c-1.1,0.5-1.5,1.9-1,2.9c3,6,4.6,13.7,4.6,21.7c0,8.4-1.8,16.5-5.1,22.6    c-0.6,1.1-0.2,2.4,0.9,3c0.3,0.2,0.7,0.3,1,0.3c0.8,0,1.5-0.4,1.9-1.2c3.6-6.7,5.6-15.5,5.6-24.6C92.1,41,90.2,32.6,87,26z"></path>
          <path d="M69.2,44.7c-1.1,0.5-1.5,1.9-1,2.9c0.5,1,0.8,2.5,0.8,3.9c0,1.5-0.3,3-0.9,4.1c-0.6,1.1-0.2,2.4,0.9,3    c0.3,0.2,0.7,0.3,1,0.3c0.8,0,1.5-0.4,1.9-1.2c0.9-1.7,1.4-3.9,1.4-6.2c0-2.1-0.5-4.2-1.3-5.9C71.6,44.6,70.3,44.2,69.2,44.7z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M60.4,12.1c-2.8-1.4-6.1-1-8.5,1L30.1,30.4c-0.6,0.5-1.5,0.6-2.3,0.6H16.7c-1.2,0-2.2,1.3-2.2,2.5s1,2.5,2.2,2.5h11.1    c1.8,0,3.6-0.8,5-1.9l21.8-17.5c1.1-0.9,2.8-1.1,4.1-0.5c1.3,0.6,2.3,1.8,2.3,3.3v61.2c0,1.4-1,2.7-2.3,3.3    c-1.3,0.6-2.9,0.5-4-0.4L32.9,65.9c-1.4-1.1-3.3-1.9-5.1-1.9H16.7c-2,0-3.7-1.4-3.7-3.4V43.9c0-1.2-0.8-2.2-2-2.2    c-1.2,0-2,1-2,2.2v16.7C9,65,12.2,69,16.7,69h11.1c0.8,0,1.6,0.1,2.3,0.6L51.9,87c1.5,1.2,3.2,1.7,5,1.7c1.2,0,2.4-0.3,3.5-0.8    c2.8-1.4,4.6-4.2,4.6-7.3V19.4C65,16.3,63.2,13.5,60.4,12.1z"></path>
          <path d="M84.1,52.1l6.3-6.3c0.9-0.9,0.9-2.2,0-3.1c-0.9-0.9-2.2-0.9-3.1,0L81,49l-6.3-6.3c-0.9-0.9-2.2-0.9-3.1,0    c-0.9,0.9-0.9,2.2,0,3.1l6.3,6.3l-6.3,6.3c-0.9,0.9-0.9,2.2,0,3.1c0.4,0.4,1,0.6,1.6,0.6c0.6,0,1.1-0.2,1.6-0.6l6.3-6.3l6.3,6.3    c0.4,0.4,1,0.6,1.6,0.6c0.6,0,1.1-0.2,1.6-0.6c0.9-0.9,0.9-2.2,0-3.1L84.1,52.1z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M89.7,34c-1.3-1.8-3.3-3-5.5-3H38.8c-1,0-1.9,1-1.9,2s0.8,2,1.9,2h45.4c1,0,1.9,0.5,2.5,1.3c0.6,0.8,0.7,1.9,0.4,2.8    l-7.9,23.7c-0.4,1.3-1.6,2.2-2.9,2.2h-34c-1.3,0-2.5-0.9-2.9-2.1L23.8,21.4c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1    c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1    c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1H10.8c-1,0-1.9,1-1.9,2    s0.8,2,1.9,2h9.9l15.1,40.3c0.5,1.4,1.6,2.6,2.8,3.4c-1.1,1.3-1.7,2.9-1.7,4.8c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.2,7.5-7.3    c0-1.4-0.4-2.6-1-3.6h15.1c-0.6,1-1,2.2-1,3.6c0,4.1,3.3,7.4,7.5,7.4s7.5-3.4,7.5-7.5c0-1.5-0.5-3-1.3-4.1    c1.9-0.7,3.5-2.2,4.2-4.3l7.9-23.7C91.4,38.2,91,35.8,89.7,34z M48.1,72.4c0,2.1-1.7,3.7-3.7,3.7c-2.1,0-3.7-1.7-3.7-3.7    c0-2.1,1.7-3.7,3.7-3.7C46.5,68.6,48.1,70.3,48.1,72.4z M72.4,76.1c-2.1,0-3.7-1.7-3.7-3.7c0-2.1,1.7-3.7,3.7-3.7    c2.1,0,3.7,1.7,3.7,3.7C76.1,74.4,74.4,76.1,72.4,76.1z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M33.9,8H17c-4.9,0-9,4-9,9c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1.2,2.2-2.4c0-2.5,2.1-4.8,4.6-4.8h16.9c2.5,0,4.1,2.4,4.1,5    v16.9c0,2.5-1.6,4.1-4.1,4.1H17c-2.5,0-4.6-1.8-4.6-4.4c0-1.2-1-2.1-2.2-2.1C9,31.6,8,32.7,8,33.9c0,4.9,4,9.1,9,9.1h16.9    c4.9,0,9.1-4.2,9.1-9.1V17C43,12,38.8,8,33.9,8z"></path>
          <path d="M33.9,54H17c-4.9,0-9,3.7-9,8.6v16.9c0,4.9,4,8.5,9,8.5h16.9c4.9,0,9.1-3.5,9.1-8.5V62.6C43,57.7,38.8,54,33.9,54z     M38,79.5c0,2.5-1.6,4.5-4.1,4.5H17c-2.5,0-5-1.9-5-4.5V62.6c0-2.5,2.4-4.6,5-4.6h16.9c2.5,0,4.1,2.1,4.1,4.6V79.5z"></path>
          <path d="M62.6,43h16.9c4.9,0,8.5-4.2,8.5-9.1V17c0-4.9-3.5-9-8.5-9H62.6c-4.9,0-8.6,4-8.6,9v16.9C54,38.8,57.7,43,62.6,43z M58,17    c0-2.5,2.1-5,4.6-5h16.9c2.5,0,4.5,2.4,4.5,5v16.9c0,2.5-1.9,4.1-4.5,4.1H62.6c-2.5,0-4.6-1.6-4.6-4.1V17z"></path>
          <path d="M89.4,64.8l-12-12c-1.7-1.7-3.9-2.6-6.3-2.6c-2.4,0-4.6,0.9-6.3,2.6l-12,12c-1.7,1.7-2.6,3.9-2.6,6.3    c0,2.4,0.9,4.6,2.6,6.3l12,12c1.7,1.7,3.9,2.6,6.3,2.6c2.4,0,4.6-0.9,6.3-2.6l12-12c1.7-1.7,2.6-3.9,2.6-6.3    C92,68.7,91.1,66.4,89.4,64.8z M86.3,74.3l-12,12c-1.8,1.8-4.7,1.8-6.5,0l-12-12c-1.8-1.8-1.8-4.7,0-6.5l12-12    c0.9-0.9,2.1-1.3,3.2-1.3c1.2,0,2.3,0.4,3.2,1.3l12,12C88.1,69.6,88.1,72.5,86.3,74.3z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M78.4,40.9L33.7,11.2c-3.4-2.2-7.6-2.4-11.2-0.5c-3.5,1.9-5.8,5.6-5.8,9.6c0,1.6,1.3,3,3,3s3-1.3,3-3c0-1.9,1-3.5,2.6-4.4    c1.6-0.9,3.5-0.8,5.1,0.2l44.7,29.8c1.4,0.9,2.2,2.4,2.2,4.1c0,1.7-0.8,3.2-2.2,4.1L30.4,83.9c-1.5,1-3.3,1.1-4.9,0.2    c-1.6-0.9-2.5-2.5-2.5-4.4V40.8c0-1.6-1.4-3-3-3s-3,1.3-3,3v38.9c0,4,2.1,7.7,5.6,9.6c1.6,0.9,3.3,1.3,5.1,1.3    c2.1,0,4.2-0.6,6-1.8l44.6-29.8c3-2,4.8-5.4,4.8-9.1S81.4,43,78.4,40.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M80,19.3c-1.6,0-3,1.3-3,2.9v19.7c0-0.2-0.4-0.5-0.7-0.7L33.2,12.4c-3.2-2.2-7.3-2.4-10.8-0.5c-3.4,1.8-5.5,5.4-5.5,9.3    c0,1.6,1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9c0-1.8,0.9-3.4,2.5-4.2c1.6-0.8,3.4-0.8,4.9,0.2L73.3,46c1.4,0.9,2.1,2.4,2.1,4    c0,1.6-0.8,3.1-2.1,4L30.1,82.8c-1.5,1-3.2,1.1-4.7,0.2c-1.6-0.8-2.4-2.4-2.4-4.2V41.1c0-1.6-1.4-2.9-3-2.9s-3,1.3-3,2.9v37.7    c0,3.9,2.1,7.5,5.5,9.3c1.6,0.8,3.2,1.2,5,1.2c2,0,4.1-0.6,5.8-1.8l43-28.8c0.3-0.2,0.7-0.4,0.7-0.7v20.7c0,1.6,1.4,2.9,3,2.9    c1.6,0,3-1.3,3-2.9V22.2C83,20.6,81.6,19.3,80,19.3z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M77.6,13c-3.4-1.8-7.4-1.6-10.6,0.5c-0.1,0.1-0.2,0.1-0.3,0.2L37.1,33.5c-1.3,0.9-1.7,2.6-0.8,3.9    c0.5,0.8,1.4,1.3,2.4,1.3c0.5,0,1.1-0.2,1.6-0.5l30-20c0,0,0,0,0,0c1.5-1,3.5-1,5-0.2c1.6,0.8,2.7,2.4,2.7,4.2v56.6    c0,1.8-1.1,3.3-2.7,4.2c-1.6,0.8-3.5,0.7-4.9-0.2L27.8,54.4c-1.3-0.9-2.1-2.3-2.1-3.9c0-1.6,0.8-3,2.1-3.9    c1.3-0.9,1.6-2.6,0.8-3.9c-0.9-1.3-2.5-1.7-3.8-0.8C24.4,42,24,42.3,24,42.5V23.1c0-1.6-1.4-2.8-3-2.8s-3,1.3-3,2.8v55.7    c0,1.6,1.4,2.8,3,2.8s3-1.3,3-2.8V58.5c0,0.2,0.4,0.4,0.7,0.6l42.4,28.3c1.7,1.2,3.7,1.7,5.7,1.7c1.7,0,3.2-0.4,4.8-1.2    c3.4-1.8,5.3-5.3,5.3-9.1V22.1C83,18.3,81,14.8,77.6,13z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M45,20c0-5-4-9-9-9H34c-5,0-9,4-9,9V81c0,5,4,9,9,9H36c5,0,9-4,9-9V20z M40,79.9c0,2.3-1.8,4.1-4.1,4.1h-1.8    c-2.3,0-4.1-1.8-4.1-4.1V19.1c0-2.3,1.8-4.1,4.1-4.1h1.8c2.3,0,4.1,1.8,4.1,4.1V79.9z"></path>
          <path d="M75,20c0-5-4-9-9-9H64c-5,0-9,4-9,9V81c0,5,4,9,9,9H66c5,0,9-4,9-9V20z M70,79.9c0,2.3-1.8,4.1-4.1,4.1h-1.8    c-2.3,0-4.1-1.8-4.1-4.1V19.1c0-2.3,1.8-4.1,4.1-4.1h1.8c2.3,0,4.1,1.8,4.1,4.1V79.9z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M50.5,76.9c7.9,0,14.5-6.4,14.5-14.4V20.1c0-7.9-6.6-14.4-14.5-14.4c-1.2,0-2.3,1-2.3,2.2s0.8,2.2,2,2.2    c5.5,0,9.7,4.4,9.7,9.9v42.5c0,4.7-3,8.6-7,9.7v-17c0-1.2-1.3-2.2-2.5-2.2c-1.2,0-2.5,1-2.5,2.2v17c-4-1-7-4.8-7-9V20.7    c0-1.2-1.3-2.2-2.5-2.2s-2.5,1-2.5,2.2v42.5C36,70.6,42.7,76.9,50.5,76.9z"></path>
          <path d="M74.2,67.6c-1.2,0-2.2,0.7-2.2,1.9c0,5.5-4.4,9.6-9.9,9.6H39.6c-5.5,0-9.9-4.1-9.9-9.6c0-1.2-1-2.1-2.2-2.1    c-1.2,0-2.2,0.5-2.2,1.7C25.2,77,31.7,83,39.6,83H48v10h-3.4c-1.2,0-2.2,0.8-2.2,2s1,2,2.2,2h12.5c1.2,0,2.2-0.8,2.2-2s-1-2-2.2-2    H53V83h9c7.9,0,14.4-5.9,14.4-13.8C76.4,68,75.4,67.6,74.2,67.6z"></path>
        </g>
      </g>
      <g style={{ display: 'none' }}>
        <path style={{ display: 'inline' }} d="M77.1,23.1c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.2-0.2-0.2-0.2c0-0.1-0.2-0.1-0.2-0.2   c0,0,0,0,0,0c0-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0   c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1C77,21.1,77,21,77,21c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0   c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0   c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0   c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L12.8,46.4c0,0,0,0-0.1,0   c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1   c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3   c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0   c0.1,0.2,0.2,0.4,0.3,0.5c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.2l25.2,8.4   l8.4,25.2c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.3,0.6c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.4c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.2   c0,0,0.1,0,0.1,0c0,0,0.2,0,0.3,0c0.2,0.1-0.3,0.1,0.7,0.1v-0.2V86c0,0-0.2,0-0.2,0c0.2,0,0.5-0.1,0.7-0.1c0,0,0,0,0,0   c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0,0   L77.1,23.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0C77,23.1,77.1,23.1,77.1,23.1z M49,77.2l-6.4-19.2l25.6-25.6L49,77.2z"></path>
      </g>
      <g style={{ display: 'none' }}>
        <g style={{ display: 'inline' }}>
          <path d="M19.3,63.1c1.1,0,1.9-0.9,1.9-1.9c0-0.9,0.3-1.7,0.9-2.3l39.5-39.5c0.8-0.8,0.8-2,0-2.7c-0.8-0.8-2-0.8-2.7,0L19.5,56.1    c-1.3,1.3-2.1,3.1-2.1,5C17.4,62.2,18.3,63.1,19.3,63.1z"></path>
          <path d="M86.7,25.3l-9.1-9.1c-0.8-0.8-2-0.8-2.7,0c-0.8,0.8-0.8,2,0,2.7l9.1,9.1c0.6,0.6,0.9,1.4,0.9,2.3c0,0.9-0.3,1.7-0.9,2.3    l-44,44c-0.6,0.6-1.4,0.9-2.3,0.9l-14.9,2.6c-1.7-0.1-3.1-1.5-3.1-3.2c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9    c0,3.9,3.2,7.1,7.1,7.1h0.2l14.7-2.6c1.8,0,3.5-0.8,4.8-2.1l44-44c1.3-1.3,2.1-3.1,2.1-5C88.8,28.5,88,26.7,86.7,25.3z"></path>
          <path d="M38,55.8l5,5c1.3,1.3,3.1,2.1,5,2.1c1.9,0,3.7-0.7,5-2.1l25-25c0.8-0.8,0.8-2,0-2.7c-0.8-0.8-2-0.8-2.7,0l-25,25    c-1.2,1.2-3.3,1.2-4.6,0l-5-5c-1.3-1.3-1.3-3.3,0-4.6L70.3,19c0.8-0.8,0.8-2,0-2.7c-0.8-0.8-2-0.8-2.7,0L38,45.8    C35.2,48.6,35.2,53.1,38,55.8z"></path>
        </g>
      </g>
    </SvgIcon>
  )
}
