import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  FILE_STATUSES,
  PACKAGE_TYPES,
  PROJECT_STAGES,
  PROJECT_STATUSES,
  ROLES
} from '../../../constants'
import { DownloadIcon, PlayIcon } from '../../common/SVG'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import {
  createFinishedMovie,
  createProjectResources,
  downloadErrorMessage,
  downloadFile,
  fetchCuratedFiles, finishedFiles,
  generateZip,
  getMovieFile, getProducerFavoritesTotalSize,
  getProjectFilesTotalSize,
  projectInfo
} from '../../../actions'
import SelectProjectRole from './SelectProjectRole'
import {
  commonStyles,
  getPackageType,
  history,
  humanFileSize,
  mapProjectStatusToStage
} from '../../../helpers'
import { fileProgressStatus } from '../../../reducers/fileProgress'
import AdminActionDialog from '../../admin/AdminActionDialog'
import DropZone from '../../common/DropZone'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeCheckbox from '../../common/UrlifeCheckbox'
import UrlifeConfirmDialog from '../../common/UrlifeConfirmDialog'
import styled from 'styled-components'
import UploadMsg from '../../common/UploadMsg'
import ModalPreview from '../../common/ModalPreview'

/* eslint-disable sort-keys */
const styles = {
  sidebar: {
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: 2,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.07)'
  },
  sidebarSection: {
    padding: '16px 20px',
    '& + &': {
      borderTop: '1px solid #dfdfdf'
    }
  },
  sidebarSectionHeader: {
    fontSize: 18,
    fontWeight: 500,
    color: '#393939',
    lineHeight: '25.45px',
    marginBottom: 9
  },
  button: {
    width: '100%',
    height: 40,
    fontSize: 12,
    marginTop: 10,
    marginBottom: 5
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: '#393939',
    lineHeight: '26px'
  },
  fileActionBlock: {
    display: 'flex',
    cursor: 'pointer',
    margin: '15px 0',
    '&._disabled': {
      cursor: 'default',
      opacity: 0.4
    }
  },
  downloadIcon: {
    width: 22,
    height: 22,
    color: '#01b7d7',
    marginRight: 10,
    '&._upload': {
      transform: 'scale(1, -1)'
    }
  },
  assignedCheckBox: {
    marginLeft: 3
  },
  assignedCheckBoxIcon: {
    width: 26,
    height: 26
  },
  finishedMovieWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  finishedMovieBlock: {
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 8,
    width: '100%',
    paddingTop: '56.25%'
  },
  processingText: {
    margin: '10px auto',
    textAlign: 'center',
    ...commonStyles.text({
      fontSize: 12,
      color: '#323232'
    })
  },
  rejected: {
    fontWeight: 600,
    color: '#f00',
    '& p': {
      margin: 'auto',
      textAlign: 'center'
    },
    '& button': {
      padding: 0,
      background: 'none',
      border: 'none',
      outline: 'none',
      display: 'block',
      cursor: 'pointer',
      margin: '10px auto 20px auto',
      ...commonStyles.text({
        fontSize: 12,
        color: '#5334b6'
      })
    }
  },
  finishedMovieActions: {
    marginLeft: 0,
    marginTop: 15
  },
  finishedMoviePlayIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 16,
    margin: 'auto',
    color: '#ffffff'
  },
  finishedMovieDownloadButton: {
    ...commonStyles.text({
      fontSize: 12,
      color: '#01b7d7'
    }),
    background: 'none',
    border: 'none',
    outline: 'none',
    display: 'block',
    margin: 'auto',
    cursor: 'pointer',
    '&[disabled]': {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  price: {
    color: '#000'
  }
}
/* eslint-enable sort-keys */

const FinishedMovie = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
`

class ProjectSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      approvalInProgress: '', // '', sending, success, fail
      chosenPreview: null,
      error: '',
      errorFileName: '',
      negotiationPriceDialog: false,
      negotiationQueuedDialog: false,
      openModal: false,
      projectFilesTotalSize: 0,
      toProductionDialog: false
    }
    this.timeoutId = null
  }

  downloadFinishedMovie () {
    const { project, finishedMovieFiles, clientDownloadFunction } = this.props
    const finishedMovie = finishedMovieFiles[0] || null
    if (finishedMovie) {
      clientDownloadFunction({
        id: finishedMovie.id,
        name: project.title + '.mp4',
        projectId: project.id,
        role: 'finished',
        url: finishedMovie.signedUrl
      })
        .then(
          success => {},
          error => {
            this.setState({
              error: downloadErrorMessage(error),
              errorFileName: project.title + '.mp4'
            })
          }
        )
    }
  }

  finishedUpload (e) {
    const { project, createMovieFunction } = this.props
    const file = e.target.files[0]
    if (file) {
      createMovieFunction(
        {
          files: [{
            mimeType: file.type,
            originalName: file.name
          }],
          type: 'file'
        },
        file,
        project.id,
        project.projectSkus[0].id,
        this.finishedMovieUpdateLoop.bind(this)
      )
    }
  }

  downloadZip () {
    const { project, generateZipFunction } = this.props
    generateZipFunction(project, ['project'])
  }

  downloadProducerFavorites () {
    const { project, generateZipFunction } = this.props
    generateZipFunction(project, ['project'], ['producerFavorite'])
  }

  cancelToProduction () {
    this.setState({ toProductionDialog: false })
  }

  confirmToProduction () {
    this.approveCompleteVideo()
  }

  approveCompleteVideo () {
    const { updateProjectSku, project } = this.props
    const { toProductionDialog } = this.state
    const nextProjectStatus =
      (project.projectSkus[0].statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL ||
      project.projectSkus[0].statusId === PROJECT_STATUSES.COMPLETE)
        ? PROJECT_STATUSES.PRODUCTION_EDITING
        : PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
    const goingFromComplete = project.projectSkus[0].statusId === PROJECT_STATUSES.COMPLETE
    if (goingFromComplete && nextProjectStatus === PROJECT_STATUSES.PRODUCTION_EDITING && !toProductionDialog) {
      this.setState({ toProductionDialog: true })
    } else {
      this.setState(
        { approvalInProgress: 'sending', toProductionDialog: false },
        async () => {
          try {
            await updateProjectSku({ statusId: nextProjectStatus })
            this.setState({ approvalInProgress: 'success' })
            if (nextProjectStatus !== PROJECT_STATUSES.PRODUCTION_EDITING) {
              history.push('/projects/' + project.id + '/movie')
            }
          } catch (e) {
            this.setState({ approvalInProgress: 'fail' })
          }
        }
      )
    }
  }

  approveCuratedFiles (completeProject) {
    const { updateProjectSku, project } = this.props
    let nextProjectStatus = PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED
    if (completeProject) {
      if (project.projectSkus[0].statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL ||
        project.projectSkus[0].statusId === PROJECT_STATUSES.PRODUCTION_EDITING) {
        nextProjectStatus = PROJECT_STATUSES.COMPLETE
      }
    } else if (project.projectSkus[0].statusId === PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED) {
      nextProjectStatus = PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
    }
    this.setState(
      { approvalInProgress: 'sending' },
      async () => {
        try {
          await updateProjectSku({ statusId: nextProjectStatus })
          this.setState({ approvalInProgress: 'success' })
          if (nextProjectStatus !== PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED) {
            history.push('/projects/' + project.id + '/movie')
          }
        } catch (e) {
          this.setState({ approvalInProgress: 'fail' })
        }
      }
    )
  }

  async assignProject () {
    const { project, userId, updateProjectSku } = this.props
    if (project && project.id) {
      this.setState({ takenStatus: 'taking' })
      try {
        await updateProjectSku({
          id: project.projectSkus[0].id,
          producerId: userId,
          statusId: PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED
        })
        this.setState({ takenStatus: 'success' })
      } catch (e) {
        this.setState({ takenStatus: 'fail' })
      }
    }
  }

  async unassignProject () {
    const { project, updateProjectSku } = this.props
    if (project && project.id) {
      this.setState({ dropStatus: 'dropping' })
      try {
        await updateProjectSku({
          id: project.projectSkus[0].id,
          producerId: null,
          statusId: PROJECT_STATUSES.PROJECT_QUEUED
        })
        this.setState({ dropStatus: 'success' })
      } catch (e) {
        this.setState({ dropStatus: 'fail' })
      }
    }
  }

  onChangeEditor () {
    const { project, projectInfoFunction } = this.props
    projectInfoFunction(project.id)
  }

  openFinishedMoviePopup () {
    const { finishedMovieFiles } = this.props
    finishedMovieFiles && finishedMovieFiles.length > 0 &&
      this.setState({ chosenPreview: this.getFinishedMovieUrl(finishedMovieFiles[0]), openModal: true })
  }

  onCloseFinishedMoviePopup () {
    this.setState({
      chosenPreview: null,
      openModal: false
    })
  }

  getFinishedMovieUrl (finishedMovie) {
    if (finishedMovie.webview) {
      return finishedMovie.webviewCdnUrl
    }
    return finishedMovie.cdnUrl || finishedMovie.signedUrl
  }

  async getFinishedMovie (firstMounted) {
    const { finishedFilesFunction, project } = this.props
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
    if (project) {
      const files = await finishedFilesFunction(project.id, project.projectSkus[0].id)
      if (firstMounted && files && files.data && files.data.length && !files.data[0].webview) {
        this.finishedMovieUpdateLoop()
      }
    }
  }

  async finishedMovieUpdateLoop () {
    const { getMovieFileFunction, project, finishedMovieFiles } = this.props
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
    try {
      const movie = await getMovieFileFunction(project.id, project.projectSkus[0].id, finishedMovieFiles[0].id)
      if (!movie.webview) {
        this.timeoutId = window.setTimeout(this.finishedMovieUpdateLoop.bind(this), 10000)
      }
    } catch (e) {}
  }

  reloadCuratedFiles () {
    const { reloadCuratedFilesFunction, project } = this.props
    if (project) {
      reloadCuratedFilesFunction(project.id)
    }
  }

  uploadResources (e) {
    const { project, createProjectResourcesFunction } = this.props
    const { projectFilesTotalSize } = this.state
    e.preventDefault()
    const files = e.target.files
    const rawFiles = []
    const fileData = {
      files: [],
      type: 'file'
    }
    let addedSize = 0
    for (let i = 0; i < files.length; i++) {
      rawFiles.push({ index: i, object: files[i] })
      fileData.files.push({
        lastModified: files[i].lastModified,
        mimeType: files[i].type,
        originalName: files[i].name
      })
      addedSize += files[i].size
    }
    this.setState({ projectFilesTotalSize: projectFilesTotalSize + addedSize })
    createProjectResourcesFunction(
      fileData,
      project.id,
      rawFiles)
  }

  goToMovie () {
    const { project } = this.props
    history.push(`/projects/${project.id}/movie`)
  }

  closeNegotiationDialog (type) {
    this.setState({ [type]: false })
  }

  openNegotiationDialog (type) {
    this.setState({ [type]: true })
  }

  componentDidMount () {
    const {
      project,
      getProjectFilesTotalSizeFunction,
      getProducerFavoritesTotalSizeFunction
    } = this.props
    this.getFinishedMovie(true)
    this.reloadCuratedFiles()

    getProjectFilesTotalSizeFunction(project.id)
      .then(response => this.setState({
        projectFilesTotalSize: response.totalSize
      }))
    getProducerFavoritesTotalSizeFunction(project.id)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }
  }

  closeErrorPopup () {
    this.setState({ error: '', errorFileName: '' })
  }

  render () {
    const {
      classes,
      roleId,
      project,
      userId,
      fileProgress,
      producerFavoritesTotalSize,
      curatedFiles,
      finishedMovieFiles,
      packages,
      updateProjectSku
    } = this.props
    const {
      approvalInProgress,
      chosenPreview,
      openModal,
      takenStatus,
      dropStatus,
      projectFilesTotalSize,
      negotiationQueuedDialog,
      negotiationPriceDialog,
      toProductionDialog,
      error,
      errorFileName
    } = this.state

    const finishedMovie = finishedMovieFiles[0] || {}
    const uploadingFinishedMovie = fileProgress
      .filter(file => file.projectId === project.id && file.role === 'finished' &&
        [fileProgressStatus.inProgress, fileProgressStatus.inQueue].includes(file.status)
      )
      .length > 0
    const downloadingFinishedMovie = fileProgress
      .filter(file => file.url === finishedMovie.signedUrl &&
        [fileProgressStatus.inProgress, fileProgressStatus.inQueue].includes(file.status))
      .length > 0
    const downloadingZip = fileProgress
      .filter(file => file.projectId === project.id && file.role === 'zip' &&
        [fileProgressStatus.inProgress, fileProgressStatus.inQueue].includes(file.status))
      .length > 0
    const generatingZip = fileProgress
      .filter(file => file.projectId === project.id && file.role === 'generate' &&
        [
          fileProgressStatus.inProgress,
          fileProgressStatus.inQueue,
          fileProgressStatus.toProgress
        ].includes(file.status))
      .length > 0

    const isAdmin = roleId === ROLES.ADMIN
    const isProducer = roleId === ROLES.PRODUCER
    const isEditor = roleId === ROLES.EDITOR
    const isAssignedAsProducer = isProducer && project.projectSkus[0].producerId === userId
    const isAssignedAsEditor = isEditor && project.projectSkus[0].editorId === userId
    const isRejected = finishedMovie &&
      finishedMovie.rejectionReason &&
      finishedMovie.rejectionReason.length > 0
    const isInProduction = project &&
      [
        PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
        PROJECT_STATUSES.PRODUCTION_EDITING,
        PROJECT_STATUSES.PRODUCTION_REVISIONS,
        PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW
      ].includes(project.projectSkus[0].statusId)
    const inPrep = project &&
      mapProjectStatusToStage(project.projectSkus[0].statusId) === PROJECT_STAGES.PREP
    const isNegotiation = project &&
      project.projectSkus[0].statusId === PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION
    const isProcessing = finishedMovie &&
      (finishedMovie.status !== FILE_STATUSES.CLIENT_UPLOAD_COMPLETE || !finishedMovie.webview)
    const downloadAllDisabled = downloadingZip || !projectFilesTotalSize || generatingZip
    const downloadProducerFavDisabled = !producerFavoritesTotalSize || generatingZip
    const isCurated = getPackageType(packages, project.projectSkus[0].packageId) === PACKAGE_TYPES.CURATED
    const hasCurated = curatedFiles && curatedFiles.length > 0
    const price = project && (project.projectSkus[0].price || project.projectSkus[0].price === 0)
      ? parseFloat(project.projectSkus[0].price / 100, 10)
      : null

    return (
      <div className={classes.sidebar}>
        { /* Producer section */}
        {(isAdmin || isProducer) &&
        <div className={classes.sidebarSection}>
          <div className={classes.sidebarSectionHeader}>Producer</div>
          {/* Take the project */}
          {isProducer && !isAssignedAsProducer && !inPrep &&
          <UrlifeButton
            className={classes.button}
            disabled={ takenStatus === 'taking' }
            onClick={ this.assignProject.bind(this) }>
            Assign to me
          </UrlifeButton>
          }
          {/* Drop the project */}
          {isAssignedAsProducer &&
            <UrlifeCheckbox
              className={classes.assignedCheckBox}
              iconClassName={classes.assignedCheckBoxIcon}
              labelClassName={classes.text}
              label={'Assigned to you'}
              checked={true}
              disabled={dropStatus === 'dropping'}
              onChange={this.unassignProject.bind(this)}
            />
          }
          { /* admin assign producer to project */}
          {isAdmin && <SelectProjectRole
            roleId={ROLES.PRODUCER}
            project={project}
            updateProjectSku={updateProjectSku}
          />}
        </div>
        }

        { /* assign editor to project */ }
        {(isAssignedAsProducer || isAdmin) &&
        <div className={classes.sidebarSection}>
          <div className={classes.sidebarSectionHeader}>Editor</div>
          <SelectProjectRole
            roleId={ROLES.EDITOR}
            disabled={isAdmin}
            project={project}
            updateProjectSku={updateProjectSku}
          />
        </div>
        }

        { /* Download files */ }
        {(isAssignedAsProducer || isAssignedAsEditor || isAdmin) &&
        <div className={classes.sidebarSection}>
          <div className={classes.sidebarSectionHeader}>Download</div>
          {/* Download all files */}
          <div
            className={classNames(classes.fileActionBlock, { _disabled: downloadAllDisabled })}
            onClick={downloadAllDisabled ? null : this.downloadZip.bind(this)}>
            <DownloadIcon className={classes.downloadIcon} />
            <span className={classes.text}>
              Download all files
              {projectFilesTotalSize > 0 &&
                <span>&nbsp;(~{humanFileSize(projectFilesTotalSize, true)})</span>
              }
            </span>
          </div>
          {/* Download producer favorites */}
          <div
            className={classNames(classes.fileActionBlock, { _disabled: downloadProducerFavDisabled })}
            onClick={downloadProducerFavDisabled ? null : this.downloadProducerFavorites.bind(this)}>
            <DownloadIcon className={classes.downloadIcon} />
            <span className={classes.text}>
              Download producer favorites
              {producerFavoritesTotalSize > 0 &&
                <span>&nbsp;(~{humanFileSize(producerFavoritesTotalSize, true)})</span>
              }
            </span>
          </div>
        </div>
        }

        {/* Upload files */}
        {(isAssignedAsProducer || isAssignedAsEditor || isAdmin) &&
        <div className={classes.sidebarSection}>
          <div className={classes.sidebarSectionHeader}>{isAdmin ? 'Movie' : 'Upload'}</div>
          {/* finished movie */}
          <div className={classes.finishedMovieWrapper}>
            {finishedMovie.id && finishedMovie.webview &&
              <div
                onClick={this.openFinishedMoviePopup.bind(this)}
                className={classes.finishedMovieBlock}>
                <FinishedMovie
                  thumbnail={finishedMovie.thumbnailCdnUrl || finishedMovie.thumbnailCdnUrl}
                />
                <PlayIcon className={classes.finishedMoviePlayIcon} />
              </div>
            }
            {isAssignedAsProducer && finishedMovie.id &&
            <React.Fragment>
              {!uploadingFinishedMovie && isProcessing &&
              <div className={classes.processingText}>
                Waiting for processing to complete
              </div>}
              {!uploadingFinishedMovie && !isProcessing &&
              <React.Fragment>
                {!isRejected &&
                <button
                  className={classes.finishedMovieDownloadButton}
                  onClick={this.goToMovie.bind(this)}
                >
                  <span className={classes.submitText}>See finished movie</span>
                </button>}
                {isRejected &&
                <div className={classes.rejected}>
                  <p>REJECTED</p>
                  <button onClick={this.goToMovie.bind(this)}><span>Reason for rejection</span></button>
                </div>}
              </React.Fragment>
              }
            </React.Fragment>
            }
            <div className={classes.finishedMovieActions}>
              {finishedMovie.id &&
              <React.Fragment>
                <button
                  disabled={uploadingFinishedMovie || downloadingFinishedMovie}
                  className={classes.finishedMovieDownloadButton}
                  onClick={this.downloadFinishedMovie.bind(this)}>
                  <span className={classes.submitText}>
                    Download finished movie
                  </span>
                </button>
              </React.Fragment>
              }
            </div>
          </div>
          {/* Upload project resources */}
          {isAssignedAsProducer &&
          <React.Fragment>
            <label htmlFor='uploadResources'>
              <div className={classes.fileActionBlock}>
                <DownloadIcon className={classNames(classes.downloadIcon, '_upload')} />
                <span className={classes.text}>Upload project resources</span>
              </div>
            </label>
            <input
              onChange={this.uploadResources.bind(this)}
              style={{ display: 'none', pointerEvents: 'none' }}
              type="file"
              id='uploadResources'
              multiple
            />
          </React.Fragment>
          }
          {/* Upload finished move */}
          {(isAssignedAsProducer || isAssignedAsEditor) &&
          <React.Fragment>
            <label htmlFor='finishedMovie'>
              <div className={classes.fileActionBlock}>
                <DownloadIcon className={classNames(classes.downloadIcon, '_upload')} />
                <span className={classes.text}>
                  {finishedMovie.id
                    ? 'Upload another movie'
                    : uploadingFinishedMovie ? 'Uploading movie...' : 'Upload finished movie'
                  }
                </span>
              </div>
            </label>
            <input
              onChange={this.finishedUpload.bind(this)}
              style={{ display: 'none', pointerEvents: 'none' }}
              disabled={uploadingFinishedMovie}
              accept=".mp4, .mov"
              type="file"
              id='finishedMovie'
            />
          </React.Fragment>
          }
          {/* Upload curated files */}
          {(isAssignedAsProducer || isAssignedAsEditor) &&
          <div className={classes.fileActionBlock}>
            <DownloadIcon className={classNames(classes.downloadIcon, '_upload')} />
            <span className={classes.text}>
              <DropZone
                view={'link'}
                isCurated={true}
                filesServer={curatedFiles}
                projectId={project.id}
                projectSkuId={project.projectSkus[0].id}
              />
            </span>
          </div>
          }
          {/* approval */}
          {isAssignedAsProducer &&
          <React.Fragment>
            {(finishedMovie.id || hasCurated) &&
            <UrlifeButton
              disabled={
                (finishedMovie.id && (uploadingFinishedMovie || isProcessing)) ||
                approvalInProgress === 'sending'
              }
              onClick={
                finishedMovie.id
                  ? this.approveCompleteVideo.bind(this)
                  : this.approveCuratedFiles.bind(this, false)
              }
              inverted
              className={classes.button}>
              {isInProduction
                ? 'User approval'
                : 'Back to production'
              }
            </UrlifeButton>
            }
            {isCurated && hasCurated && isInProduction &&
            <UrlifeButton
              disabled={approvalInProgress === 'sending'}
              onClick={this.approveCuratedFiles.bind(this, true)}
              className={classes.button}>
              Complete project
            </UrlifeButton>
            }
          </React.Fragment>
          }
        </div>
        }
        { /* Custom price Negotiation */ }
        {(isAdmin || isProducer) &&
        <div className={classes.sidebarSection}>
          <div className={classes.sidebarSectionHeader}>
            {isAdmin ? 'Admin control' : 'Pricing'}
          </div>
          <span
            className={classes.price}
          >
            Project price: {(price || price === 0) ? `$${price}` : 'n/a'}
          </span>
          {inPrep &&
            <React.Fragment>
              <UrlifeButton
                onClick={() => this.openNegotiationDialog('negotiationPriceDialog')}
                className={classes.button}>
                {(isNegotiation || (!price && price !== 0) ? 'Set' : 'Change') + ' price'}
              </UrlifeButton>
              <AdminActionDialog
                type='price'
                isOpen={negotiationPriceDialog}
                updateProjectSku={updateProjectSku}
                onClose={() => this.closeNegotiationDialog('negotiationPriceDialog')}
                onComplete={() => this.closeNegotiationDialog('negotiationPriceDialog')}
              />
              {isAdmin && <React.Fragment>
                <UrlifeButton
                  onClick={() => this.openNegotiationDialog('negotiationQueuedDialog')}
                  className={classes.button}>
                  Move to production queue
                </UrlifeButton>
                <AdminActionDialog
                  type='queued'
                  isOpen={negotiationQueuedDialog}
                  updateProjectSku={updateProjectSku}
                  onClose={() => this.closeNegotiationDialog('negotiationQueuedDialog')}
                  onComplete={() => this.closeNegotiationDialog('negotiationQueuedDialog')}
                />
              </React.Fragment>}
            </React.Fragment>
          }
        </div>
        }
        { /* error */}
        {error &&
        <UploadMsg
          title={''}
          specText={error}
          warningText={errorFileName}
          onClose={this.closeErrorPopup.bind(this)}
        />
        }
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onCloseFinishedMoviePopup.bind(this)}
          video={chosenPreview}
          credentials={true}
        />
        <UrlifeConfirmDialog
          isOpen={toProductionDialog}
          header='Are you sure?'
          text='The customer has already approved the project, are you sure you want to go back to production?'
          cancelLabel='Cancel'
          confirmLabel='Back to production'
          onIgnore={this.cancelToProduction.bind(this)}
          onCancel={this.cancelToProduction.bind(this)}
          onConfirm={this.confirmToProduction.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    curatedFiles: state.file.curatedFiles,
    fileProgress: Object.values(state.fileProgress),
    files: state.file.files,
    finishedMovieFiles: state.file.finishedMovieFiles,
    packages: state.packages.packages,
    producerFavoritesTotalSize: state.file.producerFavoritesTotalSize,
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clientDownloadFunction: function (fileData, needAuth = false) {
      return dispatch(downloadFile(fileData, needAuth))
    },
    createMovieFunction: function (fileInfo, file, projectId, projectSkuId, onComplete) {
      dispatch(createFinishedMovie(fileInfo, file, projectId, projectSkuId, onComplete))
    },
    createProjectResourcesFunction: function (files, projectId, fileObjects) {
      return dispatch(createProjectResources(files, projectId, fileObjects))
    },
    finishedFilesFunction: function (projectId, projectSkuId, params) {
      return dispatch(finishedFiles(projectId, projectSkuId, params))
    },
    generateZipFunction: function (project, include, filter) {
      return dispatch(generateZip(project, include, filter))
    },
    getMovieFileFunction: function (projectId, projectSkuId, movieFileId) {
      return dispatch(getMovieFile(projectId, projectSkuId, movieFileId))
    },
    getProducerFavoritesTotalSizeFunction: function (projectId) {
      return dispatch(getProducerFavoritesTotalSize(projectId))
    },
    getProjectFilesTotalSizeFunction: function (projectId) {
      return dispatch(getProjectFilesTotalSize(projectId))
    },
    projectInfoFunction: function (projectId) {
      dispatch(projectInfo(projectId))
    },
    reloadCuratedFilesFunction: function (projectId) {
      return dispatch(fetchCuratedFiles(projectId, null))
    }
  }
}

ProjectSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  clientDownloadFunction: PropTypes.func.isRequired,
  createMovieFunction: PropTypes.func.isRequired,
  createProjectResourcesFunction: PropTypes.func.isRequired,
  curatedFiles: PropTypes.array,
  fileProgress: PropTypes.array.isRequired,
  files: PropTypes.array,
  finishedFilesFunction: PropTypes.func.isRequired,
  finishedMovieFiles: PropTypes.array,
  generateZipFunction: PropTypes.func.isRequired,
  getMovieFileFunction: PropTypes.func.isRequired,
  getProducerFavoritesTotalSizeFunction: PropTypes.func.isRequired,
  getProjectFilesTotalSizeFunction: PropTypes.func.isRequired,
  packages: PropTypes.array,
  producerFavoritesTotalSize: PropTypes.number.isRequired,
  project: PropTypes.object,
  projectInfoFunction: PropTypes.func.isRequired,
  reloadCuratedFilesFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSidebar))
