import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import ModalPreview from '../../common/ModalPreview'
import { ParallaxPlayIcon } from '../../common/SVG'

/* eslint-disable sort-keys */
const styles = {
  videoContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  filter: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.34) 0%, rgba(255, 255, 255, 0.14) 20%)',
    cursor: 'pointer',
    '&._noFilter': {
      background: 'transparent'
    }
  },
  playIcon: {
    display: 'inline-block',
    width: '10%',
    height: 'auto',
    margin: 'auto'
  },
  thumbnail: {
    width: '100%'
  }
}
/* eslint-enable sort-keys */

class ModalVideo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openModal: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.autoplay === true && prevProps.autoplay === false) {
      this.setState({ openModal: true })
    }
  }

  toggleModalPreview () {
    this.setState({ openModal: !this.state.openModal })
  }

  render () {
    const {
      classes,
      className,
      filter = true,
      thumbnailClass,
      thumbnail,
      video,
      playIcon = true
    } = this.props
    const {
      openModal
    } = this.state
    return (
      <React.Fragment>
        <div
          data-testid='mv-root'
          className={classNames(classes.videoContainer, className)}
          onClick={this.toggleModalPreview.bind(this)}
        >
          <img
            className={classNames(classes.thumbnail, thumbnailClass)}
            data-testid='mv-thumbnail'
            src={thumbnail}
            alt='thumbnail'
          />
          <div className={classNames(classes.filter, { _noFilter: !filter })}>
            {playIcon && <ParallaxPlayIcon className={classes.playIcon} />}
          </div>
        </div>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.toggleModalPreview.bind(this)}
          poster={thumbnail}
          video={video}
          videoWidth={1125}
          title={''}
        />
      </React.Fragment>
    )
  }
}

ModalVideo.propTypes = {
  autoplay: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  filter: PropTypes.bool,
  playIcon: PropTypes.bool,
  thumbnail: PropTypes.string.isRequired,
  thumbnailClass: PropTypes.string,
  video: PropTypes.string.isRequired
}

export default withStyles(styles)(ModalVideo)
