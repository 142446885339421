export default class ExpiresManager {
  /**
   * @param {number} expiresTime - time in ms
   */
  constructor (expiresTime) {
    this.expiresTime = expiresTime
  }

  getExpiresIn (seconds) {
    if (seconds && !isNaN(parseInt(seconds))) {
      return parseInt(seconds) * 1000
    }
    return Date.now() + this.expiresTime
  }

  checkExpiresIn (date) {
    return date > Date.now()
  }
}
