import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, getFilePermissions } from '../../../helpers'
import FilesViewItem from './FilesViewItem'
import UrlifeInfiniteScroller from '../UrlifeInfiniteScroller'
import DropZone from '../DropZone'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  tileViewRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '-4px'
  },
  tileViewCol: {
    width: '16.666666%',
    padding: '4px',
    ...commonStyles.media(991, {
      width: '20%'
    }),
    ...commonStyles.media(767, {
      width: '25%'
    }),
    ...commonStyles.media(575, {
      width: '33.333333%'
    }),
    '&._nonCustomer': {
      width: '20%',
      ...commonStyles.media(767, {
        width: '25%'
      }),
      ...commonStyles.media(575, {
        width: '33.333333%'
      })
    }
  }
}
/* eslint-enable sort-keys */

class TileView extends Component {
  constructor () {
    super()
    this.state = {
      loadedFilesCount: 0
    }
  }

  loadMoreFiles () {
    const { loadedFilesCount } = this.state
    const batchSize = loadedFilesCount === 0 ? 23 : 12
    this.setState({
      loadedFilesCount: loadedFilesCount + batchSize
    })
  }

  render () {
    const {
      classes,
      files,
      selectedFiles,
      fileProgress,
      thumbnails,
      project,
      edit,
      isCurated,
      canUpload,
      roleId,
      userId,
      openFileNotes,
      onOpen,
      onSelected,
      saveFavoriteStatus,
      saveProducerFavoriteStatus,
      setFileError,
      updateProject
    } = this.props
    const { loadedFilesCount } = this.state
    const loadedFiles = files.slice(0, loadedFilesCount)
    const selectionMode = selectedFiles && selectedFiles.size > 0
    const permissions = getFilePermissions(null, project, userId, roleId, isCurated)
    return (
      <UrlifeInfiniteScroller
        loadMore={this.loadMoreFiles.bind(this)}
        initialLoad={true}
        hasMore={loadedFilesCount < files.length}
        isEmpty={!files.length}
      >
        <div className={classes.tileViewRow}>
          {canUpload && !permissions.isNonCustomer &&
            <div className={classes.tileViewCol}>
              <DropZone
                view={'tile'}
                filesServer={files}
                projectId={project.id}
                projectSkuId={project.projectSkus[0].id}
                isCurated={isCurated} />
            </div>
          }
          {loadedFiles.map((file, idx) => {
            const thumb = thumbnails && thumbnails[file.id] ? thumbnails[file.id].thumbnail : null
            const progress = fileProgress ? fileProgress[file.id] : null
            return (
              <div
                key={file.id}
                className={classNames(classes.tileViewCol, { _nonCustomer: permissions.isNonCustomer })}
              >
                <FilesViewItem
                  view={'tile'}
                  openFileNotes={openFileNotes}
                  onOpen={onOpen}
                  onSelected={onSelected}
                  project={project}
                  file={file}
                  thumbnail={thumb}
                  fileProgress={progress}
                  edit={edit}
                  first={idx === 0}
                  isCurated={isCurated}
                  isSelected={selectedFiles.has(file.id)}
                  selectionMode={selectionMode}
                  saveFavoriteStatus={saveFavoriteStatus}
                  saveProducerFavoriteStatus={saveProducerFavoriteStatus}
                  setFileError={setFileError}
                  updateProject={updateProject}
                />
              </div>
            )
          })}
        </div>
      </UrlifeInfiniteScroller>
    )
  }
}

TileView.propTypes = {
  canUpload: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  fileProgress: PropTypes.object,
  files: PropTypes.array.isRequired,
  isCurated: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onSelected: PropTypes.func,
  openFileNotes: PropTypes.func,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  saveFavoriteStatus: PropTypes.func,
  saveProducerFavoriteStatus: PropTypes.func,
  selectedFiles: PropTypes.object,
  setFileError: PropTypes.func.isRequired,
  thumbnails: PropTypes.object,
  updateProject: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    fileProgress: state.fileProgress,
    roleId: state.user.roleId,
    thumbnails: state.thumbnails.thumbnails,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(TileView))
