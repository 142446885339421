import React from 'react'
import PropTypes from 'prop-types'
import Confirmation from '../common/Confirmation'
import { toLocale } from '../../helpers'

class ThankYou extends React.Component {
  subheaderText () {
    const { appointmentDate } = this.props
    if (appointmentDate) {
      return 'A producer will call you on ' + toLocale(appointmentDate, { forThankYou: true })
    } else {
      return 'Your changes have been sent to the Producer'
    }
  }

  infoText () {
    const { appointmentDate } = this.props
    if (appointmentDate) {
      return 'You will receive a confirmation email shortly'
    } else {
      return 'A URLIFE producer will connect with you soon'
    }
  }

  render () {
    return (
      <Confirmation
        headerText={'Thank you'}
        subHeaderText={this.subheaderText()}
        infoText={this.infoText()}
      />
    )
  }
}

ThankYou.propTypes = {
  appointmentDate: PropTypes.object
}

export default ThankYou
