import { withStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { commonStyles } from '../../../helpers/index'

/* eslint-disable sort-keys */
const styles = {
  menu: {
    display: 'flex'
  },
  menuItem: {
    '&+&': {
      marginLeft: 0,
      ...commonStyles.media(991, {
        marginLeft: 0
      })
    }
  },
  menuLink: {
    textDecoration: 'none',
    transition: '.3s ease',
    opacity: 0.6,
    '&._active': {
      opacity: 1
    },
    '&:hover': {
      opacity: 1
    }
  },
  menuLinkNoIcon: {
    opacity: 1,
    '&._activeNoIcon': {
      '& div': {
        '& span': {
          borderBottom: '2px solid #fff'
        }
      }
    }
  },
  icon: {
    width: 80,
    height: 50,
    margin: '21px 10px 0 10px',
    '& span': {
      color: '#fff',
      marginTop: 8,
      textAlign: 'center',
      display: 'block',
      fontSize: 12
    },
    '& svg': {
      display: 'block',
      margin: 'auto'
    },
    ...commonStyles.media(991, {
      width: 50,
      margin: '21px 5px 0 5px',
      '& span': {
        fontSize: 10
      }
    }),
    ...commonStyles.media(480, {
      margin: '21px 0px 0 0px',
      '& span': {
        marginTop: 5
      }
    })
  },
  iconMiddle: {
    margin: '5px 10px 0 10px',
    width: 80,
    height: 70,
    '& svg': {
      width: 50,
      height: 'auto'
    },
    ...commonStyles.media(991, {
      '& span': {
        fontSize: 10
      }
    }),
    ...commonStyles.media(480, {
      margin: '17px 10px 0 10px',
      width: 70,
      height: 60,
      '& svg': {
        width: 35
      }
    })
  },
  iconTextOnly: {
    margin: '0px 15px 0 15px',
    width: 'auto',
    height: 'auto',
    '& span': {
      fontSize: 14,
      borderBottom: '2px solid transparent',
      transition: '.3s ease',
      '&:hover': {
        borderBottomColor: '#fff'
      }
    },
    '& svg': {
      margin: 0,
      display: 'none'
    }
  }
}
/* eslint-enable sort-keys */

const TopBarLineMenu = (props) => {
  const { classes, items, className = '' } = props
  const classNames = `${className} ${classes.menuLink}`
  return items.length
    ? (
    <nav className={`${classes.menu} ${className}`}>
      {items.map((menuItem, i) => (
        <NavLink
          key={i}
          activeClassName={`${classNames} ${menuItem.icon ? '_active' : '_activeNoIcon'}`}
          className={`${classNames} ${!menuItem.icon ? classes.menuLinkNoIcon : null}`}
          to={{
            pathname: menuItem.to,
            state: menuItem.state
          }}
        >
          <div
            className={`
              ${classes.icon} 
              ${menuItem.middle ? classes.iconMiddle : null} 
              ${!menuItem.icon ? classes.iconTextOnly : null}
            `}
          >
            {menuItem.icon ? menuItem.icon : null}
            <span>{menuItem.name}</span>
          </div>
        </NavLink>
      ))}
    </nav>
      )
    : null
}

TopBarLineMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  items: PropTypes.array,
  type: PropTypes.oneOf(['dark', 'light'])
}

export default withStyles(styles)(TopBarLineMenu)
