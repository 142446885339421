import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import UrlifeButton from '../../../common/UrlifeButton'
import { connect } from 'react-redux'
import {
  setTopBarHeaders
} from '../../../../actions'
import { CONTACT_CHOICE } from '../../../../constants'
import { commonStyles } from '../../../../helpers'

/* eslint-disable sort-keys */
const styles = theme => ({
  producerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    marginTop: 40,
    marginBottom: 50
  },
  label: {
    color: '#323232',
    fontSize: 16,
    marginLeft: 10,
    fontWeight: 500,
    lineHeight: '26px'
  },
  subLabel: {
    opacity: 0.8,
    color: '#323232',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    marginLeft: 47,
    marginTop: -10
  },
  nextButton: {
    padding: '8px 30px',
    margin: '60px 8px 5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  radio: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    border: '1px solid #cccccc'
  },
  radioChecked: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: '1px solid #01b7d7'
  },
  radioCheckedInner: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
  }
})
/* eslint-enable sort-keys */

class ProducerRequest extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      choice: props.contactChoice
        ? props.contactChoice === CONTACT_CHOICE.NOTES
          ? 'notes'
          : props.contactChoice === CONTACT_CHOICE.PRODUCER_CONTACT ||
            props.contactChoice === CONTACT_CHOICE.APPOINTMENT ||
            props.contactChoice === CONTACT_CHOICE.EMAIL_ME
            ? 'speak'
            : ''
        : ''
    }
  }

  handleChange (e) {
    const choice = e.target.value
    this.setState({ choice }, () => {
      this.saveChoice(choice === 'speak', false)
    })
  }

  async saveChoice (shouldContact, next = false) {
    const {
      nextStep,
      setContactProducer,
      updateProject
    } = this.props
    setContactProducer(shouldContact)
    const contactChoice = shouldContact ? CONTACT_CHOICE.PRODUCER_CONTACT : CONTACT_CHOICE.NOTES
    await updateProject({}, { contactChoice })
    if (next) {
      nextStep()
    }
  }

  onNext () {
    const { choice } = this.state
    this.saveChoice(choice === 'speak', true)
  }

  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  render () {
    const { classes } = this.props
    const { choice } = this.state
    return (
      <div className={classes.producerContainer}>
        <span className={classes.text}>How would you like to share your vision for the movie?</span>
        <RadioGroup aria-label="producerCallNotes" name="producerCallNotes" value={choice}
          onChange={this.handleChange.bind(this)}>
          <FormControlLabel classes={{ label: classes.label }} value={'speak'}
            control={<Radio
              icon={<div className={classes.radio}></div>}
              checkedIcon={<div className={classes.radioChecked}>
                <div className={classes.radioCheckedInner}></div>
              </div>}/>}
            label="I’d like to connect with a Producer" />
          <FormControlLabel classes={{ label: classes.label }} value={'notes'}
            control={<Radio
              icon={<div className={classes.radio}></div>}
              checkedIcon={<div className={classes.radioChecked}>
                <div className={classes.radioCheckedInner}></div>
              </div>}/>}
            label="I’ll provide notes" />
          <span className={classes.subLabel}>You can always schedule a call later</span>
        </RadioGroup>
        <UrlifeButton
          className={classes.nextButton}
          disabled={!choice}
          onClick={this.onNext.bind(this)}>
          Next
        </UrlifeButton>
      </div>
    )
  }
}

ProducerRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  contactChoice: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  setContactProducer: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Step', 'Our Producers are excited to hear your story!'))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(ProducerRequest))
