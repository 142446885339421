import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeButton from '../common/UrlifeButton'
import {
  fetchEncodingQueueStatus
} from '../../actions'
import {
  commonStyles
} from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  titleText: {
    margin: '10px 0px 30px 10px'
  },
  encodingQueueTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: 10,
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  encodingQueueRow: {
    cursor: 'pointer',
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  encodingQueueCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    padding: 10,
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  refreshButton: {
    marginTop: 20
  }
}
/* eslint-enable sort-keys */

class AdminEncodingQueue extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      queue: { images: 0, videos: 0 }
    }
  }

  fetchQueueStatus () {
    const { fetchEncodingQueueStatusFunction } = this.props
    this.setState({ isLoading: true }, async () => {
      this.setState({
        isLoading: false,
        queue: await fetchEncodingQueueStatusFunction()
      })
    })
  }

  componentDidMount () {
    this.fetchQueueStatus()
  }

  render () {
    const { classes } = this.props
    const { queue, isLoading } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h4" component="h2" className={classes.titleText}>
            <span>Encoding queue</span>
          </Typography>
          <div className={classes.encodingQueueTable}>
            <div className={classes.headerRow}>
              <div className={classes.headerCol}>Type</div>
              <div className={classes.headerCol}>Number of files</div>
            </div>
            <div className={classes.encodingQueueRow}>
              <div className={classes.encodingQueueCol}>
                Images
              </div>
              <div className={classes.encodingQueueCol}>
                {queue.images}
              </div>
            </div>
            <div className={classes.encodingQueueRow}>
              <div className={classes.encodingQueueCol}>
                Videos
              </div>
              <div className={classes.encodingQueueCol}>
                {queue.videos}
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <UrlifeButton
              className={classes.refreshButton}
              disabled={isLoading}
              onClick={this.fetchQueueStatus.bind(this)}
            >
              Refresh
            </UrlifeButton>
          </div>
        </Paper>
      </div>
    )
  }
}

AdminEncodingQueue.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchEncodingQueueStatusFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchEncodingQueueStatusFunction: function () {
      return dispatch(fetchEncodingQueueStatus())
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(AdminEncodingQueue))
