import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function UploadMovieArrowIcon ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 147 124'>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-370.000000, -1317.000000)" stroke={color || '#4A30B5'} strokeWidth="3">
          <g transform="translate(361.000000, 868.000000)">
            <g transform="translate(11.000000, 451.000000)">
              <polyline id="Path" strokeLinecap="round" strokeLinejoin="round" points="0 77.9503309 33.8062593 53.0764694 50.5160741 70.3859511 86.2660741 37.3081712 118.131241 64.7873665"></polyline>
              <path d="M113.078574,0 L5.05266667,0 C2.26151852,0 0,2.23838543 0,5.0009829 L0,89.9993447 C0,92.7619422 2.26151852,95.0003276 5.05266667,95.0003276 L92.8122963,95.0003276 C92.8732037,93.2782911 92.9552963,92.274425 93.4584444,90.575978 C93.8742037,89.1684686 93.8477222,87.8841491 94.6236296,85.1923203 C95.2141667,83.6721054 96.0456852,82.4952493 97.0705185,81.1585086 C98.3151481,79.5282092 99.5915556,77.8585938 101.085111,76.5900007 C103.343981,74.6713846 106.249,73.2062119 109.148722,72.1735142 C111.717426,71.2587642 115.440722,70.511762 118.133889,70.2706245 L118.133889,5.0009829 C118.131241,2.23838543 115.869722,0 113.078574,0 Z" id="Path" strokeLinecap="round"></path>
              <ellipse id="Oval" cx="32.8343889" cy="24.808335" rx="10.6852778" ry="10.575978"></ellipse>
              <ellipse id="Oval" cx="117.935278" cy="95.191665" rx="25.0647222" ry="24.808335"></ellipse>
              <line x1="118.131241" y1="107.308826" x2="118.131241" y2="83.0771247" id="Path" strokeLinecap="round"></line>
              <polyline id="Path" strokeLinecap="round" strokeLinejoin="round" points="108.415185 92.6937946 117.935278 82.8202608 127.844648 92.6937946"></polyline>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

UploadMovieArrowIcon.propTypes = {
  color: PropTypes.string
}
