import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  getPackageType,
  history,
  mapProjectStatusToStage
} from '../../helpers'
import { PROJECT_STAGES, STRIPE_KEY } from '../../constants'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CardPayment from './CardPayment'
import Congratulations from '../createmovie/components/Steps/Congratulations'
import {
  clearMoreMenuActions,
  deleteProject,
  projectInfo,
  setMoreMenuAction,
  setTopBarHeaders,
  setTopBarType,
  updateProject,
  updateProjectSku
} from '../../actions'

const stripePromise = loadStripe(STRIPE_KEY, {
  locale: 'en'
})

const styles = {
  container: {
    color: '#323232',
    margin: 'auto',
    maxWidth: 500
  },
  loadingProject: {
    color: 'black'
  }
}

class Checkout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      project: props.project || null,
      success: false
    }
    this.isCreateProject = false
  }

  async saveProject () {
    const { updateProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      await updateProjectFunction(project)
    }
  }

  async deleteProject () {
    const { deleteProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      await deleteProjectFunction(project.id)
    }
  }

  async updateProjectSku (updateFields = {}) {
    const { updateProjectSkuFunction } = this.props
    const { project } = this.state
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      try {
        const projectSkuRes = await updateProjectSkuFunction(project.id, { id: projectSku.id, ...updateFields })
        const skuIndex = project.projectSkus.findIndex(sku => sku.id === projectSku.id)
        project.projectSkus[skuIndex] = projectSkuRes
        this.setState({ project })
      } catch (e) { }
    }
  }

  async componentDidMount () {
    const {
      setTopBarHeadersFunction,
      setTopBarTypeFunction,
      match,
      getProjectInfoFunction,
      clearMoreMenuActionsFunction,
      setMoreMenuActionFunction
    } = this.props
    const { project } = this.state
    let statusId = getLeastCompleteProjectSkuStatus(project)

    if (project && mapProjectStatusToStage(statusId) !== PROJECT_STAGES.PREP) {
      history.push('/')
    } else {
      this.isCreateProject = history.location.pathname.startsWith('/createmovie/checkout') ||
        (history.location.state && history.location.state.type === 'creationFlow')
      setTopBarTypeFunction('project')
      setTopBarHeadersFunction('CHECKOUT')
      if (!this.isCreateProject) {
        clearMoreMenuActionsFunction()
        setMoreMenuActionFunction('saveProject', this.saveProject.bind(this))
        setMoreMenuActionFunction('deleteProject', this.deleteProject.bind(this))
      }
      if (match && match.params.projectId) {
        if (match.params.projectId && history.location.pathname === `/projects/${match.params.projectId}/checkout`) {
          try {
            const project = await getProjectInfoFunction(match.params.projectId)
            statusId = getLeastCompleteProjectSkuStatus(project)
            if (project && mapProjectStatusToStage(statusId) !== PROJECT_STAGES.PREP) {
              history.push(`/projects/${project.id}/summary`)
            } else if (project && mapProjectStatusToStage(statusId) === PROJECT_STAGES.PREP) {
              const sku = getLeastCompleteProjectSku(project)
              if (sku && sku.price) {
                history.push(`/createmovie/checkout/${project.id}`)
              } else {
                history.push(`/createmovie/continue/${project.id}`)
              }
            } else if (!project) {
              history.push('/')
            }
            this.setState({ project })
          } catch (e) {
            history.push('/')
          }
        }
      }
    }
  }

  componentWillUnmount () {
    if (!this.isCreateProject) {
      this.props.clearMoreMenuActionsFunction()
    }
  }

  onPaymentComplete () {
    const { nextStep } = this.props
    const { project } = this.state
    if (this.isCreateProject) {
      nextStep()
    } else if (history.location.pathname === `/projects/${project.id}/checkout`) {
      this.setState({ success: true })
    }
  }

  render () {
    const {
      classes,
      packages,
      canChangePackage,
      updateProjectSku
    } = this.props
    const {
      project,
      success
    } = this.state
    const projectSku = getLeastCompleteProjectSku(project)
    const packageType = projectSku && getPackageType(packages, projectSku.packageId)
    const fonts = [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@500' }]
    return (
      <div className={classes.container}>
        {project &&
          <Elements fonts={fonts} stripe={stripePromise}>
            <React.Fragment>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  elements && stripe &&
                  <CardPayment
                    project={project}
                    paymentComplete={this.onPaymentComplete.bind(this)}
                    elements={elements}
                    stripe={stripe}
                    canChangePackage={canChangePackage}
                    updateProjectSku={updateProjectSku || this.updateProjectSku.bind(this)}
                  />
                )}
              </ElementsConsumer>
            </React.Fragment>
          </Elements>
        }
        {!project &&
          <p className={classes.loadingProject}>
            Loading your project...
          </p>
        }
        {success && <Congratulations project={project} packageType={packageType} isCustomPrice={false} />}
      </div>
    )
  }
}

Checkout.propTypes = {
  canChangePackage: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  clearMoreMenuActionsFunction: PropTypes.func.isRequired,
  deleteProjectFunction: PropTypes.func.isRequired,
  getProjectInfoFunction: PropTypes.func.isRequired,
  match: PropTypes.object,
  nextStep: PropTypes.func,
  packages: PropTypes.array,
  project: PropTypes.object,
  setMoreMenuActionFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  setTopBarTypeFunction: PropTypes.func.isRequired,
  updateProjectFunction: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func,
  updateProjectSkuFunction: PropTypes.func.isRequired
}

Checkout.defaultProps = {
  canChangePackage: true
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearMoreMenuActionsFunction: function () {
      dispatch(clearMoreMenuActions())
    },
    deleteProjectFunction: function (projectId) {
      return dispatch(deleteProject(projectId))
    },
    getProjectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setMoreMenuActionFunction: function (name, func) {
      dispatch(setMoreMenuAction(name, func))
    },
    setTopBarHeadersFunction: function (text) {
      dispatch(setTopBarHeaders('', text))
    },
    setTopBarTypeFunction: function (type) {
      return dispatch(setTopBarType(type))
    },
    updateProjectFunction: function (project) {
      return dispatch(updateProject(project))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Checkout))
