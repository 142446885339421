import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import PackageListV2 from '../../common/Packages/components/PackageListV2'

/* eslint-disable sort-keys */
const styles = {
  root: {
    ...commonStyles.media(480, {
      marginBottom: 75,
      fontSize: 14,
      fontWeight: 500
    })
  },
  group: {
    height: 'auto',
    marginBottom: 100,
    ...commonStyles.media(480, {
      marginTop: -40,
      marginBottom: 0
    })
  },
  layer: {
    position: 'relative',
    height: 'auto'
  },
  mainHeader: {
    background: 'none',
    height: 270,
    width: '100%',
    '&::before': {
      position: 'absolute',
      content: "''",
      display: 'block',
      width: '160vw',
      left: '-30vw',
      height: 270,
      borderRadius: '0 0 900% 900%',
      backgroundImage: 'linear-gradient(135deg, #3d29b1 20%, #bd6ad3 80%)'
    },
    '& h3': {
      position: 'relative',
      padding: '40px 0 15px 0',
      margin: 'auto',
      textAlign: 'center',
      color: '#fff',
      fontSize: 26,
      fontWeight: 600,
      letterSpacing: 1,
      textTransform: 'uppercase'
    },
    '& p': {
      position: 'relative',
      margin: 'auto',
      textAlign: 'center',
      color: '#ffffff',
      fontSize: 18,
      fontWeight: 500
    },
    ...commonStyles.media(480, {
      '&::before': {
        height: 170,
        backgroundImage: 'linear-gradient(135deg, #3d29b1 20%, #bd6ad3 80%)'
      },
      '& h3': {
        top: -20,
        padding: '12px auto 6px auto',
        fontSize: 18,
        width: 200
      },
      '& p': {
        paddingBottom: 30,
        display: 'none'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      '&::before': {
        height: 170,
        backgroundImage: 'linear-gradient(135deg, #3d29b1 20%, #bd6ad3 80%)'
      },
      '& h3': {
        top: -20,
        padding: '12px auto 6px auto',
        fontSize: 18
      },
      '& p': {
        marginTop: -30,
        fontSize: 14
      }
    }),
    ...commonStyles.mediaDimensions(575, 414, {
      '& p': {
        paddingBottom: 30,
        display: 'none'
      }
    })
  },
  bigScreenHeader: {
    ...commonStyles.media(959, {
      display: 'none'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'none'
    })
  },
  smallScreenHeader: {
    display: 'none',
    ...commonStyles.media(959, {
      display: 'block'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'block'
    })
  }
}
/* eslint-enable sort-keys */

class ExplorePackages extends React.PureComponent {
  render () {
    const { classes } = this.props
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('ExplorePackages')} className={classes.group} index={35}>
        <ParallaxLayer layer='layer5' className={classes.layer}>
          <div className={classes.mainHeader}>
            <h3>
              <div className={classes.bigScreenHeader}>EXPERT EDITORS AND STORYTELLERS WORKING FOR YOU</div>
              <div className={classes.smallScreenHeader}>EXPLORE OUR UNIQUE PACKAGES</div>
            </h3>
            <p>Choose the package that best fits your project</p>
          </div>
          <PackageListV2/>
        </ParallaxLayer>
      </ParallaxGroup>
    )
  }
}

ExplorePackages.propTypes = {
  classes: PropTypes.object.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

export default withStyles(styles)(ExplorePackages)
