import { api, ONLY_MARKETING } from '../constants'

const CONTENT_TYPES = {
  JSON: 'application/json'
}
export default class ApiService {
  /**
   * @param {String} url - api uri without server. Ex: 'projects/my'
   * @param {Object} [urlVars = {}] - object for replacing. Ex: {userId: 123}.
   * @param {Object} [urlParams = {}] - additional url params. Ex: {p:1} => ?p=1
   * @param {boolean} needAuth
   * @param {function} dispatch
   */
  constructor (url, urlVars = {}, urlParams = {}, needAuth, dispatch) {
    if (!dispatch) throw new Error('Dispatch is required')
    if (!url) throw new Error('url is required')

    if (typeof url !== 'string') throw new Error('url must be "string"')
    if (typeof urlVars !== 'object' || urlVars === null) {
      throw new Error('urlVars must be "object"')
    }
    if (typeof urlParams !== 'object' || urlParams === null) {
      throw new Error('urlParams must be "object"')
    }
    if (typeof needAuth !== 'boolean') throw new Error('needAuth must be "boolean"')
    if (typeof dispatch !== 'function') throw new Error('dispatch must be "function"')

    this.needAuth = !!needAuth
    this.dispatch = dispatch
    this.url = null
    this.headers = {
      'Content-Type': CONTENT_TYPES.JSON
    }
    this.body = null

    this.setUrl(url, urlParams, urlVars)
  }

  async preFlight () {
    const auth = JSON.parse(window.localStorage.getItem('auth'))
    const reqTime = parseInt(window.localStorage.getItem('lastRequest'), 10)

    if (auth) {
      if (auth.access_token && auth.expires_in && reqTime + auth.expires_in * 1000 > Date.now() + 60000) {
        return Promise.resolve(true)
      } else if (auth.refresh_token && auth.expires_in && reqTime + auth.expires_in * 1000 < Date.now() + 60000) {
        let response
        try {
          response = await new ApiService(api.REFRESH, {}, {}, false, this.dispatch)
            .post({ refresh_token: auth.refresh_token })
        } catch (err) {
          if (err.message === 'NetworkError when attempting to fetch resource.') {
            this.dispatch({ type: 'BACKEND_DOWN' })
            return Promise.reject(new Error('Backend is not responding.'))
          }
          this.dispatch({ type: 'LOGOUT', wasAutomaticLogout: true, wasManualLogout: false })
          return Promise.reject(new Error('Failed to fetch new access token.'))
        }
        window.localStorage.setItem('lastRequest', Date.now())
        window.localStorage.setItem('auth', JSON.stringify({ ...response, refresh_token: auth.refresh_token }))
        return Promise.resolve(true)
      }
    }

    this.dispatch({ type: 'LOGOUT', wasAutomaticLogout: true, wasManualLogout: false })
    return Promise.reject(new Error('The user is not able to log in automatically.'))
  }

  getAuthHeader () {
    // return authorization header with jwt token
    const auth = JSON.parse(window.localStorage.getItem('auth'))

    if (auth && auth.access_token) {
      return { Authorization: 'Bearer ' + auth.access_token }
    } else {
      return {}
    }
  }

  async getRequestOptions () {
    const requestOptions = {
      headers: this.headers,
      method: this.method
    }
    if (this.body) {
      requestOptions.body = this.body
    }
    if (this.needAuth) {
      try {
        await this.preFlight(this.dispatch)
      } catch (err) {
        return Promise.reject(err)
      }
      requestOptions.headers = {
        ...requestOptions.headers,
        ...this.getAuthHeader()
      }
    }
    return requestOptions
  }

  /**
   * <p>Set url after processing url parameters and replacing api variables. </p>
   * <p>Ex: setUrl('users/:userId/projects', { p:1 }, { userId: 123 }) =>
   * <b>'users/123/projects?p=1'</b></p>
   *
   * @param {String} uri - api uri without server. Ex: 'projects/my'
   * @param {Object} [urlParams] - additional url params. Ex: {p:1} => ?p=1
   * @param {Object} [urlReplace] - object for replacing. Ex: {userId: 123}.
   * After 'users/:userId/projects' => 'users/123/projects'
   * @return {Object}
   */
  setUrl (uri, urlParams = {}, urlReplace = {}) {
    if (!uri) throw new Error('Api url is required')

    for (const replaceName in urlReplace) {
      /* eslint-disable-next-line no-prototype-builtins */
      if (urlReplace.hasOwnProperty(replaceName)) {
        if (uri.indexOf(replaceName) < 0) {
          throw new Error(`Wrong parameter for replace variable in api: "${replaceName}"`)
        }
        if (typeof urlReplace[replaceName] !== 'undefined' && urlReplace[replaceName] !== null) {
          uri = uri.replace(`:${replaceName}`, urlReplace[replaceName])
        }
      }
    }
    if (/:/.test(uri) && !uri.includes('http')) {
      throw new Error(`Api url was not processed with parameters completely: ${uri}`)
    }

    const fullUrl = uri.includes('http')
      ? uri
      : ONLY_MARKETING
        ? api.ONLY_MARKETING_SERVER_NAME + uri
        : api.SERVER_NAME + uri
    const wUrl = new window.URL(fullUrl)

    Object.keys(urlParams).forEach(key => {
      if (typeof urlParams[key] !== 'undefined' && urlParams[key] !== null) {
        if (Array.isArray(urlParams[key])) {
          urlParams[key].forEach(urlParam => {
            wUrl.searchParams.append(key, urlParam)
          })
        } else {
          wUrl.searchParams.append(key, urlParams[key])
        }
      }
    })
    this.url = wUrl
    return this
  }

  setBody (body = {}) {
    if (!['POST', 'PUT', 'PATCH'].includes(this.method.toUpperCase())) {
      throw new Error('Body is not allowed in this request')
    }
    this.body = JSON.stringify(body)
    return this
  }

  /**
   * Set request headers with clearing empty headers
   * @param {object} [headers]
   * @return {ApiService}
   */
  setHeaders (headers = {}) {
    const newHeaders = {
      ...this.headers,
      ...headers
    }
    // clear empty headers
    this.headers = Object.keys(newHeaders).reduce((ob, key) => {
      if (newHeaders[key]) ob[key] = newHeaders[key]
      return ob
    }, {})
    return this
  }

  async handleResponse (response) {
    let processedResponse
    const headerContentType = response.headers.get('content-type')
    if (headerContentType && headerContentType.includes(CONTENT_TYPES.JSON)) {
      processedResponse = await response.json()
    } else {
      processedResponse = response
    }

    if (!response.ok) {
      const throwMessage = processedResponse.error ? processedResponse.error : processedResponse
      throw new Error(throwMessage)
    }
    return processedResponse
  }

  handleFetchError (err) {
    this.dispatch({ type: 'BACKEND_DOWN' })
    return Promise.reject(err)
  }

  get () {
    this.method = 'GET'
    return this.fetch()
  }

  delete () {
    this.method = 'DELETE'
    return this.fetch()
  }

  put (body) {
    this.method = 'PUT'
    return this.setBody(body).fetch()
  }

  post (body) {
    this.method = 'POST'
    return this.setBody(body).fetch()
  }

  /**
   * Get file with progress
   * @param {function} [onProgress] - progress callback
   * @return {Promise<*>}
   */
  async download (onProgress) {
    const options = await this.getRequestOptions()

    return new Promise((resolve, reject) => {
      const xmlhttp = new window.XMLHttpRequest()
      xmlhttp.open('GET', this.url, true)
      xmlhttp.responseType = 'blob'

      for (const headerName in options.headers) {
        xmlhttp.setRequestHeader(headerName, options.headers[headerName])
      }

      if (onProgress) xmlhttp.addEventListener('progress', onProgress)
      xmlhttp.addEventListener('error', e => {
        this.handleFetchError(e)
      })

      xmlhttp.addEventListener('load', () => {
        resolve(xmlhttp.response)
      })

      xmlhttp.onreadystatechange = function (e) {
        if (xmlhttp.readyState === 4) {
          if (xmlhttp.status !== 200) {
            reject(new Error(`Failed to download file from server, statusCode ${xmlhttp.status}`))
          }
        }
      }
      xmlhttp.send()
    })
  }

  /**
   * @return {Promise<Response | never>}
   */
  async fetch () {
    let options
    try {
      options = await this.getRequestOptions()
    } catch (error) {
      return Promise.reject(error)
    }
    return window.fetch(this.url, options)
      .then(
        response => this.handleResponse(response),
        err => this.handleFetchError(err)
      )
  }
}
