import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import CarouselWrapper from './components/CarouselWrapper'
import ModalPreview from '../../common/ModalPreview'
import { ParallaxArrowRight, ParallaxPlayIcon } from '../../common/SVG'
import { fetchTips } from '../../../actions'
import connect from 'react-redux/es/connect/connect'
import styled from 'styled-components'

/* eslint-disable sort-keys */
const styles = {
  root: {
    width: '100%',
    height: 'auto',
    display: 'flex'
  },
  layer: {
    position: 'static',
    height: 'auto'
  },
  group: {
    height: 'auto',
    marginBottom: 344,
    boxSizing: 'border-box',
    ...commonStyles.media(599, {
      marginBottom: 280
    }),
    ...commonStyles.media(480, {
      height: 400,
      marginBottom: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 340,
      marginBottom: 0
    })
  },
  container: {
    ...commonStyles.container()
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  header: {
    color: '#5334b6',
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    display: 'flex',
    margin: '0',
    ...commonStyles.media(599, {
      display: 'block'
    }),
    ...commonStyles.media(480, {
      fontSize: 18,
      width: 260,
      margin: '18px 0 0 0px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18
    })
  },
  scrollText: {
    display: 'flex',
    cursor: 'pointer',
    '& span': {
      color: '#323232',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 1,
      margin: 'auto 20px auto auto'
    },
    '& svg': {
      width: 40,
      height: 40
    },
    ...commonStyles.media(599, {
      display: 'none'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      display: 'none'
    })
  },
  text: {
    marginBottom: 45,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(480, {
      fontSize: 12,
      margin: '12px 0 15px 0',
      lineHeight: '20px',
      '& br': {
        display: 'none'
      }
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      margin: '12px 0 15px 0',
      lineHeight: '20px',
      '& br': {
        display: 'none'
      }
    })
  },
  playIcon: {
    position: 'relative',
    display: 'inline-block',
    width: '15%',
    padding: 11,
    height: 'auto',
    margin: 'auto'
  },
  textBox: {
    position: 'absolute',
    bottom: 15,
    left: 15
  },
  title: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'left',
    margin: 0
  },
  type: {
    opacity: 0.8,
    color: '#ffffff',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left',
    margin: 0
  },
  thumbnailFilter: {
    position: 'absolute',
    left: 0,
    top: '70%',
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 95%)'
  }
}
/* eslint-enable sort-keys */

const MovieContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  margin-right: 21px;
  width: 420px;
  height: 270px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  @media (max-width: 599px) {
    width: 300px;
    height: 220px;
  };
  @media (max-width: 480px) {
    width: 270px;
    height: 161px;
    margin-right: 10px;
  };
  @media (max-width: 896px) and (max-height: 414px) {
    width: 270px;
    height: 161px;
    margin-right: 10px;
  };
`

class TipsAndTricks extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      chosenGaTitle: '',
      chosenVideo: null,
      extraSpeed: 0,
      openModal: false,
      tips: null
    }
    this.scrollFaster = this.scrollFaster.bind(this)
    this.scrollSlower = this.scrollSlower.bind(this)
    this.refScroll = React.createRef()
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }

      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
  }

  scrollFaster (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ extraSpeed: 10 })
  }

  scrollSlower (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ extraSpeed: 0 })
  }

  setVideo (video, title) {
    this.setState({
      chosenGaTitle: title,
      chosenVideo: video,
      openModal: true
    })
  }

  onClose () {
    this.setState({
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    })
  }

  thumbnailPriority (video) {
    if (video.imageWebviewUrl) return video.imageWebviewUrl
    else if (video.imageUrl) return video.imageUrl
    else if (video.imageThumbnailUrl) return video.imageThumbnailUrl
    else return video.videoThumbnailUrl
  }

  componentDidMount () {
    this.props.fetchTipsFunction()
      .then(res => {
        this.setState({ tips: res.data })
      })
    if (this.refScroll && this.refScroll.current) {
      const ref = this.refScroll.current
      ref.addEventListener('mousedown', this.scrollFaster)
      ref.addEventListener('pointerdown', this.scrollFaster)
      ref.addEventListener('touchstart', this.scrollFaster)
      ref.addEventListener('mouseup', this.scrollSlower)
      ref.addEventListener('pointerup', this.scrollSlower)
      ref.addEventListener('touchend', this.scrollSlower)
      ref.addEventListener('mouseleave', this.scrollSlower)
      ref.addEventListener('pointerleave', this.scrollSlower)
    }
  }

  componentWillUnmount () {
    const ref = this.refScroll.current
    ref.removeEventListener('mousedown', this.scrollFaster, false)
    ref.removeEventListener('pointerdown', this.scrollFaster, false)
    ref.removeEventListener('touchstart', this.scrollFaster, false)
    ref.removeEventListener('mouseup', this.scrollSlower, false)
    ref.removeEventListener('pointerup', this.scrollSlower, false)
    ref.removeEventListener('touchend', this.scrollSlower, false)
    ref.removeEventListener('mouseleave', this.scrollSlower, false)
    ref.removeEventListener('pointerleave', this.scrollSlower, false)
  }

  render () {
    const { classes } = this.props
    const { tips, extraSpeed, chosenVideo, chosenGaTitle, openModal } = this.state
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('TipsAndTricks')} className={classes.group} index={34}>
        <ParallaxLayer layer='layer5' className={classes.layer}>
          <Grid className={classes.container} container justify="space-around" spacing={0}>
            <Grid item xs={12} className={classes.topRow}>
              <h3 className={classes.header}>TIPS, TECHNIQUES AND TASTEMAKERS</h3>
              <div ref={this.refScroll} className={classes.scrollText}>
                <span>SCROLL</span>
                <ParallaxArrowRight />
              </div>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.text}>After building careers in Hollywood
                - our co-founders have some fun and (surprisingly!) easy tips
                to share on how to take your content to the next level.
                Scroll to our Founders section to learn more about their background!</p>
            </Grid>
          </Grid>
          <div className={classes.root}>
            <CarouselWrapper extraSpeed={extraSpeed} isOpenModalPreview={openModal}>
              {tips && tips.map((item, idx) => {
                return (
                  !item.ishidden && <MovieContainer
                    key={idx}
                    thumbnail={this.thumbnailPriority(item)}
                    onClick={() => {
                      (item.videoWebviewUrl || item.videoUrl) &&
                        this.setVideo(item.videoWebviewUrl ? item.videoWebviewUrl : item.videoUrl, item.title)
                    }}
                  >
                    <div className={classes.thumbnailFilter}>
                    </div>
                    <ParallaxPlayIcon className={classes.playIcon}/>
                    <div className={classes.textBox}>
                      <p className={classes.title}>{item.title}</p>
                      <p className={classes.type}>{item.subtitle}</p>
                    </div>
                  </MovieContainer>
                )
              }).sort(this.compareFields)}
            </CarouselWrapper>
          </div>
        </ParallaxLayer>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          video={chosenVideo}
          gaVideoTitle={chosenGaTitle}
          title={''}
        />
      </ParallaxGroup>
    )
  }
}

TipsAndTricks.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchTipsFunction: PropTypes.func.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    fetchTipsFunction: function () {
      return dispatch(fetchTips())
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(TipsAndTricks))
