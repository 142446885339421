import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function HeartFilled (props) {
  return (
    <SvgIcon {...props} viewBox="22 13 34 27">
      <defs>
        <filter id="a" width="77" height="72" x="0" y="0" filterUnits="userSpaceOnUse">
          <feOffset dy="4" in="SourceAlpha"/>
          <feGaussianBlur result="blurOut" stdDeviation="2.236"/>
          <feFlood floodColor="#000" result="floodOut"/>
          <feComposite in="floodOut" in2="blurOut" operator="atop"/>
          <feComponentTransfer>
            <feFuncA slope=".1" type="linear"/>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <path fill="#ed4049" fillRule="evenodd" d="M37.751 39.003h-.001a3.345 3.345 0 0 1-2.375-.976c-3.217-3.2-4.594-4.369-5.7-5.309-.768-.653-1.432-1.217-2.488-2.259-2.613-2.577-4.803-5.034-5.153-8.995a8.921 8.921 0 0 1 2.146-6.636c1.477-1.688 3.507-2.689 5.717-2.819a8.418 8.418 0 0 1 7.853 4.213 8.42 8.42 0 0 1 7.852-4.213c2.211.13 4.241 1.131 5.717 2.819a8.922 8.922 0 0 1 2.147 6.636c-.35 3.962-2.541 6.418-5.153 8.995-2.108 2.08-3.267 3.086-4.388 4.061a70.085 70.085 0 0 0-3.817 3.521 3.346 3.346 0 0 1-2.357.962z" filter="url(#a)"/>
    </SvgIcon>
  )
}
