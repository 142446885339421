import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { toggleModal } from '../../actions'
import backImg from '../../assets/img/cliffs-in-sea.jpg'

/* eslint-disable sort-keys */
const styles = () => ({
  backdropBg: {
    left: 0,
    zIndex: 100,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    backgroundImage: `url(${backImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
})
/* eslint-enable sort-keys */

class UrlifeResetPassword extends Component {
  componentDidMount () {
    const { dispatch, type } = this.props
    const resetOrSetPassword = `${type}Password`
    dispatch(toggleModal(true, resetOrSetPassword))
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.backdropBg}/>
    )
  }
}

UrlifeResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
}

export default withStyles(styles)(connect()(UrlifeResetPassword))
