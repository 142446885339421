import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { ViewCarousel, ViewTile } from '../SVG'
import { commonStyles } from '../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  changeViewButton: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#333',
    outline: 'none',
    fontSize: 'inherit',
    ...commonStyles.media(575, {
      padding: '0'
    })
  },
  changeViewIcon: {
    width: 24,
    height: 22
  }
}
/* eslint-enable sort-keys */

class SwitchViewButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      view: 'carousel'
    }
  }

  render () {
    const { classes, view, onChange } = this.props
    return (
      <button
        data-testid='svb-button'
        className={classes.changeViewButton}
        onClick={onChange}
      >
        { view === 'carousel' &&
        <ViewTile className={classes.changeViewIcon}/>
        }
        { view === 'tile' &&
        <ViewCarousel className={classes.changeViewIcon}/>
        }
      </button>
    )
  }
}

SwitchViewButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  view: PropTypes.oneOf(['tile', 'carousel'])
}
export default withStyles(styles)(SwitchViewButton)
