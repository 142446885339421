import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, history } from '../../../../helpers'
import { connect } from 'react-redux'
import {
  fetchAllProjectFiles,
  purchaseWithCoupon,
  setTopBarHeaders
} from '../../../../actions'
import UrlifeButton from '../../../common/UrlifeButton'
import FilesView from '../../../common/files/FilesView'
import ProjectDetailsBox from '../../../common/ProjectDetailsBox'
import StickyBottomBar from '../../../common/StickyBottomBar'
import PackageSelector from '../../../packages/PackageSelector'
import { PROJECT_STATUSES } from '../../../../constants'

/* eslint-disable sort-keys */
const styles = {
  createMovieContent: {
    marginTop: -20,
    paddingBottom: 80,
    ...commonStyles.media(575, {
      marginTop: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 0
    })
  },
  createMovieInfoBlock: {
    marginTop: -20,
    marginBottom: -20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...commonStyles.media(767, {
      flexDirection: 'column'
    })
  },
  checkoutButton: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  loadingProject: {
    color: 'black'
  },
  bottomMenu: {
    display: 'inline',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
/* eslint-enable sort-keys */

class Summary extends React.Component {
  componentDidMount () {
    const {
      project,
      projectSku,
      setTopBarHeadersFunction,
      projectFilesFunction
    } = this.props
    const isCustomPrice = !projectSku.price && projectSku.price !== 0
    const isFree = (!projectSku.price && projectSku.price === 0) || (projectSku.price && projectSku.price < 50)
    const title = 'Review your project before ' + (isCustomPrice || isFree ? 'submitting' : 'checkout')
    projectFilesFunction(project.id, { method: 'ASC', sort: 'last_modified' })
    setTopBarHeadersFunction(title)
  }

  async onNext () {
    const { project, projectSku, updateProjectSku, nextStep, purchaseFreeFunction } = this.props
    const isCustomPrice = !projectSku.price && projectSku.price !== 0
    const isFree = (!projectSku.price && projectSku.price === 0) || (projectSku.price && projectSku.price < 50)
    if (isCustomPrice) {
      await updateProjectSku({ statusId: PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION })
    } else if (isFree) {
      try {
        await purchaseFreeFunction(project.id, projectSku.id)
      } catch (e) {
        // Silent catch for the moment
        return false
      }
    }
    nextStep()
  }

  async saveProject () {
    const { project, updateProject } = this.props
    if (project) {
      await updateProject(project)
      history.push('/home')
    }
  }

  render () {
    const {
      classes,
      packageType,
      project,
      projectSku,
      files,
      updateProject,
      updateProjectSku
    } = this.props
    const isCustomPrice = (!projectSku.price && projectSku.price !== 0)
    const isFree = (projectSku.price || projectSku.price === 0) && projectSku.price < 50
    const projectAppointment = projectSku.email || projectSku.appointment
    const showDetails = ['prompts', 'stars']
    if (projectAppointment) {
      showDetails.unshift('appointment')
    }
    return (
      <div className={classes.createMovieContent}>
        <div>
          <div className={classes.createMovieInfoBlock}>
            <ProjectDetailsBox
              show={['title', 'info', 'status']}
              project={project}
              canEdit={true}
              columnLayout={'double'}
              updateProject={updateProject}
              packageType={packageType}
            />
          </div>
          <div className={classes.createMovieInfoBlock}>
            <ProjectDetailsBox
              show={showDetails}
              project={project}
              canEdit={true}
              columnLayout={'double'}
              packageType={packageType}
            />
          </div>
          <FilesView
            type={'tile'}
            edit={true}
            project={project}
            files={files}
            canUpload={true}
            updateProject={updateProject}
          />
          <StickyBottomBar>
            <div className={classes.bottomMenu}>
              <UrlifeButton
                inverted
                className={classes.checkoutButton}
                onClick={this.saveProject.bind(this)}>
                Save for later
              </UrlifeButton>
              <UrlifeButton
                className={classes.checkoutButton}
                onClick={this.onNext.bind(this)}>
                {isCustomPrice || isFree ? 'Submit' : 'Check out'}
              </UrlifeButton>
              {isCustomPrice && <PackageSelector
                projectId={project.id}
                packageSkuId={projectSku.packageSku}
                creationStatus={project.creationStatus}
                updateProjectSku={updateProjectSku}
              />}
            </div>
          </StickyBottomBar>
        </div>
      </div>
    )
  }
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  nextStep: PropTypes.func.isRequired,
  packageType: PropTypes.number,
  project: PropTypes.object.isRequired,
  projectFilesFunction: PropTypes.func.isRequired,
  projectSku: PropTypes.object.isRequired,
  purchaseFreeFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    files: state.file.files
  }
}

function mapDispatchToProps (dispatch) {
  return {
    projectFilesFunction: function (projectId, params) {
      dispatch(fetchAllProjectFiles(projectId, params))
    },
    purchaseFreeFunction: function (projectId, projectSku) {
      return dispatch(purchaseWithCoupon(projectId, projectSku))
    },
    setTopBarHeadersFunction: function (title) {
      dispatch(setTopBarHeaders('My project', title))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Summary))
