import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { commonStyles } from '../../../../helpers'
import {
  setTopBarHeaders
} from '../../../../actions'
import UrlifeButton from '../../../common/UrlifeButton'
import UrlifeTextField from '../../../common/UrlifeTextField'

/* eslint-disable sort-keys */
const styles = {
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  inputBlock: {
    width: '100%',
    display: 'block',
    paddingBottom: 80
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  button: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

class DescribeProject extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      description: props.description || '',
      maxLength: 50
    }
    this.handlingEvent = false
  }

  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  handleChangeInputTitle (e) {
    this.setState({ description: e.target.value })
  }

  onSkip () {
    this.onNext(true)
  }

  onNext (skip = false) {
    if (!this.handlingEvent) {
      this.saveDescription(skip, true)
    }
  }

  async saveDescription (skip = false, next = false) {
    const {
      nextStep,
      updateProject
    } = this.props
    const { description } = this.state
    const descriptionValue = description.trim()
    if (descriptionValue.length > 0 || skip) {
      await updateProject(
        { description: skip ? '' : descriptionValue },
        { description: true }
      )
      this.handlingEvent = false
      if (next) {
        nextStep()
      }
    } else {
      this.handlingEvent = false
    }
  }

  handleBlur (e) {
    // this is needed to allow user to click next when onBlur is called on field
    this.handlingEvent = true
    const { relatedTarget } = e
    if (relatedTarget && (relatedTarget.getAttribute('type')) === 'button') {
      if (relatedTarget.getAttribute('id') === 'skip') {
        this.saveDescription(true, true)
      } else {
        this.saveDescription(false, true)
      }
    } else {
      this.saveDescription(false, false)
    }
  }

  onSubmit (e) {
    e.preventDefault()
    this.onNext()
  }

  render () {
    const { classes } = this.props
    const { description, maxLength } = this.state
    const descriptionValue = description.trim()
    const isDescriptionValid = descriptionValue.length > 0
    return (
      <div className={classes.createMovieSteptwo}>
        <div className={classes.createMovieContent}>
          <div className={classes.formWrapper}>
            <form onSubmit={this.onSubmit.bind(this)}>
              <div className={classes.inputBlock}>
                <UrlifeTextField
                  id="input"
                  autoFocus
                  label={'Short description'}
                  onChange={this.handleChangeInputTitle.bind(this)}
                  onBlur={this.handleBlur.bind(this)}
                  defaultValue={descriptionValue}
                  maxLength={maxLength}
                />
              </div>
            </form>
            <div className={classes.buttonBlock}>
              <UrlifeButton
                id='skip'
                inverted
                className={classes.button}
                onClick={() => this.onSkip()}>
                Skip
              </UrlifeButton>
              <UrlifeButton
                className={classes.button}
                disabled={!isDescriptionValid}
                onClick={() => this.onNext()}>
                Next
              </UrlifeButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
DescribeProject.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  nextStep: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Step', 'Describe your project in a few words'))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(DescribeProject))
