import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function NotreDameIcon (props) {
  return (
    <SvgIcon {...props} viewBox="180 720 3600 550">
      <style type="text/css">
        .fil1 {'{fill:#C49E0D;fill-rule:nonzero}'}
        .fil0 {'{fill:#00244D;fill-rule:nonzero}'}
      </style>
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2530025821104">
          <polygon className="fil0" points="520.3,811.61 520.3,1256.85 462.02,1256.85 347.49,955.28 347.49,1188.42 376.63,1188.42 376.63,1256.85 250.56,1256.85 250.56,1188.42 279.04,1188.42 279.04,811.61 250.56,811.61 250.56,743.15 340.04,743.15 451.84,1037.26 451.84,811.61 422.7,811.61 422.7,743.15 549.44,743.15 549.44,811.61 "/>
          <path className="fil0" d="M779.83 830.58l-18.97 -18.97 -65.74 0 -18.97 18.97 0 338.86 18.97 18.97 65.74 0 18.97 -18.97 0 -338.86zm9.47 426.3l-122.66 0 -58.95 -58.99 0 -395.78 58.95 -58.96 122.66 0 59.66 58.96 0 395.78 -59.66 58.99z"/>
          <polygon className="fil0" points="1170.18,867.17 1170.18,811.61 1107.13,811.61 1107.13,1188.41 1136.26,1188.41 1136.26,1256.87 1009.56,1256.87 1009.56,1188.41 1038.7,1188.41 1038.7,811.61 975.64,811.61 975.64,867.17 907.22,867.17 907.22,743.14 1238.61,743.14 1238.61,867.17 "/>
          <path className="fil0" d="M1498.81 830.58l-18.97 -18.97 -85.39 0 0 190.43 85.39 0 18.97 -18.96 0 -152.5zm107.78 426.3l-126.74 0 0 -68.46 22.36 0 -38.61 -117.24 -69.13 0 0 117.24 29.11 0 0 68.46 -126.04 0 0 -68.46 28.48 0 0 -376.8 -28.48 0 0 -68.46 210.08 0 59.66 58.96 0 209.44 -39.31 39.32 46.76 137.55 31.87 0 0 68.46z"/>
          <polygon className="fil0" points="1674.98,1256.86 1674.98,1188.4 1703.46,1188.4 1703.46,811.63 1674.98,811.63 1674.98,743.13 1944.7,743.13 1944.7,867.16 1876.27,867.16 1876.27,811.63 1771.89,811.63 1771.89,965.46 1889.13,965.46 1889.13,1034.56 1771.89,1034.56 1771.89,1188.4 1876.27,1188.4 1876.27,1132.83 1944.7,1132.83 1944.7,1256.86 "/>
          <path className="fil0" d="M2408.23 830.58l-19 -18.97 -85.38 0 0 376.8 85.38 0 19 -18.97 0 -338.86zm8.8 426.3l-210.08 0 0 -68.46 28.47 0 0 -376.8 -28.47 0 0 -68.46 210.08 0 59.67 58.96 0 395.78 -59.67 58.99z"/>
          <path className="fil0" d="M2692.83 865.15l-33.91 196.55 67.11 0 -33.21 -196.55zm30.52 391.72l0 -68.46 24.37 0 -9.5 -57.61 -90.79 0 -10.17 57.61 24.37 0 0 68.46 -126.04 0 0 -68.46 31.87 0 77.92 -445.27 94.22 0 78.59 445.27 31.87 0 0 68.46 -126.71 0z"/>
          <polygon className="fil0" points="3206.5,1256.86 3206.5,1188.4 3235.64,1188.4 3235.64,932.26 3138.71,1256.86 3102.11,1256.86 3005.21,932.26 3005.21,1188.4 3034.35,1188.4 3034.35,1256.86 2908.28,1256.86 2908.28,1188.4 2936.76,1188.4 2936.76,811.63 2908.28,811.63 2908.28,743.13 3018.78,743.13 3120.44,1083.37 3221.4,743.13 3332.53,743.13 3332.53,811.63 3304.09,811.63 3304.09,1188.4 3332.53,1188.4 3332.53,1256.86 "/>
          <polygon className="fil0" points="3391.46,1256.86 3391.46,1188.4 3419.94,1188.4 3419.94,811.63 3391.46,811.63 3391.46,743.13 3661.2,743.13 3661.2,867.16 3592.75,867.16 3592.75,811.63 3488.39,811.63 3488.39,965.46 3605.64,965.46 3605.64,1034.56 3488.39,1034.56 3488.39,1188.4 3592.75,1188.4 3592.75,1132.83 3661.2,1132.83 3661.2,1256.86 "/>
          <path className="fil1" d="M546.04 746.54l-119.96 0 0 61.68 29.14 0 0 247.37 -117.24 -309.06 -84.04 0 0 61.68 28.47 0 0 383.59 -28.47 0 0 61.68 119.29 0 0 -61.68 -29.15 0 0 -255.5 120.64 317.18 52.17 0 0 -445.27 29.15 0 0 -61.68zm16.96 -17.63l0 96.26 -28.47 0 0 445.91 -81.98 0 -90.83 -239.89 0 143.67 29.15 0 0 96.22 -154.55 0 0 -96.22 29.15 0 0 -349.69 -29.15 0 0 -96.26 113.19 0 88.09 232.48 0 -136.22 -28.44 0 0 -96.26 153.84 0z"/>
          <path className="fil1" d="M766.29 836.68l-10.85 -11.52 -54.22 0 -11.52 11.52 0 326.62 11.52 11.54 54.22 0 10.85 -11.54 0 -326.62zm-4.06 -28.48l20.98 21.02 0 341.56 -20.98 21.02 -68.46 0 -21.01 -21.02 0 -341.56 21.01 -21.02 68.46 0zm83.36 -4.73l-57.65 -56.94 -119.93 0 -56.94 56.94 0 393.1 56.94 56.9 119.93 0 57.65 -56.9 0 -393.1zm16.92 -6.78l0 406.63 -67.75 67.78 -133.53 0 -67.76 -67.78 0 -406.63 67.76 -67.78 133.53 0 67.75 67.78z"/>
          <path className="fil1" d="M1235.23 746.54l-324.64 0 0 117.24 61.68 0 0 -55.56 69.81 0 0 383.59 -29.15 0 0 61.68 119.96 0 0 -61.68 -29.15 0 0 -383.59 69.81 0 0 55.56 61.67 0 0 -117.24zm17.63 -17.63l0 152.5 -96.93 0 0 -56.24 -34.59 0 0 349.69 28.51 0 0 96.22 -153.87 0 0 -96.22 28.47 0 0 -349.69 -35.22 0 0 56.24 -96.26 0 0 -152.5 359.88 0z"/>
          <path className="fil1" d="M1484.58 836.68l-10.84 -11.52 -65.07 0 0 163.34 65.74 0 10.17 -10.88 0 -140.94zm-3.39 -28.48l21.01 21.02 0 155.89 -21.01 20.31 -90.12 0 0 -197.22 90.12 0zm122.01 383.6l-31.2 0 -47.44 -141.65 39.32 -39.99 0 -206.69 -57.62 -56.94 -205.34 0 0 61.67 28.46 0 0 383.6 -28.46 0 0 61.67 119.29 0 0 -61.67 -29.15 0 0 -124.02 74.54 0 41.33 124.02 -23.71 0 0 61.67 119.97 0 0 -61.67zm16.92 -16.96l0 96.26 -153.84 0 0 -96.26 16.95 0 -29.81 -90.12 -44.76 0 0 90.12 29.18 0 0 96.26 -154.55 0 0 -96.26 29.15 0 0 -349.68 -29.15 0 0 -96.26 230.42 0 67.79 67.78 0 220.96 -37.27 37.24 39.95 119.96 35.93 0z"/>
          <path className="fil1" d="M1879.66 808.2l0 55.56 61.68 0 0 -117.24 -262.96 0 0 61.68 28.47 0 0 383.59 -28.47 0 0 61.68 262.96 0 0 -117.25 -61.68 0 0 55.57 -111.13 0 0 -160.62 117.2 0 0 -62.35 -117.2 0 0 -160.62 111.13 0zm-17.63 73.19l0 -56.24 -75.91 0 0 126.72 116.57 0 0.67 96.26 -117.24 0 0 126.7 75.91 0 0 -56.23 96.93 0 0 152.5 -298.21 0 0 -96.27 29.15 0 0 -349.68 -29.15 0 0 -96.26 298.21 0 0 152.5 -96.93 0z"/>
          <path className="fil1" d="M2393.98 836.68l-10.81 -11.52 -65.1 0 0 349.68 65.1 0 10.81 -11.54 0 -326.62zm-3.39 -28.48l21.02 21.02 0 341.56 -21.02 21.02 -90.11 0 0 -383.6 90.11 0zm82.7 -4.73l-57.62 -56.94 -205.35 0 0 61.67 28.47 0 0 383.6 -28.47 0 0 61.67 205.35 0 57.62 -56.9 0 -393.1zm17.62 -6.78l0 406.63 -67.78 67.78 -230.43 0 0 -96.26 29.15 0 0 -349.68 -29.15 0 0 -96.26 230.43 0 67.78 67.78z"/>
          <path className="fil1" d="M2692.83 947.82l-17.63 100.32 34.55 0 -16.92 -100.32zm0 -102.33l37.27 219.57 -75.24 0 37.97 -219.57zm153.84 346.32l-31.83 0 -77.96 -445.27 -88.78 0 -77.92 445.27 -31.19 0 0 61.68 119.29 0 0 -61.68 -25.08 0 11.52 -64.39 96.23 0 10.85 64.39 -25.06 0 0 61.68 119.93 0 0 -61.68zm16.96 -16.95l0 96.22 -153.87 0 0 -96.22 21.73 0 -5.44 -30.52 -67.11 0 -5.41 30.52 22.4 0 0 96.22 -154.55 0 0 -96.22 34.59 0 77.92 -445.95 117.92 0 77.92 445.95 33.91 0z"/>
          <path className="fil1" d="M3300.69 1191.8l0 -383.6 28.45 0 0 -61.67 -105.7 0 -103.01 348.34 -103.67 -348.34 -105.09 0 0 61.67 28.47 0 0 383.6 -28.47 0 0 61.67 119.29 0 0 -61.67 -29.15 0 0 -282.6 103.01 344.27 31.19 0 103.02 -344.27 0 282.6 -29.15 0 0 61.67 119.26 0 0 -61.67 -28.45 0zm16.93 -366.64l0 349.68 29.14 0 0 96.26 -154.51 0 0 -96.26 29.15 0 0 -147.06 -72.52 243.31 -56.91 0 -72.52 -243.31 0 147.06 29.14 0 0 96.26 -154.51 0 0 -96.26 29.14 0 0 -349.68 -29.14 0 0 -96.26 135.54 0 90.81 305 90.15 -305 136.18 0 0 96.26 -29.14 0z"/>
          <path className="fil1" d="M3596.14 808.2l0 55.56 61.67 0 0 -117.24 -262.96 0 0 61.68 28.48 0 0 383.59 -28.48 0 0 61.68 262.96 0 0 -117.25 -61.67 0 0 55.57 -111.17 0 0 -160.62 117.28 0 0 -62.35 -117.28 0 0 -160.62 111.17 0zm-17.63 73.19l0 -56.24 -75.91 0 0 126.72 116.57 0 0.7 96.26 -117.28 0 0 126.7 75.91 0 0 -56.23 96.93 0 0 152.5 -298.21 0 0 -96.27 29.15 0 0 -349.68 -29.15 0 0 -96.26 298.21 0 0 152.5 -96.93 0z"/>
          <polygon className="fil1" points="3711.1,1244.63 3701.09,1244.63 3701.09,1239.48 3726.74,1239.48 3726.74,1244.63 3716.7,1244.63 3716.7,1271.08 3711.1,1271.08 "/>
          <polygon className="fil1" points="3732.63,1239.47 3738.54,1239.47 3748.15,1254.44 3757.77,1239.47 3763.69,1239.47 3763.69,1271.08 3758.12,1271.08 3758.12,1248.43 3748.15,1263.34 3747.95,1263.34 3738.06,1248.53 3738.06,1271.08 3732.63,1271.08 "/>
        </g>
      </g>
    </SvgIcon>
  )
}
