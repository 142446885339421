import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ExpandPlusIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M15.99042,10.00073c0,0.43157 -0.34969,0.78125 -0.78125,0.78125h-4.42719v8.13156c0,0.43157 -0.34969,0.78125 -0.78125,0.78125c-0.43156,0 -0.78125,-0.34969 -0.78125,-0.78125v-8.13156h-8.13156c-0.43157,0 -0.78125,-0.34969 -0.78125,-0.78125c0,-0.43156 0.34969,-0.78125 0.78125,-0.78125h8.13156v-8.13157c0,-0.43156 0.34969,-0.78125 0.78125,-0.78125c0.43157,0 0.78125,0.34969 0.78125,0.78125v8.13157h4.42719c0.43156,0 0.78125,0.34969 0.78125,0.78125zM19.69479,10.00073c0,0.43156 -0.34969,0.78125 -0.78125,0.78125h-1.1525c-0.43157,0 -0.78125,-0.34969 -0.78125,-0.78125c0,-0.43157 0.34969,-0.78125 0.78125,-0.78125h1.1525c0.43125,0 0.78125,0.34969 0.78125,0.78125z" fillOpacity="1"></path>
    </SvgIcon>
  )
}
