import React from 'react'
import ExploreNavMenu from './ExploreNavMenu'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AllComponent from './AllComponent'
import { commonStyles } from '../../helpers'

const styles = {
  explore: {
    marginTop: -60,
    ...commonStyles.media(575, {
      marginTop: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 0
    })
  },
  exploreContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  exploreContent: {
    flex: 1
  }
}

class Explore extends React.Component {
  render () {
    const { classes, packages } = this.props
    return (
      <div className={classes.explore}>
        <div className={classes.exploreContainer}>
          <ExploreNavMenu />
          <div className={classes.exploreContent}>
            {packages && packages.length > 0 && <AllComponent />}
          </div>
        </div>
      </div>
    )
  }
}
Explore.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(Object)
}

const mapStateToProps = state => {
  return {
    loggedIn: state.user.loggedIn,
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Explore))
