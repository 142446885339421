import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

/* eslint-disable sort-keys */
const styles = {
  badge: {
    position: 'absolute',
    left: 6,
    top: 6,
    maxWidth: 'calc(100% - 12px)',
    padding: '7px 14px',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.64px',
    textTransform: 'uppercase',
    color: '#ffffff',
    borderRadius: 15,
    backgroundColor: '#01b7d7'
  }
}
/* eslint-enable sort-keys */

class ProjectBadge extends React.Component {
  render () {
    const { classes, label } = this.props
    return (
      <div className={classes.badge}>
        {label}
      </div>
    )
  }
}

ProjectBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
}

export default withStyles(styles)(ProjectBadge)
