import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { ExpandPlusIcon } from '../common/SVG'
import { commonStyles } from '../../helpers'
import { toggleModal } from '../../actions'
import getQuestions from './FAQQuestions'
import ModalPreview from '../common/ModalPreview'

/* eslint-disable sort-keys */
const styles = {
  header: {
    color: '#5334b6',
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 10,
    ...commonStyles.media(480, {
      fontSize: 18,
      width: 212
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18
    })
  },
  line: {
    width: '100%',
    height: window.devicePixelRatio && window.devicePixelRatio > 1
      ? (1 / window.devicePixelRatio) * 2
      : 2,
    borderRadius: 2,
    backgroundColor: '#cccccc'
  },
  expansion: {
    color: '#323232',
    boxShadow: 'none',
    borderRadius: 2,
    padding: 8,
    backgroundColor: '#f6f6f6',
    '&:before': {
      display: 'none'
    },
    '& .MuiIconButton-root': {
      padding: 7,
      color: '#333333'
    },
    ...commonStyles.media(480, {
      padding: '15px 20px'
    })
  },
  expanded: {
    '&.Mui-expanded': {
      margin: 0
    },
    '& .MuiIconButton-root': {
      transform: 'rotate(45deg)',
      color: '#fff',
      backgroundImage: 'linear-gradient(90deg, #3d29b1 0%, #bd6ad3 100%)',
      borderRadius: '50%',
      marginTop: '-10px'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 48
    }
  },
  summary: {
    color: '#323232',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(480, {
      padding: 0,
      fontSize: 16,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      padding: 0,
      fontSize: 16,
      lineHeight: '24px'
    })
  },
  summaryContent: {
    margin: '12px 0',
    '&.Mui-expanded': {
      margin: '12px 0'
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 40px',
    ...commonStyles.media(480, {
      padding: '0 0 0 20px',
      margin: 0,
      width: '100%'
    })
  },
  expandIcon: {
    width: 20,
    height: 20
  },
  questionTitle: {
    color: '#5334b6',
    padding: '10px 0',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '26px',
    ...commonStyles.media(480, {
      fontSize: 14,
      lineHeight: '20px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '20px'
    })
  },
  questionAnswer: {
    color: '#323232',
    marginBottom: 20,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(480, {
      fontSize: 14,
      lineHeight: '20px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '20px'
    }),
    '& p': {
      margin: 0
    },
    '& p:not(:first-child)': {
      marginTop: 10
    },
    '& ul': {
      margin: 0,
      paddingLeft: 10
    },
    '& li': {
      padding: '5px 0',
      listStyle: 'none'
    },
    '& b': {
      fontWeight: 700
    },
    '& a': {
      color: '#01B7D7',
      textDecoration: 'none'
    },
    '& span': {
      color: '#01B7D7',
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }
}
/* eslint-enable sort-keys */

class FAQList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      expandedList: [],
      modalVideo: null,
      openModal: false
    }
    this.questionRefs = {}
    this.scrollOnExpanded = false
  }

  componentDidMount () {
    this.setExpandedAndScrollToQuestionIfNeeded()
  }

  componentDidUpdate (prevProps) {
    const { scrollToId } = this.props
    if (scrollToId && scrollToId !== prevProps.scrollToId) {
      this.setExpandedAndScrollToQuestionIfNeeded()
    }
  }

  toggleVideoModal (video) {
    this.setState({ modalVideo: video, openModal: !!video })
  }

  setExpandedAndScrollToQuestionIfNeeded () {
    const {
      loggedIn,
      questionsType,
      scrollToId,
      toggleModal
    } = this.props
    const expandedList = []
    const questions = getQuestions(questionsType, loggedIn, toggleModal.bind(this, true, 'register'))
    questions.forEach((item, idx) => {
      const shouldExpandAndScroll = !!scrollToId && !!item.questions.find(q => q.id && q.id === scrollToId)
      expandedList[idx] = shouldExpandAndScroll
      if (shouldExpandAndScroll) {
        this.scrollOnExpanded = true
      }
    })
    this.setState({ expandedList })
  }

  toggleExpanded (index) {
    const { expandedList } = this.state
    expandedList[index] = !expandedList[index]
    this.setState({ ...expandedList })
  }

  onExpansionDone (index) {
    const { loggedIn, questionsType, scrollToId } = this.props
    const questionRef = scrollToId && this.questionRefs[scrollToId]
    if (this.scrollOnExpanded && questionRef) {
      const questions = getQuestions(questionsType, loggedIn, toggleModal.bind(this, true, 'register'))
      if (questions[index].questions.find(q => q.id && q.id === scrollToId)) {
        questionRef.scrollIntoView(true)
        window.scrollBy(0, questionRef.getBoundingClientRect().y - 110)
        this.scrollOnExpanded = false
      }
    }
  }

  render () {
    const {
      classes,
      loggedIn,
      questionsType,
      toggleModal
    } = this.props
    const {
      expandedList,
      modalVideo,
      openModal
    } = this.state
    const questions = getQuestions(
      questionsType,
      loggedIn,
      toggleModal.bind(this, true, 'register'),
      this.toggleVideoModal.bind(this)
    )
    return (
      <Grid item xs={12}>
        <div className={classes.line}></div>
        {questions.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <Accordion
                classes={{ expanded: classes.expanded, root: classes.expansion }}
                expanded={expandedList[idx] || false}
                TransitionProps={{ onEntered: this.onExpansionDone.bind(this, idx) }}
              >
                <AccordionSummary
                  expandIcon={<ExpandPlusIcon className={classes.expandIcon} fill="#333333" />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className={classes.summary}
                  classes={{ content: classes.summaryContent }}
                  onClick={this.toggleExpanded.bind(this, idx)}
                >
                  {(idx + 1) + '. ' + item.categories}
                </AccordionSummary>
                <AccordionDetails
                  data-testid='faql-question-container'
                  className={classes.details}
                >
                  {item.questions.map((q, i) => {
                    return (<React.Fragment key={i}>
                      <div
                        data-testid='faql-question'
                        className={classes.questionTitle}
                        ref={ref => {
                          if (q.id) { this.questionRefs[q.id] = ref }
                        }}
                      >
                        {q.title}
                      </div>
                      <div
                        data-testid='faql-answer'
                        className={classes.questionAnswer}
                      >
                        {q.answer}
                      </div>
                    </React.Fragment>)
                  })}
                </AccordionDetails>
              </Accordion>
              <div className={classes.line}></div>
            </React.Fragment>
          )
        })}
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.toggleVideoModal.bind(this, null)}
          video={modalVideo}
          videoWidth={1125}
          title={''}
        />
      </Grid>
    )
  }
}

FAQList.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  questionsType: PropTypes.string,
  scrollToId: PropTypes.string,
  toggleModal: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FAQList))
