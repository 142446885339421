import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  fetchProjectCodeGrouping,
  generateProjectCodes
} from '../../../actions'
import { commonStyles, history } from '../../../helpers/index'
import { BackArrow } from '../../common/SVG/index'
import Typography from '@material-ui/core/Typography'
import UrlifeTextField from '../../common/UrlifeTextField'
import UrlifeButton from '../../common/UrlifeButton'
import download from 'downloadjs'
import streamSaver from 'streamsaver/StreamSaver'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  errorBox: {
    margin: '10px 0',
    color: '#d70101',
    opacity: 0
  },
  submitButton: {
    width: 100,
    margin: '10px auto 0pc auto'
  },
  inputLabel: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#666666',
    '&._focused': {
      color: '#666666'
    },
    '&._error': {
      color: '#666666'
    },
    '&._shrink': {
      transform: 'translate(0, -3.5px) scale(0.875)'
    }
  },
  selectSku: {
    width: '100%',
    '&:after': {
      borderBottomColor: '#5334b6'
    }
  }
}
/* eslint-enable sort-keys */

class GenerateProjectCodes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      formValid: false,
      grouping: null,
      packageSkuId: '',
      quantity: ''
    }
  }

  componentDidMount () {
    const { match, fetchProjectCodeGroupingFunction } = this.props
    fetchProjectCodeGroupingFunction(match.params.groupId)
      .then(
        data => {
          if (data.data && data.data.length > 0) {
            this.setState({ grouping: data.data[0] })
          }
        }
      )
  }

  async saveProjectCodesFile (stream, fileName, fileSize) {
    let fileStream = null
    let fileArray = null
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    if (isChrome) {
      streamSaver.mitm = `${window.location.origin}/mitm.html`
      fileStream = streamSaver.createWriteStream(fileName)
      window.writer = fileStream.getWriter()
    } else {
      fileArray = new Uint8Array(fileSize)
    }
    const reader = stream.getReader()
    let pos = 0
    let readResult = { done: false }
    while (!readResult.done) {
      readResult = await reader.read()
      if (readResult.done) {
        if (isChrome) {
          window.writer.close()
        }
      } else {
        if (isChrome) {
          window.writer.write(readResult.value)
        } else {
          fileArray.set(readResult.value, pos)
        }
        pos += readResult.value.length
      }
    }
    if (!isChrome) {
      download(fileArray, fileName, 'text/csv')
    }
  }

  onSubmit () {
    const { generateProjectCodesFunction } = this.props
    const { grouping, quantity, packageSkuId } = this.state
    const parsedQuantity = parseInt(quantity, 10)
    grouping && generateProjectCodesFunction(grouping.id, parsedQuantity, packageSkuId)
      .then(
        response => {
          const fileSize = response.headers.get('content-length')
          let fileName = grouping.prefix + '_codes.csv'
          const contentDisposition = response.headers.get('content-disposition')
          if (contentDisposition) {
            const parts = contentDisposition.split('"')
            fileName = parts[1]
          }
          this.saveProjectCodesFile(response.body, fileName, fileSize)
        }
      ).catch(e => {
        const error = 'Something went wrong when generating the project codes.'
        this.setState({ error: error })
      })
  }

  onSubmitForm (e) {
    e.preventDefault()
    this.onSubmit()
  }

  onValueChange (e) {
    this.setState({
      [e.target.name]: e.target.value
    }, () => {
      this.formValidator()
    })
  }

  formValidator () {
    const { quantity, packageSkuId } = this.state
    const parsedQuantity = parseInt(quantity.trim(), 10)
    const quantityValid = parsedQuantity > 0 && parsedQuantity <= 1000
    const packageSkuIdValid = packageSkuId.trim().length > 0
    this.setState({
      formValid: quantityValid && packageSkuIdValid
    })
  }

  render () {
    const { classes, packages } = this.props
    const { grouping, error, quantity, formValid, packageSkuId } = this.state
    return (
      <Paper className={classes.paper} elevation={0}>
        <BackArrow
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        {grouping &&
        <div className={classes.formWrapper}>
          <Typography variant='h4' component='h2'>
            <span>{'Generate Project Codes (' + grouping.prefix + ')'}</span>
          </Typography>
          <form
            className={classes.container}
            onSubmit={this.onSubmitForm.bind(this)}
          >
            <UrlifeTextField
              id='project-code-quantity'
              name='quantity'
              label='Quantity'
              autoFocus
              onChange={this.onValueChange.bind(this)}
              placeholder={'Must be between 1-1000'}
              defaultValue={quantity}
            />
            <FormControl>
              <InputLabel
                classes={{
                  error: classNames(classes.inputLabel, '_error'),
                  focused: classNames(classes.inputLabel, '_focused'),
                  root: classes.inputLabel,
                  shrink: classNames(classes.inputLabel, '_shrink')
                }}
              >
                SKU
              </InputLabel>
              <Select
                className={classes.selectSku}
                name='packageSkuId'
                value={packageSkuId}
                onChange={this.onValueChange.bind(this)}>
                {packages.length > 0 &&
                  packages.map(pack => (
                    pack.skus.map(sku => {
                      const packageSkuPrice = parseFloat(sku.price.replace('$', ''))
                      if (!isNaN(packageSkuPrice)) {
                        return (
                          <MenuItem key={sku.id} value={sku.id}>
                            {pack.title + ' - ' +
                            (sku.producerDescription
                              ? sku.producerDescription + ' - '
                              : sku.description
                                ? sku.description + ' - '
                                : '') +
                            sku.price}
                          </MenuItem>
                        )
                      } else return null
                    })
                  ))
                }
              </Select>
            </FormControl>
            <div
              className={classes.errorBox}
              style={error ? { opacity: 1 } : {}}
            >
              {error}
            </div>
            <UrlifeButton
              disabled={!formValid}
              className={classes.submitButton}
              onClick={this.onSubmit.bind(this)}
            >
              Generate
            </UrlifeButton>
          </form>
        </div>
        }
        {!grouping && <div>Loading project code grouping</div>}
      </Paper>
    )
  }
}

GenerateProjectCodes.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchProjectCodeGroupingFunction: PropTypes.func.isRequired,
  generateProjectCodesFunction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchProjectCodeGroupingFunction: function (groupId) {
      return dispatch(fetchProjectCodeGrouping(groupId))
    },
    generateProjectCodesFunction: function (groupId, quantity, packageSkuId) {
      return dispatch(generateProjectCodes(groupId, quantity, packageSkuId))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GenerateProjectCodes))
