import { combineReducers } from 'redux'
import category from './category'
import file from './file'
import fileBatch from './fileBatch'
import fileProgress from './fileProgress'
import front from './front'
import moreMenu from './moreMenu'
import notification from './notification'
import packages from './packages'
import projectList from './projectList'
import skinning from './skinning'
import thumbnails from './thumbnails'
import toggleModal from './toggleModal'
import topBar from './topBar'
import user from './user'

const urlife = combineReducers({
  category,
  file,
  fileBatch,
  fileProgress,
  front,
  moreMenu,
  notification,
  packages,
  projectList,
  skinning,
  thumbnails,
  toggleModal,
  topBar,
  user
})

export default urlife
