import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { NavLink, withRouter } from 'react-router-dom'
import { commonStyles } from '../../helpers'
import { FacebookIcon, InstagramIcon, ToTopIcon, UrlifeLogoGradient, YoutubeIcon } from '../common/SVG'
import smoothscroll from 'smoothscroll-polyfill'
import { ONLY_MARKETING } from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  root: {
  },
  bottomContainer: {
    ...commonStyles.container(),
    height: 110,
    display: 'flex',
    justifyContent: 'space-between',
    ...commonStyles.media(959, {
      height: 120,
      flexDirection: 'column',
      justifyContent: 'space-around'
    }),
    ...commonStyles.media(480, {
      justifyContent: 'space-evenly !important',
      height: '160px !important'
    })
  },
  divider: {
    marginTop: 30,
    width: '100%',
    height: 1,
    backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    ...commonStyles.media(959, {
      justifyContent: 'space-around'
    }),
    ...commonStyles.media(480, {
      height: '100%',
      flexDirection: 'column'
    })
  },
  logo: {
    height: 40,
    width: 'auto',
    margin: 'auto',
    ...commonStyles.media(480, {
      height: 35
    })
  },
  copyright: {
    opacity: 0.6,
    color: '#333333',
    fontSize: 12,
    fontWeight: 400,
    margin: 'auto auto auto 20px',
    textAlign: 'center',
    ...commonStyles.media(480, {
      margin: 'auto',
      fontSize: 10
    })
  },
  links: {
    display: 'flex',
    margin: 'auto 10px'
  },
  link: {
    margin: 'auto 10px',
    fontSize: 12,
    fontWeight: 500,
    textDecoration: 'none',
    color: '#323232',
    textAlign: 'center',
    ...commonStyles.media(480, {
      fontSize: 10,
      fontWeight: 400,
      marginBottom: 5
    })
  },
  toTop: {
    margin: 'auto auto auto 20px',
    width: 48,
    height: 48,
    cursor: 'pointer'
  },
  iconGrid: {
    ...commonStyles.media(480, {
      flexDirection: 'column-reverse'
    })
  },
  icons: {
    display: 'flex',
    margin: 'auto 10px',
    '& a': {
      margin: 'auto 4px'
    }
  },
  socialIcon: {
    margin: 'auto 7px',
    width: 'auto',
    height: 20
  }
}
/* eslint-enable sort-keys */

class Footer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentYear: new Date().getFullYear(),
      scrollBar: false
    }
    this.checkScroll = this.checkScroll.bind(this)
  }

  checkScroll () {
    const scrollBar = this.state.scrollBar
    if (window.innerHeight < document.body.clientHeight && !scrollBar) {
      this.setState({ scrollBar: true })
    } else if (window.innerHeight >= document.body.clientHeight && scrollBar) {
      this.setState({ scrollBar: false })
    }
  }

  componentDidMount () {
    if (this.props.parallax) {
      this.setState({ scrollBar: true })
    } else {
      this.checkScroll()
      window.addEventListener('resize', this.checkScroll)
    }
  }

  componentWillUnmount () {
    if (!this.props.parallax) {
      window.removeEventListener('resize', this.checkScroll)
    }
  }

  goToTop () {
    smoothscroll.polyfill()
    if (this.props.parallax) {
      const scrollRef = this.props.getScrollRef().current
      scrollRef.scroll({
        behavior: 'smooth',
        top: 0
      })
    } else {
      window.scroll({
        behavior: 'smooth',
        top: 0
      })
    }
  }

  render () {
    const { classes } = this.props
    const { scrollBar, currentYear } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.divider}></div>
        <div className={classes.bottomContainer}>
          <div className={classes.footer}>
            <UrlifeLogoGradient className={classes.logo}/>
            <span className={classes.copyright}>©2018-{currentYear} URLIFE Media, Inc. All rights reserved.</span>
          </div>
          <div className={`${classes.footer} ${classes.iconGrid}`}>
            {ONLY_MARKETING
              ? null
              : (
              <div className={classes.links}>
                <NavLink to="/privacy" className={classes.link}>Privacy Policy</NavLink>
                <NavLink to="/terms" className={classes.link}>Terms and Conditions</NavLink>
              </div>
                )}
            <div className={classes.icons}>
              <a target='_blank' rel='noopener noreferrer'
                href='https://www.youtube.com/channel/UC58PQFT2RySjKSovjaH41ZA'>
                <YoutubeIcon className={classes.socialIcon}/>
              </a>
              <a target='_blank' rel='noopener noreferrer'
                href='https://www.instagram.com/urlifemedia/'>
                <InstagramIcon className={classes.socialIcon}/>
              </a>
              <a target='_blank' rel='noopener noreferrer'
                href='https://www.facebook.com/UrLifeMedia/'>
                <FacebookIcon className={classes.socialIcon}/>
              </a>
              { scrollBar && <ToTopIcon className={classes.toTop} onClick={this.goToTop.bind(this)}/> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  getScrollRef: PropTypes.func,
  parallax: PropTypes.bool
}

export default withRouter(withStyles(styles)(Footer))
