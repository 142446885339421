import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import {
  deleteProjectNotes,
  getProjectNotes,
  updateProjectNotes
} from '../../../actions'
import { ROLES } from '../../../constants'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

/* eslint-disable sort-keys */
const styles = {
  infoText: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#323232',
    '&._error': {
      color: '#d70101'
    }
  },
  editorWrapper: {
    borderRadius: 2,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.07)'
  },
  toolbar: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#000000'
  },
  editor: {
    fontFamily: 'Arial',
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: '0 10px',
    minHeight: 500
  }
}
/* eslint-enable sort-keys */

class ProjectNotes extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      contentState: null,
      error: '',
      isLoading: false,
      isNew: false,
      isSaving: false,
      loadingDone: false
    }
  }

  componentDidMount () {
    const {
      projectId,
      getProjectNotesFunction
    } = this.props
    this.setState({ isLoading: true })
    getProjectNotesFunction(projectId)
      .then(
        response => {
          try {
            const contentState = JSON.parse(response.data.notes)
            this.setState({
              contentState: contentState,
              isLoading: false,
              loadingDone: true
            })
          } catch (error) {
            this.setState({
              error: 'Project notes data corrupted.',
              isLoading: false
            })
          }
        },
        fail => {
          if (fail.message === 'That project does not have notes') {
            this.setState({
              isLoading: false,
              isNew: true,
              loadingDone: true
            })
          } else {
            this.setState({
              error: 'Failed to fetch project notes.',
              isLoading: false
            })
          }
        }
      )
  }

  setEditorRef (ref) {
    if (ref) {
      ref.focus()
    }
  }

  saveNotes () {
    const {
      projectId,
      updateProjectNotesFunction,
      deleteProjectNotesFunction
    } = this.props
    const { contentState, loadingDone, isSaving, isNew } = this.state
    if (!loadingDone || isSaving) {
      return
    }
    this.setState({ isSaving: true })
    const isEmpty =
      !contentState ||
      contentState.blocks.length === 0 ||
      (contentState.blocks.length === 1 && !contentState.blocks[0].text)
    if (!isEmpty) {
      updateProjectNotesFunction(projectId, JSON.stringify(contentState))
        .then(
          response => {
            this.setState({ isNew: false, isSaving: false })
          },
          fail => {
            this.setState({
              error: 'Unable to save project notes.',
              isSaving: false
            })
          }
        )
    } else if (!isNew) {
      deleteProjectNotesFunction(projectId)
        .then(
          response => {
            this.setState({ isNew: true, isSaving: false })
          },
          fail => {
            if (fail.error === 'That project does not have notes') {
              this.setState({ isSaving: false })
            } else {
              this.setState({
                error: 'Unable to save project notes.',
                isSaving: false
              })
            }
          }
        )
    } else {
      this.setState({ isSaving: false })
    }
  }

  onContentStateChange (content) {
    this.setState({ contentState: content })
  }

  render () {
    const { classes, roleId } = this.props
    const { contentState, error, isLoading, loadingDone, isSaving } = this.state
    const readOnly = roleId === ROLES.EDITOR || isLoading || isSaving
    return (
      <div>
        {isLoading && <div className={classes.infoText}>Loading notes...</div>}
        {!isLoading && <div>
          {error && <div className={classNames(classes.infoText, '_error')}>{error}</div>}
          {loadingDone &&
          <Editor
            initialContentState={contentState}
            wrapperClassName={classes.editorWrapper}
            toolbarClassName={classes.toolbar}
            editorClassName={classes.editor}
            readOnly={readOnly}
            toolbarHidden={roleId === ROLES.EDITOR}
            editorRef={this.setEditorRef.bind(this)}
            onContentStateChange={this.onContentStateChange.bind(this)}
            onBlur={this.saveNotes.bind(this)}
          />
          }
        </div>}
      </div>
    )
  }
}

ProjectNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteProjectNotesFunction: PropTypes.func.isRequired,
  getProjectNotesFunction: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  roleId: PropTypes.number.isRequired,
  updateProjectNotesFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId
  }
}

function mapDispatchToProps (dispatch) {
  return {
    deleteProjectNotesFunction: function (projectId) {
      return dispatch(deleteProjectNotes(projectId))
    },
    getProjectNotesFunction: function (projectId) {
      return dispatch(getProjectNotes(projectId))
    },
    updateProjectNotesFunction: function (projectId, notes) {
      return dispatch(updateProjectNotes(projectId, notes))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectNotes))
