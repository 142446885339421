import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  ParallaxGroup: {
    position: 'relative',
    height: '100vh',
    width: '100vw',
    overflow: 'visible',
    transformStyle: 'preserve-3d',
    ...commonStyles.media(480, {
      transformStyle: 'flat'
    })
  }
}
/* eslint-enable sort-keys */

class ParallaxGroup extends React.Component {
  render () {
    const { classes, children, index, sectionRef } = this.props
    return (
      <div
        ref={sectionRef || null }
        className={`${classes.ParallaxGroup} ${this.props.className}`}
        style={{ zIndex: index }}
      >
        {children}
      </div>
    )
  }
}

ParallaxGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  index: PropTypes.number,
  sectionRef: PropTypes.object
}

export default withStyles(styles)(ParallaxGroup)
