import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  addCategory,
  createCategoryFile,
  deleteCategory,
  getCategory,
  updateCategory
} from '../../actions'
import { fileProgressStatus } from '../../reducers/fileProgress'
import EditTable from './EditTable/EditTable'

class Categories extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSortAnimationId: false,
      creatingNewItem: false,
      duplicateDisplayOrder: [],
      items: props.categories.sort(this.compareFields),
      newItem: false,
      sortAnimationToBottomId: false,
      sortAnimationToTopId: false,
      thumbWait: false
    }
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }

      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
    this.fieldSettings = [
      {
        default: true,
        field: 'isHidden',
        name: 'Active',
        type: 'checkboxInverted',
        width: 90
      },
      {
        field: 'id',
        name: 'ID',
        type: 'string',
        width: 90
      },
      {
        field: 'name',
        name: 'Name',
        type: 'text'
      },
      {
        field: 'promptsTitle',
        name: 'Prompts Title',
        type: 'text'
      },
      {
        field: 'displayOrder',
        name: 'Sorting',
        type: 'sort',
        width: 130
      }
    ]
    this.existingFieldSettings = [
      {
        field: 'image',
        name: 'Image',
        type: 'uploadFile',
        width: 170
      },
      {
        field: 'icon',
        name: 'Icon',
        type: 'uploadFile',
        width: 170
      }
    ]
    this.bogusFields = [
      {
        field: '',
        name: '',
        type: 'string',
        width: 170
      }
    ]
  }

  /**
   * @param {object} field
   * @param {object} fieldsData
   * @param {boolean} [apply] - save to sever after updating data
   * @param {boolean} [needSort] - sort after changing
   */
  onChangeField (id, fieldsData, apply = false, needSort = false, callback = null) {
    const { items, duplicateDisplayOrder } = this.state

    const newStateData = {}

    newStateData.items = items.map(field => (
      field.id === id
        ? { ...field, ...fieldsData }
        : field
    ))
    if (needSort) newStateData.items = newStateData.items.sort(this.compareFields)

    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = items.filter(it => it.id !== id &&
        it.displayOrder === +fieldsData.displayOrder).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(id)
        ? duplicateDisplayOrder.concat(id)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== id)
    }

    this.setState(newStateData, callback)

    if (apply) this.applyChanges(id, fieldsData)
  }

  /* exchange 2 items positions with animation */
  exchangeFieldOrderPosition (currentItemIndex, changing) {
    const { items } = this.state

    const exchangeItemIndex = currentItemIndex + changing
    const currentItemPosition = items[currentItemIndex].displayOrder
    const exchangeItemPosition = items[exchangeItemIndex].displayOrder
    const target = changing > 0 ? 'bottom' : 'top'

    // start animation
    this.setState({
      activeSortAnimationId: items[currentItemIndex].id,
      sortAnimationToBottomId: items[target === 'bottom' ? currentItemIndex : exchangeItemIndex].id,
      sortAnimationToTopId: items[target === 'top' ? currentItemIndex : exchangeItemIndex].id
    })

    // end animation with timeout
    window.setTimeout(() => {
      this.setState({
        activeSortAnimationId: false,
        items: items.map((field, fieldIndex) => {
          if (fieldIndex === currentItemIndex) return { ...field, displayOrder: exchangeItemPosition }
          if (fieldIndex === exchangeItemIndex) return { ...field, displayOrder: currentItemPosition }
          return field
        }).sort(this.compareFields),
        sortAnimationToBottomId: false,
        sortAnimationToTopId: false
      })
    }, 600)

    // apply
    if (items[currentItemIndex].id) {
      this.applyChanges(
        items[currentItemIndex].id,
        { displayOrder: exchangeItemPosition }
      )
    }
    if (items[exchangeItemIndex].id) {
      this.applyChanges(
        items[exchangeItemIndex].id,
        { displayOrder: currentItemPosition }
      )
    }
  }

  // check filing items fields
  validateItem (item) {
    const { duplicateDisplayOrder } = this.state
    // filter errors
    return this.fieldSettings.filter(fieldSettingsItem => {
      const fieldName = fieldSettingsItem.field
      const value = item[fieldName]
      if (fieldName === 'id') return false

      return value.toString().length === 0
    }).length === 0 && !duplicateDisplayOrder.includes(item.tempId || item.id)
  }

  onChangeNewItem (fieldsData) {
    const { newItem, items, duplicateDisplayOrder } = this.state

    const newStateData = {
      newItem: { ...newItem, ...fieldsData }
    }
    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = fieldsData.displayOrder &&
        items.filter(it => it.id !== newItem.tempId &&
        it.displayOrder === +fieldsData.displayOrder).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(newItem.tempId)
        ? duplicateDisplayOrder.concat(newItem.tempId)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== newItem.tempId)
    }
    this.setState(newStateData)
  }

  /**
   * @param {string|null} id
   * @param {object} fieldsData
   */
  applyChanges (id, fieldsData) {
    const { updateCategoryFunction } = this.props
    updateCategoryFunction(id, fieldsData)
  }

  /**
   * add new item without saving
   * */
  addNewTempItem () {
    const { items } = this.state
    const sortOrderValues = items.map(item => +item.displayOrder)
    const maxSortOrder = items.length ? Math.max(...sortOrderValues) : 0

    const newItem = this.fieldSettings.reduce((ob, fieldSettingsItem) => {
      if (fieldSettingsItem.field === 'displayOrder') {
        ob[fieldSettingsItem.field] = maxSortOrder + 100
      } else {
        ob[fieldSettingsItem.field] = typeof fieldSettingsItem.default !== 'undefined' ? fieldSettingsItem.default : ''
      }
      return ob
    }, {
      tempId: `newItem-${Date.now()}`
    })
    this.setState({ newItem })
  }

  onSubmitNewItem (callback = null) {
    const { newItem: { tempId, id, ...useItem } } = this.state
    const { addCategoryFunction } = this.props

    this.setState({
      creatingNewItem: true
    }, async () => {
      try {
        const newItem = await addCategoryFunction(useItem)
        this.setState({
          creatingNewItem: false,
          items: this.state.items.concat(newItem).sort(this.compareFields),
          newItem: false
        }, () => {
          callback && callback(newItem.id)
        })
      } catch (e) {}
    })
  }

  onDeleteItem (id) {
    const { deleteCategoryFunction } = this.props

    this.setState({
      items: this.state.items.filter(item => item.id !== id)
    })
    deleteCategoryFunction(id)
  }

  uploadFile (id, file, field) {
    const { createCategoryFileFunction } = this.props
    const fileInfo = { mimeType: file.type }
    this.setState({ updatedRow: id, updatedType: field })
    createCategoryFileFunction(
      field === 'Image' ? 'categoryImage' : 'categoryIcon',
      { categoryId: id, files: [fileInfo], type: 'file' },
      file
    )
  }

  getUpdatedCategory (times = 0) {
    const { updatedRow, updatedType, items } = this.state
    const { getCategoryFunction } = this.props
    this.setState({ thumbWait: true })
    getCategoryFunction(updatedRow).then(
      row => {
        if (!row.data || row.data.length < 1) return false
        if ((updatedType === 'Image' && row.data[0].imageUrl) ||
          (updatedType === 'Icon' && row.data[0].iconUrl)) {
          this.setState({
            items: items.map(cat => {
              this.setState({ thumbWait: false })
              if (cat.id === row.data[0].id) return row.data[0]
              return cat
            })
          })
        } else if (times < 30) {
          window.setTimeout(() => this.getUpdatedCategory(++times), 500)
        }
      }
    )
  }

  componentDidUpdate (prevProps, prevState) {
    const { fileProgress } = this.props
    const oldExploreUploads = prevProps.fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'category' &&
      file.status === fileProgressStatus.inProgress)
    const newExploreUploads = fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'category' &&
      file.status === fileProgressStatus.inProgress)
    if (oldExploreUploads.length > 0 &&
      newExploreUploads.length === 0) {
      this.getUpdatedCategory()
    }
  }

  render () {
    const {
      items,
      newItem,
      creatingNewItem,
      sortAnimationToTopId,
      sortAnimationToBottomId,
      activeSortAnimationId,
      duplicateDisplayOrder,
      thumbWait
    } = this.state
    const existingFieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.existingFieldSettings[0],
      this.existingFieldSettings[1],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4]
    ]
    const newFieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.bogusFields[0],
      this.bogusFields[0],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4]
    ]

    return (
      <EditTable
        title={'Edit categories'}
        message={thumbWait ? 'Waiting for file to process and generate a thumbnail.' : ''}
        items={items}
        fieldList={existingFieldList}
        newItem={newItem}
        newFieldList={newFieldList}
        newItemIsValid={!creatingNewItem && newItem && this.validateItem(newItem)}
        sortAnimationToTopId={sortAnimationToTopId}
        sortAnimationToBottomId={sortAnimationToBottomId}
        activeSortAnimationId={activeSortAnimationId}
        duplicateDisplayOrder={duplicateDisplayOrder}
        onChangeField={this.onChangeField.bind(this)}
        exchangeFieldOrderPosition={this.exchangeFieldOrderPosition.bind(this)}
        uploadFile={this.uploadFile.bind(this)}
        addNewTempItem={this.addNewTempItem.bind(this)}
        newButtonLabel={'New category'}
        onChangeNewItem={this.onChangeNewItem.bind(this)}
        onSubmitNewItem={this.onSubmitNewItem.bind(this)}
      />
    )
  }
}

Categories.propTypes = {
  addCategoryFunction: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  createCategoryFileFunction: PropTypes.func.isRequired,
  deleteCategoryFunction: PropTypes.func.isRequired,
  fileProgress: PropTypes.array,
  getCategoryFunction: PropTypes.func.isRequired,
  updateCategoryFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories,
    fileProgress: Object.values(state.fileProgress)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addCategoryFunction: function (categoryData) {
      return dispatch(addCategory(categoryData))
    },
    createCategoryFileFunction: function (type, file, fileObject) {
      return dispatch(createCategoryFile(type, file, fileObject))
    },
    deleteCategoryFunction: function (categoryId) {
      return dispatch(deleteCategory(categoryId))
    },
    getCategoryFunction: function (categoryId) {
      return dispatch(getCategory(categoryId))
    },
    updateCategoryFunction: function (categoryId, categoryData) {
      return dispatch(updateCategory(categoryId, categoryData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
