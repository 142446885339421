import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CastIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 24 20' >
      <g><g><g><g><g><path fill="#01b7d7" d="M12.477 3.647c0 .226-.181.409-.404.409a.406.406 0 0 1-.403-.409c0-.225.18-.408.403-.408.223 0 .404.183.404.408zm-1.837 0c0 .805.643 1.46 1.433 1.46s1.434-.655 1.434-1.46c0-.804-.643-1.459-1.434-1.459-.79 0-1.433.655-1.433 1.46zm-3.796.07l6.432 10.755H3.25c-.852 0-1.545-.707-1.545-1.576V7.77zm8.532 3.274l3.065 5.048v.857c0 .87-.692 1.576-1.544 1.576h-2.415l-2.91-4.866zm3.58 2.504a.52.52 0 0 0 .515-.525V2.652c0-1.448-1.155-2.626-2.574-2.626H3.25C1.83.026.676 1.204.676 2.652v10.244c0 1.449 1.155 2.627 2.575 2.627h13.646c1.42 0 2.574-1.178 2.574-2.627V11.89c0-.012-.002-.023-.003-.035-.002-.02-.003-.039-.006-.058-.003-.018-.008-.034-.013-.05-.004-.016-.008-.032-.014-.048-.008-.02-.017-.038-.027-.056-.005-.01-.008-.02-.014-.03L15.97 5.975a.507.507 0 0 0-.725-.16l-4.21 2.894-3.612-6.04a.509.509 0 0 0-.755-.142L1.707 6.44V2.652c0-.869.693-1.576 1.545-1.576h13.646c.851 0 1.544.707 1.544 1.576V8.97c0 .29.23.525.515.525z"/></g></g></g><g><g><path fill="#01b7d7" d="M18.598 8.7c-2.96 0-5.368 2.458-5.368 5.479 0 3.02 2.408 5.478 5.368 5.478 2.96 0 5.369-2.458 5.369-5.478 0-3.021-2.408-5.479-5.369-5.479z"/></g><g><g><g><path fill="#fff" d="M20.153 14.11a.244.244 0 0 1-.241.246h-1.366v2.562a.244.244 0 0 1-.24.246.244.244 0 0 1-.242-.246v-2.562h-2.508a.244.244 0 0 1-.241-.246c0-.136.107-.246.24-.246h2.51v-2.561c0-.136.107-.246.24-.246s.241.11.241.246v2.561h1.366c.133 0 .241.11.241.246zm1.143 0a.244.244 0 0 1-.241.246h-.356a.244.244 0 0 1-.24-.246c0-.136.107-.246.24-.246h.356c.133 0 .24.11.24.246z"/></g></g></g></g></g></g>
    </SvgIcon>
  )
}
