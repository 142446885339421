import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeButton from '../../common/UrlifeButton'
import CouponForm from './CouponForm'
import { commonStyles, history, toLocale } from '../../../helpers/index'
import {
  deleteCoupon,
  fetchUserCoupons,
  userInfoById
} from '../../../actions'
import { BackArrow } from '../../common/SVG/index'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  backButton: {
    cursor: 'pointer',
    margin: 10
  },
  couponsContainer: {
    display: 'table-row',
    '&:nth-child(even)': {
      backgroundColor: '#eeeeee'
    }
  },
  couponsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row',
    backgroundColor: '#eeeeee'
  },
  headerCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 5px 0',
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  cell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    padding: '0px 5px'
  },
  addButton: {
    margin: '20px 0px'
  },
  deleteButton: {
    margin: '5px 0px'
  },
  listItem: {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent'
    }
  }
}
/* eslint-enable sort-keys */

class UrlifeUserCoupons extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      coupons: [],
      formState: false,
      user: null
    }
    this.handleClickDelete = this.handleClickDelete.bind(this)
  }

  componentDidMount () {
    const { match, dispatch } = this.props
    const { userId } = match.params

    userId && dispatch(userInfoById(userId))
      .then(
        response => {
          this.setState(
            { user: response.data }
          )
          this.fetchCoupons()
        }
      )
  }

  fetchCoupons () {
    const { dispatch } = this.props
    const { id } = this.state.user
    dispatch(fetchUserCoupons(id))
      .then(response => {
        this.setState({ coupons: response.data })
      })
  }

  onBackClick () {
    history.goBack()
  }

  handleClickAdd () {
    this.setState({ formState: true })
  }

  handleClickDelete (couponId) {
    const { user } = this.state
    const { dispatch } = this.props
    dispatch(deleteCoupon(user.id, couponId))
      .then(() => {
        this.fetchCoupons()
      })
  }

  handleCloseForm () {
    this.setState({ formState: false })
    this.fetchCoupons()
  }

  render () {
    const { classes, packages } = this.props
    const { formState, user, coupons } = this.state
    const dateOptions = {
      date: true,
      full: false,
      time: false
    }
    return (
      <div>
        {!formState &&
        <Paper className={classes.paper} elevation={0}>
          <BackArrow className={classes.backButton} onClick={this.onBackClick} />
          <Typography variant="h5" component="h3">
            <span className={classes.userTitleText}>Coupons of user: </span>
            {user && <span className={classes.userName}>{user.fullname}, </span>}
            {user && <span className={classes.userEmail}>{user.email}</span>}
          </Typography>
          <UrlifeButton
            className={classes.addButton}
            onClick={this.handleClickAdd.bind(this)}
          >
            Add Coupon
          </UrlifeButton>
          <div
            className={classes.couponsTable}
          >
            <div className={classes.headerRow}>
              <div className={classes.headerCell}>
                Title
              </div>
              <div className={classes.headerCell}>
                Description
              </div>
              <div className={classes.headerCell}>
                Amount Off
              </div>
              <div className={classes.headerCell}>
                Percent Off
              </div>
              <div className={classes.headerCell}>
                {"SKU's"}
              </div>
              <div className={classes.headerCell}>
                Redeemed
              </div>
              <div className={classes.headerCell}>
                Expiration date
              </div>
              <div className={classes.headerCell}>
                &nbsp;
              </div>
            </div>
            {coupons && coupons.map((coupon, idx) => {
              return (
                <div key={idx} className={classes.couponsContainer}>
                  <span className={classes.cell}>{coupon.title}</span>
                  <span className={classes.cell}>{coupon.description}</span>
                  <span className={classes.cell}>{coupon.amount_off ? `$ ${coupon.amount_off / 100}` : ' '}</span>
                  <span className={classes.cell}>{coupon.percent_off ? `${coupon.percent_off} %` : ' '}</span>
                  <div className={classes.cell}>
                    {packages.map((pack, idxPack) => {
                      return (
                        pack.skus.map((sku, idxSku) => {
                          if (coupon.skus && coupon.skus.includes(sku.id)) {
                            return (
                              <div
                                key={`${idxPack}${idxSku}`}
                                className={classes.listItem}
                              >
                                {`${pack.title}, ${sku.description} ${sku.price}`}
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      )
                    })}
                  </div>
                  <span className={classes.cell}>{coupon.redeemed ? 'Yes' : 'No'}</span>
                  <span className={classes.cell}>
                    {coupon.expiration ? toLocale(new Date(coupon.expiration), dateOptions) : 'N/A'}
                  </span>
                  <span className={classes.cell}>
                    <UrlifeButton
                      className={classes.deleteButton}
                      onClick={() => this.handleClickDelete(coupon.id)}
                    >
                      Delete
                    </UrlifeButton>
                  </span>
                </div>
              )
            })}
          </div>
        </Paper>
        }
        {formState &&
          <CouponForm
            user={user}
            handleCloseForm={this.handleCloseForm.bind(this)}
          />
        }
      </div>
    )
  }
}

UrlifeUserCoupons.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(UrlifeUserCoupons))
