import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { commonStyles } from '../../../helpers'
import { ParallaxListIcon } from '../../common/SVG'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  root: {
    marginTop: 65,
    width: '100%'
  },
  header: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    position: 'relative',
    marginLeft: '-50vw',
    left: '50%',
    backgroundColor: ({ headerColor }) => (headerColor) || 'none',
    fontWeight: 400,
    textAlign: 'center',
    '& span': {
      marginTop: 10,
      fontSize: 48,
      lineHeight: '50px'
    },
    '& p': {
      maxWidth: 975,
      fontSize: 21
    },
    ...commonStyles.media(1280, {
      '& span': {
        fontSize: 40,
        lineHeight: '42px'
      },
      '& p': {
        maxWidth: 920,
        fontSize: 18
      }
    }),
    ...commonStyles.media(1125, {
      '& span': {
        fontSize: 34,
        lineHeight: '38px'
      },
      '& p': {
        maxWidth: 780,
        fontSize: 16
      }
    }),
    ...commonStyles.media(991, {
      padding: '10px 15px',
      '& span': {
        fontSize: 28,
        lineHeight: '32px'
      },
      '& p': {
        maxWidth: 680,
        fontSize: 14
      }
    }),
    ...commonStyles.media(767, {
      '& span': {
        fontSize: 24,
        lineHeight: '28px'
      },
      '& p': {
        maxWidth: 580,
        fontSize: 12
      }
    }),
    ...commonStyles.media(650, {
      '& span': {
        fontSize: 22,
        lineHeight: '24px'
      },
      '& p': {
        maxWidth: 380,
        fontSize: 12
      }
    })
  },
  logo: {
    height: 38,
    width: 'auto',
    margin: '0px 0px -3px 14px',
    ...commonStyles.media(1280, {
      height: 30
    }),
    ...commonStyles.media(1125, {
      height: 28
    }),
    ...commonStyles.media(991, {
      height: 25
    }),
    ...commonStyles.media(767, {
      height: 22
    })
  },
  packageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1065,
    margin: 'auto',
    background: ({ packageBackground }) => (packageBackground) || 'none',
    color: '#4a30b5',
    ...commonStyles.media(650, {
      height: 'auto'
    })
  },
  packageTitle: {
    marginTop: 34,
    fontSize: 36,
    fontWeight: 700,
    textTransform: 'uppercase',
    ...commonStyles.media(767, {
      marginTop: 25,
      fontSize: 28
    }),
    ...commonStyles.media(650, {
      marginTop: 20,
      fontSize: 22
    })
  },
  packageSubtitle: {
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 400,
    margin: '0px 0px 34px 0px',
    ...commonStyles.media(767, {
      fontSize: 16,
      margin: '0px 0px 25px 0px'
    }),
    ...commonStyles.media(650, {
      fontSize: 14,
      margin: '0px 0px 20px 0px'
    })
  },
  bulletList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '68%',
    height: ({ listHeight }) => listHeight || 270,
    marginLeft: 42,
    ...commonStyles.media(1125, {
      width: '75%'
    }),
    ...commonStyles.media(991, {
      width: '90%',
      marginLeft: 32
    }),
    ...commonStyles.media(767, {
      width: '100%',
      marginLeft: 20
    }),
    ...commonStyles.media(650, {
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      height: 'auto',
      width: '90%',
      marginLeft: 20
    })
  },
  bullet: {
    padding: '7px 5px',
    width: '38%',
    '& span': {
      textTransform: 'none',
      color: ({ bulletColor }) => bulletColor || '#323232',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '2px',
      ...commonStyles.media(991, {
        fontSize: 12
      })
    },
    '& svg': {
      margin: '0px 8px -4px -24px',
      width: 'auto',
      height: 16,
      ...commonStyles.media(991, {
        height: 14
      })
    },
    ...commonStyles.media(767, {
      width: '40%',
      marginLeft: 20
    }),
    ...commonStyles.media(650, {
      width: '100%',
      padding: '4px 2px'
    })
  },
  gift: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px auto 40px auto',
    width: 350,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      width: 350,
      height: 1,
      backgroundColor: '#dfdfdf'
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: 350,
      height: 1,
      backgroundColor: '#dfdfdf'
    },
    '& p': {
      width: 201,
      margin: '25px auto',
      color: '#323232',
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '22px',
      textAlign: 'center'
    },
    ...commonStyles.media(650, {
      margin: '20px auto 20px auto',
      width: 240,
      '&::before': {
        width: 240
      },
      '&::after': {
        width: 240
      },
      '& p': {
        width: 180,
        margin: '10px auto',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '22px'
      }
    })
  },
  free: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '30px auto 50px auto',
    width: 250
  },
  price: {
    margin: 0,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 36,
    fontWeight: 400,
    textAlign: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 22,
      width: 110,
      height: 2,
      backgroundColor: '#4a30b5',
      ...commonStyles.media(650, {
        top: 18,
        width: 90
      })
    },
    ...commonStyles.media(650, {
      marginTop: 40,
      fontSize: 30
    })
  },
  text: {
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    ...commonStyles.media(650, {
      fontSize: 18
    })
  },
  description: {
    marginTop: 5,
    fontSize: 18,
    fontWeight: 500,
    ...commonStyles.media(650, {
      fontSize: 14
    })
  }
})
/* eslint-enable sort-keys */

const ExclusivePackage = ({
  bulletColor,
  free,
  gift,
  headerColor,
  headerLogo,
  headerSubtitle,
  headerTitle,
  listHeight,
  packageBackground,
  packages,
  packageSkuId,
  packageSubtitle,
  packageTitle,
  packageType
}) => {
  const classes = useStyles({
    bulletColor,
    headerColor,
    listHeight,
    packageBackground
  })
  const pack = packages && packages.length > 0 && packages.find(pack => pack.type === packageType)
  const packSku = pack && packageSkuId && pack.skus.find(packSku => packSku.id === packageSkuId)
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span>
          {headerTitle}
          {headerLogo && headerLogo({ className: classes.logo })}
        </span>
        <p>{headerSubtitle}</p>
      </div>
      {pack && <div className={classes.packageContainer}>
        <span className={classes.packageTitle}>{packageTitle || pack.title}</span>
        <p className={classes.packageSubtitle}>{packageSubtitle || pack.subtitle}</p>
        {pack.jsonBulletList && pack.jsonBulletList.length > 0 && <div
          data-testid='ep-list'
          className={classes.bulletList}
        >
          {pack.jsonBulletList.map((text, idx) => (
            <div key={idx} className={classes.bullet}>
              <ParallaxListIcon color={bulletColor || null} />
              <span>{text}</span>
            </div>
          ))}
        </div>}
        {gift && <div className={classes.gift}>
          <p>{gift}</p>
        </div>}
        {free && packSku && <div className={classes.free}>
          <span className={classes.price}>{packSku.price}</span>
          <span className={classes.text}>complimentary</span>
          <span className={classes.description}>{packSku.description}</span>
        </div>}
      </div>}
    </div>
  )
}

ExclusivePackage.propTypes = {
  bulletColor: PropTypes.string,
  free: PropTypes.bool,
  gift: PropTypes.string,
  headerColor: PropTypes.string.isRequired,
  headerLogo: PropTypes.func,
  headerSubtitle: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  listHeight: PropTypes.number,
  packageBackground: PropTypes.string,
  packages: PropTypes.array,
  packageSkuId: PropTypes.string,
  packageSubtitle: PropTypes.string,
  packageTitle: PropTypes.string,
  packageType: PropTypes.number.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default connect(mapStateToProps)(ExclusivePackage)
