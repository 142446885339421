import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history } from '../../helpers'
import {
  projectInfo,
  setTopBarHeaders,
  updateProject,
  updateProjectSku
} from '../../actions'
import ScheduleCallForm from '../common/ScheduleCallForm'
import ThankYou from './ThankYou'
import { CONTACT_CHOICE, PROJECT_STATUSES } from '../../constants'

class MovieScheduleCall extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      appointmentDate: null,
      project: null,
      projectSkuIndex: 0,
      submitted: false
    }
  }

  async componentDidMount () {
    const { match, projectInfoFunction, setTopBarHeadersFunction } = this.props
    const { projectId, projectSkuId } = match.params

    try {
      setTopBarHeadersFunction('')
      const project = await projectInfoFunction(projectId)
      let projectSkuIndex = project.projectSkus.findIndex(sku => sku.id === projectSkuId)
      if (projectSkuIndex === -1) {
        projectSkuIndex = 0
      }
      setTopBarHeadersFunction(project.title)
      this.setState({
        appointmentDate: new Date(project.projectSkus[projectSkuIndex].appointment),
        project,
        projectSkuIndex
      })
    } catch (e) {}
  }

  async updateProjectSku (updateFields = {}) {
    const { updateProjectSkuFunction } = this.props
    const { project, projectSkuIndex } = this.state
    const projectSku = project.projectSkus[projectSkuIndex]
    if (projectSku) {
      try {
        const projectSkuRes = await updateProjectSkuFunction(project.id, { id: projectSku.id, ...updateFields })
        project.projectSkus[projectSkuIndex] = projectSkuRes
        this.setState({ project })
      } catch (e) {}
    }
  }

  async onDone () {
    const {
      updateProjectFunction
    } = this.props
    const { project } = this.state
    const historyState = history.location.state
    if (historyState && historyState.type && historyState.type === 'projectStatusBar') {
      try {
        await updateProjectFunction({
          creationStatus: {
            ...project.creationStatus,
            contactChoice: CONTACT_CHOICE.APPOINTMENT,
            madeAppointment: true
          },
          id: project.id
        })
        history.goBack()
      } catch (e) {}
      return null
    } else {
      this.setState({ submitted: true })
      return { statusId: PROJECT_STATUSES.PRODUCTION_REVISIONS }
    }
  }

  async onCancelBooking () {
    const {
      updateProjectFunction
    } = this.props
    const { project } = this.state
    const historyState = history.location.state
    if (historyState && historyState.type && historyState.type === 'projectStatusBar') {
      try {
        await updateProjectFunction({
          creationStatus: {
            ...project.creationStatus,
            contactChoice: CONTACT_CHOICE.CHOICE_NOT_MADE,
            madeAppointment: false
          },
          id: project.id
        })
        history.push('/createmovie/continue/' + project.id)
      } catch (e) {}
    } else {
      await this.updateProjectSku({ statusId: PROJECT_STATUSES.PRODUCTION_REVISIONS })
      this.setState({ submitted: true })
    }
  }

  setAppointmentDate (date) {
    this.setState({ appointmentDate: date })
  }

  render () {
    const { project, projectSkuIndex, appointmentDate, submitted } = this.state
    const projectSku = project && project.projectSkus[projectSkuIndex]
    return (
      <React.Fragment>
        {projectSku && !submitted &&
        <ScheduleCallForm
          phoneNumber={projectSku.phoneNumber || ''}
          appointmentDate={appointmentDate}
          isBooked={!!projectSku.appointment}
          setAppointmentDate={this.setAppointmentDate.bind(this)}
          onDone={this.onDone.bind(this)}
          onCancelBooking={this.onCancelBooking.bind(this)}
          updateProjectSku={this.updateProjectSku.bind(this)}
        />}
        {submitted && <ThankYou appointmentDate={appointmentDate} />}
      </React.Fragment>
    )
  }
}
MovieScheduleCall.propTypes = {
  match: PropTypes.object.isRequired,
  projectInfoFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProjectFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    projectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setTopBarHeadersFunction: function (projectTitle) {
      dispatch(setTopBarHeaders(projectTitle, 'Schedule a call'))
    },
    updateProjectFunction: function (project) {
      return dispatch(updateProject(project))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default connect(null, mapDispatchToProps)(MovieScheduleCall)
