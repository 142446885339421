import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink, withRouter } from 'react-router-dom'
import { history } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  exploreTitle: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: '400',
    color: '#000000',
    marginBottom: 54
  },
  exploreDropMenuList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    outline: 'none',
    padding: 0,
    margin: '0 auto'
  },
  exploreDropMenuItem: {
    paddingBottom: '20px'
  },
  exploreDropMenuLink: {
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: 500,
    color: '#999a9a',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: 107,
    '&:hover': {
      color: '#01b7d7',
      cursor: 'pointer'
    },
    '&._active': {
      color: '#01b7d7'
    },
    '&:hover div': {
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    },
    '&._active div': {
      backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)'
    },
    '&:hover img': {
      '-webkit-filter': 'brightness(100%)',
      filter: 'brightness(100%)'
    },
    '&._active img': {
      '-webkit-filter': 'brightness(100%)',
      filter: 'brightness(100%)'
    },
    '& span': {
      marginTop: 6
    }
  },
  exploreMovieAuthor: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#5334b6',
    margin: '0 0 18px'
  },
  exploreMovieType: {
    margin: 0
  },
  iconWrapper: {
    width: 47,
    height: 47,
    borderRadius: '50%',
    backgroundColor: 'rgba(94, 104, 112, 0.1)'
  },
  categoryIcon: {
    width: '100%',
    padding: '23%',
    '-webkit-filter': 'brightness(60%)',
    filter: 'brightness(60%)'
  }
}
/* eslint-enable sort-keys */

class Explore extends React.Component {
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.categories.length === 0 && this.props.categories.length > 0) {
      history.push({ pathname: '/explore', state: { activeCategoryId: this.props.categories[0].id } })
    }
  }

  componentDidMount () {
    if (this.props.categories.length > 0) {
      history.push({ pathname: '/explore', state: { activeCategoryId: this.props.categories[0].id } })
    }
  }

  render () {
    const { classes, categories, location } = this.props
    const loading = categories.length < 1
    let activeCategoryId = 'default'
    if (!loading) {
      activeCategoryId = location.state && location.state.activeCategoryId
        ? location.state.activeCategoryId
        : categories[0].id
    }
    return (
      <div className={classes.exploreSideBar}>
        <h2 className={classes.exploreTitle}>Discover</h2>
        {loading && <p>Loading...</p>}
        <ul className={classes.exploreDropMenuList}>
          {categories.map(category => (
            <li key={category.id} className={classes.exploreDropMenuItem}>
              <NavLink
                to={{
                  pathname: '/explore',
                  state: { activeCategoryId: category.id }
                }}
                className={classNames([
                  classes.exploreDropMenuLink,
                  { _active: activeCategoryId === category.id }
                ])}>
                <div className={classes.iconWrapper}><img src={category.iconUrl}
                  className={classes.categoryIcon} alt={category.name}/></div>
                <span>{category.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
Explore.propTypes = {
  categories: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    categories: state.category.categories,
    loggedIn: state.user.loggedIn
  }
}
export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(Explore))
)
