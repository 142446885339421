import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../../helpers'

const styles = {
  container: {
    ...commonStyles.container(),
    height: 'auto',
    overflowX: 'hidden',
    position: 'static'
  },
  roll: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute'
  }
}

class CarouselWrapper extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      movieMap: null
    }
    this.calcWidth = this.calcWidth.bind(this)
    this.refRoll = React.createRef()
    this.pos = 0
    this.start = 0
    this.end = 0
    this.frameId = 0
    // This refers to edge that is non chromium
    this.isEdge = window.navigator.userAgent.toLowerCase().indexOf('edge') > -1
  }

  calcWidth () {
    if (this.refRoll.current) {
      const totalWidth = this.refRoll.current.scrollWidth
      this.start = -Math.abs(totalWidth / 3)
      this.end = -Math.abs(totalWidth / 3) * 2
    }
  }

  automaticScrolling () {
    if (this.refRoll && this.refRoll.current) {
      this.calcWidth()
      if (this.pos < this.end || this.pos > this.start) {
        this.pos = this.start
      } else if (!this.props.isOpenModal && !this.props.isOpenModalPreview) {
        this.pos = this.pos - ((this.isEdge ? 2 : 1) + this.props.extraSpeed)
      }
      this.refRoll.current.style.transform = `translateX(${this.pos}px)`
    }
  }

  startLoop () {
    if (!this.frameId) {
      this.limitLoop(this.automaticScrolling.bind(this), this.isEdge ? 25 : 60)
    } else {
      setTimeout(() => {
        this.startLoop()
      }, 100)
    }
  }

  limitLoop (fn, fps) {
    let then = new Date().getTime()
    const interval = 1000 / fps

    return (function loop (time, frameId) {
      frameId = window.requestAnimationFrame(loop)
      const now = new Date().getTime()
      const delta = now - then

      if (delta > interval) {
        then = now - (delta % interval)
        fn()
      }
    }(0))
  }

  stopLoop () {
    window.cancelAnimationFrame(this._frameId)
  }

  componentWillUnmount () {
    this.stopLoop()
  }

  componentDidMount () {
    this.startLoop()
  }

  render () {
    const { classes, children } = this.props
    const duplicates = [1, 2, 3]
    return (
      <div className={classes.container}>
        <div ref={this.refRoll} className={classes.roll}>
          {duplicates.map(() => children)}
        </div>
      </div>
    )
  }
}

CarouselWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object.isRequired,
  extraSpeed: PropTypes.number.isRequired,
  isOpenModal: PropTypes.bool,
  isOpenModalPreview: PropTypes.bool
}

CarouselWrapper.defaultProps = {
  extraSpeed: 0
}

const mapStateToProps = state => {
  return {
    isOpenModal: state.toggleModal.isOpenModal
  }
}

export default connect(mapStateToProps)(withStyles(styles)(CarouselWrapper))
