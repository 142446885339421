import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function SmallArrow (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16">
      <g><g><path fill="#fff" d="M16.343 8.02c0-.063-.007-.127-.02-.19-.007-.036-.02-.069-.03-.103-.009-.03-.016-.06-.027-.09-.018-.043-.041-.08-.063-.12-.011-.02-.02-.04-.032-.058a1.091 1.091 0 0 0-.133-.163L9.055.314a1.035 1.035 0 1 0-1.464 1.464l5.217 5.218H1.816a1.036 1.036 0 1 0 0 2.071h10.992L7.59 14.285a1.035 1.035 0 1 0 1.465 1.465l6.986-6.986.016-.02a1.05 1.05 0 0 0 .112-.137c.02-.03.034-.061.05-.093.016-.028.033-.055.045-.085.016-.038.025-.077.036-.115.008-.026.018-.052.023-.079.014-.067.021-.135.021-.203l-.001-.011z" /></g></g>
    </SvgIcon>
  )
}
