import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { parse } from 'query-string'
import styled from 'styled-components'

import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import {
  BackArrow,
  CloseIcon,
  PlusIcon,
  UrlifeLogo,
  UrlifeLogoGradient
} from '../common/SVG'
import IconButton from '@material-ui/core/IconButton'

import UrlifeLoginDialog from '../menu/UrlifeLoginDialog'
import UrlifeInviteDialog from '../menu/UrlifeInviteDialog'
import UrlifeRegisterDialog from '../menu/UrlifeRegisterDialog'
import UrlifeForgottenDialog from '../menu/UrlifeForgottenDialog'
import UrlifeInviteComingSoonDialog from '../menu/UrlifeInviteComingSoonDialog'
import UrlifeResetPasswordDialog from '../menu/UrlifeResetPasswordDialog'
import UrlifeUseCodeDialog from '../menu/UrlifeUseCodeDialog'
import UrlifeGetStartedDialog from '../menu/UrlifeGetStartedDialog'

import { commonStyles, history } from '../../helpers'
import { setSkinId, toggleModal } from '../../actions'

const Background = styled.div(props => ({
  ...props.background
}))

/* eslint-disable sort-keys */
const styles = theme => ({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  background: {
    outline: 'none',
    width: '100%',
    '&._skinned': {
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
  },
  skinnedHeader: {
    ...commonStyles.container(),
    display: 'flex',
    paddingTop: 40,
    paddingBottom: 40,
    height: 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...commonStyles.media(599, {
      height: 56,
      paddingTop: 20,
      paddingBottom: 20
    }),
    ...commonStyles.media(459, {
      height: 44,
      paddingTop: 10
    })
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    '&._left': {
      width: 20,
      '& svg': {
        height: '100%',
        width: 'auto',
        margin: 0,
        cursor: 'pointer'
      }
    },
    '&._center': {
      width: 'auto'
    },
    '&._right': {
      width: 20,
      '& svg': {
        height: '100%',
        width: 'auto',
        margin: 0,
        cursor: 'pointer'
      }
    },
    ...commonStyles.media(599, {
      height: 16
    }),
    ...commonStyles.media(459, {
      height: 12
    })
  },
  centerLogo: {
    margin: 0,
    '&._center': {
      height: 35,
      margin: '2px 14px',
      width: 'auto'
    },
    '&._side': {
      height: 40,
      width: 'auto',
      '&._celebrity': {
        height: 30,
        ...commonStyles.media(599, {
          height: 24
        }),
        ...commonStyles.media(459, {
          height: 18
        })
      }
    },
    ...commonStyles.media(599, {
      '&._center': {
        height: 12
      },
      '&._side': {
        height: 16
      }
    }),
    ...commonStyles.media(459, {
      '&._center': {
        height: 10
      },
      '&._side': {
        height: 12
      }
    })
  },
  paper: {
    zIndex: 20,
    position: 'relative',
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    margin: 'auto',
    flexDirection: 'column',
    borderRadius: 2,
    maxWidth: '450px',
    minWidth: '410px',
    overflowX: 'hidden',
    ...commonStyles.media(480, {
      minWidth: 200,
      width: '90%'
    }),
    '&._center': {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  text: {
    display: 'flex',
    color: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h1': {
      fontSize: 50,
      fontWeight: 700,
      marginBottom: 0,
      marginTop: 0,
      ...commonStyles.media(959, {
        fontSize: 44
      }),
      ...commonStyles.media(599, {
        fontSize: 26
      })
    },
    '& h3': {
      marginTop: 5,
      fontSize: 24,
      fontWeight: 400,
      ...commonStyles.media(959, {
        fontSize: 18
      }),
      ...commonStyles.media(599, {
        textAlign: 'center',
        fontSize: 14,
        width: 300
      })
    },
    '& p': {
      fontSize: 16,
      fontWeight: 500,
      ...commonStyles.media(959, {
        fontSize: 14
      }),
      ...commonStyles.media(599, {
        textAlign: 'center',
        fontSize: 12
      }),
      ...commonStyles.media(459, {
        fontSize: 10
      })
    }
  },
  iconWrapper: {
    padding: 15,
    marginLeft: 'auto'
  },
  icon: {
    fill: '#333',
    width: 15,
    height: 15
  },
  paperHeader: {
    height: 40,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    ...commonStyles.text({
      color: '#ffffff'
    }),
    width: 120,
    height: 59,
    borderRadius: 29,
    backgroundColor: 'tranparent',
    border: 'solid 1px #ffffff',
    '@media screen and (min-width: 641px)': {
      color: '#000000',
      border: 'solid 1px #000000'
    }
  },
  buttonBlack: {
    ...commonStyles.text(),
    width: 120,
    height: 59,
    borderRadius: 29,
    backgroundColor: 'tranparent',
    border: 'solid 1px #1c2025',
    color: '#1c2025'
  },
  modalBackdrop: {
    backgroundImage: 'linear-gradient(135deg, #3d29b1 0%, #bd6ad3 100%)',
    opacity: '0.9 !important'
  },
  backdropSkin: {
    opacity: '0 !important'
  },
  logoIcon: {
    width: 138,
    height: 55,
    margin: '0 auto'
  }
})
/* eslint-enable sort-keys */

class CustomModal extends Component {
  constructor (props) {
    super(props)
    this.root = document.createElement('div')
    document.body.appendChild(this.root)
    const query = parse(history.location.search)
    this.state = {
      code: props.code,
      failedReset: !!(query && query.token)
    }
  }

  handleSwitch (componentModal) {
    this.props.toggleModal(true, componentModal)
  }

  closeModal () {
    const { failedReset } = this.state
    this.props.setSkinIdFunction(null)
    this.props.toggleModal(false, null)
    if (failedReset) {
      history.push('/')
    }
  }

  goBack () {
    this.props.toggleModal(true, this.props.lastComponentModal)
  }

  componentWillUnmount () {
    document.body.removeChild(this.root)
  }

  setCode (code) {
    this.setState({ code })
  }

  render () {
    const {
      classes,
      componentModal,
      skinData
    } = this.props
    const { code } = this.state
    const skinned = !!skinData && (
      componentModal === 'login' ||
      componentModal === 'register' ||
      componentModal === 'setpassword' ||
      componentModal === 'resetpassword' ||
      componentModal === 'getstarted' ||
      componentModal === 'projectcode'
    )
    const canGoBack =
      componentModal === 'usecode'
    const canCloseModal =
      componentModal !== 'resetpassword' &&
      componentModal !== 'setpassword' &&
      componentModal !== 'usecode' &&
      !skinned

    let successBody = ''
    let successTitle = ''
    if (skinData) {
      successBody = skinData.successBody
        ? skinData.successBody
        : `You have unlocked your package courtesy of ${skinData.title}`
      successTitle = skinData.successTitle ? skinData.successTitle : 'Success!'
    }

    return (
      <Modal
        open={
          componentModal === 'login' ||
          componentModal === 'invite' ||
          componentModal === 'register' ||
          componentModal === 'forgotten' ||
          componentModal === 'invitecomingsoon' ||
          componentModal === 'resetpassword' ||
          componentModal === 'setpassword' ||
          componentModal === 'usecode' ||
          componentModal === 'getstarted' ||
          componentModal === 'projectcode'
        }
        onClose={canCloseModal ? this.closeModal.bind(this) : null}
        aria-labelledby="form-dialog-title"
        className={classes.modal}
        BackdropProps={{ className: classes.modalBackdrop }}
      >
        <Background
          background={skinned ? skinData.backgroundStyleClass : null}
          className={classNames(classes.background, { _skinned: skinned })}
        >
          {skinned && <div className={classes.skinnedHeader}>
            <div
              className={classNames(classes.topBar, '_left')}
              onClick={this.closeModal.bind(this)}
            >
              {(componentModal === 'login' || componentModal === 'register') && code &&
                <BackArrow/>
              }
            </div>
            <div className={classNames(classes.topBar, '_center')}>
              {skinData.logo({ className: classNames(classes.centerLogo, '_side', skinData.logoClass) })}
              <PlusIcon className={classNames(classes.centerLogo, '_center')}/>
              <UrlifeLogo className={classNames(classes.centerLogo, '_side')}/>
            </div>
            <div
              className={classNames(classes.topBar, '_right')}
              onClick={this.closeModal.bind(this)}
            >
              {(componentModal === 'login' || componentModal === 'register') && !code &&
                <CloseIcon/>
              }
            </div>
          </div>}
          {skinned && code &&
          (componentModal === 'login' || componentModal === 'register') &&
          <div className={classes.text}>
            <h1>{successTitle}</h1>
            <h3>{successBody}</h3>
            {componentModal === 'register' && <p>Please set up your account or log in to get started</p>}
          </div>}
          <Paper className={classNames(classes.paper, { _center: !!skinned && !code })}>
            <div className={classes.paperHeader}>
              <div>
                {canGoBack && <IconButton
                  key="goBack"
                  aria-label="Go Back"
                  color="inherit"
                  className={classes.iconWrapper}
                  onClick={this.goBack.bind(this)}
                >
                  <BackArrow className={classes.icon} />
                </IconButton>}
              </div>
              <div>
                {canCloseModal && <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.iconWrapper}
                  onClick={this.closeModal.bind(this)}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>}
              </div>
            </div>
            {!skinned && <UrlifeLogoGradient className={classes.logoIcon} />}

            {componentModal === 'login' && <UrlifeLoginDialog
              code={code}
              setCode={this.setCode.bind(this)}
              registerSwitch={() => { this.handleSwitch('register') }}
            />}

            {componentModal === 'invite' && <UrlifeInviteDialog
              loginSwitch={() => { this.handleSwitch('login') }}
            />}

            {componentModal === 'register' && <UrlifeRegisterDialog
              code={code}
              setCode={this.setCode.bind(this)}
              loginSwitch={() => { this.handleSwitch('login') }}
            />}

            {componentModal === 'invitecomingsoon' && <UrlifeInviteComingSoonDialog
              loginSwitch={() => { this.handleSwitch('login') }}
            />}

            {componentModal === 'forgotten' && <UrlifeForgottenDialog
              code={code}
              setCode={this.setCode.bind(this)}
              loginSwitch={() => { this.handleSwitch('login') }}
            />}
            {componentModal === 'setpassword' && <UrlifeResetPasswordDialog
              title={'Set a password'}
              code={code}
              setCode={this.setCode.bind(this)}
            />}
            {componentModal === 'resetpassword' && <UrlifeResetPasswordDialog
              title={'Reset password'}
              code={code}
              setCode={this.setCode.bind(this)}
            />}
            {componentModal === 'usecode' && <UrlifeUseCodeDialog
              title={'Project code'}
              loginSwitch={() => { this.handleSwitch('login') }}
              setCode={this.setCode.bind(this)}
            />}
            {componentModal === 'projectcode' && <UrlifeUseCodeDialog
              title={'Project code'}
              setCode={this.setCode.bind(this)}
              showBackButton={false}
            />}
            {componentModal === 'getstarted' && <UrlifeGetStartedDialog
              code={code}
              loginSwitch={() => { this.handleSwitch('login') }}
            />}
          </Paper>
        </Background>
      </Modal>
    )
  }
}

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  componentModal: PropTypes.string.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  lastComponentModal: PropTypes.string,
  setSkinIdFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    code: state.toggleModal.code,
    componentModal: state.toggleModal.componentModal.toLowerCase(),
    isOpenModal: state.toggleModal.isOpenModal,
    lastComponentModal: state.toggleModal.lastComponentModal,
    skinData: state.skinning.skinData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CustomModal))
