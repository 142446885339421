import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  BackArrow
} from '../common/SVG'
import { commonStyles, history } from '../../helpers'
import FAQList from './FAQList'
import { parse } from 'query-string'

/* eslint-disable sort-keys */
const styles = {
  FAQContainer: {
    marginTop: -20,
    ...commonStyles.media(575, {
      marginTop: 10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 10
    })
  },
  FAQHeader: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 50
  },
  FAQTitle: {
    margin: 0,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '72px'
    })
  },
  backArrow: {
    color: '#333',
    cursor: 'pointer',
    position: 'absolute',
    left: 5,
    top: 25
  }
}
/* eslint-enable sort-keys */

class FAQPage extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      scrollToId: ''
    }
  }

  componentDidMount () {
    const parsed = parse(history.location.search)
    if (parsed.q && typeof parsed.q === 'string') {
      this.setState({ scrollToId: parsed.q })
    }
  }

  goBack () {
    history.goBack()
  }

  render () {
    const { classes } = this.props
    const { scrollToId } = this.state
    return <div>
      <div className={classes.FAQContainer}>
        <div className={classes.FAQHeader}>
          <BackArrow className={classes.backArrow} onClick={this.goBack} />
          <h2 className={classes.FAQTitle}>Frequently Asked Questions</h2>
        </div>
        <FAQList scrollToId={scrollToId} />
      </div>
    </div>
  }
}
FAQPage.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FAQPage)
