import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import classNames from 'classnames'
import FAQList from '../faq/FAQList'
import TestimonialSlider from '../common/TestimonialSlider'
import RegisterOrProjectButton from '../common/RegisterOrProjectButton'
import {
  BoxedCheckMarkIcon,
  PlusCircleIconNotch,
  ProducerChairV2,
  TravelinkLogo,
  UploadMovieArrowIcon
} from '../common/SVG'
import PackageList from './components/PackageList'
import Hero from './components/Hero'
import GetStarted from './components/GetStarted'
import LineTitle from './components/LineTitle'

/* eslint-disable sort-keys */
const styles = {
  travelinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  sectionContainer: {
    marginTop: 80
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    color: '#4A30b5',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    '& span': {
      fontWeight: 600
    }
  },
  subHeader: {
    fontSize: 24,
    lineHeight: '33px',
    fontWeight: 600,
    color: '#4A30b5',
    ...commonStyles.media(991, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  buttonContainer: {
    marginTop: 65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  packageContainer: {
    marginTop: 80,
    position: 'relative',
    width: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50vw)',
      width: '100vw',
      height: 263,
      backgroundColor: '#4A30b5',
      ...commonStyles.media(991, {
        height: 219
      }),
      ...commonStyles.media(767, {
        height: 164
      }),
      ...commonStyles.media(575, {
        height: 142
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        height: 142
      })
    }
  },
  packageWrapper: {
    position: 'relative',
    marginTop: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  packageHeader: {
    fontSize: 48,
    lineHeight: '50px',
    fontWeight: 400,
    color: '#ffffff',
    ...commonStyles.media(991, {
      fontSize: 40,
      lineHeight: '42px'
    }),
    ...commonStyles.media(767, {
      fontSize: 30,
      lineHeight: '32px'
    }),
    ...commonStyles.media(575, {
      fontSize: 26,
      lineHeight: '28px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 26,
      lineHeight: '28px'
    })
  },
  packageTravelinkLogo: {
    width: 237,
    height: 35,
    position: 'relative',
    top: 1,
    marginRight: 15,
    ...commonStyles.media(991, {
      width: 210,
      height: 31,
      marginRight: 13
    }),
    ...commonStyles.media(767, {
      width: 162,
      height: 24,
      marginRight: 10
    }),
    ...commonStyles.media(575, {
      width: 142,
      height: 21,
      marginRight: 8
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 142,
      height: 21,
      marginRight: 8
    })
  },
  faqHeader: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
    color: '#4A30b5',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 24,
      lineHeight: '30px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '24px'
    })
  },
  faqText: {
    marginTop: 5,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 14,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    },
    '&._marginBottom': {
      marginBottom: 30
    }
  },
  contactContainer: {
    marginTop: 47,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  shoutoutsContainer: {
    width: '82vw',
    maxWidth: 1575,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 68,
    ...commonStyles.media(575, {
      width: 'calc(100% + 40px)'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 'calc(100% + 40px)'
    })
  }
}
/* eslint-enable sort-keys */

const steps = [
  {
    icon: PlusCircleIconNotch,
    text: 'Start a project'
  },
  {
    icon: BoxedCheckMarkIcon,
    text: 'Select a package and follow on-screen prompts'
  },
  {
    icon: UploadMovieArrowIcon,
    text: 'Upload photos/videos using the website or iOS App'
  },
  {
    icon: ProducerChairV2,
    text: 'Sit back and relax while our team makes the magic'
  }
]

const shoutouts = [
  {
    image: 'https://static.urlifemedia.com/travelink/JacksonHole.jpeg',
    imagePosition: '50% 15%',
    name: 'Brittany',
    project: 'Jackson Hole Family Trip',
    quote: `I cannot believe how exciting that is to watch over and over again.
      We are 100% doing this for every trip now. Holy moly. SO AMAZING!`
  },
  {
    image: 'https://static.urlifemedia.com/travelink/GreeceHoneymoon.JPG',
    imagePosition: '50% 15%',
    name: 'Jillian',
    project: 'Greece Honeymoon',
    quote: 'I LOVED my URLIFE experience.'
  },
  {
    image: 'https://static.urlifemedia.com/travelink/OaxacaFamilyVacation.png',
    imagePosition: '20% 15%',
    name: 'Erin',
    project: 'Oaxaca Family Vacation',
    quote: `These movies are treasures to my family! And I love knowing that my tens
      of thousands of images and video won’t be forgotten.`
  }
]

class Travelink extends React.Component {
  render () {
    const { classes } = this.props
    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 6000,
      dots: false,
      focusOnSelect: true,
      infinite: true,
      pauseOnHover: false,
      slidesToShow: 1,
      speed: 1500,
      swipeToSlide: true
    }
    return (
      <div className={classes.travelinkContainer}>
        <Hero
          headerBefore='Savor your'
          headerAfter='adventure'
          headerSubHeader='Luxury memory preservation by the pros'
          headerInfo='Hollywood trained producers ready to make a movie of your vacation'
          headerLogo={(props) => <TravelinkLogo {...props} hideAmEx={true} />}
          thumbnail={'https://static.urlifemedia.com/travelink/thumbnail-intro.jpg'}
          video={'https://static.urlifemedia.com/travelink/namibia/master.m3u8'}
        />
        <GetStarted
          steps={steps}
          title='Easy to get started'
          subtitle='Create an Account on the Website'
          subtitleVideo={'https://static.urlifemedia.com/marketing-2022-11-17/video/master.m3u8'}
          subtitleLink={false}
          color='#4A30b5'
        />
        <div className={classes.buttonContainer}>
          <RegisterOrProjectButton promocode='TRAVELINK2021' />
        </div>
        <div className={classes.packageContainer}>
          <div className={classes.packageWrapper}>
            <div className={classes.packageHeader}>
              <TravelinkLogo className={classes.packageTravelinkLogo} color={'#ffffff'} hideAmEx={true} />
              working for you
            </div>
            <div className={classes.packageHeader}>Save 20% on select URLIFE packages</div>
            <PackageList />
            <div className={classes.buttonContainer}>
              <RegisterOrProjectButton promocode='TRAVELINK2021' />
            </div>
          </div>
        </div>
        <div className={classNames(classes.shoutoutsContainer, 'testimonial')}>
          <LineTitle text='User Shout Outs'/>
          <TestimonialSlider settings={settings} testimonials={shoutouts} />
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.faqHeader}>Still have questions?</div>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            We have categorized our frequently asked questions to make it easier to find what you are after.
          </div>
          <FAQList />
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.faqText}>
            <div className={classes.faqHeader}>Need more info?</div>
            If you have any questions, email us at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a>
          </div>
        </div>
      </div>
    )
  }
}

Travelink.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Travelink)
