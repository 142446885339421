import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import classNames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { history } from '../../../../helpers'
import { CheckMark } from '../../SVG'

const StyledCircularProgress = styled(
  ({ strokeColor, ...otherProps }) => <CircularProgress {...otherProps} />
)(props => ({
  color: props.strokeColor
}))

/* eslint-disable sort-keys */
const styles = {
  infoContainer: {
    position: 'relative',
    width: '100%',
    '&._large': {
      height: 59
    },
    '&._medium': {
      height: 57
    },
    '&._small': {
      height: 57
    }
  },
  progressCircle: {
    position: 'absolute',
    left: 15,
    top: 15,
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  progressCircleIcon: {
    position: 'absolute',
    left: 20,
    top: 20,
    width: 15,
    height: 15,
    color: '#01b7d7',
    '&._complete': {
      top: 21,
      width: 14,
      height: 14,
      color: '#bd6ad3'
    },
    '&._pause': {
      left: 21,
      top: 21,
      width: 13,
      height: 13
    },
    '&._play': {
      left: 22
    }
  },
  infoTextContainer: {
    position: 'absolute',
    left: 55,
    top: 9,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '&._fullWidth': {
      left: 15,
      width: 'calc(100% - 30px)'
    },
    '&._center': {
      left: 200
    },
    '&._rightSide': {
      left: 'auto',
      right: 15,
      justifyContent: 'flex-end',
      textAlign: 'right'
    }
  },
  infoText: {
    color: '#323232',
    '&._large': {
      fontSize: 14
    },
    '&._medium': {
      fontSize: 13
    },
    '&._small': {
      fontSize: 13
    },
    '&._extra': {
      paddingTop: 2,
      color: '#222222',
      opacity: 0.5,
      '&._large': {
        fontSize: 13,
        letterSpacing: '0.85px'
      },
      '&._medium': {
        fontSize: 11,
        letterSpacing: '0.76px'
      },
      '&._small': {
        fontSize: 11,
        letterSpacing: '0.76px'
      }
    }
  }
}
/* eslint-enable sort-keys */

class InfoBox extends React.Component {
  render () {
    const {
      classes,
      text,
      sizeClass,
      skinData
    } = this.props

    const isCreationFlow = history.location.state &&
      history.location.state.type &&
      history.location.state.type === 'creationFlow'
    const skinned = isCreationFlow && !!skinData
    const checkMarkColor = skinned ? skinData.mainColor : '#bd6ad3'

    return (
      <div className={classNames(classes.infoContainer, sizeClass)}>
        <CheckMark
          color={checkMarkColor}
          className={classNames(
            classes.progressCircleIcon,
            '_complete'
          )}
        />
        <StyledCircularProgress
          variant="determinate"
          color="inherit"
          strokeColor={checkMarkColor}
          className={classNames(
            classes.progressCircle
          )}
          value={100}
          size={25}
        />
        <div className={classes.infoTextContainer}>
          <div className={classNames(classes.infoText, sizeClass)}>
            All files uploaded
          </div>
          <div className={classNames(classes.infoText, '_extra', sizeClass)}>
            {text}
          </div>
        </div>
      </div>
    )
  }
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  sizeClass: PropTypes.oneOf(['_small', '_medium', '_large']).isRequired,
  skinData: PropTypes.object,
  text: PropTypes.string
}

const mapStateToProps = state => {
  return {
    skinData: state.skinning.skinData
  }
}

export default withStyles(styles)(connect(mapStateToProps)(InfoBox))
