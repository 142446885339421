import ApiService from '../services/ApiService'
import { api } from '../constants'

export {
  listTopicSubscriptions
}

function listTopicSubscriptions (params = {}) {
  return async dispatch => {
    try {
      const response = await new ApiService(api.TOPIC_SUBSCRIPTIONS, {}, params, true, dispatch)
        .get()
      return response
    } catch (e) {
      return { data: [] }
    }
  }
}
