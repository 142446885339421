import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import {
  getAllPromptsCategory
} from '../../../../../actions'
import { commonStyles } from '../../../../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  createMovieCard: {
    width: '100%',
    paddingTop: '15%',
    paddingBottom: '35%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: '2px',
    boxShadow: '0 2px 32px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#323232',
    marginRight: '30px',
    position: 'relative',
    overflow: 'hidden',
    '&._selected': {
      border: '2px solid #01b7d7',
      width: 'calc(100% + 4px)',
      marginLeft: -2
    },
    '&:nth-child(4)': {
      marginRight: '0'
    },
    '@media screen and (max-width: 1225px)': {
      '&:nth-child(4)': {
        marginRight: '30px'
      }
    }
  },
  createMovieCardText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    fontSize: '17px',
    fontWeight: 500,
    textAlign: 'center',
    transition: '.3s ease',
    padding: '5px 5px 37px 5px',
    ...commonStyles.media(769, {
      fontSize: 16,
      height: 50,
      padding: '18px 5px 24px 5px'
    })
  },
  iconWrapper: {
    width: 76,
    height: 76,
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(133deg, #01b7d7 0%, #02dbd8 100%)',
    ...commonStyles.media(769, {
      width: 64,
      height: 64
    })
  },
  categoryIcon: {
    width: '100%',
    padding: '30%'
  }
}
/* eslint-enable sort-keys */

class CreateMovieCardComponent extends React.Component {
  async nextStep () {
    const {
      type,
      category,
      setPrompts,
      getAllPromptsCategoryFunction,
      updateProject
    } = this.props
    if (type === 'about') {
      const data = await getAllPromptsCategoryFunction(category.id)
      await updateProject(
        { categoryId: category.id },
        { category: true, totalNumberOfPrompts: data.length }
      )
      await setPrompts(data)
      this.props.handleNext()
    } else {
      this.props.handleNext()
    }
  }

  render () {
    const { classes, category, categoryId } = this.props
    const isSelected = category.id === categoryId
    return (
      <div
        onClick={ this.nextStep.bind(this)}
        className={classNames(classes.createMovieCard, { _selected: isSelected })}>
        <div className={classes.iconWrapper}>
          {category.iconUrl
            ? (<img src={category.iconUrl} alt="categoryIcon" className={classes.categoryIcon}/>)
            : null}
        </div>
        <span className={classes.createMovieCardText}>{category.name}</span>
      </div>
    )
  }
}

CreateMovieCardComponent.propTypes = {
  category: PropTypes.object.isRequired,
  categoryId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  genre: PropTypes.bool,
  getAllPromptsCategoryFunction: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  setPrompts: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    getAllPromptsCategoryFunction: function (id) {
      return dispatch(getAllPromptsCategory(id))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CreateMovieCardComponent))
