import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  createPrompt,
  createPromptAnswer,
  deletePrompt,
  deletePromptAnswer,
  getAllPromptsCategory,
  getProjectAnswers,
  updatePrompt,
  updatePromptAnswer
}

function getAllPromptsCategory (categoryId, filter = null) {
  return async dispatch => {
    const prompts = await new ApiService(api.PROMPTS_LIST, { categoryId }, { filter }, true, dispatch)
      .get()
    return prompts.data
  }
}

function createPrompt (categoryId, promptData) {
  return dispatch => {
    return new ApiService(api.PROMPTS, { categoryId }, {}, true, dispatch)
      .post(promptData)
  }
}

function updatePrompt (categoryId, promptId, promptData) {
  return dispatch => {
    return new ApiService(api.ONE_PROMPT, { categoryId, promptId }, {}, true, dispatch)
      .put(promptData)
  }
}

function deletePrompt (categoryId, promptId) {
  return dispatch => {
    return new ApiService(api.ONE_PROMPT, { categoryId, promptId }, {}, true, dispatch)
      .delete()
  }
}

function createPromptAnswer (projectId, prompt) {
  return async dispatch => {
    const result = await new ApiService(api.PROMPT_ANSWERS, { projectId }, {}, true, dispatch)
      .post(prompt)
    return result.data
  }
}

function updatePromptAnswer (projectId, promptAnswerId, answer) {
  return async dispatch => {
    const result = await new ApiService(api.ONE_PROMPT_ANSWER, { projectId, promptAnswerId }, {}, true, dispatch)
      .put({ answer })
    return result.data
  }
}

function deletePromptAnswer (projectId, promptAnswerId) {
  return async dispatch => {
    const result = await new ApiService(api.ONE_PROMPT_ANSWER, { projectId, promptAnswerId }, {}, true, dispatch)
      .delete()
    return result.data
  }
}

function getProjectAnswers (projectId) {
  return async dispatch => {
    const result = await new ApiService(api.PROMPT_ANSWERS, { projectId }, {}, true, dispatch)
      .get()
    return result.data
  }
}
