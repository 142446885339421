import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MoreMenu from '../../common/MoreMenu/MoreMenu'
import { connect } from 'react-redux'
import { commonStyles } from '../../../helpers/index'
import { LogOutIcon } from '../../common/SVG/index'
import { userLogout } from '../../../actions'
import TopBarLineMenu from './TopBarLineMenu'
import NotificationMenu from './NotificationMenu'
import TopBarLogo from './TopBarLogo'

/* eslint-disable sort-keys */
const styles = {
  container: {
    ...commonStyles.container(),
    minHeight: 0
  },
  topBarRow: {
    marginTop: -20,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  topBarLeft: {
    ...commonStyles.flex(),
    justifyContent: 'flex-start',
    flex: 1,
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  topBarMiddle: {
    ...commonStyles.flex(),
    ...commonStyles.media(767, {
      margin: 'auto'
    })
  },
  topBarRight: {
    ...commonStyles.flex(),
    justifyContent: 'flex-end',
    flex: 1,
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  icon: {
    width: 80,
    height: 50,
    margin: '5px 10px 0 10px',
    cursor: 'pointer',
    '& span': {
      color: '#fff',
      marginTop: 8,
      textAlign: 'center',
      display: 'block',
      fontSize: 12
    },
    '& svg': {
      display: 'block',
      margin: 'auto'
    },
    ...commonStyles.media(991, {
      width: 50,
      margin: '17px 5px 0 5px',
      '& span': {
        fontSize: 10
      }
    })
  }
}
/* eslint-enable sort-keys */

class ToolbarAdmin extends React.Component {
  render () {
    const { classes, logOut, userId } = this.props
    return (
      <Toolbar className={classes.container}>
        <div className={classes.topBarRow}>
          {/* left col */}
          <div className={classes.topBarLeft}>
            <TopBarLogo />
          </div>
          {/* middle col */}
          <div className={classes.topBarMiddle}>
            <TopBarLineMenu
              className={classes.topBarMenu}
              type={'light'}
              items={[
                { name: 'Home', to: '/admin/home' },
                { name: 'Users', to: '/admin/users' },
                { name: 'Invites', to: '/admin/invites' }
              ]}
            />
            <MoreMenu />
          </div>
          {/* right col */}
          <div className={classes.topBarRight}>
            {userId && <NotificationMenu />}
            <div className={classes.icon} onClick={logOut}>
              <LogOutIcon />
              <span>Log Out</span>
            </div>
          </div>
        </div>
      </Toolbar>
    )
  }
}

ToolbarAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  userId: PropTypes.string
}

function mapStateToProps (state) {
  return {
    userId: state.user.userid
  }
}
function mapDispatchToProps (dispatch) {
  return {
    logOut: function () {
      dispatch(userLogout())
    }
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolbarAdmin))
)
