import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, getFileDimensions } from '../../../helpers'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { FormControl, FormHelperText } from '@material-ui/core'
import ProjectStatusBar from '../../common/ProjectStatusBar/ProjectStatusBar'
import SwitchField from './Fields/SwitchField'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeConfirmDialog from '../../common/UrlifeConfirmDialog'
import { PlusCircledIcon } from '../../common/SVG'

/* eslint-disable sort-keys */
const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 38
  },
  requirementsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: '#323232'
  },
  message: {
    padding: '5px 0',
    color: '#323232'
  },
  editTable: {
    border: '1px solid #ebebeb',
    width: '100%',
    borderRadius: 4,
    borderCollapse: 'collapse',
    ...commonStyles.text({
      color: '#979797'
    }),
    '&._newItem': {
      border: 'none',
      marginTop: 30,
      boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.1)'
    }
  },
  editTableTh: {
    fontWeight: 400,
    color: '#999999',
    fontSize: 14,
    padding: '15px 10px',
    textAlign: 'left',
    position: 'sticky',
    top: 106,
    zIndex: 2,
    background: '#f6f6f6'
  },
  editTableTr: {
    position: 'relative'
  },
  editTableTd: {
    padding: '10px 10px',
    background: 'none',
    position: 'relative',
    transition: 'background-color .3s',
    verticalAlign: 'top',
    '&._newButton': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    'tr:nth-child(odd) &': {
      backgroundColor: '#eeeeee'
    },
    '$editTableTr._inAnimationToTop &': {
      transition: 'transform .5s',
      transform: 'translate(0, -100%)'
    },
    '$editTableTr._inAnimationToBottom &': {
      transition: 'transform .5s',
      transform: 'translate(0, 100%)'
    },
    '$editTableTr._activeSortAnimation &': {
      zIndex: 1
    },
    '$editTable._newItem &': {
      paddingBottom: 40
    }
  },
  submitNewItemButton: {
    position: 'absolute',
    bottom: 10,
    right: 10
  },
  deleteHolder: {
    display: 'inline-flex',
    margin: '0 0 0 6px'
  },
  addButton: {
    padding: '15px 30px',
    marginTop: 30
  },
  addButtonIcon: {
    marginRight: 18
  },
  errorText: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: '100%',
    marginTop: 4
  }
}
/* eslint-enable sort-keys */

class EditTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmDeleteOpen: false,
      deleteId: '',
      error: ''
    }
    this.rowRefs = {}
  }

  onChangeField (id, fieldsData, apply = false, needSort = false) {
    const { onChangeField } = this.props
    onChangeField(id, fieldsData, apply, needSort, () => {
      if (needSort) {
        this.rowRefs[id].scrollIntoViewIfNeeded()
      }
    })
  }

  onSubmitNewItem () {
    const { onSubmitNewItem } = this.props
    onSubmitNewItem((id) => { this.rowRefs[id].scrollIntoViewIfNeeded() })
  }

  exchangeFieldOrderPosition (currentItemIndex, changing) {
    const { exchangeFieldOrderPosition } = this.props
    exchangeFieldOrderPosition(currentItemIndex, changing)
  }

  async validateAndUploadFile (id, file, field) {
    const {
      videoMinRes,
      imageMinRes,
      uploadFile
    } = this.props
    const fileDimensions = await getFileDimensions(file)
    const isImage = field === 'Image' || field === 'Thumbnail'
    const minRes = isImage ? imageMinRes : videoMinRes
    const isSquareVideo = !isImage && fileDimensions.width === fileDimensions.height
    const isAspectRatio16x9 = Math.abs(fileDimensions.height / fileDimensions.width - 0.5625) < 0.005
    let error = ''
    let warning = ''
    if (minRes) {
      if (isSquareVideo && fileDimensions.height < minRes.height) {
        error = 'Square video must have a resolution of at least ' + minRes.height + 'x' + minRes.height + ' pixels'
      } else if (!isSquareVideo && (fileDimensions.width < minRes.width || fileDimensions.height < minRes.height)) {
        error = field + ' must have a resolution of at least ' + minRes.width + 'x' + minRes.height + ' pixels'
      } else if (!isSquareVideo && !isAspectRatio16x9) {
        warning = 'Wrong aspect ratio. An aspect ratio of 1:1 or 16:9 is recommended.'
      } else if (isImage) {
        if (fileDimensions.width / minRes.aspectX !== fileDimensions.height / minRes.aspectY) {
          warning = 'Wrong aspect ratio. An aspect ratio of ' + minRes.aspectX + ':' + minRes.aspectY +
            ' is recommended. This image can still be uploaded, but will be cropped.'
        }
      } else if (isSquareVideo) {
        warning = 'This is a square video (aspect ratio of 1:1) but can still be uploaded'
      }
    }
    this.setState({ error: error || warning })
    if (uploadFile && !error) {
      uploadFile(id, file, field)
    }
  }

  onDeleteItem () {
    const { onDeleteItem } = this.props
    const { deleteId } = this.state
    onDeleteItem(deleteId)
    this.setState({
      confirmDeleteOpen: false,
      deleteId: ''
    })
  }

  confirmDelete (id) {
    this.setState({
      confirmDeleteOpen: true,
      deleteId: id
    })
  }

  render () {
    const {
      classes,
      title,
      filterComponent,
      videoMinRes,
      imageMinRes,
      message,
      items,
      fieldList,
      newItem,
      newFieldList,
      newItemIsValid,
      sortAnimationToTopId,
      sortAnimationToBottomId,
      activeSortAnimationId,
      duplicateDisplayOrder,
      errorMessage,
      onDeleteItem,
      deleteHeader,
      deleteMessage,
      addNewTempItem,
      newButtonLabel,
      onChangeNewItem
    } = this.props
    const {
      confirmDeleteOpen,
      error
    } = this.state
    const newFields = newFieldList || fieldList
    return (
      <React.Fragment>
        <div className={classes.headerContainer}>
          <Typography component="h1" variant="h1" gutterBottom={false}>
            {title}
          </Typography>
          {filterComponent}
          {(!!videoMinRes || !!imageMinRes) && <div className={classes.requirementsContainer}>
            <div>Minimum resolution:</div>
            {!!videoMinRes && <div>
              {'Video: ' +
                videoMinRes.width +
                'x' +
                videoMinRes.height +
                ' ( 1:1 ' +
                videoMinRes.height +
                'x' +
                videoMinRes.height +
                ' )'
              }
            </div>}
            {!!imageMinRes && <div>{'Image: ' + imageMinRes.width + 'x' + imageMinRes.height}</div>}
          </div>}
        </div>

        <ProjectStatusBar project={{ id: 'admin' }} hideInactive={true} />
        {message && <div className={classes.message}>{message}</div>}

        {items.length > 0 && <table className={classes.editTable}>
          <thead>
            <tr>
              {fieldList.map((fieldSettingsItem, fieldSettingsKey) => (
                <th key={`header-${fieldSettingsKey}`} className={classes.editTableTh}
                  style={{ width: fieldSettingsItem.width || 'auto' }}>
                  {fieldSettingsItem.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((item, itemIndex) => {
              return (
                <tr
                  ref={ref => { this.rowRefs[item.id] = ref }}
                  key={item.id}
                  className={classNames(
                    classes.editTableTr,
                    {
                      _activeSortAnimation: item.id === activeSortAnimationId,
                      _inAnimationToBottom: item.id === sortAnimationToBottomId,
                      _inAnimationToTop: item.id === sortAnimationToTopId
                    }
                  )}>
                  {fieldList.map((fieldSettingsItem, fieldSettingsItemIndex) => {
                    const fieldValue = item[fieldSettingsItem.field] ||
                      item[fieldSettingsItem.field + 'ThumbnailUrl'] ||
                      item[fieldSettingsItem.field + 'Url']
                    let preview = null
                    if (fieldSettingsItem.field === 'image' && item.imageUrl) {
                      preview = {
                        image: item.imageUrl
                      }
                    } else if (fieldSettingsItem.field === 'thumbnail' && item.thumbnailUrl) {
                      preview = {
                        image: item.thumbnailUrl
                      }
                    } else if (fieldSettingsItem.field === 'icon' && item.iconUrl) {
                      preview = {
                        image: item.iconUrl
                      }
                    } else if (fieldSettingsItem.field === 'video' && (item.videoWebviewUrl || item.videoUrl)) {
                      preview = {
                        gaVideoTitle: item.title,
                        video: item.videoWebviewUrl || item.videoUrl
                      }
                    }
                    return (
                      <td
                        style={{ width: fieldSettingsItem.width || 'auto' }}
                        key={`${item.id}-${fieldSettingsItemIndex}`}
                        className={classes.editTableTd}>
                        <SwitchField
                          value={fieldValue}
                          fieldSettings={fieldSettingsItem}
                          rowId={item.id}
                          onChangeField={this.onChangeField.bind(this, item.id)}
                          exchangeFieldOrderPosition={this.exchangeFieldOrderPosition.bind(this, itemIndex)}
                          init={this.validateAndUploadFile.bind(this, item.id)}
                          isSortDuplicated={duplicateDisplayOrder.includes(item.id)}
                          disableSortTop={!!activeSortAnimationId ||
                            itemIndex === 0 ||
                            !items[itemIndex - 1].displayOrder}
                          disableSortBot={!!activeSortAnimationId ||
                            itemIndex === items.length - 1 ||
                            !items[itemIndex + 1].displayOrder}
                          preview={preview}
                        />

                        {onDeleteItem && item.isHidden && fieldSettingsItemIndex === fieldList.length - 1 &&
                        <div className={classes.deleteHolder}>
                          <UrlifeButton onClick={this.confirmDelete.bind(this, item.id)}>Delete</UrlifeButton>
                        </div>
                        }
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>}

        {newItem && <table className={`${classes.editTable} _newItem`}>
          <tbody>
            <tr className={classes.editTableTr}>
              {newFields.map((fieldSettingsItem, fieldSettingsItemIndex) => {
                const fieldValue = Array.isArray(fieldSettingsItem.field)
                  ? fieldSettingsItem.field.map(fieldName => newItem[fieldName])
                  : newItem[fieldSettingsItem.field]
                return (
                  <td
                    style={{ width: fieldSettingsItem.width || 'auto' }}
                    key={`${newItem.tempId}-${fieldSettingsItemIndex}`}
                    className={classes.editTableTd}>
                    <SwitchField
                      value={fieldValue}
                      fieldSettings={fieldSettingsItem}
                      onChangeField={onChangeNewItem}
                      isSortDuplicated={duplicateDisplayOrder.includes(newItem.tempId)}
                      disableSortTop={true}
                      disableSortBot={true}
                    />

                    {fieldSettingsItemIndex === newFields.length - 1 &&
                    <UrlifeButton
                      disabled={!newItemIsValid}
                      onClick={this.onSubmitNewItem.bind(this)}
                      className={classes.submitNewItemButton}
                      color="primary"
                      variant="contained">
                      Add
                    </UrlifeButton>}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>}

        {errorMessage && <FormControl error={true}>
          <FormHelperText className={classes.errorText}>{errorMessage}</FormHelperText>
        </FormControl>}

        <UrlifeConfirmDialog
          isOpen={!!error}
          header={'Warning'}
          text={error}
          onIgnore={() => { this.setState({ error: '' }) }}
          confirmLabel={'OK'}
          onConfirm={() => { this.setState({ error: '' }) }}
        />

        <UrlifeConfirmDialog
          isOpen={confirmDeleteOpen}
          header={deleteHeader}
          text={deleteMessage}
          onIgnore={() => { this.setState({ confirmDeleteOpen: false, deleteId: '' }) }}
          cancelLabel={'Cancel'}
          onCancel={() => { this.setState({ confirmDeleteOpen: false }) }}
          confirmLabel={'Delete'}
          onConfirm={this.onDeleteItem.bind(this)}
        />

        {addNewTempItem && <UrlifeButton
          disabled={!!newItem}
          onClick={addNewTempItem}
          color="primary"
          variant="contained"
          className={classes.addButton}>
          <PlusCircledIcon className={classes.addButtonIcon} /> {newButtonLabel}
        </UrlifeButton>}
      </React.Fragment>
    )
  }
}

EditTable.propTypes = {
  activeSortAnimationId: PropTypes.bool,
  addNewTempItem: PropTypes.func,
  classes: PropTypes.object.isRequired,
  deleteHeader: PropTypes.string,
  deleteMessage: PropTypes.string,
  duplicateDisplayOrder: PropTypes.array,
  errorMessage: PropTypes.string,
  exchangeFieldOrderPosition: PropTypes.func.isRequired,
  fieldList: PropTypes.array,
  filterComponent: PropTypes.element,
  imageMinRes: PropTypes.object,
  items: PropTypes.array,
  message: PropTypes.string,
  newButtonLabel: PropTypes.string.isRequired,
  newFieldList: PropTypes.array,
  newItem: PropTypes.object,
  newItemIsValid: PropTypes.array,
  onChangeField: PropTypes.func.isRequired,
  onChangeNewItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onSubmitNewItem: PropTypes.func,
  sortAnimationToBottomId: PropTypes.bool,
  sortAnimationToTopId: PropTypes.bool,
  title: PropTypes.string,
  uploadFile: PropTypes.func,
  videoMinRes: PropTypes.object,
  videoSquareMinRes: PropTypes.object
}

EditTable.defaultProps = {
  activeSortAnimationId: false,
  deleteHeader: '',
  deleteMessage: '',
  duplicateDisplayOrder: [],
  errorMessage: '',
  fieldList: [],
  filterComponent: null,
  imageMinRes: null,
  items: [],
  message: '',
  newButtonLabel: 'New',
  newItem: null,
  newItemIsValid: false,
  sortAnimationToBottomId: false,
  sortAnimationToTopId: false,
  title: '',
  videoMinRes: null,
  videoSquareMinRes: null
}

export default withStyles(styles)(EditTable)
