import React from 'react'

export function VideoLoading (props) {
  return (
    <svg {...props} viewBox='0 0 37 28' fill={'none'} width='1em' height='1em'>
      <rect x="1" y="1" width="35" height="26" strokeWidth="2" stroke='currentColor' />
      <path d="M22.5839 13.7072C23.238 14.0944 23.238 15.0409 22.5839 15.4282L15.7283 19.4874C15.0616 19.8821 14.2188 19.4016 14.2188 18.6269V10.5085C14.2188 9.73378 15.0616 9.2533 15.7283 9.64799L22.5839 13.7072Z" strokeWidth="2" stroke='currentColor' />
    </svg>
  )
}
