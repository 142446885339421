import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { commonStyles, getPackageType } from '../../helpers'
import {
  projectInfo,
  setTopBarHeaders,
  updateFinishedMovie,
  updateProjectSku
} from '../../actions'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import ThankYou from './ThankYou'
import { PACKAGE_TYPES, PROJECT_STATUSES } from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  formWrapper: {
    width: 600,
    margin: 'auto',
    ...commonStyles.media(767, {
      width: '80%'
    })
  },
  inputBlock: {
    width: '100%',
    display: 'block',
    paddingBottom: 80,
    ...commonStyles.media(575, {
      paddingTop: 40
    })
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  submitButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    },
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

const movieQuestions = [
  'Remove/replace content',
  'Add content',
  'Text changes',
  'Music adjustments',
  'Other'
]

const curationQuestions = [
  'Changes'
]

class MakeChanges extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      answers: [],
      project: null,
      projectSkuIndex: 0,
      questions: [],
      submitted: false
    }
  }

  componentDidMount () {
    const {
      match,
      projectInfoFunction,
      setTopBarHeadersFunction,
      packages
    } = this.props
    const { projectId, projectSkuId } = match.params

    setTopBarHeadersFunction('')
    projectInfoFunction(projectId)
      .then(
        project => {
          let projectSkuIndex = project.projectSkus.findIndex(sku => sku.id === projectSkuId)
          if (projectSkuIndex === -1) {
            projectSkuIndex = 0
          }
          const questions =
            getPackageType(
              packages,
              project.projectSkus[projectSkuIndex].packageId
            ) === PACKAGE_TYPES.CURATED
              ? curationQuestions
              : movieQuestions

          setTopBarHeadersFunction(project.title)
          this.setState({
            project: project,
            projectSkuIndex,
            questions: questions
          })
        }
      )
  }

  handleChangeInputAnswer (e, index) {
    const { answers } = this.state
    answers[index] = e.target.value
    this.setState({ answers: answers })
  }

  onSubmit (e) {
    e.preventDefault()

    const { match, updateFinishedMovieFunction, updateProjectSkuFunction } = this.props
    const { project, projectSkuIndex, questions, answers } = this.state
    const { fileId } = match.params
    const projectSku = project.projectSkus[projectSkuIndex]
    const rejectionReasons = []

    answers.forEach((answer, index) => {
      if (answer.trim().length > 0) {
        rejectionReasons.push({
          answer: answer.trim(),
          question: questions[index]
        })
      }
    })
    if (rejectionReasons.length > 0) {
      if (fileId) {
        updateFinishedMovieFunction(project.id, projectSku.id, { id: fileId, rejectionReason: rejectionReasons })
          .then(() => {
            updateProjectSkuFunction(
              project.id,
              { id: projectSku.id, statusId: PROJECT_STATUSES.PRODUCTION_REVISIONS }
            )
              .then(() => {
                this.setState({ submitted: true })
              })
          })
      } else {
        // TODO: This should be updated when there is back-end support for setting rejectionReason without
        // needing a fileId, so that curation projects can also do this
        updateProjectSkuFunction(
          project.id,
          { id: projectSku.id, statusId: PROJECT_STATUSES.PRODUCTION_REVISIONS }
        )
          .then(() => {
            this.setState({ submitted: true })
          })
      }
    }
  }

  render () {
    const { classes } = this.props
    const { questions, answers, submitted } = this.state
    let answersAreValid = false
    for (let i = 0; !answersAreValid && i < questions.length; i++) {
      answersAreValid = answers[i] && answers[i].trim().length > 0
    }
    return (
      <React.Fragment>
        {!submitted &&
        <div className={classes.formWrapper}>
          <form onSubmit={this.onSubmit.bind(this)}>
            <div className={classes.inputBlock}>
              {questions.map((question, index) => {
                const answer = answers[index]
                return (
                  <UrlifeTextField
                    key={index}
                    multiline
                    rowsMax={5}
                    label={question}
                    onChange={e => this.handleChangeInputAnswer(e, index)}
                    defaultValue={answer}
                    infoLabel={
                      index === questions.length - 1
                        ? 'Please add time references where applicable'
                        : ''
                    }
                  />
                )
              })}
            </div>
          </form>
          <div className={classes.buttonBlock}>
            <UrlifeButton
              className={classes.submitButton}
              onClick={this.onSubmit.bind(this)}
              disabled={!answersAreValid}>
              Submit
            </UrlifeButton>
          </div>
        </div>}
        {submitted && <ThankYou />}
      </React.Fragment>
    )
  }
}
MakeChanges.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  packages: PropTypes.array,
  projectInfoFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateFinishedMovieFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    projectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setTopBarHeadersFunction: function (projectTitle) {
      dispatch(setTopBarHeaders(projectTitle, 'What would you like to change?'))
    },
    updateFinishedMovieFunction: function (projectId, projectSkuId, file) {
      return dispatch(updateFinishedMovie(projectId, projectSkuId, file))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MakeChanges))
