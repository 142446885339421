import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import {
  deleteProject,
  getPackageSku,
  projectInfo,
  setSkinId,
  updateProject,
  updateProjectSku
} from '../../actions'
import {
  commonStyles,
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  history,
  mapProjectStatusToStage
} from '../../helpers'
import { PROJECT_STAGES, ROLES } from '../../constants'
import ProjectPageUser from './components/ProjectPageUser'
import ProjectPageProducer from './components/ProjectPageProducer'

/* eslint-disable sort-keys */
const styles = {
  editProjectPage: {
    marginTop: -30,
    ...commonStyles.media(575, {
      marginTop: 10
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 10
    }),
    '&._userPrep': {
      marginTop: -30,
      ...commonStyles.media(575, {
        marginTop: 30
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        marginTop: 30
      })
    }
  }
}
/* eslint-enable sort-keys */

class EditProjectPage extends Component {
  constructor (props) {
    super(props)

    const { match, location } = props
    let openChat = false

    if (/projects\/[^/]+$/.test(location.pathname)) {
      history.push(`/projects/${match.params.project}/summary`)
    } else if (/projects\/.*\/chat/.test(location.pathname)) {
      history.replace(`/projects/${match.params.project}/summary`)
      openChat = true
    }

    this.state = {
      openChat: openChat,
      project: null
    }
  }

  async fetchProject (projectId) {
    const {
      getPackageSkuFunction,
      projectInfoFunction,
      setSkinIdFunction
    } = this.props
    try {
      const project = await projectInfoFunction(projectId)
      if (project) {
        getPackageSkuFunction(project.projectSkus[0].packageSku).then(
          data => {
            setSkinIdFunction(data.skinId)
          }
        )
        this.setState({ project })
      } else {
        history.push('/home')
      }
    } catch (e) {
      history.push('/home')
    }
  }

  componentDidUpdate (prevProps) {
    const { match } = this.props
    if (match.params.project !== prevProps.match.params.project) {
      this.fetchProject(match.params.project)
    }
  }

  componentDidMount () {
    const { match } = this.props
    if (match.params.project) {
      this.fetchProject(match.params.project)
    }
  }

  componentWillUnmount () {
    if (!history.location.pathname.match(/\/projects\/.*\/movie-schedule-call/i)) {
      const { setSkinIdFunction } = this.props
      setSkinIdFunction(null)
    }
  }

  async updateProject (updateFields = null) {
    const { updateProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      const proj = updateFields ? { id: project.id, ...updateFields } : project
      const projectRes = await updateProjectFunction(proj)
      this.setState({ project: projectRes })
    }
  }

  async deleteProject () {
    const { deleteProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      await deleteProjectFunction(project.id)
      this.setState({ project: null })
    }
  }

  async updateProjectSku (updateFields = {}) {
    const { updateProjectSkuFunction } = this.props
    const { project } = this.state
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const projectSkuRes = await updateProjectSkuFunction(project.id, { id: projectSku.id, ...updateFields })
      const skuIndex = project.projectSkus.findIndex(sku => sku.id === projectSku.id)
      project.projectSkus[skuIndex] = projectSkuRes
      this.setState({ project })
    }
  }

  render () {
    const { classes, match, location, roleId } = this.props
    const { project, openChat } = this.state
    const statusId = getLeastCompleteProjectSkuStatus(project)
    const stage = mapProjectStatusToStage(statusId)
    const isUserPrep = stage === PROJECT_STAGES.PREP
    return (
      <div className={classNames(classes.editProjectPage, { _userPrep: isUserPrep })}>
        {project && roleId !== ROLES.USER &&
        <ProjectPageProducer
          project={project}
          match={match}
          location={location}
          updateProject={this.updateProject.bind(this)}
          updateProjectSku={this.updateProjectSku.bind(this)}
        />
        }
        {project && roleId === ROLES.USER &&
        <ProjectPageUser
          project={project}
          openChat={openChat}
          updateProject={this.updateProject.bind(this)}
          deleteProject={this.deleteProject.bind(this)}
          updateProjectSku={this.updateProjectSku.bind(this)}
        />
        }
      </div>
    )
  }
}

EditProjectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteProjectFunction: PropTypes.func.isRequired,
  getPackageSkuFunction: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  projectInfoFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  updateProjectFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    cognito: state.user.cognito,
    roleId: state.user.roleId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteProjectFunction: function (projectId) {
      return dispatch(deleteProject(projectId))
    },
    getPackageSkuFunction: function (id) {
      return dispatch(getPackageSku(id))
    },
    projectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    updateProjectFunction: function (project) {
      return dispatch(updateProject(project))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditProjectPage))
