const thumbnails = (state = {
  queue: [],
  thumbnails: {}
}, action) => {
  switch (action.type) {
    case 'ADD_THUMBNAIL_TO_QUEUE':
      if (!action.file) return state
      return {
        ...state,
        queue: [
          ...state.queue,
          action.file
        ]
      }
    case 'ADD_THUMBNAIL':
      if (!action.fileId) return state
      return {
        ...state,
        queue: state.queue.slice(1),
        thumbnails: {
          ...state.thumbnails,
          [action.fileId]: {
            id: action.fileId,
            thumbnail: action.thumbnail
          }
        }
      }
    case 'CLEAR_THUMBNAILS':
      return {
        queue: [],
        thumbnails: {}
      }
    default:
      return state
  }
}

export default thumbnails
