import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function LoadMore (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 4">
      <g fill="#01b7d7" transform="translate(0,-32)" id="layer1"><circle r="2" cy="33.969559" cx="18.171875" id="path3754" /><circle r="2" cy="34.000809" cx="33.998592" id="path3754-4" /><circle r="2" cy="34.00639" cx="2.0262671" id="path3754-4-9" /></g>
    </SvgIcon>
  )
}
