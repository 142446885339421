import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import StarsMovieInput from './StarsMovieInput'
import {
  createCast,
  createCastFile,
  deleteCast,
  deleteCastFile,
  listCast,
  queueThumbnail,
  updateCast
} from '../../../actions'
import { ROLES } from '../../../constants'
import { commonStyles } from '../../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  BtnAddMore: {
    fontFamily: 'Montserrat',
    fontSize: '17px',
    color: '#01b7d7',
    cursor: 'pointer',
    margin: 15,
    paddingTop: 10,
    width: 'calc(33.33333% - 30px)',
    padding: '0',
    '&._disable': {
      opacity: 0.3,
      pointerEvents: 'none'
    },
    '&._createProject': {
      width: '100%'
    },
    '&._projectDetails': {
      width: '100%',
      fontSize: 16,
      paddingTop: 0
    },
    ...commonStyles.media(767, {
      width: 'calc(50% - 30px)'
    }),
    ...commonStyles.media(575, {
      width: '100%',
      padding: 0,
      margin: '10px 0'
    })
  },
  InputWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    margin: '-15px',
    ...commonStyles.media(575, {
      margin: '-10px 0'
    })
  }
}
/* eslint-enable sort-keys */

class StarsMovie extends React.Component {
  constructor (props) {
    super(props)
    this.counter = 0
    this.changedFiles = {}
    this.mounted = false

    this.state = {
      movieCasts: [this.getEmptyCast()]
    }
  }

  async componentDidMount () {
    const { projectId, listCastFunction } = this.props

    this.mounted = true
    // get all stars from backend
    try {
      const data = await listCastFunction(projectId)
      this.handleCastChange(data)
    } catch (e) {}
  }

  componentWillUnmount () {
    this.mounted = false
  }

  getEmptyCast () {
    return { name: '', tempId: `cast-${++this.counter}` }
  }

  handleCastChange (cast) {
    this.mounted && this.setState({
      movieCasts: cast.length ? cast : [this.getEmptyCast()]
    })
  }

  handleChangeInput (i, e) {
    const movieCasts = [...this.state.movieCasts]
    if (movieCasts[i]) {
      movieCasts[i].name = e.target.value
      if (movieCasts[i].id) {
        this.changedFiles[movieCasts[i].id] = true
      }
    }
    this.setState({ movieCasts })
  }

  handleDelete (i) {
    const {
      projectId,
      deleteCastFunction
    } = this.props
    const movieCasts = [...this.state.movieCasts]
    if (movieCasts[i]) {
      const newCast = movieCasts.filter((cast, castIndex) => castIndex !== i)
      this.handleCastChange(newCast)
      if (movieCasts[i].id) {
        deleteCastFunction(movieCasts[i].id, projectId, movieCasts.castFileId)
      }
    }
  }

  async applyChangesCasts (i) {
    const {
      projectId,
      createCastFunction,
      updateCastFunction
    } = this.props
    const casts = [...this.state.movieCasts]

    const { tempId, ...postCastData } = casts[i]

    if (casts[i] && (!casts[i].id || this.changedFiles[casts[i].id])) {
      if (casts[i].id) {
        this.changedFiles[casts[i].id] = false
        await updateCastFunction(postCastData, projectId)
        this.handleCastChange(casts)
      } else {
        const { tempId } = casts[i]
        const data = await createCastFunction(postCastData, projectId)
        const updatedCasts = this.state.movieCasts.map(cast => {
          return cast.tempId === tempId ? { ...cast, ...data } : cast
        })
        this.handleCastChange(updatedCasts)
      }
    }
  }

  async imageChange (i, file) {
    const {
      projectId,
      createCastImageFunction,
      deleteCastImageFunction,
      queueThumbnailFunction
    } = this.props
    let movieCasts = this.state.movieCasts
    let movieCast = { ...movieCasts[i] }
    if (!movieCast || !file) {
      return null
    }
    if (!movieCast.id) {
      await this.applyChangesCasts(i)
      movieCasts = this.state.movieCasts
      movieCast = { ...movieCasts[i] }
      if (!movieCast.id) {
        return null
      }
    } else {
      delete movieCasts[i].signedUrl
      delete movieCasts[i].signedThumbnailUrl
      delete movieCasts[i].cdnUrl
      delete movieCasts[i].thumbnailCdnUrl
      movieCasts[i].castFileId = 'uploading'
      this.handleCastChange(movieCasts)
    }

    const fileInfo = { mimeType: file.type, uploadSource: 'web' }
    if (!fileInfo.mimeType && /.*\.(HEIC|heic|HEIF|heif)$/.test(file.name)) {
      if (/.*\.(HEIC|heic)$/.test(file.name)) {
        fileInfo.mimeType = 'image/heic'
      } else if (/.*\.(HEIF|heif)$/.test(file.name)) {
        fileInfo.mimeType = 'image/heif'
      }
    }

    const createCastImage = async () => {
      const castFileId = await createCastImageFunction(
        movieCast.id,
        projectId,
        { files: [fileInfo], type: 'file' },
        file
      )
      queueThumbnailFunction({ ...fileInfo, id: castFileId, src: window.URL.createObjectURL(file) })
      movieCasts[i] = {
        ...movieCasts[i],
        castFileId,
        orientation: fileInfo.orientation
      }
      this.handleCastChange(movieCasts)
    }
    if (movieCast.castFileId) {
      await deleteCastImageFunction(movieCast.id, projectId, movieCast.castFileId)
    }
    createCastImage()
  }

  addMore () {
    this.setState({
      movieCasts: [...this.state.movieCasts, { name: '', tempId: `cast-${++this.counter}` }]
    })
  }

  render () {
    const { classes, roleId, type, thumbnails } = this.props
    const { movieCasts } = this.state

    return (
      <div className={classes.InputWrap}>
        {movieCasts.map(
          (cast, i) => {
            const thumbnail = thumbnails && thumbnails[cast.castFileId]
              ? thumbnails[cast.castFileId].thumbnail
              : null
            return (
              <StarsMovieInput
                key={cast.tempId || cast.id}
                num={i}
                isDeletable={ !!cast.id || movieCasts.length > 1 }
                cast={cast}
                type={type}
                thumbnail={thumbnail}
                onImageChange={this.imageChange.bind(this, i)}
                onBlur={this.applyChangesCasts.bind(this, i)}
                onDelete={this.handleDelete.bind(this, i)}
                onChange={this.handleChangeInput.bind(this, i)}
              />
            )
          }
        )}
        {roleId === ROLES.USER && type !== 'projectDetails' &&
        <div
          className={
            classNames(
              classes.BtnAddMore,
              { _createProject: type === 'createProject' },
              { _projectDetails: type === 'projectDetailsEditing' }
            )
          }
          onClick={this.addMore.bind(this)}>
          + Add more
        </div>
        }
      </div>
    )
  }
}
StarsMovie.propTypes = {
  classes: PropTypes.object.isRequired,
  createCastFunction: PropTypes.func.isRequired,
  createCastImageFunction: PropTypes.func.isRequired,
  deleteCastFunction: PropTypes.func.isRequired,
  deleteCastImageFunction: PropTypes.func.isRequired,
  listCastFunction: PropTypes.func.isRequired,
  location: PropTypes.object,
  match: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  queueThumbnailFunction: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  thumbnails: PropTypes.object,
  type: PropTypes.oneOf(['normal', 'createProject', 'projectDetails', 'projectDetailsEditing']).isRequired,
  updateCastFunction: PropTypes.func.isRequired
}

StarsMovie.defaultProps = {
  type: 'normal'
}

function mapStateToProps (state) {
  return {
    roleId: state.user.roleId,
    thumbnails: state.thumbnails.thumbnails
  }
}

function mapDispatchToProps (dispatch) {
  return {
    createCastFunction: function (cast, projectId) {
      return dispatch(createCast(cast, projectId))
    },
    createCastImageFunction: function (castId, projectId, file, fileObject) {
      return dispatch(createCastFile(castId, projectId, file, fileObject))
    },
    deleteCastFunction: function (castId, projectId, castFileId) {
      return dispatch(deleteCast(castId, projectId, castFileId))
    },
    deleteCastImageFunction: function (castId, projectId, fileId) {
      return dispatch(deleteCastFile(fileId, castId, projectId))
    },
    listCastFunction: function (projectId) {
      return dispatch(listCast(projectId))
    },
    queueThumbnailFunction: function (file) {
      return dispatch(queueThumbnail(file))
    },
    updateCastFunction: function (cast, projectId) {
      return dispatch(updateCast(cast, projectId))
    }
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StarsMovie)
)
