import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  createPromocode,
  fetchPromocode,
  fetchPromocodes,
  updatePromocode
}

function createPromocode (promocodeData) {
  return dispatch => {
    return new ApiService(api.PROMOCODES, {}, {}, true, dispatch)
      .post(promocodeData)
  }
}

function updatePromocode (promoId, promocodeData) {
  return dispatch => {
    return new ApiService(api.ONE_PROMOCODE, { promoId }, {}, true, dispatch)
      .put(promocodeData)
  }
}

function fetchPromocodes (params = {}) {
  return dispatch => {
    return new ApiService(api.PROMOCODES, {}, params, true, dispatch)
      .get()
  }
}

function fetchPromocode (promoId, params = {}) {
  return dispatch => {
    return new ApiService(api.ONE_PROMOCODE, { promoId }, params, true, dispatch)
      .get()
  }
}
