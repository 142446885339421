import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TopTenTip from './components/TopTenTip'
import { commonStyles } from '../../helpers'
import startImage from '../../assets/img/top10/start_Landing_Page_Wiffle.jpg'
import horizontalThumb from '../../assets/img/top10/01_horizontal.jpg'
import soundbitesThumb from '../../assets/img/top10/02_soundbites.jpg'
import estShotThumb from '../../assets/img/top10/03_establishing.jpg'
import signageThumb from '../../assets/img/top10/04_signage.jpg'
import proveItThumb from '../../assets/img/top10/05_prove_it.jpg'
import followThumb from '../../assets/img/top10/06_follow_get_in_front.jpg'
import tiltThumb from '../../assets/img/top10/07_tilt_up_down.jpg'
import pullBackThumb from '../../assets/img/top10/08_pull_back.jpg'
import includeYourselfThumb from '../../assets/img/top10/09_include_yourself.jpg'
import exitInterviewThumb from '../../assets/img/top10/10_exit_interview.jpg'
import endImage from '../../assets/img/top10/end_late_beach.jpg'

/* eslint-disable sort-keys */
const styles = {
  topTenContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontSize: 26,
    lineHeight: '52px',
    fontWeight: 600,
    color: '#5334b6',
    textTransform: 'uppercase',
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '40px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '32px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '32px'
    })
  },
  subHeader: {
    fontSize: 48,
    lineHeight: '72px',
    fontWeight: 500,
    color: '#323232',
    ...commonStyles.media(767, {
      fontSize: 40,
      lineHeight: '56px'
    }),
    ...commonStyles.media(575, {
      fontSize: 32,
      lineHeight: '40px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 32,
      lineHeight: '40px'
    })
  },
  tipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}
/* eslint-enable sort-keys */

const tips = [
  {
    alt: 'start top10 image',
    description: <React.Fragment>
      <p>A great meal starts with great ingredients. And a great family movie starts with great footage.</p>
      <p>Our co-founder, <b>Jack Giarraputo</b> - Hollywood producer
        (Big Daddy, Waterboy, Grown Ups) and home-video expert
        - would like to guide you to capturing the best videos of your summer
        with this list of easy-to-use tips and tricks. These are the techniques he uses himself,
        to keep his favorite memories alive forever.</p>
      <p>We know you will already be taking tons of photos/videos anyway.
        We are going to help you do it better, more efficiently and with a purpose.</p>
      <p>And rather than just ending up with a few social posts,
        we can edit it all together for you - creating a movie of the Summer of 2020,
        a time capsule - that you can relive and remember - forever.</p>
    </React.Fragment>,
    image: startImage,
    thumbnail: '',
    title: ''
  },
  {
    description: <div>
      <p>Think of your TV. A movie. Those nice wide shots.
        Horizontal shots give us more information about the surroundings.
        They also mimic what we ourselves are seeing with our eyes.
        Hold your phone horizontal to capture the best footage!</p>
    </div>,
    thumbnail: horizontalThumb,
    title: 'HORIZONTAL. HORIZONTAL. HORIZONTAL.',
    video: 'https://static.urlifemedia.com/top10/01_horizontal.mp4'
  },
  {
    description: <div>
      <p>Film your travel buddy in different scenarios,
        and we’ll use the soundbites captured to make your movie even more personal!</p>
      <div>
        <p><b>Examples</b></p>
        <div>
          <p><b><i>Pre-trip/event. Some examples of questions to ask:</i></b></p>
          <div>
            <p><i>What are you looking forward to the most? Who will you hang with,
              what will you do? What will be different this year or about this new destination?</i></p>
          </div>
        </div>
        <div>
          <p><b>Vacation Stories/Experiences</b></p>
          <div>
            <p>When the kids rush in the door to tell you a story - say, “wait 5 seconds”.
              Grab your phone and have them tell you on camera. Did you miss it?
              That’s okay too - you’re the Director!
              Get the phone ready and ask for another take - say, “Wow that&apos;s awesome.
              Say that again for our summer movie recap.”</p>
          </div>
        </div>
      </div>
    </div>,
    thumbnail: soundbitesThumb,
    title: 'SOUNDBITES',
    video: 'https://static.urlifemedia.com/top10/02_soundbites.mp4'
  },
  {
    description: <div>
      <p>Always start a scene with a big wide shot (just like in the movies!) to show your location
        - or the space of where the action is going to be. It could be one wide shot - or a few shots.
        It could be new establishing shots for each day/activity.</p>
    </div>,
    thumbnail: estShotThumb,
    title: 'THE ESTABLISHING SHOT.',
    video: 'https://static.urlifemedia.com/top10/03_establishing.mp4'
  },
  {
    description: <div>
      <p>This always helps to tell a story. Name of the town. Restaurants. Beaches. Menus. The more the better.</p>
    </div>,
    thumbnail: signageThumb,
    title: 'SIGNAGE.',
    video: 'https://static.urlifemedia.com/top10/04_signage.mp4'
  },
  {
    description: <div>
      <p>The concept is simple: <i>Prove</i> you were there. Don’t just take a picture of the Eiffel Tower
        - take a picture of your kid in front of the Eiffel Tower. For summer - don’t just shoot the pretty beach
        – take a great pan shot of the beach and then your kid running into the water! Then we have a great memory.</p>
    </div>,
    thumbnail: proveItThumb,
    title: 'THE PROVE IT SHOT.',
    video: 'https://static.urlifemedia.com/top10/05_prove_it.mp4'
  },
  {
    description: <div>
      <p>Tell them to walk ahead of you (into a restaurant, or down to the beach).
        You stay back for 5 seconds then <b>FOLLOW</b> and film them walking to the featured place.
        It can also be a great establishing shot.</p>
      <p>Or - do the <i>opposite</i> - and <b>GET IN FRONT</b>.
        You go ahead and start filming them as they come towards you
        - so you can see their faces.  Seeing faces - reactions - is priceless.</p>
    </div>,
    thumbnail: followThumb,
    title: 'FOLLOW or GET IN FRONT',
    video: 'https://static.urlifemedia.com/top10/06_follow_get_in_front.mp4'
  },
  {
    description: <div>
      <p>Is a great way to make a reveal. Start on a platter of food, tilt up to see the reaction of your subject!</p>
    </div>,
    thumbnail: tiltThumb,
    title: 'TILT UP/DOWN',
    video: 'https://static.urlifemedia.com/top10/07_tilt_up_down.mp4'
  },
  {
    description: <div>
      <p>It’s always great to see food being prepared or grilled. We love the PULL BACK shot here.
        Start in close on the burger cooking and walk backwards a few steps
        until you can see the chef and all the surrounding area.</p>
    </div>,
    thumbnail: pullBackThumb,
    title: 'PULL BACK',
    video: 'https://static.urlifemedia.com/top10/08_pull_back.mp4'
  },
  {
    description: <div>
      <p>You might usually be the one doing the filming, but make sure you get yourself in some shots too.
        Years from now the family isn’t going to just want to see themselves
        – they are going to want to see you too!</p>
    </div>,
    thumbnail: includeYourselfThumb,
    title: 'INCLUDE YOURSELF IN THE STORY!',
    video: 'https://static.urlifemedia.com/top10/09_include_yourself.mp4'
  },
  {
    description: <div>
      <p>The exit interview is key - it’s like a debriefing after a mission.
        Soundbites from this interview make for great voiceover throughout the video
        to narrate the things we are seeing.</p>
      <div>
        <p><b><i>Ask questions like:</i></b></p>
        <div>
          <p><i>So, what was your favorite part of the summer/vacation/event? What were the highlights?
            What happened that you didn’t expect? Did you make any new friends?
            Try anything new? What will you miss most?</i></p>
        </div>
      </div>
    </div>,
    thumbnail: exitInterviewThumb,
    title: 'EXIT INTERVIEW',
    video: 'https://static.urlifemedia.com/top10/10_exit_interview.mp4'
  },
  {
    alt: 'end top10 image',
    description: <React.Fragment>
      <p>Now that you know what the good footage is to capture - you can actually be more IN THE MOMENT,
        because once you get your shot, you are done. You are no longer in fear of missing everything.</p>
      <p>But don’t worry. Even if you do none of the above
        - we can still make a great memory from the stuff you take on your own. Editing provides the magic
        - and our team of personal editors are going to be the new luxury you can’t live without.</p>
      <p>Now, let’s go make a movie!</p>
    </React.Fragment>,
    image: endImage,
    thumbnail: '',
    title: ''
  }
]

class TopTen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentTip: 0,
      mobile: null
    }
  }

  setCurrentTip (tipIndex) {
    this.setState({ currentTip: tipIndex })
  }

  handleResize () {
    const { mobile } = this.state
    if (window.innerWidth <= 575 || (window.innerWidth <= 896 && window.innerHeight <= 414)) {
      if (!mobile) {
        this.setState({ mobile: true })
      }
    } else {
      if (mobile || mobile === null) {
        this.setState({ mobile: false })
      }
    }
  }

  componentDidMount () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize.bind(this), true)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize.bind(this), true)
  }

  render () {
    const { classes } = this.props
    const { currentTip, mobile } = this.state
    return (
      <div className={classes.topTenContainer}>
        <div className={classes.coverImage}/>
        <div className={classes.header}>
          The URLIFE Summer Shot List
        </div>
        <div className={classes.subHeader}>
          10 Simple Tips to Take WOW! Summer Videos
        </div>
        <div className={classes.tipWrapper}>
          {mobile === true &&
            tips.map((tip, index) => (
              <TopTenTip
                key={index}
                tip={tip}
                index={index}
                currentIndex={currentTip}
                setTip={this.setCurrentTip.bind(this)}
              />
            ))
          }
          {mobile === false &&
            <TopTenTip
              key={currentTip}
              tip={tips[currentTip]}
              index={currentTip}
              currentIndex={currentTip}
              setTip={this.setCurrentTip.bind(this)}
            />
          }
        </div>
      </div>
    )
  }
}

TopTen.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TopTen)
