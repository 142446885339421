/* eslint no-multi-spaces: ["error", { ignoreEOLComments: true }] */

export const awsInfo = {
  BUCKET: process.env.REACT_APP_BUCKET,
  KEY_MAP: process.env.REACT_APP_KEY_MAP,
  UPLOAD: process.env.REACT_APP_UPLOAD_DIR
}

export const BUILD_ID = process.env.REACT_APP_BUILD_ID || 'dev'

export const cdnDomain = process.env.REACT_APP_CDN_DOMAIN

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY

export const DEBUG_LEVEL = process.env.REACT_APP_DEBUG_LEVEL || 'none'

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

export const ZIP_WEBSOCKET_ENDPOINT = process.env.REACT_APP_ZIP_WEBSOCKET_ENDPOINT

export const APP_STORE_LINK = 'https://apps.apple.com/app/id1535638517'

export const api = {
  CAST: 'projects/:projectId/cast',
  CAST_FILES: 'projects/:projectId/cast/:castId/files',
  CATEGORY: 'category',
  COGNITO: 'user/me/cognito',
  COUPONS: 'users/:userId/coupons',
  CURATED_FILES: 'projects/:projectId/curated',
  CURATED_FILES_SKU: 'projects/:projectId/skus/:projectSkuId/curated',
  CURRENT_USER_INFO: 'user/me',
  DOWNLOAD_SELECTED_FILES: 'projects/:projectId/download',
  EMAIL_TEMPLATES: 'email',
  ENCODING_QUEUE: 'system/encoding',
  EXPLORE: 'explore',
  FINISHED_MOVIE: 'projects/:projectId/movie',
  FINISHED_MOVIE_SKU: 'projects/:projectId/skus/:projectSkuId/movie',
  FORGOTTEN: 'auth/local/forgotpassword',
  GENERATE_ZIP: 'projects/:projectId/download/zip',
  GET_PACKAGE_ACCESS: 'users/:userId/packages',
  HEALTH_CHECK: 'healthcheck',
  INFO: 'user',
  INSPIRATION: 'inspiration',
  INVITE: 'auth/local/invites',
  INVITE_COMING_SOON: 'auth/local/comingsoon',
  INVITES: 'invites',
  LIST_USER_PROJECTS: 'users/:userId/projects',
  LOGIN: 'auth/local/verify',
  LOGS: 'logs',
  MESSAGES: 'projects/:projectId/messages/:thread',
  NOTIFICATIONS: 'user/me/notifications',
  ONE_CAST: 'projects/:projectId/cast/:castId',
  ONE_CAST_FILE: 'projects/:projectId/cast/:castId/files/:fileId',
  ONE_CATEGORY: 'category/:categoryId',
  ONE_COUPON: 'users/:userId/coupons/:couponId',
  ONE_CURATED_FILE_SKU: 'projects/:projectId/skus/:projectSkuId/curated/:fileId',
  ONE_EMAIL_TEMPLATE: 'email/:emailId',
  ONE_EXPLORE: 'explore/:videoId',
  ONE_FINISHED_MOVIE_SKU: 'projects/:projectId/skus/:projectSkuId/movie/:fileId',
  ONE_INSPIRATION: 'inspiration/:inspirationId',
  ONE_INVITE: 'invites/:inviteId',
  ONE_MESSAGE: 'projects/:projectId/messages/:thread/:messageId',
  ONE_NOTIFICATION: 'user/me/notifications/:notificationId',
  ONE_PACKAGE_SKU: 'packagesku/:skuId',
  ONE_PROJECT_CODE_GROUPING: 'projectcodegroupings/:groupId',
  ONE_PROJECT_FILE: 'projects/:projectId/files/:fileId',
  ONE_PROJECT_FILE_DOWNLOAD: 'projects/:projectId/files/:fileId/download',
  ONE_PROJECT_RESOURCE: 'projects/:projectId/resources/:fileId',
  ONE_PROJECT_SKU: 'projects/:projectId/skus/:projectSkuId',
  ONE_PROMOCODE: 'promocodes/:promoId',
  ONE_PROMPT: 'category/:categoryId/prompts/:promptId',
  ONE_PROMPT_ANSWER: 'projects/:projectId/prompt/:promptAnswerId',
  ONE_SYSTEM_FILE: 'system/files/:fileId',
  ONE_TIP: 'tips/:tipId',
  ONE_USER_NOTIFICATION: 'notifications/:userId/:notificationId',
  ONLY_MARKETING_SERVER_NAME: 'https://alvik.stage.urlifemedia.com/',
  PACKAGE_SKUS: 'packages/:packageId/sku',
  PACKAGES: 'packages',
  PRODUCER_FAVORITES_TOTAL_SIZE: 'projects/:projectId/files/download/favorites/size',
  PROJECT: 'projects',
  PROJECT_CODE_GROUPINGS: 'projectcodegroupings',
  PROJECT_CODES: 'projectcodegroupings/:groupId/codes',
  PROJECT_COUPON_PURCHASE: 'projects/:projectId/skus/:projectSkuId/couponpurchase',
  PROJECT_FILES: 'projects/:projectId/files',
  PROJECT_FILES_DOWNLOAD: 'projects/:projectId/files/download/all',
  PROJECT_FILES_TOTAL_SIZE: 'projects/:projectId/files/download/all/size',
  PROJECT_INFO: 'projects/:projectId',
  PROJECT_NOTES: 'projects/:projectId/notes',
  PROJECT_PAYMENT: 'projects/:projectId/skus/:projectSkuId/purchase',
  PROJECT_RESOURCES: 'projects/:projectId/resources',
  PROJECT_SKUS: 'projects/:projectId/skus',
  PROMOCODES: 'promocodes',
  PROMPT_ANSWERS: 'projects/:projectId/prompts',
  PROMPTS: 'category/:categoryId/prompts',
  PROMPTS_LIST: 'category/:categoryId/promptslist',
  REFRESH: 'auth/local/token',
  REGISTER: 'auth/local/register',
  REPORTS_FIRST_TIME_USERS: 'reports/firsttimeusers',
  REPORTS_NEW_USERS: 'reports/newusers',
  REPORTS_PRE_PURCHASE_USERS: 'reports/prepurchaseusers',
  REPORTS_REPEATING_USERS: 'reports/repeatingusers',
  REPORTS_RETURNING_USERS: 'reports/returningusers',
  REPORTS_YEAR_STATISTICS: 'reports/statisticsbyyear',
  RESET: 'auth/local/resetpassword',
  ROLES: 'roles',
  SERVER_NAME: process.env.REACT_APP_BACKEND_SERVER,
  SET_USER_PACKAGE_ACCESS: 'users/:userId/packages/:packageId',
  SIGN_UP: 'auth/local/signup',
  SYSTEM_FILES: 'system/files/:fileType',
  TIPS: 'tips',
  TOPIC_SUBSCRIPTIONS: 'signups',
  USE_CODE: 'code/use',
  USER_INFO: 'user/:userId',
  USER_NOTIFICATIONS: 'notifications/:userId',
  USER_ROLE: 'users/:userId/role',
  USERS: 'users',
  VERIFY_PROJECT_CODE: 'auth/local/projectcode'
}

export const ROLES = {
  ADMIN: 1,
  EDITOR: 4,
  GUEST: 5,
  PRODUCER: 3,
  USER: 2
}

export const ROLE_NAMES = {
  1: 'ADMIN',
  2: 'USER',
  3: 'PRODUCER',
  4: 'EDITOR',
  5: 'GUEST'
}

export const PROJECT_STATUSES = {
  COMPLETE: 4,
  DELETED: 5,
  PREP_CUSTOM_NEGOTIATION: 11,
  PREP_USER: 1,
  PREP_WAIT_PAYMENT: 7,
  PRODUCTION_EDITING: 8,
  PRODUCTION_PRODUCER_ASSIGNED: 3,
  PRODUCTION_PRODUCER_REVIEW: 9,
  PRODUCTION_REVISIONS: 10,
  PRODUCTION_WAIT_CUSTOMER_APPROVAL: 6,
  PROJECT_QUEUED: 2
}

/* eslint-disable sort-keys */
export const PROJECT_STATUSES_NAME = {
  1: 'User Prep',
  2: 'Queued',
  3: 'In Production',
  4: 'Finished',
  5: 'Deleted',
  6: 'Awaiting customer approval',
  7: 'Awaiting payment',
  8: 'In Editing',
  9: 'In Producer Review',
  10: 'In Revisions',
  11: 'Custom negotiation'
}
/* eslint-enable sort-keys */

export const PROJECT_STATUSES_ORDER = [
  PROJECT_STATUSES.PREP_USER,
  PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION,
  PROJECT_STATUSES.PREP_WAIT_PAYMENT,
  PROJECT_STATUSES.PROJECT_QUEUED,
  PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
  PROJECT_STATUSES.PRODUCTION_EDITING,
  PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW,
  PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL,
  PROJECT_STATUSES.PRODUCTION_REVISIONS,
  PROJECT_STATUSES.COMPLETE,
  PROJECT_STATUSES.DELETED
]

export const PROJECT_STAGES = {
  COMPLETE: 4,
  PREP: 1,
  PRODUCTION: 3,
  QUEUED: 2,
  UNKNOWN: 0
}

export const PACKAGE_TYPES = {
  CURATED: 3,
  HOLLYWOOD: 1,
  RECAP: 2
}

export const FILE_STATUSES = {
  CLIENT_UPLOAD_COMPLETE: 3,
  CLIENT_UPLOADING: 1,
  FILE_DELETED: 5,
  FILE_READY: 4,
  UPLOAD_FAILED: 2
}

export const NOTIFICATION_TYPES = {
  ALL: 1
}

export const ONLY_MARKETING = /(tallkrogen|comingsoon)/.test(window.location.hostname)

export const PROJECT_DATA = {
  MAX_PROMPTS_PER_PAGE: 3
}

export const CONTACT_CHOICE = {
  APPOINTMENT: 3,
  CHOICE_NOT_MADE: 0,
  EMAIL_ME: 4,
  NOTES: 1,
  PRODUCER_CONTACT: 2
}

export const initProjectCreationStatus = {
  cast: false,
  category: false,
  contactChoice: CONTACT_CHOICE.CHOICE_NOT_MADE,
  description: false,
  madeAppointment: false,
  promptsAnswered: 0,
  title: false,
  totalNumberOfPrompts: 0,
  uploaded: false
}

export const MAX_TRANSCODE_FILE_SIZE = 10 * 1024 * 1024 * 1024
