const file = (state = {
  curatedFiles: [],
  files: [],
  finishedMovieFiles: [],
  producerFavoritesTotalSize: 0
}, action) => {
  let newFiles = []
  switch (action.type) {
    case 'FETCHING_FILES':
      newFiles = []
      if (action.add) {
        newFiles = state.files
      }
      return {
        ...state,
        failedFetchingFiles: false,
        fetchingFiles: true,
        files: newFiles,
        nextFile: action.nextCursor || null,
        succeededFetchingFiles: false
      }
    case 'PROJECT_FILES':
      newFiles = action.files
      if (action.add) {
        newFiles = [...state.files, ...action.files]
      }
      return {
        ...state,
        fetchingFiles: false,
        files: newFiles,
        nextFile: action.nextCursor || null,
        succeededFetchingFiles: true
      }
    case 'PROJECT_FILES_FAILED':
      return {
        ...state,
        failedFetchingFiles: true,
        fetchingFiles: false
      }
    case 'PROJECT_FILES_APPEND':
      return {
        ...state,
        files: [...state.files, ...action.files]
      }
    case 'PROJECT_FILE_UPDATE':
      return {
        ...state,
        files: state.files.map(file => action.file && action.file.id === file.id
          ? { ...file, ...action.file }
          : file
        )
      }
    case 'PROJECT_FILE_DELETE':
      const files = state.files || []
      return {
        ...state,
        files: files.filter(file => file.id !== action.file)
      }
    case 'CLEAR_PROJECT_FILES':
      return {
        ...state,
        files: [],
        producerFavoritesTotalSize: 0
      }
    case 'SET_PRODUCER_FAVORITES_SIZE':
      return {
        ...state,
        producerFavoritesTotalSize: action.size
      }
    case 'FETCHING_CURATED_FILES':
      newFiles = []
      if (action.add) {
        newFiles = state.curatedFiles
      }
      return {
        ...state,
        curatedFiles: newFiles,
        failedFetchingFiles: false,
        fetchingFiles: true,
        nextFile: action.nextCursor || null,
        succeededFetchingFiles: false
      }
    case 'CURATED_FILES':
      newFiles = action.curatedFiles
      if (action.add) {
        newFiles = [...state.curatedFiles, ...action.curatedFiles]
      }
      return {
        ...state,
        curatedFiles: newFiles,
        fetchingFiles: false,
        nextFile: action.nextCursor || null,
        succeededFetchingFiles: true
      }
    case 'CURATED_FILES_APPEND':
      return {
        ...state,
        curatedFiles: [...state.curatedFiles, ...action.curatedFiles]
      }
    case 'CURATED_FILES_FAILED':
      return {
        ...state,
        failedFetchingFiles: true,
        fetchingFiles: false
      }
    case 'CURATED_FILE_UPDATE':
      return {
        ...state,
        curatedFiles: state.curatedFiles.map(file => action.file && action.file.id === file.id
          ? { ...file, ...action.file }
          : file
        )
      }
    case 'CURATED_FILE_DELETE':
      const curatedFiles = state.curatedFiles || []
      return {
        ...state,
        curatedFiles: curatedFiles.filter(file => file.id !== action.file)
      }
    case 'FINISHED_MOVIE_FILES':
      return {
        ...state,
        finishedMovieFiles: action.files
      }
    case 'FINISHED_MOVIE_FILES_PREPEND':
      return {
        ...state,
        finishedMovieFiles: [action.file, ...state.finishedMovieFiles]
      }
    case 'FINISHED_MOVIE_FILE_UPDATE':
      return {
        ...state,
        finishedMovieFiles: state.finishedMovieFiles.map(file => {
          return action.file && action.file.id === file.id
            ? { ...file, ...action.file }
            : file
        })
      }
    default:
      return state
  }
}

export default file
