import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import UrlifeTextField from '../UrlifeTextField'
import { updateFile, updateLocalFile } from '../../../actions'

const styles = {
  notesWrapper: {
    width: '100%'
  }
}

class NotesInput extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      changedProdNote: false,
      changedUserNote: false,
      description: props.noteFile ? props.noteFile.description || '' : '',
      localFile: props.noteFile,
      producerNote: props.noteFile ? props.noteFile.producerNote || '' : ''
    }
  }

  updateDescription (e) {
    this.setState({ changedUserNote: true, description: e.target.value })
  }

  async updateNotes () {
    const { noteFile, updateFileFunction, projectId, updateLocalFileFunction } = this.props
    const file = {
      description: this.state.description,
      id: noteFile.id
    }
    if (this.state.changedUserNote) {
      await updateFileFunction(file, projectId)
      updateLocalFileFunction(file)
      this.setState({ changedUserNote: false })
    }
  }

  updateLocalProdNote (e) {
    this.setState({ changedProdNote: true, producerNote: e.target.value })
  }

  async updateProducerNote () {
    const { noteFile, updateFileFunction, projectId, updateLocalFileFunction } = this.props
    const file = {
      id: noteFile.id,
      producerNote: this.state.producerNote
    }
    if (this.state.changedProdNote) {
      await updateFileFunction(file, projectId)
      updateLocalFileFunction(file)
      this.setState({ changedProdNote: false })
    }
  }

  static getDerivedStateFromProps (newProps, oldState) {
    if (newProps.noteFile && newProps.noteFile.id !== oldState.localFile.id) {
      oldState.localFile = newProps.noteFile
      oldState.description = newProps.noteFile.description || ''
      oldState.producerNote = newProps.noteFile.producerNote || ''
    } else if (!newProps.noteFile) {
      oldState.localFile = undefined
      oldState.description = ''
      oldState.producerNote = ''
    }
    return oldState
  }

  render () {
    const {
      classes,
      permissions,
      noteFile
    } = this.props

    return (
      <div className={classes.notesWrapper}>
        {permissions.canSeeNotes &&
        <UrlifeTextField
          onFocus={(e) => {
            e.target.value = ''
          }}
          onBlur={this.updateNotes.bind(this)}
          onChange={this.updateDescription.bind(this)}
          label={'Customer notes'}
          id="notes"
          value={noteFile ? this.state.description : ''}
          disabled={!permissions.canEditNotes}
        />
        }
        {permissions.canSeeProdNotes &&
        <UrlifeTextField
          onFocus={(e) => {
            e.target.value = ''
          }}
          onBlur={this.updateProducerNote.bind(this)}
          onChange={this.updateLocalProdNote.bind(this)}
          label={'Producer notes'}
          id="prod-notes"
          value={noteFile ? this.state.producerNote : ''}
          disabled={!permissions.canEditProdNotes}
        />
        }
      </div>
    )
  }
}
NotesInput.propTypes = {
  classes: PropTypes.object.isRequired,
  noteFile: PropTypes.object,
  permissions: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  updateFileFunction: PropTypes.func,
  updateLocalFileFunction: PropTypes.func
}

function mapDispatchToProps (dispatch) {
  return {
    updateFileFunction (file, projectId) {
      return dispatch(updateFile(file, projectId))
    },
    updateLocalFileFunction (file) {
      dispatch(updateLocalFile(file))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(NotesInput))
