import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, history, toLocale } from '../../../helpers'
import { fetchProjectCodeGroupings, getAllPackageSkus } from '../../../actions'
import Paper from '@material-ui/core/Paper'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeTextField from '../../common/UrlifeTextField'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  topContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addWrapper: {
    flexGrow: 1
  },
  button: {
    margin: '20px 0px'
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  searchField: {
    width: 300,
    marginRight: 20
  },
  groupingsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 5px 0',
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  groupingsContainer: {
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  groupingCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}
/* eslint-enable sort-keys */

class ProjectCodeGroupings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      nextCursor: null,
      projectCodeGroupings: [],
      search: ''
    }
  }

  fetchProjectCodeGroupings (add) {
    const { fetchProjectCodeGroupingsFunction } = this.props
    const { projectCodeGroupings, nextCursor, search } = this.state
    const params = { query: search }
    if (add) {
      params.cursor = nextCursor
    }
    this.setState({ isLoading: true })
    fetchProjectCodeGroupingsFunction(params)
      .then(
        data => {
          let newProjectCodeGroupings = data.data
          if (add) {
            newProjectCodeGroupings = [...projectCodeGroupings, ...newProjectCodeGroupings]
          }
          this.setState({
            isLoading: false,
            nextCursor: data.nextCursor || null,
            projectCodeGroupings: newProjectCodeGroupings
          })
        }
      )
  }

  componentDidMount () {
    this.props.updateSkusFunction(this.props.packages)
    this.fetchProjectCodeGroupings()
  }

  onChangeSearch (e) {
    const { search } = this.state
    if (search && !e.target.value) {
      this.setState({ search: e.target.value }, this.fetchProjectCodeGroupings.bind(this, false))
    } else {
      this.setState({ search: e.target.value })
    }
  }

  onSearch (e) {
    e.preventDefault()
    this.fetchProjectCodeGroupings(false)
  }

  render () {
    const { classes } = this.props
    const {
      projectCodeGroupings,
      nextCursor,
      isLoading,
      search
    } = this.state
    const dateOptions = {
      date: true,
      full: false,
      time: false
    }
    return (
      <div>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.topContainer}>
            <div className={classes.addWrapper}>
              <UrlifeButton
                className={classes.button}
                onClick={() => history.push('/admin/projectcodes/create')}
              >
                Add project code
              </UrlifeButton>
            </div>
            <form onSubmit={this.onSearch.bind(this)} className={classes.searchForm}>
              <UrlifeTextField
                className={classes.searchField}
                name="search"
                placeholder="Search"
                label="Search"
                onChange={this.onChangeSearch.bind(this)}
                value={search}
              />
              <UrlifeButton
                className={classes.button}
                type="submit"
                disabled={isLoading}
              >
                Search
              </UrlifeButton>
            </form>
          </div>
          <UrlifeInfiniteScroller
            loadMore={this.fetchProjectCodeGroupings.bind(this, true)}
            hasMore={!!nextCursor}
            isLoading={isLoading}
            isEmpty={!projectCodeGroupings.length}
            className={classes.groupingsTable}
          >
            <div className={classes.headerRow}>
              <div className={classes.headerCell}>
                Prefix
              </div>
              <div className={classes.headerCell}>
                Expires
              </div>
              <div className={classes.headerCell}>
                &nbsp;
              </div>
              <div className={classes.headerCell}>
                &nbsp;
              </div>
            </div>
            {projectCodeGroupings && projectCodeGroupings.map(group => {
              return (
                <div key={group.id} className={classes.groupingsContainer}>
                  <span className={classes.groupingCell}>
                    {group.prefix}
                  </span>
                  <span className={classes.groupingCell}>
                    {group.expiration ? toLocale(new Date(group.expiration), dateOptions) : 'N/A'}
                  </span>
                  <span className={classes.groupingCell}>
                    <UrlifeButton
                      className={classes.button}
                      onClick={() => history.push('/admin/projectcodes/' + group.id)}
                    >
                      Edit
                    </UrlifeButton>
                  </span>
                  <span className={classes.groupingCell}>
                    <UrlifeButton
                      className={classes.button}
                      onClick={() => history.push('/admin/projectcodes/' + group.id + '/generate')}
                    >
                      Generate codes
                    </UrlifeButton>
                  </span>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
        </Paper>
      </div>
    )
  }
}

ProjectCodeGroupings.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchProjectCodeGroupingsFunction: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  updateSkusFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchProjectCodeGroupingsFunction: function (params) {
      return dispatch(fetchProjectCodeGroupings(params))
    },
    updateSkusFunction: function (packages) {
      packages.forEach(pack => {
        dispatch(getAllPackageSkus(pack.id, 'all'))
      })
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectCodeGroupings))
