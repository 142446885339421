import ApiService from '../services/ApiService'
import { api } from '../constants'

export {
  listUsers,
  saveUserRole
}

function saveUserRole (userId, roleId) {
  return dispatch => {
    return new ApiService(api.USER_ROLE, { userId }, {}, true, dispatch)
      .put({ role_id: roleId })
      .then(
        res => res,
        fail => Promise.reject(fail)
      )
  }
}

function listUsers (params = {}) {
  return dispatch => {
    return new ApiService(api.USERS, {}, params, true, dispatch)
      .get()
      .then(
        users => users,
        fail => Promise.reject(fail)
      )
  }
}
