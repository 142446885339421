import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ValidateForm from '../common/form/ValidateForm'

import { setSkinId, toggleModal, verifyProjectCode } from '../../actions'
import { commonStyles, history } from '../../helpers'
import { parse } from 'query-string'

/* eslint-disable sort-keys */
const styles = () => ({
  header: {
    textAlign: 'left',
    padding: '40px 50px 0px 50px',
    ...commonStyles.media(480, {
      padding: '20px 20px 0px 20px'
    })
  },
  description: {
    textAlign: 'left',
    width: 220,
    color: '#666666',
    fontSize: 12,
    fontWeight: 500,
    margin: '5px 50px 0px 50px',
    ...commonStyles.media(480, {
      margin: '5px 20px 0px 20px'
    })
  },
  headerRoot: {
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    ...commonStyles.media(480, {
      fontSize: 16
    })
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 22px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  buttonHolder: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 50px',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  useButton: {
    margin: 0,
    marginBottom: 30,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  }
})
/* eslint-enable sort-keys */

export class UrlifeUseCodeDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      formStatus: '' // '', sending, success, fail
    }
  }

  componentDidMount () {
    const query = history.location.search
    const parsed = parse(query)
    const skinId = parsed.skinId ? parseInt(parsed.skinId) : null
    this.props.setSkinIdFunction(skinId)
  }

  useCode () {
    const { code } = this.state.fields
    const {
      verifyProjectCodeFunction,
      setCode,
      setSkinIdFunction,
      toggleModal
    } = this.props
    if (code) {
      this.setState({
        formStatus: 'sending'
      }, () => {
        // action
        verifyProjectCodeFunction(code)
          .then(data => {
            if (data.valid) {
              setCode(code)
              toggleModal(true, 'register')
            } else {
              this.setState({
                formStatus: data.message
              })
            }
            if (data.skinId) {
              setSkinIdFunction(data.skinId)
            }
          })
      })
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  render () {
    const { fields, formStatus } = this.state

    const {
      classes,
      title
    } = this.props

    return (
      <div>
        <DialogTitle
          id="form-dialog-title"
          className={classes.header}
          classes={{ root: classes.headerRoot }}
          disableTypography
        >
          {title}
        </DialogTitle>
        <p className={classes.description}>This is a one-time unique code supplied by one of our partners.</p>
        <ValidateForm
          onSubmit={this.useCode.bind(this)}
          onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
          validateOnMount={true}>
          {(form) => (
            <React.Fragment>
              <DialogContent classes={{ root: classes.content }}>
                {form.renderField(
                  'code',
                  ['required', 'text'],
                  (field, fieldData) => {
                    let validationStatus = 'none'
                    let errorMessage = ''
                    if (fields.code && fields.code.length > 0) {
                      if (formStatus && formStatus !== 'sending') {
                        validationStatus = 'error'
                        errorMessage = formStatus
                      } else {
                        validationStatus = 'success'
                      }
                    }
                    return (
                      <UrlifeTextField
                        autoFocus
                        label="Code"
                        margin="dense"
                        id="name"
                        name="code"
                        type="text"
                        fullWidth
                        onChange={e => field.onChange(e)}
                        onBlur={e => field.onBlur(e)}
                        defaultValue={fields.code || ''}
                        validationStatus={validationStatus}
                        infoLabel={errorMessage}
                      />
                    )
                  }
                )}
              </DialogContent>
              <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                <UrlifeButton
                  type="submit"
                  className={classes.useButton}
                  disabled={formStatus === 'sending' || !form.checkFormValid()}
                >
                  Use Code
                </UrlifeButton>
              </DialogActions>
            </React.Fragment>
          )}
        </ValidateForm>
      </div>
    )
  }
}

UrlifeUseCodeDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  loginSwitch: PropTypes.func,
  setCode: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  verifyProjectCodeFunction: PropTypes.func.isRequired
}

UrlifeUseCodeDialog.defaultProps = {
  showBackButton: true
}

function mapStateToProps (state) {
  return {
    loginFailed: state.user.loginFailed
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    },
    verifyProjectCodeFunction: function (code) {
      return dispatch(verifyProjectCode({ code }))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeUseCodeDialog))
