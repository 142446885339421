import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TopMenuMore } from '../common/SVG'
import { APP_STORE_LINK } from '../../constants'
import cloudIcon from '../../assets/img/cloudIcon.png'

const useStyles = makeStyles({
  moreIcon: {
    fill: 'none',
    marginBottom: -6,
    stroke: '#626262',
    width: 36
  }
})

const MoreIcon = () => {
  const classes = useStyles()
  return <TopMenuMore className={classes.moreIcon}/>
}

const CloudIcon = () => {
  return (
    <img
      src={cloudIcon}
      style={{ height: 25, width: 25 }}
      alt='cloud icon'
      align='center'
    />
  )
}

const videos = {
  cameraSettingsAndroid: 'https://static.urlifemedia.com/tutorials/camera-settings-android/master.m3u8',
  cameraSettingsAndroidV2: 'https://static.urlifemedia.com/silversea-tutorial/android/master.m3u8',
  cameraSettingsiOS: 'https://static.urlifemedia.com/tutorials/camera-settings-ios/master.m3u8',
  cameraSettingsiOSV2: 'https://static.urlifemedia.com/silversea-tutorial/ios/master.m3u8',
  downloadAppAndroid: 'https://static.urlifemedia.com/tutorials/download-app-android/master.m3u8',
  downloadAppiOS: 'https://static.urlifemedia.com/tutorials/download-app-ios/master.m3u8',
  downloadFinishedMovie: 'https://static.urlifemedia.com/share-download/video/master.m3u8'
}

const questions = (loggedIn, toggleModalFunc, openVideoFunc) => {
  return [
    {
      categories: 'Using URLIFE',
      questions: [
        {
          answer: <React.Fragment>
            <p>First things first - {!loggedIn &&
              <span onClick={toggleModalFunc}>
                sign up for your free account </span>}
            {loggedIn && 'sign up for your free account '}
            on our website!</p>
            <p>Once you verify your account by setting a password, you can start a new project and upload your
              photos/videos using the website or our
              <a target="_blank" rel="noopener noreferrer" href={APP_STORE_LINK}> iOS
          App</a>. Simply select the package that works best for you and follow the on-screen prompts. </p>
          </React.Fragment>,
          filters: ['general', 'notreDame'],
          title: 'How do I get started?'
        },
        {
          answer: <React.Fragment>
            <p>For an optimized upload experience:</p>
            <ul>
              <li>• Use a strong/fast Wi-Fi connection</li>
              <li>• Have a good battery charge and/or plug your device into a power source</li>
              <li>• Keep the app open/active</li>
              <ul>
                <li>• Note: If you accidentally close the app, or your iPhone times-out the app
                  (because of low battery/Wi-Fi), the upload will pause until conditions improve and/or you open the
                  App back up again. Then the upload will pick up where it left off!
                </li>
              </ul>
            </ul>
            <p>PERMISSION SETTINGS: If prompted to set permissions for the App, be sure to select “Allow All Photos”
              for the easiest upload process. Our App will <b><i>only</i></b> access the files you specifically select
              during your upload process, but this setting saves multiple steps by allowing access to all photos,
              versus a multi-step pre-selecting process. </p>
          </React.Fragment>,
          filters: ['general'],
          title: 'Uploading on iPhone/iOS App'
        },
        {
          answer: <React.Fragment>
            <p>Download the app and log in using your account credentials. Using the More (<MoreIcon/>) menu,
               enter your Promo Code and the complimentary movie will be automatically added to your account. A prompt
               will appear to start your movie now, or it will be saved to your account to start later from
               your home page. Follow the on-screen prompts to guide you through creating your project.
               For an optimized upload process:
            </p>
            <ul>
              <li>• Be sure you are using a fast wi-fi connection</li>
              <li>• Have a battery with at least 50% charge and/or plug your device into a power source</li>
              <li>• Keep the app open/active</li>
            </ul>
            <p>If wi-fi or battery are less than optimal your upload may pause and start again later when conditions
              are improved. Please note: trying to upload without a wi-fi connection may result in charges to your
              mobile account, depending on your data plan.
            </p>
          </React.Fragment>,
          filters: ['notreDame'],
          title: 'How do I create a project on the iOS App?'
        },
        {
          answer: <p>Our Android App is coming soon, but in the meantime, you can create a project on your phone
          using our fully mobile-optimized website!</p>,
          filters: ['notreDame'],
          title: 'How do I upload a project on an Android?'
        },
        {
          answer: <React.Fragment>
            <p>Uploading through the website is slightly different because it uses a web browser.
               For an optimized upload experience:</p>
            <ul>
              <li>• Use a strong/fast Wi-Fi connection</li>
              <li>• Keep your browser window open until all files are uploaded (Check on-screen status box)</li>
              <ul>
                <li>• If you accidentally close the window, lose Wi-Fi, or the screen goes to sleep – it will
                    interrupt the upload progress. If that happens – just select the files to upload again, and our
                    system will remove any duplicates that were previously successfully uploaded!
                </li>
              </ul>
              <li>• If you find the upload is going slowly, it is most likely because the process is limited by
                    your available browser memory. To solve for this, refresh the page, and try uploading in smaller
                    batches to ensure you have enough bandwidth to process them smoothly.
              </li>
              <li>• The website works on all browsers, however, users have told us Safari & Chrome work best</li>
            </ul>
            <p>
              CLOUD STORAGE UPLOAD: Most Android phones will let you upload directly from your Cloud platforms
              (like Google Photos). However, if you are uploading from a computer and your files are in a cloud storage
              platform - you first have to save a copy of the Cloud files to your computer. Create a temporary folder
              on your desktop and download the files from your Cloud. Then, when you start your project
              on <b>URLIFE</b> and you get to the upload prompt on our website, you will easily be able to select the
              folder of files from your desktop.
            </p>
          </React.Fragment>,
          filters: ['general'],
          title: 'Uploading to the website on Android/Laptop'
        },
        {
          answer: <React.Fragment>
            <p> Trying to upload without a Wi-Fi connection may result in charges to your mobile
                account, depending on your data plan.
            </p>
          </React.Fragment>,
          filters: ['general'],
          title: 'Can I upload without Wi-Fi?'
        },
        {
          answer: <React.Fragment>
            <p>Using the More (<MoreIcon/>) menu, enter your Promo Code and the complimentary movie will be
                automatically added to your account. A prompt will appear to start your movie now, or
                it will be saved to your account to start later from your home page. Follow the on-screen
                prompts to guide you through creating your project. Uploading through the website is slightly
                different than using the iOS App because it uses a web browser. These tips help improve performance!</p>
            <ul>
              <li>• Keep your browser window open until all files are uploaded (Check on-screen status box)</li>
              <li>• If you accidentally close the window, lose Wi-Fi, or the computer screen goes to sleep
                  – it will likely interrupt the upload progress. If that happens – just select the files to upload
                  again, and our system will remove any duplicates that were previously successfully uploaded!
              </li>
              <li>• Use a fast wi-fi connection for the best upload speed</li>
              <li>• Uploading requires loading all images/videos into your browser memory. So the more available memory
                    you have, the better the upload performance will be
              </li>
              <li>• The website works on all browsers, however, users have told us Safari & Chrome work best</li>
            </ul>
          </React.Fragment>,
          filters: ['notreDame'],
          title: 'How do I upload a project on the website?'
        },
        {
          answer: <React.Fragment>
          <p> Absolutely! If your content is in multiple places, you can start your project on the iOS app and save
              it, and then sign into the website (or another phone!) to continue uploading additional content. If
              uploading from your computer, make sure to save the content to your computer first - before trying to
              upload to the website. (More on this above - in the question about uploading on the website.)
          </p>
          </React.Fragment>,
          filters: ['general', 'notreDame'],
          title: 'Can I use both the iOS app and website for the same project?'
        }
      ]
    },
    {
      categories: 'Choosing what’s best for your project',
      questions: [
        {
          answer: <React.Fragment>
            <p>The <i>Hollywood Movie</i> offers hands-on, personal project management and concierge services with a
              Senior Producer. Whether you decide to communicate by call or in-app, your Producer will work closely
              with you to learn the highlights of your story and the personal style preferences you’d like for your
              finished movie. It also includes additional high-level editing not included with the <i>Recap</i> –
              things like visual effects, narration (upon request), use of HD stock and drone footage of your
              location, and a round of edits.</p>
            <p>For <i>Hollywood Custom Projects</i>, a number of factors determine pricing. Once you have had your
              Producer Call, you will be provided with a price estimate.</p>
            <p>With the <i>Recap Movie</i>, your details are provided digitally only (in the App) - using a guided
              questionnaire. You can still message with your Producer, but collaboration is kept online.</p>
          </React.Fragment>,
          filters: ['general'],
          id: 'RecapVsHollywood',
          title: 'What is the difference between the Recap and Hollywood Movies?'
        }
      ]
    },
    {
      categories: 'Tips for making a great project',
      questions: [
        {
          answer: <React.Fragment>
            <p>Our team has provided some suggestions/limits as a guide:</p>
            <p>HOLLYWOOD MOVIES</p>
            <ul>
              <li>• For projects 60-90 seconds long: we suggest a minimum of 40-50 files, no limits</li>
              <li>• For projects 2-2.5 minutes long: we suggest a minimum of 125 files, no limits</li>
              <li>• For projects with custom lengths (more than 2.5 minutes) - unlimited</li>
            </ul>
            <p>RECAP MOVIES</p>
            <ul>
              <li>• For projects 60-90 seconds long: minimum of 40-50 files, maximum of 100 files</li>
              <li>• For projects 2-2.5 minutes long: minimum of 125 files, maximum 250 files</li>
            </ul>
          </React.Fragment>,
          filters: ['general'],
          title: 'How many photos and videos should I upload for my Movie?  Is there a limit?'
        },
        {
          answer: <p>For photos we recommend 12 MBS size (or 1280x720 or higher).
            For videos we recommend 1080p or higher (but 4K is best!). To check or change your camera settings,
            view this helpful tutorial for your type of phone:
            <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsiOSV2) }}> iPhone</span>
            &nbsp;|&nbsp;
            <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsAndroidV2) }}>Android</span>
          </p>,
          filters: ['general', 'notreDame'],
          title: 'Is there a minimum resolution recommended?'
        },
        {
          answer: <p>We can accept/work with all types of digital file formats!
          (Including audio files, or old photos and home movies that have been digitized.)
          If using content from your smartphone, you can easily upload using our mobile app.
          For other types of files - like GoPro’s or digital cameras or other digitized files,
          you can easily upload that content from your computer, using our website. </p>,
          filters: ['general', 'notreDame'],
          title: 'What type of files can I submit?'
        },
        {
          answer: <p>Yes! During upload or on the summary screen you can “star” your favorites to let us know
          which photos/videos are must-include content. You can also add notes on individual files to give more
          detail about a particular moment you captured. And even if a file is not the best quality - if it is
          something important to telling your story, tell us and we’ll make it work!</p>,
          filters: ['general', 'notreDame'],
          title: 'Can I choose my favorites?'
        }
      ]
    },
    {
      categories: 'Collaborating & sharing with friends and family',
      questions: [
        {
          answer: <p>We will have a “Contributor” feature (coming soon!),
          that will allow you to invite friends and family to add their photos and videos to your project.
          However, if you have content from friends and family that you’d like to get to us before the
          Contributor feature is available,
          please email us at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a> and
          we can help link projects so that all the relevant content is combined!</p>,
          filters: ['general'],
          title: 'Can my friend/family member add their content to my project?'
        },
        {
          answer: <p>We will have a “Contributor” feature (coming soon!),
          that will allow you to invite friends and family to add their photos and videos to your project.
          However, if you have content from friends and family that you’d like to get to us before the
          Contributor feature is available,
          please email us at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a> and
          we can provide options so that all the relevant content is combined!</p>,
          filters: ['notreDame'],
          title: 'Can my friend/family member add their content to my project?'
        },
        {
          answer: <p>All of your finished projects (Recaps and Hollywood Movies)
          will be conveniently located and stored in one place - the My Gallery section of the URLIFE app.
          From there you can easily save to your devices and share them to your social networks!
          <span
            onClick={() => { openVideoFunc && openVideoFunc(videos.downloadFinishedMovie) }}
          > Watch how easy it is.</span>
          </p>,
          filters: ['general', 'test'],
          title: 'How do I receive my finished projects?'
        },
        {
          answer: <p>All of your finished projects will be conveniently located and stored in one place
          - the My Gallery section of the URLIFE app. From there you can easily download/save to your
          devices and share them to your social networks!</p>,
          filters: ['notreDame'],
          title: 'How do I receive my finished projects?'
        }
      ]
    },
    {
      categories: 'Get Started: Project codes and account set-up',
      questions: [
        {
          answer: <p>Once you disembark, you will receive an email with a custom Silversea
            project code and instructions on how to get your project started. You will start
            by entering the project code from your email, which will automatically add the
            complimentary movie credit to your account.</p>,
          filters: ['silversea'],
          title: 'What is a project code? Where do I find mine?'
        }
      ]
    },
    {
      categories: 'GET STARTED: Purchasing and project codes',
      questions: [
        {
          answer: <p>When visiting the Photo Gallery, simply purchase at the kiosk, like you
            would any of the other Photo Package offerings. You will be directed to the Photo
            staff to receive your custom project code and instructions.
            </p>,
          filters: ['celebrity'],
          title: 'How do I purchase?'
        },
        {
          answer: <p>The project code is a unique code that when entered into the <b>urlife Media</b> website
            will automatically add your purchased movie credit to your account. When you complete your purchase
            onboard, you will receive your custom project code from the Photo staff.
            </p>,
          filters: ['celebrity'],
          title: 'What is a project code? Where do I find mine?'
        },
        {
          answer: <p>The card you receive from the Photo staff will also feature a QR code (and url)
            that will direct you to a website with instructions on how to get started!
            </p>,
          filters: ['celebrity'],
          title: 'How do I get started?'
        }
      ]
    },
    {
      categories: 'Uploading Tips',
      questions: [
        {
          answer: <React.Fragment>
            <p>For an optimized upload experience:</p>
            <ul>
              <li>• Use a strong/fast Wi-Fi connection</li>
              <li>• Keep your browser window open until all files are uploaded (Check on-screen status box)</li>
              <li>• If you accidentally close the window, lose Wi-Fi, or the screen goes to sleep – it will
                likely interrupt the upload progress. If that happens – just select the files to upload again,
                and our system will remove any duplicates that were previously successfully uploaded!</li>
              <li>• If you find the upload is going slowly, it is most likely because the process is
                limited by your browser memory. To solve for this, refresh the page, and try uploading
                in smaller batches to ensure you have enough bandwidth to process them smoothly.</li>
              <li>• The website works on all browsers, however, users have told us Safari & Chrome work best</li>
              <li>• If you are uploading from a computer and your files are in a cloud storage platform
                (like iCloud, Google Photos, etc.) - first, create a folder on your desktop and <b>save</b> a copy of
                the files to the desktop folder. Then, when you start your project, and get to the upload prompt
                on our website, you will easily be able to select the folder of files from your desktop.</li>
            </ul>
          </React.Fragment>,
          filters: ['silversea'],
          title: 'What works best for Android or website upload:'
        },
        {
          answer: <React.Fragment>
            <p>For an optimized upload experience:</p>
            <ul>
              <li>• Use a strong/fast Wi-Fi connection</li>
              <li>• Have a good battery charge</li>
              <li>• Keep the app open/active (running in the background is fine too!)</li>
            </ul>
            <p>If your iPhone times-out the app (because of low battery/Wi-Fi), the upload will pause until
              conditions improve and/or you open the App back up again. Then the upload will pick up where it
              left off!</p>
          </React.Fragment>,
          filters: ['silversea'],
          title: 'What works best for an iPhone upload?'
        },
        {
          answer: <p>Trying to upload without a Wi-Fi connection may result in charges to your mobile account,
            depending on your data plan.</p>,
          filters: ['silversea'],
          title: 'Can I upload without Wi-Fi?'
        },
        {
          answer: <React.Fragment>
            <p>Absolutely! If your content is in multiple places, you can start your project on the iPhone
              app and save it, and then sign into the website (or another phone!) to continue
              uploading additional content.</p>
          </React.Fragment>,
          filters: ['silversea'],
          title: 'Can I use both the iOS app and website for the same project?'
        }
      ]
    },
    {
      categories: 'Tips for creating your project',
      questions: [
        {
          answer: <React.Fragment>
              <p>The iPhone app and website both have easy-to-follow on-screen prompts that guide you through the
                process. But it’s as easy as this:</p>
              <ul>
                <li>• Name your Project (ex. Silversea Antarctica 2022)</li>
                <li>• Upload photos and videos from your trip</li>
                <li>• Schedule a call to connect with your Producer to talk about your project
                  <ul>
                    <li>- Share highlights and special moments, preferences for what music you
                      might like to include, etc.</li>
                    <li>- You can also favorite (☆) and add notes to individual files before you
                      submit if you’d like, or you can just save all that for your Producer call!</li>
                  </ul>
                </li>
                <li>• That’s it - sit back and relax - we’ll do the rest!</li>
              </ul>
            </React.Fragment>,
          filters: ['silversea'],
          title: 'How do I start my project? '
        },
        {
          answer: <p>
            For a 2.5 minute Hollywood Movie, our team suggests a minimum of 125 files, but there are no
            limits on how many you can submit!</p>,
          filters: ['silversea'],
          title: 'How many photos and videos should I upload for my project? Is there a limit?'
        },
        {
          answer: <p>
            We can accept/work with all types of digital file formats! Including photos,
            videos and audio files from your smartphone, or other digital files - like GoPro’s, or
            photos from digital cameras.</p>,
          filters: ['silversea'],
          title: 'What type of files can I submit?'
        },
        {
          answer: <React.Fragment>
              <p>
                You will begin by entering your custom project code either on the
                website (from the QR code), or on the iPhone App. Once your code is entered,
                it will prompt you to create an account. Once you finish signing up – by
                confirming a password – you can then continue on whatever device you would
                like to upload content from - either the website from an Android
                phone/computer, or
                the <a target="_blank" rel="noopener noreferrer" href={APP_STORE_LINK}>iPhone App</a>.
              </p>
              <p>
                Both the iPhone app and website have easy-to-follow on-screen prompts that
                guide you through the process. But it’s as easy as this:
              </p>
              <ul>
                <li>• Name your Project (ex. Our Celebrity Cruise 2022)</li>
                <li>• Upload photos and videos from your trip</li>
                <li>• Follow the onscreen prompts to add notes (highlights, special moments, music and style
                  preferences). The more information you provide, the better we can make it feel personal to your
                  experience.
                  <ul>
                    <li>- You can also favorite (☆) and add notes to individual files on the summary page before you
                      submit - so we make sure we don’t miss any of your must-have content.</li>
                  </ul>
                </li>
                <li>• That’s it - sit back and relax - we’ll do the rest!</li>
              </ul>
              <p><i>SITUATION: Still not sure how it works?</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Watch the App Tutorial on the website to see how easy it is!</li>
              </ul>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'How do I start my project?'
        },
        {
          answer: <React.Fragment>
              <p>For a 60-90 second video, upload 60-150 files</p>
              <p>For a 2.5 minute video, upload 125-300 files</p>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'How many photos and videos should I upload for my project?'
        },
        {
          answer: <React.Fragment>
              <p>For videos we recommend a minimum resolution of 1080p - but the higher the better. Regular photo
                settings are usually set to default to a 3:4 ratio - and it is unnecessary to change them - unless
                you want a wider shot - then try a 9:16!</p>
              <p><i>SITUATION: Not sure what resolution you are filming in</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Go to camera settings on your phone and look for video resolution options. Watch our Video
                  Settings Tutorial:{' '}
                  <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsiOS) }}>iPhone</span>
                  &nbsp;|&nbsp;
                  <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsAndroid) }}>Android</span>
                </li>
              </ul>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'Is there a minimum resolution recommended?'
        },
        {
          answer: <React.Fragment>
              <p>We can accept/work with all types of digital file formats! If using content from your smartphone,
                you can easily upload using the iPhone app or website.</p>
              <p>For other types of files - like GoPro’s, digital cameras or other digitized files (like audio files),
                you can easily upload that content from your computer, using the website.</p>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'What type of files can I submit?'
        }
      ]
    },
    {
      categories: 'Uploading tips',
      questions: [
        {
          answer: <React.Fragment>
              <p>For an optimized upload experience:</p>
              <ul>
                <li>• Use a strong/fast Wi-Fi connection</li>
                <li>• Have a good battery charge</li>
                <li>• Keep the app open/active</li>
                <li>• Make sure to turn on notifications</li>
              </ul>
              <p><i>SITUATION: Your upload has paused</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Check your battery and Wi-Fi signal. When Wi-Fi or battery conditions are not optimal,
                  your iPhone may close the app.</li>
                <li>➔ The upload should restart again when conditions improve, or you open the App back up again
                  and the upload will pick up where it left off!</li>
              </ul>
            </React.Fragment>,
          filters: ['celebrity'],
          id: 'UploadingTips',
          title: 'What works best for an iPhone upload?'
        },
        {
          answer: <React.Fragment>
              <p>For an optimized upload experience:</p>
              <ul>
                <li>• Use a strong/fast Wi-Fi connection</li>
                <li>• Be sure to keep your browser window open until all files are uploaded.
                  (Check on-screen status box)</li>
                <li>• The website works on all browsers, however, users have told us Safari & Chrome work best.</li>
              </ul>
              <p><i>SITUATION: Your upload has paused</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Check to see if you accidentally closed the browser window, lost Wi-Fi, or your screen went to
                  sleep. If so, it will likely interrupt the upload progress.</li>
                <li>➔ If that happens – just select the files to upload again, and our system will remove any
                  duplicates that were previously successfully uploaded!</li>
              </ul>
              <p><i>SITUATION: Your upload is slow</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ If the upload is moving slowly, it is most likely because the process is affected/limited by
                  your browser memory.</li>
                <li>➔ Refresh the page, and try uploading in smaller batches to ensure you have enough bandwidth to
                  process them smoothly.</li>
              </ul>
              <p><i>SITUATION: Can’t find files to upload on computer</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Make sure the content is saved directly to your computer
                  - and not just somewhere in the cloud!</li>
                <li>➔ Create a temporary folder on your Desktop or in your Photos folder and download the content
                  from the cloud to that folder first, and then you can upload that content to our App.</li>
              </ul>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'What works best for website or Android upload on website:'
        },
        {
          answer: <React.Fragment>
              <p>Yes - if on an iPhone. However, you will need to switch the App into offline mode by clicking on the
                cloud icon <CloudIcon /> in the upper right hand corner. This will allow you to start uploading your
                files while onboard and will keep them in the queue until you disembark. Once you disembark and have
                connected with your home Wi-Fi, switch back to online mode and the files will finish processing to our
                server.</p>
              <p>The Android/mobile optimized website does not have an offline mode. For the best user experience, we
                recommend you wait to upload your files until you disembark.</p>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'Can I start to upload while onboard?'
        },
        {
          answer: <p>Trying to upload without a Wi-Fi connection may result in charges to your mobile account,
            depending on your data plan. If you do not have Wi-Fi and want to start uploading, switch to offline
            mode described above.</p>,
          filters: ['celebrity'],
          title: 'Can I upload without Wi-Fi?'
        },
        {
          answer: <p>Absolutely! If your content is in multiple places, you can start your project on the iOS app
            and save it, and then sign into the website (or another phone!) to continue uploading additional
            content.</p>,
          filters: ['celebrity'],
          title: 'Can I use both the iOS app and website for the same project?'
        }
      ]
    },
    {
      categories: 'Expected time to complete my movie',
      questions: [
        {
          answer: <p>
            Creating a project is a quick and easy process!  Upload time will vary depending on
            how many files you select and how strong your Wi-Fi signal is.</p>,
          filters: ['silversea'],
          title: 'How long does it take to create a project?'
        },
        {
          answer: <p>
            Once you have your Producer call, you should see a first look in 7-10 business days.
            You will have a chance to request minor revisions, which will take 2-3 business days.</p>,
          filters: ['silversea'],
          title: 'How long does it take to receive my movie?'
        },
        {
          answer: <>
            <p>
              When the first look of your movie is ready, your video will be delivered via the
              app/website for your review. You will have the opportunity to provide comments/revisions.
              Once the final movie is approved, you can easily save it to your devices and share to
              your social networks!
            </p>
            <p>
              When the first look of your movie is ready, your video will be delivered in the app for your
              review. You will have the opportunity to provide comments/revisions. Once the final movie is approved,
              it will be stored in the URLIFE app in your personal Gallery. From there you can easily save it to
              your devices and share to your social networks!  See how easy it is in this
              <span
                onClick={() => { openVideoFunc && openVideoFunc(videos.downloadFinishedMovie) }}
              > how-to</span> video!
            </p>
          </>,
          filters: ['silversea'],
          title: 'How do I receive my movie?'
        },
        {
          answer: <p>
            Once you have submitted your project, you should see a first look in 7-9 business days. You will have a
            chance to request minor revisions, which will take 2 business days.</p>,
          filters: ['celebrity'],
          title: 'How long does it take to receive my movie?'
        },
        {
          answer: <React.Fragment>
              <p>When the first look of your movie is ready, your video will be delivered in the app for your review.
                You will have the opportunity to provide comments/revisions. Once the final movie is approved, it will
                be stored in the My Gallery (Complete) section of the URLIFE app. From there you can easily download
                and save to your devices, or share on your social networks!</p>
              <p><i>SITUATION: Need more help?</i></p>
              <p><i>SOLUTION:</i></p>
              <ul>
                <li>➔ Watch our Downloading Tutorial:{' '}
                  <span onClick={() => { openVideoFunc && openVideoFunc(videos.downloadAppiOS) }}>iPhone</span>
                  &nbsp;|&nbsp;
                  <span onClick={() => { openVideoFunc && openVideoFunc(videos.downloadAppAndroid) }}>Android</span>
                </li>
              </ul>
            </React.Fragment>,
          filters: ['celebrity'],
          title: 'How do I receive my movie?'
        }
      ]
    },
    {
      categories: 'We take your content and privacy seriously',
      questions: [
        {
          answer: <p>
          The only information we collect is your account information (user name & email address).
          Your purchase is handled by a secure payment processor, we do not collect or store that information.
          This is compliant with the strictest requirements established by the GDPR.</p>,
          filters: ['general', 'notreDame'],
          title: 'What information do you collect?'
        },
        {
          answer: <p>
          The only information we collect is your account information (user name & email address).
          URLIFE Media is compliant with the strictest requirements established by the GDPR.</p>,
          filters: ['silversea'],
          title: 'What information do you collect?'
        },
        {
          answer: <p>
          We follow best practices to ensure the transfer of your content (between
          your device and our servers) use secure, encrypted connections.
          </p>,
          filters: ['general', 'notreDame', 'silversea'],
          title: 'How do you protect my content during the upload process?'
        },
        {
          answer: <p>Your photos, videos, curated galleries and final movie are owned by YOU.
          Your digital content is 100% private and confidential,
          unless you choose to share it.</p>,
          filters: ['general', 'notreDame'],
          title: 'Who owns the final curated files and movies?'
        },
        {
          answer: <p>Your photos, videos and final movie are owned by YOU.
            Sharing your digital content is 100% at your discretion. However,
            Silversea may contact you if they’d like to showcase it on one of their channels!</p>,
          filters: ['silversea'],
          title: 'Who owns the final curated files and movies?'
        },
        {
          answer: <p>Yes this is the first test question.</p>,
          filters: ['test'],
          title: 'Is this the first test question?'
        }
      ]
    },
    {
      categories: 'URLIFE Media privacy standards',
      questions: [
        {
          answer: <p>URLIFE Media is compliant with the strictest privacy requirements established by the GDPR.
            We follow best practices to ensure the transfer of your content (between your device and our servers)
            use secure, encrypted connections.</p>,
          filters: ['celebrity'],
          title: 'How do you protect my information and content?'
        },
        {
          answer: <p>Your photos, videos and final movie are owned by YOU. Sharing your digital content is 100% at
            your discretion.</p>,
          filters: ['celebrity'],
          title: 'Who owns the final curated files and movies?'
        }
      ]
    },
    {
      categories: 'Taking photos and video on your trip',
      questions: [
        {
          answer: <p>No - you can take photos and videos just like you normally would! But if you’d like to try some
            fun and easy new ways to capture your trip - be sure to watch the how-to video above.  Plus, as a bonus,
            download a copy of the Shot List (with more helpful tips) based on your itinerary! We can also use any of
            the photos and videos you receive from the onboard Silversea photographer!</p>,
          filters: ['silverseaPre'],
          title: 'Should I take photos and videos differently if I am making a URLIFE movie?'
        },
        {
          answer: <p>For videos we recommend 1080 or higher(4K is best!), for photos 12 MBS size
            (or 1280x720 or higher). To check/change the resolution view the tutorial for your type of
            phone:
            <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsiOSV2) }}> iPhone</span>
            &nbsp;|&nbsp;
            <span onClick={() => { openVideoFunc && openVideoFunc(videos.cameraSettingsAndroidV2) }}>Android</span>
          </p>,
          filters: ['silverseaPre'],
          title: 'Is there a suggested minimum resolution you recommend when I take photos and videos on my trip?'
        },
        {
          answer: <p>We can accept/work with all types of digital file formats! Including photos, videos and audio files
             from your smartphone, or other digital files - like GoPro’s, or photos from digital cameras.</p>,
          filters: ['silverseaPre'],
          title: 'What type of files can I submit for making my movie?'
        }
      ]
    },
    {
      categories: 'Making a movie from your photos and videos',
      questions: [
        {
          answer: <p>When you get back from your trip, you will receive an email with instructions on how to upload your
            photos and video to our easy-to-use, private and secure app/website. Then the process is as follows:
            <ul>
                <li>• Producer Call: A Producer will be assigned to your project, review the photos and videos you have
                  uploaded, and you will have a quick chat with them to tell them about all the amazing highlights from
                  your trip!  They will also ask some questions about style and music preferences.</li>
                <li>• Then our editors work their magic - and replicate the movie-making process - by adding a
                   storyline, graphics, music, soundbites from your video clips.  The final piece will take you
                   right back to Antarctica - and you’ll be able to share easily with friends and family - or just
                   watch it and relive the experience over and over again yourselves!</li>
            </ul>
          </p>,
          filters: ['silverseaPre'],
          title: 'How do you make a movie using my photos and videos?'
        }
      ]
    },
    {
      categories: 'Expected time to complete your movie',
      questions: [
        {
          answer: <p>Creating a project is a quick and easy process!  Upload time will vary depending on how many
            files you select and how strong your Wi-Fi signal is.</p>,
          filters: ['silverseaPre'],
          title: 'How long does it take to create a project?'
        },
        {
          answer: <p>Once you have your Producer call, you should see a first look in 7-10 business days.
            You will have a chance to request minor revisions, which will take 2-3 business days.</p>,
          filters: ['silverseaPre'],
          title: 'How long does it take to receive my movie?'
        },
        {
          answer: <p>When the first look of your movie is ready, your video will be delivered via
            the app/website for your review. You will have the opportunity to provide comments/revisions.
            Once the final movie is approved, you can easily save it to your devices and share to your social networks!
          </p>,
          filters: ['silverseaPre'],
          title: 'How do I receive my movie?'
        }
      ]
    },
    {
      categories: 'The test question category',
      questions: [
        {
          answer: <p>Yes this is the second test question.</p>,
          filters: ['test'],
          title: 'Is this the second test question?'
        },
        {
          answer: <p>Yes this is the third test question.</p>,
          filters: ['test'],
          title: 'Is this the third test question?'
        }
      ]
    }
  ]
}

const getQuestions = (type = 'general', loggedIn, toggleModalFunc, openVideoFunc) => {
  return questions(loggedIn, toggleModalFunc, openVideoFunc).map(category => ({
    categories: category.categories,
    questions: category.questions.filter(question => question.filters.includes(type))
  })).filter(category => category.questions.length > 0)
}

export default getQuestions
