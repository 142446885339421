import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  column: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&._double': {
      width: 'calc(50% - 15px)',
      '&._left': {
        marginRight: 15
      },
      '&._right': {
        marginLeft: 15
      },
      ...commonStyles.media(767, {
        width: '100%',
        '&._left': {
          marginRight: 0
        },
        '&._right': {
          marginLeft: 0
        }
      })
    }
  }
}
/* eslint-enable sort-keys */

class ProjectDetailsLayoutContainer extends React.Component {
  render () {
    const { classes, children, columnLayout } = this.props
    let childArray
    if (Array.isArray(children)) {
      childArray = []
      children.forEach(child => {
        if (Array.isArray(child)) {
          childArray = [...childArray, ...child]
        } else {
          childArray.push(child)
        }
      })
    } else {
      childArray = React.Children.toArray(children)
    }
    const splitIndex = Math.floor((childArray.length + 1) / 2)
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          {columnLayout === 'single' &&
          <div className={classes.column}>
            {children}
          </div>
          }
          {columnLayout === 'double' &&
          <React.Fragment>
            <div className={classNames(classes.column, '_double', '_left')}>
              {childArray.map((child, index) => {
                return index < splitIndex ? child : null
              })}
            </div>
            <div className={classNames(classes.column, '_double', '_right')}>
              {childArray.map((child, index) => {
                return index >= splitIndex ? child : null
              })}
            </div>
          </React.Fragment>
          }
        </div>
      </div>
    )
  }
}

ProjectDetailsLayoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element)
    ]))
  ]),
  classes: PropTypes.object.isRequired,
  columnLayout: PropTypes.oneOf(['single', 'double']).isRequired
}

ProjectDetailsLayoutContainer.defaultProps = {
  columnLayout: 'single'
}

export default withStyles(styles)(ProjectDetailsLayoutContainer)
