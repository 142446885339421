import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

/* eslint-disable sort-keys */
const styles = {
  root: {
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 700,
    border: 'solid 1px #01b7d7',
    cursor: 'pointer',
    textTransform: 'none'
  },
  normal: {
    backgroundColor: '#01b7d7',
    color: '#ffffff',
    '&:hover': {
      color: '#01b7d7',
      backgroundColor: 'transparent'
    }
  },
  inverted: {
    backgroundColor: 'transparent',
    color: '#01b7d7',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#01b7d7'
    }
  }
}
/* eslint-enable sort-keys */

class UrlifeButton extends React.Component {
  render () {
    const { classes, className, children, inverted, ...otherProps } = this.props
    return (
      <Button
        className={`
          ${classes.root} ${inverted ? classes.inverted : classes.normal}
          ${this.props.className}`}
        {...otherProps}>
        {children}
      </Button>
    )
  }
}

UrlifeButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  inverted: PropTypes.bool
}

export default withStyles(styles)(UrlifeButton)
