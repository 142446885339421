import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

export function MenuIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 17">
      <path fill={props.fill} stroke="#fff" fillRule="evenodd" d="M0 17v-1.044h22V17H0zM0-.001h22v1.043H0V-.001zm16.772 9.038H0V7.962h16.772v1.075z"/>
    </SvgIcon>
  )
}

MenuIcon.propTypes = {
  fill: PropTypes.string
}
