import ApiService from '../services/ApiService'
import { api } from './../constants'
export {
  createMessage,
  deleteMessage,
  getMessages
}

function createMessage (projectId, thread, message) {
  return dispatch => {
    return new ApiService(api.MESSAGES, { projectId, thread }, {}, true, dispatch)
      .post({ message })
  }
}

function deleteMessage (projectId, thread, messageId) {
  return dispatch => {
    return new ApiService(api.ONE_MESSAGE, { messageId, projectId, thread }, {}, true, dispatch)
      .delete()
  }
}

function getMessages (projectId, thread, params) {
  return dispatch => {
    return new ApiService(api.MESSAGES, { projectId, thread }, params, true, dispatch)
      .get()
  }
}
