import ApiService from '../services/ApiService'
import { abortUpload, uploadFile } from './index'
import { api, FILE_STATUSES } from './../constants'
import { cacheSignedUrl } from '../services/CacheSignedUrls'

export {
  createCuratedFiles,
  updateCuratedFile,
  fetchCuratedFiles,
  fetchAllCuratedFiles,
  getCuratedFile,
  deleteCuratedFile
}

const timeOutUpdateIds = {}

function createCuratedFiles (files, projectId, projectSkuId, fileObjects) {
  return dispatch => {
    return new ApiService(api.CURATED_FILES_SKU, { projectId, projectSkuId }, {}, true, dispatch)
      .post(files)
      .then(
        data => {
          const uploads = data.data.map((file, index) => {
            return { ...file, fileObject: fileObjects[index].object }
          })
          const updateCuratedFileSuccess = (fileId, IdentityId) => {
            dispatch(updateCuratedFile(
              { id: fileId, status: FILE_STATUSES.CLIENT_UPLOAD_COMPLETE },
              projectId,
              projectSkuId,
              IdentityId)
            ).then(
              data => {
                dispatch({
                  file: { ...data },
                  type: 'CURATED_FILE_UPDATE'
                })
              }
            ).catch(e => {
              // try to update status every 5 sec
              timeOutUpdateIds[fileId] = setTimeout(
                () => {
                  updateCuratedFileSuccess(fileId, IdentityId)
                },
                5000
              )
            })
          }
          const uploadCuratedFile = file => {
            dispatch(uploadFile(
              null,
              file.fileObject,
              file.id,
              file.fileExt,
              projectId,
              'curated',
              'curated/' + file.id + '.' + file.fileExt,
              (cognitoInfo) => updateCuratedFileSuccess(file.id, cognitoInfo.IdentityId),
              () => {
                dispatch(updateCuratedFile(
                  { id: file.id, status: FILE_STATUSES.UPLOAD_FAILED },
                  projectId,
                  projectSkuId,
                  ''
                ))
              }
            )).catch(e => {
              // Catches any error in the uploadFile function.
              // This should never occur.
            })
          }

          uploads.forEach(file => uploadCuratedFile(file))
          const addedFiles = data.data.map((file, index) => {
            return {
              id: file.id,
              ...files.files[index],
              src: window.URL.createObjectURL(fileObjects[index].object),
              status: 1
            }
          })
          dispatch({ curatedFiles: addedFiles, type: 'CURATED_FILES_APPEND' })
          return uploads
        }
      )
  }
}

function updateCuratedFile ({ id, ...body }, projectId, projectSkuId, identityId = null) {
  return dispatch => {
    return new ApiService(
      api.ONE_CURATED_FILE_SKU,
      { fileId: id, projectId, projectSkuId },
      { identityId },
      true,
      dispatch
    ).put(body)
  }
}

function fetchCuratedFiles (projectId, projectSkuId, params = {}) {
  return dispatch => {
    dispatch({
      add: (params && params.add) || null,
      nextCursor: (params && params.cursor) || null,
      type: 'FETCHING_CURATED_FILES'
    })
    const service = projectSkuId ? api.CURATED_FILES_SKU : api.CURATED_FILES
    const vars = projectSkuId ? { projectId, projectSkuId } : { projectId }
    return new ApiService(service, vars, params, true, dispatch)
      .get()
      .then(
        files => {
          return dispatch({
            add: (params && params.add) || null,
            curatedFiles: files.data,
            nextCursor: files.nextCursor,
            type: 'CURATED_FILES'
          })
        },
        fail => {
          return dispatch({ type: 'CURATED_FILES_FAILED' })
        }
      )
  }
}

function fetchAllCuratedFiles (projectId, projectSkuId, params = {}) {
  return dispatch => {
    dispatch({
      add: null,
      nextCursor: params.cursor || null,
      type: 'FETCHING_CURATED_FILES'
    })
    const fetchParams = {
      count: params.count || 100,
      method: params.method || 'ASC',
      sort: params.sort || 'created_at'
    }
    if (params.cursor) fetchParams.cursor = params.cursor
    const service = projectSkuId ? api.CURATED_FILES_SKU : api.CURATED_FILES
    const vars = projectSkuId ? { projectId, projectSkuId } : { projectId }
    return new ApiService(service, vars, fetchParams, true, dispatch)
      .get()
      .then(
        files => {
          params.curatedFiles = [].concat(params.curatedFiles || [], files.data)
          if (files.nextCursor) {
            params.cursor = files.nextCursor
            return dispatch(fetchAllCuratedFiles(projectId, projectSkuId, params))
          } else {
            // cache signed urls
            const fileLinks = params.curatedFiles.reduce((arr, file) => {
              arr.push(file.signedUrl)
              arr.push(file.signedThumbnailUrl)
              return arr
            }, [])
            cacheSignedUrl.add(fileLinks)

            return dispatch({
              add: null,
              curatedFiles: params.curatedFiles,
              nextCursor: files.nextCursor,
              type: 'CURATED_FILES'
            })
          }
        },
        fail => {
          return dispatch({ type: 'CURATED_FILES_FAILED' })
        }
      )
  }
}

function getCuratedFile (projectId, projectSkuId, fileId) {
  return dispatch => {
    return new ApiService(api.ONE_CURATED_FILE_SKU,
      { fileId, projectId, projectSkuId }, {}, true, dispatch)
      .get()
  }
}

function deleteCuratedFile (projectId, projectSkuId, fileId) {
  return (dispatch, getStore) => {
    const upload = Object.values(getStore().fileProgress).filter(file => {
      return file.id === fileId && file.progressType === 'upload'
    })
    if (Object.values(getStore().fileProgress) && upload.length > 0) {
      dispatch(abortUpload(fileId))
    }
    dispatch({ file: fileId, type: 'CURATED_FILE_DELETE' })
    return new ApiService(api.ONE_CURATED_FILE_SKU, { fileId, projectId, projectSkuId }, {}, true, dispatch)
      .delete()
  }
}
