import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function ChatIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 23">
      <g><g><path d="M1.128 22.533a.77.77 0 0 1-.77-.77V6.94C.358 3.118 3.468.01 7.29.01h10.416c3.821 0 6.93 3.109 6.93 6.93a.77.77 0 1 1-1.54 0 5.397 5.397 0 0 0-5.39-5.39H7.289a5.397 5.397 0 0 0-5.39 5.39v13.273L6 17.097a.765.765 0 0 1 .492-.157l.112.007c.022.001.045.004.068.004h11.032a5.397 5.397 0 0 0 5.39-5.391V9.372a.77.77 0 1 1 1.54 0v2.188c0 3.821-3.109 6.93-6.93 6.93H6.712l-5.118 3.887a.77.77 0 0 1-.466.156z" /></g></g>
    </SvgIcon>
  )
}
