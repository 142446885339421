import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  fetchEncodingQueueStatus
}

function fetchEncodingQueueStatus () {
  return async dispatch => {
    try {
      const response = await new ApiService(api.ENCODING_QUEUE, {}, {}, true, dispatch).get()
      return response.data
    } catch (e) {
      return { images: 0, videos: 0 }
    }
  }
}
