import ExpiresManager from '../helpers/ExpiresManager'

const storageName = 'cacheSignedUrls'
const storageNameLastUpdate = 'cacheSignedUrlsExpiresIn'
// set to 59 minutes (since server returns URLs with a 60 min expiry)
const expiresManager = new ExpiresManager(59 * 60 * 1000)

class CacheSignedUrlService {
  constructor () {
    /**
     * @type {{signedUrl: String, expiresIn: Number}}
     * @private
     */
    this.cachedUrls = {}

    /**
     * linkWithExpiresStorage contains the latest link with expiry information
     * if the link is already in cachedUrls. This link can be used after the
     * cachedUrl has expired.
     *
     * @type {{signedUrl: String, expiresIn: Number}}
     * @private
     */
    this.linkWithExpiresStorage = {}
    this.loadFromLocalStorage()

    window.addEventListener('storage', e => {
      if (e.key === storageName) {
        this.loadFromLocalStorage()
      }
    })
  }

  /**
   * Clear expired links
   * @private
   */
  clearExpiredLinks () {
    this.cachedUrls = Object.keys(this.cachedUrls)
      .filter(clearLink => expiresManager.checkExpiresIn(this.cachedUrls[clearLink].expiresIn))
      .reduce((ob, clearLink) => {
        ob[clearLink] = this.cachedUrls[clearLink]
        return ob
      }, {})
  }

  /**
   * Load cache from localStorage
   * @private
   */
  loadFromLocalStorage () {
    const storageLastUpdate = window.localStorage.getItem(storageNameLastUpdate)
    if (storageLastUpdate && expiresManager.checkExpiresIn(storageLastUpdate)) {
      const storageCache = JSON.parse(window.localStorage.getItem(storageName)) || {}
      this.cachedUrls = { ...this.cachedUrls, ...storageCache }
      this.clearExpiredLinks()
    } else {
      // clear expired data
      window.localStorage.removeItem(storageName)
      if (storageLastUpdate) window.localStorage.removeItem(storageNameLastUpdate)
    }
  }

  /**
   * Save cache in localStorage
   * @private
   */
  saveToLocalStorage () {
    if (this.cachedUrls) {
      this.clearExpiredLinks()
      window.localStorage.setItem(storageName, JSON.stringify(this.cachedUrls))
      window.localStorage.setItem(storageNameLastUpdate, expiresManager.getExpiresIn())
    }
  }

  /**
   * Clear url from parameters.
   * Ex: '/home/user?userId=1' => '/home/user'
   *
   * @param {String} signedUrl
   * @return {String}
   * @private
   */
  getClearUrl (signedUrl) {
    return signedUrl.replace(/\?.+$/, '')
  }

  /**
   * Replace cachedUrls with object from this.linkWithExpiresStorage by clearUrl
   * It is necessary to maintain the relevance of cached links.
   *
   * @param clearUrl
   * @private
   */
  updateExpiredLinkFromStore (clearUrl) {
    if (this.linkWithExpiresStorage[clearUrl]) {
      this.cachedUrls[clearUrl] = this.linkWithExpiresStorage[clearUrl]
      this.saveToLocalStorage()
    }
  }

  /**
   * Add signedUrl or array of signedUrls to cache
   * @param {string | array} signedUrl
   */
  add (signedUrl) {
    let updatedItems = 0;

    [].concat(signedUrl).forEach(url => {
      if (!url || !url.length) return

      const clearUrl = this.getClearUrl(url)
      const cachedUrl = this.cachedUrls[clearUrl]
      const expireSeconds = url.match(/Expires=([0-9]+)/)[1]
      const newItem = {
        expiresIn: expiresManager.getExpiresIn(expireSeconds),
        signedUrl: url
      }
      if (!cachedUrl || !expiresManager.checkExpiresIn(cachedUrl.expiresIn)) {
        updatedItems++
        // Save to cache if the lifetime has not yet expired
        this.cachedUrls[clearUrl] = newItem
      } else {
        // Save in the store for keeping expiresIn time
        this.linkWithExpiresStorage[clearUrl] = newItem
      }
    })
    if (updatedItems) this.saveToLocalStorage()
  }

  /**
   * Get cached url if actual or url from this.linkWithExpiresStorage
   * We had to save url before in any cases.
   *
   * @param {string} signedUrl
   * @return {string} signedUrl
   */
  get (signedUrl) {
    const clearUrl = this.getClearUrl(signedUrl)
    if (this.cachedUrls[clearUrl] && expiresManager.checkExpiresIn(this.cachedUrls[clearUrl].expiresIn)) {
      return this.cachedUrls[clearUrl].signedUrl
    } else {
      if (this.linkWithExpiresStorage[clearUrl]) {
        this.updateExpiredLinkFromStore(clearUrl)
        return this.linkWithExpiresStorage[clearUrl].signedUrl
      }
      return signedUrl
    }
  }
}

export const cacheSignedUrl = new CacheSignedUrlService()
