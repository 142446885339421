import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import ProjectContentCard from './ProjectContentCard'
import ProjectContentList from './ProjectContentList'
import NoProjects from './NoProjects'
import ProjectStatusBar from '../../common/ProjectStatusBar/ProjectStatusBar'
import {
  chooseSummaryPage,
  commonStyles,
  getLeastCompleteProjectSku
} from '../../../helpers'
import { projectInfo } from '../../../actions'

/* eslint-disable sort-keys */
const styles = {
  projectListRow: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-8px -16px'
  },
  projectListCol: {
    width: '33.333%',
    padding: '8px 16px 8px 0px',
    ...commonStyles.media(767, {
      width: '50%'
    }),
    ...commonStyles.media(575, {
      width: '100%'
    }),
    ...commonStyles.media(480, {
      padding: '8px 16px'
    })
  },
  groupHeader: {
    width: '100%',
    opacity: 0.6,
    color: '#323232',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.51,
    textTransform: 'uppercase',
    padding: '32px 0 8px',
    '&:first-child': {
      paddingTop: 8
    },
    ...commonStyles.media(480, {
      paddingLeft: 16
    })
  },
  cursor: {
    cursor: 'pointer'
  },
  projectBox: {
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.07)',
    borderRadius: '3px'
  }
}
/* eslint-enable sort-keys */

class ProjectListScroller extends React.Component {
  setProject (project) {
    const { dispatch } = this.props
    dispatch(projectInfo(project.id))
      .then(() => {
        chooseSummaryPage(project, this.props.packages)
      })
  }

  render () {
    const {
      classes,
      type,
      view,
      projectGroups,
      users,
      mapPackages,
      hasMore,
      isLoading,
      loadMore,
      freeCoupons
    } = this.props
    return (
      <UrlifeInfiniteScroller
        loadMore={loadMore}
        hasMore={hasMore}
        isLoading={isLoading}
        className={classes.projectListRow}
        isEmpty={!projectGroups.length || !projectGroups[0].projects.length}
        emptyText={'No projects'}
        emptyComponent={NoProjects}
        emptyComponentProps={{ type: type }}
      >
        {projectGroups.map((group, index) => (
          <React.Fragment key={index}>
            {group.title && group.title.length > 0 &&
            <div className={classes.groupHeader}>{group.title}</div>
            }
            {group.projects.map(proj => {
              const projectSku = getLeastCompleteProjectSku(proj)
              const pack = projectSku ? mapPackages[projectSku.packageId] : null
              return (
                <div key={proj.id} className={classes.projectListCol}>
                  <div className={classes.projectBox}>
                    <div onClick={this.setProject.bind(this, proj)} className={classes.cursor}>
                      {view === 'card' &&
                      <ProjectContentCard
                        isLoading={isLoading}
                        freeCoupons={freeCoupons}
                        user={users[proj.customerId]}
                        project={proj}
                        pack={pack}
                      />}
                      {view === 'list' &&
                      <ProjectContentList
                        user={users[proj.customerId]}
                        project={proj}
                      />}
                    </div>
                    {type === 'inprogress' && <ProjectStatusBar project={proj} />}
                  </div>
                </div>
              )
            })}
          </React.Fragment>
        ))}
      </UrlifeInfiniteScroller>
    )
  }
}

ProjectListScroller.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  freeCoupons: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  mapPackages: PropTypes.object.isRequired,
  packages: PropTypes.array.isRequired,
  projectGroups: PropTypes.arrayOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['card', 'list']).isRequired
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProjectListScroller))
