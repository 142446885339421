import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../../helpers'
import DropZone from '../../../common/DropZone'
import { connect } from 'react-redux'
import {
  fetchAllProjectFiles,
  setTopBarHeaders
} from '../../../../actions'
import { DEBUG_LEVEL } from '../../../../constants'
import UrlifeButton from '../../../common/UrlifeButton'
import FilesView from '../../../common/files/FilesView'
import ProjectStatusBar from '../../../common/ProjectStatusBar/ProjectStatusBar'
import StickyBottomBar from '../../../common/StickyBottomBar'

/* eslint-disable sort-keys */
const styles = {
  createMovieTitleBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...commonStyles.media(767, {
      flexDirection: 'column'
    })
  },
  createMovieUploadTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#323232',
    textAlign: 'center',
    margin: '30px 0 15px 0',
    ...commonStyles.media(575, {
      fontSize: '14px',
      marginBottom: 10
    })
  },
  nextButton: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  uploadBlock: {
    paddingBottom: 80
  }
}
/* eslint-enable sort-keys */

class UploadFiles extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false
    }
    this.header = ''
  }

  async onInit (files) {
    const { project, updateProject } = this.props

    if (!project.coverFileId && files.length > 0) {
      const firstImage = files.filter(
        ({ fileObject }) => (
          fileObject.mimeType && fileObject.mimeType.includes('image')) ||
          (fileObject.type && fileObject.type.includes('image')))[0]
      firstImage && await updateProject({ coverFileId: firstImage.id })
    }
    this.setHeaders()
  }

  setHeaders () {
    const {
      filesServer,
      setTopBarHeadersFunction,
      fileBatch,
      project
    } = this.props
    const projectFileBatch = fileBatch[project.id]
    const isProcessing = projectFileBatch && projectFileBatch.totalCount !== 0
    const isUploading = isProcessing || (filesServer && filesServer.length > 0)
    const header =
      isUploading
        ? 'Great! Your files are now uploading'
        : 'Upload it all - we\'ll do the work for you'

    if (header !== this.header) {
      this.header = header
      setTopBarHeadersFunction(header)
    }
  }

  componentDidUpdate (prevProps) {
    const { filesServer } = this.props
    if (filesServer.length > 0 && prevProps.filesServer.length < 1) {
      if (DEBUG_LEVEL === 'upload') {
        /* eslint-disable-next-line no-console */
        console.debug('SHOWING STATUS BAR: ' +
        (Date.now() - window.sessionStorage.getItem('debug_time_start')))
      }
    }
    if (filesServer.length === 0 && prevProps.filesServer.length !== 0) {
      this.setHeaders()
    }
  }

  async componentDidMount () {
    const { project, projectFilesFunction } = this.props
    await projectFilesFunction(project.id, { method: 'ASC', sort: 'last_modified' })
    this.setHeaders()
    this.setState({ mounted: true })
  }

  async onNext () {
    const {
      updateProject,
      nextStep
    } = this.props
    await updateProject({}, { uploaded: true })
    nextStep()
  }

  render () {
    const { classes, project, filesServer, fileBatch, updateProject } = this.props
    const { mounted } = this.state
    const projectFileBatch = fileBatch[project.id]
    const isProcessing = projectFileBatch && projectFileBatch.totalCount !== 0
    return (
      <div>
        {filesServer && mounted &&
          <div>
            <DropZone
              view={(filesServer.length === 0 && !isProcessing) ? 'big' : 'error-only'}
              filesServer={filesServer}
              projectId={project.id}
              projectSkuId={project.projectSkus[0].id}
              onInit={this.onInit.bind(this)} />
            {(filesServer.length > 0 || isProcessing) &&
              <div className={classes.uploadBlock}>
                <div className={classes.createMovieTitleBlock}>
                  <div className={classes.createMovieUploadTitle}>
                    Files will upload in the background as you continue
                  </div>
                  <ProjectStatusBar project={project} type={'standalone'} />
                </div>
                <FilesView
                  type={'tile'}
                  edit={true}
                  project={project}
                  files={filesServer}
                  canUpload={true}
                  title={''}
                  updateProject={updateProject}
                />
                <StickyBottomBar>
                  <UrlifeButton
                    className={classes.nextButton}
                    onClick={() => this.onNext()}>
                    Next
                  </UrlifeButton>
                </StickyBottomBar>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

UploadFiles.propTypes = {
  classes: PropTypes.object.isRequired,
  fileBatch: PropTypes.object,
  filesServer: PropTypes.array,
  nextStep: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  projectFilesFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    fileBatch: state.fileBatch,
    filesServer: state.file.files
  }
}

function mapDispatchToProps (dispatch) {
  return {
    projectFilesFunction: function (projectId, params) {
      return dispatch(fetchAllProjectFiles(projectId, params))
    },
    setTopBarHeadersFunction: function (header) {
      dispatch(setTopBarHeaders('Step', header))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UploadFiles))
