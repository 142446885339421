import React from 'react'
import CreateMovieCard from '../Steps/components/CreateMovieCardComponent'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../../helpers'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setTopBarHeaders
} from '../../../../actions'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  createMovieContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  createMovieCardsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 748,
    margin: '-15px',
    ...commonStyles.media(575, {
      margin: '-10px'
    })
  },
  createMovieCardsCol: {
    width: '33.3333%',
    padding: '10px',
    '&._selected': {
      padding: '8px 10px'
    },
    ...commonStyles.media(991, {
      width: '33.3333%'
    }),
    ...commonStyles.media(575, {
      width: '50%'
    })
  }
}
/* eslint-enable sort-keys */

class AboutMovie extends React.Component {
  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  handleNext () {
    this.props.nextStep()
  }

  render () {
    const { classes, categories, categoryId, updateProject, setPrompts } = this.props
    return (
      <div className={classes.createMovieSteptwo}>
        <div className={classes.createMovieContent}>
          <div className={classes.createMovieCardsRow}>
            {categories.map((e, i) => {
              const isSelected = e.id === categoryId
              return <div key={i} className={classNames(classes.createMovieCardsCol, { _selected: isSelected })}>
                <CreateMovieCard
                  handleNext={this.handleNext.bind(this)}
                  updateProject={updateProject}
                  setPrompts={setPrompts}
                  type="about"
                  categoryId={categoryId}
                  category={e}/>
              </div>
            })}
          </div>
        </div>
      </div>
    )
  }
}
AboutMovie.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.number,
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  setPrompts: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Step', 'What is your project about?'))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AboutMovie))
