import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import UrlifeButton from '../common/UrlifeButton'
import DialogActions from '@material-ui/core/DialogActions'
import { commonStyles } from '../../helpers'

import {
  setSkinId,
  toggleModal
} from '../../actions'

/* eslint-disable sort-keys */
const styles = {
  buttonHolder: {
    flexDirection: 'column',
    padding: '0 50px',
    margin: '50px 0 0',
    justifyContent: 'center',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  useButton: {
    margin: 0,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  submitButton: {
    marginBottom: 10,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  loginButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '20px 0',
    textTransform: 'none'
  },
  orText: {
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '5px 0 15px'
  }
}
/* eslint-enable sort-keys */

class UrlifeGetStartedDialog extends React.Component {
  closeModal () {
    this.props.setSkinIdFunction(null)
    this.props.toggleModal(false, null)
  }

  useCode () {
    this.props.toggleModal(true, 'useCode')
  }

  register () {
    this.props.toggleModal(true, 'register')
  }

  render () {
    const { classes, loginSwitch, skinData } = this.props
    const skinned = !!skinData
    return (
      <div>
          <React.Fragment>
            <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
              <UrlifeButton
                className={classes.submitButton}
                onClick={this.register.bind(this)}
              >
              {skinned ? 'Continue' : 'Create Account'}
              </UrlifeButton>
              {!skinned &&
                <div className={classes.orText}>or</div>
              }
              {!skinned && <UrlifeButton
                inverted
                className={classes.useButton}
                onClick={this.useCode.bind(this)}
              >
                Use Project Code
              </UrlifeButton>}
              <Button
                onClick={loginSwitch}
                classes={{ root: classes.loginButton }}
              >
                Already have an account?
              </Button>
            </DialogActions>
          </React.Fragment>
      </div>
    )
  }
}

UrlifeGetStartedDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  loginSwitch: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeGetStartedDialog))
