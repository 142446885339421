import React from 'react'
import ExploreMovie from './ExploreMovie'
import { getPackageType, history, inherits } from '../../helpers/'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getAllVideo } from '../../actions'
import { PACKAGE_TYPES } from '../../constants'
import UrlifeButton from '../common/UrlifeButton'
import ModalPreview from '../common/ModalPreview'
// import beforeAfter from '../../assets/img/curation-before-after-cover.jpg'
// import digitalDisorder from '../../assets/img/curation-expanded-cover.jpg'

/* eslint-disable sort-keys */
const styles = inherits({
})({
  paper: {
    borderRadius: '10px'
  },
  explore: {
    background: 'none'
  },
  exploreContainer: {
    maxWidth: '1170px',
    minHeight: 275,
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap'
  },
  exploreHead: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  exploreTitle: {
    fontFamily: 'Montserrat',
    fontSize: '48px',
    fontWeight: '300',
    lineHeight: '1.25',
    color: '#000000'
  },
  exploreIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '94px'
  },
  exploreDropMenuClose: {
    position: 'absolute',
    right: '26px',
    top: '26px',
    cursor: 'pointer',
    outline: 'none'
  },
  exploreDropMenuList: {
    listStyle: 'none',
    outline: 'none',
    margin: '26px 97px 0px 47px',
    padding: 0
  },
  exploreDropMenuItem: {
    paddingBottom: '20px'
  },
  exploreDropMenuLink: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: '#5334b6',
    position: 'relative',
    textDecoration: 'none',
    '&:hover': {
      color: '#000000',
      cursor: 'pointer'
    },
    '&::before': {
      display: 'none',
      content: '""',
      width: '7px',
      height: '14px',
      top: 0,
      left: '-12px',
      background: 'url("./../../assets/img/icon-sort-pink-copy.png")',
      position: 'absolute'
    },
    '&:hover::before': {
      display: 'block'
    }
  },
  exploreMovieContent: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-20px -15px',
    padding: '20px 0'
  },
  exploreMovieLink: {
    margin: '20px 16px 40px 0',
    textDecoration: 'none'
  },
  packageHeader: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.58,
    textTransform: 'uppercase'
  },
  divider: {
    width: '97%',
    maxWidth: 1139,
    height: 1,
    marginBottom: 40,
    backgroundColor: '#dfdfdf'
  },
  explorePackagesButton: {
    color: '#01b7d7',
    background: 'none',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '5px 20px',
    '&:hover': {
      color: '#fff',
      background: '#01b7d7'
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      color: '#323232',
      fontSize: 16,
      textAlign: 'center',
      fontWeight: 500
    }
  }
})
/* eslint-enable sort-keys */

class AllComponent extends React.Component {
  constructor (props) {
    super(props)
    this.getCategory = () => this.props.location.state && this.props.location.state.activeCategoryId
      ? this.props.location.state.activeCategoryId
      : null

    this.state = {
      categoryId: this.getCategory(),
      chosenTitle: '',
      chosenVideo: null,
      isLoading: false,
      nextCursor: null,
      openModal: false,
      sort: null,
      videos: []
    }
  }

  componentDidMount () {
    this.getVideos()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const nextCategory = this.getCategory()
    if (this.state.categoryId !== nextCategory) {
      this.setState({
        categoryId: nextCategory,
        sort: null,
        videos: []
      }, this.getVideos)
    }
  }

  createMovie () {
    history.push('/pricing')
  }

  getVideos () {
    const { categoryId, sort, nextCursor: cursor } = this.state
    if (!categoryId) return
    const filter = categoryId ? 'category=' + categoryId : ''
    this.props.getAllVideo({ cursor, filter, sort })
      .then(
        respond => {
          this.setState({
            videos: [...this.state.videos, ...(respond.data ? respond.data : [])]
          })
        }
      )
  }

  playVideo (title, url) {
    this.setState({ chosenTitle: title, chosenVideo: url, openModal: true })
  }

  onClose () {
    this.setState({
      chosenTitle: '',
      chosenVideo: null,
      openModal: false
    })
  }

  render () {
    const { videos, chosenTitle, chosenVideo, openModal } = this.state
    const { classes, packages } = this.props
    const hollywoodMovies = videos.filter(vid =>
      getPackageType(packages, vid.packageId) === PACKAGE_TYPES.HOLLYWOOD && vid.videoWebviewUrl)
    const recapMovies = videos.filter(vid =>
      getPackageType(packages, vid.packageId) === PACKAGE_TYPES.RECAP && vid.videoWebviewUrl)
    const hollywoodPackage = packages.filter(p => p.type === PACKAGE_TYPES.HOLLYWOOD)[0]
    const recapPackage = packages.filter(p => p.type === PACKAGE_TYPES.RECAP)[0]
    // const curatedPackage = packages.filter(p => p.type === PACKAGE_TYPES.CURATED)[0]
    return (
      <div>
        <div className={classes.explore}>
          <div className={classes.packageHeader}>{hollywoodPackage.title}</div>
          <div className={classes.exploreContainer}>
            {hollywoodMovies.map((e) => {
              return <div
                onClick={this.playVideo.bind(this, e.title, e.videoWebviewUrl)}
                className={classes.exploreMovieLink} key={e.id}>
                <ExploreMovie video={e}/>
              </div>
            })}
          </div>
          <div className={classes.packageHeader}>{recapPackage.title}</div>
          <div className={classes.exploreContainer}>
            {recapMovies.map((e) => {
              return <div
                onClick={this.playVideo.bind(this, e.title, e.videoWebviewUrl)}
                className={classes.exploreMovieLink} key={e.id}>
                <ExploreMovie video={e}/>
              </div>
            })}
          </div>
          {/*
          <div className={classes.divider}></div>
          <div className={classes.packageHeader}>{curatedPackage.title}</div>
          <div className={classes.exploreContainer}>
            <div
              onClick={() => history.push('/explore/curation-before-after')}
              className={classes.exploreMovieLink}>
              <ExploreMovie
                video={{
                  thumbnailUrl: beforeAfter,
                  title: 'Before & after'
                }}
                showPlayIcon={false}
              />
            </div>
            <div
              onClick={() => history.push('/explore/curation-expanded')}
              className={classes.exploreMovieLink}>
              <ExploreMovie
                video={{
                  thumbnailUrl: digitalDisorder,
                  title: 'Say goodbye to digital disorder!'
                }}
                showPlayIcon={false}
              />
            </div>
          </div>
          */}
          <div className={classes.buttonContainer}>
            <p>Learn more about the individual packages we offer</p>
            <UrlifeButton className={classes.explorePackagesButton} onClick={this.createMovie}>
              Learn more
            </UrlifeButton>
          </div>
        </div>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          video={chosenVideo}
          title={chosenTitle}
        />
      </div>
    )
  }
}
AllComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  getAllVideo: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(Object)
}

const mapStateToProps = state => {
  return {
    loggedIn: state.user.loggedIn,
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getAllVideo: function (params) {
      return dispatch(getAllVideo(params))
    }
  }
}
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AllComponent))
)
