import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function CelebrityIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 140 30">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={(props && props.fill) || '#ffffff'}>
          <g id="Group-4">
            <polyline id="Fill-1" points="52.7543544 30 66.8804805 14.3374858 54.0996997 0 63.7525526 0 71.7657658 8.90996602 79.7957958 0 89.4738739 0 76.6678679 14.3459796 90.7435435 30 80.83003 30 71.7741742 19.8329558 62.6930931 30 52.7543544 30"></polyline>
            <path d="M6.95375375,12.7406569 C6.76622539,11.5773303 5.73906189,10.7450573 4.57417417,10.8125708 C2.53093093,10.8125708 1.67327327,12.6387316 1.67327327,14.4988675 C1.67327327,16.3590034 2.51411411,18.1851642 4.57417417,18.1851642 C5.23279245,18.1762514 5.86065568,17.9021962 6.31845102,17.423807 C6.77624635,16.9454178 7.02611411,16.3022574 7.01261261,15.6370328 L8.64384384,15.6370328 C8.63102306,16.7049494 8.19152606,17.7223125 7.42517009,18.4580617 C6.65881412,19.1938108 5.63064383,19.5854992 4.57417417,19.5441676 C1.71531532,19.5441676 0,17.2423556 0,14.4479049 C0,11.6534541 1.68168168,9.35164213 4.57417417,9.35164213 C6.73513514,9.35164213 8.42522523,10.5662514 8.62702703,12.6557191 L6.94534535,12.6557191" id="Fill-3"></path>
          </g>
          <path d="M14.5801802,15.2293318 C14.6259019,14.5580446 14.2972982,13.9170133 13.7282053,13.5673223 C13.1591124,13.2176313 12.4444912,13.2176313 11.8753983,13.5673223 C11.3063054,13.9170133 10.9777017,14.5580446 11.0234234,15.2293318 L14.5801802,15.2293318 Z M11.015015,16.2485844 C10.9546428,16.7790312 11.1228351,17.3101077 11.4767885,17.7066595 C11.8307418,18.1032114 12.3363226,18.3269968 12.8648649,18.3210646 C13.6285025,18.3882858 14.3327248,17.9009962 14.5465465,17.1574179 L15.9927928,17.1574179 C15.6606144,18.6088807 14.3461596,19.6109933 12.8732733,19.5356738 C10.7123123,19.5356738 9.50990991,17.9983012 9.50990991,15.8493771 C9.44013745,14.9010929 9.7620605,13.9659341 10.3992305,13.2659771 C11.0364005,12.5660201 11.9319734,12.1637105 12.8732733,12.1545866 C13.8728037,12.1819141 14.8102242,12.6505252 15.4380065,13.436682 C16.0657888,14.2228388 16.3208117,15.2474962 16.1357357,16.2400906 L11.015015,16.2485844 Z" id="Fill-5"></path>
          <polygon id="Fill-6" points="17.2960961 19.3488109 18.818018 19.3488109 18.818018 9.67440544 17.2960961 9.67440544"></polygon>
          <path d="M25.0234234,15.2293318 C25.0691452,14.5580446 24.7405414,13.9170133 24.1714485,13.5673223 C23.6023557,13.2176313 22.8877344,13.2176313 22.3186415,13.5673223 C21.7495487,13.9170133 21.4209449,14.5580446 21.4666667,15.2293318 L25.0234234,15.2293318 Z M21.4666667,16.2485844 C21.4060919,16.7805215 21.575375,17.3130282 21.9312125,17.709887 C22.28705,18.1067458 22.7949265,18.3294623 23.3249249,18.3210646 C24.0894554,18.3917348 24.7956461,17.9030832 25.0066066,17.1574179 L26.4528529,17.1574179 C26.1263176,18.6162307 24.803044,19.6223547 23.3249249,19.5356738 C21.1555556,19.5356738 19.9615616,17.9983012 19.9615616,15.8493771 C19.7911227,14.4954548 20.4392693,13.1699315 21.6072365,12.4838106 C22.7752037,11.7976896 24.2362435,11.8841739 25.316886,12.7033982 C26.3975284,13.5226225 26.8879785,14.9155435 26.5621622,16.2400906 L21.4666667,16.2485844 Z" id="Fill-7"></path>
          <path d="M31.1027027,13.3776897 C29.8162162,13.3776897 29.1603604,14.3714609 29.1603604,15.8578709 C29.1603604,17.3442809 29.8666667,18.3210646 31.1027027,18.3210646 C32.4900901,18.3210646 33.0114114,17.0724802 33.0114114,15.8578709 C33.0114114,14.6432616 32.3555556,13.3776897 31.1027027,13.3776897 Z M27.7393393,9.67440544 L29.2528529,9.67440544 L29.2528529,13.2587769 L29.2528529,13.2587769 C29.7199421,12.5785794 30.4850282,12.1699807 31.3045045,12.1630804 C33.4906907,12.1630804 34.550205,13.8618347 34.550205,15.8833522 C34.5585586,17.7689694 33.6,19.5356738 31.5567568,19.5356738 C30.6036603,19.6534269 29.6692833,19.2030222 29.1603604,18.380521 L29.1603604,18.380521 L29.1603604,19.3403171 L27.6972973,19.3403171 L27.6972973,9.67440544 L27.7393393,9.67440544 Z" id="Fill-8"></path>
          <path d="M35.8870871,12.3499434 L37.3249249,12.3499434 L37.3249249,13.700453 L37.3249249,13.700453 C37.6200989,12.8135701 38.4259023,12.2022377 39.3513514,12.1630804 C39.5417785,12.1518042 39.732696,12.1518042 39.9231231,12.1630804 L39.9231231,13.6494904 C39.7059634,13.6142461 39.4869882,13.5915591 39.2672673,13.5815402 C38.2246246,13.5815402 37.3921922,14.4309173 37.3921922,15.9852775 L37.3921922,19.3148358 L35.8870871,19.3148358 L35.8870871,12.3499434" id="Fill-9"></path>
          <path d="M41.1255255,19.3488109 L42.6558559,19.3488109 L42.6558559,12.3499434 L41.1255255,12.3499434 L41.1255255,19.3488109 Z M41.1255255,11.1438279 L42.6558559,11.1438279 L42.6558559,9.67440544 L41.1255255,9.67440544 L41.1255255,11.1438279 Z" id="Fill-10"></path>
          <path d="M43.9507508,12.3499434 L45.1027027,12.3499434 L45.1027027,10.2519819 L46.6246246,10.2519819 L46.6246246,12.3499434 L48.012012,12.3499434 L48.012012,13.4966025 L46.6246246,13.4966025 L46.6246246,17.2338618 C46.6246246,17.8708947 46.6246246,18.2021518 47.3561562,18.2021518 C47.5708671,18.2123232 47.7859358,18.1923131 47.9951952,18.1426954 L47.9951952,19.3403171 C47.6588589,19.3403171 47.3393393,19.416761 47.0114114,19.416761 C45.4138138,19.416761 45.1195195,18.7967157 45.1027027,17.6330691 L45.1027027,13.4966025 L43.9507508,13.4966025 L43.9507508,12.3499434" id="Fill-11"></path>
          <path d="M48.9033033,12.3499434 L50.584985,12.3499434 L52.3843844,17.5736127 L52.3843844,17.5736127 L54.1417417,12.3499434 L55.7477477,12.3499434 L53.0570571,19.7310306 C52.560961,20.9881087 52.2162162,22.1262741 50.6102102,22.1262741 C50.2593045,22.1377319 49.9080378,22.1235386 49.5591592,22.0838052 L49.5591592,20.7417894 C49.7970236,20.7942813 50.0389963,20.8255455 50.2822823,20.8352208 C50.9885886,20.8352208 51.1231231,20.4614949 51.3501502,19.8584371 L51.5183183,19.3233296 L48.9033033,12.3499434" id="Fill-12"></path>
          <path d="M94.6786787,12.7406569 C94.4837275,11.5781414 93.4561938,10.7485001 92.2906907,10.8125708 C90.2474474,10.8125708 89.3897898,12.6387316 89.3897898,14.4988675 C89.3897898,16.3590034 90.2306306,18.1851642 92.2906907,18.1851642 C93.778979,18.1851642 94.5945946,17.0554926 94.7291291,15.6370328 L96.3603604,15.6370328 C96.3475396,16.7049494 95.9080426,17.7223125 95.1416866,18.4580617 C94.3753306,19.1938108 93.3471603,19.5854992 92.2906907,19.5441676 C89.4318318,19.5441676 87.7165165,17.2423556 87.7165165,14.4479049 C87.7165165,11.6534541 89.3981982,9.35164213 92.2906907,9.35164213 C94.4432432,9.35164213 96.1417417,10.5662514 96.3435435,12.6557191 L94.6618619,12.6557191" id="Fill-13"></path>
          <path d="M97.7309309,12.3499434 L99.1687688,12.3499434 L99.1687688,13.700453 L99.1687688,13.700453 C99.4608093,12.8148923 100.26362,12.2032927 101.186787,12.1630804 C101.380012,12.151569 101.573741,12.151569 101.766967,12.1630804 L101.766967,13.6494904 C101.549697,13.6151006 101.330768,13.5924184 101.111111,13.5815402 C100.068468,13.5815402 99.236036,14.4309173 99.236036,15.9852775 L99.236036,19.3148358 L97.7057057,19.3148358 L97.7057057,12.3499434" id="Fill-14"></path>
          <path d="M109.107508,19.3488109 L107.619219,19.3488109 L107.619219,18.3720272 L107.619219,18.3720272 C107.204077,19.0914007 106.44233,19.5343371 105.618018,19.5356738 C103.818619,19.5356738 103.045045,18.6098528 103.045045,16.783692 L103.045045,12.3499434 L104.575375,12.3499434 L104.575375,16.6392978 C104.575375,17.8708947 105.063063,18.3380521 105.895495,18.3380521 C107.156757,18.3380521 107.577177,17.488675 107.577177,16.4269536 L107.577177,12.3499434 L109.099099,12.3499434 L109.099099,19.3488109" id="Fill-15"></path>
          <path d="M110.906907,19.3488109 L112.428829,19.3488109 L112.428829,12.3499434 L110.906907,12.3499434 L110.906907,19.3488109 Z M110.906907,11.1438279 L112.428829,11.1438279 L112.428829,9.67440544 L110.906907,9.67440544 L110.906907,11.1438279 Z" id="Fill-16"></path>
          <path d="M115.497898,17.0979615 C115.573574,17.9983012 116.237838,18.3210646 117.053453,18.3210646 C117.633634,18.3210646 118.634234,18.2021518 118.592192,17.3952435 C118.55015,16.5883352 117.44024,16.4949037 116.33033,16.2315968 C115.22042,15.9682899 114.118919,15.5860702 114.118919,14.1676104 C114.118919,12.7491506 115.75015,12.1630804 117.003003,12.1630804 C118.424024,12.1630804 119.702102,12.7576444 119.895495,14.3035108 L118.297898,14.3035108 C118.171772,13.5815402 117.566366,13.3776897 116.893694,13.3776897 C116.456456,13.3776897 115.640841,13.4881087 115.640841,14.0996602 C115.640841,14.8556059 116.767568,14.9490374 117.885886,15.2208381 C119.004204,15.4926387 120.122523,15.8833522 120.122523,17.2678369 C120.122523,18.9665912 118.440841,19.5441676 117.036637,19.5441676 C115.287688,19.5441676 113.992793,18.7542469 113.967568,17.0979615 L115.497898,17.0979615" id="Fill-17"></path>
          <path d="M126.092492,15.2293318 C126.138214,14.5580446 125.80961,13.9170133 125.240518,13.5673223 C124.671425,13.2176313 123.956803,13.2176313 123.387711,13.5673223 C122.818618,13.9170133 122.490014,14.5580446 122.535736,15.2293318 L126.092492,15.2293318 Z M122.535736,16.2485844 C122.477981,16.7799151 122.648244,17.3107934 123.00347,17.7069698 C123.358696,18.1031462 123.864871,18.3266876 124.393994,18.3210646 C125.157632,18.3882858 125.861854,17.9009962 126.075676,17.1574179 L127.521922,17.1574179 C127.189208,18.612185 125.869974,19.6152378 124.393994,19.5356738 C122.233033,19.5356738 121.030631,17.9983012 121.030631,15.8493771 C121.030631,13.8533409 122.308709,12.1545866 124.393994,12.1545866 C125.395011,12.1794737 126.33474,12.6470482 126.964396,13.433525 C127.594053,14.2200018 127.850141,15.2460704 127.664865,16.2400906 L122.535736,16.2485844 Z" id="Fill-18"></path>
          <path d="M130.271471,17.0979615 C130.355556,17.9983012 131.01982,18.3210646 131.827027,18.3210646 C132.407207,18.3210646 133.407808,18.2021518 133.374174,17.3952435 C133.340541,16.5883352 132.222222,16.4949037 131.112312,16.2315968 C130.002402,15.9682899 128.892492,15.5860702 128.892492,14.1676104 C128.892492,12.7491506 130.523724,12.1630804 131.776577,12.1630804 C133.197598,12.1630804 134.475676,12.7576444 134.669069,14.3035108 L133.07988,14.3035108 C132.945345,13.5815402 132.33994,13.3776897 131.675676,13.3776897 C131.23003,13.3776897 130.414414,13.4881087 130.414414,14.0996602 C130.414414,14.8556059 131.541141,14.9490374 132.659459,15.2208381 C133.777778,15.4926387 134.896096,15.8833522 134.896096,17.2678369 C134.896096,18.9665912 133.214414,19.5441676 131.81021,19.5441676 C130.06967,19.5441676 128.766366,18.7542469 128.74955,17.0979615 L130.271471,17.0979615" id="Fill-19"></path>
          <path d="M137.78018,13.7368229 L138.007207,13.7368229 C138.293093,13.7368229 138.52012,13.6348977 138.52012,13.3715908 C138.52012,13.1082838 138.385586,13.0063586 138.007207,13.0063586 L137.78018,13.0063586 L137.78018,13.7368229 Z M137.78018,14.9004696 L137.435435,14.9004696 L137.435435,12.7940143 C137.62995,12.7610237 137.826788,12.7439806 138.024024,12.7430517 C138.251163,12.7279757 138.477347,12.784354 138.671471,12.9044333 C138.824831,13.0372517 138.893161,13.2446898 138.849153,13.4438473 C138.805145,13.6430048 138.655976,13.8014015 138.461261,13.8557357 L138.461261,13.8557357 C138.637838,13.8982046 138.755556,14.0595862 138.806006,14.3738558 C138.818985,14.5524856 138.861622,14.7276367 138.932132,14.8919758 L138.587387,14.8919758 C138.508729,14.7235711 138.460329,14.542384 138.444444,14.3568682 C138.441276,14.2432986 138.389327,14.1367468 138.302134,14.0649795 C138.214941,13.9932122 138.101141,13.9633381 137.99039,13.9831423 L137.771772,13.9831423 L137.78018,14.9004696 Z" id="Fill-20"></path>
          <path d="M138.108108,11.8912797 C139.152971,11.8912797 140,12.7469077 140,13.8023783 C140,14.8578488 139.152971,15.7134768 138.108108,15.7134768 C137.063245,15.7134768 136.216216,14.8578488 136.216216,13.8023783 C136.216216,12.7469077 137.063245,11.8912797 138.108108,11.8912797 Z M138.108108,12.3159683 C137.295437,12.3159683 136.636637,12.9814567 136.636637,13.8023783 C136.636637,14.6232998 137.295437,15.2887882 138.108108,15.2887882 C138.920779,15.2887882 139.57958,14.6232998 139.57958,13.8023783 C139.57958,12.9814567 138.920779,12.3159683 138.108108,12.3159683 Z" id="Oval" fillRule="nonzero"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

CelebrityIcon.propTypes = {
  fill: PropTypes.string
}
