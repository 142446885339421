import { createBrowserHistory } from 'history'
import {
  cdnDomain,
  MAX_TRANSCODE_FILE_SIZE,
  PACKAGE_TYPES,
  PROJECT_DATA,
  PROJECT_STAGES,
  PROJECT_STATUSES,
  ROLES
} from '../constants'

export const history = createBrowserHistory()

/* eslint-disable sort-keys */
export const commonStyles = {
  text: (params = {}) => {
    const defaultParams = {
      fontSize: 16,
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#ffffff',
      fontWeight: 'normal',
      fontStyle: 'normal'
    }
    params = { ...defaultParams, ...params }
    return params
  },
  flex: (params) => {
    const defaultParams = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
    params = { ...defaultParams, ...params }
    return params
  },
  image: (params) => {
    if (!params.width) {
      throw new Error('Width should be provided')
    }
    if (!params.height) {
      throw new Error('Height should be provided')
    }
    const defaultParams = {
      objectFit: 'contain'
    }
    params = { ...defaultParams, ...params }
    return params
  },
  media: (size, styles, maxMin = 'max') => {
    return { [`@media screen and (${maxMin}-width: ${size}px)`]: styles }
  },
  mediaHeight: (size, styles, maxMin = 'max') => {
    return { [`@media screen and (${maxMin}-height: ${size}px)`]: styles }
  },
  mediaDimensions: (sizeW, sizeH, styles, maxMinW = 'max', maxMinH = 'max') => {
    return { [`@media screen and (${maxMinW}-width: ${sizeW}px) and (${maxMinH}-height: ${sizeH}px)`]: styles }
  },
  mediaAnd: (size1, size2, styles, maxMin1 = 'max', maxMin2 = 'max') => {
    return { [`@media screen and (${maxMin1}-width: ${size1}px) and (${maxMin2}-width: ${size2}px)`]: styles }
  },
  container: function () {
    return {
      ...this.text(),
      width: 1440,
      maxWidth: '100%',
      margin: '0 auto',
      paddingLeft: 150,
      paddingRight: 150,
      position: 'relative',
      ...commonStyles.media(1279, {
        paddingLeft: 50,
        paddingRight: 50
      }),
      ...commonStyles.media(480, {
        paddingLeft: 15,
        paddingRight: 15
      })
    }
  }
}
/* eslint-enable sort-keys */

export const inherits = (params) => (styles) => {
  const buffer = { ...styles }
  Object.keys(params).forEach(key => {
    buffer[key] = { ...(styles[params[key]] || {}), ...(styles[key] || {}) }
  })
  return buffer
}

export const toLocale = (date, options) => {
  if (!options) {
    throw new Error('Options needed: {full: true|false, date: true|false, time: true|false}')
  }
  if (options.date) {
    return toDateString(date)
  } else if (options.time) {
    return toTimeString(date)
  } else if (options.full) {
    return toDateString(date) + ' ' + toTimeString(date)
  } else if (options.forMyMovie) {
    return formatForMyMovie(date)
  } else if (options.forThankYou) {
    return formatForThankYou(date)
  }
  throw new Error('Options needed: {full: true|false, date: true|false, time: true|false}')
}

const formatForMyMovie = date => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' }
  const newDate = date.toLocaleString('en-US', options).split(' ')
  return `${newDate[1].replace(/,/i, '')} ${newDate[0]} ${newDate[2]}`
}

const formatForThankYou = date => {
  const dateOptions = { day: '2-digit', month: 'short', weekday: 'long' }
  const timeOptions = { timeStyle: 'short' }
  return date.toLocaleString('en-US', dateOptions) + ' at ' + date.toLocaleString('en-US', timeOptions)
}

const toDateString = (date) => {
  let month = date.getMonth() + 1
  if (month.toString().length === 1) {
    month = '0' + month
  }
  let dateDay = date.getDate()
  if (dateDay.toString().length === 1) {
    dateDay = '0' + dateDay
  }
  return date.getFullYear() + '-' + month + '-' + dateDay
}

const toTimeString = (date) => {
  let hour = date.getHours()
  if (hour.toString().length === 1) {
    hour = '0' + hour
  }
  let minutes = date.getMinutes()
  if (minutes.toString().length === 1) {
    minutes = '0' + minutes
  }
  return hour + ':' + minutes
}

export const humanFileSize = (bytes, si) => {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes.toFixed(0) + ' B'
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}

export const formatPrice = (priceCents) => {
  const priceDollars = priceCents / 100
  let formattedPrice = '$'
  if (priceCents < 50) {
    formattedPrice = 'FREE'
  } else if (priceCents % 100 !== 0) {
    formattedPrice += priceDollars.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
  } else {
    formattedPrice += priceDollars
  }
  return formattedPrice
}

export const getFilePermissions = (file, project, userId, roleId, isCurated) => {
  const projectSku = getLeastCompleteProjectSku(project)
  const isProjectCreator = roleId === ROLES.USER && userId === project.customerId
  const isAssignedProducer = roleId === ROLES.PRODUCER && projectSku && userId === projectSku.producerId
  const isAssignedEditor = roleId === ROLES.EDITOR && projectSku && userId === projectSku.editorId
  const isAdmin = roleId === ROLES.ADMIN
  const isNonCustomer = roleId === ROLES.ADMIN || isAssignedEditor || isAssignedProducer
  const isImage = file && file.mimeType && file.mimeType.includes('image')
  const isTranscodable = file && file.fileSize <= MAX_TRANSCODE_FILE_SIZE
  const statusId = getLeastCompleteProjectSkuStatus(project)
  const projectIsCompleted = statusId === PROJECT_STATUSES.COMPLETE

  const permissions = {
    canDelete: isAdmin,
    canDownload: isAdmin,
    canEditNotes: isAdmin && !isCurated,
    canEditProdNotes: isAdmin && !isCurated,
    canFavorite: isAdmin,
    canMakeCover: isAdmin && isImage && !isCurated,
    canProdFavorite: isAdmin,
    canSeeFavorite: !isCurated,
    canSeeNotes: !isCurated,
    canSeeProdFavorite: isNonCustomer && !isCurated,
    canSeeProdNotes: isNonCustomer && !isCurated,
    hasActions: isAdmin,
    isAdmin: isAdmin,
    isAssignedEditor: isAssignedEditor,
    isAssignedProducer: isAssignedProducer,
    isNonCustomer: isNonCustomer,
    isProjectCreator: isProjectCreator
  }

  if (isAdmin) {
    return permissions
  }

  if (isCurated) {
    permissions.canDelete = (isAssignedProducer || isAssignedEditor) && !projectIsCompleted
    permissions.canDownload = isNonCustomer || (isProjectCreator && projectIsCompleted)
  } else {
    permissions.canFavorite = isProjectCreator
    permissions.canProdFavorite = isAssignedProducer
    permissions.canMakeCover = isProjectCreator && isImage
    permissions.canDelete = isProjectCreator
    permissions.canDownload = isAssignedProducer || isAssignedEditor || (file && !isTranscodable)
    permissions.canEditNotes = isProjectCreator
    permissions.canEditProdNotes = isAssignedProducer
  }

  permissions.hasActions =
    permissions.canFavorite ||
    permissions.canProdFavorite ||
    permissions.canMakeCover ||
    permissions.canDelete ||
    permissions.canDownload

  return permissions
}

export const mapProjectStatusToStage = (statusId) => {
  switch (statusId) {
    case PROJECT_STATUSES.PREP_USER:
    case PROJECT_STATUSES.PREP_WAIT_PAYMENT:
    case PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION:
      return PROJECT_STAGES.PREP
    case PROJECT_STATUSES.PROJECT_QUEUED:
      return PROJECT_STAGES.QUEUED
    case PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED:
    case PROJECT_STATUSES.PRODUCTION_EDITING:
    case PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW:
    case PROJECT_STATUSES.PRODUCTION_REVISIONS:
    case PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL:
      return PROJECT_STAGES.PRODUCTION
    case PROJECT_STATUSES.COMPLETE:
      return PROJECT_STAGES.COMPLETE
    default:
      return PROJECT_STAGES.UNKNOWN
  }
}

export const getLeastCompleteProjectSkuStatus = (project) => {
  const sku = getLeastCompleteProjectSku(project)
  const statusId = sku && sku.statusId ? sku.statusId : null
  return statusId
}

export const getLeastCompleteProjectSku = (project) => {
  const statusId = project ? PROJECT_STATUSES.COMPLETE : PROJECT_STATUSES.PREP_USER
  let stage = mapProjectStatusToStage(statusId)
  let selectedSku = null

  project && project.projectSkus && project.projectSkus.forEach(sku => {
    if (!selectedSku) {
      selectedSku = sku
    } else {
      const skuStage = mapProjectStatusToStage(sku.statusId)
      if (skuStage < stage) {
        selectedSku = sku
        stage = skuStage
      } else if (skuStage === stage) {
        if (selectedSku.statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL) {
          selectedSku = sku
        } else {
          if (sku.statusId < selectedSku.statusId) {
            selectedSku = sku
          }
        }
      }
    }
  })

  return selectedSku
}

export const getPackageIdFromPackageSkuId = (packageSkuId, packages) => {
  let packageId = null
  if (packages && packages.length && packages.length > 0) {
    packages.forEach(pack => {
      pack.skus.forEach(sku => {
        if (sku.id === packageSkuId) {
          packageId = sku.packageId
        }
      })
    })
  }
  return packageId
}

export const chooseSummaryPage = (project, packages) => {
  const sku = getLeastCompleteProjectSku(project)
  const status = sku.statusId

  if (status === PROJECT_STATUSES.PREP_USER) {
    history.push(`/createmovie/continue/${project.id}`, {
      lastLocation: history.location
    })
  } else {
    if ((status === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL ||
        status === PROJECT_STATUSES.COMPLETE)
    ) {
      history.push(`/projects/${project.id}/movie`)
    } else {
      history.push(`/projects/${project.id}/summary`)
    }
  }
}

export const getNoteStepsArray = (promptsLength) => {
  const steps = Math.floor(promptsLength / PROJECT_DATA.MAX_PROMPTS_PER_PAGE)
  const stepsArray = Array(+steps).fill(PROJECT_DATA.MAX_PROMPTS_PER_PAGE)
  const left = promptsLength % PROJECT_DATA.MAX_PROMPTS_PER_PAGE
  if (left) {
    stepsArray.push(left)
  }
  return stepsArray
}

export const getCreateProjectTotalSteps = (packageType) => {
  switch (packageType) {
    case PACKAGE_TYPES.HOLLYWOOD:
      return 5
    case PACKAGE_TYPES.RECAP:
      return 4
    case PACKAGE_TYPES.CURATED:
      return 3
    default:
      return 0
  }
}

export const getPackageType = (packages, packageId) => {
  const pack = packages.find(pack => pack.id === packageId)
  if (pack) {
    return pack.type
  } else {
    return null
  }
}

export const codeIsOffer = (code) => {
  switch (code) {
    case 'FAMILYGALLERY':
    case 'TRAVELINK2021':
      return true
    default:
      return false
  }
}

export const getCodeResultDescription = (codeResult, code, skinData = null) => {
  const description = {
    line1: '',
    line2: ''
  }
  const skinned = !!skinData
  if (codeResult) {
    if (codeResult.status === 'success') {
      if (codeResult.data && codeResult.data.coupon) {
        if (codeResult.data.projectId) {
          if (skinned) {
            if (skinData.codeResultDescription) {
              description.line1 = skinData.codeResultDescription
            } else {
              description.line1 = 'You can now access your complimentary video package courtesy of ' + skinData.title
            }
          } else {
            description.line1 = 'You can now access your package'
          }
        } else if (codeResult.data.coupon) {
          description.line1 = 'You have unlocked: ' + codeResult.data.coupon.title
        }
      }
    } else if (codeResult.status === 'fail') {
      if (codeIsOffer(code)) {
        description.line1 = 'Failed to unlock offer'
        switch (code) {
          case 'FAMILYGALLERY':
            description.line1 += ': 50% off your first project'
            break
          case 'TRAVELINK2021':
            description.line1 += ': 25% off your first project'
            break
          default:
            break
        }
        description.line2 = 'This offer is no longer available'
      } else {
        description.line1 = 'Failed to unlock code: ' + code

        const duplicateError = codeResult.message.match(/Duplicate entry/)
        const limitError = codeResult.message.match(/Promocode usage limit exceeded/)
        const expiredError = codeResult.message.match(/promocode has expired/)
        const redeemedError = codeResult.message.match(/already redeemed/)
        if (duplicateError) {
          description.line2 = 'You have already used this code'
        } else if (limitError || expiredError) {
          description.line2 = 'This code is no longer valid.'
        } else if (redeemedError) {
          description.line2 = 'Something has gone wrong. Please contact hello@urlifemedia.com for assistance.'
        } else {
          description.line2 = 'This code does not exist'
        }
      }
    }
  }
  return description
}

export const setProjectCookies = (project) => {
  if (project.cookies) {
    document.cookie = 'CloudFront-Key-Pair-Id=' +
      project.cookies['CloudFront-Key-Pair-Id'] +
      ';domain=' + cdnDomain + `;path=/${project.id}`
    document.cookie = 'CloudFront-Policy=' +
      project.cookies['CloudFront-Policy'] +
      ';domain=' + cdnDomain + `;path=/${project.id}`
    document.cookie = 'CloudFront-Signature=' +
      project.cookies['CloudFront-Signature'] +
      ';domain=' + cdnDomain + `;path=/${project.id}`
  }
}

export const getFileDimensions = async (file, onError = null) => {
  const metadata = {}
  const mimeType = file.type || file.mimeType

  if (mimeType.includes('image')) {
    await new Promise(resolve => {
      const timeId = window.setTimeout(() => {
        onError && onError('IMAGE TIMEOUT', 'Creating image timed out')
        resolve()
      }, 10 * 1000)
      const image = new window.Image()
      image.onload = function () {
        window.clearTimeout(timeId)
        metadata.width = this.width || undefined
        metadata.height = this.height || undefined
        resolve()
      }
      image.onerror = function (e) {
        window.clearTimeout(timeId)
        onError && onError('IMAGE ERROR', e.message || 'Error loading image to get width/height')
        resolve()
      }
      image.src = window.URL.createObjectURL(file)
    })
  } else if (mimeType.includes('video')) {
    await new Promise((resolve) => {
      const timeId = window.setTimeout(() => {
        onError && onError('VIDEO TIMEOUT', 'Creating video timed out')
        resolve()
      }, 10 * 1000)
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = function () {
        window.clearTimeout(timeId)
        metadata.width = this.videoWidth || undefined
        metadata.height = this.videoHeight || undefined
        resolve()
      }
      video.onerror = function (e) {
        window.clearTimeout(timeId)
        onError && onError('VIDEO ERROR', e.message || 'Error loading video to get width/height')
        resolve()
      }
      video.src = window.URL.createObjectURL(file)
    })
  }

  return metadata
}

export const is4K = (file) => {
  return ((file.width >= 3840 && file.height >= 2160) || (file.width >= 2160 && file.height >= 3840))
}

export const is1080p = (file) => {
  return (file.height >= 1080 && file.height < 2160 && file.width >= 1920 && file.width < 3840) ||
    (file.height >= 1920 && file.height < 3840 && file.width >= 1080 && file.width < 2160)
}

export const getSkinIds = async (projectList, packages, getPackageSku, currentSkinIds) => {
  const skusToFetch = {}
  projectList.forEach(project => {
    const packageId = project.packageId
    const projectSku = getLeastCompleteProjectSku(project)
    const packageSkuId = projectSku && projectSku.packageSku
    if (packageId && packageSkuId && !currentSkinIds[packageSkuId]) {
      const pack = packages.find(pack => pack.id === packageId)
      const sku = pack.skus.find(sku => sku.id === packageSkuId)
      if (!sku) {
        skusToFetch[packageSkuId] = true
      }
    }
  })
  try {
    const data = await Promise.all(Object.keys(skusToFetch).map(skuId => getPackageSku(skuId)))
    const skinIds = {}
    data.forEach(sku => {
      if (sku.skinId) {
        skinIds[sku.id] = sku.skinId
      }
    })
    return skinIds
  } catch (error) {
    return {}
  }
}

export const downloadLink = (url) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    true
  )
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}
