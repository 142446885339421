import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TopMenuNewProject (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 47">
      <defs><clipPath id="7upwa"><path fill="#fff" d="M0 47V0h47v47zM23.76 2.7C12.148 2.7 2.7 12.148 2.7 23.76c0 11.612 9.448 21.06 21.06 21.06 11.612 0 21.06-9.448 21.06-21.06 0-11.612-9.448-21.06-21.06-21.06z"/></clipPath></defs><g><g><g><g><path fill="none" stroke="#fff" strokeMiterlimit="50" strokeWidth="4" d="M23.76 2.7C12.148 2.7 2.7 12.148 2.7 23.76c0 11.612 9.448 21.06 21.06 21.06 11.612 0 21.06-9.448 21.06-21.06 0-11.612-9.448-21.06-21.06-21.06z" clipPath='url("#7upwa")'/></g></g><g><g><g><path fill="#fff" d="M31.399 24.466c0 .5-.405.904-.904.904H25.37v9.412a.904.904 0 1 1-1.808 0V25.37h-9.413a.904.904 0 1 1 0-1.808h9.413v-9.413a.904.904 0 1 1 1.808 0v9.413h5.125c.5 0 .904.404.904.904zm4.288 0c0 .5-.405.904-.905.904h-1.334a.904.904 0 1 1 0-1.808h1.334c.5 0 .905.404.905.904z"/></g></g></g></g></g>
    </SvgIcon>
  )
}
