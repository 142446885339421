import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  createProjectCodeGrouping,
  fetchProjectCodeGrouping,
  fetchProjectCodeGroupings,
  generateProjectCodes,
  updateProjectCodeGrouping
}

function createProjectCodeGrouping (grouping) {
  return dispatch => {
    return new ApiService(api.PROJECT_CODE_GROUPINGS, {}, {}, true, dispatch)
      .post(grouping)
  }
}

function updateProjectCodeGrouping (groupId, grouping) {
  return dispatch => {
    return new ApiService(api.ONE_PROJECT_CODE_GROUPING, { groupId }, {}, true, dispatch)
      .put(grouping)
  }
}

function fetchProjectCodeGroupings (params = {}) {
  return dispatch => {
    return new ApiService(api.PROJECT_CODE_GROUPINGS, {}, params, true, dispatch)
      .get()
  }
}

function fetchProjectCodeGrouping (groupId, params = {}) {
  return dispatch => {
    return new ApiService(api.ONE_PROJECT_CODE_GROUPING, { groupId }, params, true, dispatch)
      .get()
  }
}

function generateProjectCodes (groupId, quantity, packageSkuId) {
  return dispatch => {
    return new ApiService(api.PROJECT_CODES, { groupId }, {}, true, dispatch)
      .post({ packageSkuId, quantity })
  }
}
