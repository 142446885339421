import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Checkbox, FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core'
import EditFileField from './EditFileField'
import EditSortField from './EditSortField'
import EditTextField from './EditTextField'
import EditTimeField from './EditTimeField'
import EditUploadField from './EditUploadField'
import classNames from 'classnames'
import EditDateField from './EditDateField'

/* eslint-disable sort-keys */
const styles = {
  readOnlyString: {
    paddingTop: 10,
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  checkboxField: {
    fontSize: 24,
    width: '1em',
    height: '1em',
    paddingTop: 10
  },
  editSelectField: {
    width: '100%',
    fontSize: 14,
    '&._placeholder': {
      color: '#8fa4ad'
    }
  },
  editSelectFieldControl: {
    position: 'relative',
    width: '100%'
  },
  editSelectFieldError: {
    position: 'absolute',
    top: '100%',
    marginTop: 4,
    whiteSpace: 'nowrap',
    fontSize: 10
  }
}
/* eslint-enable sort-keys */

class SwitchField extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const observedProps = ['value', 'disableSortTop', 'disableSortBot', 'isSortDuplicated']
    return observedProps.filter(propName => {
      if (Array.isArray(this.props[propName])) {
        return this.props[propName]
          .filter((_, propIndex) => this.props[propName][propIndex] !== nextProps[propName][propIndex])
          .length > 0
      } else {
        return this.props[propName] !== nextProps[propName]
      }
    }).length > 0
  }

  render () {
    const {
      classes,
      disableSortBot,
      disableSortTop,
      exchangeFieldOrderPosition,
      fieldSettings,
      init,
      isSortDuplicated,
      onChangeField,
      preview,
      rowId,
      value
    } = this.props
    const fieldName = fieldSettings.field

    switch (fieldSettings.type) {
      case 'checkboxInverted':
        return <Checkbox
          color='primary'
          className={classes.checkboxField}
          checked={!value}
          onChange={e => onChangeField(
            { [fieldName]: !e.currentTarget.checked },
            true
          )}
        />
      case 'string':
        return <div className={classes.readOnlyString}>{value}</div>
      case 'file':
        const isArray = Array.isArray(fieldSettings.field)
        const imageFieldName = isArray ? fieldName[0] : fieldName
        const videoFieldName = isArray ? fieldName[1] : fieldName
        return <EditFileField
          fieldTitle={fieldSettings.name}
          image={isArray ? value[0] || '' : value || ''}
          video={isArray ? value[1] || '' : value || ''}
          withVideo={isArray}
          onApply={
            changes => onChangeField(isArray
              ? {
                  [imageFieldName]: changes[0],
                  [videoFieldName]: changes[1]
                }
              : { [imageFieldName]: changes },
            true)
          }
        />
      case 'uploadFile':
        return <EditUploadField
          fieldTitle={fieldSettings.name}
          acceptFiles={fieldSettings.acceptFiles}
          image={value}
          rowId={rowId}
          onApply={init}
          preview={preview}
        />
      case 'sort':
        return <EditSortField
          isDuplicate={isSortDuplicated}
          value={value || ''}
          onExchangeFieldPosition={changing => exchangeFieldOrderPosition(changing)}
          disableSortTop={!value || disableSortTop}
          disableSortBot={!value || disableSortBot}
          onChange={value => onChangeField({ [fieldName]: value })}
          onApply={value => onChangeField(
            { [fieldName]: value },
            true,
            true)}
        />
      case 'time':
        return <EditTimeField
          value={value || ''}
          onApply={value => onChangeField({ [fieldName]: value }, true)}
        />
      case 'select':
        return <FormControl error={!value} className={classes.editSelectFieldControl}>
          <Select
            onChange={e => onChangeField({ [fieldName]: e.target.value }, true)}
            error={!value}
            className={classNames(
              classes.editSelectField,
              {
                _placeholder: !value
              }
            )}
            value={value || 'no-value'}
          >
            {fieldSettings.placeholder &&
            <MenuItem
              className={classes.editSelectMenuItemPlaceholder}
              value='no-value'
              disabled>{fieldSettings.placeholder}</MenuItem>
            }
            {fieldSettings.values && fieldSettings.values.map(option => (
              <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>

          {!value &&
          <FormHelperText className={classes.editSelectFieldError}>Required</FormHelperText>
          }
        </FormControl>
      case 'date':
        return <EditDateField
          value={value || ''}
          onApply={value => onChangeField({ [fieldName]: value }, true)}
        />
      case 'textMultiLine':
        return <EditTextField
          rows={2}
          placeholder={fieldSettings.name}
          value={value || ''}
          onChange={value => onChangeField({ [fieldName]: value })}
          onApply={value => onChangeField({ [fieldName]: value }, true)}
        />
      default:
        return <EditTextField
          placeholder={fieldSettings.name}
          value={value || ''}
          required={fieldSettings.required}
          onApply={value => onChangeField({ [fieldName]: value }, true)}
        />
    }
  }
}

SwitchField.propTypes = {
  classes: PropTypes.object.isRequired,
  disableSortBot: PropTypes.bool,
  disableSortTop: PropTypes.bool,
  exchangeFieldOrderPosition: PropTypes.func,
  fieldSettings: PropTypes.object.isRequired,
  init: PropTypes.func,
  isSortDuplicated: PropTypes.bool,
  onChangeField: PropTypes.func,
  preview: PropTypes.object,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any
}

export default withStyles(styles)(SwitchField)
