const category = (state = { categories: [] }, action) => {
  switch (action.type) {
    case 'GETTING_ALL_CATEGORIES':
      return {
        ...state,
        failedgettingCategories: false,
        gettingCategories: true,
        gotCategories: false
      }
    case 'GOT_ALL_CATEGORIES':
      return {
        ...state,
        categories: [...action.categories],
        failedgettingCategories: false,
        gettingCategories: false,
        gotCategories: true
      }
    case 'GET_ALL_CATEGORIES_FAILED':
      return {
        ...state,
        failedgettingCategories: true,
        gettingCategories: false,
        gotCategories: false
      }
    case 'ADD_CATEGORY':
      return {
        ...state,
        categories: [...state.categories, { ...action.category }]
      }
    case 'UPDATE_CATEGORY':
      return {
        ...state,
        categories: state.categories.map(category => {
          return category.id === action.category.id ? { ...category, ...action.category } : category
        })
      }
    case 'DELETE_CATEGORY':
      return {
        ...state,
        categories: state.categories.filter(categories => categories.id !== action.categoryId)
      }
    default:
      return state
  }
}

export default category
