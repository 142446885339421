import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { parse } from 'query-string'
import Button from '@material-ui/core/Button'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { forgottenPassword, toggleModal } from '../../actions'
import { commonStyles, history } from '../../helpers'
import ValidateForm from '../common/form/ValidateForm'
import UrlifeDialogSuccess from '../common/UrlifeDialogSuccess'

/* eslint-disable sort-keys */
const styles = () => ({
  forgotHeader: {
    textAlign: 'center',
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    padding: '40px 50px 0px 50px',
    ...commonStyles.media(480, {
      fontSize: 16,
      padding: '20px 20px 0px 20px'
    })
  },
  content: {
    minHeight: '80px',
    padding: '0 50px 40px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  buttonHolder: {
    flexDirection: 'column',
    padding: '0 50px',
    justifyContent: 'center',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  forgotButton: {
    margin: 'auto',
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  registerButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '20px 0',
    textTransform: 'none'
  }
})
/* eslint-enable sort-keys */

export class UrlifeForgottenDialog extends React.Component {
  constructor (props) {
    super(props)
    const query = parse(history.location.search)
    this.state = {
      failedReset: !!(query && query.token),
      fields: {},
      formStatus: '' // '', sending, success, fail
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  onSubmit () {
    const { dispatch, code, setCode } = this.props
    const { userEmail } = this.state.fields
    this.setState({
      formStatus: 'sending'
    }, async () => {
      try {
        const result = await dispatch(forgottenPassword(userEmail, code))
        const nextState = { ...this.state }
        nextState.formStatus = result.status || 'fail'
        if (result.formStatus === 'success') {
          // clear fields value on success
          setCode('')
          nextState.fields = this.state.fields.map(field => {
            field = ''
            return field
          })
        }
        this.setState(nextState)
      } catch (e) {
        this.setState({ formStatus: 'fail' })
      }
    })
  }

  closeModal () {
    const { failedReset } = this.state
    this.props.dispatch(toggleModal(false, null))
    if (failedReset) {
      history.push('/')
    }
  }

  render () {
    const { classes, loginSwitch } = this.props

    const { formStatus, fields } = this.state

    return (
      <div>
        <DialogTitle
          id="form-dialog-title"
          className={classes.forgotHeader}
          disableTypography
        >
          Reset password
        </DialogTitle>
        {formStatus === 'success' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            className={classes.successText}
            message={'An email will be sent with instructions on how to reset your password.'} />

          <UrlifeButton
            onClick={this.closeModal.bind(this)}
            classes={{ root: classes.forgotButton }}
          >OK</UrlifeButton>
        </DialogContent>
        }
        {formStatus !== 'success' &&
        <ValidateForm
          onSubmit={this.onSubmit.bind(this)}
          onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
          validateOnMount={true}>
          {(form) => (
            <React.Fragment>
              <DialogContent classes={{ root: classes.content }}>
                {form.renderField(
                  'userEmail',
                  ['required', 'email'],
                  (field, fieldData) => {
                    let validationStatus = 'none'
                    let errorMessage = ''
                    if (formStatus === 'fail') {
                      validationStatus = 'error'
                      errorMessage = 'There is no such user'
                    } else if (fields.userEmail && fields.userEmail.length > 0) {
                      if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                        validationStatus = 'error'
                        errorMessage = fieldData.errors[0]
                      } else {
                        validationStatus = 'success'
                      }
                    }
                    return (
                      <UrlifeTextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="userEmail"
                        label="Email"
                        type="email"
                        fullWidth
                        onChange={e => field.onChange(e)}
                        onBlur={e => field.onBlur(e)}
                        defaultValue={fields.userEmail || ''}
                        validationStatus={validationStatus}
                        infoLabel={errorMessage}
                      />
                    )
                  }
                )}
              </DialogContent>
              <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                <UrlifeButton
                  type="submit"
                  className={classes.forgotButton}
                  disabled={formStatus === 'sending' || !form.checkFormValid()}
                >
                  Reset password
                </UrlifeButton>
                <Button
                  onClick={loginSwitch}
                  classes={{ root: classes.registerButton }}
                >
                  Log in
                </Button>
              </DialogActions>

            </React.Fragment>
          )}
        </ValidateForm>
        }
      </div>
    )
  }
}

UrlifeForgottenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  loginSwitch: PropTypes.func,
  setCode: PropTypes.func.isRequired
}

export default withStyles(styles)(connect()(UrlifeForgottenDialog))
