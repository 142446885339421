import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { commonStyles, history } from '../../helpers'
import UrlifeButton from './UrlifeButton'
import ProjectStatusBar from './ProjectStatusBar/ProjectStatusBar'
import { fileProgressStatus, fileProgressType } from '../../reducers/fileProgress'
import styled from 'styled-components'
import fireworks from '../../assets/img/fireworks.jpg'

/* eslint-disable sort-keys */
const ConfirmationWrapper = styled.div(props => ({
  backgroundImage: `url(${props.backgroundImage})`,
  backgroundColor: `${props.backgroundColor}`,
  backgroundSize: `${props.backgroundSize}`,
  backgroundPosition: `${props.backgroundPosition}`
}))

const Overlay = styled.div(props => ({
  backgroundImage: `
      linear-gradient(
        135deg,
        ${props.gradientStartColor} 20%,
        ${props.gradientEndColor} 80%
      )
    `,
  opacity: `${props.opacity}`,
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 300
}))

const styles = {
  root: {
    top: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100
  },
  confirmationWrapper: {
    top: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat'
  },
  contentWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 400
  },
  contentBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    color: '#ffffff',
    fontFamily: 'Montserrat'
  },
  header: {
    fontSize: 60,
    fontWeight: 700,
    lineHeight: '95px',
    ...commonStyles.media(575, {
      fontSize: 48,
      lineHeight: '76px'
    }),
    ...commonStyles.media(430, {
      fontSize: 36
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 36,
      lineHeight: '56px'
    })
  },
  subHeader: {
    fontSize: 28,
    fontWeight: 400,
    lineHeight: '55px',
    paddingBottom: 50,
    textAlign: 'center',
    ...commonStyles.media(575, {
      fontSize: 22,
      lineHeight: '46px',
      paddingBottom: 35
    }),
    ...commonStyles.media(430, {
      fontSize: 18,
      width: 320
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '36px',
      paddingBottom: 10
    })
  },
  infoText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    textAlign: 'center',
    paddingTop: 24,
    paddingBottom: 100,
    ...commonStyles.media(575, {
      fontSize: 14,
      lineHeight: '21px'
    }),
    ...commonStyles.media(430, {
      width: 320
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '21px',
      paddingTop: 10,
      paddingBottom: 25
    })
  },
  doneButton: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    '&:hover': {
      border: '1px solid #ffffff',
      color: '#ffffff'
    },
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}

class Confirmation extends React.Component {
  onDone () {
    history.push('/home')
  }

  isUploading () {
    const { project, fileProgress, fileBatch } = this.props
    if (project) {
      const projectFileBatch = fileBatch[project.id]
      const isProcessing = projectFileBatch && projectFileBatch.totalCount !== 0
      return isProcessing || fileProgress.some(file => {
        return file.projectId === project.id &&
          file.progressType === fileProgressType.upload &&
          file.status !== fileProgressStatus.complete
      })
    } else {
      return false
    }
  }

  render () {
    const {
      classes,
      headerText,
      subHeaderText,
      infoText,
      project,
      hasStatusBar,
      skinData
    } = this.props
    const showStatusBar = hasStatusBar && project && this.isUploading()
    const skinned = skinData !== null
    const gradientStartColor = skinned ? skinData.gradientStartColor : '#3d29b1'
    const gradientEndColor = skinned ? skinData.gradientEndColor : '#bd6ad3'
    const opacity = skinned ? skinData.opacity : 0.84
    const backgroundImage = skinned ? skinData.backgroundImage : fireworks
    const backgroundPosition = skinned ? skinData.backgroundPosition : '50%'
    const backgroundSize = skinned ? skinData.backgroundSize : 'auto 100vh'
    const backgroundColor = skinned ? skinData.backgroundColor : '#000000'
    return (
      <div className={classes.root}>
        <ConfirmationWrapper data-testid='confirmationWrapper'
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              backgroundPosition={backgroundPosition}
              backgroundSize={backgroundSize}
              gradientStartColor={gradientStartColor}
              gradientEndColor={gradientEndColor}
              className={classes.confirmationWrapper}
              />
              <Overlay
                gradientStartColor={gradientStartColor}
                gradientEndColor={gradientEndColor}
                opacity={opacity}
              />
              <div className={classes.contentWrapper}>
                <div className={classes.contentBlock}>
                  <div className={classes.header}>
                    {headerText}
                  </div>
                  <div className={classes.subHeader}>
                    {subHeaderText}
                  </div>
                  {showStatusBar && <ProjectStatusBar project={project} type={'standalone'} />}
                  <div className={classes.infoText}>
                    {showStatusBar &&
                    <div>
                      Please make sure all files are uploaded before closing your browser.
                    </div>
                    }
                    <div>{infoText}</div>
                  </div>
                  <UrlifeButton
                    className={classes.doneButton}
                    onClick={() => this.onDone()}>
                    Return home
                  </UrlifeButton>
                </div>
              </div>

       </div>
    )
  }
}

Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  fileBatch: PropTypes.object,
  fileProgress: PropTypes.array,
  hasStatusBar: PropTypes.bool,
  headerText: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  project: PropTypes.object,
  skinData: PropTypes.object,
  subHeaderText: PropTypes.string.isRequired
}

Confirmation.defaultProps = {
  hasStatusBar: false,
  headerText: '',
  infoText: '',
  project: null,
  skinData: null,
  subHeaderText: ''
}

const mapStateToProps = state => {
  return {
    fileBatch: state.fileBatch,
    fileProgress: Object.values(state.fileProgress),
    skinData: state.skinning.skinData
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Confirmation))
