import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  BackArrow
} from '../common/SVG'
import { commonStyles, history } from '../../helpers'
import { Switch } from '@material-ui/core'
import classNames from 'classnames'
import styled from 'styled-components'
import offImg0 from '../../assets/img/curation-expanded/off-0.png'
import offImg1 from '../../assets/img/curation-expanded/off-1.png'
import offImg2 from '../../assets/img/curation-expanded/off-2.png'
import offImg3 from '../../assets/img/curation-expanded/off-3.png'
import offImg4 from '../../assets/img/curation-expanded/off-4.png'
import offImg5 from '../../assets/img/curation-expanded/off-5.png'
import offImg6 from '../../assets/img/curation-expanded/off-6.png'
import offImg7 from '../../assets/img/curation-expanded/off-7.png'
import offImg8 from '../../assets/img/curation-expanded/off-8.png'
import offImg9 from '../../assets/img/curation-expanded/off-9.png'
import offImg10 from '../../assets/img/curation-expanded/off-10.png'
import offImg11 from '../../assets/img/curation-expanded/off-11.png'
import offImg12 from '../../assets/img/curation-expanded/off-12.png'
import offImg13 from '../../assets/img/curation-expanded/off-13.png'
import offImg14 from '../../assets/img/curation-expanded/off-14.png'
import offImg15 from '../../assets/img/curation-expanded/off-15.png'
import offImg16 from '../../assets/img/curation-expanded/off-16.png'
import offImg17 from '../../assets/img/curation-expanded/off-17.png'
import offImg18 from '../../assets/img/curation-expanded/off-18.png'
import offImg19 from '../../assets/img/curation-expanded/off-19.png'
import offImg20 from '../../assets/img/curation-expanded/off-20.png'
import offImg21 from '../../assets/img/curation-expanded/off-21.png'
import offImg22 from '../../assets/img/curation-expanded/off-22.png'
import offImg23 from '../../assets/img/curation-expanded/off-23.png'
import offImg24 from '../../assets/img/curation-expanded/off-24.png'
import offImg25 from '../../assets/img/curation-expanded/off-25.png'
import offImg26 from '../../assets/img/curation-expanded/off-26.png'
import offImg27 from '../../assets/img/curation-expanded/off-27.png'
import offImg28 from '../../assets/img/curation-expanded/off-28.png'
import offImg29 from '../../assets/img/curation-expanded/off-29.png'
import offImg30 from '../../assets/img/curation-expanded/off-30.png'
import offImg31 from '../../assets/img/curation-expanded/off-31.png'
import onImg3 from '../../assets/img/curation-expanded/on-3.png'
import onImg5 from '../../assets/img/curation-expanded/on-5.png'
import onImg9 from '../../assets/img/curation-expanded/on-9.png'
import onImg11 from '../../assets/img/curation-expanded/on-11.png'
import onImg12 from '../../assets/img/curation-expanded/on-12.png'
import onImg13 from '../../assets/img/curation-expanded/on-13.png'
import onImg19 from '../../assets/img/curation-expanded/on-19.png'
import onImg20 from '../../assets/img/curation-expanded/on-20.png'
import onImg23 from '../../assets/img/curation-expanded/on-23.png'
import onImg24 from '../../assets/img/curation-expanded/on-24.png'
import onImg28 from '../../assets/img/curation-expanded/on-28.png'

/* eslint-disable sort-keys */
const styles = {
  curationContainer: {
    marginTop: -40,
    ...commonStyles.media(575, {
      marginTop: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 0
    })
  },
  curationHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  curationTitle: {
    margin: 0,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '72px'
    })
  },
  backArrow: {
    color: '#333',
    cursor: 'pointer',
    position: 'absolute',
    left: 5,
    top: 25
  },
  infoHeader: {
    marginTop: 30,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: '0.5px'
    })
  },
  infoText: {
    marginTop: 10,
    ...commonStyles.text({
      color: '#323232',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '26px'
    })
  },
  switchBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40
  },
  switchText: {
    ...commonStyles.text({
      color: '#989898',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.64px',
      textTransform: 'uppercase',
      '&._selected': {
        color: '#323232'
      }
    })
  },
  switchTrack: {
    backgroundColor: '#dddddd',
    opacity: 1
  },
  switchThumb: {
    color: '#01b7d7'
  },
  tileView: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '-4px',
    marginTop: 14
  },
  tileViewItem: {
    position: 'relative',
    width: 'calc(12.5% - 8px)',
    paddingTop: 'calc(12.5% - 8px)',
    margin: 4,
    ...commonStyles.media(991, {
      width: 'calc(16.666666% - 8px)',
      paddingTop: 'calc(16.666666% - 8px)'
    }),
    ...commonStyles.media(767, {
      width: 'calc(20% - 8px)',
      paddingTop: 'calc(20% - 8px)'
    }),
    ...commonStyles.media(575, {
      width: 'calc(25% - 8px)',
      paddingTop: 'calc(25% - 8px)'
    })
  },
  tileViewImageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    transition: 'transform .5s',
    transform: 'scale(1)',
    '&._hidden': {
      transform: 'scale(0)'
    }
  },
  tileViewImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity .5s',
    opacity: 1,
    '&._hidden': {
      opacity: 0
    }
  }
}

const offUrls = [
  offImg0,
  offImg1,
  offImg2,
  offImg3,
  offImg4,
  offImg5,
  offImg6,
  offImg7,
  offImg8,
  offImg9,
  offImg10,
  offImg11,
  offImg12,
  offImg13,
  offImg14,
  offImg15,
  offImg16,
  offImg17,
  offImg18,
  offImg19,
  offImg20,
  offImg21,
  offImg22,
  offImg23,
  offImg24,
  offImg25,
  offImg26,
  offImg27,
  offImg28,
  offImg29,
  offImg30,
  offImg31
]

const onUrls = {
  3: onImg3,
  5: onImg5,
  9: onImg9,
  11: onImg11,
  12: onImg12,
  13: onImg13,
  19: onImg19,
  20: onImg20,
  23: onImg23,
  24: onImg24,
  28: onImg28
}
/* eslint-enable sort-keys */
const onImages = [3, 5, 9, 11, 12, 13, 19, 20, 23, 24, 28]

const TileViewImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
  border-radius: 2px;
  transition: transform .5s;
  transform: translate(${props => props.xOffset}px, ${props => props.yOffset}px)
`

class CurationExpanded extends React.Component {
  constructor (props) {
    super(props)

    const images = []
    for (let i = 0; i < 32; i++) {
      const image = {
        offUrl: offUrls[i],
        onUrl: null,
        ref: React.createRef(),
        x: 0,
        y: 0
      }
      if (onUrls[i]) {
        image.onUrl = onUrls[i]
      }
      images.push(image)
    }

    this.state = {
      curationOn: false,
      images: images
    }
  }

  onResize () {
    const { curationOn, images } = this.state

    images.forEach(image => {
      const element = image.ref.current
      if (element) {
        const boundingRect = element.getBoundingClientRect()
        image.x = boundingRect.x
        image.y = boundingRect.y
      }
    })

    if (curationOn) {
      this.forceUpdate()
    }
  }

  componentDidMount () {
    this.onResize()
    window.addEventListener('resize', this.onResize.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize.bind(this))
  }

  goBack () {
    history.goBack()
  }

  toggleCuration (e, checked) {
    this.setState({ curationOn: checked })
  }

  render () {
    const { classes } = this.props
    const { curationOn, images } = this.state
    return (
      <div className={classes.curationContainer}>
        <div className={classes.curationHeader}>
          <BackArrow className={classes.backArrow} onClick={this.goBack} />
          <h2 className={classes.curationTitle}>Curation</h2>
          <div className={classes.infoHeader}>
            Say goodbye to digital disorder!
          </div>
          <div className={classes.infoText}>
            Your content - all the best in one place, hand-selected, organized and enhanced
          </div>
          <div className={classes.switchBlock}>
            <div className={classNames(classes.switchText, { _selected: !curationOn })}>
              Curation Off
            </div>
            <Switch
              checked={curationOn}
              onChange={this.toggleCuration.bind(this)}
              classes={{
                thumb: classes.switchThumb,
                track: classes.switchTrack
              }}
            />
            <div className={classNames(classes.switchText, { _selected: curationOn })}>
              Curation On
            </div>
          </div>
        </div>
        <div className={classes.tileView}>
          {images.map((image, index) => {
            let xOffset = 0
            let yOffset = 0
            if (curationOn && image.onUrl) {
              const newIndex = onImages.findIndex(e => e === index)
              if (newIndex !== -1) {
                xOffset = images[newIndex].x - image.x
                yOffset = images[newIndex].y - image.y
              }
            }
            return (
              <div key={index} ref={image.ref} className={classes.tileViewItem}>
                <div
                  className={
                    classNames(classes.tileViewImageContainer, { _hidden: curationOn && !image.onUrl })
                  }
                >
                  <div
                    className={
                      classNames(classes.tileViewImage, { _hidden: curationOn && image.onUrl })
                    }
                  >
                    <TileViewImage url={image.offUrl} xOffset={xOffset} yOffset={yOffset} />
                  </div>
                  {image.onUrl &&
                  <div
                    className={
                      classNames(classes.tileViewImage, { _hidden: !curationOn })
                    }
                  >
                    <TileViewImage url={image.onUrl} xOffset={xOffset} yOffset={yOffset} />
                  </div>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
CurationExpanded.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CurationExpanded)
