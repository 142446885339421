import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { commonStyles, getPackageType, history } from '../../helpers'
import { withStyles } from '@material-ui/core'
import {
  deleteProject,
  downloadErrorMessage,
  downloadFile,
  downloadFilesSize,
  fetchAllCuratedFiles,
  fetchAllProjectFiles,
  finishedFiles,
  getPackageSku,
  projectInfo,
  setSkinId,
  setTopBarHeaders,
  setTopBarType,
  updateProjectSku
} from '../../actions'
import VideoPlayerPreview from '../common/VideoPlayerPreview'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeConfirmDialog from '../common/UrlifeConfirmDialog'
import MovieList from './MovieList'
import { api, PACKAGE_TYPES, PROJECT_STATUSES, ROLES } from '../../constants'
import FilesView from '../common/files/FilesView'
import StickyBottomBar from '../common/StickyBottomBar'
import classNames from 'classnames'
import ProjectTitleRow from '../common/ProjectTitleRow'
import ProjectDetailsBox from '../common/ProjectDetailsBox'
import ProjectStatusBar from '../common/ProjectStatusBar/ProjectStatusBar'
import { DownloadIcon } from '../common/SVG'
import UploadMsg from '../common/UploadMsg'

/* eslint-disable sort-keys */
const styles = {
  pageContainer: {
    marginTop: 0,
    ...commonStyles.media(575, {
      marginTop: 30
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 30
    })
  },
  titleRowBlock: {
    marginTop: -20,
    marginBottom: 40
  },
  container: { ...commonStyles.container() },
  movieContainer: {
    backgroundColor: '#000',
    marginBottom: 40,
    borderRadius: 3,
    position: 'relative'
  },
  movieHeader: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.47,
    color: '#323232',
    textTransform: 'uppercase',
    marginBottom: 15
  },
  infoText: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    color: '#323232',
    textAlign: 'center',
    marginBottom: 10
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '15px 0',
    '&._sticky': {
      padding: 0
    },
    '&._upgrade': {
      flexDirection: 'column'
    }
  },
  button: {
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  },
  rejectButton: {
    color: '#01b7d7',
    background: 'none',
    '&:hover': {
      color: '#fff',
      background: '#01b7d7'
    }
  },
  approveButton: {
    color: '#fff',
    background: '#01b7d7',
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    }
  },
  previousBlock: {
    width: '100%',
    marginTop: 60
  },
  detailsBlock: {
    width: '100%',
    marginTop: 60,
    marginBottom: 20
  },
  rejectedBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&._dialog': {
      alignItems: 'flex-start',
      width: 540,
      marginTop: 10,
      marginBottom: -20,
      ...commonStyles.media(767, {
        width: 400
      }),
      ...commonStyles.media(575, {
        width: 260
      })
    }
  },
  rejectionReasonText: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#323232',
    lineHeight: '26px',
    '&._question': {
      marginBottom: 10
    },
    '&._answer': {
      marginBottom: 30,
      opacity: 0.8
    }
  },
  rejectedText: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    color: '#d70101',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  downloadStatusContainer: {
    position: 'fixed',
    left: 20,
    bottom: 20
  },
  downloadIcon: {
    zIndex: 5,
    position: 'absolute',
    top: 15,
    right: 15,
    width: 22,
    height: 22,
    cursor: 'pointer'
  }
}
/* eslint-enable sort-keys */

class Movie extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dialogState: 'closed',
      error: '',
      errorFileName: '',
      movie: null,
      project: null,
      projectSkuIndex: 0,
      rejectMovieIndex: 0
    }
    this.videoJsOptions = {
      aspectRatio: '16:9',
      autoplay: false,
      controls: true,
      fluid: true,
      html5: {
        vhs: {
          withCredentials: true
        }
      },
      preload: 'none'
    }
  }

  pushMakeChangesPage () {
    const { movie, project, projectSkuIndex } = this.state
    history.push(
      '/projects/' + project.id +
      '/skus/' + project.projectSkus[projectSkuIndex].id +
      (movie ? '/files/' + movie.id : '') +
      '/movie-changes'
    )
  }

  makeChanges () {
    const { project, projectSkuIndex } = this.state
    const { packages } = this.props
    const packageType = getPackageType(
      packages,
      project.projectSkus[projectSkuIndex].packageId
    )
    if (packageType === PACKAGE_TYPES.HOLLYWOOD) {
      this.setState({ dialogState: 'schedule' })
    } else {
      this.pushMakeChangesPage()
    }
  }

  onApprove () {
    this.setState({ dialogState: 'confirm' })
  }

  ignoreDialog () {
    this.setState({ dialogState: 'closed' })
  }

  confirmApprove () {
    const { project, projectSkuIndex } = this.state
    const { updateProjectSkuFunction } = this.props
    this.setState({ dialogState: 'closed' }, () => {
      updateProjectSkuFunction(
        project.id,
        { id: project.projectSkus[projectSkuIndex].id, statusId: PROJECT_STATUSES.COMPLETE }
      )
        .then(
          data => {
            project.projectSkus[projectSkuIndex] = data
            this.setState({ project })
            this.updateHeaders()
          }
        )
    })
  }

  scheduleCall () {
    const { project, projectSkuIndex } = this.state
    const projectSkuId = project.projectSkus[projectSkuIndex].id
    history.push('/projects/' + project.id + '/skus/' + projectSkuId + '/movie-schedule-call')
  }

  upgradeCuration () {
    // TODO: Do something useful here
    this.setState({ dialogState: 'comingsoon' })
  }

  showRejectionReasons (movieIndex) {
    this.setState({
      dialogState: 'rejection',
      rejectMovieIndex: movieIndex
    })
  }

  getCategoryName (categoryId) {
    const { categories } = this.props
    const cat = categories.filter(catItem => catItem.id === categoryId)
    return cat.length ? cat[0].name : ''
  }

  getMovieUrl () {
    const { movie } = this.state
    if (!movie) {
      return false
    }
    if (movie.webview) {
      return movie.webviewCdnUrl
    }
    return movie.cdnUrl || movie.signedUrl
  }

  fetchInitialData () {
    const {
      match,
      user,
      getPackageSkuFunction,
      projectInfoFunction,
      fetchAllProjectFilesFunction,
      fetchAllCuratedFilesFunction,
      finishedFilesFunction,
      setSkinIdFunction
    } = this.props
    const { projectId, projectSkuId } = match.params
    const isAdminOrProducer = user && (user.roleId === ROLES.ADMIN || user.roleId === ROLES.PRODUCER)

    projectInfoFunction(projectId)
      .then(
        data => {
          let projectSkuIndex = data.projectSkus.findIndex(sku => sku.id === projectSkuId)
          if (projectSkuIndex === -1) {
            projectSkuIndex = 0
          }
          getPackageSkuFunction(data.projectSkus[projectSkuIndex].packageSku).then(
            data => {
              setSkinIdFunction(data.skinId)
            }
          )
          this.setState({ project: data, projectSkuIndex })
          this.updateHeaders()
          fetchAllCuratedFilesFunction(projectId, projectSkuId)
          if (isAdminOrProducer) {
            fetchAllProjectFilesFunction(projectId, projectSkuId)
          }
        }
      ).catch(e => { /* Ignore this error. */ })
    finishedFilesFunction(projectId, projectSkuId, { sort: 'updated_at' })
      .then(
        data => {
          this.setState({ movie: data.data[0] })
          this.updateHeaders()
        }
      ).catch(e => { /* Ignore this error. */ })
  }

  isWaitingForApproval () {
    const { project, projectSkuIndex, movie } = this.state
    const { finishedMovieFiles, packages } = this.props
    const projectSku = project ? project.projectSkus[projectSkuIndex] : null

    if (!projectSku) {
      return false
    } else if (getPackageType(packages, projectSku.packageId) === PACKAGE_TYPES.CURATED) {
      return projectSku.statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
    } else {
      return projectSku.statusId === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL &&
        movie && finishedMovieFiles[0] &&
        finishedMovieFiles[0].id === movie.id
    }
  }

  isComplete () {
    const { project, projectSkuIndex } = this.state
    const projectSku = project ? project.projectSkus[projectSkuIndex] : null

    if (!projectSku) {
      return false
    } else {
      return projectSku.statusId === PROJECT_STATUSES.COMPLETE
    }
  }

  updateHeaders () {
    const {
      user,
      finishedMovieFiles,
      setTopBarTypeFunction,
      setTopBarHeadersFunction,
      packages
    } = this.props
    const { project, projectSkuIndex } = this.state
    const waitingApproval = this.isWaitingForApproval()
    const isAdminOrProducer = user && (user.roleId === ROLES.ADMIN || user.roleId === ROLES.PRODUCER)

    setTopBarTypeFunction(waitingApproval && !isAdminOrProducer ? 'project' : 'normal')

    if (waitingApproval && !isAdminOrProducer) {
      const packageType = getPackageType(packages, project.projectSkus[projectSkuIndex].packageId)
      if (packageType === PACKAGE_TYPES.CURATED) {
        setTopBarHeadersFunction(project.title, 'Review your completed project')
      } else {
        const header = finishedMovieFiles.length > 1
          ? 'Revised cut for your review'
          : finishedMovieFiles.length === 1
            ? 'Preview your first cut!'
            : ''
        setTopBarHeadersFunction(project.title, header)
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.match.params.projectId !== prevProps.match.params.projectId) {
      this.setState({
        movie: null,
        project: null
      })
      this.fetchInitialData()
    }
  }

  componentDidMount () {
    this.updateHeaders()
    this.fetchInitialData()
  }

  componentWillUnmount () {
    const { setSkinIdFunction } = this.props
    setSkinIdFunction(null)
  }

  async onMovieDownload (movieIndex) {
    const { finishedMovieFiles, clientDownloadFunction } = this.props
    const { project } = this.state
    const finishedMovie = finishedMovieFiles && finishedMovieFiles.length > movieIndex
      ? finishedMovieFiles[movieIndex]
      : null
    if (!finishedMovie) { return }
    try {
      await clientDownloadFunction({
        id: finishedMovie.id,
        name: project.title + '.mp4',
        projectId: project.id,
        role: 'finished',
        size: finishedMovie.fileSize,
        url: finishedMovie.signedUrl
      })
    } catch (e) {
      this.setState({
        error: downloadErrorMessage(e),
        errorFileName: project.title + '.mp4'
      })
    }
  }

  async downloadProject () {
    const {
      clientDownloadFunction,
      downloadFilesSizeFunction
    } = this.props
    const { project } = this.state
    const data = {
      queryParams: {
        include: ['curated', 'movie']
      },
      url: api.DOWNLOAD_SELECTED_FILES,
      urlVars: {
        projectId: project.id
      }
    }

    try {
      const res = await downloadFilesSizeFunction(project.id, data.queryParams)
      if (res.totalSize > 0 && res.numFiles > 0) {
        await clientDownloadFunction({
          ...data,
          name: project.title + '.zip',
          projectId: project.id,
          role: 'zip',
          total: res.totalSize
        }, true)
      }
    } catch (e) {
      this.setState({
        error: downloadErrorMessage(e),
        errorFileName: project.title + '.zip'
      })
    }
  }

  async deleteProject () {
    const { deleteProjectFunction } = this.props
    const { project } = this.state
    if (project) {
      await deleteProjectFunction(project.id)
    }
  }

  closeErrorPopup () {
    this.setState({ error: '', errorFileName: '' })
  }

  renderErrorPopup () {
    const { error, errorFileName } = this.state
    if (error) {
      return (
        <UploadMsg
          title={''}
          specText={error}
          warningText={errorFileName}
          onClose={this.closeErrorPopup.bind(this)}
        />
      )
    }
    return null
  }

  renderDownloadProgress () {
    const { classes } = this.props
    const { project } = this.state
    return (
      <div className={classes.downloadStatusContainer}>
        <ProjectStatusBar project={project} type={'standalone'} hideInactive={true}/>
      </div>
    )
  }

  renderDialog () {
    const { classes, finishedMovieFiles } = this.props
    const { dialogState, rejectMovieIndex } = this.state
    const rejectMovie = finishedMovieFiles[rejectMovieIndex]
    switch (dialogState) {
      case 'confirm':
        return (
          <UrlifeConfirmDialog
            header={'Last chance for changes!'}
            text={'Once approved, we will no longer be able to edit this movie.'}
            onIgnore={this.ignoreDialog.bind(this)}
            cancelLabel={'Make changes'}
            onCancel={this.makeChanges.bind(this)}
            confirmLabel={'Approve'}
            onConfirm={this.confirmApprove.bind(this)}
          />
        )
      case 'schedule':
        return (
          <UrlifeConfirmDialog
            header={'Would you prefer to make these changes via a phone call or leave notes in the app?'}
            onIgnore={this.ignoreDialog.bind(this)}
            cancelLabel={'Schedule phone call'}
            onCancel={this.scheduleCall.bind(this)}
            confirmLabel={'Leave notes'}
            onConfirm={this.pushMakeChangesPage.bind(this)}
          />
        )
      case 'comingsoon':
        return (
          <UrlifeConfirmDialog
            header={'Coming soon'}
            onIgnore={this.ignoreDialog.bind(this)}
            confirmLabel={'OK'}
            onConfirm={this.ignoreDialog.bind(this)}
          />
        )
      case 'rejection':
        return (
          <UrlifeConfirmDialog
            header={'Rejection reasons'}
            onIgnore={this.ignoreDialog.bind(this)}
            confirmLabel={'OK'}
            onConfirm={this.ignoreDialog.bind(this)}
          >
            <div className={classNames(classes.rejectedBlock, '_dialog')}>
              {rejectMovie.rejectionReason.map((reason, index) => {
                return (
                  <div key={index} className={classes.rejectionReasonDisplay}>
                    <div className={classNames(classes.rejectionReasonText, '_question')}>
                      {reason.question}
                    </div>
                    <div className={classNames(classes.rejectionReasonText, '_answer')}>
                      {reason.answer}
                    </div>
                  </div>
                )
              })}
            </div>
          </UrlifeConfirmDialog>
        )
      default:
        return null
    }
  }

  renderMovie () {
    const { classes, curatedFiles, finishedMovieFiles } = this.props
    const { project } = this.state
    const moreMovies = finishedMovieFiles.length > 1
    const waitingApproval = this.isWaitingForApproval()
    const isComplete = this.isComplete()
    const movieUrl = this.getMovieUrl()
    return (
      <div className={classes.pageContainer}>
        {moreMovies && waitingApproval &&
        <div className={classes.movieHeader}>
          Most recent
        </div>
        }
        {!waitingApproval &&
        <div className={classes.titleRowBlock}>
          <ProjectTitleRow
            project={project}
            canEdit={false}
            downloadProject={isComplete ? this.downloadProject.bind(this) : null}
            deleteProject={this.deleteProject.bind(this)}
          />
        </div>
        }
        <div className={classes.movieContainer}>
          <div className={classes.container}>
            {movieUrl &&
              <VideoPlayerPreview
                {...this.videoJsOptions}
                video={movieUrl}
                sources={[{ src: movieUrl }]}
                wrapperWidth={'100%'}
              />
            }
          </div>
          {!waitingApproval &&
          <DownloadIcon className={classes.downloadIcon} onClick={this.onMovieDownload.bind(this, 0)} />
          }
        </div>

        {waitingApproval &&
        <div className={classes.container}>
          <div className={classes.infoText}>
            {
              moreMovies
                ? 'Let’s see how we did. Still missing something - or approved?'
                : 'Watch the magic unfold. Approve - or tell us how to make it even better'
            }
          </div>

          <div className={classes.buttonBlock}>
            <UrlifeButton
              className={classNames(classes.button, classes.rejectButton)}
              onClick={this.makeChanges.bind(this)}>
              Make changes
            </UrlifeButton>
            <UrlifeButton
              className={classNames(classes.button, classes.approveButton)}
              onClick={this.onApprove.bind(this)}>
              Approve
            </UrlifeButton>
          </div>
        </div>
        }
        {moreMovies && waitingApproval &&
          <div className={classes.previousBlock}>
            <div className={classes.movieHeader}>
              Previous
            </div>
            <MovieList movieList={finishedMovieFiles} />
          </div>
        }
        {!waitingApproval &&
        <div className={classes.detailsBlock}>
          <ProjectDetailsBox
            title={'Details'}
            show={['info', 'prompts', 'stars']}
            project={project}
            canEdit={false}
            columnLayout={'double'} />
        </div>
        }
        {!waitingApproval && curatedFiles && curatedFiles.length > 0 &&
        <FilesView
          type={'tile'}
          edit={true}
          project={project}
          files={curatedFiles}
          canUpload={false}
          isCurated={true}
          title={'Curated files'} />
        }
        {this.renderDialog()}
        {this.renderDownloadProgress()}
        {this.renderErrorPopup()}
      </div>
    )
  }

  renderCuration () {
    const { classes, curatedFiles } = this.props
    const { project } = this.state
    const waitingApproval = this.isWaitingForApproval()
    const isComplete = this.isComplete()
    return (
      <div className={classes.pageContainer}>
        {waitingApproval &&
        <div className={classes.infoText}>
          Please approve - or tell us how to make it even better
        </div>
        }
        {!waitingApproval &&
        <div className={classes.titleRowBlock}>
          <ProjectTitleRow
            project={project}
            canEdit={false}
            downloadProject={isComplete ? this.downloadProject.bind(this) : null}
            deleteProject={this.deleteProject.bind(this)}
          />
        </div>
        }
        <FilesView
          type={'tile'}
          edit={true}
          project={project}
          files={curatedFiles}
          canUpload={false}
          isCurated={true}
          title={waitingApproval ? '' : 'Files'} />
        {waitingApproval &&
        <StickyBottomBar>
          <div className={classNames(classes.buttonBlock, '_sticky')}>
            <UrlifeButton
              className={classNames(classes.button, classes.rejectButton)}
              onClick={this.makeChanges.bind(this)}>
              Make changes
            </UrlifeButton>
            <UrlifeButton
              className={classNames(classes.button, classes.approveButton)}
              onClick={this.onApprove.bind(this)}>
              Approve
            </UrlifeButton>
          </div>
        </StickyBottomBar>
        }
        {!waitingApproval &&
        <div className={classNames(classes.buttonBlock, '_upgrade')}>
          <div className={classes.infoText}>
            Turn these memories into a personalized movie
          </div>
          <UrlifeButton
            className={classNames(classes.button, classes.rejectButton)}
            onClick={this.upgradeCuration.bind(this)}>
            Upgrade
          </UrlifeButton>
        </div>
        }
        {this.renderDialog()}
        {this.renderDownloadProgress()}
      </div>
    )
  }

  renderProducerView () {
    const { classes, files, curatedFiles, finishedMovieFiles } = this.props
    const { project, movie } = this.state
    const moreMovies = finishedMovieFiles.length > 1
    const video = this.getMovieUrl()
    return (
      <div className={classes.pageContainer}>
        <div className={classes.titleRowBlock}>
          <ProjectTitleRow project={project} canEdit={false} />
        </div>
        {movie &&
        <React.Fragment>
          {moreMovies &&
          <div className={classes.movieHeader}>
            Most recent
          </div>
          }
          <div className={classes.movieContainer}>
            <div className={classes.container}>
              {video &&
                <VideoPlayerPreview
                  {...this.videoJsOptions}
                  video={video}
                  sources={[{ src: video }]}
                  wrapperWidth={'100%'}
                />
              }
            </div>
            <DownloadIcon className={classes.downloadIcon} onClick={this.onMovieDownload.bind(this, 0)} />
          </div>
          {movie.rejectionReason &&
          <div className={classes.rejectedBlock}>
            <div className={classes.rejectedText}>
              Rejected
            </div>
            <UrlifeButton
              className={classNames(classes.button, classes.approveButton)}
              onClick={this.showRejectionReasons.bind(this, 0)}>
              See reasons
            </UrlifeButton>
          </div>
          }
          {moreMovies &&
          <div className={classes.previousBlock}>
            <div className={classes.movieHeader}>
              Previous
            </div>
            <MovieList
              movieList={finishedMovieFiles}
              showRejectionReasons={this.showRejectionReasons.bind(this)} />
          </div>
          }
        </React.Fragment>
        }
        <div className={classes.buttonBlock}>
          <UrlifeButton
            className={classNames(classes.button, classes.approveButton)}
            onClick={() => { history.push('/projects/' + project.id + '/summary') }}>
            Project details
          </UrlifeButton>
        </div>
        {curatedFiles && curatedFiles.length > 0 &&
        <FilesView
          type={'tile'}
          edit={true}
          project={project}
          files={curatedFiles}
          canUpload={true}
          isCurated={true}
          title={'Curated files'} />
        }
        {files && files.length > 0 &&
        <FilesView
          type={'tile'}
          edit={false}
          project={project}
          files={files}
          canUpload={false}
          isCurated={false}
          title={'Customer files'} />
        }
        {this.renderDialog()}
        {this.renderDownloadProgress()}
        {this.renderErrorPopup()}
      </div>
    )
  }

  render () {
    const { user, packages } = this.props
    const { project, projectSkuIndex, movie } = this.state
    const isAdminOrProducer = user && (user.roleId === ROLES.ADMIN || user.roleId === ROLES.PRODUCER)
    const packageType = !!project && getPackageType(packages, project.projectSkus[projectSkuIndex].packageId)
    if (project) {
      if (isAdminOrProducer) {
        return this.renderProducerView()
      } else {
        if (movie) {
          return this.renderMovie()
        } else if (packageType === PACKAGE_TYPES.CURATED) {
          return this.renderCuration()
        }
      }
    }
    return null
  }
}

function mapStateToProps (state) {
  return {
    categories: state.category.categories,
    curatedFiles: state.file.curatedFiles,
    files: state.file.files,
    finishedMovieFiles: state.file.finishedMovieFiles,
    packages: state.packages.packages,
    user: state.user
  }
}
Movie.propTypes = {
  categories: PropTypes.array,
  classes: PropTypes.object.isRequired,
  clientDownloadFunction: PropTypes.func.isRequired,
  curatedFiles: PropTypes.array,
  deleteProjectFunction: PropTypes.func.isRequired,
  downloadFilesSizeFunction: PropTypes.func.isRequired,
  fetchAllCuratedFilesFunction: PropTypes.func.isRequired,
  fetchAllProjectFilesFunction: PropTypes.func.isRequired,
  files: PropTypes.array,
  finishedFilesFunction: PropTypes.func.isRequired,
  finishedMovieFiles: PropTypes.array,
  getPackageSkuFunction: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  packages: PropTypes.array,
  projectInfoFunction: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  setTopBarTypeFunction: PropTypes.func.isRequired,
  updateProjectSkuFunction: PropTypes.func.isRequired,
  user: PropTypes.object
}

function mapDispatchToProps (dispatch) {
  return {
    clientDownloadFunction: function (fileData, needAuth = false) {
      return dispatch(downloadFile(fileData, needAuth))
    },
    deleteProjectFunction: function (projectId) {
      return dispatch(deleteProject(projectId))
    },
    downloadFilesSizeFunction: function (projectId, params, needAuth = false) {
      return dispatch(downloadFilesSize(projectId, params, needAuth))
    },
    fetchAllCuratedFilesFunction: function (projectId, projectSkuId) {
      dispatch(fetchAllCuratedFiles(projectId, projectSkuId))
    },
    fetchAllProjectFilesFunction: function (projectId, projectSkuId) {
      dispatch(fetchAllProjectFiles(projectId, projectSkuId))
    },
    finishedFilesFunction: function (projectId, projectSkuId, params) {
      return dispatch(finishedFiles(projectId, projectSkuId, params))
    },
    getPackageSkuFunction: function (id) {
      return dispatch(getPackageSku(id))
    },
    projectInfoFunction: function (projectId) {
      return dispatch(projectInfo(projectId))
    },
    setSkinIdFunction: function (skinId) {
      dispatch(setSkinId(skinId))
    },
    setTopBarHeadersFunction: function (projectTitle, header) {
      dispatch(setTopBarHeaders(projectTitle, header))
    },
    setTopBarTypeFunction: function (type) {
      dispatch(setTopBarType(type))
    },
    updateProjectSkuFunction: function (projectId, projectSku) {
      return dispatch(updateProjectSku(projectId, projectSku))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Movie))
