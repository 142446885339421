import React from 'react'
import { inherits } from '../../helpers/'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ParallaxPlayIcon } from '../common/SVG'

/* eslint-disable sort-keys */
const styles = inherits({
})({
  playIcon: {
    position: 'relative',
    display: 'inline-block',
    width: '15%',
    padding: 14,
    height: 'auto',
    margin: 'auto'
  },
  textBox: {
    position: 'absolute',
    bottom: 15,
    left: 15
  },
  title: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'left',
    margin: 0
  },
  thumbnailFilter: {
    position: 'absolute',
    left: 0,
    top: '70%',
    right: 0,
    bottom: 0,
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 95%)'
  }
})
/* eslint-enable sort-keys */

const MovieContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  display: flex;
  width: 360px;
  height: 216px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  background-color: #01b7d7;
  border-radius: 3px;
  @media (max-width: 599px) {
    width: 300px;
    height: 180px;
  };
`

export const ExploreMovie = ({ ...props }) => {
  const { video, classes, showPlayIcon } = props
  return (
    <MovieContainer thumbnail={video.thumbnailUrl}>
      <div className={classes.thumbnailFilter}>
      </div>
      {showPlayIcon && <ParallaxPlayIcon className={classes.playIcon}/>}
      <div className={classes.textBox}>
        <p className={classes.title}>{video.title}</p>
      </div>
    </MovieContainer>
  )
}

ExploreMovie.propTypes = {
  classes: PropTypes.object.isRequired,
  showPlayIcon: PropTypes.bool,
  video: PropTypes.object.isRequired
}

ExploreMovie.defaultProps = {
  showPlayIcon: true
}

export default withStyles(styles)(ExploreMovie)
