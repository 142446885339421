import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import UrlifeDialogSuccess from '../common/UrlifeDialogSuccess'
import ValidateForm from '../common/form/ValidateForm'
import { getUserInvite, toggleModal } from '../../actions'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  registrationHeader: {
    textAlign: 'left',
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    padding: '40px 50px 0px 50px',
    ...commonStyles.media(480, {
      fontSize: 16,
      padding: '20px 20px 0px 20px'
    })
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 22px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  buttonHolder: {
    flexDirection: 'column',
    padding: '0 50px',
    justifyContent: 'center',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  submitButton: {
    margin: 0,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  loginButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '20px 0',
    textTransform: 'none'
  }
}
/* eslint-enable sort-keys */

class UrlifeInviteDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      formStatus: '' // '', sending, success, fail
    }

    this.send = () => (event) => {
      const { userEmail, fullName } = this.state.fields
      this.setState({
        formStatus: 'sending'
      }, () => {
        this.props.getUserInvite(userEmail, fullName)
          .then(
            data => {
              this.setState({
                formStatus: data.status || 'fail'
              })
            }
          )
          .catch(e => {
            this.setState({
              formStatus: 'fail'
            })
          })
      })
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  closeModal () {
    this.props.toggleModal(false, null)
  }

  render () {
    const { fields, formStatus } = this.state
    const { classes, loginSwitch } = this.props
    return (
      <div>
        <DialogTitle
          disableTypography
          className={classes.registrationHeader}>
          {formStatus === 'success'
            ? 'Thank you for your interest'
            : 'Request invite'}
        </DialogTitle>

        {formStatus === 'success' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            className={classes.successText}
            message={'An email will be sent when space is available for you to join the beta.'} />

          <UrlifeButton
            onClick={this.closeModal.bind(this)}
            classes={{ root: classes.submitButton }}
          >OK</UrlifeButton>
        </DialogContent>
        }
        {formStatus !== 'success' &&
          <ValidateForm
            onSubmit={this.send()}
            onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
            validateOnMount={true}>
            {(form) => (
              <React.Fragment>
                <DialogContent classes={{ root: classes.content }}>
                  {form.renderField(
                    'fullName',
                    ['required'],
                    (field, fieldData) => {
                      const validationStatus = fields.fullName && fields.fullName.length > 0 ? 'success' : 'none'
                      return (
                        <UrlifeTextField
                          autoFocus
                          margin="dense"
                          name="fullName"
                          label="Full name"
                          type="text"
                          fullWidth
                          defaultValue={fields.fullName || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          validationStatus={validationStatus}
                        />
                      )
                    }
                  )}
                  {form.renderField(
                    'userEmail',
                    ['required', 'email'],
                    (field, fieldData) => {
                      let validationStatus = 'none'
                      let errorMessage = ''
                      if (formStatus === 'fail') {
                        validationStatus = 'error'
                        errorMessage = 'Account already exists for user'
                      } else if (fields.userEmail && fields.userEmail.length > 0) {
                        if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                          validationStatus = 'error'
                          errorMessage = fieldData.errors[0]
                        } else {
                          validationStatus = 'success'
                        }
                      }
                      return (
                        <UrlifeTextField
                          margin="dense"
                          name="userEmail"
                          label="Email"
                          type="email"
                          fullWidth
                          defaultValue={fields.userEmail || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          validationStatus={validationStatus}
                          infoLabel={errorMessage}
                        />
                      )
                    }
                  )}
                </DialogContent>
                <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                  <UrlifeButton
                    type="submit"
                    disabled={formStatus === 'sending' || !form.checkFormValid()}
                    className={classes.submitButton}
                  >
                    Request Invite
                  </UrlifeButton>
                  <Button
                    onClick={loginSwitch}
                    classes={{ root: classes.loginButton }}
                  >
                    Log in
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
          </ValidateForm>
        }
      </div>
    )
  }
}

UrlifeInviteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  getUserInvite: PropTypes.func.isRequired,
  loginSwitch: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    getUserInvite: function (email, fullName) {
      return dispatch(getUserInvite(email, fullName))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(UrlifeInviteDialog))
