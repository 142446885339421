import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  invites,
  acceptInvite,
  deleteInvite
}

function invites (params = {}) {
  return dispatch => {
    return new ApiService(api.INVITES, {}, params, true, dispatch)
      .get()
  }
}

function acceptInvite (inviteId) {
  return dispatch => {
    return new ApiService(api.ONE_INVITE, { inviteId }, {}, true, dispatch)
      .put()
  }
}

function deleteInvite (inviteId) {
  return dispatch => {
    return new ApiService(api.ONE_INVITE, { inviteId }, {}, true, dispatch)
      .delete()
  }
}
