import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  checkConnection,
  getConnectionState
}

function checkConnection (retryCount = 0) {
  return (dispatch) => {
    return new ApiService(api.HEALTH_CHECK, {}, {}, false, dispatch)
      .get()
      .then(
        response => {
          dispatch({ type: 'BACKEND_UP' })
        },
        fail => {
          dispatch({ retryCount, type: 'BACKEND_DOWN' })
        }
      )
  }
}

function getConnectionState () {
  return (dispatch, getStore) => {
    return getStore().front.backend
  }
}
