import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function ProjectCodeV2 ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox="0 0 460.26 462.74">
      <defs>
        <style type="text/css">
          .cls-1{`{
              fill: none;
              stroke: ${color};
              stroke-miterlimit: 10;
              stroke-width: 8px;
            }`}
          .cls-2{`{
            fill: ${color};
            fill-rule="evenodd"
          }`}
        </style>
      </defs>
      <g>
        <path className="cls-1" d="M190.3,296.9L8.78,458.14c-2.8,2.58-7.33,.57-7.28-3.24L4.58,185.29c.02-1.57,2.26-3.89,3.59-3.05l180.3,111.15c1.62,.92,4.09,3.79,1.83,3.52Z"/>
        <path className="cls-1" d="M8.17,182.24l73.88,44.22c.8,.52,.99-1.96,.82-2.9l-2.71-102.01c-.1-.56-72.82,60.51-72.82,60.51-.35,.32,.43-.08,.83,.18Z"/>
        <path className="cls-1" d="M229.24,461.24l-222.58-2.03c-1.87,0,.62-.29,2.05-1.49,10.58-8.9,136.09-122.76,193.45-168.5,14.87-11.86,25.16-19.13,27.68-18.82,4.25,.54,13.78,6.74,26.6,16.6,57.52,44.2,186.22,163.46,197.68,172.29,1.51,1.17-4.16,1.95-6.07,1.95H229.24Z"/>
        <path className="cls-1" d="M383.39,121.55V11.61s1.52-8.55-10.7-7.75L85.89,1.5s-6.62-.04-6.59,14.18l.86,105.87"/>
        <path className="cls-1" d="M269.96,296.9l181.52,161.23c1.4,1.29,3.23,1.43,4.71,.78s2.6-2.11,2.58-4.01l-3.08-269.61c-.02-1.57-2.26-3.89-3.59-3.05l-180.3,111.15c-1.62,.92-4.09,3.79-1.83,3.52Z"/>
        <path className="cls-1" d="M455.28,182.24l-73.88,44.22c-.8,.52-.99-1.96-.82-2.9l2.71-102.01c.1-.56,70.84,58.85,70.84,58.85,.35,.32,1.55,1.58,1.15,1.84Z"/>
      </g>
      <g>
        <path className="cls-2" d="M134.41,89.8c2.6,2.17,3.9,5.15,3.9,8.95s-1.3,6.78-3.9,8.95c-2.6,2.17-6.17,3.25-10.7,3.25h-8.65v10.6h-5v-35h13.65c4.53,0,8.1,1.08,10.7,3.25Zm-3.6,14.77c1.67-1.35,2.5-3.29,2.5-5.82s-.83-4.48-2.5-5.83c-1.67-1.35-4.08-2.02-7.25-2.02h-8.5v15.7h8.5c3.17,0,5.58-.67,7.25-2.02Z"/>
        <path className="cls-2" d="M169.06,121.55l-7.55-10.75c-.93,.07-1.67,.1-2.2,.1h-8.65v10.65h-5v-35h13.65c4.53,0,8.1,1.08,10.7,3.25,2.6,2.17,3.9,5.15,3.9,8.95,0,2.7-.67,5-2,6.9-1.33,1.9-3.23,3.28-5.7,4.15l8.3,11.75h-5.45Zm-2.65-16.95c1.67-1.37,2.5-3.32,2.5-5.85s-.83-4.48-2.5-5.83c-1.67-1.35-4.08-2.02-7.25-2.02h-8.5v15.75h8.5c3.17,0,5.58-.68,7.25-2.05Z"/>
        <path className="cls-2" d="M188.26,119.62c-2.83-1.55-5.06-3.69-6.67-6.43-1.62-2.73-2.43-5.78-2.43-9.15s.81-6.42,2.43-9.15c1.62-2.73,3.84-4.88,6.67-6.43,2.83-1.55,6.02-2.32,9.55-2.32s6.67,.77,9.5,2.32c2.83,1.55,5.05,3.68,6.65,6.4,1.6,2.72,2.4,5.77,2.4,9.17s-.8,6.46-2.4,9.17c-1.6,2.72-3.82,4.85-6.65,6.4-2.83,1.55-6,2.32-9.5,2.32s-6.72-.77-9.55-2.32Zm16.47-3.88c2.05-1.17,3.67-2.78,4.85-4.83,1.18-2.05,1.77-4.34,1.77-6.88s-.59-4.83-1.77-6.88c-1.18-2.05-2.8-3.66-4.85-4.83-2.05-1.17-4.36-1.75-6.92-1.75s-4.89,.58-6.97,1.75c-2.08,1.17-3.72,2.78-4.9,4.83-1.18,2.05-1.77,4.34-1.77,6.88s.59,4.83,1.77,6.88c1.18,2.05,2.82,3.66,4.9,4.83,2.08,1.17,4.41,1.75,6.97,1.75s4.88-.58,6.92-1.75Z"/>
        <path className="cls-2" d="M221.91,120.62c-1.8-.88-3.25-2.12-4.35-3.73l2.9-3.4c1.93,2.77,4.37,4.15,7.3,4.15,1.97,0,3.46-.6,4.48-1.8,1.02-1.2,1.52-2.97,1.52-5.3v-19.65h-12.65v-4.35h17.6v23.75c0,3.87-.92,6.77-2.78,8.73-1.85,1.95-4.57,2.92-8.17,2.92-2.1,0-4.05-.44-5.85-1.32Z"/>
        <path className="cls-2" d="M274.31,117.2v4.35h-25.4v-35h24.7v4.35h-19.7v10.75h17.55v4.25h-17.55v11.3h20.4Z"/>
        <path className="cls-2" d="M288.43,119.62c-2.82-1.55-5.02-3.68-6.62-6.4-1.6-2.72-2.4-5.77-2.4-9.17s.8-6.46,2.4-9.17c1.6-2.72,3.82-4.85,6.65-6.4,2.83-1.55,6-2.32,9.5-2.32,2.73,0,5.23,.46,7.5,1.38,2.27,.92,4.2,2.27,5.8,4.07l-3.25,3.15c-2.63-2.77-5.92-4.15-9.85-4.15-2.6,0-4.95,.58-7.05,1.75-2.1,1.17-3.74,2.78-4.92,4.83-1.18,2.05-1.77,4.34-1.77,6.88s.59,4.83,1.77,6.88c1.18,2.05,2.83,3.66,4.92,4.83,2.1,1.17,4.45,1.75,7.05,1.75,3.9,0,7.18-1.4,9.85-4.2l3.25,3.15c-1.6,1.8-3.54,3.17-5.83,4.1-2.28,.93-4.79,1.4-7.52,1.4-3.5,0-6.66-.77-9.48-2.32Z"/>
        <path className="cls-2" d="M324.96,90.9h-12v-4.35h28.95v4.35h-12v30.65h-4.95v-30.65Z"/>
        <path className="cls-2" d="M165.51,179.62c-2.82-1.55-5.02-3.68-6.62-6.4-1.6-2.72-2.4-5.77-2.4-9.17s.8-6.46,2.4-9.17c1.6-2.72,3.82-4.85,6.65-6.4,2.83-1.55,6-2.32,9.5-2.32,2.73,0,5.23,.46,7.5,1.38,2.27,.92,4.2,2.27,5.8,4.07l-3.25,3.15c-2.63-2.77-5.92-4.15-9.85-4.15-2.6,0-4.95,.58-7.05,1.75-2.1,1.17-3.74,2.78-4.93,4.83-1.18,2.05-1.77,4.34-1.77,6.88s.59,4.83,1.77,6.88c1.18,2.05,2.83,3.66,4.93,4.83,2.1,1.17,4.45,1.75,7.05,1.75,3.9,0,7.18-1.4,9.85-4.2l3.25,3.15c-1.6,1.8-3.54,3.17-5.83,4.1-2.28,.93-4.79,1.4-7.52,1.4-3.5,0-6.66-.77-9.48-2.32Z"/>
        <path className="cls-2" d="M200.98,179.62c-2.83-1.55-5.06-3.69-6.67-6.43-1.62-2.73-2.43-5.78-2.43-9.15s.81-6.42,2.43-9.15c1.62-2.73,3.84-4.88,6.67-6.43,2.83-1.55,6.02-2.32,9.55-2.32s6.67,.77,9.5,2.32c2.83,1.55,5.05,3.68,6.65,6.4,1.6,2.72,2.4,5.77,2.4,9.17s-.8,6.46-2.4,9.17c-1.6,2.72-3.82,4.85-6.65,6.4-2.83,1.55-6,2.32-9.5,2.32s-6.72-.77-9.55-2.32Zm16.47-3.88c2.05-1.17,3.67-2.78,4.85-4.83,1.18-2.05,1.77-4.34,1.77-6.88s-.59-4.83-1.77-6.88c-1.18-2.05-2.8-3.66-4.85-4.83-2.05-1.17-4.36-1.75-6.92-1.75s-4.89,.58-6.97,1.75c-2.08,1.17-3.72,2.78-4.9,4.83-1.18,2.05-1.77,4.34-1.77,6.88s.59,4.83,1.77,6.88c1.18,2.05,2.82,3.66,4.9,4.83,2.08,1.17,4.41,1.75,6.97,1.75s4.88-.58,6.92-1.75Z"/>
        <path className="cls-2" d="M236.73,146.55h14.75c3.7,0,6.98,.73,9.85,2.2,2.87,1.47,5.09,3.52,6.67,6.17s2.38,5.69,2.38,9.12-.79,6.48-2.38,9.12-3.81,4.71-6.67,6.17c-2.87,1.47-6.15,2.2-9.85,2.2h-14.75v-35Zm14.45,30.65c2.83,0,5.32-.55,7.47-1.65s3.81-2.64,4.97-4.62c1.17-1.98,1.75-4.27,1.75-6.88s-.58-4.89-1.75-6.88c-1.17-1.98-2.82-3.52-4.97-4.62s-4.64-1.65-7.47-1.65h-9.45v26.3h9.45Z"/>
        <path className="cls-2" d="M303.43,177.2v4.35h-25.4v-35h24.7v4.35h-19.7v10.75h17.55v4.25h-17.55v11.3h20.4Z"/>
      </g>
    </SvgIcon>
  )
}

ProjectCodeV2.propTypes = {
  color: PropTypes.string
}
