export {
  closeMoreMenu,
  openMoreMenu
}

function openMoreMenu (menu) {
  return dispatch => {
    dispatch({ menu, type: 'SET_MORE_MENU' })
  }
}

function closeMoreMenu () {
  return dispatch => {
    dispatch({ menu: '', type: 'SET_MORE_MENU' })
  }
}
