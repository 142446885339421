import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Slider from 'react-slick'
import FilesViewItem from './FilesViewItem'
import classNames from 'classnames'
import { commonStyles, getFilePermissions, toLocale } from '../../../helpers'
import UrlifeCheckbox from '../UrlifeCheckbox'
import NotesInput from './NotesInput'
import GoogleMapReact from 'google-map-react'
import { awsInfo } from '../../../constants'
import {
  updateProject
} from '../../../actions'
import {
  CoverIcon,
  HeartFilled,
  HeartOutline,
  MapIcon,
  StarFilled,
  StarOutline
} from '../SVG'

/* eslint-disable sort-keys */
const styles = {
  carouselWrapper: {
    minHeight: '70vh'
  },
  carouselItem: {
    padding: '0px 7px',
    outline: 'none'
  },
  fileInfoContainer: {
    marginTop: 30,
    padding: '0px 7px',
    display: 'flex',
    flexDirection: 'column'
  },
  fileInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  fileInfoCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 15,
    '&._notes': {
      flexGrow: 1
    },
    '&._map': {
      width: '60%',
      height: '258px',
      marginLeft: 15,
      ...commonStyles.media(650, {
        width: '100%',
        marginLeft: 0
      })
    }
  },
  fileInfoItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 6,
    '&._clickable': {
      cursor: 'pointer'
    }
  },
  fileInfo: {
    fontSize: 16,
    fontWeight: 500,
    color: '#393939',
    lineHeight: '26px',
    marginBottom: 6,
    '&._fileName': {
      fontSize: 18
    },
    '&._action': {
      fontSize: 14,
      color: '#6a6a6a',
      marginBottom: 0
    }
  },
  icon: {
    color: '#01b7d7',
    height: 22,
    marginRight: 10,
    '&._heart': {
      stroke: '#01b7d7',
      fill: '#01b7d7'
    }
  }
}
/* eslint-enable sort-keys */

function AnyReactComponent () {
  return (
    <MapIcon />
  )
}

class CarouselView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentFileId: props.files && props.files.length > 0 ? props.files.id : null,
      currentFileIndex: 0
    }
  }

  static getDerivedStateFromProps (newProps, oldState) {
    if (newProps.files && newProps.files.length > 0) {
      let found = false
      newProps.files.forEach((file, index) => {
        if (file.id === oldState.currentFileId) {
          oldState.currentFileIndex = index
          found = true
        }
      })
      if (!found) {
        oldState.currentFileIndex = 0
        oldState.currentFileId = newProps.files[0].id
      }
    }
    return oldState
  }

  componentDidUpdate (prevProps) {
    const { files } = this.props
    if (prevProps.files.length && files.length > prevProps.files.length) {
      // scroll to first new file
      this.slider.slickGoTo(files.length - (files.length - prevProps.files.length))
    }
  }

  handleBeforeChange (oldIndex, newIndex) {
    const { files } = this.props
    const realIndex = newIndex <= 0 ? 0 : newIndex
    this.setState({
      currentFileId: files[realIndex].id,
      currentFileIndex: realIndex
    })
  }

  handleFileDelete (fileId) {
    const { files } = this.props
    const { currentFileIndex, currentFileId } = this.state
    if (currentFileId === fileId) {
      let indexSlideTo = currentFileIndex === files.length - 1 ? currentFileIndex - 1 : currentFileIndex
      if (indexSlideTo < 0) indexSlideTo = 0
      this.setState({
        currentFileId: files[indexSlideTo].id,
        currentFileIndex: indexSlideTo
      })
    }
  }

  getFile () {
    const { files } = this.props
    const { currentFileIndex } = this.state
    return currentFileIndex < files.length ? files[currentFileIndex] : null
  }

  makeCover () {
    const { project, dispatch } = this.props
    const { currentFileId } = this.state
    const isCover = currentFileId === project.coverFileId
    if (currentFileId) {
      dispatch(updateProject({ coverFileId: isCover ? null : currentFileId, id: project.id }))
    }
  }

  toggleFavorite (e, favorite) {
    e.stopPropagation()
    const file = this.getFile()
    if (file) {
      this.props.saveFavoriteStatus(file, favorite)
    }
  }

  toggleProducerFavorite (e, favorite) {
    e.stopPropagation()
    const file = this.getFile()
    if (file) {
      this.props.saveProducerFavoriteStatus(file, favorite)
    }
  }

  render () {
    const {
      classes,
      files,
      project,
      fileProgress,
      thumbnails,
      edit,
      isCurated,
      userId,
      roleId,
      onOpen,
      saveFavoriteStatus,
      saveProducerFavoriteStatus,
      setFileError,
      updateProject
    } = this.props
    const { currentFileIndex, currentFileId } = this.state
    const currentFile = this.getFile()
    const permissions = getFilePermissions(currentFile, project, userId, roleId, isCurated)
    const isCover = currentFileId === project.coverFileId
    const showMap = currentFile && currentFile.latitude && currentFile.longitude
    const settings = {
      arrow: true,
      className: 'current',
      focusOnSelect: true,
      infinite: files.length > 2,
      responsive: [{
        breakpoint: 575,
        settings: {
          arrow: true,
          className: 'current',
          infinite: files.length > 1,
          slidesToShow: 1
        }
      }],
      slidesToShow: files && files.length > 2 ? 2 : files.length,
      speed: 500
    }

    return (
      <div className={classes.carouselWrapper}>
        <Slider
          ref={slider => (this.slider = slider)}
          {...settings}
          beforeChange={this.handleBeforeChange.bind(this)}>
          {files.map((file, idx) => {
            const thumb = thumbnails && thumbnails[file.id] ? thumbnails[file.id].thumbnail : null
            const progress = fileProgress ? fileProgress[file.id] : null
            return (
              <div
                key={file.id}
                className={classes.carouselItem}>
                <FilesViewItem
                  view={'carousel'}
                  first={currentFileIndex === idx}
                  onOpen={onOpen}
                  onFileDelete={this.handleFileDelete.bind(this)}
                  project={project}
                  file={file}
                  thumbnail={thumb}
                  fileProgress={progress}
                  edit={edit}
                  isCurated={isCurated}
                  saveFavoriteStatus={saveFavoriteStatus}
                  saveProducerFavoriteStatus={saveProducerFavoriteStatus}
                  setFileError={setFileError}
                  updateProject={updateProject}
                />
              </div>
            )
          }
          )}
        </Slider>
        {currentFile && !isCurated &&
        <div className={classes.fileInfoContainer}>
          <div className={classes.fileInfoRow}>
            <div className={classes.fileInfoCell}>
              <div className={classNames(classes.fileInfo, '_fileName')}>
                {currentFile.original_name}
              </div>
              <div className={classes.fileInfo}>
                {'Date: ' + toLocale(new Date(currentFile.last_modified), { full: true })}
              </div>
            </div>
            <div className={classes.fileInfoCell}>
              {permissions.canMakeCover &&
              <div className={classes.fileInfoItem}>
                <UrlifeCheckbox
                  labelClassName={classNames(classes.fileInfo, '_action')}
                  label={'Set as cover image'}
                  checked={isCover}
                  disabled={!permissions.canMakeCover}
                  onChange={this.makeCover.bind(this)}
                />
              </div>
              }
              {!permissions.canMakeCover && isCover &&
              <div className={classes.fileInfoItem}>
                <CoverIcon className={classes.icon} />
                <div className={classNames(classes.fileInfo, '_action')}>
                  Cover image
                </div>
              </div>
              }
              {permissions.canSeeFavorite &&
              <div
                className={classNames(classes.fileInfoItem, { _clickable: permissions.canFavorite })}
                onClick={permissions.canFavorite ? e => this.toggleFavorite(e, !currentFile.favorite) : null}
              >
                {currentFile.favorite &&
                <StarFilled className={classes.icon} />
                }
                {!currentFile.favorite &&
                <StarOutline className={classes.icon} />
                }
                <div className={classNames(classes.fileInfo, '_action')}>
                  Customer favorite
                </div>
              </div>
              }
              {permissions.canSeeProdFavorite &&
              <div
                className={classNames(classes.fileInfoItem, { _clickable: permissions.canProdFavorite })}
                onClick={permissions.canProdFavorite
                  ? e => this.toggleProducerFavorite(e, !currentFile.producerFavorite)
                  : null
                }
              >
                {currentFile.producerFavorite &&
                <HeartFilled className={classes.icon} />
                }
                {!currentFile.producerFavorite &&
                <HeartOutline className={classNames(classes.icon, '_heart')} />
                }
                <div className={classNames(classes.fileInfo, '_action')}>
                  Producer favorite
                </div>
              </div>
              }
            </div>
          </div>
          <div className={classes.fileInfoRow}>
            <div className={classNames(classes.fileInfoCell, '_notes')}>
              <NotesInput
                noteFile={currentFile}
                projectId={project.id}
                permissions={permissions}
              />
            </div>
            {showMap &&
            <div className={classNames(classes.fileInfoCell, '_map')}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: awsInfo.KEY_MAP }}
                center={{
                  lat: currentFile.latitude,
                  lng: currentFile.longitude
                }}
                defaultZoom={10}
              >
                <AnyReactComponent
                  lat={currentFile.latitude}
                  lng={currentFile.longitude}
                />
              </GoogleMapReact>
            </div>
            }
          </div>
        </div>
        }
      </div>
    )
  }
}

CarouselView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  fileProgress: PropTypes.object,
  files: PropTypes.array.isRequired,
  isCurated: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  roleId: PropTypes.number.isRequired,
  saveFavoriteStatus: PropTypes.func,
  saveProducerFavoriteStatus: PropTypes.func,
  setFileError: PropTypes.func.isRequired,
  showNotes: PropTypes.bool.isRequired,
  thumbnails: PropTypes.object,
  updateProject: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    fileProgress: state.fileProgress,
    roleId: state.user.roleId,
    thumbnails: state.thumbnails.thumbnails,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CarouselView))
