import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../../helpers'
import ModalVideo from './ModalVideo'

/* eslint-disable sort-keys */
const useStyles = makeStyles({
  container: {
    marginTop: 46,
    width: '95vw',
    maxWidth: 1150,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...commonStyles.media(991, {
      flexDirection: 'column'
    })
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '&._silversea, &._celebrity, &._postvoyage': {
      ...commonStyles.media(991, {
        width: 600
      }),
      ...commonStyles.media(767, {
        width: '84vw'
      }),
      ...commonStyles.media(575, {
        width: 'auto'
      })
    }
  },
  videoContainer: {
    flexGrow: 1,
    maxWidth: 550,
    marginLeft: 30,
    '&._celebrity': {
      maxWidth: 580
    },
    ...commonStyles.media(1280, {
      maxWidth: 525,
      '&._celebrity': {
        maxWidth: 550
      }
    }),
    ...commonStyles.media(1125, {
      maxWidth: 470,
      '&._celebrity': {
        maxWidth: 520
      }
    }),
    ...commonStyles.media(991, {
      width: '100%',
      margin: 'auto',
      maxWidth: 625,
      marginTop: 30,
      '&._celebrity': {
        maxWidth: 625
      }
    })
  },
  thumbnail: {
    '&._postvoyage': {
      display: 'block',
      width: 550,
      height: 310,
      objectFit: 'cover',
      objectPosition: 'bottom',
      ...commonStyles.media(1280, {
        width: 525,
        height: 295
      }),
      ...commonStyles.media(1125, {
        width: 470,
        height: 264
      }),
      ...commonStyles.media(991, {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        marginTop: '-10%'
      })
    },
    '&._silversea': {
      display: 'block',
      width: 550,
      height: 310,
      objectFit: 'cover',
      objectPosition: 'bottom',
      ...commonStyles.media(1280, {
        width: 525,
        height: 295
      }),
      ...commonStyles.media(1125, {
        width: 470,
        height: 264
      }),
      ...commonStyles.media(991, {
        maxWidth: '100%',
        width: '100%',
        height: 'auto'
      })
    },
    '&._celebrity': {
      display: 'block',
      width: 580,
      height: 328,
      objectFit: 'cover',
      objectPosition: '20%',
      ...commonStyles.media(1280, {
        width: 550,
        height: 311
      }),
      ...commonStyles.media(1125, {
        width: 520,
        height: 294
      }),
      ...commonStyles.media(991, {
        maxWidth: '100%',
        width: '100%',
        height: 'auto',
        marginTop: '-10%'
      })
    }
  },
  introHeader: {
    fontSize: 65,
    lineHeight: '83px',
    fontWeight: 300,
    color: props => props.textColor,
    textTransform: 'uppercase',
    ...commonStyles.media(1280, {
      fontSize: 56,
      lineHeight: '72px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 52,
      lineHeight: '64px'
    }),
    ...commonStyles.media(991, {
      fontSize: 65,
      lineHeight: '83px'
    }),
    ...commonStyles.media(767, {
      fontSize: 56,
      lineHeight: '72px'
    }),
    ...commonStyles.media(575, {
      fontSize: 54,
      lineHeight: '68px'
    }),
    ...commonStyles.media(485, {
      fontSize: 46,
      lineHeight: '50px'
    }),
    ...commonStyles.media(400, {
      fontSize: 34,
      lineHeight: '40px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 54,
      lineHeight: '68px'
    }),
    '&._silversea, &._celebrity, &._postvoyage': {
      fontSize: 40,
      lineHeight: 1.4,
      ...commonStyles.media(1280, {
        fontSize: 36
      }),
      ...commonStyles.media(1125, {
        fontSize: 30
      }),
      ...commonStyles.media(991, {
        fontSize: 40
      }),
      ...commonStyles.media(767, {
        fontSize: 34
      }),
      ...commonStyles.media(575, {
        fontSize: 30
      }),
      ...commonStyles.media(485, {
        fontSize: 27
      }),
      ...commonStyles.media(400, {
        fontSize: 22
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 30
      })
    }
  },
  logo: {
    width: 'auto',
    height: 47,
    margin: '9px 0',
    ...commonStyles.media(1280, {
      height: 41,
      margin: '8px 0'
    }),
    ...commonStyles.media(1125, {
      height: 39,
      margin: '7px 0'
    }),
    ...commonStyles.media(991, {
      height: 47,
      margin: '9px 0'
    }),
    ...commonStyles.media(767, {
      height: 41,
      margin: '8px 0'
    }),
    ...commonStyles.media(575, {
      height: 39,
      margin: '7px 0'
    }),
    ...commonStyles.media(485, {
      height: 34,
      margin: '7px 0'
    }),
    ...commonStyles.media(400, {
      height: 28,
      margin: '7px 0'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      height: 39,
      margin: '7px 0'
    }),
    '&._bigLogo': {
      height: 72,
      margin: 0,
      ...commonStyles.media(1280, {
        height: 66
      }),
      ...commonStyles.media(1125, {
        height: 64
      }),
      ...commonStyles.media(991, {
        height: 72
      }),
      ...commonStyles.media(767, {
        height: 66
      }),
      ...commonStyles.media(575, {
        height: 64
      }),
      ...commonStyles.media(485, {
        height: 52
      }),
      ...commonStyles.media(400, {
        height: 42
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        height: 64
      })
    },
    '&._silversea, &._postvoyage': {
      height: 58,
      margin: '9px 0',
      ...commonStyles.media(1280, {
        height: 52
      }),
      ...commonStyles.media(1125, {
        height: 48
      }),
      ...commonStyles.media(991, {
        height: 58
      }),
      ...commonStyles.media(767, {
        height: 52
      }),
      ...commonStyles.media(575, {
        height: 42
      }),
      ...commonStyles.media(485, {
        height: 38
      }),
      ...commonStyles.media(400, {
        height: 32
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        height: 42
      })
    },
    '&._celebrity': {
      height: 84,
      margin: 0,
      ...commonStyles.media(1280, {
        height: 76
      }),
      ...commonStyles.media(1125, {
        height: 70
      }),
      ...commonStyles.media(991, {
        height: 84
      }),
      ...commonStyles.media(767, {
        height: 76
      }),
      ...commonStyles.media(575, {
        height: 64
      }),
      ...commonStyles.media(485, {
        height: 52
      }),
      ...commonStyles.media(400, {
        height: 42
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        height: 64
      })
    }
  },
  subHeader: {
    fontWeight: 600,
    color: props => props.textColor,
    fontSize: 26,
    lineHeight: '38px',
    letterSpacing: '-0.4px',
    ...commonStyles.media(1280, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 20,
      lineHeight: '27px'
    }),
    ...commonStyles.media(991, {
      fontSize: 26,
      lineHeight: '38px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(575, {
      fontSize: 21,
      lineHeight: '29px'
    }),
    ...commonStyles.media(485, {
      fontSize: 17,
      lineHeight: '21px'
    }),
    ...commonStyles.media(400, {
      fontSize: 12,
      lineHeight: '16px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 21,
      lineHeight: '29px'
    }),
    '&._postvoyage': {
      fontSize: 22,
      lineHeight: 1.4,
      ...commonStyles.media(1280, {
        fontSize: 20
      }),
      ...commonStyles.media(1125, {
        fontSize: 19
      }),
      ...commonStyles.media(991, {
        fontSize: 22
      }),
      ...commonStyles.media(767, {
        fontSize: 20
      }),
      ...commonStyles.media(575, {
        fontSize: 18
      }),
      ...commonStyles.media(485, {
        fontSize: 16
      }),
      ...commonStyles.media(400, {
        fontSize: 14
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 18
      })
    },
    '&._silversea': {
      fontSize: 24,
      lineHeight: 1.4,
      ...commonStyles.media(1280, {
        fontSize: 22
      }),
      ...commonStyles.media(1125, {
        fontSize: 20
      }),
      ...commonStyles.media(991, {
        fontSize: 24
      }),
      ...commonStyles.media(767, {
        fontSize: 22
      }),
      ...commonStyles.media(575, {
        fontSize: 20
      }),
      ...commonStyles.media(485, {
        fontSize: 18
      }),
      ...commonStyles.media(400, {
        fontSize: 16
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 20
      })
    }
  },
  text: {
    color: props => props.textColor,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '23px',
    marginBottom: -12,
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: '100%'
    },
    ...commonStyles.media(1280, {
      fontSize: 13,
      marginBottom: -10,
      lineHeight: '20px'
    }),
    ...commonStyles.media(1125, {
      fontSize: 11,
      marginBottom: -9,
      lineHeight: '16px'
    }),
    ...commonStyles.media(991, {
      fontSize: 15,
      marginBottom: -12,
      lineHeight: '23px'
    }),
    ...commonStyles.media(767, {
      fontSize: 13,
      marginBottom: -10,
      lineHeight: '20px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      marginBottom: -9,
      lineHeight: '18px'
    }),
    ...commonStyles.media(485, {
      fontSize: 11,
      lineHeight: '16px'
    }),
    ...commonStyles.media(450, {
      textAlign: 'left'
    }),
    ...commonStyles.media(400, {
      fontSize: 9,
      lineHeight: '12px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      marginBottom: -9,
      lineHeight: '18px'
    })
  },
  videoDisclaimer: {
    padding: '5px 0px',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.22,
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: '#55565a',
    '&._silversea': {
      color: '#ffffff',
      backgroundColor: '#231F20',
      textAlign: 'left',
      paddingLeft: 10
    },
    ...commonStyles.media(1125, {
      fontSize: 10
    }),
    ...commonStyles.media(991, {
      fontSize: 12
    }),
    ...commonStyles.media(575, {
      fontSize: 10
    }),
    ...commonStyles.media(450, {
      fontSize: 8
    })
  }
})

function Hero ({
  autoplay,
  bigLogo,
  extraClass,
  filter,
  headerAfter,
  headerBefore,
  headerLogo,
  headerSubHeader,
  headerInfo,
  logoColor,
  video,
  videoDisclaimer,
  textColor,
  thumbnail
}) {
  const classes = useStyles({
    textColor: textColor || '#4A30b5'
  })
  return (
    <div className={classes.container}>
      <div className={classNames(classes.headerContainer, { [extraClass]: !!extraClass })}>
        <div className={classNames(classes.introHeader, { [extraClass]: !!extraClass })}>{headerBefore}</div>
        {headerLogo({
          fill: logoColor,
          className: classNames(classes.logo, { _bigLogo: bigLogo, [extraClass]: !!extraClass })
        })}
        <div className={classNames(classes.introHeader, { [extraClass]: !!extraClass })}>{headerAfter}</div>
        <div>
          <div className={classNames(classes.subHeader, { [extraClass]: !!extraClass })}>
            {headerSubHeader}
          </div>
          <div className={classNames(classes.text, { [extraClass]: !!extraClass })}>
            {headerInfo}
          </div>
        </div>
      </div>
      <div className={classNames(classes.videoContainer, { [extraClass]: !!extraClass })}>
        <ModalVideo
          autoplay={autoplay}
          className={classNames(classes.modal, { [extraClass]: !!extraClass })}
          thumbnailClass={classNames(classes.thumbnail, { [extraClass]: !!extraClass })}
          thumbnail={thumbnail}
          filter={filter}
          video={video}
        />
        {videoDisclaimer && <div className={classNames(classes.videoDisclaimer, { [extraClass]: !!extraClass })}>
          {videoDisclaimer}
        </div>}
      </div>
    </div>
  )
}

Hero.propTypes = {
  autoplay: PropTypes.bool,
  bigLogo: PropTypes.bool,
  extraClass: PropTypes.string,
  filter: PropTypes.bool,
  headerAfter: PropTypes.string,
  headerBefore: PropTypes.string,
  headerLogo: PropTypes.func,
  headerSubHeader: PropTypes.string,
  headerInfo: PropTypes.string,
  logoColor: PropTypes.string,
  video: PropTypes.string,
  videoDisclaimer: PropTypes.string,
  textColor: PropTypes.string,
  thumbnail: PropTypes.string
}

export default Hero
