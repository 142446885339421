import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeInfiniteScroller from '../common/UrlifeInfiniteScroller'
import UrlifeCheckbox from '../common/UrlifeCheckbox'
import {
  listProjects,
  userInfoById
} from '../../actions'
import {
  commonStyles,
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  history,
  toLocale
} from '../../helpers'
import {
  FILE_STATUSES,
  PROJECT_STATUSES_NAME,
  PROJECT_STATUSES_ORDER
} from '../../constants'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  titleText: {
    margin: '10px 0px 30px 10px'
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 17
  },
  checkbox: {
    marginLeft: 6,
    marginRight: 15,
    width: 250
  },
  projectsTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: 10,
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  projectRow: {
    cursor: 'pointer',
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  projectCol: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    maxWidth: 275,
    padding: 10,
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '&._red': {
      color: '#d70101'
    },
    '&._link': {
      color: '#01b7d7',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}
/* eslint-enable sort-keys */

class AdminProjectList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      nextProject: null,
      projects: [],
      statuses: [],
      users: []
    }
  }

  async fetchUser (userId) {
    const { userInfoByIdFunction } = this.props
    const { users } = this.state
    if (!users[userId]) {
      try {
        const response = await userInfoByIdFunction(userId)
        this.setState({
          users: {
            ...this.state.users,
            [userId]: response.data
          }
        })
      } catch (e) {}
    }
  }

  async fetchProjects () {
    const { listProjectsFunction } = this.props
    const { projects, nextProject, statuses } = this.state
    const params = {
      cursor: nextProject,
      filter: statuses.map(status => 'status=' + status).join(','),
      method: 'DESC',
      sort: 'updatedAt'
    }
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await listProjectsFunction(params)
        const newProjects = response.data
        newProjects.forEach(project => {
          this.fetchUser(project.customerId)
        })
        this.setState({
          isLoading: false,
          nextProject: response.nextCursor,
          projects: [...projects, ...newProjects]
        })
      } catch (e) {
        this.setState({ isLoading: false })
      }
    })
  }

  componentDidMount () {
    this.fetchProjects()
  }

  setStatus (e) {
    const { statuses } = this.state
    let newStatuses = statuses
    const status = +e.target.value
    if (e.target.checked) {
      newStatuses.push(status)
    } else {
      newStatuses = statuses.filter(s => +s !== status)
    }
    this.setState({ nextProject: null, projects: [], statuses: newStatuses }, this.fetchProjects)
  }

  render () {
    const { classes } = this.props
    const {
      projects,
      nextProject,
      isLoading,
      users,
      statuses
    } = this.state
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h4" component="h2" className={classes.titleText}>
            <span>Projects</span>
          </Typography>
          <div className={classes.filterContainer}>
            {PROJECT_STATUSES_ORDER.map(status => (
              <div
                data-testid='apl-check'
                key={status}
              >
                <UrlifeCheckbox
                  data-testid='ul-checkbox'
                  key={+status}
                  className={classes.checkbox}
                  label={PROJECT_STATUSES_NAME[status]}
                  checked={statuses.indexOf(+status) > -1}
                  onChange={this.setStatus.bind(this)}
                  value={+status}
                />
              </div>
            ))}
          </div>
          <UrlifeInfiniteScroller
            loadMore={this.fetchProjects.bind(this)}
            hasMore={!!nextProject}
            isLoading={isLoading}
            isEmpty={!projects.length}
            className={classes.projectsTable}
          >
            <div
              data-testid='apl-header'
              className={classes.headerRow}
            >
              <div className={classes.headerCol}>Title</div>
              <div className={classes.headerCol}>Status</div>
              <div className={classes.headerCol}>Files <br /> (uploaded/total)</div>
              <div className={classes.headerCol}>Created date</div>
              <div className={classes.headerCol}>Last updated</div>
              <div className={classes.headerCol}>Submitted date</div>
              <div className={classes.headerCol}>User</div>
            </div>
            {projects && projects.map(project => {
              const projectSku = getLeastCompleteProjectSku(project)
              return (
                <div
                  data-testid='apl-project'
                  key={project.id}
                  className={classes.projectRow}
                  onClick={() => history.push('/projects/' + project.id + '/summary')}
                >
                  <div className={classes.projectCol}>
                    {project.title || '[ No title ]'}
                  </div>
                  <div className={classes.projectCol}>
                    {PROJECT_STATUSES_NAME[getLeastCompleteProjectSkuStatus(project)]}
                  </div>
                  <div className={classNames(classes.projectCol, { _red: project.fileCounts.toUpload })}>
                    {project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE]}
                    /
                    {project.fileCounts[FILE_STATUSES.CLIENT_UPLOADING] +
                      project.fileCounts[FILE_STATUSES.UPLOAD_FAILED] +
                      project.fileCounts[FILE_STATUSES.CLIENT_UPLOAD_COMPLETE]}
                  </div>
                  <div className={classes.projectCol}>
                    {toLocale(new Date(project.createdAt), { full: true })}
                  </div>
                  <div className={classes.projectCol}>
                    {toLocale(new Date(project.updatedAt), { full: true })}
                  </div>
                  <div className={classes.projectCol}>
                    {projectSku && projectSku.firstSubmittedDate
                      ? toLocale(new Date(projectSku.firstSubmittedDate), { full: true })
                      : 'N/A'}
                  </div>
                  <div
                    className={classNames(classes.projectCol, '_link')}
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push('/admin/users/' + project.customerId)
                    }}
                  >
                    {users[project.customerId] ? users[project.customerId].fullname : '...'}
                  </div>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
        </Paper>
      </div>
    )
  }
}

AdminProjectList.propTypes = {
  classes: PropTypes.object.isRequired,
  listProjectsFunction: PropTypes.func.isRequired,
  userInfoByIdFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    listProjectsFunction: function (params) {
      return dispatch(listProjects(params))
    },
    userInfoByIdFunction: function (userId) {
      return dispatch(userInfoById(userId))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(AdminProjectList))
