import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  fetchTips,
  fetchUserCoupons,
  getPackageSku,
  listProjects,
  projectInfo,
  setSkinId
} from '../../actions'
import {
  ExpandPlusIcon
} from '../common/SVG'
import MostRecentProjectBox from './MostRecentProjectBox'
import ModalVideo from '../common/ModalVideo'
import {
  chooseSummaryPage,
  commonStyles,
  getLeastCompleteProjectSku,
  getSkinIds,
  history,
  mapProjectStatusToStage
} from '../../helpers'
import { PROJECT_STAGES, PROJECT_STATUSES } from '../../constants'
import classNames from 'classnames'
import NotificationMenu from '../MainLayout/topBarComponents/NotificationMenu'
import { getSkinData } from '../../reducers/skinning'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    backgroundColor: 'transparent'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Montserrat',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: -20,
    padding: '40px 0px',
    ...commonStyles.media(991, {
      marginTop: -30
    })
  },
  leftHeader: {
    width: 40
  },
  middleHeader: {
    fontSize: 40,
    fontWeight: 500,
    textAlign: 'center',
    color: '#ffffff',
    margin: 'auto',
    ...commonStyles.media(991, {
      fontSize: 32
    }),
    ...commonStyles.media(575, {
      fontSize: 28
    })
  },
  name: {
    display: 'inline',
    fontWeight: 750
  },
  rightHeader: {
    width: 40,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#ffffff',
    '&._black': {
      color: '#000000',
      marginTop: 90
    },
    ...commonStyles.media(991, {
      fontSize: 14,
      marginTop: -10,
      '&._black': {
        marginTop: 40
      }
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      marginTop: -20,
      '&._black': {
        marginTop: 30
      }
    })
  },
  seeAllLink: {
    fontSize: 14,
    cursor: 'pointer',
    ...commonStyles.media(991, {
      fontSize: 12
    }),
    ...commonStyles.media(575, {
      fontSize: 11
    })
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '13px -6px',
    ...commonStyles.media(991, {
      marginTop: '8px'
    }),
    ...commonStyles.media(575, {
      marginTop: '5px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: '5px'
    })
  },
  mostRecentListCol: {
    width: '25%',
    padding: '7px',
    ...commonStyles.media(991, {
      padding: '5px'
    }),
    ...commonStyles.media(850, {
      width: '33.333%'
    }),
    ...commonStyles.media(575, {
      width: '50%',
      padding: '3px'
    }),
    ...commonStyles.media(325, {
      width: '100%'
    })
  },
  tipsListCol: {
    width: '33.333%',
    padding: '7px',
    ...commonStyles.media(991, {
      padding: '5px'
    }),
    ...commonStyles.media(700, {
      width: '50%'
    }),
    ...commonStyles.media(575, {
      padding: '3px'
    }),
    ...commonStyles.media(375, {
      width: '100%'
    })
  },
  startContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      fontSize: 22,
      color: '#ffffff',
      lineHeight: '30px'
    }
  },
  iconShade: {
    cursor: 'pointer',
    padding: 15,
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(135deg, #dad7ea 0%, #bd6ad3 100%)',
    opacity: 0.71
  },
  iconHolder: {
    padding: 23,
    borderRadius: '50%',
    backgroundImage: 'linear-gradient(135deg, #7265bf 0%, #bd6ad3 100%)'
  },
  createIcon: {
    height: 58,
    width: 58,
    color: '#ffffff'
  },
  startText: {
    margin: '10px 0 0 0',
    fontWeight: 500
  },
  startTextEmph: {
    margin: 0,
    fontWeight: 700
  },
  freeInfoHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 48,
    '&._emptyProjects': {
      marginBottom: 170
    }
  },
  freeText: {
    color: '#ffffff',
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '30px',
    marginBottom: 20
  },
  freeButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    padding: '8px 30px',
    margin: 'auto',
    '&:hover': {
      color: '#01b7d7',
      background: '#fff'
    }
  },
  notifications: {
    display: 'none',
    ...commonStyles.media(767, {
      display: 'flex'
    })
  }
}
/* eslint-enable sort-keys */

export class UrlifeHomePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      freeCoupons: [],
      hasFreeProjects: false,
      skinIds: {},
      tips: null
    }

    this.openProject = (project) => (event) => {
      const { dispatch } = this.props
      dispatch(projectInfo(project.id))
        .then(() => {
          chooseSummaryPage(project, props.packages)
        })
    }
    this.seeAll = () => (event) => {
      history.push('/projects/inprogress')
    }
    this.createMovie = () => {
      history.push('/createmovie', {
        lastLocation: history.location
      })
    }
  }

  async checkForSkin () {
    const { dispatch, projectList, packages } = this.props
    const skinIds = await getSkinIds(
      projectList,
      packages,
      (skuId) => dispatch(getPackageSku(skuId)),
      this.state.skinIds
    )
    this.setState({
      isLoading: false,
      skinIds
    })
  }

  fetchProjects () {
    const { dispatch } = this.props
    dispatch({ type: 'CLEAR_PROJECT_LIST' })
    const params = {
      count: 20,
      method: 'DESC',
      sort: 'createdAt'
    }
    const filter = 'status=' + PROJECT_STATUSES.PREP_USER +
      ',status=' + PROJECT_STATUSES.PREP_WAIT_PAYMENT +
      ',status=' + PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION +
      ',status=' + PROJECT_STATUSES.PROJECT_QUEUED +
      ',status=' + PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED +
      ',status=' + PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL +
      ',status=' + PROJECT_STATUSES.PRODUCTION_EDITING +
      ',status=' + PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW +
      ',status=' + PROJECT_STATUSES.PRODUCTION_REVISIONS +
      ',status=' + PROJECT_STATUSES.COMPLETE

    return this.setState(
      { isLoading: true },
      async () => {
        try {
          await dispatch(listProjects({ ...params, filter }))
          this.checkFreeProjects()
          this.checkForSkin()
        } catch (e) { /* Ignore this error. */ }
      }
    )
  }

  fetchTipsVideos () {
    const { dispatch } = this.props
    dispatch(fetchTips())
      .then(res => {
        this.setState({ tips: res.data })
      })
  }

  thumbnailPriority (video) {
    if (video.imageWebviewUrl) return video.imageWebviewUrl
    else if (video.imageUrl) return video.imageUrl
    else if (video.imageThumbnailUrl) return video.imageThumbnailUrl
    else return video.videoThumbnailUrl
  }

  fetchCoupons () {
    const { dispatch, userId } = this.props
    dispatch(fetchUserCoupons(userId, { state: ['unredeemed', 'unexpired'] }))
      .then(res => {
        let freeCoupons = []
        if (res.data && res.data.length > 0) {
          freeCoupons = res.data.filter(coupon => coupon.percent_off && coupon.percent_off >= 100)
        }
        this.setState({ freeCoupons }, () => { this.checkFreeProjects() })
      })
  }

  checkFreeProjects () {
    const { freeCoupons } = this.state
    const { projectList } = this.props
    let hasFreeProjects = false

    freeCoupons.forEach(coupon => {
      projectList.forEach(project => {
        project.projectSkus.forEach(sku => {
          const isUnpaid = mapProjectStatusToStage(sku.statusId) === PROJECT_STAGES.PREP
          if (isUnpaid && coupon.skus.includes(sku.packageSku)) {
            hasFreeProjects = true
          }
        })
      })
    })

    this.setState({ hasFreeProjects })
  }

  clearSkinId () {
    const { dispatch } = this.props
    dispatch(setSkinId(null))
  }

  componentDidUpdate (prevProps) {
    const firstPrevProject = prevProps.projectList[0]
    const firstProject = this.props.projectList[0]
    if (
      (!firstPrevProject && firstProject) ||
      (firstPrevProject && firstProject && firstPrevProject.id !== firstProject.id)
    ) {
      this.setState(
        { isLoading: true },
        () => this.checkForSkin.bind(this)
      )
    }
  }

  componentDidMount () {
    const wasSkinned = history.location.state &&
      history.location.state.type &&
      history.location.state.type === 'skinned'
    if (!wasSkinned) {
      this.clearSkinId()
    }
    this.fetchProjects()
    this.fetchTipsVideos()
    this.fetchCoupons()
  }

  render () {
    const {
      classes,
      username,
      projectList
    } = this.props
    const {
      freeCoupons,
      isLoading,
      skinIds,
      tips
    } = this.state
    const activeProjects = projectList.filter(project => {
      return getLeastCompleteProjectSku(project).statusId !== PROJECT_STATUSES.COMPLETE
    })
    const completeProjects = projectList.filter(project => {
      return getLeastCompleteProjectSku(project).statusId === PROJECT_STATUSES.COMPLETE
    })
    const hasMore = projectList.length === 5
    const showProjects = activeProjects.slice(0, 4)
    const greeting = projectList.length > 0 ? 'Hi ' : 'Welcome '
    const hasFreeCoupons = freeCoupons.length > 0
    const hasOnlyCompletedProjects = completeProjects.length && !activeProjects.length

    return (
      <div>
        <Paper className={classes.paper} elevation={0}>

          {/* header */}
          <div className={classes.header}>
            <div className={classes.leftHeader}>
            </div>
            <div className={classes.middleHeader}>
              {greeting}
              <div className={classes.name}>
                {username}
              </div>
            </div>
            <div className={classes.rightHeader}>
              <div className={classes.notifications} ><NotificationMenu /></div>
            </div>
          </div>

          {/* most recent */}
          {activeProjects.length > 0 && !isLoading &&
            <React.Fragment>
              <div className={classes.subHeader}>
                <div>MOST RECENT</div>
                {hasMore && <div className={classes.seeAllLink} onClick={this.seeAll()}>See all</div>}
              </div>
              <div className={classes.list}>
                {showProjects.map(proj => {
                  const projectSku = getLeastCompleteProjectSku(proj)
                  const packageSkuId = projectSku && projectSku.packageSku
                  const skinData = getSkinData(skinIds[packageSkuId])
                  return (
                    <div key={proj.id} className={classes.mostRecentListCol}>
                      <div onClick={this.openProject(proj)}>
                        <MostRecentProjectBox
                          user={0}
                          project={proj}
                          pack={0}
                          freeCoupons={freeCoupons}
                          skinnedThumbnail={skinData && skinData.projectThumbnail}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </React.Fragment>}
          {(activeProjects.length === 0 || hasOnlyCompletedProjects) && !hasFreeCoupons &&
            <div className={classes.startContainer}>
              <div className={classes.iconShade} onClick={this.createMovie}>
                <div className={classes.iconHolder}>
                  <ExpandPlusIcon className={classes.createIcon} />
                </div>
              </div>
              <p className={classes.startText}>
                Let’s get started with
              </p>
              <p className={classes.startTextEmph}>
                {`your ${hasOnlyCompletedProjects ? 'next' : 'first'} project!`}
              </p>
            </div>
          }
          {/* tips */}
          <div className={classNames(classes.subHeader, '_black')}>
            TIPS, TECHNIQUES & TASTEMAKERS
          </div>
          <div className={classes.list}>
            {tips && tips.map((item, idx) => {
              return (
                !item.ishidden && <div key={idx} className={classes.tipsListCol}>
                  <ModalVideo
                    video={item}
                  />
                </div>
              )
            }).sort(this.compareFields)}
          </div>
        </Paper>
      </div>
    )
  }
}

UrlifeHomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  projectList: PropTypes.arrayOf(Object),
  userId: PropTypes.string,
  username: PropTypes.string
}

UrlifeHomePage.defaultProps = {
  projectList: []
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages,
    projectList: state.projectList.projects,
    userId: state.user.userid,
    username: state.user.fullname
  }
}

export default withStyles(styles)(connect(mapStateToProps)(UrlifeHomePage))
