import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  createExploreFile,
  createVideo,
  deleteVideo,
  getAllVideoLooped,
  getVideo,
  updateVideo
} from '../../actions'
import { fileProgressStatus } from '../../reducers/fileProgress'
import EditTable from './EditTable/EditTable'

const videoMinRes = {
  height: 439,
  width: 780
}
const imageMinRes = {
  aspectX: 10,
  aspectY: 6,
  height: 216,
  width: 360
}

class EditExplore extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSortAnimationId: false,
      creatingNewItem: false,
      duplicateDisplayOrder: [],
      items: [],
      newItem: false,
      sortAnimationToBottomId: false,
      sortAnimationToTopId: false,
      thumbWait: false
    }
    this.compareFields = (a, b) => {
      // sort by id if equal
      if (a.displayOrder === b.displayOrder) {
        if (a.id && b.id) {
          // id can't be equal
          return a.id > b.id ? 1 : -1
        }
        return 0
      }

      // normal sorting by order field
      return +a.displayOrder > +b.displayOrder ? 1 : -1
    }
    this.fieldSettings = [
      {
        default: true,
        field: 'isHidden',
        name: 'Active',
        type: 'checkboxInverted',
        width: 70
      },
      {
        field: 'id',
        name: 'ID',
        type: 'string',
        width: 100
      },
      {
        field: 'date',
        name: 'Date',
        type: 'date'
      },
      {
        field: 'name',
        name: 'User name',
        type: 'text',
        width: 100
      },
      {
        field: 'categoryId',
        name: 'Category',
        placeholder: 'Choose Category',
        type: 'select',
        values: props.categories.map(cat => ({ name: cat.name, value: cat.id }))
      },
      {
        field: 'title',
        name: 'Title',
        type: 'text'
      },
      {
        field: 'packageId',
        name: 'Package',
        placeholder: 'Choose Package',
        type: 'select',
        values: props.packages.map(pack => ({ name: pack.title, value: pack.id }))
      }
    ]
    this.existingFieldSettings = [
      {
        acceptFiles: '.mp4',
        field: 'video',
        name: 'Video',
        type: 'uploadFile',
        width: 150
      },
      {
        field: 'thumbnail',
        name: 'Thumbnail',
        type: 'uploadFile',
        width: 150
      }
    ]
    this.bogusFields = [
      {
        field: '',
        name: '',
        type: 'string',
        width: 150
      }
    ]
  }

  /**
   * @param {string|number} id
   * @param {object} fieldsData
   * @param {boolean} [apply] - save to sever after updating data
   * @param {boolean} [needSort] - sort after changing
   */
  onChangeField (id, fieldsData, apply = false, needSort = false, callback = null) {
    const { items, duplicateDisplayOrder } = this.state

    const newStateData = {}

    newStateData.items = items.map(field => (
      field.id === id
        ? { ...field, ...fieldsData }
        : field
    ))
    if (needSort) newStateData.items = newStateData.items.sort(this.compareFields)

    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = items.filter(it => {
        return it.id !== id && it.displayOrder === +fieldsData.displayOrder
      }).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(id)
        ? duplicateDisplayOrder.concat(id)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== id)
    }

    this.setState(newStateData, callback)

    if (apply) this.applyChanges(id, fieldsData)
  }

  /* exchange 2 items positions with animation */
  exchangeFieldOrderPosition (currentItemIndex, changing) {
    const { items } = this.state

    const exchangeItemIndex = currentItemIndex + changing
    const currentItemPosition = items[currentItemIndex].displayOrder
    const exchangeItemPosition = items[exchangeItemIndex].displayOrder
    const target = changing > 0 ? 'bottom' : 'top'

    // start animation
    this.setState({
      activeSortAnimationId: items[currentItemIndex].id,
      sortAnimationToBottomId: items[target === 'bottom' ? currentItemIndex : exchangeItemIndex].id,
      sortAnimationToTopId: items[target === 'top' ? currentItemIndex : exchangeItemIndex].id
    })

    // end animation with timeout
    window.setTimeout(() => {
      this.setState({
        activeSortAnimationId: false,
        items: items.map((field, fieldIndex) => {
          if (fieldIndex === currentItemIndex) return { ...field, displayOrder: exchangeItemPosition }
          if (fieldIndex === exchangeItemIndex) return { ...field, displayOrder: currentItemPosition }
          return field
        }).sort(this.compareFields),
        sortAnimationToBottomId: false,
        sortAnimationToTopId: false
      })
    }, 600)

    // apply
    if (items[currentItemIndex].id) {
      this.applyChanges(
        items[currentItemIndex].id,
        { displayOrder: exchangeItemPosition }
      )
    }
    if (items[exchangeItemIndex].id) {
      this.applyChanges(
        items[exchangeItemIndex].id,
        { displayOrder: currentItemPosition }
      )
    }
  }

  // check filing items fields
  validateItem (item) {
    const { duplicateDisplayOrder } = this.state
    // filter errors
    return this.fieldSettings.filter(fieldSettingsItem => {
      let isValid = true;
      [].concat(fieldSettingsItem.field).forEach(fieldName => {
        if (fieldName === 'id') return false
        if (item[fieldName].toString().length === 0) {
          isValid = false
        }
      })

      return !isValid
    }).length === 0 && !duplicateDisplayOrder.includes(item.tempId || item.id)
  }

  onChangeNewItem (fieldsData) {
    const { newItem, items, duplicateDisplayOrder } = this.state

    const newStateData = {
      newItem: { ...newItem, ...fieldsData }
    }
    if (fieldsData.displayOrder) {
      const isDuplicateDisplayOrder = fieldsData.displayOrder && items.filter(it => {
        return it.id !== newItem.tempId && it.displayOrder === +fieldsData.displayOrder
      }).length > 0
      newStateData.duplicateDisplayOrder = isDuplicateDisplayOrder && !duplicateDisplayOrder.includes(newItem.tempId)
        ? duplicateDisplayOrder.concat(newItem.tempId)
        : duplicateDisplayOrder.filter(duplicateId => duplicateId !== newItem.tempId)
    }
    this.setState(newStateData)
  }

  /**
   * @param {string|null} id
   * @param {object} fieldsData
   */
  applyChanges (id, fieldsData) {
    const { updateVideoFunction } = this.props
    updateVideoFunction(id, fieldsData)
  }

  /**
   * add new item without saving
   * */
  addNewTempItem () {
    const { items } = this.state
    const sortOrderValues = items.map(item => +item.displayOrder)
    const maxSortOrder = items.length ? Math.max(...sortOrderValues) : 0

    const newItem = this.fieldSettings.reduce((ob, fieldSettingsItem) => {
      [].concat(fieldSettingsItem.field).forEach(fieldName => {
        switch (fieldName) {
          case 'displayOrder':
            ob[fieldName] = maxSortOrder + 100
            break
          case 'date':
            // need ISO format for moment library correct working
            ob[fieldName] = new Date().toISOString()
            break
          default:
            ob[fieldName] = typeof fieldSettingsItem.default !== 'undefined' ? fieldSettingsItem.default : ''
        }
      })
      return ob
    }, {
      tempId: `newItem-${Date.now()}`
    })
    this.setState({ newItem })
  }

  onSubmitNewItem (callback = null) {
    const { newItem: { tempId, id, ...newItemFormatted } } = this.state
    const { addVideoFunction } = this.props

    if (newItemFormatted.date) {
      // server needs a MySQL compatible date format
      newItemFormatted.date = new Date(newItemFormatted.date).toISOString()
    }
    newItemFormatted.duration = '0'
    this.setState({
      creatingNewItem: true
    }, () => {
      addVideoFunction(newItemFormatted)
        .then(
          response => {
            const responseNewItem = response.data
            const newState = {
              creatingNewItem: false,
              newItem: false
            }
            if (responseNewItem && responseNewItem.id) {
              newState.items = this.state.items
                .concat(responseNewItem)
            }
            this.setState(
              newState,
              () => {
                callback && callback(responseNewItem.id)
              }
            )
          }
        )
    })
  }

  onDeleteItem (id) {
    const { deleteVideoFunction } = this.props

    this.setState({
      items: this.state.items.filter(item => item.id !== id)
    })
    deleteVideoFunction(id)
  }

  uploadFile (id, file, field) {
    const { createExploreFileFunction } = this.props
    const fileInfo = { mimeType: file.type }
    this.setState({ updatedRow: id, updatedType: field })
    createExploreFileFunction(
      field === 'Thumbnail' ? 'exploreThumbnail' : 'exploreVideo',
      { exploreVideoId: id, files: [fileInfo], type: 'file' },
      file
    )
  }

  getUpdatedRow (times = 0) {
    const { updatedRow, updatedType, items } = this.state
    const { getVideoFunction } = this.props
    this.setState({ thumbWait: true })
    getVideoFunction(updatedRow).then(
      row => {
        if ((updatedType === 'Thumbnail' && row.data.thumbnailThumbnailUrl) ||
          (updatedType === 'Video' && row.data.videoThumbnailUrl)) {
          this.setState({
            items: items.map(vid => {
              this.setState({ thumbWait: false })
              if (vid.id === row.data.id) return row.data
              return vid
            })
          })
        } else if (times < 30) {
          this.time = window.setTimeout(() => this.getUpdatedRow(++times), 500)
        } else {
          this.time = window.setTimeout(() => this.getUpdatedRow(++times), 10000)
        }
      }
    )
  }

  componentWillUnmount () {
    if (this.time) {
      window.clearTimeout(this.time)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { fileProgress } = this.props
    const oldExploreUploads = prevProps.fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'explore' &&
      file.status === fileProgressStatus.inProgress)
    const newExploreUploads = fileProgress.filter(
      file => file.role === 'system' &&
      file.projectId === 'explore' &&
      file.status === fileProgressStatus.inProgress)
    if (oldExploreUploads.length > 0 &&
      newExploreUploads.length === 0) {
      this.getUpdatedRow()
    }
  }

  componentDidMount () {
    const { getAllVideoFunction } = this.props
    getAllVideoFunction()
      .then(response => this.setState({
        items: response
      }))
  }

  render () {
    const {
      items,
      newItem,
      creatingNewItem,
      sortAnimationToTopId,
      sortAnimationToBottomId,
      activeSortAnimationId,
      duplicateDisplayOrder,
      thumbWait
    } = this.state
    const fieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.existingFieldSettings[0],
      this.existingFieldSettings[1],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4],
      this.fieldSettings[5],
      this.fieldSettings[6]
    ]
    const newFieldList = [
      this.fieldSettings[0],
      this.fieldSettings[1],
      this.bogusFields[0],
      this.bogusFields[0],
      this.fieldSettings[2],
      this.fieldSettings[3],
      this.fieldSettings[4],
      this.fieldSettings[5],
      this.fieldSettings[6]
    ]

    return (
      <EditTable
        title={'Edit Explore Videos'}
        videoMinRes={videoMinRes}
        imageMinRes={imageMinRes}
        message={thumbWait ? 'Waiting for file to process and generate a thumbnail.' : ''}
        items={items}
        fieldList={fieldList}
        newItem={newItem}
        newFieldList={newFieldList}
        newItemIsValid={!creatingNewItem && newItem && this.validateItem(newItem)}
        sortAnimationToTopId={sortAnimationToTopId}
        sortAnimationToBottomId={sortAnimationToBottomId}
        activeSortAnimationId={activeSortAnimationId}
        duplicateDisplayOrder={duplicateDisplayOrder}
        onChangeField={this.onChangeField.bind(this)}
        exchangeFieldOrderPosition={this.exchangeFieldOrderPosition.bind(this)}
        uploadFile={this.uploadFile.bind(this)}
        addNewTempItem={this.addNewTempItem.bind(this)}
        newButtonLabel={'New video'}
        onChangeNewItem={this.onChangeNewItem.bind(this)}
        onSubmitNewItem={this.onSubmitNewItem.bind(this)}
      />
    )
  }
}

EditExplore.propTypes = {
  addVideoFunction: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  createExploreFileFunction: PropTypes.func.isRequired,
  deleteVideoFunction: PropTypes.func.isRequired,
  fileProgress: PropTypes.array,
  getAllVideoFunction: PropTypes.func.isRequired,
  getVideoFunction: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(Object),
  updateVideoFunction: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    categories: state.category.categories,
    fileProgress: Object.values(state.fileProgress),
    packages: state.packages.packages
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addVideoFunction: function (data) {
      return dispatch(createVideo(data))
    },
    createExploreFileFunction: function (type, file, fileObject) {
      return dispatch(createExploreFile(type, file, fileObject))
    },
    deleteVideoFunction: function (id) {
      return dispatch(deleteVideo(id))
    },
    getAllVideoFunction: function () {
      return dispatch(getAllVideoLooped({ filter: 'hidden=true' }))
    },
    getVideoFunction: function (id) {
      return dispatch(getVideo(id))
    },
    updateVideoFunction: function (id, data) {
      return dispatch(updateVideo(id, data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditExplore)
