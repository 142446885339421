import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function AlertTriangle (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 24 24' >
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4424 15.9532L15.4371 3.97762C14.7338 2.7584 13.4229 2.00166 12.0053 2C10.5875 1.99834 9.27626 2.75196 8.56422 3.97767L1.55628 15.9576C0.829606 17.1795 0.813166 18.7105 1.51703 19.9592C2.22148 21.2089 3.54051 21.9866 4.97525 21.9983L19.0131 21.9983C20.4628 21.9842 21.7793 21.2089 22.4836 19.9603C23.1871 18.7132 23.1714 17.1856 22.4424 15.9532ZM3.27894 16.9737L10.2921 4.98491C10.6472 4.37354 11.2986 3.99918 12.003 4C12.7073 4.00083 13.3584 4.37673 13.7077 4.98222L20.7186 16.9672C21.086 17.5885 21.0939 18.3533 20.7417 18.9777C20.389 19.603 19.7295 19.9913 19.0034 19.9984L4.98339 19.9983C4.27278 19.9925 3.61208 19.6029 3.25929 18.9771C2.90689 18.3519 2.91512 17.5855 3.27894 16.9737ZM12.0012 17.9983C12.5537 17.9983 13.0015 17.5506 13.0015 16.9983C13.0015 16.4461 12.5537 15.9983 12.0012 15.9983C11.4487 15.9983 11.0009 16.4461 11.0009 16.9983C11.0009 17.5506 11.4487 17.9983 12.0012 17.9983ZM13.0045 7.99835H11.0038V14.9983H13.0045V7.99835Z"/>
    </SvgIcon>
  )
}
