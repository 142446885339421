import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ModalPreview from '../../ModalPreview'
import { ParallaxListIcon, ParallaxPlayIcon } from '../../../common/SVG'
import { commonStyles } from '../../../../helpers'
import connect from 'react-redux/es/connect/connect'
import styled from 'styled-components'
import MarketingPageCTA from '../../../marketing/MarketingPageCTA'
import RegisterOrProjectButton from '../../../common/RegisterOrProjectButton'

/* eslint-disable sort-keys */
const styles = {
  container: {
    ...commonStyles.container(),
    marginTop: -110,
    ...commonStyles.media(480, {
      ...commonStyles.container(),
      marginTop: -185
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      ...commonStyles.container(),
      marginTop: -185
    }),
    ...commonStyles.mediaDimensions(575, 414, {
      ...commonStyles.container(),
      marginTop: -215
    })
  },
  thumbnail: {
    position: 'relative',
    marginTop: 10,
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  filter: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    width: '100%',
    height: 'calc(100% - 5px)',
    background: 'radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.34) 0%, rgba(255, 255, 255, 0.14) 20%)'
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 21
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    margin: '25px 0px',
    paddingInline: 45
  },
  playIcon: {
    display: 'inline-block',
    width: '10%',
    height: 'auto',
    margin: 'auto'
  },
  text: {
    padding: '25px 16px 9px 16px',
    margin: 0,
    color: '#323232',
    lineHeight: '26px',
    fontSize: 16,
    fontWeight: 500,
    ...commonStyles.media(959, {
      borderBottom: '1px solid #dfdfdf',
      padding: '25px 16px'
    }),
    ...commonStyles.media(480, {
      fontSize: 14,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '24px'
    })
  },
  additionalText: {
    fontSize: 16,
    paddingInline: 16,
    ...commonStyles.media(959, {
      paddingTop: 10,
      alignSelf: 'flex-start'
    })
  },
  list: {
    padding: '5px 16px 9px 0px',
    ...commonStyles.media(959, {
      alignSelf: 'flex-start',
      padding: '10px 16px 9px 0px'
    })
  },
  listItem: {
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 15
  },
  details: {
    padding: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    marginBottom: 40,
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.15)'
  },
  panel: {
    boxShadow: 'none',
    paddingBottom: 50
  },
  panelExpanded: {
    borderTop: '1px solid #dfdfdf',
    paddingBottom: 60
  },
  panelExpandedWithBullets: {
    paddingBottom: 60
  },
  priceContainer: {
    width: 'calc(100% - 16px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 16,
    borderBottom: '1px solid #dfdfdf',
    '&._last': {
      borderBottom: 'none'
    },
    ...commonStyles.media(959, {
      marginLeft: 0,
      width: '100%',
      '&._last': {
        borderBottom: '1px solid #dfdfdf'
      }
    })
  },
  amountRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  price: {
    padding: '10px 0px',
    margin: 'auto auto auto 0px',
    color: '#323232',
    fontSize: 30,
    fontWeight: 500,
    ...commonStyles.media(959, {
      paddingLeft: 16
    })
  },
  priceText: {
    textAlign: 'right',
    width: '100%',
    paddingRight: 24,
    margin: 'auto',
    color: '#323232',
    fontSize: 15,
    fontWeight: 400,
    ...commonStyles.media(959, {
      margin: 'auto 0px'
    })
  },
  extraInfo: {
    textAlign: 'right',
    paddingRight: 24,
    position: 'absolute',
    right: 0,
    bottom: 5,
    fontSize: 10,
    fontWeight: 400,
    ...commonStyles.media(959, {
      left: 20
    })
  },
  priceDescriptionContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative'
  },
  spaceOut: {
    marginTop: 30,
    marginBottom: -50,
    ...commonStyles.media(959, {
      margin: 'auto'
    })
  }
}
/* eslint-enable sort-keys */

const ItemHeader = styled.h2`
  color: ${props => props.color ? props.color : '#3d29b1'};
  margin: 0;
  padding: 10px 0px 5px 21px;
  font-size: 34px;
  font-family: Montserrat;
  font-weight: 900;
  letter-spacing: 0px;
  text-transform: uppercase;
  @media (max-width: 1169px) {
    font-size: 30px;
  }
  @media (max-width: 480px) {
    font-size: 21px;
  }
  @media (max-width: 896px) and (max-height: 414px) {
    font-size: 21px;
  }
`

const headerColors = ['#5032b6', '#7546c0', '#9857c9']

class PackageListV2 extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    }
    this.splitText = this.splitText.bind(this)
  }

  splitText (text) {
    if (text && typeof text === 'string') {
      return text.split('|')
    }
    return ['']
  }

  setVideo (video, title) {
    this.setState({
      chosenGaTitle: title,
      chosenVideo: video,
      openModal: true
    })
  }

  onClose () {
    this.setState({
      chosenGaTitle: '',
      chosenVideo: null,
      openModal: false
    })
  }

  render () {
    const { classes, packages, className } = this.props
    const filteredPackages = packages.filter(pack => !pack.skus.filter(sku => !!sku.comingSoon).length)
    const { chosenVideo, chosenGaTitle, openModal } = this.state
    return (
      <>
        <div className={classNames(classes.container, className)}>
          {filteredPackages && filteredPackages.map((item, idx) => {
            const [subHeader, text] = this.splitText(item.subtitle)
            return (
              <Paper key={idx} className={classes.paper}>
                <Grid container>
                  <Grid item sm={12} md={4}>
                    <ItemHeader color={headerColors[idx]}>{item.title}</ItemHeader>
                    <span className={classes.subHeader}>{subHeader}</span>
                    {item.thumbnailUrl && <div
                      className={classes.thumbnail}
                      onClick={() => {
                        item.videoUrl && this.setVideo(item.videoUrl, item.title)
                      }}
                    >
                      <img src={item.thumbnailUrl} alt="thumbnail"/>
                      <div
                        className={classes.filter}
                        style={ { cursor: item.videoUrl ? 'pointer' : 'default' } } >
                        {item.videoUrl && <ParallaxPlayIcon className={classes.playIcon}/>}
                      </div>
                    </div>}
                  </Grid>
                  <Grid item className={classNames(classes.gridItem, classes.spaceOut)} sm={12} md={4}>
                    <p className={classes.text}>{text}</p>
                    {item.skus.map((priceInfo, idx) => {
                      const [description, extraInfo] = this.splitText(priceInfo.description)
                      return (
                        <div
                          key={idx}
                          className={classNames(classes.priceContainer, { _last: item.skus.length - 1 === idx })}
                        >
                          <div className={classes.amountRow}>
                            <span className={classes.price}>
                              {priceInfo.comingSoon ? 'Coming Soon' : priceInfo.price}
                            </span>
                            <div className={classes.priceDescriptionContainer}>
                              <span className={classes.priceText}>{description}</span>
                              {extraInfo && <span className={classes.extraInfo}>{extraInfo}</span>}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Grid>
                  <Grid item className={classNames(classes.gridItem, classes.spaceOut)} sm={12} md={4}>
                    {idx > 0 && item.jsonBulletList &&
                      <span
                        className={classes.additionalText}
                      >
                        Everything included in the {filteredPackages[idx - 1].title}, <b>PLUS:</b>
                      </span>
                    }
                    <List
                      className={classes.list}
                    >
                      {item.jsonBulletList.map((item, idx) => {
                        return (
                          <ListItem key={idx} className={classes.listItem}>
                            <ParallaxListIcon className={classes.icon}/>
                            {item}
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12} sm={12} md={4}>
                    <MarketingPageCTA>
                      <RegisterOrProjectButton modal='getStarted' className={classes.button} />
                    </MarketingPageCTA>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        </div>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.onClose.bind(this)}
          video={chosenVideo}
          gaVideoTitle={chosenGaTitle}
          title={''}
        />
      </>
    )
  }
}

PackageListV2.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  packages: PropTypes.arrayOf(Object),
  setSectionRef: PropTypes.func.isRequired
}

PackageListV2.default = {
  packages: []
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages
  }
}

export default withStyles(styles)(connect(mapStateToProps)(PackageListV2))
