import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import PropTypes from 'prop-types'
import { commonStyles, toLocale } from '../../../../helpers'

/* eslint-disable sort-keys */
const styles = {
  container: {
    maxHeight: '90px',
    borderLeft: '5px solid #01b7d7',
    padding: '2px',
    margin: '8px',
    boxShadow: '0 0 10px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: 2,
    cursor: 'pointer'
  },
  info: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
    ...commonStyles.media(600, {
      margin: 5
    })
  },
  projectName: {
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    ...commonStyles.media(600, {
      fontSize: 11
    })
  },
  date: {
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 10,
    ...commonStyles.media(600, {
      fontSize: 11
    })
  },
  typeInfo: {
    display: 'inline-block',
    margin: '0 0 10px 10px',
    color: '#323232',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    ...commonStyles.media(600, {
      fontSize: 11,
      margin: '0 0 5px 5px'
    })
  }
}
/* eslint-enable sort-keys */

const Notification = (props) => {
  const { classes, notification, onClick } = props
  let topLine = ''
  let bottomLine = ''
  if (notification.notification.type === 'MESSAGE') {
    topLine = notification.notification.projectTitle
    bottomLine = 'New message(s) received'
  } else if (notification.notification.type === 'MOVIE') {
    topLine = notification.notification.projectTitle
    bottomLine = 'Movie ready for review'
  } else if (notification.notification.type === 'MOVIE_APPROVED') {
    topLine = 'Project approved by customer'
    bottomLine = notification.notification.projectTitle
  } else if (notification.notification.type === 'COUPON') {
    topLine = 'You have a new code'
    bottomLine = notification.notification.couponTitle
  } else if (notification.notification.type === 'PAYMENT') {
    topLine = 'Project ready to check out'
    bottomLine = notification.notification.projectTitle
  }
  const showDate = toLocale(new Date(notification.createdAt), { full: true })

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.info}>
        <span className={classes.projectName}>
          {topLine}
        </span>
        <span className={classes.date}>
          {showDate}
        </span>
      </div>
      <span className={classes.typeInfo}>
        {bottomLine}
      </span>
    </div>
  )
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default withStyles(styles)(Notification)
