import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import MoreMenu from '../../common/MoreMenu/MoreMenu'
import { connect } from 'react-redux'
import { commonStyles } from '../../../helpers/index'
import {
  LogOutIcon,
  TopMenuGallery
} from '../../common/SVG/index'
import TopBarLineMenu from './TopBarLineMenu'
import { userLogout } from '../../../actions'
import NotificationMenu from './NotificationMenu'
import TopBarLogo from './TopBarLogo'

/* eslint-disable sort-keys */
const styles = {
  container: {
    ...commonStyles.container(),
    minHeight: 0
  },
  topBarRow: {
    marginTop: -20,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  topBarLeft: {
    ...commonStyles.flex(),
    justifyContent: 'flex-start',
    flex: 1,
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  topBarMiddle: {
    ...commonStyles.flex(),
    ...commonStyles.media(767, {
      margin: 'auto'
    })
  },
  topBarRight: {
    ...commonStyles.flex(),
    justifyContent: 'flex-end',
    flex: 1,
    ...commonStyles.media(767, {
      display: 'none'
    })
  },
  moreContainer: {
    marginTop: 4
  },
  icon: {
    marginLeft: 10,
    cursor: 'pointer'
  }
}
/* eslint-enable sort-keys */

class ToolbarProducer extends React.Component {
  render () {
    const { classes, logOut, userId } = this.props
    return (
      <Toolbar className={classes.container}>
        <div className={classes.topBarRow}>
          {/* left col */}
          <div className={classes.topBarLeft}>
            <TopBarLogo />
          </div>
          {/* middle col */}
          <div className={classes.topBarMiddle}>
            <TopBarLineMenu
              className={classes.topBarMenu}
              type={'light'}
              items={[
                { icon: <TopMenuGallery />, name: 'Gallery', to: '/projects/assigned' }
              ]}
            />
            <div className={classes.moreContainer}>
              <MoreMenu />
            </div>
          </div>
          {/* right col */}
          <div className={classes.topBarRight}>
            {userId && <NotificationMenu />}
            <LogOutIcon className={classes.icon} onClick={logOut} />
          </div>
        </div>
      </Toolbar>
    )
  }
}

ToolbarProducer.propTypes = {
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  userId: PropTypes.string
}

function mapStateToProps (state) {
  return {
    userId: state.user.userid
  }
}
function mapDispatchToProps (dispatch) {
  return {
    logOut: function () {
      dispatch(userLogout())
    }
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolbarProducer))
)
