import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { parse } from 'query-string'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  codeIsOffer,
  commonStyles,
  getCodeResultDescription,
  history
} from '../../helpers'
import classNames from 'classnames'
import ReactGA from 'react-ga4'
import ValidateForm from '../common/form/ValidateForm'

import {
  resetPassword,
  setSkinId,
  toggleModal,
  wantsLogin
} from '../../actions'

/* eslint-disable sort-keys */
const styles = () => ({
  header: {
    textAlign: 'left',
    color: '#333',
    padding: '40px 50px 0px 50px',
    ...commonStyles.media(480, {
      padding: '20px 20px 0px 20px'
    }),
    '&._noForm': {
      textAlign: 'center',
      paddingBottom: 40,
      ...commonStyles.media(480, {
        paddingBottom: 20
      })
    }
  },
  headerText: {
    fontSize: 20,
    fontWeight: 600,
    ...commonStyles.media(480, {
      fontSize: 16
    })
  },
  describeText: {
    margin: '0px 0px 5px 0px',
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 300,
    '&._noForm': {
      maxWidth: '100%',
      margin: 0
    },
    '&._codeResult': {
      maxWidth: '100%',
      margin: '10px 0 0 0'
    }
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 22px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  buttonHolder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 50px',
    '&._noForm': {
      alignItems: 'center'
    },
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  confirmButton: {
    margin: '0px 0px 50px 0px',
    width: '100%',
    height: 46,
    fontSize: 12,
    '&._noForm': {
      maxWidth: 213,
      fontSize: 14
    }
  }
})
/* eslint-enable sort-keys */

export class UrlifeResetPasswordDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      codeResult: null,
      fields: {},
      formStatus: '', // '', sending, success, fail
      token: null,
      wantedAutoLogin: false
    }
    this.isReset = props.title === 'Reset password'
  }

  resetPassword () {
    const { userPass, userConfirmPass } = this.state.fields
    const { resetPassword, skinData, setCode } = this.props
    if (userPass && userConfirmPass) {
      const autoLogin = !!skinData
      this.setState({
        formStatus: 'sending',
        wantedAutoLogin: autoLogin
      }, async () => {
        const result = await resetPassword(userPass, this.state.token, autoLogin)
        setCode(result.code)
        this.setState({
          codeResult: result.codeResult,
          formStatus: ''
        })
      })
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.loggedIn && !prevState.wantedAutoLogin) {
      if (nextProps.toggleModal) {
        nextProps.toggleModal(false, null)
      }
      history.push('/')
    }
    return prevState
  }

  componentDidMount () {
    const query = history.location.search
    const parsed = parse(query)
    const skinId = parsed.skinId ? parseInt(parsed.skinId) : null
    if (parsed.token && typeof parsed.token === 'string') {
      this.setState({ token: parsed.token })
      this.props.setSkinIdFunction(skinId)
    } else {
      history.push('/')
    }
  }

  loginRedirect () {
    const {
      resetSuccess,
      wantsLoginFunction,
      toggleModal,
      setCode,
      loggedIn,
      code,
      skinData
    } = this.props
    const { codeResult } = this.state
    if (resetSuccess) {
      if (!this.isReset && !codeIsOffer(code)) {
        ReactGA.event({
          action: 'registrationComplete',
          category: 'conversionRate',
          label: skinData ? skinData.title : 'URLIFE'
        })
      }
      const redeemedCode = codeResult && codeResult.status === 'success'
      const redirectToProject = redeemedCode && codeResult.data && codeResult.data.projectId
      setCode('')
      if (loggedIn) {
        toggleModal(false, null)
      } else {
        wantsLoginFunction()
      }
      if (redirectToProject) {
        history.push('/createmovie/continue/' + codeResult.data.projectId)
      } else {
        history.push(loggedIn ? '/home' : '/')
      }
    } else {
      toggleModal(true, 'forgotten')
    }
  }

  render () {
    const { fields, formStatus } = this.state

    const {
      classes,
      title,
      resetSuccess,
      resetFailed,
      code,
      loggedIn,
      skinData
    } = this.props
    const { codeResult } = this.state

    let title1 = resetSuccess
      ? 'Thank you!'
      : resetFailed
        ? 'We could not set your password'
        : title
    let title2 = resetSuccess
      ? this.isReset
        ? 'Password is set.'
        : 'Registration complete.'
      : ''
    const description = resetSuccess
      ? ''
      : resetFailed
        ? 'Maybe you used an old link?'
        : 'Your password must be at least 6 characters long'
    const showForm = !resetSuccess && !resetFailed
    let buttonLabel = 'Log in'
    if (resetFailed) {
      buttonLabel = 'Try again'
    } else if (loggedIn) {
      buttonLabel = 'OK'
    }

    const codeResultDescription = getCodeResultDescription(codeResult, code, skinData)
    const skinned = !!skinData
    if (codeResult && !showForm) {
      if (codeResult.status === 'success') {
        if (codeResult.data && codeResult.data.coupon) {
          if (codeResult.data.projectId) {
            if (skinned) {
              title1 = 'Congratulations'
              buttonLabel = 'OK'
            } else {
              title1 = "You're all set"
              buttonLabel = 'View package'
            }
            title2 = ''
          }
        }
      }
    }

    return (
      <div>
        <DialogTitle
          id="form-dialog-title"
          className={classNames(classes.header, { _noForm: !showForm })}
        >
          <div className={classes.headerText}>{title1}</div>
          {title2 && <div className={classes.headerText}>{title2}</div>}
          {description &&
          <p className={classNames(classes.describeText, { _noForm: !showForm })}>{description}</p>
          }
          {codeResultDescription.line1 &&
          <p className={classNames(classes.describeText, '_codeResult')}>{codeResultDescription.line1}</p>
          }
          {codeResultDescription.line2 &&
          <p className={classNames(classes.describeText, '_codeResult')}>{codeResultDescription.line2}</p>
          }
        </DialogTitle>
        {showForm &&
        <ValidateForm
          onSubmit={this.resetPassword.bind(this)}
          onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
          validateOnMount={true}
          compareFields={['userPass', 'userConfirmPass']}
          passwordMinLength={6}>
          {(form) => (
            <React.Fragment>
              <DialogContent classes={{ root: classes.content }}>
                {form.renderField(
                  'userPass',
                  ['required', 'passwordLength'],
                  (field, fieldData) => {
                    let validationStatus = 'none'
                    let errorMessage = ''
                    if (fields.userPass && fields.userPass.length > 0) {
                      if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                        validationStatus = 'error'
                        errorMessage = fieldData.errors[0]
                      } else {
                        validationStatus = 'success'
                      }
                    }
                    return (
                      <UrlifeTextField
                        margin="dense"
                        id="pass"
                        label="Password"
                        name="userPass"
                        type="password"
                        fullWidth
                        onChange={e => field.onChange(e)}
                        onBlur={e => field.onBlur(e)}
                        defaultValue={fields.userPass || ''}
                        validationStatus={validationStatus}
                        infoLabel={errorMessage}
                      />
                    )
                  }
                )}
                {form.renderField(
                  'userConfirmPass',
                  ['required', 'passwordLength', 'passwordMatch'],
                  (field, fieldData) => {
                    let validationStatus = 'none'
                    let errorMessage = ''
                    if (fields.userConfirmPass && fields.userConfirmPass.length > 0) {
                      if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                        validationStatus = 'error'
                        errorMessage = fieldData.errors[0]
                      } else {
                        validationStatus = 'success'
                      }
                    }
                    return (
                      <UrlifeTextField
                        margin="dense"
                        id="confirmPass"
                        label="Confirm password"
                        name="userConfirmPass"
                        type="password"
                        fullWidth
                        onChange={e => field.onChange(e)}
                        onBlur={e => field.onBlur(e)}
                        defaultValue={fields.userConfirmPass || ''}
                        validationStatus={validationStatus}
                        infoLabel={errorMessage}
                      />
                    )
                  }
                )}
              </DialogContent>
              <DialogActions classes={{ root: classes.buttonHolder }}>
                <UrlifeButton
                  type="submit"
                  className={classes.confirmButton}
                  disabled={formStatus === 'sending' || !form.checkFormValid()}
                >
                  Confirm
                </UrlifeButton>
              </DialogActions>
            </React.Fragment>
          )}
        </ValidateForm>
        }
        {!showForm &&
        <div className={classNames(classes.buttonHolder, '_noForm')}>
          <UrlifeButton
            className={classNames(classes.confirmButton, '_noForm')}
            onClick={this.loginRedirect.bind(this)}
          >
            {buttonLabel}
          </UrlifeButton>
        </div>
        }
      </div>
    )
  }
}

UrlifeResetPasswordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
  resetFailed: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
  resetSuccess: PropTypes.bool,
  setCode: PropTypes.func.isRequired,
  setSkinIdFunction: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  wantsLoginFunction: PropTypes.func.isRequired
}
const mapStateToProps = state => {
  return {
    loggedIn: state.user.loggedIn,
    resetFailed: state.user.resetFailed,
    resetSuccess: state.user.resetSuccess,
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    resetPassword: function (password, token, autoLogin) {
      return dispatch(resetPassword(password, token, autoLogin))
    },
    setSkinIdFunction: function (skinId) {
      return dispatch(setSkinId(skinId))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    },
    wantsLoginFunction: function () {
      return dispatch(wantsLogin(true))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeResetPasswordDialog))
