import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from '../common/UrlifeButton'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import UrlifeDialogError from '../common/UrlifeDialogError'
import UrlifeDialogSuccess from '../common/UrlifeDialogSuccess'
import ValidateForm from '../common/form/ValidateForm'
import { getUserComingSoonInvite, toggleModal } from '../../actions'
import { commonStyles } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  registrationHeader: {
    textAlign: 'left',
    color: '#333',
    padding: '40px 50px 0px 50px',
    '& h2': {
      fontSize: '20px',
      fontWeight: 600,
      ...commonStyles.media(480, {
        fontSize: 16
      })
    },
    ...commonStyles.media(480, {
      padding: '20px 20px 0px 20px'
    })
  },
  input: {
    textAlign: 'center',
    fontSize: '0.88rem',
    paddingBottom: '25px',
    marginTop: '1rem'
  },
  underline: {
    '&:after': {
      borderBottom: '1px solid #000'
    },
    '&:hover': {
      '&:before': {
        borderBottom: '1px solid #000'
      }
    }
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 24px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  buttonHolder: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0 50px 24px 50px',
    ...commonStyles.media(480, {
      padding: '0 20px'
    })
  },
  submitButton: {
    margin: 0,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  loginButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    color: '#ee6386',
    fontSize: '0.875rem',
    margin: '2rem 0',
    textTransform: 'none'
  },
  successButton: {
    width: 200,
    height: 50,
    margin: '30px auto 0'
  }
}
/* eslint-enable sort-keys */

class UrlifeInviteComingSoonDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      formStatus: '' // '', sending, success, fail
    }

    this.send = () => (event) => {
      const { userEmail, fullName } = this.state.fields
      this.setState({
        formStatus: 'sending'
      }, () => {
        this.props.sendInvite(userEmail, fullName)
          .then(
            data => {
              this.setState({
                formStatus: data.status || 'fail'
              })
            }
          )
          .catch(e => {
            this.setState({
              formStatus: 'fail'
            })
          })
      })
    }
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
  }

  closeModal () {
    this.props.toggleModal(false, null)
  }

  render () {
    const { fields, formStatus } = this.state
    const { classes } = this.props
    return (
      <div>
        <DialogTitle
          className={classes.registrationHeader}>
          {formStatus === 'success'
            ? 'Thank you for your interest'
            : 'Request invite'}
        </DialogTitle>

        {formStatus === 'success' &&
        <DialogContent classes={{ root: classes.content }}>
          <UrlifeDialogSuccess
            className={classes.successText}
            message={'An email will be sent when space is available for you to join the beta.'} />

          <UrlifeButton
            onClick={this.closeModal.bind(this)}
            className={classes.submitButton}
          >OK</UrlifeButton>
        </DialogContent>
        }
        {formStatus !== 'success' &&
          <ValidateForm
            onSubmit={this.send()}
            onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}>
            {(form) => (
              <React.Fragment>
                <DialogContent classes={{ root: classes.content }}>
                  {formStatus === 'fail' &&
                <UrlifeDialogError message={'Account already exists for user'} />}
                  {form.renderField(
                    'fullName',
                    [],
                    (field, fieldData) => (
                      <FormControl error={fieldData.errors.length > 0}>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="fullName"
                          placeholder="Full Name"
                          type="text"
                          fullWidth
                          value={fields.fullName || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          InputProps={{
                            classes: {
                              input: classes.input,
                              underline: classes.underline
                            }
                          }}
                        />
                        {fieldData.errors.map((error, i) => (
                          <FormHelperText key={i}>
                            {error}
                          </FormHelperText>
                        ))}
                      </FormControl>
                    ))}
                  {form.renderField(
                    'userEmail',
                    ['required', 'email'],
                    (field, fieldData) => (
                      <FormControl error={fieldData.errors.length > 0}>
                        <TextField
                          margin="dense"
                          name="userEmail"
                          placeholder="Email Address"
                          type="email"
                          fullWidth
                          value={fields.userEmail || ''}
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          InputProps={{
                            classes: {
                              input: classes.input,
                              underline: classes.underline
                            }
                          }}
                        />
                        {fieldData.errors.map((error, i) => (
                          <FormHelperText key={i}>
                            {error}
                          </FormHelperText>
                        ))}
                      </FormControl>
                    ))}
                </DialogContent>
                <DialogActions classes={{ root: classes.buttonHolder }}>
                  <UrlifeButton
                    type="submit"
                    disabled={formStatus === 'sending' || !form.checkFormValid()}
                    className={classes.submitButton }
                  >
                    Request Invite
                  </UrlifeButton>
                </DialogActions>
              </React.Fragment>
            )}
          </ValidateForm>
        }
      </div>
    )
  }
}

UrlifeInviteComingSoonDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  sendInvite: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    sendInvite: function (email, fullName) {
      return dispatch(getUserComingSoonInvite(email, fullName))
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(UrlifeInviteComingSoonDialog))
