import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  commonStyles,
  getLeastCompleteProjectSku,
  getPackageIdFromPackageSkuId,
  getPackageType,
  history,
  mapProjectStatusToStage
} from '../../../helpers'
import { PROJECT_STAGES, PROJECT_STATUSES } from '../../../constants'
import ProjectTitleRow from '../../common/ProjectTitleRow'
import ProjectStatusBar from '../../common/ProjectStatusBar/ProjectStatusBar'
import ProjectDetailsBox from '../../common/ProjectDetailsBox'
import FilesView from '../../common/files/FilesView'
import StickyBottomBar from '../../common/StickyBottomBar'
import UrlifeButton from '../../common/UrlifeButton'
import Congratulations from '../../createmovie/components/Steps/Congratulations'
import ProjectChat from '../../common/chat/ProjectChat'
import {
  clearMoreMenuActions,
  fetchAllProjectFiles,
  getPackageSku,
  setMoreMenuAction,
  setTopBarHeaders,
  setTopBarType
} from '../../../actions'

/* eslint-disable sort-keys */
const styles = {
  projectStatusHolder: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  },
  projectDetailsHolder: {
    marginTop: 20,
    marginBottom: -15
  },
  chatContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 15
  },
  titleStatusBlock: {
    marginTop: 0,
    marginBottom: -20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...commonStyles.media(767, {
      flexDirection: 'column'
    })
  },
  checkoutButton: {
    color: '#fff',
    background: '#01b7d7',
    border: '1px solid #01b7d7',
    textTransform: 'none',
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    '&:hover': {
      color: '#01b7d7',
      background: 'none'
    },
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
}
/* eslint-enable sort-keys */

class ProjectPageUser extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      packageType: null,
      submitted: false
    }
  }

  componentDidMount () {
    const {
      project,
      projectFilesFunction,
      setTopBarTypeFunction,
      setTopBarHeadersFunction,
      clearMoreMenuActionsFunction,
      setMoreMenuActionFunction,
      updateProject,
      deleteProject
    } = this.props
    this.setPackageType()
    projectFilesFunction(project.id, { method: 'ASC', sort: 'last_modified' })
    const projectSku = getLeastCompleteProjectSku(project)
    const statusId = projectSku && projectSku.statusId
    const stage = mapProjectStatusToStage(statusId)
    const isUserPrep = stage === PROJECT_STAGES.PREP
    setTopBarTypeFunction(isUserPrep ? 'project' : 'normal')
    if (isUserPrep) {
      const isCustomPrice = projectSku && !projectSku.price
      const isCustomNegotiation = isCustomPrice && statusId !== PROJECT_STATUSES.PREP_USER
      const isFree = (projectSku.price || projectSku.price === 0) && projectSku.price < 50
      const title = isCustomNegotiation
        ? 'Finalize custom details'
        : 'Review your project before ' + (isCustomPrice || isFree ? 'submitting' : 'checkout')
      setTopBarHeadersFunction(title)
      clearMoreMenuActionsFunction()
      setMoreMenuActionFunction('saveProject', updateProject)
      setMoreMenuActionFunction('deleteProject', deleteProject)
    }
  }

  setPackageType () {
    const {
      getPackageSkuFunction,
      project,
      packages
    } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const packageId = getPackageIdFromPackageSkuId(projectSku.packageSku, packages)
      if (packageId) {
        this.setState({ packageType: getPackageType(packages, packageId) })
      } else {
        getPackageSkuFunction(projectSku.packageSku)
          .then(sku => {
            this.setState({ packageType: getPackageType(packages, sku.packageId) })
          })
      }
    }
  }

  componentWillUnmount () {
    this.props.clearMoreMenuActionsFunction()
  }

  async onCheckoutClick () {
    const { project, updateProjectSku } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    const isCustomPrice = projectSku && !projectSku.price
    if (isCustomPrice) {
      await updateProjectSku({ statusId: PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION })
      this.setState({ submitted: true })
    } else {
      history.push(`/projects/${project.id}/checkout`)
    }
  }

  render () {
    const {
      classes,
      project,
      files,
      openChat,
      updateProject,
      deleteProject
    } = this.props
    const {
      packageType,
      submitted
    } = this.state
    const projectSku = getLeastCompleteProjectSku(project)
    const statusId = projectSku && projectSku.statusId
    const stage = mapProjectStatusToStage(statusId)
    const isUserPrep = stage === PROJECT_STAGES.PREP
    const isCustomPrice = isUserPrep && projectSku && !projectSku.price
    const isCustomNegotiation = isCustomPrice && statusId !== PROJECT_STATUSES.PREP_USER
    const chatDefault = openChat ? 'open' : 'closed'
    return (
      <React.Fragment>
        {!submitted &&
        <React.Fragment>
          {!isUserPrep && <React.Fragment>
            <div>
              <ProjectTitleRow
                project={project}
                canEdit={true}
                openChat={openChat}
                updateProject={updateProject}
                saveProject={updateProject}
                deleteProject={deleteProject}
              />
            </div>
            <div className={classes.projectStatusHolder}>
              <ProjectStatusBar packageType={packageType} project={project} type={'standalone'} />
            </div>
            <div className={classes.projectDetailsHolder}>
              <ProjectDetailsBox
                title={'Details'}
                show={['info', 'prompts', 'stars']}
                project={project}
                canEdit={true}
                columnLayout={'double'}
              />
            </div>
          </React.Fragment>}
          {isUserPrep && <React.Fragment>
            {isCustomNegotiation && <div className={classes.chatContainer}>
              <ProjectChat project={project} view={'drawer'} defaultState={chatDefault} />
            </div>}
            <div className={classes.titleStatusBlock}>
              <ProjectDetailsBox
                show={['title', 'info', 'status']}
                project={project}
                canEdit={true}
                columnLayout={'double'}
                updateProject={updateProject}
              />
            </div>
            <div className={classes.titleStatusBlock}>
              <ProjectDetailsBox
                show={['prompts', 'stars']}
                project={project}
                canEdit={true}
                columnLayout={'double'}
              />
            </div>
          </React.Fragment>}

          <FilesView
            type={'tile'}
            edit={true}
            project={project}
            files={files}
            canUpload={true}
            updateProject={updateProject}
          />
          {isUserPrep && !isCustomNegotiation &&
          <StickyBottomBar>
            <UrlifeButton
              className={classes.checkoutButton}
              disabled={!projectSku}
              onClick={this.onCheckoutClick.bind(this)}>
              {isCustomPrice ? 'Submit' : 'Check out'}
            </UrlifeButton>
          </StickyBottomBar>
          }
        </React.Fragment>
        }
        {submitted && <Congratulations project={project} packageType={packageType} isCustomPrice={isCustomPrice} />}
      </React.Fragment>
    )
  }
}

ProjectPageUser.propTypes = {
  classes: PropTypes.object.isRequired,
  clearMoreMenuActionsFunction: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  files: PropTypes.array,
  getPackageSkuFunction: PropTypes.func.isRequired,
  openChat: PropTypes.bool.isRequired,
  packages: PropTypes.array,
  project: PropTypes.object.isRequired,
  projectFilesFunction: PropTypes.func.isRequired,
  setMoreMenuActionFunction: PropTypes.func.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  setTopBarTypeFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  updateProjectSku: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    files: state.file.files,
    packages: state.packages.packages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearMoreMenuActionsFunction: function () {
      dispatch(clearMoreMenuActions())
    },
    getPackageSkuFunction: function (id) {
      return dispatch(getPackageSku(id))
    },
    projectFilesFunction: function (projectId, params) {
      dispatch(fetchAllProjectFiles(projectId, params))
    },
    setMoreMenuActionFunction: function (name, func) {
      dispatch(setMoreMenuAction(name, func))
    },
    setTopBarHeadersFunction: function (title) {
      dispatch(setTopBarHeaders('My project', title))
    },
    setTopBarTypeFunction: function (type) {
      dispatch(setTopBarType(type))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectPageUser))
