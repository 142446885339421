import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { commonStyles } from '../../helpers'
import PackageListV2 from '../common/Packages/components/PackageListV2'

/* eslint-disable sort-keys */
const styles = {
  root: {
    marginTop: -60,
    ...commonStyles.media(575, {
      marginTop: -5
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: -5
    })
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontWeight: '400',
    color: '#323232',
    marginBottom: 54
  },
  packages: {
    marginTop: 0,
    padding: 0
  }
}
/* eslint-enable sort-keys */

class Pricing extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <h2 className={classes.title}>Packages</h2>
        <PackageListV2 className={classes.packages}/>
      </div>
    )
  }
}

Pricing.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Pricing)
