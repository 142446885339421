import ApiService from '../services/ApiService'
import { api } from '../constants'
import { updateSystemFile, uploadFile } from './index'

export {
  createExploreFile,
  createVideo,
  deleteVideo,
  getVideo,
  getAllVideo,
  getAllVideoLooped,
  updateVideo
}

function getVideo (id) {
  return dispatch => {
    return new ApiService(api.ONE_EXPLORE,
      { videoId: id }, {}, false, dispatch)
      .get()
      .then(
        respond => respond,
        fail => Promise.reject(fail)
      )
  }
}

function createVideo (data) {
  return dispatch => {
    return new ApiService(api.EXPLORE, {}, {}, true, dispatch)
      .post(data)
  }
}

function updateVideo (id, data) {
  return dispatch => {
    return new ApiService(api.ONE_EXPLORE, { videoId: id }, {}, true, dispatch)
      .put(data)
  }
}

function deleteVideo (id) {
  return dispatch => {
    return new ApiService(api.ONE_EXPLORE, { videoId: id }, {}, true, dispatch)
      .delete()
  }
}

function getAllVideo (params = {}) {
  return dispatch => {
    return new ApiService(api.EXPLORE,
      {}, params, false, dispatch)
      .get()
  }
}

function getAllVideoLooped (params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let videos = []

      const loop = params => {
        dispatch(getAllVideo(params))
          .then(response => {
            videos = videos.concat(response.data)
            if (response.nextCursor) {
              loop({ ...params, cursor: response.nextCursor })
            } else {
              resolve(videos)
            }
          })
          .catch(err => reject(err))
      }
      loop(params)
    })
  }
}

function createExploreFile (fileType, file, fileObject) {
  return dispatch => {
    return new ApiService(api.SYSTEM_FILES, { fileType }, {}, true, dispatch)
      .post(file)
      .then(
        response => {
          const responseFileData = response.data[0]
          return dispatch(uploadFile(
            null,
            fileObject,
            responseFileData.id,
            responseFileData.fileExt,
            'explore',
            'system',
            'system/' + responseFileData.id + '.' + responseFileData.fileExt,
            (cognitoInfo) => {
              return dispatch(updateSystemFile(
                { id: responseFileData.id, status: 3 },
                cognitoInfo.IdentityId)
              )
            }
          ))
        }
      )
  }
}
