import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import { closeMoreMenu, projectInfo, useCode, verifyProjectCode } from '../../actions'
import { history } from '../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = () => ({
  form: {
    padding: 40,
    '&._payment': {
      padding: '0 0 20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  label: {
    background: '0f0'
  },
  fieldContainer: {
    flexGrow: 1
  },
  button: {
    width: 220,
    height: 46,
    '&._payment': {
      width: 'auto',
      marginLeft: 10
    }
  },
  successContainer: {
    padding: 40,
    '& h3': {
      color: '#323232',
      fontSize: 18,
      fontWeight: 600
    },
    '& p': {
      color: '#323232',
      fontSize: 16,
      fontWeight: 500
    }
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  promoIcon: {
    width: 20,
    height: 20,
    marginTop: -6,
    marginRight: 5
  }
})
/* eslint-enable sort-keys */

export class UsePromo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      code: '',
      coupon: null,
      errorMessage: '',
      projectId: null,
      success: false,
      valid: false,
      validationStatus: 'none'
    }
    this.handleChangeValue = this.handleChangeValue.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  formValidator () {
    const { code } = this.state
    const isCodeValid = code.trim().length > 0

    if (isCodeValid) {
      this.setState({ valid: true, validationStatus: 'success' })
    } else if (this.state.valid) {
      this.setState({ valid: false, validationStatus: 'error' })
    }
  }

  handleError (res) {
    let errorMessage = ''
    const redeemedError = res.message.match(/This code is already redeemed/)
    const duplicateError = res.message.match(/Duplicate entry/)
    const limitError = res.message.match(/Promocode usage limit exceeded/)
    const expiredError = res.message.match(/promocode has expired/)
    if (duplicateError) {
      errorMessage = 'You have already used this code'
    } else if (limitError || expiredError) {
      errorMessage = 'This code is no longer valid'
    } else if (redeemedError) {
      errorMessage = 'This code has already been used'
    } else {
      errorMessage = 'This code does not exist'
    }
    this.setState({
      errorMessage,
      validationStatus: 'error'
    })
  }

  handleChangeValue (event) {
    const value = event.target.value
    this.setState({
      code: value
    }, () => {
      this.formValidator()
    })
  };

  redeemCode () {
    const { dispatch, onSuccess, isPayment } = this.props
    const { code, valid } = this.state
    if (valid) {
      dispatch(useCode(code))
        .then(res => {
          if (res.status === 'success') {
            if (isPayment && res.data.codeType === 'projectcode') {
              // Should never happen, but this case is handled for completeness
              this.setState({
                errorMessage: 'The code you entered was not a promo code',
                validationStatus: 'error'
              })
            } else {
              if (onSuccess) {
                onSuccess(res.data)
                this.setState({
                  errorMessage: ''
                })
              } else {
                this.setState({
                  coupon: res.data.coupon || null,
                  errorMessage: '',
                  projectId: res.data.projectId || null,
                  success: true
                })
                if (res.data.projectId) {
                  dispatch(projectInfo(res.data.projectId)).then(project => {
                    dispatch({
                      projects: [project],
                      type: 'PREPEND_PROJECTS'
                    })
                  })
                }
              }
            }
          } else {
            this.handleError(res)
          }
        })
        .catch(e => {
          this.handleError(e)
        })
    }
  }

  handleSubmit (e) {
    const { dispatch, isPayment } = this.props
    const { code, valid } = this.state
    e.preventDefault()
    if (valid) {
      if (isPayment) {
        dispatch(verifyProjectCode({ code }))
          .then(res => {
            if (res.valid) {
              this.setState({
                errorMessage: 'This code can not be used during checkout. Please use this code on the Home page.',
                validationStatus: 'error'
              })
            } else {
              this.redeemCode()
            }
          })
          .catch(e => {
            this.setState({
              errorMessage: 'Something went wrong. Please try again.',
              validationStatus: 'error'
            })
          })
      } else {
        this.redeemCode()
      }
    }
  }

  async handleStart () {
    const { dispatch } = this.props
    const { projectId } = this.state
    let path = '/createmovie'
    if (projectId) {
      path += '/continue/' + projectId
    }
    dispatch(closeMoreMenu())
    history.push(path, {
      lastLocation: history.location
    })
  }

  render () {
    const { classes, isPayment } = this.props
    const {
      code,
      errorMessage,
      validationStatus,
      success,
      valid,
      coupon,
      projectId
    } = this.state
    return (
      <div>
        {!success &&
          <form
            onSubmit={e => this.handleSubmit(e)}
            className={classNames(classes.form, { _payment: isPayment })}
          >
            <div className={classes.fieldContainer}>
              <UrlifeTextField
                autoFocus={!isPayment}
                label={
                  <div className={classes.labelContainer}>
                    {isPayment ? '' : 'Enter code'}
                  </div>
                }
                margin="dense"
                id="code"
                name="promoCode"
                type="text"
                fullWidth
                onChange={e => this.handleChangeValue(e)}
                defaultValue={code || ''}
                validationStatus={validationStatus}
                infoLabel={errorMessage}
              />
            </div>
            <UrlifeButton
              className={classNames(classes.button, { _payment: isPayment })}
              onClick={ e => this.handleSubmit(e)}
              style={errorMessage.length > 0 && !isPayment ? { marginTop: 30 } : {}}
              disabled={!valid}
            >
              Submit
            </UrlifeButton>
          </form>
        }
        {success &&
          <div className={classes.successContainer}>
            <h3>Success!</h3>
            <React.Fragment>
              {projectId &&
              <p>
                You have unlocked a free project!
              </p>
              }
              {!projectId &&
              <p>
                You have unlocked a discount of
                {coupon.percent_off ? ` ${coupon.percent_off}% ` : ` $${coupon.amount_off / 100} `}
                off your next project!
              </p>
              }
            </React.Fragment>
            <UrlifeButton
              onClick={this.handleStart.bind(this)}
              className={classes.button}
            >
              Start now
            </UrlifeButton>
          </div>
        }

      </div>
    )
  }
}

UsePromo.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isPayment: PropTypes.bool,
  onSuccess: PropTypes.func
}

export default withStyles(styles)(connect()(UsePromo))
