import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import UrlifeButton from '../common/UrlifeButton'
import UrlifeTextField from '../common/UrlifeTextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import ValidateForm from '../common/form/ValidateForm'

import {
  resetLogin,
  toggleModal,
  useCode,
  userLogin
} from '../../actions'
import {
  commonStyles,
  getCodeResultDescription,
  history
} from '../../helpers'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = () => ({
  loginHeader: {
    textAlign: 'left',
    padding: '40px 50px 0px 50px',
    ...commonStyles.media(480, {
      padding: '20px 20px 0px 20px'
    }),
    '&._noForm': {
      textAlign: 'center'
    }
  },
  headerRoot: {
    color: '#333',
    fontSize: 20,
    fontWeight: 600,
    ...commonStyles.media(480, {
      fontSize: 16
    })
  },
  content: {
    minHeight: '140px',
    padding: '0 50px 22px 50px',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      padding: '0 20px 10px 20px'
    })
  },
  describeText: {
    margin: '10px 0 0 0',
    maxWidth: '100%',
    color: '#333333',
    fontSize: 16,
    fontWeight: 400
  },
  buttonHolder: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 50px',
    ...commonStyles.media(480, {
      padding: '0 20px'
    }),
    '&._center': {
      alignItems: 'center'
    }
  },
  useButton: {
    margin: 0,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  loginButton: {
    marginBottom: 10,
    width: '100%',
    height: 46,
    fontSize: 14,
    fontWeight: 700
  },
  registerButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    width: '100%',
    textAlign: 'center',
    color: '#333',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1,
    padding: 0,
    margin: '20px 0',
    textTransform: 'none'
  },
  forgottenButton: {
    '&:hover': {
      backgroundColor: 'inherit'
    },
    opacity: 0.6,
    color: '#666666',
    fontSize: 12,
    fontWeight: 500,
    padding: '0 0 16px 0',
    lineHeight: '20px',
    textTransform: 'none'
  },
  codeResultButton: {
    marginTop: 30,
    marginBottom: 30,
    width: 200,
    height: 46,
    fontSize: 14,
    fontWeight: 700
  }
})
/* eslint-enable sort-keys */

export class UrlifeLoginDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      codeResult: null,
      fields: {},
      formStatus: '' // '', sending, success, fail
    }
    props.resetLogin()
  }

  handleLogin () {
    const { userLogin, code } = this.props
    const { userEmail, userPass } = this.state.fields
    if (userPass && userPass) {
      this.setState({ formStatus: 'sending' }, () => {
        userLogin(userEmail, userPass, this.onLoginDone.bind(this), !code)
      })
    }
  }

  async onLoginDone (error) {
    const {
      code,
      useCodeFunction,
      setCode
    } = this.props
    let codeResult = null
    if (code && !error) {
      try {
        codeResult = await useCodeFunction(code)
      } catch (e) {
        codeResult = {
          message: e.error,
          status: 'fail'
        }
      }
      setCode('')
    }
    this.setState({
      codeResult,
      formStatus: ''
    })
  }

  onFieldChange (field, fieldData) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field.name]: fieldData.value
      },
      formStatus: ''
    })
    if (this.props.loginFailed) {
      this.props.resetLogin()
    }
  }

  handleGoToForgotten () {
    this.props.toggleModal(true, 'forgotten')
  }

  useCode () {
    this.props.toggleModal(true, 'useCode')
  }

  closeCodeResult () {
    const { toggleModal } = this.props
    const { codeResult } = this.state
    toggleModal(false, '')
    if (codeResult && codeResult.data) {
      if (codeResult.data.projectId) {
        history.push('/createmovie/continue/' + codeResult.data.projectId)
      } else if (codeResult.data.coupon) {
        history.push('/createmovie')
      }
    }
  }

  render () {
    const { fields, formStatus } = this.state

    const {
      classes,
      registerSwitch,
      loginFailed,
      code,
      skinData
    } = this.props
    const { codeResult } = this.state
    const codeResultDescription = getCodeResultDescription(codeResult, code, skinData)
    const title = (!!skinData && skinData.loginTitle) || 'Success!'
    const buttonText = (!!skinData && skinData.loginButton) || 'View package'

    return (
      <div>
        {!codeResult && <React.Fragment>
          <DialogTitle
            id="form-dialog-title"
            className={classes.loginHeader}
            classes={{ root: classes.headerRoot }}
            disableTypography
          >
            Login
          </DialogTitle>
          <ValidateForm
            onSubmit={this.handleLogin.bind(this)}
            onFieldChange={(field, fieldData) => this.onFieldChange(field, fieldData)}
            validateOnMount={true}>
            {(form) => (
              <React.Fragment>
                <DialogContent classes={{ root: classes.content }}>
                  {form.renderField(
                    'userEmail',
                    ['required', 'email'],
                    (field, fieldData) => {
                      let validationStatus = 'none'
                      let errorMessage = ''
                      if (loginFailed) {
                        validationStatus = 'error'
                      } else if (fields.userEmail && fields.userEmail.length > 0) {
                        if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                          validationStatus = 'error'
                          errorMessage = fieldData.errors[0]
                        } else {
                          validationStatus = 'success'
                        }
                      }
                      return (
                        <UrlifeTextField
                          autoFocus
                          label="Email"
                          margin="dense"
                          id="name"
                          name="userEmail"
                          type="email"
                          fullWidth
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          defaultValue={fields.userEmail || ''}
                          validationStatus={validationStatus}
                          infoLabel={errorMessage}
                        />
                      )
                    }
                  )}
                  {form.renderField(
                    'userPass',
                    ['required'],
                    (field, fieldData) => {
                      let validationStatus = 'none'
                      let errorMessage = ''
                      if (loginFailed) {
                        validationStatus = 'error'
                        errorMessage = 'Wrong Username or Password'
                      } else if (fields.userPass && fields.userPass.length > 0) {
                        if (fieldData.errors[0] && fieldData.errors[0].length > 0) {
                          validationStatus = 'error'
                          errorMessage = fieldData.errors[0]
                        }
                      }
                      return (
                        <UrlifeTextField
                          margin="dense"
                          id="pass"
                          label="Password"
                          name="userPass"
                          type="password"
                          fullWidth
                          onChange={e => field.onChange(e)}
                          onBlur={e => field.onBlur(e)}
                          defaultValue={fields.userPass || ''}
                          validationStatus={validationStatus}
                          infoLabel={errorMessage}
                        />
                      )
                    }
                  )}
                </DialogContent>
                <DialogActions classes={{ root: classes.buttonHolder }} disableSpacing>
                  <Button
                    onClick={this.handleGoToForgotten.bind(this)}
                    classes={{ root: classes.forgottenButton }}
                  >
                    Forgot Password?
                  </Button>

                  <UrlifeButton
                    type="submit"
                    className={classes.loginButton}
                    disabled={formStatus === 'sending' || !form.checkFormValid()}
                  >
                    Log in
                  </UrlifeButton>
                  {!code && <UrlifeButton
                    inverted
                    className={classes.useButton}
                    onClick={this.useCode.bind(this)}
                  >
                    Use Project Code
                  </UrlifeButton>}
                  <Button
                    onClick={registerSwitch}
                    classes={{ root: classes.registerButton }}
                  >
                    Create Account
                  </Button>
                </DialogActions>
              </React.Fragment>
            )}
          </ValidateForm>
        </React.Fragment>}
        {codeResult && <React.Fragment>
          <DialogTitle
            id="form-dialog-title"
            className={classNames(classes.loginHeader, '_noForm')}
          >
            {codeResult.status === 'success' &&
            <div>{title}</div>
            }
            {codeResultDescription.line1 &&
            <p className={classNames(classes.describeText, '_codeResult')}>{codeResultDescription.line1}</p>
            }
            {codeResultDescription.line2 &&
            <p className={classNames(classes.describeText, '_codeResult')}>{codeResultDescription.line2}</p>
            }
          </DialogTitle>
          <DialogActions
            classes={
              { root: classNames(classes.buttonHolder, '_center') }
            }
            disableSpacing>
            <UrlifeButton
              className={classes.codeResultButton}
              onClick={this.closeCodeResult.bind(this)}
            >
              {codeResult.data && codeResult.data.projectId
                ? buttonText
                : codeResult.data && codeResult.data.coupon
                  ? 'Start project'
                  : 'OK'
              }
            </UrlifeButton>
          </DialogActions>
        </React.Fragment>}
      </div>
    )
  }
}

UrlifeLoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  loginFailed: PropTypes.bool,
  registerSwitch: PropTypes.func.isRequired,
  resetLogin: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  skinData: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  useCodeFunction: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    loginFailed: state.user.loginFailed,
    skinData: state.skinning.skinData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    resetLogin: function () {
      dispatch(resetLogin())
    },
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    },
    useCodeFunction: function (code) {
      return dispatch(useCode(code))
    },
    userLogin: function (email, password, onSuccess, closeModal) {
      dispatch(userLogin(email, password, onSuccess, closeModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UrlifeLoginDialog))
