import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  commonStyles,
  getLeastCompleteProjectSkuStatus,
  mapProjectStatusToStage,
  toLocale
} from '../../helpers'
import { getImageRotationCss } from '../../helpers/imageRotationCss'
import { cacheSignedUrl } from '../../services/CacheSignedUrls'
import classNames from 'classnames'
import {
  PROJECT_STAGES,
  PROJECT_STATUSES
} from '../../constants'
import ProjectBadge from '../common/ProjectBadge'
import { fileProgressType } from '../../reducers/fileProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import projectThumbnail from '../../assets/img/defaultThumbnail/default.jpg'

/* eslint-disable sort-keys */
const styles = {
  mostRecentWrap: {
    overflow: 'hidden',
    boxShadow: '0 2px 13px rgba (0, 0, 0, 0.07)',
    borderRadius: '3px'
  },
  mostRecent: {
    position: 'relative',
    cursor: 'pointer'
  },
  mostRecentBlock: {
    width: '100%',
    paddingTop: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#eff2f5'
  },
  coverImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  projectPlaceholder: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    color: '#dde3e8',
    fontSize: 107,
    ...commonStyles.media(1279, {
      fontSize: 76
    }),
    ...commonStyles.media(767, {
      fontSize: 50
    }),
    ...commonStyles.media(575, {
      fontSize: 86
    })
  },
  gradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(170, 170, 170, 0)',
    backgroundImage: 'linear-gradient(4deg, rgba(37, 37, 37, 0.75) 0%, rgba(37, 37, 37, 0) 100%)'
  },
  infoText: {
    position: 'absolute',
    left: 15,
    right: 15,
    bottom: 20,
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    color: '#ffffff',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&._title': {
      bottom: 37
    },
    '&._date': {
      fontSize: 12,
      opacity: 0.8
    },
    ...commonStyles.media(991, {
      fontSize: 14,
      bottom: 18,
      '&._title': {
        bottom: 33
      },
      '&._date': {
        fontSize: 10
      }
    }),
    ...commonStyles.media(575, {
      fontSize: 13,
      bottom: 16,
      '&._title': {
        bottom: 30
      },
      '&._date': {
        fontSize: 9
      }
    })
  },
  projectStatusBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '9px',
    overflow: 'hidden'
  },
  projectStatusBarSection: {
    position: 'absolute',
    top: 0,
    backgroundColor: '#bd6ad3',
    height: '9px',
    width: '33.333%',
    borderRadius: '0 5px 5px 0',
    '&::before': {
      content: "''",
      height: '11px',
      width: '7px',
      left: -7,
      top: -1,
      position: 'absolute',
      backgroundColor: 'transparent',
      borderRadius: '0 7px 7px 0',
      boxShadow: '1px 0px 0px 1px #bd6ad3'
    },
    '&._notDone': {
      backgroundColor: '#ffffff',
      opacity: 0.4,
      '&::before': {
        boxShadow: '1px 0px 0px 1px #ffffff'
      }
    },
    '&._userPrep': {
      left: 0,
      width: 'calc(33.333% + 1px)'
    },
    '&._queued': {
      left: 'calc(33.333% + 2px)',
      width: 'calc(33.333% - 2px)'
    },
    '&._inProduction': {
      left: 'calc(66.666% + 1px)',
      borderRadius: 0
    }
  },
  uploadProgressBarContainer: {
    position: 'absolute',
    bottom: 10,
    background: 'none',
    minWidth: '100%',
    minHeight: 8,
    height: '8px',
    borderRadius: '0 2px 2px 0'
  }
}
/* eslint-enable sort-keys */

class MostRecentProjectBox extends React.Component {
  constructor (props) {
    super(props)
    const { project } = props
    this.state = { coverAdditionalStyles: {} }
    this.fitCoverToParent = () => {
      if (project.coverFileOrientation && [6, 8, 5, 7].includes(project.coverFileOrientation)) {
        /**
         * need to fit to parent if we rotate image
         */
        const parent = this.coverRef.parentElement
        this.setState({
          coverAdditionalStyles: {
            height: parent.offsetWidth || 'auto',
            width: parent.offsetHeight || 'auto'
          }
        })
      }
    }
  }

  getFiles (progressType) {
    const { fileProgress, project } = this.props
    const projectId = project ? project.id : ''
    const files = fileProgress.filter(file => {
      return (file.projectId === projectId && file.progressType === progressType)
    })
    return files
  }

  calculateProgress (files) {
    let totalSize = 0
    let finishedSize = 0
    this.lastInterval = Date.now()

    files.forEach(file => {
      finishedSize += file.loaded || 0
      totalSize += file.total || 0
    })

    let loaded = finishedSize
    if (loaded === totalSize) {
      loaded = 0
    }
    this.lastLoaded = loaded
    return totalSize ? Math.round((finishedSize / totalSize * 100 * 100)) / 100 : 0
  }

  componentDidMount () {
    this.fitCoverToParent()
    window.addEventListener('resize', this.fitCoverToParent)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.fitCoverToParent)
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { project, fileBatch, skinnedThumbnail } = this.props
    const { coverAdditionalStyles } = this.state
    const uploadingFiles = this.getFiles(fileProgressType.upload)
    if (nextState.coverAdditionalStyles !== coverAdditionalStyles) {
      return true
    }
    if (nextProps.skinnedThumbnail !== skinnedThumbnail) {
      return true
    }
    if ((fileBatch[project.id] && nextProps.fileBatch[project.id]) || uploadingFiles.length > 0) {
      const projectFileBatch = nextProps.fileBatch[project.id]
      const fileBatchComplete =
        (
          projectFileBatch &&
          projectFileBatch.completedCount === projectFileBatch.totalCount
        ) ||
        !projectFileBatch
      if (fileBatchComplete && uploadingFiles) {
        return Date.now() - this.lastInterval > 1000
      } else {
        return fileBatchComplete
      }
    }
    if (getLeastCompleteProjectSkuStatus(project) !== getLeastCompleteProjectSkuStatus(nextProps.project)) {
      return true
    }
    if (!fileBatch[project.id] && !nextProps.fileBatch[project.id]) {
      return false
    }
    return true
  }

  render () {
    const { classes, project, freeCoupons, fileBatch, skinnedThumbnail } = this.props
    const { coverAdditionalStyles } = this.state
    const status = getLeastCompleteProjectSkuStatus(project)
    const stage = mapProjectStatusToStage(status)
    let hasFreeCoupon = false
    freeCoupons && freeCoupons.forEach(coupon => {
      project.projectSkus.forEach(sku => {
        const isUnpaid = mapProjectStatusToStage(sku.statusId) === PROJECT_STAGES.PREP
        if (isUnpaid && coupon.skus.includes(sku.packageSku)) {
          hasFreeCoupon = true
        }
      })
    })
    let isFree = false
    project && project.projectSkus && project.projectSkus.forEach(sku => {
      const isUnpaid = mapProjectStatusToStage(sku.statusId) === PROJECT_STAGES.PREP
      if (isUnpaid) {
        isFree = sku && sku.price < 50
      }
    })

    const uploadingFiles = this.getFiles(fileProgressType.upload)
    const progress = !!uploadingFiles && this.calculateProgress(uploadingFiles)
    const projectFileBatch = project ? fileBatch[project.id] : null
    const isProcessing = projectFileBatch &&
      projectFileBatch.totalCount !== 0 &&
      projectFileBatch.completedCount < projectFileBatch.totalCount
    const isActive = (!!progress && progress < 100) || isProcessing

    let projectBadgeLabel = ''
    if (isActive) {
      projectBadgeLabel = 'Upload in progress'
    } else if (status === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL) {
      projectBadgeLabel = 'Ready for your review'
    } else if (status === PROJECT_STATUSES.PREP_WAIT_PAYMENT) {
      projectBadgeLabel = 'Finalize with email'
    } else if (status === PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION) {
      projectBadgeLabel = 'Finalize custom details'
    } else if (hasFreeCoupon || isFree) {
      projectBadgeLabel = 'Free Project'
    }
    return (
      <div className={classes.mostRecentWrap}>
        {project && (
          <div className={classes.mostRecent}>
            <div className={classes.mostRecentBlock}>
              {project.coverUrl &&
                <div className={classes.coverImage}
                  ref={ref => { this.coverRef = ref }}
                  style={
                    {
                      backgroundImage: `url(${cacheSignedUrl.get(project.coverUrl)})`,
                      ...getImageRotationCss(project.coverFileOrientation),
                      ...coverAdditionalStyles
                    }
                  } >
                </div>
              }
              {!project.coverUrl &&
                <div className={classes.coverImage}
                  ref={ref => { this.coverRef = ref }}
                  style={
                    {
                      backgroundImage: `url(${skinnedThumbnail || projectThumbnail})`,
                      ...coverAdditionalStyles
                    }
                  } >
                </div>
              }
              {projectBadgeLabel && <ProjectBadge label={projectBadgeLabel}/>}
              <div className={classes.gradient} />
              <div className={classNames(classes.infoText, '_title')}>{project.title}</div>
              <div
                className={classNames(classes.infoText, '_date')}
              >
                {toLocale(new Date(project.createdAt), { forMyMovie: true })}
              </div>
              {isActive &&
                <React.Fragment>
                  {isProcessing &&
                    <LinearProgress
                      className={classes.uploadProgressBarContainer}
                    />
                  }
                  {!isProcessing &&
                  <LinearProgress
                    className={classes.uploadProgressBarContainer}
                    variant="determinate"
                    value={progress}
                  />
                  }
                </React.Fragment>
              }
              <div className={classes.projectStatusBar}>
                <div
                  className={classNames(
                    classes.projectStatusBarSection,
                    '_inProduction',
                    { _notDone: stage < PROJECT_STAGES.PRODUCTION }
                  )}
                />
                <div
                  className={classNames(
                    classes.projectStatusBarSection,
                    '_queued',
                    { _notDone: stage < PROJECT_STAGES.QUEUED }
                  )}
                />
                <div
                  className={classNames(
                    classes.projectStatusBarSection,
                    '_userPrep',
                    { _notDone: stage < PROJECT_STAGES.PREP }
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

MostRecentProjectBox.propTypes = {
  classes: PropTypes.object.isRequired,
  fileBatch: PropTypes.object,
  fileProgress: PropTypes.array,
  freeCoupons: PropTypes.array,
  project: PropTypes.object.isRequired,
  skinnedThumbnail: PropTypes.string
}

const mapStateToProps = state => {
  return {
    fileBatch: state.fileBatch,
    fileProgress: Object.values(state.fileProgress)
  }
}

export default withStyles(styles)(connect(mapStateToProps)(MostRecentProjectBox))
