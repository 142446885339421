import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CancelMenuIcon (props) {
  return (
    <SvgIcon {...props}>
      <path fill="5334b6" fillRule="evenodd" d="M16.986 16.278l-.738.737L8.47 9.237.722 16.985l-.707-.707L7.763 8.53-.015.752.722.014 8.5 7.792 16.278.014l.707.707-7.778 7.778 7.779 7.779z"/>
    </SvgIcon>
  )
}
