import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CloseIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g><g transform="rotate(-315 10 10)"><path d="M19.76 9.999c0 .431-.35.78-.782.78H10.78v11.904a.781.781 0 1 1-1.563 0V10.78H-2.685a.781.781 0 1 1 0-1.563H9.217V-2.685a.781.781 0 1 1 1.563 0V9.217h8.198c.432 0 .782.35.782.782zm3.71 0c0 .431-.35.78-.783.78h-1.155a.782.782 0 1 1 0-1.562h1.155c.433 0 .784.35.784.782z"/></g></g>
    </SvgIcon>
  )
}
