import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function LogOutIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <g><g><g><g><path fill="#fff" d="M15.765 16.34v6.494c0 1.175-.956 2.131-2.131 2.131H2.939a2.133 2.133 0 0 1-2.13-2.13V2.171c0-1.175.955-2.13 2.13-2.13h10.695c1.175 0 2.13.955 2.13 2.13v2.376a.71.71 0 1 1-1.42 0V2.172a.71.71 0 0 0-.71-.71H2.939a.71.71 0 0 0-.71.71v20.662c0 .392.318.71.71.71h10.695a.71.71 0 0 0 .71-.71V16.34a.71.71 0 1 1 1.42 0zm.008-9.33V8.48c0 .392-.32.71-.714.71a.712.712 0 0 1-.715-.71V7.01c0-.393.32-.71.715-.71.394 0 .714.317.714.71zm8.038 5.494a.72.72 0 0 1-.014.14c-.004.018-.01.035-.016.053-.007.026-.014.053-.024.079-.009.02-.02.039-.031.058-.011.022-.02.044-.034.064a.72.72 0 0 1-.077.094l-.011.014-4.788 4.79a.708.708 0 0 1-1.004 0 .71.71 0 0 1 0-1.005l3.576-3.577H8.858a.71.71 0 0 1 0-1.42h12.53l-3.576-3.579a.71.71 0 0 1 1.004-1.004l4.786 4.788a.748.748 0 0 1 .091.111l.022.04c.015.027.03.054.043.083.008.02.012.04.018.061.008.024.016.047.021.071a.702.702 0 0 1 .014.13v.009z"/></g></g></g></g>
    </SvgIcon>
  )
}

LogOutIcon.propTypes = {
  color: PropTypes.string
}
