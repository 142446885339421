import ApiService from '../services/ApiService'
import { api } from './../constants'

export {
  getAllPackageSkus,
  getPackages,
  getPackageSku
}

function getPackages () {
  return dispatch => {
    dispatch({ type: 'GETTING_PACKAGES' })
    return new ApiService(api.PACKAGES, {}, {}, false, dispatch)
      .get()
      .then(
        data => {
          return dispatch({ packages: data.data, type: 'GOT_PACKAGES' })
        },
        fail => {
          return dispatch({ fail, type: 'GET_PACKAGES_FAILED' })
        }
      )
  }
}

function getPackageSku (id) {
  return dispatch => {
    return new ApiService(api.ONE_PACKAGE_SKU, { skuId: id }, {}, true, dispatch)
      .get()
      .then(
        data => {
          return data.data
        },
        fail => {
          return fail
        }
      )
  }
}

function getAllPackageSkus (packageId, filter) {
  return dispatch => {
    return new ApiService(api.PACKAGE_SKUS, { packageId }, { filter }, true, dispatch)
      .get()
      .then(
        data => {
          return dispatch({ packageId, skus: data.data, type: 'GOT_SKUS' })
        },
        fail => {
          return fail
        }
      )
  }
}
