import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function PasswordHide (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 11">
      <g><g><path fill="#999" d="M8.567 8.293c-1.626 0-2.949-1.31-2.949-2.92 0-1.609 1.323-2.918 2.95-2.918 1.626 0 2.948 1.31 2.948 2.919 0 .314-.257.57-.575.57a.573.573 0 0 1-.576-.57 1.79 1.79 0 0 0-1.798-1.78 1.79 1.79 0 0 0-1.797 1.78c0 .98.806 1.78 1.797 1.78A1.8 1.8 0 0 0 9.95 6.51a.58.58 0 0 1 .81-.074c.246.201.279.56.076.802a2.955 2.955 0 0 1-2.269 1.054zM8.39 10.6C5.482 10.6 2.707 9 .12 5.876a.617.617 0 0 1 .002-.734C.254 4.954 3.696.4 8.619.4c2.908 0 5.684 1.6 8.25 4.743a.588.588 0 0 1 .02.715c-.134.188-3.575 4.742-8.498 4.742zM1.357 5.482C3.6 8.097 5.957 9.414 8.39 9.414c3.574 0 6.387-2.88 7.262-3.896-2.243-2.615-4.6-3.932-7.034-3.932-3.574 0-6.388 2.879-7.262 3.896z" /></g></g>
    </SvgIcon>
  )
}
