import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import UrlifePaper from './components/UrlifePaper'
import Footer from '../../MainLayout/Footer'
import Slider from 'react-slick'
import MarketingPageCTA from '../../marketing/MarketingPageCTA'
import { toggleModal } from '../../../actions'
import UrlifeButton from '../../common/UrlifeButton'
import { AppStoreBadge } from '../../common/SVG'
import jackImgLarge from '../../../assets/img/marketing/parallaxJackGiarraputo.jpg'
import jackImgSmall from '../../../assets/img/marketing/parallaxJackGiarraputoSmall.jpg'
import ryanImgLarge from '../../../assets/img/marketing/parallaxRyanHegenberger.jpg'
import ryanImgSmall from '../../../assets/img/marketing/parallaxRyanHegenbergerSmall.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/* eslint-disable sort-keys */
const styles = {
  layer: {
    position: 'relative',
    bottom: 0
  },
  layer5: {
    top: 816,
    ...commonStyles.mediaAnd(1279, 960, {
      top: 700
    }, 'max', 'min'),
    ...commonStyles.mediaAnd(959, 481, {
      top: 870
    }, 'max', 'min'),
    ...commonStyles.media(480, {
      top: 600
    })
  },
  group: {
    // marginBottom: '0vh',
    height: 'auto',
    marginBottom: 911,
    ...commonStyles.mediaAnd(1279, 960, {
      marginBottom: 795
    }, 'max', 'min'),
    ...commonStyles.media(959, {
      marginTop: 0,
      marginBottom: 975
    }),
    ...commonStyles.media(480, {
      marginBottom: 660
    })
  },
  founderContainer: {
    ...commonStyles.container(),
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -594,
    ...commonStyles.mediaAnd(1279, 960, {
      bottom: -545
    }, 'max', 'min'),
    ...commonStyles.mediaAnd(959, 481, {
      bottom: -500
    }, 'max', 'min'),
    ...commonStyles.media(480, {
      paddingTop: 0,
      bottom: 0,
      marginTop: 300
    })
  },
  headerStyle: {
    width: '87%',
    maxWidth: '400px',
    ...commonStyles.media(1279, {
      width: '100%',
      maxWidth: '325px'
    }),
    ...commonStyles.media(480, {
      width: '100%',
      maxWidth: '360px'
    })
  },
  paper: {
    maxWidth: 615,
    right: 56,
    bottom: 0,
    margin: 0,
    padding: '47px 100px',
    ...commonStyles.mediaAnd(1279, 701, {
      maxWidth: '600px',
      padding: '47px 47px',
      marginRight: 0,
      bottom: -20
    }, 'max', 'min'),
    ...commonStyles.mediaAnd(700, 481, {
      padding: 30,
      maxWidth: '461px',
      right: 13,
      bottom: -20
    }, 'max', 'min'),
    ...commonStyles.media(480, {
      width: 'calc(100% + 15px)',
      padding: 30,
      right: -20,
      bottom: -520
    })
  },
  infoContainer: {
    ...commonStyles.media(480, {
      width: 265
    })
  },
  portrait: {
    height: 125,
    ...commonStyles.media(480, {
      display: 'none'
    })
  },
  portraitSmall: {
    display: 'none',
    marginTop: 5,
    ...commonStyles.media(480, {
      display: 'block',
      width: 62,
      height: 62
    })
  },
  headerPerson: {
    color: '#323232',
    fontSize: 22,
    fontWeight: 500,
    margin: 0,
    marginLeft: -40,
    ...commonStyles.media(480, {
      fontSize: 18,
      marginTop: 5,
      marginLeft: -10,
      width: 200
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18
    })
  },
  title: {
    color: '#323232',
    fontSize: 11,
    fontWeight: 400,
    opacity: 0.7,
    marginLeft: -40,
    ...commonStyles.media(480, {
      display: 'inline-block',
      width: 162,
      fontSize: 12,
      lineHeight: '20px',
      marginTop: 5,
      marginLeft: -10
    })
  },
  text: {
    color: '#323232',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '19px',
    marginLeft: -40,
    ...commonStyles.media(480, {
      position: 'relative',
      left: -88,
      width: 318,
      margin: '5px 0',
      fontSize: 13,
      fontWeight: 500
    })
  },
  button: {
    display: 'block',
    margin: 'auto',
    width: 210,
    height: 46,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 50,
    ...commonStyles.mediaAnd(959, 481, {
      marginBottom: 'auto'
    }),
    ...commonStyles.media(480, {
      marginBottom: 'auto',
      marginTop: 50,
      fontSize: 12,
      height: 40,
      width: 181
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      height: 40,
      width: 181
    })
  },
  appStoreBadge: {
    display: 'flex',
    justifyContent: 'center',
    ...commonStyles.media(480, {
      marginTop: 50
    })
  },
  backgroundContainer: {
    ...commonStyles.container(),
    position: 'absolute',
    left: 0,
    right: 0,
    top: 146,
    height: 608,
    padding: '0px',
    ...commonStyles.mediaAnd(1279, 960, {
      top: 150,
      height: 440
    }, 'max', 'min'),
    ...commonStyles.mediaAnd(959, 481, {
      top: '540px',
      height: 280
    }, 'max', 'min'),
    ...commonStyles.media(480, {
      display: 'none'
    })
  },
  carouselContainer: {
    position: 'absolute',
    width: '100%',
    maxWidth: 872,
    height: 608,
    top: 0,
    left: 0,
    ...commonStyles.mediaAnd(1279, 960, {
      width: 630
    }, 'max', 'min'),
    ...commonStyles.media(959, {
      height: 290,
      width: 400
    }),
    ...commonStyles.media(480, {
      top: -10,
      width: '100%',
      height: 400
    })
  },
  carouselImg: {
    height: 'auto',
    maxHeight: 608,
    width: '100%',
    objectFit: 'cover',
    ...commonStyles.media(480, {
      maxHeight: 300
    })
  },
  pipe: {
    ...commonStyles.media(480, {
      display: 'none'
    })
  }
}
/* eslint-enable sort-keys */

const quotes = [
  {
    img: 'https://static.urlifemedia.com/founder_images/family.jpg'
  },
  {
    img: 'https://static.urlifemedia.com/founder_images/jack_1.jpg'
  },
  {
    img: 'https://static.urlifemedia.com/founder_images/travel.jpg'
  },
  {
    img: 'https://static.urlifemedia.com/founder_images/jack_2.jpg'
  }
]

class Founders extends React.PureComponent {
  openGetStartedModal () {
    this.props.toggleModal(true, 'getStarted')
  }

  render () {
    const { classes, getScrollRef, loggedIn } = this.props

    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000,
      className: 'current',
      dots: false,
      dotsClass: classes.dotsContainer,
      focusOnSelect: true,
      infinite: true,
      pauseOnHover: false,
      slidesToShow: 1,
      speed: 1500,
      swipeToSlide: true
    }

    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('Founders')} className={classes.group} index={34}>
        <ParallaxLayer layer='layer5' className={classes.layer}>
          <MarketingPageCTA className={classes.button}>
            {!loggedIn && <UrlifeButton className={classes.button} onClick={this.openGetStartedModal.bind(this)}>
              Get Started
            </UrlifeButton>}
            {loggedIn && <div className={classes.appStoreBadge}>
              <AppStoreBadge />
            </div>}
          </MarketingPageCTA>
          <div className={classes.backgroundContainer}>
            <div className={classes.carouselContainer}>
              <Slider {...settings}>
                {quotes.map((item, idx) => {
                  return (
                    <img key={idx} src={item.img} width="100%" alt="carousel" className={classes.carouselImg}/>
                  )
                })}
              </Slider>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer layer='layer4' className={classes.layer}>
          <div className={classes.founderContainer}>
            <UrlifePaper className={classes.paper} headerStyle={classes.headerStyle} header="Meet the founders">
              <Grid container justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Grid container className={classes.infoContainer} justify="flex-start" spacing={2}>
                    <Grid item xs={4}>
                      <img
                        className={classes.portrait}
                        src={jackImgLarge}
                        alt="Jack Giarraputo"
                      />
                      <img
                        className={classes.portraitSmall}
                        src={jackImgSmall}
                        alt="Jack Giarraputo"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <h4 className={classes.headerPerson}>Jack Giarraputo</h4>
                      <span className={classes.title}>
                        Co-founder URLIFE <span className={classes.pipe}>| </span>Hollywood producer
                      </span>
                      <p className={classes.text}>As a Hollywood producer, Jack made huge box office hits - but off-set,
                        he loves making more personal movies - capturing all the adventures he has with his family.</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.infoContainer} justify="flex-start" spacing={2}>
                    <Grid item xs={4}>
                      <img
                        className={classes.portrait}
                        src={ryanImgLarge}
                        alt="Ryan Hegenberger"
                      />
                      <img
                        className={classes.portraitSmall}
                        src={ryanImgSmall}
                        alt="Ryan Hegenberger"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <h4 className={classes.headerPerson}>Ryan Hegenberger</h4>
                      <span className={classes.title}>
                        Co-founder URLIFE <span className={classes.pipe}>| </span>Hollywood producer
                      </span>
                      <p className={classes.text}>As a leader in crafting iconic Hollywood movie trailers,
                        Ryan wanted to build an editing team who could create a time capsule of memories for his family
                        - and also make it accessible to everyone.</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </UrlifePaper>
          </div>
        </ParallaxLayer>
        <ParallaxLayer layer='layer5' className={classes.layer5}>
          <Footer parallax getScrollRef={getScrollRef}/>
        </ParallaxLayer>
      </ParallaxGroup>
    )
  }
}

Founders.propTypes = {
  classes: PropTypes.object.isRequired,
  getScrollRef: PropTypes.func,
  loggedIn: PropTypes.bool.isRequired,
  setSectionRef: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    loggedIn: state.user.loggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleModal: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Founders))
