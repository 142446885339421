import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import UrlifePaper from './components/UrlifePaper'
import { AppStoreBadge, CloseIcon } from '../../common/SVG'
import ModalPreview from '../../common/ModalPreview'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import phoneStep1 from '../../../assets/img/marketing/stepsPhone1.png'
import phoneStep2 from '../../../assets/img/marketing/stepsPhone2.png'
import phoneStep3 from '../../../assets/img/marketing/stepsPhone3.png'
import phoneStep4 from '../../../assets/img/marketing/stepsPhone4.png'

/* eslint-disable sort-keys */
const styles = {
  root: {
    ...commonStyles.container(),
    position: 'relative'
  },
  group: {
    marginTop: -80,
    marginBottom: 146,
    height: 'auto',
    ...commonStyles.media(480, {
      marginTop: -30,
      marginBottom: 75
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginBottom: 75
    })
  },
  layer5: {
    position: 'static',
    height: 'auto'
  },
  link: {
    color: '#01B7D7',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  container: {
    position: 'relative',
    marginTop: 50,
    marginLeft: 0,
    width: '100%',
    ...commonStyles.media(480, {
      margin: '40px 16px 15px',
      paddingTop: 240,
      width: '90%'
    })
  },
  header: {
    width: '100%',
    maxWidth: '300px',
    ...commonStyles.media(1279, {
      width: '100%',
      maxWidth: '230px'
    }),
    ...commonStyles.media(480, {
      width: '100%',
      maxWidth: '234px'
    })
  },
  textSection: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1,
    width: 350,
    heigh: 200,
    ...commonStyles.media(959, {
      fontSize: 14,
      lineHeight: '19px'
    }),
    ...commonStyles.media(767, {
      width: 200
    }),
    ...commonStyles.media(480, {
      width: 230
    })
  },
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '61%',
    opacity: 0.4,
    cursor: 'pointer',
    transition: 'opacity ease .5s',
    ...commonStyles.media(1279, {
      width: '55%'
    }),
    ...commonStyles.media(480, {
      width: '100%'
    })
  },
  stepsInt: {
    display: 'none',
    ...commonStyles.media(959, {
      color: '#9455c8',
      fontWeight: 800,
      fontSize: 52,
      margin: 0,
      lineHeight: 1,
      display: 'inline-block'
    }),
    ...commonStyles.media(480, {
      fontSize: 46
    })
  },
  stepsString: {
    color: '#9455c8',
    fontWeight: 800,
    fontSize: 62,
    margin: 0,
    lineHeight: 1,
    display: 'inline-block',
    ...commonStyles.media(959, {
      display: 'none'
    })
  },
  stepsText: {
    display: 'inline-block',
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    margin: 'auto 0 auto 10px',
    width: 243,
    lineHeight: 1,
    ...commonStyles.media(959, {
      fontSize: 14,
      lineHeight: '19px'
    })
  },
  active: {
    opacity: 1
  },
  phoneContainer: {
    ...commonStyles.container()
  },
  phone: {
    position: 'absolute',
    right: 250,
    top: -635,
    width: 383,
    height: 715,
    opacity: 0,
    zIndex: 1,
    transition: 'opacity ease .5s',
    ...commonStyles.media(1279, {
      position: 'absolute',
      display: 'block',
      top: -525,
      right: 100,
      height: 570,
      width: 305
    }),
    ...commonStyles.media(959, {
      top: -355,
      right: '10vw',
      width: 230,
      height: 430
    }),
    ...commonStyles.media(599, {
      top: -378,
      right: '0vw',
      width: 230,
      height: 430
    }),
    ...commonStyles.media(480, {
      position: 'absolute',
      right: 0,
      left: 0,
      top: -640,
      margin: 'auto',
      height: 280,
      width: 150
    }),
    ...commonStyles.media(334, {
      position: 'absolute',
      right: 0,
      left: 0,
      top: -660,
      margin: 'auto',
      height: 280,
      width: 150
    })
  },
  activePhone: {
    zIndex: 2,
    opacity: 1,
    transition: 'opacity ease .5s'
  },
  lastActivePhone: {
    zIndex: 1,
    opacity: 1,
    transition: 'opacity ease .5s'
  },
  popupContainer: {
    position: 'absolute',
    right: 0,
    bottom: 65,
    width: 420,
    backgroundColor: '#8e45be',
    zIndex: 2,
    borderRadius: 3,
    padding: '50px 35px',
    transition: 'transform .5s',
    transform: 'translate(100%, 0)',
    '&._open': {
      transform: 'translate(0, 0)'
    },
    ...commonStyles.media(959, {
      width: 336,
      padding: '40px 25px'
    }),
    ...commonStyles.media(599, {
      width: 280,
      padding: '30px 15px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      width: 280,
      padding: '30px 15px'
    })
  },
  popupHeader: {
    ...commonStyles.text({
      color: '#ffffff',
      fontSize: 34,
      fontWeight: 650,
      lineHeight: '48px',
      textTransform: 'uppercase'
    }),
    ...commonStyles.media(959, {
      fontSize: 28,
      lineHeight: '40px'
    }),
    ...commonStyles.media(599, {
      fontSize: 24,
      lineHeight: '32px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 24,
      lineHeight: '32px'
    })
  },
  popupSubHeader: {
    ...commonStyles.text({
      color: '#ffffff',
      fontSize: 40,
      fontWeight: 300,
      lineHeight: '48px',
      opacity: 0.8,
      marginTop: 10
    }),
    ...commonStyles.media(959, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(599, {
      fontSize: 28,
      lineHeight: '36px',
      marginTop: 8
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '36px',
      marginTop: 8
    })
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  closeIcon: {
    fill: '#ffffff',
    width: 12,
    height: 12
  },
  appStoreBadge: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 20
  }
}
/* eslint-enable sort-keys */

const steps = [
  {
    img: phoneStep1,
    int: 1,
    string: 'ONE',
    text: 'Select your package'
  },
  {
    img: phoneStep2,
    int: 2,
    string: 'TWO',
    text: 'Upload your files'
  },
  {
    img: phoneStep3,
    int: 3,
    string: 'THREE',
    text: 'Collaborate with us, as much or as little as you like'
  },
  {
    img: phoneStep4,
    int: 4,
    string: 'FOUR',
    text: 'Sit back and relax while our team makes the magic'
  }
]

class HowItWorks extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      currentStep: 1,
      openModal: false,
      popupOpen: false
    }
    this.ref = React.createRef()
    this.loopInterval = null
    this.handleClick = this.handleClick.bind(this)
    this.inView = false
  }

  handleClick (value) {
    clearInterval(this.loopInterval)
    this.setState({ currentStep: value })
    this.loop()
  }

  loop () {
    this.loopInterval = setInterval(() => {
      let currentStep = this.state.currentStep
      if (currentStep === 4) {
        currentStep = 1
      } else {
        currentStep++
      }
      this.setState({ currentStep })
    }, 7000)
  }

  componentWillUnmount () {
    clearInterval(this.loopInterval)
  }

  componentDidUpdate (prevProps) {
    const { inView } = this.props
    if (inView('HowItWorks') && !this.inView) {
      if (!this.loopInterval) {
        this.loop()
      }
      this.inView = true
      this.setState({ popupOpen: true })
    }
  }

  closePopup () {
    this.setState({ popupOpen: false })
  }

  toggleModal () {
    this.setState({ openModal: !this.state.openModal })
  }

  render () {
    const { classes } = this.props
    const { openModal, popupOpen } = this.state
    const handleClick = this.handleClick
    const currentStep = this.state.currentStep
    return (
      <ParallaxGroup sectionRef={this.props.setSectionRef('HowItWorks')} className={classes.group} index={32}>
        <ParallaxLayer layer='layer5' className={classes.layer5}>
          <div className={classes.root} ref={this.ref}>
            <UrlifePaper className={classes.container} header="How it works" headerStyle={classes.header}>
              <p className={classes.textSection}>
                Create an account on our website and then use the web or iOS mobile app.
              </p>
              {steps.map((item, idx) => {
                const active = idx + 1 === currentStep ? classes.active : ''
                return (
                  <div
                    key={item.int}
                    onClick={() => handleClick(item.int)}
                    className={`${classes.stepsContainer} ${active}`}
                  >
                    <p className={classes.stepsInt} >{item.int}</p>
                    <p className={classes.stepsString}>{item.string}</p>
                    <p className={classes.stepsText}>{item.text}</p>
                  </div>
                )
              })}
            </UrlifePaper>
          </div>
          <div className={classes.phoneContainer}>
            {steps.map(item => {
              return (
                <img
                  key={item.int}
                  src={item.img}
                  alt="Shows one of the steps to create a project in the URLIFE app."
                  className={`${classes.phone} ${item.int === currentStep
                    ? classes.activePhone
                    : item.int === currentStep - 1
                      ? classes.lastActivePhone
                      : null}`}
                />
              )
            })}
          </div>
          <div className={classNames(classes.popupContainer, { _open: popupOpen })}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closePopup.bind(this)}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
            <div className={classes.popupHeader}>DOWNLOAD OUR iOS APP TODAY</div>
            <div className={classes.appStoreBadge}><AppStoreBadge /></div>
          </div>
        </ParallaxLayer>
        <ModalPreview
          isOpenModalPreview={openModal}
          closeModalPreview={this.toggleModal.bind(this)}
          video='https://static.urlifemedia.com/ios-create-project/video/master.m3u8'
          gaVideoTitle='iOS create project tutorial'
          title=''
        />
      </ParallaxGroup>
    )
  }
}

HowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
  inView: PropTypes.func,
  setSectionRef: PropTypes.func.isRequired
}

export default withStyles(styles)(HowItWorks)
