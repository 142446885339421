import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function CoverIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 22">
      <g><g><path d="M16.295 4.98a.556.556 0 0 1-.553.558.556.556 0 0 1-.554-.557c0-.308.249-.558.554-.558.305 0 .553.25.553.558zm-2.52 0c0 1.1.882 1.993 1.967 1.993 1.084 0 1.966-.894 1.966-1.992 0-1.099-.882-1.993-1.966-1.993-1.085 0-1.967.894-1.967 1.993zm-5.208.095l8.825 14.688H3.637c-1.17 0-2.12-.965-2.12-2.152v-7zm11.706 4.473l4.206 6.892v1.171c0 1.187-.95 2.152-2.12 2.152h-3.313l-3.992-6.645zm4.913 3.418c.39 0 .706-.32.706-.717V3.622c0-1.978-1.585-3.587-3.533-3.587H3.637C1.689.035.104 1.645.104 3.622v13.99c0 1.977 1.585 3.586 3.533 3.586h18.722c1.948 0 3.533-1.609 3.533-3.587v-1.375c0-.016-.004-.031-.005-.047-.002-.027-.003-.054-.008-.08-.004-.024-.01-.046-.017-.07-.006-.02-.012-.043-.02-.065-.01-.026-.023-.05-.036-.075-.007-.013-.012-.028-.02-.04l-4.698-7.7a.698.698 0 0 0-.995-.217l-5.775 3.95-4.956-8.249a.7.7 0 0 0-1.035-.194l-6.81 5.347V3.622c0-1.187.95-2.152 2.12-2.152h18.722c1.17 0 2.12.965 2.12 2.152v8.627c0 .396.316.717.707.717z" /></g></g>
    </SvgIcon>
  )
}
