import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import classNames from 'classnames'
import {
  PROJECT_STAGES,
  PROJECT_STATUSES,
  PROJECT_STATUSES_NAME
} from '../../../../constants'
import { connect } from 'react-redux'
import {
  mapProjectStatusToStage
} from '../../../../helpers'

const ProjectStageBackground = styled.div(props => ({
  '&::after': {
    backgroundColor: props.color
  },
  backgroundColor: props.color
}))

/* eslint-disable sort-keys */
const styles = {
  projectStage: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    '&._large': {
      height: 32
    },
    '&._medium': {
      height: 29
    },
    '&._small': {
      height: 11
    }
  },
  projectStageSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    '&._userPrep': {
      left: 0,
      width: '28%'
    },
    '&._queued': {
      left: '28%',
      width: '28%',
      paddingLeft: '10px'
    },
    '&._inProduction': {
      left: '56%',
      right: 0
    }
  },
  projectStageBackground: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    '&::before': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      backgroundColor: '#ffffff',
      top: 0,
      transform: 'rotate(45deg)',
      borderRadius: '3px'
    },
    '&::after': {
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      transform: 'rotate(45deg)',
      borderRadius: '3px'
    },
    '&._large': {
      '&::before': {
        width: '32px',
        height: '32px',
        left: -18
      },
      '&::after': {
        width: '32px',
        height: '32px',
        right: -12
      }
    },
    '&._medium': {
      '&::before': {
        width: '29px',
        height: '29px',
        left: -17
      },
      '&::after': {
        width: '29px',
        height: '29px',
        right: -11
      }
    },
    '&._small': {
      '&::before': {
        width: '11px',
        height: '11px',
        left: -7
      },
      '&::after': {
        width: '11px',
        height: '11px',
        right: -3
      }
    },
    '&._notBegun': {
      backgroundColor: '#f9f9f9',
      '&::after': {
        backgroundColor: '#f9f9f9'
      }
    },
    '&._hideBefore': {
      '&::before': {
        display: 'none'
      }
    },
    '&._hideAfter': {
      '&::after': {
        display: 'none'
      }
    }
  },
  projectStageText: {
    position: 'relative',
    left: '8px',
    letterSpacing: '0.44px',
    color: '#ffffff',
    textTransform: 'uppercase',
    '&._notBegun': {
      color: '#323232'
    },
    '&._large': {
      fontSize: 13
    },
    '&._medium': {
      fontSize: 12
    },
    '&._small': {
      display: 'none'
    }
  }
}
/* eslint-enable sort-keys */

class ProjectStage extends React.Component {
  render () {
    const {
      classes,
      projectSku,
      sizeClass,
      skinData
    } = this.props

    let prepBegun = false
    let queuedBegun = false
    let productionBegun = false
    if (projectSku) {
      const stage = mapProjectStatusToStage(projectSku.statusId)
      prepBegun = stage >= PROJECT_STAGES.PREP
      queuedBegun = stage >= PROJECT_STAGES.QUEUED
      productionBegun = stage >= PROJECT_STAGES.PRODUCTION
    }

    const color = skinData ? skinData.mainColor : '#bd6ad3'

    return (
      <div className={classNames(classes.projectStage, sizeClass)}>
        <div className={classNames(classes.projectStageSection, '_inProduction')}>
          <ProjectStageBackground
            color={color}
            className={
              classNames(
                classes.projectStageBackground,
                sizeClass,
                '_hideAfter',
                { _notBegun: !productionBegun }
              )
            }
          />
          <div
            className={classNames(
              classes.projectStageText,
              sizeClass,
              { _notBegun: !productionBegun }
            )}>
            {PROJECT_STATUSES_NAME[PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED]}
          </div>
        </div>
        <div className={classNames(classes.projectStageSection, '_queued')}>
          <ProjectStageBackground
            color={color}
            className={
              classNames(
                classes.projectStageBackground,
                sizeClass,
                '_queued',
                { _notBegun: !queuedBegun }
              )
            }
          />
          <div
            className={classNames(
              classes.projectStageText,
              sizeClass,
              { _notBegun: !queuedBegun }
            )}
          >
            {PROJECT_STATUSES_NAME[PROJECT_STATUSES.PROJECT_QUEUED]}
          </div>
        </div>
        <div className={classNames(classes.projectStageSection, '_userPrep')}>
          <ProjectStageBackground
            color={color}
            className={
              classNames(
                classes.projectStageBackground,
                sizeClass,
                '_hideBefore',
                { _notBegun: !prepBegun }
              )
            }
          />
          <div
            className={classNames(
              classes.projectStageText,
              sizeClass,
              { _notBegun: !prepBegun }
            )}
          >
            {PROJECT_STATUSES_NAME[PROJECT_STATUSES.PREP_USER]}
          </div>
        </div>
      </div>
    )
  }
}

ProjectStage.propTypes = {
  classes: PropTypes.object.isRequired,
  projectSku: PropTypes.object,
  sizeClass: PropTypes.oneOf(['_small', '_medium', '_large']).isRequired,
  skinData: PropTypes.object
}

const mapStateToProps = state => {
  return {
    skinData: state.skinning.skinData
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProjectStage))
