import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function FacebookIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <defs><linearGradient id="4zkda" x1="0" x2="20" y1="0" y2="20" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1"/><stop offset="1" stopColor="#bd6ad3"/></linearGradient></defs><g><g><path fill="url(#4zkda)" d="M18 0a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-4.16v-7.765h2.573l.434-3.066H13.84v-2.09c0-.649.53-1.179 1.178-1.179h1.946V3.077l-2.445-.098c-2.282-.092-3.832 1.63-3.832 3.658V9.17H8.112v3.066h2.574V20H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/></g></g>
    </SvgIcon>
  )
}
