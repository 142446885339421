import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles, history, toLocale } from '../../../helpers'
import { fetchPromocodes, getAllPackageSkus } from '../../../actions'
import Paper from '@material-ui/core/Paper'
import UrlifeInfiniteScroller from '../../common/UrlifeInfiniteScroller'
import UrlifeButton from '../../common/UrlifeButton'
import UrlifeTextField from '../../common/UrlifeTextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import classNames from 'classnames'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  topContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  addWrapper: {
    flexGrow: 1
  },
  button: {
    margin: '20px 0px'
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  searchField: {
    width: 300,
    marginRight: 20
  },
  promoTable: {
    display: 'table',
    width: '100%'
  },
  headerRow: {
    display: 'table-row'
  },
  headerCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    fontWeight: '500',
    fontSize: '16px',
    padding: '10px 5px 0',
    position: 'sticky',
    top: 106,
    backgroundColor: '#f6f6f6',
    zIndex: '1',
    whiteSpace: 'nowrap',
    ...commonStyles.media(575, {
      top: 85
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      top: 85
    })
  },
  promoContainer: {
    display: 'table-row',
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  },
  promoCell: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    display: 'table-cell',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  filter: {
    width: 150,
    marginRight: 25,
    marginTop: -6
  },
  inputLabel: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    color: '#666666',
    '&._focused': {
      color: '#666666'
    },
    '&._error': {
      color: '#666666'
    },
    '&._shrink': {
      transform: 'translate(0, -3.5px) scale(0.875)'
    }
  },
  filterSelect: {
    width: '100%',
    '&:after': {
      borderBottomColor: '#5334b6'
    }
  }
}
/* eslint-enable sort-keys */

class PromoCodes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filter: 'all',
      isLoading: false,
      nextCursor: null,
      promocodes: [],
      search: ''
    }
  }

  fetchPromocodes (add) {
    const { fetchPromocodesFunction } = this.props
    const { promocodes, nextCursor, search, filter } = this.state
    const params = { query: search, sort: 'title' }
    if (add) {
      params.cursor = nextCursor
    }
    if (filter === 'valid') {
      params.active = true
      params.expired = false
      params.limitReached = false
    }
    this.setState({ isLoading: true })
    fetchPromocodesFunction(params)
      .then(
        data => {
          let newPromocodes = data.data
          if (add) {
            newPromocodes = [...promocodes, ...newPromocodes]
          }
          this.setState({
            isLoading: false,
            nextCursor: data.nextCursor || null,
            promocodes: newPromocodes
          })
        }
      )
  }

  componentDidMount () {
    this.props.updateSkusFunction(this.props.packages)
    this.fetchPromocodes()
  }

  onChangeSearch (e) {
    const { search } = this.state
    if (search && !e.target.value) {
      this.setState({ search: e.target.value }, this.fetchPromocodes.bind(this, false))
    } else {
      this.setState({ search: e.target.value })
    }
  }

  onSearch (e) {
    e.preventDefault()
    this.fetchPromocodes(false)
  }

  onFilterChange (e) {
    this.setState({ filter: e.target.value }, this.fetchPromocodes.bind(this, false))
  }

  render () {
    const { classes } = this.props
    const {
      promocodes,
      nextCursor,
      isLoading,
      search,
      filter
    } = this.state
    const dateOptions = {
      date: true,
      full: false,
      time: false
    }
    return (
      <div>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.topContainer}>
            <div className={classes.addWrapper}>
              <UrlifeButton
                className={classes.button}
                onClick={() => history.push('/admin/promocodes/create')}
              >
                Add promocode
              </UrlifeButton>
            </div>
            <FormControl className={classes.filter}>
              <InputLabel
                classes={{
                  error: classNames(classes.inputLabel, '_error'),
                  focused: classNames(classes.inputLabel, '_focused'),
                  root: classes.inputLabel,
                  shrink: classNames(classes.inputLabel, '_shrink')
                }}
              >
                Filter
              </InputLabel>
              <Select
                className={classes.filterSelect}
                name='promocodefilter'
                value={filter}
                onChange={this.onFilterChange.bind(this)}>
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'valid'}>Valid</MenuItem>
              </Select>
            </FormControl>
            <form onSubmit={this.onSearch.bind(this)} className={classes.searchForm}>
              <UrlifeTextField
                className={classes.searchField}
                name="search"
                placeholder="Search"
                label="Search"
                onChange={this.onChangeSearch.bind(this)}
                value={search}
              />
              <UrlifeButton
                className={classes.button}
                type="submit"
                disabled={isLoading}
              >
                Search
              </UrlifeButton>
            </form>
          </div>
          <UrlifeInfiniteScroller
            loadMore={this.fetchPromocodes.bind(this, true)}
            hasMore={!!nextCursor}
            isLoading={isLoading}
            isEmpty={!promocodes.length}
            className={classes.promoTable}
          >
            <div className={classes.headerRow}>
              <div className={classes.headerCell}>
                Active
              </div>
              <div className={classes.headerCell}>
                Title
              </div>
              <div className={classes.headerCell}>
                Code
              </div>
              <div className={classes.headerCell}>
                Expires
              </div>
              <div className={classes.headerCell}>
                Limit
              </div>
              <div className={classes.headerCell}>
                Redeemed
              </div>
              <div className={classes.headerCell}>
                &nbsp;
              </div>
            </div>
            {promocodes && promocodes.map(promo => {
              return (
                <div key={promo.id} className={classes.promoContainer}>
                  <span className={classes.promoCell}>
                    {promo.active ? 'Yes' : 'No'}
                  </span>
                  <span className={classes.promoCell}>
                    {promo.title}
                  </span>
                  <span className={classes.promoCell}>
                    {promo.code}
                  </span>
                  <span className={classes.promoCell}>
                    {promo.expiration ? toLocale(new Date(promo.expiration), dateOptions) : 'N/A'}
                  </span>
                  <span className={classes.promoCell}>
                    {promo.userLimit ? promo.userLimit : 'Unlimited'}
                  </span>
                  <span className={classes.promoCell}>
                    {promo.redeemed}
                  </span>
                  <span className={classes.promoCell}>
                    <UrlifeButton
                      className={classes.button}
                      onClick={() => history.push('/admin/promocodes/' + promo.id)}
                    >
                      Edit
                    </UrlifeButton>
                  </span>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
        </Paper>
      </div>
    )
  }
}

PromoCodes.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchPromocodesFunction: PropTypes.func.isRequired,
  packages: PropTypes.arrayOf(PropTypes.object),
  promocodes: PropTypes.arrayOf(PropTypes.object),
  updateSkusFunction: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    packages: state.packages.packages,
    promocodes: []
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchPromocodesFunction: function (params) {
      return dispatch(fetchPromocodes(params))
    },
    updateSkusFunction: function (packages) {
      packages.forEach(pack => {
        dispatch(getAllPackageSkus(pack.id, 'all'))
      })
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PromoCodes))
