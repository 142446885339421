import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { commonStyles } from '../../helpers'
import classNames from 'classnames'
import FAQList from '../faq/FAQList'
import RegisterOrProjectButton from '../common/RegisterOrProjectButton'
import {
  NotreDameIcon,
  PlusCircleIconNotch,
  ProducerChairV2,
  PromoCode,
  UploadMovieArrowIcon
} from '../common/SVG'
import Hero from './components/Hero'
import GetStarted from './components/GetStarted'
import backgroundImage from '../../assets/img/notreDameStadium.jpg'
import { PACKAGE_TYPES } from '../../constants'
import ExclusivePackage from './components/ExclusivePackage'

/* eslint-disable sort-keys */
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...commonStyles.media(575, {
      marginTop: 20,
      padding: '0px 3px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginTop: 20,
      padding: '0px 3px'
    })
  },
  sectionContainer: {
    marginTop: 80
  },
  header: {
    fontSize: 36,
    lineHeight: '44px',
    fontWeight: 400,
    color: '#512cb9',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 34,
      lineHeight: '44px'
    }),
    ...commonStyles.media(767, {
      fontSize: 32,
      lineHeight: '42px'
    }),
    ...commonStyles.media(575, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 28,
      lineHeight: '34px'
    }),
    '& span': {
      fontWeight: 600
    }
  },
  subHeader: {
    fontSize: 24,
    lineHeight: '33px',
    fontWeight: 600,
    color: '#512cb9',
    ...commonStyles.media(991, {
      fontSize: 22,
      lineHeight: '31px'
    }),
    ...commonStyles.media(767, {
      fontSize: 20,
      lineHeight: '29px'
    }),
    ...commonStyles.media(575, {
      fontSize: 16,
      lineHeight: '25px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 16,
      lineHeight: '25px'
    })
  },
  buttonContainer: {
    marginTop: 65,
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  inspirationHolder: {
    backgroundColor: '#f0f0f0',
    marginTop: 50,
    overflowX: 'hidden',
    position: 'relative'
  },
  faqHeader: {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 600,
    color: '#512cb9',
    textTransform: 'uppercase',
    ...commonStyles.media(991, {
      fontSize: 24,
      lineHeight: '30px'
    }),
    ...commonStyles.media(767, {
      fontSize: 22,
      lineHeight: '28px'
    }),
    ...commonStyles.media(575, {
      fontSize: 18,
      lineHeight: '24px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18,
      lineHeight: '24px'
    })
  },
  faqText: {
    marginTop: 5,
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    ...commonStyles.media(991, {
      fontSize: 14,
      lineHeight: '22px'
    }),
    ...commonStyles.media(575, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 12,
      lineHeight: '18px'
    }),
    '& a': {
      color: '#01b7d7',
      textDecoration: 'none'
    },
    '&._marginBottom': {
      marginBottom: 30
    }
  },
  contactContainer: {
    marginTop: 47,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  }
}
/* eslint-enable sort-keys */

const steps = [
  {
    icon: PromoCode,
    text: 'Create an account and enter promo code'
  },
  {
    icon: PlusCircleIconNotch,
    text: 'Follow on-screen prompts to start your project'
  },
  {
    icon: UploadMovieArrowIcon,
    text: 'Upload photos/videos using the website or iOS App'
  },
  {
    icon: ProducerChairV2,
    text: 'Sit back and relax while our team makes the magic'
  }
]

class NotreDame extends React.Component {
  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Hero
          headerBefore='RELIVE YOUR'
          headerAfter='EXPERIENCE'
          headerSubHeader='First class memory preservation by the pros'
          headerInfo='Your game day weekend brought to life in a personalized mini-movie'
          headerLogo={NotreDameIcon}
          bigLogo
          thumbnail='https://static.urlifemedia.com/thumbnails/NotreDameVideo_thumbnail.jpg'
          video='https://static.urlifemedia.com/NotreDameVideo/master.m3u8'
        />
        <GetStarted
          steps={steps}
          title='Easy to get started'
          color='#4a30b5'
        />
        <div className={classes.buttonContainer}>
          <RegisterOrProjectButton/>
        </div>
        <ExclusivePackage
          headerTitle='This gift is courtesy of Notre Dame Athletic Advancement '
          headerSubtitle={'You take the photos and videos - and the URLIFE Hollywood' +
            ' trained editors and producers will create a custom video memory of your' +
            ' Notre Dame experience.'
          }
          headerColor='#0c2340'
          packageTitle='recap movie'
          packageSubtitle='Highlight reel of your big game weekend'
          packageBackground={`
            linear-gradient(0deg, rgba(216, 216, 216, 0.75), rgba(216, 216, 216, 0.75)),
            url(${backgroundImage}) center / cover no-repeat
          `}
          bulletColor='#4a30b5'
          listHeight={190}
          packageSkuId='BCazW3M6n'
          free
          packageType={PACKAGE_TYPES.RECAP}
        />
        <div className={classes.buttonContainer}>
          <RegisterOrProjectButton/>
        </div>
        <div className={classes.sectionContainer}>
          <div className={classes.faqHeader}>Still have questions?</div>
          <div className={classNames(classes.faqText, '_marginBottom')}>
            We have categorized our frequently asked questions to make it easier to find what you are after.
          </div>
          <FAQList questionsType='notreDame'/>
        </div>
        <div className={classes.contactContainer}>
          <div className={classes.faqText}>
            <div className={classes.faqHeader}>Need more info?</div>
            If you have any questions, email us at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a>
          </div>
        </div>
      </div>
    )
  }
}

NotreDame.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NotreDame)
