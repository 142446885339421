import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function TopMenuGallery (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 28 25">
      <g><g><g><g><path fill="#fff" d="M14.883 8.695a.503.503 0 0 1-.505.5.503.503 0 0 1-.506-.5c0-.275.227-.499.506-.499s.505.224.505.5zM7.185 1.537h17.181c1.366 0 1.937 1.205 1.937 1.768v12.562c0 .807-.618 1.91-1.937 1.91v1.274c2.177 0 3.227-1.814 3.227-3.184V3.447c0-2.267-1.944-3.184-3.227-3.184H7.096c-1.875 0-3.1 1.771-3.1 3.185H5.33c0-1.06.924-1.911 1.855-1.911zm5.396 7.165c0 .981.806 1.779 1.797 1.779a1.79 1.79 0 0 0 1.796-1.78 1.79 1.79 0 0 0-1.796-1.779 1.79 1.79 0 0 0-1.797 1.78zm-4.763.073l8.054 13.04H3.318c-1.066 0-1.934-.857-1.934-1.91v-6.216zm10.7 3.97l3.841 6.12v1.04c0 1.053-.868 1.91-1.936 1.91h-3.026l-3.647-5.9zm4.486 3.036a.641.641 0 0 0 .646-.637V7.485c0-1.756-1.448-3.184-3.227-3.184H3.32C1.54 4.3.093 5.729.093 7.485v12.42c0 1.756 1.448 3.184 3.227 3.184h17.103c1.78 0 3.227-1.428 3.227-3.185v-1.22c0-.015-.004-.028-.005-.043-.001-.024-.003-.047-.007-.07-.004-.022-.01-.041-.016-.062-.006-.019-.01-.038-.018-.058-.01-.023-.021-.045-.033-.067-.007-.012-.01-.024-.018-.036l-4.292-6.835a.653.653 0 0 0-.91-.193l-5.274 3.507L8.55 7.504a.654.654 0 0 0-.946-.172l-6.22 4.747V7.485c0-1.054.869-1.91 1.936-1.91h17.103c1.068 0 1.936.856 1.936 1.91v7.659c0 .351.289.637.645.637z"/></g></g></g></g>
    </SvgIcon>
  )
}
