import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function LocationIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 25">
      <defs><linearGradient id="88ksa" x1=".72" x2="19.61" y1=".14" y2="24.86" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#3d29b1" /><stop offset="1" stopColor="#bd6ad3" /></linearGradient></defs><g><g><path fill="url(#88ksa)" d="M10.163 12.275a2.69 2.69 0 0 1-2.687-2.687A2.69 2.69 0 0 1 10.163 6.9a2.69 2.69 0 0 1 2.687 2.688 2.69 2.69 0 0 1-2.687 2.687zm-.004-6.795a4.108 4.108 0 0 0-4.104 4.104 4.108 4.108 0 0 0 4.104 4.103 4.108 4.108 0 0 0 4.103-4.103 4.108 4.108 0 0 0-4.103-4.104zm.004-5.338C4.954.142.717 4.379.717 9.588c0 4.066 2.293 7.922 4.218 10.44 1.546 2.025 4.223 4.83 5.228 4.83.197 0 .564 0 2.076-1.415a.71.71 0 1 0-.97-1.037c-.555.52-.93.814-1.135.958-1.532-.952-7.997-7.488-7.997-13.776 0-4.426 3.6-8.026 8.026-8.026 4.425 0 8.025 3.6 8.025 8.026 0 4.355-3.116 8.622-4.973 10.778a.71.71 0 1 0 1.076.927c1.986-2.305 5.318-6.891 5.318-11.706 0-5.208-4.238-9.445-9.446-9.445z" /></g></g>
    </SvgIcon>
  )
}
