import React, { Component } from 'react'

import { cacheSignedUrl } from '../../services/CacheSignedUrls'
import classNames from 'classnames'
import { commonStyles } from '../../helpers'
import Dialog from '@material-ui/core/Dialog'
import { getImageRotationCss } from '../../helpers/imageRotationCss'
import PropTypes from 'prop-types'
import VideoPlayerPreview from './VideoPlayerPreview'
import { withStyles } from '@material-ui/core/styles'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    maxWidth: '90%',
    overflow: 'visible',
    '&._image': {
      backgroundColor: 'transparent'
    },
    ...commonStyles.media(575, {
      maxWidth: 'calc(100% - 20px)',
      margin: '30px 10px'
    })
  },
  closeIconWrapper: {
    position: 'absolute',
    top: '-40px',
    right: '-20px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    fontSize: '30px',
    color: '#FFF',
    textAlign: 'center',
    lineHeight: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(100, 100, 100, 0.7)'
    },
    '&:active': {
      background: 'rgba(50, 50, 50, 0.9)'
    },
    ...commonStyles.media(575, {
      right: -10
    })
  },
  videoName: {
    position: 'absolute',
    top: '-30px',
    color: '#FFF',
    left: '-20px'
  },
  modalPreviewImage: {
    backgroundColor: '#eff2f5',
    maxWidth: '100%',
    maxHeight: '90vh',
    zIndex: 1,
    '&._rotated': {
      maxWidth: 'calc(100vh - 96px)',
      maxHeight: 'calc(100vw - 96px)'
    },
    ...commonStyles.media(575, {
      '&._rotated': {
        maxWidth: 'calc(100vh - 60px)',
        maxHeight: 'calc(100vw - 20px)'
      }
    })
  },
  trickModalPreviewImage: {
    opacity: 0,
    display: 'block',
    pointerEvents: 'none',
    maxWidth: 'calc(100vw - 96px)',
    maxHeight: 'calc(100vh - 96px)',
    backgroundColor: '#eff2f5',
    ...commonStyles.media(575, {
      maxWidth: 'calc(100vw - 20px)',
      maxHeight: 'calc(100vh - 60px)'
    })
  }
}
/* eslint-enable sort-keys */

class ModalPreview extends Component {
  constructor (props) {
    super(props)
    this.state = {
      trickedImageSrc: ''
    }
    this.intervalId = 0
  }

  getImageUrl () {
    const { image } = this.props
    if (image && typeof image === 'object') {
      return image.src || cacheSignedUrl.get(image.signedUrl)
    }
    return image
  }

  getVideoOptions () {
    const { video, videoWidth, credentials, poster } = this.props
    const sources = []
    if (video && typeof video === 'object') {
      sources.push({ src: video.src, type: video.type || video.mimeType })
      sources.push({ src: video.src, type: 'video/mp4' })
      /*
       * Pushing an additional source with hard coded mime of 'video/mp4' solves a bug
       * on chrome to play quicktime files. Since it's the second source it should
       * not cause a problem for other formats we're using.
       */
    } else if (video) {
      sources.push({ src: video })
    }
    const videoSrc = (video && video.src) || video
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      fluid: true,
      iLikeItLink: {
        right: '0'
      },
      maxHeight: '300px',
      maxWidth: document.documentElement.clientWidth / 2,
      sources: sources,
      video: videoSrc,
      videoPlayerFooter: {
        '&:active': {
          background: 'rgba(50, 50, 50, 0.9)'
        },
        '&:hover': {
          background: 'rgba(100, 100, 100, 0.7)'
        },
        borderRadius: '5px',
        bottom: '-40px',
        color: '#ee6386',
        cursor: 'pointer',
        fontWeight: 'bold',
        padding: '10px',
        position: 'absolute'
      },
      watchMoreLink: {
        left: '0'
      },
      wrapperWidth: videoWidth || 780
    }
    if (poster) {
      videoJsOptions.poster = poster
    }
    if (credentials) {
      videoJsOptions.html5 = {
        vhs: {
          withCredentials: true
        }
      }
    }
    return videoJsOptions
  }

  componentDidMount () {
    // bug with Dialog https://github.com/mui-org/material-ui/issues/5421
    // interval need for wait image width and height
    this.intervalId = window.setInterval(() => {
      const { isOpenModalPreview, image } = this.props
      const { trickedImageSrc } = this.state

      if (!trickedImageSrc && isOpenModalPreview && this.imageRef &&
        image && image.orientation && [6, 8, 5, 7].includes(image.orientation) &&
        !image.width && !image.height
      ) {
        if (!this.imageRef.naturalWidth || !this.imageRef.naturalHeight) return
        this.canvasRef.width = this.imageRef.naturalHeight || 0
        this.canvasRef.height = this.imageRef.naturalWidth || 0
        this.setState({
          trickedImageSrc: this.canvasRef.toDataURL('image/jpeg')
        })
      }
    }, 300)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { image } = this.props
    const { trickedImageSrc } = this.state
    // if we have width and height, create image immediately, else wait for interval
    if (!trickedImageSrc && image && image.width && image.height) {
      if (image.orientation && [6, 8, 5, 7].includes(image.orientation)) {
        this.canvasRef.width = image.height
        this.canvasRef.height = image.width
      } else {
        this.canvasRef.width = image.width
        this.canvasRef.height = image.height
      }
      this.setState({
        trickedImageSrc: this.canvasRef.toDataURL('image/jpeg')
      })
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.trickedImageSrc && !props.image) {
      state.trickedImageSrc = ''
    }
    return state
  }

  componentWillUnmount () {
    window.clearInterval(this.intervalId)
  }

  render () {
    const {
      isOpenModalPreview,
      video,
      image,
      title,
      gaVideoTitle,
      classes,
      children
    } = this.props
    const { trickedImageSrc } = this.state
    const isRotatedOnSide = image && image.orientation && [6, 8, 5, 7].includes(image.orientation)
    return (
      <div style={{ maxWidth: '' }}>
        <Dialog open={isOpenModalPreview && (!video && !image)}>
          <h3>Loading... Please Wait</h3>
        </Dialog>
        <Dialog
          classes={{ paper: classNames(classes.paper, { _image: image }) }}
          open={!!(isOpenModalPreview && (video || image))}
          onClose={() => { this.props.closeModalPreview() }}>
          <div
            className = {classes.closeIconWrapper}
            onClick={() => { this.props.closeModalPreview() }}>
            <span>&times;</span>
          </div>
          {title && (video || image) && <div className = {classes.videoName}>{title}</div>}
          {video &&
            <VideoPlayerPreview
              gaVideoTitle={gaVideoTitle}
              controls { ...this.getVideoOptions() }
            />
          }
          {image &&
          <div>
            <img
              ref={ref => { this.imageRef = ref }}
              className={classNames(
                classes.modalPreviewImage,
                {
                  _rotated: isRotatedOnSide
                }
              )}
              src={this.getImageUrl()}
              alt={title}
              style={{
                ...getImageRotationCss(image.orientation)
              }}/>
            {trickedImageSrc &&
            <img className={classes.trickModalPreviewImage} src={trickedImageSrc} alt=""/>}
          </div>
          }
          {children}
        </Dialog>
        <canvas style={{ display: 'none' }} ref={ref => { this.canvasRef = ref }}/>
      </div>
    )
  }
}

ModalPreview.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object.isRequired,
  closeModalPreview: PropTypes.func.isRequired,
  credentials: PropTypes.bool.isRequired,
  gaVideoTitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isOpenModalPreview: PropTypes.bool.isRequired,
  poster: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  videoWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

ModalPreview.defaultProps = {
  credentials: false,
  isOpenModalPreview: false,
  video: null
}

export default withStyles(styles)(ModalPreview)
