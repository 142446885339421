import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { commonStyles } from '../../../helpers'
import ParallaxGroup from '../ParallaxGroup'
import ParallaxLayer from '../ParallaxLayer'
import FAQList from '../../faq/FAQList'

/* eslint-disable sort-keys */
const styles = {
  group: {
    height: 'auto',
    marginBottom: 50,
    ...commonStyles.media(480, {
      marginBottom: 0
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      marginBottom: 0
    })
  },
  layer: {
    position: 'static',
    height: 'auto'
  },
  container: {
    ...commonStyles.container(),
    marginBottom: 20
  },
  header: {
    color: '#5334b6',
    fontSize: 26,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase',
    marginBottom: 10,
    ...commonStyles.media(480, {
      fontSize: 18,
      width: 212
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 18
    })
  },
  subHeader: {
    color: '#323232',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '26px',
    marginTop: 0,
    marginBottom: 40,
    ...commonStyles.media(480, {
      fontSize: 14,
      lineHeight: '24px',
      marginBottom: 20
    }),
    ...commonStyles.mediaDimensions(896, 414, {
      fontSize: 14,
      lineHeight: '24px',
      marginBottom: 20
    }),
    '& a': {
      color: '#01B7D7',
      textDecoration: 'none'
    }
  }
}
/* eslint-enable sort-keys */

function TopQuestions (props) {
  const { classes } = props
  return (
    <ParallaxGroup sectionRef={props.setSectionRef('TopQuestions')} className={classes.group} index={34}>
      <ParallaxLayer layer='layer5' className={classes.layer}>
        <Grid className={classes.container} container justify="space-around" spacing={0}>
          <Grid item xs={12}>
            <h3 className={classes.header}>Still have questions?</h3>
            <p className={classes.subHeader}>
             Learn a bit more about how the URLIFE process works. Or, if you have additional questions -  email us
              at <a href="mailto:hello@urlifemedia.com">hello@urlifemedia.com</a> and our helpful staff will reach out.
               </p>
          </Grid>
          <FAQList />
        </Grid>
      </ParallaxLayer>
    </ParallaxGroup>
  )
}

TopQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  setSectionRef: PropTypes.func.isRequired
}

export default withStyles(styles)(TopQuestions)
