import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function UploadIcon (props) {
  return (
    <SvgIcon {...props} viewBox='0 0 23 18'>
      <path fill="#EE6386" fillRule="evenodd" d="M20 15v-1l2-.001v-13H4v1H3v-2h20v15L20 15zm-1 1.205l.241.207-.241.281V18l-19-.001v-15L19 3v13.205zM18 4L1 3.999v8.752l4.483-4.483 5.034 5.754 3.493-2.096L18 15.347V4zm0 12.664l-4.094-3.509-3.591 2.154-4.881-5.578L1 14.165v2.834L18 17v-.336zm-5.959-8.727a2.273 2.273 0 0 1 2.271-2.271 2.273 2.273 0 0 1 2.271 2.271 2.274 2.274 0 0 1-2.271 2.271 2.274 2.274 0 0 1-2.271-2.271zm2.271 1.271a1.272 1.272 0 0 0 0-2.542 1.271 1.271 0 0 0 0 2.542z"/>
    </SvgIcon>
  )
}
