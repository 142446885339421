import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export function MapIcon (props) {
  return (
    <SvgIcon {...props}>
      <path fill="#EE6386" fillRule="evenodd" stroke="#FFF" strokeWidth="3" d="M14.5 5.5a7 7 0 1 1 0 14 7 7 0 0 1 0-14z" />
    </SvgIcon>
  )
}
