/**
 * styles for image rotation
 * based on https://github.com/demimonde/exif2css
 */

const imageRotationCss = {
  2: {
    transform: 'rotateY(180deg)'
  },
  3: {
    transform: 'rotate(180deg)'
  },
  4: {
    transform: 'rotate(180deg) rotateY(180deg)'
  },
  5: {
    transform: 'rotate(270deg) rotateY(180deg)',
    transformOrigin: 'top left'
  },
  6: {
    transform: 'translateY(-100%) rotate(90deg)',
    transformOrigin: 'bottom left'
  },
  7: {
    transform: 'translateY(-100%) translateX(-100%) rotate(90deg) rotateY(180deg)',
    transformOrigin: 'bottom right'
  },
  8: {
    transform: 'translateX(-100%) rotate(270deg)',
    transformOrigin: 'top right'
  }
}

const imageRotationPureCss = {
  2: `
    transform: rotateY(180deg);
  `,
  3: `
    transform: rotate(180deg);
  `,
  4: `
    transform: rotate(180deg) rotateY(180deg);
  `,
  5: `
    transform: rotate(270deg) rotateY(180deg);
    transformOrigin: top left;
  `,
  6: `
    transform: translateY(-100%) rotate(90deg);
    transformOrigin: bottom left;
  `,
  7: `
    transform: translateY(-100%) translateX(-100%) rotate(90deg) rotateY(180deg);
    transformOrigin: bottom right;
  `,
  8: `
    transform: translateX(-100%) rotate(270deg);
    transformOrigin: top right;
  `
}

const isChrome = !!window.chrome
const isSafari = !!window.safari
const iOSSafari = !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) && !!navigator.userAgent.match(/AppleWebKit/)
export const browserHandlesRotation = isChrome || isSafari || iOSSafari

export const getImageRotationCss = rotation => {
  if (browserHandlesRotation) {
    return {}
  } else {
    return rotation && imageRotationCss[rotation] ? imageRotationCss[rotation] : {}
  }
}

export const getImageRotationPureCss = rotation => {
  if (browserHandlesRotation) {
    return ''
  } else {
    return rotation && imageRotationPureCss[rotation] ? imageRotationPureCss[rotation] : ''
  }
}
