import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js'
import ElementWrapper from './ElementWrapper'
import {
  fetchUserCoupons,
  getPaymentIntent,
  purchaseWithCoupon
} from '../../actions'
import {
  commonStyles,
  formatPrice,
  getLeastCompleteProjectSku,
  history
} from '../../helpers'
import UrlifeButton from '../common/UrlifeButton'
import UsePromo from '../promo/usePromo'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import PackageSelector from '../packages/PackageSelector'

/* eslint-disable sort-keys */
const styles = {
  cardElement: {
    display: 'inline-block',
    fontSize: 18,
    width: 'calc(100% - 16px)',
    ...commonStyles.media(480, {
      width: 'calc(100% - 14px)'
    })
  },
  cardNumber: {
    width: '100%'
  },
  cardExpiry: {
    width: 'calc(50% - 15px)'
  },
  cardCvc: {
    width: 'calc(50% - 15px)'
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  form: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    ...commonStyles.media(480, {
      minHeight: 'calc(100vh - 145px)'
    })
  },
  selectCoupon: {
    width: '100%',
    marginBottom: 10,
    '&:after': {
      borderBottomColor: '#01b7d7'
    }
  },
  priceText: {
    margin: '7px 0px',
    fontSize: 16,
    fontWeight: 500,
    color: '#323232'
  },
  payButton: {
    height: 44,
    maxWidth: 210,
    width: '100%',
    margin: 'auto',
    ...commonStyles.media(480, {
      marginTop: 20
    })
  },
  errorText: {
    height: 20,
    color: '#d70101',
    fontSize: 16,
    fontWeight: 500,
    margin: '6px 0px',
    ...commonStyles.media(480, {
      fontSize: 14
    })
  },
  loading: {
    height: 47,
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      marginRight: 10
    }
  },
  loadingRing: {
    width: 32,
    height: 32
  },
  hidden: {
    display: 'none'
  }
}

const defaultOpt = {
  style: {
    base: {
      iconColor: '#000',
      color: '#3a3a3a',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: 500
    },
    invalid: {
      color: '#3a3a3a'
    }
  }
}

const cardNumOpt = {
  ...defaultOpt,
  showIcon: true,
  iconStyle: 'solid'
}
/* eslint-enable sort-keys */

const priceError = `Unable to get a price for this project. If you selected a package with a custom price,
            please contact URLIFE to finalize the price, otherwise please try again later or contact
            URLIFE for assistance.`
const purchaseError = `An error happened when attempting to purchase.
          Please try again later or contact URLIFE for assistance.`

class CardPayment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      amount: 0,
      baseAmount: 0,
      chargeError: null,
      coupons: [],
      customError: null,
      fields: {
        cardCvc: {
          complete: false,
          empty: true
        },
        cardExpiry: {
          complete: false,
          empty: true
        },
        cardNumber: {
          complete: false,
          empty: true
        }
      },
      formComplete: false,
      isLoading: false,
      selectedCoupon: 'nocoupon',
      unlockedCoupon: null
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async submitCard (couponId) {
    const {
      stripe,
      elements,
      project,
      getPaymentIntentFunction,
      paymentComplete
    } = this.props
    const {
      amount,
      baseAmount
    } = this.state

    this.setState({
      isLoading: true
    })

    const cardNumberElement = elements.getElement(CardNumberElement)
    const projectSku = getLeastCompleteProjectSku(project)
    if (!projectSku || (baseAmount === amount && couponId)) {
      this.setState({
        customError: purchaseError,
        isLoading: false
      })
    } else {
      try {
        const intentResponse = await getPaymentIntentFunction(project.id, projectSku.id, couponId)
        if (intentResponse.data.amount === amount) {
          const paymentResponse = await stripe.confirmCardPayment(
            intentResponse.data.clientSecret,
            {
              payment_method: {
                card: cardNumberElement
              }
            })
          if (paymentResponse.error) {
            this.setState({
              chargeError: paymentResponse.error.message,
              isLoading: false
            })
          } else if (paymentResponse.paymentIntent.status === 'succeeded') {
            this.setState({
              isLoading: false
            })
            paymentComplete()
          }
        } else {
          this.setState({
            customError: purchaseError,
            isLoading: false
          })
        }
      } catch (e) {
        this.setState({
          customError: purchaseError,
          isLoading: false
        })
      }
    }
  }

  async submitCoupon (couponId) {
    const {
      purchaseWithCouponFunction,
      project,
      paymentComplete
    } = this.props
    this.setState({
      isLoading: true
    })
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku && couponId) {
      try {
        await purchaseWithCouponFunction(project.id, projectSku.id, couponId)
        this.setState({
          isLoading: false
        })
        paymentComplete()
      } catch (e) {
        this.setState({
          customError: purchaseError,
          isLoading: false
        })
      }
    } else {
      this.setState({
        customError: purchaseError,
        isLoading: false
      })
    }
  }

  handleSubmit (e) {
    e.preventDefault()
    const {
      amount,
      selectedCoupon,
      coupons
    } = this.state
    const isCardPayment = amount >= 50
    const couponIndex = parseInt(selectedCoupon)
    const coupon = !isNaN(couponIndex) ? coupons[couponIndex] : null
    const couponId = coupon ? coupon.id : null
    if (isCardPayment) {
      this.submitCard(couponId)
    } else {
      this.submitCoupon(couponId)
    }
  }

  handleChange (e) {
    const { formComplete, fields } = this.state
    const newFields = {
      ...fields,
      [e.elementType]: {
        ...fields[e.elementType],
        complete: e.complete,
        empty: e.empty,
        error: e.error && e.error.message ? e.error.message : undefined
      }
    }
    const newFieldsKeys = Object.keys(newFields)
    const newFieldsNotComplete = newFieldsKeys.filter(key => newFields[key].complete !== true)
    let validForm = formComplete
    if (!formComplete && newFieldsNotComplete && newFieldsNotComplete.length < 1) {
      validForm = true
    } else if (formComplete && newFieldsNotComplete.length > 0) {
      validForm = false
    }
    this.setState({
      chargeError: null,
      customError: null,
      fields: newFields,
      formComplete: validForm
    })
  }

  handleFocus (focus, e) {
    const { fields } = this.state
    const newFields = {
      ...fields,
      [e.elementType]: {
        ...fields[e.elementType],
        focus
      }
    }
    this.setState({
      fields: newFields
    })
  }

  setAmount () {
    const { project } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    if (projectSku) {
      const price = projectSku.price
      if (!price) {
        history.replace(`/projects/${project.id}/summary`)
      } else {
        this.setState({
          amount: price,
          baseAmount: price,
          customError: null
        })
      }
    } else {
      this.setState({
        customError: priceError
      })
    }
  }

  selectCoupon (selectedCoupon) {
    const { baseAmount, coupons } = this.state
    const couponIndex = parseInt(selectedCoupon)
    let newAmount = baseAmount
    const coupon = !isNaN(couponIndex) ? coupons[couponIndex] : null
    if (coupon) {
      if (coupon.amount_off) {
        newAmount = baseAmount - coupon.amount_off
      } else {
        newAmount = Math.floor(baseAmount - baseAmount * coupon.percent_off / 100)
      }
      newAmount = Math.max(newAmount, 0)
    }
    this.setState({
      amount: newAmount,
      selectedCoupon: selectedCoupon
    })
  }

  selectBestCoupon () {
    const { baseAmount, coupons } = this.state
    let bestCoupon = 'nocoupon'
    let biggestSave = 0
    coupons.forEach((coupon, index) => {
      let save = 0
      if (coupon.amount_off) {
        save = coupon.amount_off
      } else {
        save = baseAmount * coupon.percent_off / 100
      }
      if (save > biggestSave) {
        biggestSave = save
        bestCoupon = index.toString()
      }
    })
    this.selectCoupon(bestCoupon)
  }

  handleCouponChange (e) {
    this.selectCoupon(e.target.value)
  }

  async loadUserCoupons () {
    const { userId, project, fetchUserCouponsFunction } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    if (!projectSku) {
      return
    }
    const skuId = projectSku.packageSku
    try {
      const response = await fetchUserCouponsFunction(userId, skuId)
      const selectedCoupons = []
      for (const coupon of response.data) {
        const indexMatch = selectedCoupons.findIndex(selectedCoupon => {
          return (
            // check if skus match
            coupon.skus.sort().join(',') === selectedCoupon.skus.sort().join(',') &&
            // check if discount match
            coupon.amount_off === selectedCoupon.amount_off &&
            coupon.percent_off === selectedCoupon.percent_off
          )
        })
        if (indexMatch < 0) {
          // coupon matches no other coupons
          selectedCoupons.push(coupon)
        } else {
          // select the coupon with the shortest expiration
          const selectedExp = selectedCoupons[indexMatch].expiration
            ? new Date(selectedCoupons[indexMatch].expiration).getTime()
            : null
          const couponExp = coupon.expiration
            ? new Date(coupon.expiration).getTime()
            : null
          if (
            (!selectedExp && couponExp) ||
            (selectedExp && couponExp && selectedExp > couponExp)
          ) {
            selectedCoupons[indexMatch] = coupon
          }
        }
      }
      this.setState({ coupons: selectedCoupons }, () => this.selectBestCoupon())
    } catch (e) {}
  }

  onPromoUnlock (unlockedCouponData) {
    const { project } = this.props
    const projectSku = getLeastCompleteProjectSku(project)
    if (!projectSku) {
      return
    }
    const skuId = projectSku.packageSku
    let { coupons, selectedCoupon } = this.state
    const unlockedCoupon = unlockedCouponData.coupon
    const unlockedCouponIsValid = unlockedCoupon.skus && unlockedCoupon.skus.includes(skuId)
    if (unlockedCouponIsValid) {
      selectedCoupon = coupons.length.toString()
      coupons.push(unlockedCoupon)
    }
    this.setState({
      coupons: coupons,
      unlockedCoupon: unlockedCoupon
    }, () => {
      if (unlockedCouponIsValid) {
        this.selectCoupon(selectedCoupon)
      }
    })
  }

  componentDidUpdate (prevProps) {
    const projectSku = getLeastCompleteProjectSku(this.props.project)
    const prevProjectSku = getLeastCompleteProjectSku(prevProps.project)
    const price = projectSku && projectSku.price
    const prevPrice = prevProjectSku && prevProjectSku.price
    if (price !== prevPrice) {
      this.setAmount()
      this.loadUserCoupons()
    }
  }

  componentDidMount () {
    this.setAmount()
    this.loadUserCoupons()
  }

  render () {
    const {
      classes,
      stripe,
      project,
      canChangePackage,
      updateProjectSku
    } = this.props
    const {
      baseAmount,
      amount,
      coupons,
      selectedCoupon,
      unlockedCoupon,
      formComplete,
      fields,
      isLoading,
      chargeError,
      customError
    } = this.state
    const {
      cardNumber,
      cardExpiry,
      cardCvc
    } = fields
    const error = (
      cardNumber.error ||
      cardExpiry.error ||
      cardCvc.error ||
      chargeError ||
      customError ||
      null
    )
    const isPriceError = error === priceError
    const isCardPayment = amount >= 50

    const couponIndex = parseInt(selectedCoupon)
    const coupon = !isNaN(couponIndex) ? coupons[couponIndex] : null
    const couponId = coupon ? coupon.id : null

    const projectSku = getLeastCompleteProjectSku(project)
    const skuId = projectSku && projectSku.packageSku
    const unlockedCouponHasSku = unlockedCoupon && unlockedCoupon.skus && unlockedCoupon.skus.includes(skuId)

    const buttonDisabled =
      (isCardPayment && !formComplete) ||
      (isCardPayment && !stripe) ||
      (!isCardPayment && !couponId) ||
      isLoading ||
      !!error

    const usePromo = selectedCoupon === 'usePromo'
    return (
      <React.Fragment>
        <form
          className={classes.form}
          onSubmit={this.handleSubmit}
        >
          {isCardPayment && stripe && <React.Fragment>
            <ElementWrapper
              complete={cardNumber.complete}
              error={!!cardNumber.error}
              focus={!!cardNumber.focus}
              label="Card number:"
              className={classes.cardNumber}
            >
              <CardNumberElement
                options={cardNumOpt}
                className={classes.cardElement}
                onChange={this.handleChange.bind(this)}
                onFocus={this.handleFocus.bind(this, true)}
                onBlur={this.handleFocus.bind(this, false)}
              />
            </ElementWrapper>
            <div className={classes.group}>
              <ElementWrapper
                complete={cardExpiry.complete}
                error={!!cardExpiry.error}
                focus={!!cardExpiry.focus}
                label="Expiration date:"
                className={classes.cardExpiry}
              >
                <CardExpiryElement
                  className={classes.cardElement}
                  onChange={this.handleChange.bind(this)}
                  onFocus={this.handleFocus.bind(this, true)}
                  onBlur={this.handleFocus.bind(this, false)}
                  options={defaultOpt}
                />
              </ElementWrapper>
              <ElementWrapper
                complete={cardCvc.complete}
                error={!!cardCvc.error}
                focus={!!cardCvc.focus}
                label="Card CVC:"
                className={classes.cardCvc}
              >
                <CardCvcElement
                  className={classes.cardElement}
                  onChange={this.handleChange.bind(this)}
                  onFocus={this.handleFocus.bind(this, true)}
                  onBlur={this.handleFocus.bind(this, false)}
                  options={defaultOpt}
                />
              </ElementWrapper>
            </div>
          </React.Fragment>}
          {!isPriceError && <Select
            className={classes.selectCoupon}
            value={selectedCoupon}
            onChange={this.handleCouponChange.bind(this)}>
            <MenuItem value={'nocoupon'}><em>Your offers</em></MenuItem>
            {coupons.map((coupon, index) => (
              <MenuItem key={index} value={index.toString()}>
                {coupon.title + ' (' +
                  (coupon.amount_off
                    ? formatPrice(coupon.amount_off)
                    : coupon.percent_off + '%') +
                  ' off)'
                }
              </MenuItem>
            ))
            }
            <MenuItem
              value={'usePromo'}
              classes={{
                root: unlockedCouponHasSku ? classes.hidden : null
              }}
            >
              <em>Enter a code...</em>
            </MenuItem>
          </Select>}
          <p className={classes.errorText}>{error ? `${error}` : ' '}</p>
          {usePromo && <React.Fragment>
            <UsePromo isPayment={true} onSuccess={this.onPromoUnlock.bind(this)} />
            {unlockedCoupon &&
              <React.Fragment>
                <p>{'You unlocked the code:  ' + unlockedCoupon.title}</p>
                {!unlockedCouponHasSku &&
                  <p>Unfortunately, that code is not valid for the selected package. Do you have a different code?</p>
                }
              </React.Fragment>
            }
          </React.Fragment>
          }
          {!isPriceError && <React.Fragment>
            <div className={classes.priceText}>
              <span>Price: {formatPrice(baseAmount)}</span>
              {canChangePackage && <PackageSelector
                projectId={project.id}
                packageSkuId={projectSku.packageSku}
                creationStatus={project.creationStatus}
                updateProjectSku={updateProjectSku}
              />}
            </div>
            {baseAmount !== amount &&
            <p className={classes.priceText}>Final price: {formatPrice(amount)}</p>
            }
          </React.Fragment>}
          <div className={classes.loading}>
            {isLoading && <React.Fragment>
              <span>processing payment...</span>
              <CircularProgress className={classes.loadingRing} />
            </React.Fragment>}
          </div>
          {!isPriceError &&
          <UrlifeButton disabled={buttonDisabled} type='submit' className={classes.payButton}>
            {isCardPayment ? 'Pay' : 'Redeem offer'}
          </UrlifeButton>}
        </form>
      </React.Fragment>
    )
  }
}

CardPayment.propTypes = {
  canChangePackage: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  couponId: PropTypes.string,
  elements: PropTypes.object.isRequired,
  fetchUserCouponsFunction: PropTypes.func.isRequired,
  getPaymentIntentFunction: PropTypes.func.isRequired,
  paymentComplete: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  purchaseWithCouponFunction: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  updateProjectSku: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

CardPayment.defaultProps = {
  canChangePackage: true
}

const mapStateToProps = state => {
  return {
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUserCouponsFunction: function (userId, skuId) {
      return dispatch(fetchUserCoupons(userId, { skus: skuId, state: ['unredeemed', 'unexpired'] }))
    },
    getPaymentIntentFunction: function (projectId, skuId, couponId) {
      return dispatch(getPaymentIntent(projectId, skuId, couponId))
    },
    purchaseWithCouponFunction: function (projectId, skuId, couponId) {
      return dispatch(purchaseWithCoupon(projectId, skuId, couponId))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CardPayment))
