import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { closeMoreMenu, toggleModal } from '../../../../actions'
import { history } from '../../../../helpers'
import UrlifeList from './UrlifeList'
import { InfoIcon, PackageIcon, SettingsIcon } from '../../SVG/index'

const loggedOutMenu = [
  {
    action: 'support',
    icon: <InfoIcon />,
    text: 'help & support',
    type: 'list-item'
  },
  {
    action: 'discover',
    icon: <InfoIcon />,
    text: 'discover',
    type: 'list-item'
  },
  {
    action: 'packages',
    icon: <PackageIcon />,
    text: 'packages',
    type: 'list-item'
  },
  {
    action: 'about',
    icon: <InfoIcon />,
    text: 'about',
    type: 'list-item'
  },
  {
    action: 'faq',
    icon: <InfoIcon />,
    text: 'faq',
    type: 'list-item'
  },
  {
    action: 'privacy',
    icon: <InfoIcon />,
    text: 'privacy',
    type: 'list-item'
  },
  {
    action: 'terms',
    icon: <InfoIcon />,
    text: 'terms',
    type: 'list-item'
  },
  {
    type: 'divider'
  },
  {
    action: 'login',
    icon: <SettingsIcon />,
    text: 'log in',
    type: 'list-item'
  },
  {
    action: 'register',
    icon: <SettingsIcon />,
    text: 'create account',
    type: 'list-item'
  }
]

class LoggedOutMoreMenu extends PureComponent {
  handleClick (action) {
    const { toggleModalFunction, closeMoreMenuFunction } = this.props
    switch (action) {
      case 'support':
        history.push('/support')
        closeMoreMenuFunction()
        break
      case 'discover':
        history.push('/explore')
        closeMoreMenuFunction()
        break
      case 'packages':
        history.push('/pricing')
        closeMoreMenuFunction()
        break
      case 'about':
        history.push('/aboutus')
        closeMoreMenuFunction()
        break
      case 'faq':
        history.push('/faq')
        closeMoreMenuFunction()
        break
      case 'privacy':
        history.push('/privacy')
        closeMoreMenuFunction()
        break
      case 'terms':
        history.push('/terms')
        closeMoreMenuFunction()
        break
      case 'login':
        toggleModalFunction(true, 'login')
        closeMoreMenuFunction()
        break
      case 'register':
        toggleModalFunction(true, 'register')
        closeMoreMenuFunction()
        break
      default:
        break
    }
  }

  render () {
    return (
      <UrlifeList
        items={loggedOutMenu}
        handleClick={this.handleClick.bind(this)}
      />
    )
  }
}

LoggedOutMoreMenu.propTypes = {
  closeMoreMenuFunction: PropTypes.func.isRequired,
  toggleModalFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    closeMoreMenuFunction: function () {
      dispatch(closeMoreMenu())
    },
    toggleModalFunction: function (isOpenModal, componentModal) {
      dispatch(toggleModal(isOpenModal, componentModal))
    }
  }
}

export default connect(null, mapDispatchToProps)(LoggedOutMoreMenu)
