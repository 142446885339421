import React from 'react'
import PropTypes from 'prop-types'
import { AndroidIcon, AppleIcon } from '../SVG'
import DevicesIcon from '@material-ui/icons/Devices'

function UploadSourceType ({ type, icon, className }) {
  const isWeb = type === 'web' && 'Web App'
  const isIOS = type === 'iOS' && 'iOS App'
  const isAndroid = type === 'android' && 'Android App'
  const validType = isWeb || isIOS || isAndroid
  return (
    <React.Fragment>
      {icon && validType &&
        <React.Fragment>
          {isWeb && <DevicesIcon className={className} data-testid='ust-web' />}
          {isIOS && <AppleIcon className={className} data-testid='ust-ios'/>}
          {isAndroid && <AndroidIcon className={className} data-testid='ust-android'/>}
        </React.Fragment>
      }
      {!icon && validType && <span>Uploaded From: {validType}</span>}
    </React.Fragment>
  )
}

UploadSourceType.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.bool,
  type: PropTypes.string
}

export default UploadSourceType
