import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import App from './components/App'
import urlife from './reducers'
import './styles/index.css'
import { composeWithDevTools } from 'redux-devtools-extension'
const store = createStore(urlife, composeWithDevTools(applyMiddleware(thunkMiddleware)))
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
