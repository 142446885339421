import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import StarsMovie from '../../../common/Stars/StarsMovie'
import UrlifeButton from '../../../common/UrlifeButton'
import { commonStyles } from '../../../../helpers'
import {
  setTopBarHeaders
} from '../../../../actions'

/* eslint-disable sort-keys */
const styles = theme => ({
  createMovieContent: {
    maxWidth: 600,
    margin: 'auto'
  },
  starsInfoText: {
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    color: '#323232',
    margin: '30px 0 50px 0',
    ...commonStyles.media(575, {
      fontSize: '14px',
      marginBottom: 40
    })
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: 30
  },
  nextButton: {
    padding: '8px 30px',
    margin: '5px 8px',
    width: 210,
    height: 46,
    ...commonStyles.media(1280, {
      padding: '5px 15px'
    })
  }
})
/* eslint-enable sort-keys */

class CharactersMovie extends React.Component {
  componentDidMount () {
    this.props.setTopBarHeadersFunction()
  }

  async onNext () {
    const {
      nextStep,
      updateProject
    } = this.props
    await updateProject({}, { cast: true })
    nextStep()
  }

  render () {
    const { classes, projectId } = this.props
    return (
      <div className={classes.createMovieStepthree}>
        <div className={classes.createMovieContent}>
          <div className={classes.starsInfoText}>
            Who do you want us to pay close attention to?
          </div>
          <StarsMovie type={'createProject'} projectId={projectId} />
          <div className={classes.buttonBlock}>
            <UrlifeButton
              className={classes.nextButton}
              onClick={() => this.onNext()}>
              Next
            </UrlifeButton>
          </div>
        </div>
      </div>
    )
  }
}

CharactersMovie.propTypes = {
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  setTopBarHeadersFunction: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    setTopBarHeadersFunction: function () {
      dispatch(setTopBarHeaders('Notes', 'Who is Featured in your Movie?'))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(CharactersMovie))
