import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import {
  fetchUserCoupons,
  getPackageSku,
  listProjects,
  userInfoById
} from '../../actions'
import {
  getLeastCompleteProjectSku,
  getLeastCompleteProjectSkuStatus,
  getSkinIds,
  history
} from '../../helpers'
import {
  PROJECT_STATUSES,
  PROJECT_STATUSES_NAME,
  ROLES
} from '../../constants'
import ProjectListHeader from './components/ProjectListHeader'
import ProjectListScroller from './components/ProjectListScroller'
import { getSkinData } from '../../reducers/skinning'

const styles = {
  paper: {
    background: 'none'
  }
}

export class UrlifeProjectsPage extends React.Component {
  constructor (props) {
    super(props)

    const type = this.getTypeFromPath(props.location.pathname)
    props.dispatch({ type: 'CLEAR_PROJECT_LIST' })
    this.state = {
      deleted: false,
      freeCoupons: [],
      isLoading: false,
      nextProject: null,
      projectGroups: [],
      skinIds: {},
      sort: props.sort,
      sortOrder: props.sortOrder,
      statuses: this.getStatusesForType(type),
      type: type,
      unlisten: history.listen(this.onRouteChange.bind(this)),
      users: {},
      view: 'card'
    }
  }

  componentDidUpdate (prevProps) {
    const { projectList } = this.props
    const { type } = this.state
    const firstPrevProject = prevProps.projectList[0]
    const firstProject = this.props.projectList[0]

    if (type === 'assigned' || type === 'unassigned') {
      if (projectList !== prevProps.projectList) {
        const userIds = projectList.map(project => project.customerId)
        this.getUsersData(userIds)
      }
    }

    if (
      (!firstPrevProject && firstProject) ||
      (firstPrevProject && firstProject && firstPrevProject.id !== firstProject.id)
    ) {
      this.setState(
        { isLoading: true },
        () => this.checkForSkin.bind(this)
      )
    }
  }

  getUsersData (userIds) {
    const { dispatch } = this.props
    const { users } = this.state
    userIds.filter(userId => !users[userId]).forEach(async userId => {
      try {
        const response = await dispatch(userInfoById(userId))
        this.setState({
          users: {
            ...this.state.users,
            [userId]: response.data
          }
        })
      } catch (e) {}
    })
  }

  groupProjects (projectList) {
    const {
      type,
      statuses,
      deleted,
      skinIds
    } = this.state
    const productionStatuses = [
      PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
      PROJECT_STATUSES.PRODUCTION_EDITING,
      PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW,
      PROJECT_STATUSES.PRODUCTION_REVISIONS
    ]
    if (!projectList) return []
    const projectGroups = []
    switch (type) {
      case 'complete':
        const years = {}
        let yearKeys = []
        projectList.forEach(proj => {
          let notComplete
          statuses.forEach(status => {
            if (getLeastCompleteProjectSkuStatus(proj) !== status) notComplete = true
          })
          if (notComplete) {
            return
          }
          const [year] = proj.createdAt.match(/^[0-9]{4}/)
          if (years[year]) {
            years[year].push(proj)
          } else {
            years[year] = [proj]
          }
        })
        yearKeys = Object.keys(years).sort((a, b) => parseInt(b) - parseInt(a))
        yearKeys.forEach(year => {
          projectGroups.push({
            projects: years[year] ? years[year] : [],
            title: year.toString()
          })
        })
        break
      case 'assigned':
        const hasCompleted = statuses.find(status => status === PROJECT_STATUSES.COMPLETE)
        let completedTitle = hasCompleted ? PROJECT_STATUSES_NAME[PROJECT_STATUSES.COMPLETE] : ''
        if (deleted) {
          if (hasCompleted) {
            completedTitle += ' & '
          }
          completedTitle += 'Deleted'
        }
        const completedGroup = {
          projects: [],
          title: completedTitle
        }
        const productionGroup = {
          projects: [],
          title: PROJECT_STATUSES_NAME[PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED]
        }
        const awaitingGroup = {
          projects: [],
          title: PROJECT_STATUSES_NAME[PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL]
        }
        // iterate over projects in projectList so we keep correct ordering
        projectList.forEach(project => {
          const status = getLeastCompleteProjectSkuStatus(project)
          const isInProduction = productionStatuses.includes(status)
          const isAwaitingApproval = status === PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
          const isProjectComplete = status === PROJECT_STATUSES.COMPLETE
          const isProjectDeleted = project.deleted || status === PROJECT_STATUSES.DELETED

          if (isInProduction && !isProjectDeleted) {
            productionGroup.projects.push(project)
          }
          if (isAwaitingApproval && !isProjectDeleted) {
            awaitingGroup.projects.push(project)
          }
          // deleted projects appear in the completed group
          if (isProjectComplete || (isProjectDeleted && deleted)) {
            completedGroup.projects.push(project)
          }
        })
        if (productionGroup.projects.length > 0) {
          projectGroups.unshift(productionGroup)
        }
        if (awaitingGroup.projects.length > 0) {
          projectGroups.push(awaitingGroup)
        }
        if ((hasCompleted || deleted) && completedGroup.projects.length > 0) {
          projectGroups.push(completedGroup)
        }
        break
      default:
        const updatedProjectList = projectList.map(proj => {
          const projectSku = getLeastCompleteProjectSku(proj)
          const packageSkuId = projectSku && projectSku.packageSku
          const skinData = getSkinData(skinIds[packageSkuId])
          return ({
            ...proj,
            skinnedThumbnail: skinData && skinData.projectThumbnail16x9
          })
        })
        projectGroups.push({
          projects: updatedProjectList,
          title: ''
        })
        break
    }
    return projectGroups
  }

  fetchProjectsByType (params) {
    const { dispatch, roleId } = this.props
    const {
      type,
      statuses,
      deleted
    } = this.state
    let filter = ''
    switch (type) {
      case 'unassigned':
        filter = 'assigned=false,'
        if (statuses.length > 0) {
          filter += statuses.map(status => 'status=' + status).join(',')
        } else if (roleId === ROLES.PRODUCER) {
          filter += 'status=' + PROJECT_STATUSES.PROJECT_QUEUED
        } else {
          filter += 'status=' + PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED
        }
        return dispatch(listProjects({ ...params, filter }))
      case 'assigned':
        if (deleted) {
          filter = 'deleted=true'
          dispatch(listProjects({ ...params, filter }, projects => {
            return projects.filter(proj => proj.deleted === true)
          }))
        }
        filter = statuses.map(status => 'status=' + status).join(',')
        return dispatch(listProjects({ ...params, filter }))
      default:
        if (statuses.length > 0) filter = statuses.map(status => 'status=' + status).join(',')
        return dispatch(listProjects({ ...params, filter }))
    }
  }

  fetchProjects () {
    const {
      sort,
      sortOrder,
      nextProject
    } = this.state
    const params = { cursor: nextProject, method: sortOrder, sort }

    return this.setState(
      { isLoading: true },
      async () => {
        try {
          const response = await this.fetchProjectsByType(params)
          this.setState({
            nextProject: response.nextCursor
          })
          this.checkForSkin()
          return response
        } catch (e) {}
      }
    )
  }

  async checkForSkin () {
    const { dispatch, projectList, packages } = this.props
    const skinIds = await getSkinIds(
      projectList,
      packages,
      (skuId) => dispatch(getPackageSku(skuId)),
      this.state.skinIds
    )
    this.setState({
      isLoading: false,
      skinIds
    })
  }

  fetchCoupons () {
    const { dispatch, userId } = this.props
    dispatch(fetchUserCoupons(userId, { state: ['unredeemed', 'unexpired'] }))
      .then(res => {
        let freeCoupons = []
        if (res.data && res.data.length > 0) {
          freeCoupons = res.data.filter(coupon => coupon.percent_off && coupon.percent_off >= 100)
        }
        this.setState({ freeCoupons })
      })
      .catch(e => {
        // ignore error
      })
  }

  getTypeFromPath (path) {
    return path.slice(path.lastIndexOf('/') + 1, path.length)
  }

  getStatusesForType (type) {
    const { roleId } = this.props
    switch (type) {
      case 'complete':
        return [PROJECT_STATUSES.COMPLETE]
      case 'inprogress':
        return [
          PROJECT_STATUSES.PREP_USER,
          PROJECT_STATUSES.PREP_WAIT_PAYMENT,
          PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION,
          PROJECT_STATUSES.PROJECT_QUEUED,
          PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
          PROJECT_STATUSES.PRODUCTION_EDITING,
          PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW,
          PROJECT_STATUSES.PRODUCTION_REVISIONS,
          PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
        ]
      case 'assigned':
        return [
          PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
          PROJECT_STATUSES.PRODUCTION_EDITING,
          PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW,
          PROJECT_STATUSES.PRODUCTION_REVISIONS,
          PROJECT_STATUSES.PRODUCTION_WAIT_CUSTOMER_APPROVAL
        ]
      case 'unassigned':
        if (roleId === ROLES.PRODUCER) {
          return [
            PROJECT_STATUSES.PROJECT_QUEUED,
            PROJECT_STATUSES.PREP_CUSTOM_NEGOTIATION
          ]
        } else {
          return [PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED]
        }
      default:
        return []
    }
  }

  onRouteChange (location, action) {
    const { dispatch } = this.props
    dispatch({ type: 'CLEAR_PROJECT_LIST' })
    const type = this.getTypeFromPath(location.pathname)
    this.setState({
      deleted: false,
      nextProject: null,
      projectGroups: [],
      statuses: this.getStatusesForType(type),
      type: type
    },
    this.fetchProjects)
  }

  setProjectPath (path) {
    history.push('/projects/' + path)
  }

  setView (view) {
    this.setState({ view: view })
  }

  setStatus (e) {
    const { dispatch } = this.props
    const { statuses } = this.state
    dispatch({ type: 'CLEAR_PROJECT_LIST' })

    const productionStatuses = [
      PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED,
      PROJECT_STATUSES.PRODUCTION_EDITING,
      PROJECT_STATUSES.PRODUCTION_PRODUCER_REVIEW,
      PROJECT_STATUSES.PRODUCTION_REVISIONS
    ]
    let newStatuses = statuses
    const status = +e.target.value
    if (e.target.checked) {
      if (status === PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED) {
        const statusArr = productionStatuses.filter(s => !newStatuses.includes(+s))
        newStatuses.push(...statusArr)
      } else {
        newStatuses.push(status)
      }
    } else {
      if (status === PROJECT_STATUSES.PRODUCTION_PRODUCER_ASSIGNED) {
        newStatuses = statuses.filter(s => !productionStatuses.includes(+s))
      } else {
        newStatuses = statuses.filter(s => +s !== status)
      }
    }
    this.setState({ nextProject: null, projectGroups: [], statuses: newStatuses }, this.fetchProjects)
  }

  setDeleted (e) {
    const { dispatch } = this.props
    dispatch({ type: 'CLEAR_PROJECT_LIST' })
    let deleted
    if (e.target.checked) {
      deleted = true
    } else {
      deleted = false
    }
    this.setState({ deleted, nextProject: null, projectGroups: [] }, this.fetchProjects)
  }

  componentDidMount () {
    const { roleId } = this.props
    if (roleId === ROLES.USER) {
      this.fetchCoupons()
    }
    this.fetchProjects()
  }

  componentWillUnmount () {
    this.state.unlisten()
  }

  render () {
    const {
      classes,
      roleId,
      packages,
      projectList
    } = this.props
    const {
      freeCoupons,
      nextProject,
      isLoading,
      users,
      type,
      statuses,
      deleted,
      view
    } = this.state
    const projectGroups = this.groupProjects(projectList)
    const mapPackages = {}
    if (packages) {
      packages.forEach(pack => {
        mapPackages[pack.id] = pack
      })
    }
    return (
      <div>
        <Paper elevation={0} className={classes.paper}>
          <ProjectListHeader
            type={type}
            view={view}
            setView={this.setView.bind(this)}
            statuses={statuses}
            deleted={deleted}
            setStatus={this.setStatus.bind(this)}
            setDeleted={this.setDeleted.bind(this)}
            setProjectPath={this.setProjectPath.bind(this)}
            assignedOnly={roleId === ROLES.EDITOR}
            inProductionOnly={roleId === ROLES.EDITOR}
          />
          <ProjectListScroller
            type={type}
            view={view}
            projectGroups={projectGroups}
            users={users}
            mapPackages={mapPackages}
            hasMore={!!nextProject}
            isLoading={isLoading}
            loadMore={this.fetchProjects.bind(this)}
            freeCoupons={freeCoupons}
          />
        </Paper>
      </div>
    )
  }
}

UrlifeProjectsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  packages: PropTypes.arrayOf(Object),
  projectList: PropTypes.arrayOf(Object),
  roleId: PropTypes.number,
  sort: PropTypes.string,
  sortOrder: PropTypes.oneOf(['ASC', 'DESC']),
  userId: PropTypes.string
}

UrlifeProjectsPage.defaultProps = {
  projectList: [],
  sort: 'createdAt',
  sortOrder: 'DESC'
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages,
    projectList: state.projectList.projects,
    roleId: state.user.roleId,
    userId: state.user.userid
  }
}

export default withStyles(styles)(connect(mapStateToProps)(UrlifeProjectsPage))
