import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { inherits, toLocale } from '../../../helpers'
import { ProjectPlaceholder } from '../../common/SVG/ProjectPlaceholder'
import { getImageRotationPureCss } from '../../../helpers/imageRotationCss'
import { cacheSignedUrl } from '../../../services/CacheSignedUrls'
import styled from 'styled-components'

/* eslint-disable sort-keys */
const styles = inherits({
  myMoviePreparationText: 'myMoviePreparationWaiting'
})({
  myMovieWrap: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Montserrat',
    position: 'relative',
    height: 80,
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 10px rgba(0, 0, 0, 0.07)',
    borderRadius: 2,
    '&:hover': {
      opacity: '0.7',
      backgroundColor: 'rgba(50, 50, 50, 0.1)'
    }
  },
  projectPlaceholder: {
    position: 'absolute',
    left: 10,
    top: 10,
    bottom: 10,
    width: 60,
    height: 60,
    color: '#dde3e8',
    fontSize: 107
  },
  myMovieTitle: {
    fontSize: '18px',
    lineHeight: '25.45px',
    marginTop: 3,
    textAlign: 'left',
    fontWeight: 500,
    color: '#393939',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  myMovieInfo: {
    fontSize: 13,
    color: '#323232',
    fontWeight: 500,
    textAlign: 'left',
    opacity: '0.8'
  },
  movieInfoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 70,
    right: 0,
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column'
  }
})
/* eslint-enable sort-keys */

const MovieContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 10px;
  background-size: cover;
  background-position: center center;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-image: url(${props => props.thumbnail});
  ${props => getImageRotationPureCss(props.rotation)}
`

class ProjectContentList extends React.Component {
  render () {
    const { classes, project, user } = this.props
    const movieDate = project.summaryDate || toLocale(new Date(project.createdAt), { forMyMovie: true })
    return (
      <div
        className={classes.myMovieWrap}
        ref={ref => { this.coverRef = ref }}
      >
        {project.coverUrl && <MovieContainer
          thumbnail={cacheSignedUrl.get(project.coverUrl)}
          rotation={project.coverFileOrientation}
        >
        </MovieContainer>}
        {!project.coverUrl &&
          <ProjectPlaceholder className={classes.projectPlaceholder} fill={'#dde3e8'} />
        }
        <div className={classes.movieInfoContainer}>
          <div className={classes.myMovieInfo}>{movieDate}</div>
          <div className={classes.myMovieTitle}>{project && project.title}</div>
          <div className={classes.myMovieInfo}>{user ? user.fullname : '...'}</div>
        </div>
      </div>
    )
  }
}

ProjectContentList.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  user: PropTypes.object
}

export default withStyles(styles)(ProjectContentList)
