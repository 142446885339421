import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ReactGA from 'react-ga4'
import { commonStyles, history } from '../helpers'
import PrivateRoute from './MainLayout/PrivateRoute'
import {
  checkForNewUserNotifications,
  getAllCategories,
  getPackages,
  getUserNotifications,
  sendCloseLog,
  toggleModal,
  userInfo,
  userLogout,
  wantsLogin
} from '../actions'
import {
  DEBUG_LEVEL,
  GA_TRACKING_ID,
  ROLE_NAMES,
  ROLES
} from '../constants'
import UrlifeParallax from '../components/urlifeparallax/UrlifeParallax'
import UrlifeHomePage from './home/UrlifeHomePage'
import UrlifeSettingsPage from './settings/UrlifeSettingsPage'
import UrlifeProjectsPage from './projects/UrlifeProjectsPage'
import EditProjectPage from './projects/EditProjectPage'
import UrlifeUsersPage from './admin/users/UrlifeUsersPage'
import UrlifeConnection from './common/UrlifeConnection'
import Invites from './admin/Invites'
import CreateMovie from '../components/createmovie/CreateMovie'
import Explore from './explore/Explore'
import CurationBeforeAfter from './explore/CurationBeforeAfter'
import CurationExpanded from './explore/CurationExpanded'
import Privacy from './privacy/Privacy'
import Terms from './terms/Terms'
import AboutUs from './aboutus/AboutUs'
import FAQPage from './faq/FAQPage'
import Support from './support/Support'
import Modal from './Modal/Modal'
import UrlifeResetPassword from './UrlifeResetPassword/UrlifeResetPassword'
import Pricing from './pricing/Pricing'
import Movie from './movie/Movie'
import MakeChanges from './movie/MakeChanges'
import MovieScheduleCall from './movie/MovieScheduleCall'
import UrlifeTopBar from './MainLayout/UrlifeTopBar'
import UrlifeUserInfo from './admin/users/UrlifeUserInfo'
import UrlifeUserCoupons from './admin/users/UrlifeUserCoupons'
import '../helpers/polyfill'
import Categories from './admin/Categories'
import EditExplore from './admin/EditExplore'
import EditInspiration from './admin/EditInspiration'
import EditPrompts from './admin/EditPrompts'
import EditTips from './admin/EditTips'
import Promocodes from './admin/promocode/Promocodes'
import CreatePromocode from './admin/promocode/CreatePromocode'
import EditPromocode from './admin/promocode/EditPromocode'
import Checkout from './payment/Checkout'
import AdminHome from './admin/AdminHome'
import ProjectCodeGroupings from './admin/projectcodes/ProjectCodeGroupings'
import CreateProjectCodeGrouping from './admin/projectcodes/CreateProjectCodeGrouping'
import EditProjectCodeGrouping from './admin/projectcodes/EditProjectCodeGrouping'
import GenerateProjectCodes from './admin/projectcodes/GenerateProjectCodes'
import NegotiationList from './admin/negotiation/NegotiationList'
import EmailTemplates from './admin/emails/EmailTemplates'
import EditEmailTemplate from './admin/emails/EditEmailTemplate'
import AdminProjectList from './admin/AdminProjectList'
import AdminEncodingQueue from './admin/AdminEncodingQueue'
import AdminReports from './admin/AdminReports'
import ReportsAwaitingPayment from './admin/ReportsAwaitingPayment'
import ReportsFirstTimeUsers from './admin/ReportsFirstTimeUsers'
import ReportsNewUsers from './admin/ReportsNewUsers'
import ReportsPrePurchaseUsers from './admin/ReportsPrePurchaseUsers'
import ReportsProjectApproval from './admin/ReportsProjectApproval'
import ReportsRepeatUsers from './admin/ReportsRepeatUsers'
import ReportsReturningUsers from './admin/ReportsReturningUsers'
import TopTen from './topten/TopTen'
import Moms from './moms/Moms'
import TipsRouter from './tips/TipsRouter'
import Travelink from './partnerLandingPage/Travelink'
import Silversea from './partnerLandingPage/Silversea'
import Celebrity from './partnerLandingPage/Celebrity'
import SilverseaPostVoyage from './partnerLandingPage/SilverseaPostVoyage'
import NotreDame from './partnerLandingPage/NotreDame'
import { parse } from 'query-string'
import AdminTopicSubscriptions from './admin/AdminTopicSubscriptions'

/* eslint-disable sort-keys */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#01b7d7',
      contrastText: '#fff'
    },
    secondary: {
      main: '#01b7d7',
      contrastText: '#000'
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontWeight: 300,
      lineHeight: 1.25,
      fontSize: 48,
      color: '#1c2025',
      '&$gutterBottom': {
        marginBottom: '.8em'
      },
      ...commonStyles.media(767, {
        fontSize: 36
      }),
      ...commonStyles.media(575, {
        fontSize: 24
      }),
      ...commonStyles.mediaDimensions(896, 414, {
        fontSize: 24
      })
    }
  }
})
/* eslint-enable sort-keys */

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loggedIn: props.loggedIn || false,
      possibleRedirect: false,
      redirectOnRoleIdSet: false,
      redirectPath: '',
      unlisten: history.listen(this.onRouteChange.bind(this)),
      userInfoRedirect: false
    }

    ReactGA.initialize(GA_TRACKING_ID)
    ReactGA.set({
      path: history.location.pathname,
      roleId: props.roleId || ROLES.GUEST,
      userId: props.userId
    })

    if (!props.loggedIn) {
      props.dispatch(userInfo())
      this.state.possibleRedirect = true
      this.state.userInfoRedirect = true
    }

    this.getInitialNotifications()
    this.refreshPackages()
    this.refreshCategories()

    if (DEBUG_LEVEL !== 'none') {
      /* eslint-disable-next-line no-console */
      console.debug('APP STARTED WITH DEBUG LEVEL SET TO: ' + DEBUG_LEVEL)
    }

    const parsed = parse(history.location.search)
    if (parsed.modal && typeof parsed.modal === 'string') {
      props.dispatch(toggleModal(true, parsed.modal.toLowerCase()))
    }
    window.addEventListener('beforeunload', () => {
      props.dispatch(sendCloseLog())
    })
  }

  static getDerivedStateFromProps (props, state) {
    const newState = state

    if (props.loggedIn !== state.loggedIn) {
      newState.loggedIn = props.loggedIn

      if (props.loggedIn) {
        const possibleRedirect = state.possibleRedirect
        const userInfoRedirect = state.userInfoRedirect
        const fromPath = !!(history.location.state && history.location.state.from)
        newState.possibleRedirect = false
        newState.redirectOnRoleIdSet = (!userInfoRedirect || !fromPath) && !props.roleId
        newState.redirectPath = ''
        newState.userInfoRedirect = false

        if (possibleRedirect && fromPath) {
          const path = history.location.state.from.pathname
          const search = history.location.state.from.search
          newState.redirectOnRoleIdSet = true
          newState.redirectPath = path + search
          props.dispatch(toggleModal(false, 'login'))
        }
      } else if (!props.wasManualLogout && !props.wasAutomaticLogout) {
        newState.possibleRedirect = true
        newState.userInfoRedirect = false
      }
    } else if (props.wantsLogin && !props.loggedIn) {
      newState.possibleRedirect = false
      newState.redirectOnRoleIdSet = true
      newState.redirectPath = ''
      newState.userInfoRedirect = false
      props.dispatch(wantsLogin(false))
      props.dispatch(toggleModal(true, 'login'))
    }

    if (props.roleId && newState.redirectOnRoleIdSet) {
      let redirectPath = newState.redirectPath
      newState.redirectPath = ''
      newState.redirectOnRoleIdSet = false
      let historyState = null

      if (redirectPath.length === 0) {
        switch (props.roleId) {
          case ROLES.USER:
            redirectPath = '/home'
            if (props.skinData) {
              historyState = { type: 'skinned' }
            }
            break
          case ROLES.ADMIN:
            redirectPath = '/admin/home'
            break
          case ROLES.PRODUCER:
          case ROLES.EDITOR:
            redirectPath = '/projects/assigned'
            break
          default:
            break
        }
      }
      if (redirectPath.length > 0) {
        history.push(redirectPath, historyState)
      }
    }

    return newState
  }

  async refreshPackages () {
    const { dispatch } = this.props
    try {
      await dispatch(getPackages())
    } catch (e) { /* ignore this error */ }
    // Every 2 hours after initial.
    window.setTimeout(this.refreshPackages.bind(this), 120 * 60 * 1000)
  }

  async refreshCategories () {
    const { dispatch, roleId } = this.props
    try {
      await dispatch(getAllCategories(roleId === ROLES.ADMIN ? 'all' : null))
    } catch (e) { /* ignore this error */ }
    // Every 2 hours after initial.
    this.categoriesTimeout = window.setTimeout(this.refreshCategories.bind(this), 120 * 60 * 1000)
  }

  async getInitialNotifications () {
    const { dispatch, loggedIn, userId } = this.props
    if (loggedIn && userId) {
      await dispatch(getUserNotifications(userId, { count: 100 }))
      window.setTimeout(this.checkForNewNotifications.bind(this), 60000)
    }
  }

  async checkForNewNotifications () {
    const { dispatch, loggedIn, userId } = this.props
    if (loggedIn && userId) {
      await dispatch(checkForNewUserNotifications(userId))
      window.setTimeout(this.checkForNewNotifications.bind(this), 60000)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.loggedIn && this.props.userId && !prevProps.userId) {
      this.getInitialNotifications()
    }
    if (!this.props.userId && prevProps.userId) {
      ReactGA.set({ userId: this.props.userId })
    }
    if (this.props.roleId !== prevProps.roleId) {
      ReactGA.set({ roleId: this.props.roleId || ROLES.GUEST })
      if (this.categoriesTimeout) {
        clearTimeout(this.categoriesTimeout)
      }
      this.refreshCategories()
    }
    ReactGA.set({ dimension1: this.props.roleId ? ROLE_NAMES[this.props.roleId] : 'VISITOR' })
  }

  componentDidMount () {
    const { dispatch } = this.props
    // watch logout on another browser tab
    window.addEventListener('storage', e => {
      if (e.key === 'auth' && !window.localStorage.getItem('auth')) {
        dispatch(userLogout())
      }
    })
    window.addEventListener('dragover', function (e) {
      e.preventDefault()
    }, false)
    window.addEventListener('drop', function (e) {
      e.preventDefault()
    }, false)
  }

  componentWillUnmount () {
    this.state.unlisten()
  }

  onRouteChange () {
    ReactGA.send({ hitType: 'pageview', page: history.location.pathname, title: document.title })
  }

  render () {
    const { loggedIn, roleId, isOpenModal } = this.props
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <Route path="/">
            <Fragment>
              {isOpenModal && <Modal />}

              {/* header */}
              <UrlifeTopBar/>

              {/* body */}
              <Switch>
                <PrivateRoute
                  path="/"
                  exact
                  isMainPage={true}
                  component={UrlifeParallax}
                  showFooter={false}
                />
                <PrivateRoute path="/createmovie/:subPath?/:projectId?"
                  loggedIn={loggedIn}
                  isCreateProjectPage={true}
                  role={roleId}
                  requiredRole={Object.values(ROLES)}
                  showFooter={false}
                  component={CreateMovie} />
                <PrivateRoute
                  exact
                  path="/explore"
                  component={Explore}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/explore/curation-before-after"
                  component={CurationBeforeAfter}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/explore/curation-expanded"
                  component={CurationExpanded}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/top10"
                  component={TopTen}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute path="/tips/:tip?"
                  loggedIn={loggedIn}
                  role={roleId}
                  component={TipsRouter} />
                <PrivateRoute
                  exact
                  path="/family/gallery"
                  component={Moms}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/travelink"
                  component={Travelink}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/silversea"
                  component={Silversea}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/silversea/postvoyage"
                  component={SilverseaPostVoyage}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                 exact
                 path="/celebritycruises/redeem"
                 component={Celebrity}
                 loggedIn={loggedIn}
                 role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/notredame"
                  component={NotreDame}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  exact
                  path="/home"
                  component={UrlifeHomePage}
                  isHomePage={true}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                />
                <PrivateRoute
                  path="/settings"
                  component={UrlifeSettingsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={Object.values(ROLES)}
                />
                <PrivateRoute
                  exact
                  path="/projects"
                  component={UrlifeProjectsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2, 3, 4]}
                />
                <PrivateRoute
                  exact
                  path="/projects/complete"
                  component={UrlifeProjectsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                />
                <PrivateRoute
                  exact
                  path="/projects/inprogress"
                  component={UrlifeProjectsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                />
                <PrivateRoute
                  exact
                  path="/projects/assigned"
                  component={UrlifeProjectsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[3, 4]}
                />
                <PrivateRoute
                  exact
                  path="/projects/unassigned"
                  component={UrlifeProjectsPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[3, 4]}
                />
                <PrivateRoute
                  exact
                  path="/projects/:projectId/movie"
                  component={Movie}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2, 3, 4]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/projects/:projectId/skus/:projectSkuId/movie-changes"
                  component={MakeChanges}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/projects/:projectId/skus/:projectSkuId/files/:fileId/movie-changes"
                  component={MakeChanges}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/projects/:projectId/skus/:projectSkuId/movie-schedule-call"
                  component={MovieScheduleCall}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1, 2, 3]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/projects/:projectId/checkout"
                  component={Checkout}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[2]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/projects/:project"
                  component={EditProjectPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1, 2, 3, 4]}
                  showFooter={false}
                />
                <PrivateRoute
                  path="/terms"
                  component={Terms}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  path="/privacy"
                  component={Privacy}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  path="/aboutus"
                  component={AboutUs}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  path="/faq"
                  component={FAQPage}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  path="/support"
                  component={Support}
                  loggedIn={loggedIn}
                  role={roleId}
                />
                <PrivateRoute
                  path="/pricing"
                  component={Pricing}
                  loggedIn={loggedIn}
                  role={roleId}
                  // requiredRole={[1, 2, 3, 4]}
                />
                <PrivateRoute
                  exact
                  path="/reset"
                  component={UrlifeResetPassword}
                  componentProperties={{
                    type: 'reset'
                  }}/>
                <PrivateRoute
                  exact
                  path="/invite"
                  component={UrlifeResetPassword}
                  componentProperties={{
                    type: 'set'
                  }}/>

                {/* admin */}
                <PrivateRoute
                  path="/admin/home"
                  exact
                  component={AdminHome}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/categories"
                  exact
                  component={Categories}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/explore"
                  exact
                  component={EditExplore}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/inspiration"
                  exact
                  component={EditInspiration}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/prompts"
                  exact
                  component={EditPrompts}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/tips"
                  exact
                  component={EditTips}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/users"
                  exact
                  component={UrlifeUsersPage}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/users/:userId"
                  exact
                  component={UrlifeUserInfo}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/users/:userId/coupons"
                  exact
                  component={UrlifeUserCoupons}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/subscriptions"
                  exact
                  component={AdminTopicSubscriptions}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/invites"
                  component={Invites}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/promocodes"
                  exact
                  component={Promocodes}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/promocodes/create"
                  exact
                  component={CreatePromocode}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/promocodes/:promoId"
                  exact
                  component={EditPromocode}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/projectcodes"
                  exact
                  component={ProjectCodeGroupings}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/projectcodes/create"
                  exact
                  component={CreateProjectCodeGrouping}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/projectcodes/:groupId"
                  exact
                  component={EditProjectCodeGrouping}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/projectcodes/:groupId/generate"
                  exact
                  component={GenerateProjectCodes}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/negotiation"
                  exact
                  component={NegotiationList}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/emails"
                  exact
                  component={EmailTemplates}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/emails/:emailId"
                  exact
                  component={EditEmailTemplate}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/projects"
                  exact
                  component={AdminProjectList}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/encoding"
                  exact
                  component={AdminEncodingQueue}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/usersprojectsbyyear"
                  exact
                  component={AdminReports}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/projectapproval"
                  exact
                  component={ReportsProjectApproval}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/awaitingpayment"
                  exact
                  component={ReportsAwaitingPayment}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/repeatingusers"
                  exact
                  component={ReportsRepeatUsers}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/returningusers"
                  exact
                  component={ReportsReturningUsers}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/firsttimeusers"
                  exact
                  component={ReportsFirstTimeUsers}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/prepurchaseusers"
                  exact
                  component={ReportsPrePurchaseUsers}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <PrivateRoute
                  path="/admin/reports/newusers"
                  exact
                  component={ReportsNewUsers}
                  loggedIn={loggedIn}
                  role={roleId}
                  requiredRole={[1]}
                />
                <Route
                  path="*"
                  render={() => (<Redirect to={{ pathname: '/', state: { from: history.location } }} />)}
                />
              </Switch>
              <UrlifeConnection />
            </Fragment>
          </Route>
        </Router>
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  roleId: PropTypes.number,
  skinData: PropTypes.object,
  userId: PropTypes.string,
  wantsLogin: PropTypes.bool,
  wasAutomaticLogout: PropTypes.bool,
  wasManualLogout: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    isOpenModal: state.toggleModal.isOpenModal,
    loggedIn: state.user.loggedIn,
    roleId: state.user.roleId,
    skinData: state.skinning.skinData,
    userId: state.user.userid,
    wantsLogin: state.user.wantsLogin,
    wasAutomaticLogout: state.user.wasAutomaticLogout,
    wasManualLogout: state.user.wasManualLogout
  }
}

export default connect(mapStateToProps)(App)
