export {
  clearFileProcess,
  fileProcessDone,
  getFileBatchData,
  processFiles
}

function processFiles (projectId, count) {
  return dispatch => {
    return new Promise(resolve => {
      return resolve(dispatch({ count, projectId, type: 'FILE_BATCH_PROCESS_FILES' }))
    })
  }
}

function fileProcessDone (projectId, count) {
  return dispatch => {
    dispatch({ count, projectId, type: 'FILE_BATCH_PROCESS_COMPLETED' })
  }
}

function clearFileProcess (projectId) {
  return dispatch => {
    dispatch({ projectId, type: 'FILE_BATCH_CLEAR_PROCESS' })
  }
}

function getFileBatchData () {
  return (dispatch, getStore) => {
    return getStore().fileBatch
  }
}
