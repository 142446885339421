import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  fetchUserCoupons
} from '../../actions'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import UrlifeConfirmDialog from '../common/UrlifeConfirmDialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  CheckMark,
  CloseIcon
} from '../common/SVG'
import { CONTACT_CHOICE, PACKAGE_TYPES } from '../../constants'
import { commonStyles, formatPrice, history } from '../../helpers'

/* eslint-disable sort-keys */
const styles = {
  selectPackage: {
    width: '100%',
    marginBottom: 10,
    '&:after': {
      borderBottomColor: '#01b7d7'
    }
  },
  listItem: {
    padding: 0
  },
  iconWrapper: {
    minWidth: 24
  },
  checkIcon: {
    height: 16,
    color: '#01b7d7'
  },
  closeIcon: {
    height: 16,
    color: '#d70101'
  },
  packSelectItem: {
    margin: 0,
    ...commonStyles.media(575, {
      fontSize: 13
    })
  },
  link: {
    display: 'inline-block',
    marginLeft: 5,
    color: '#01b7d7',
    cursor: 'pointer',
    padding: 0,
    textDecoration: 'underline'
  },
  text: {
    textAlign: 'center'
  }
}
/* eslint-enable sort-keys */

class PackageSelector extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      coupons: [],
      dialogOpen: false,
      fromPackage: null,
      fromPackageSku: null,
      selectedPackageSkuId: props.packageSkuId,
      toPackage: null,
      toPackageSku: null
    }
    this.currentPackageSkuId = props.packageSkuId
    this.packageChangeMap = {
      '1to2': {
        // hollywood to recap
        removed: ['contact']
      },
      '1to3': {
        // hollywood to curated
        removed: ['movie', 'contact', 'details']
      },
      '2to1': {
        // recap to hollywood
        added: ['contact']
      },
      '2to3': {
        // recap to curated
        removed: ['movie', 'details']
      },
      '3to1': {
        // curated to hollywood
        added: ['movie', 'contact', 'details']
      },
      '3to2': {
        // curated to recap
        added: ['movie', 'details']
      }
    }
    this.removedMap = {
      contact: 'Does not include personalized producer/concierge services',
      details: 'Removes options for editing details since these are only required for movie products',
      movie: 'Only includes curated files (no movie)'
    }
    this.addedMap = {
      contact: 'Adds personalized producer/concierge services',
      details: 'Require more details (what is this project about, notes, and featured) to personalize the movie',
      movie: 'Includes a movie produced from your images and movies'
    }
  }

  handlePackageChange (e) {
    const { packages } = this.props
    const selectedPackageSkuId = e.target.value
    let fromPackageSku
    let toPackageSku
    const fromPackage = packages.find(pack => pack.skus.find(sku => {
      if (sku.id === this.currentPackageSkuId) {
        fromPackageSku = sku
      }
      return sku.id === this.currentPackageSkuId
    }))
    const toPackage = packages.find(pack => pack.skus.find(sku => {
      if (sku.id === selectedPackageSkuId) {
        toPackageSku = sku
      }
      return sku.id === selectedPackageSkuId
    }))
    this.setState({
      fromPackage,
      fromPackageSku,
      selectedPackageSkuId,
      toPackage,
      toPackageSku
    })
  }

  cancelDialog () {
    this.setState({
      dialogOpen: this.state.fromPackage && this.state.toPackage,
      fromPackage: null,
      fromPackageSku: null,
      selectedPackageSkuId: this.currentPackageSkuId,
      toPackage: null,
      toPackageSku: null
    })
  }

  needAdditionalInfo () {
    const { creationStatus } = this.props
    const { fromPackage, toPackage } = this.state
    if (fromPackage.type === toPackage.type) {
      return false
    }
    if (!creationStatus) {
      return true
    }
    if (fromPackage.type === PACKAGE_TYPES.CURATED && creationStatus.category === false) {
      return true
    }
    if (toPackage.type === PACKAGE_TYPES.RECAP &&
      (
        creationStatus.totalNumberOfPrompts === 0 ||
        creationStatus.promptsAnswered !== creationStatus.totalNumberOfPrompts
      )
    ) {
      return true
    }
    if (toPackage.type === PACKAGE_TYPES.HOLLYWOOD &&
      (
        creationStatus.contactChoice === CONTACT_CHOICE.CHOICE_NOT_MADE ||
        creationStatus.contactChoice === CONTACT_CHOICE.PRODUCER_CONTACT
      )
    ) {
      return true
    }
    return false
  }

  async changePackage () {
    const {
      selectedPackageSkuId,
      toPackageSku
    } = this.state
    const {
      projectId,
      updateProjectSku
    } = this.props
    const toCustomPrice = toPackageSku && this.priceWithCoupon(toPackageSku.price, toPackageSku.id) === null
    this.setState({ dialogOpen: false })
    if (selectedPackageSkuId !== this.currentPackageSkuId) {
      await updateProjectSku({ packageSku: selectedPackageSkuId })
      this.currentPackageSkuId = selectedPackageSkuId
      history.push(`/createmovie/continue/${projectId}`, { customPrice: toCustomPrice, type: 'packageChange' })
    }
  }

  setUserCoupons () {
    const {
      userId,
      fetchUserCouponsFunction
    } = this.props
    fetchUserCouponsFunction(userId)
      .then(res => {
        this.setState({ coupons: res.data })
      })
  }

  priceWithCoupon (price, skuId) {
    let baseAmount
    const packageSkuPrice = parseFloat(price.replace('$', ''))
    if (!isNaN(packageSkuPrice)) {
      baseAmount = Math.floor(packageSkuPrice * 100)
    } else {
      baseAmount = null
    }
    const coupons = this.state.coupons.filter(coupon => coupon.skus.includes(skuId))
    let bestCoupon = null
    let biggestSave = 0
    coupons.forEach((coupon, index) => {
      let save = 0
      if (coupon.amount_off) {
        save = coupon.amount_off
      } else {
        save = baseAmount * coupon.percent_off / 100
      }
      if (save > biggestSave) {
        biggestSave = save
        bestCoupon = coupon
      }
    })

    let newAmount = baseAmount
    if (bestCoupon) {
      if (bestCoupon.amount_off) {
        newAmount = baseAmount - bestCoupon.amount_off
      } else {
        newAmount = Math.floor(baseAmount - baseAmount * bestCoupon.percent_off / 100)
      }
      newAmount = Math.max(newAmount, 0)
    }
    return newAmount
  }

  splitText (text) {
    if (text && typeof text === 'string') {
      return text.split('|')
    }
    return ['']
  }

  getItemText (skuId) {
    const { packages } = this.props
    const pack = packages.find(pack => pack.skus.find(sku => sku.id === skuId))
    if (pack === undefined) {
      return ''
    }
    const sku = pack.skus.find(sku => sku.id === skuId)
    const calculatedPrice = this.priceWithCoupon(sku.price, sku.id)
    const [skuDescription] = this.splitText(sku.description)

    const description = skuDescription ? ' ' + skuDescription : ''
    const price = calculatedPrice === null
      ? 'Custom'
      : formatPrice(calculatedPrice)
    return `${pack.title}${description}: ${price}`
  }

  openDialog () {
    this.setState({
      dialogOpen: true
    })
  }

  componentDidMount () {
    this.setUserCoupons()
  }

  render () {
    const { classes, packages } = this.props
    const {
      selectedPackageSkuId,
      dialogOpen,
      fromPackage,
      toPackage,
      fromPackageSku,
      toPackageSku
    } = this.state
    const packageSelected = fromPackage && toPackage
    const fromPackageType = packageSelected && fromPackage.type
    const toPackageType = packageSelected && toPackage.type
    const sameType = fromPackageType === toPackageType
    const needInfo = packageSelected && this.needAdditionalInfo()
    const fromCustomPrice = fromPackageSku && this.priceWithCoupon(fromPackageSku.price, fromPackageSku.id) === null
    const toCustomPrice = toPackageSku && this.priceWithCoupon(toPackageSku.price, toPackageSku.id) === null
    const changesObject = packageSelected && this.packageChangeMap[`${fromPackage.type}to${toPackage.type}`]
    const currentPackage = packages && packages.find(pack => pack.skus.find(sku => sku.id === this.currentPackageSkuId))
    const currentSku = currentPackage && currentPackage.skus.find(sku => sku.id === this.currentPackageSkuId)
    const skuIsHidden = currentSku && currentSku.isHidden
    const skuComingSoon = currentSku && currentSku.comingSoon
    return (
      <React.Fragment>
        {!skuIsHidden && !skuComingSoon &&
          <div
            className={classes.link}
            onClick={this.openDialog.bind(this)}
          >
            Change package
          </div>
        }
        <UrlifeConfirmDialog
          isOpen={dialogOpen}
          header={!packageSelected
            ? 'Change package'
            : `
              Changing 
              from ${sameType ? this.splitText(fromPackageSku.description)[0] : fromPackage.title} 
              to ${sameType ? this.splitText(toPackageSku.description)[0] : toPackage.title}
            `
          }
          text={''}
          cancelLabel="Cancel"
          confirmLabel="Change"
          onIgnore={this.cancelDialog.bind(this)}
          onCancel={this.cancelDialog.bind(this)}
          onConfirm={this.changePackage.bind(this)}
          confirmDisabled={false}
        >
          {!packageSelected &&
            <FormControl>
              <InputLabel>Package</InputLabel>
              <Select
                className={classes.selectPackage}
                value={selectedPackageSkuId}
                onChange={this.handlePackageChange.bind(this)}
              >
                <MenuItem
                  disabled
                  value={selectedPackageSkuId}
                >
                  <p className={classes.packSelectItem}>{this.getItemText(selectedPackageSkuId)}</p>
                </MenuItem>
                {packages.map(pack => {
                  return (
                    pack.skus.filter(
                      sku => !sku.comingSoon &&
                      !sku.isHidden && sku.id !== selectedPackageSkuId
                    ).map(sku => {
                      return (
                        <MenuItem
                          value={sku.id}
                          key={sku.id}
                        >
                          <p className={classes.packSelectItem}>{this.getItemText(sku.id)}</p>
                        </MenuItem>
                      )
                    })
                  )
                })}
              </Select>
            </FormControl>
          }
          {packageSelected &&
            <React.Fragment>
              {changesObject && changesObject.added && changesObject.added.length > 0 &&
                  <React.Fragment>
                    <p>Changing to this package will:</p>
                    <List dense={true}>
                      {changesObject.added.map((item, idx) => {
                        return (
                          <ListItem className={classes.listItem} key={idx}>
                            <ListItemIcon className={classes.iconWrapper}>
                              <CheckMark className={classes.checkIcon}/>
                            </ListItemIcon>
                            <ListItemText primary={this.addedMap[item]}/>
                          </ListItem>
                        )
                      })}
                    </List>
                  </React.Fragment>
              }
              {changesObject && changesObject.removed && changesObject.removed.length > 0 &&
                <React.Fragment>
                  <p>Changing to this package will:</p>
                  <List dense={true}>
                    {changesObject.removed.map((item, idx) => {
                      return (
                        <ListItem className={classes.listItem} key={idx}>
                          <ListItemIcon className={classes.iconWrapper}>
                            <CloseIcon className={classes.closeIcon}/>
                          </ListItemIcon>
                          <ListItemText primary={this.removedMap[item]}/>
                        </ListItem>
                      )
                    })}
                  </List>
                </React.Fragment>
              }
              {toCustomPrice && <p className={classes.text}>
                {`This package has a custom price and you will 
                  get contacted after the project is submitted to discuss your custom project.`
                }
              </p>}
              {fromCustomPrice && !toCustomPrice && <p className={classes.text}>
                {'This package has a predefined price and can be purchased directly'}
              </p>}
              {needInfo && <p className={classes.text}>
                {`If you choose to change we will redirect you to the 
                page where you can add/change the additional information.`}
              </p>}
            </React.Fragment>
          }
        </UrlifeConfirmDialog>
      </React.Fragment>
    )
  }
}

PackageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  creationStatus: PropTypes.object,
  fetchUserCouponsFunction: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  packageSkuId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  updateProjectSku: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  return {
    packages: state.packages.packages,
    userId: state.user.userid
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchUserCouponsFunction: function (userId) {
      return dispatch(fetchUserCoupons(userId, { state: ['unredeemed', 'unexpired'] }))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PackageSelector))
