import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function CheckBox ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox='0 0 123 109'>
      <g>
        <g>
          <path d="M90.09949,5.75519c0.75555,-0.01074 1.43015,0.67627 1.43015,1.43396c0,0.75757 -0.67459,1.4483 -1.43015,1.43396h-87.23918v97.50897h97.25023v-51.62244c-0.01068,-0.75769 0.6745,-1.43396 1.43015,-1.43396c0.75565,0 1.45932,0.67627 1.45932,1.43396v53.0564c0,0.75067 -0.71051,1.43396 -1.45932,1.43396h-100.11053c-0.74879,0 -1.43015,-0.68329 -1.43015,-1.43396v-100.37689c0,-0.57599 0.51295,-1.41962 1.43015,-1.43396zM121.33932,0c0.61026,-0.08105 1.21542,0.22363 1.50006,0.77399c0.28467,0.55042 0.16061,1.22601 -0.24869,1.66449c-15.86978,16.64807 -54.16589,57.87439 -70.07736,74.56152c-0.51334,0.55365 -1.39127,0.59332 -1.96646,0.09448l-24.31256,-21.50934c-0.43454,-0.32092 -0.65192,-0.8598 -0.56207,-1.39343c0.08984,-0.53357 0.47159,-0.97101 0.98715,-1.13116c0.51553,-0.1601 1.07706,-0.06055 1.452,0.37366l23.28464,20.5658c16.23969,-17 53.43918,-57.06293 69.13882,-73.53265c0.21164,-0.23413 0.46515,-0.3916 0.80447,-0.46735z" fill={color} fillOpacity="1"></path>
        </g>
      </g>
    </SvgIcon>
  )
}

CheckBox.propTypes = {
  color: PropTypes.string
}
