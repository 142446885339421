const front = (state = { backend: true, retryCount: 0 }, action) => {
  switch (action.type) {
    case 'BACKEND_DOWN':
      const retryCount = action.retryCount || state.retryCount
      return { backend: false, retryCount }
    case 'BACKEND_UP':
      return { backend: true, retryCount: 0 }
    default:
      return state
  }
}

export default front
