import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import UrlifeButton from '../common/UrlifeButton'
import {
  acceptInvite,
  deleteInvite,
  invites
} from '../../actions'
import { commonStyles } from '../../helpers'
import UrlifeInfiniteScroller from '../common/UrlifeInfiniteScroller'
import UrlifePageInfo from '../common/UrlifePageInfo'

/* eslint-disable sort-keys */
const styles = {
  paper: {
    background: 'none'
  },
  userContainer: {
    display: 'flex',
    margin: '5px 0px',
    justifyContent: 'space-between'
  },
  userName: {
    ...commonStyles.text({
      color: '#000',
      lineHeight: 2
    }),
    marginRight: '10px'
  },
  deleteInviteButton: {
    marginLeft: 10
  }
}
/* eslint-enable sort-keys */

export class Invites extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      invites: [],
      isLoading: false,
      message: '',
      method: 'DESC',
      nextInvite: null,
      sort: 'dateInvited'
    }

    this.messages = {
      accept_success: 'The invite has been accepted.',
      delete_success: 'The invite has been deleted.'
    }

    this.acceptInvite = (inviteId) => () => {
      const { invites } = this.state
      this.props.acceptInviteFunction(inviteId).then(
        data => {
          if (data.status === 'success') {
            const newInvites = invites.filter(invite => invite.inviteId !== inviteId)
            this.setState({ invites: newInvites, message: this.messages.accept_success })
          }
        }
      )
    }
    this.deleteInvite = (inviteId) => () => {
      const { invites } = this.state
      const invite = invites.filter(invite => invite.inviteId === inviteId)[0]
      if (window.confirm(`Delete invite '${invite.email}' ?`)) {
        this.props.deleteInviteFunction(inviteId).then(
          data => {
            if (data.status === 'success') {
              const newInvites = invites.filter(invite => invite.inviteId !== inviteId)
              this.setState({ invites: newInvites, message: this.messages.delete_success })
            }
          }
        )
      }
    }
    this.resetAcceptedInvite = () => {
      this.setState({ message: '' })
    }

    this.fetchInvites = (add) => () => {
      const { invites, nextInvite, sort, method } = this.state
      const { invitesFunction } = this.props
      const params = { method, sort }
      if (add) {
        params.cursor = nextInvite
      }
      this.setState({ isLoading: true })
      invitesFunction(params).then(
        data => {
          let newInvites = data.data
          if (add) {
            newInvites = [...invites, ...data.data]
          }
          this.setState({
            invites: newInvites,
            isLoading: false,
            nextInvite: data.nextCursor || null
          })
        },
        fail => {
          // This endpoint is not in use anymore
          // default to not cause crash
          this.setState({
            invites: [],
            isLoading: false,
            nextInvite: null
          })
        }
      )
    }
  }

  componentDidMount () {
    this.fetchInvites(false)()
  }

  render () {
    const { classes } = this.props
    const {
      invites,
      isLoading,
      message,
      nextInvite
    } = this.state
    let hasMore = false
    if (nextInvite) {
      hasMore = true
    }
    return (
      <div>
        <Paper className={classes.paper} elevation={0}>
          <Typography gutterBottom={true} variant="h5" component="h3">
            Requested Invites
          </Typography>
          <UrlifeInfiniteScroller
            loadMore={this.fetchInvites(true)}
            hasMore={hasMore}
            isLoading={isLoading}
            isEmpty={!invites.length}
          >
            {invites && invites.map(invite => {
              return (
                <div key={invite.inviteId} className={classes.userContainer}>
                  <span className={classes.userName}>{invite.fullname} ({invite.email})</span>
                  <div className={classes.inviteUserNav}>
                    <UrlifeButton
                      color="primary"
                      onClick={this.acceptInvite(invite.inviteId)}
                    >
                      Accept
                    </UrlifeButton>
                    <UrlifeButton
                      color="primary"
                      inverted
                      onClick={this.deleteInvite(invite.inviteId)}
                      className={classes.deleteInviteButton}
                    >
                      Delete
                    </UrlifeButton>
                  </div>
                </div>
              )
            })}
          </UrlifeInfiniteScroller>
          {message &&
          <UrlifePageInfo message={message} open={true} onClose={this.resetAcceptedInvite} />}
        </Paper>
      </div>
    )
  }
}

Invites.propTypes = {
  acceptInviteFunction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deleteInviteFunction: PropTypes.func.isRequired,
  invitesFunction: PropTypes.func.isRequired
}

function mapDispatchToProps (dispatch) {
  return {
    acceptInviteFunction: function (inviteId) {
      return dispatch(acceptInvite(inviteId))
    },
    deleteInviteFunction: function (inviteId) {
      return dispatch(deleteInvite(inviteId))
    },
    invitesFunction: function (params) {
      return dispatch(invites(params))
    }
  }
}

export default withStyles(styles)(connect(null, mapDispatchToProps)(Invites))
