import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

export function ParallaxListIcon ({ color, ...otherProps }) {
  return (
    <SvgIcon {...otherProps} viewBox="0 0 16 16" >
      <defs><linearGradient id="LinearGradient1014" gradientUnits="userSpaceOnUse" x1="1" y1="0" x2="16.22999999999999" y2="15.829999999999927"><stop data-testid='pli-stop1' stopOpacity="1" stopColor={color || '#3d29b1'} offset="0"></stop><stop data-testid='pli-stop2' stopOpacity="1" stopColor={color || '#bd6ad3'} offset="1"></stop></linearGradient></defs><desc>Generated with Avocode.</desc><g><g><title>Shape</title><path d="M13.48773,0.40416l-6.74885,13.12808v0l-5.22192,-3.40269c-0.43269,-0.30654 -1.03385,-0.20385 -1.34039,0.22961c-0.30654,0.43347 -0.20384,1.03385 0.22962,1.34039l6.00538,3.95731c0.16846,0.11961 0.36231,0.17653 0.55462,0.17653c0.30077,0 0.59731,-0.14076 0.78461,-0.40461l7.305,-13.91039c0.3077,-0.43307 0.20577,-1.03346 -0.22692,-1.34076c-0.43269,-0.30731 -1.03385,-0.20577 -1.34115,0.22653z" fill="url(#LinearGradient1014)"></path></g></g>
    </SvgIcon>
  )
}

ParallaxListIcon.propTypes = {
  color: PropTypes.string
}
